import { FirstLoginPage } from 'pages/Auth/FirstLoginPage/FirstLoginPage';
import { LoginPage } from 'pages/Auth/LoginPage/LoginPage';
import { FirstLoginMerchantPage } from 'pages/AuthMerchant/FirstLoginMerchantPage/FirstLoginPage';
import { LoginMerchantPage } from 'pages/AuthMerchant/LoginMerchantPage/LoginPage';
import { AdministrationPage } from 'v2Pages/Provider/AdministrationPage';
import BankAccountPage from 'v2Pages/Provider/BankAccountPage';
import { CreateMerchantPage } from 'v2Pages/Provider/CreateMerchantPage';
import { InvoicesPage } from 'v2Pages/Provider/InvoicesPage';
import { MarketPage } from 'v2Pages/Provider/MarketPage';
import { MerchantManagementPage } from 'v2Pages/Provider/MerchantManagementPage';
import { OrdersPage } from 'v2Pages/Provider/OrdersPage';
import { ProjectApprovementsPage } from 'v2Pages/Provider/ProjectApprovementsPage';
import { ProviderInvoiceLinkPage } from 'pages/Provider/ProviderInvoiceLinkPage/ProviderInvoiceLinkPage';
import { ProjectInvoiceLinkPage } from 'pages/Project/ProjectInvoiceLinkPage/ProjectInvoiceLinkPage';
import { ProviderTransactionsDetailsPage } from 'v2Pages/Provider/ProviderTransactionsDetailsPage';
import { ReportsAnalyticsPage } from 'v2Pages/Provider/ReportsAnalyticsPage';
import { UserProfilePage } from 'v2Pages/Provider/UserProfilePage';
import { WalletAddressesPage } from 'v2Pages/Provider/WalletAddressesPage';
import { WalletsPage } from 'v2Pages/Provider/WalletsPage';
import { PendingWithdrawalPage } from 'v2Pages/Provider/PendingWithdrawalPage';
import { ResetPasswordPage } from 'pages/Auth/ResetPasswordPage/ResetPasswordPage';
import { ConfirmIpAddressPage } from 'pages/Auth/ConfirmIpAddressPage/ConfirmIpAddress';
import { EParams } from 'services/constants/params';
import { LiquidityPage } from 'v2Pages/Provider/LiquidityPage/LiquidityPage';
import { Reset2fa } from 'pages/Auth/Reset2fa/Reset2fa';
import { TransactionsPage } from 'v2Pages/Provider/TransactionsPage';
import { ApprovementsPage } from 'v2Pages/Provider/Approvements';
import { TRXManagementPage } from 'v2Pages/Provider/TRXManagementPage';
import { isAll, isAuth, isGuest } from './authMiddlewares';
import { MerchantRoutes } from './MerchantRoutes/MerchantRoutes';
import { ProjectRoutes } from './ProjectRoutes/ProjectRoutes';
import { routes } from './routes';

export const authList = [
	{
		exact: true,
		path: routes.firstLogin,
		component: isGuest(FirstLoginPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.login,
		component: isGuest(LoginPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.resetPassword,
		component: isAll(ResetPasswordPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.reset2fa,
		component: isAll(Reset2fa),
		role: 'Administrator, Operator',
	},
	{
		exact: true,
		path: routes.confirmIpAddress,
		component: isAll(ConfirmIpAddressPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.firstLoginMerchant,
		component: isGuest(FirstLoginMerchantPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.loginMerchant,
		component: isGuest(LoginMerchantPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
];
export const titleList = {
	[routes.transactions]: 'Transactions',
	[routes.transactionDetails]: 'Provider transaction details',
	[routes.merchantManagement]: 'Merchant management',
	[routes.walletAddressBase]: 'Wallet addresses',
	[routes.walletAddress.profile]: 'Wallet addresses',
	[`${routes.walletsBase}${routes.wallets.balances}`]: 'Wallets',
	[`${routes.walletsBase}${routes.wallets.transactionHistory}`]: 'Wallets',
	[`${routes.walletsBase}${routes.wallets.transactionHistoryDetails}`]: 'Wallets',
	[`${routes.walletsBase}${routes.wallets.paymentTransactions}`]: 'Wallets',
	[`${routes.walletsBase}${routes.wallets.paymentTransactionsDetails}`]: 'Wallets',
	[`${routes.walletsBase}${routes.wallets.profit}`]: 'Wallets',
	[routes.marketBase]: 'Market',
	[`${routes.marketBase}${routes.market.currencies}`]: 'Market',
	[`${routes.marketBase}${routes.market.feeManagement}`]: 'Market',
	[`${routes.marketBase}${routes.market.limitation}`]: 'Market',
	[`${routes.marketBase}${routes.market.freeWithdraw}`]: 'Market',
	[routes.approvementsBase]: 'Analytics',
	[routes.bankAccount]: 'Bank Account',
	[routes.createMerchant]: 'Create merchant',
	[`${routes.invoices}${EParams.ID}`]: 'Invoices',
	[routes.liquidityBase]: 'Liquidity',
	[routes.orders]: 'Orders',
	[routes.pendingWithdrawal]: 'Pending Withdrawal',
	[routes.projectApprovements]: 'Project Index',
	[routes.reportsAnalyticsBase + EParams.TAB]: 'Reports/Analytics',
	[routes.userProfile]: 'User profile',
	[routes.administrationBase]: 'Market',
	[routes.approvementsBase]: 'Approvements',
	[routes.pendingWithdrawal]: 'Pending Withdrawal',
};

export const routeListV2 = [
	{
		exact: false,
		path: routes.bankAccount,
		component: isAuth(BankAccountPage),
		role: 'Administrator, Financier',
	},
	{
		exact: false,
		path: routes.marketBase,
		component: isAuth(MarketPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.transactions,
		component: isAuth(TransactionsPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.walletAddressBase,
		component: isAuth(WalletAddressesPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		title: 'Wallet Addresses',
		exact: true,
		path: routes.walletAddress.profile,
		component: isAuth(WalletAddressesPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.walletsBase,
		component: isAuth(WalletsPage),
		role: 'Administrator, Financier',
	},
	{
		exact: true,
		path: routes.invoices + EParams.ID,
		component: isAuth(InvoicesPage),
		role: 'Administrator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.orders,
		component: isAuth(OrdersPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.pendingWithdrawal,
		component: isAuth(PendingWithdrawalPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.projectApprovements,
		component: isAuth(ProjectApprovementsPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.administrationBase,
		component: isAuth(AdministrationPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.liquidityBase,
		component: isAuth(LiquidityPage),
		role: 'Administrator, Financier',
	},
	{
		exact: true,
		path: routes.reportsAnalyticsBase + EParams.TAB,
		component: isAuth(ReportsAnalyticsPage),
		role: 'Administrator, Financier',
	},
	{
		exact: true,
		path: routes.merchantManagement,
		component: isAuth(MerchantManagementPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.createMerchant,
		component: isAuth(CreateMerchantPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.transactionDetails,
		component: isAuth(ProviderTransactionsDetailsPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.trxManagementBase,
		component: isAuth(TRXManagementPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
];
export const routeList = [
	// Auth
	{
		exact: true,
		path: routes.firstLogin,
		component: isGuest(FirstLoginPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.login,
		component: isGuest(LoginPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.resetPassword,
		component: isAll(ResetPasswordPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.reset2fa,
		component: isAll(Reset2fa),
		role: 'Administrator, Operator',
	},
	{
		exact: true,
		path: routes.confirmIpAddress,
		component: isAll(ConfirmIpAddressPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.firstLoginMerchant,
		component: isGuest(FirstLoginMerchantPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.loginMerchant,
		component: isGuest(LoginMerchantPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// components / MerchantApprovalManagement
	{
		exact: true,
		path: routes.providerInvoiceLink,
		component: ProviderInvoiceLinkPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.merchantInvoiceLink,
		component: ProjectInvoiceLinkPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// components / Market
	{
		exact: true,
		path: routes.userProfile,
		component: isAuth(UserProfilePage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// components / Liqudity
	// components / Reports/Analytics
	// components / Wallet Addresses
	// One Project / Index
	{
		exact: false,
		path: routes.approvementsBase,
		component: isAuth(ApprovementsPage),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// components / Orders
	// components / ProviderPendingWithdrawal
	// One Project
	{
		exact: false,
		path: routes.oneProjectBase,
		component: isAuth(ProjectRoutes),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// One MerchantApprovalManagement
	{
		exact: false,
		path: routes.oneMerchantBase,
		component: isAuth(MerchantRoutes),
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// Merchant ProjectClients
	// {
	// 	path: routes.merchantClient,
	// 	component: isAuth(ClientMerchantRoutes),
	// 	role: 'Administrator, Operator, Financier, Techsupport',
	// },
];
