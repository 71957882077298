import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilter } from 'redux/reducers/app/selectors';
import { store } from 'redux/store';
import { getAuthState } from 'redux/reducers/auth/selectors';
import classNames from 'classnames';
import { WEB_API_URL } from 'services/constants/env';
import { CsvIcon, ExcelIcon } from 'assets/inline-svg';
import { useParams } from 'react-router-dom';
import { IReportDownloadMenuProps } from './types';
import styles from './styles.module.scss';

export const ReportDownloadMenu: FC<IReportDownloadMenuProps> = ({
	downloadReportUrl,
	isOpen,
	menuRef,
	tab,
	onClose,
}) => {
	const filter = useSelector(getFilter);
	const state = store.getState();
	const auth = getAuthState(state);
	const { projectId, merchantId } = useParams<{ projectId: string; merchantId: string }>();
	const download = (url: string | undefined, reportFormat: string | undefined) => {
		if (url) {
			const hiddenForm = document.createElement('form');
			hiddenForm.setAttribute('method', 'post');
			hiddenForm.setAttribute('action', `${String(WEB_API_URL)}${url}/${reportFormat || ''}`);
			hiddenForm.setAttribute('target', '_blank');
			const params: any = {
				...filter,
				project_id: projectId,
				owner_merchant_id: merchantId,
				token: auth.token as string,
			};
			Object.keys(params).forEach((key) => {
				if (typeof params[key] !== 'undefined') {
					const hiddenField = document.createElement('input');
					let val = params[key];
					if (val instanceof Date) val = val.toISOString();
					else val = val as string;
					hiddenField.setAttribute('type', 'hidden');
					hiddenField.setAttribute('name', key);
					hiddenField.setAttribute('value', val);
					hiddenForm.appendChild(hiddenField);
				}
			});
			document.body.appendChild(hiddenForm);
			hiddenForm.submit();
			document.body.removeChild(hiddenForm);
		}
	};
	const handleXLSX = () => {
		download(downloadReportUrl, 'xlsx');
		onClose();
	};
	const handleCSV = () => {
		download(downloadReportUrl, 'csv');
		onClose();
	};
	return (
		<div
			className={classNames(styles.reportDownloadMenu, { [styles.active]: isOpen })}
			ref={menuRef}
		>
			<div className={styles.reportDownloadMenu__item} onClick={handleXLSX}>
				<ExcelIcon />
				<span>Download XLSX</span>
			</div>

			<div className={styles.reportDownloadMenu__item} onClick={handleCSV}>
				<CsvIcon />
				<span>Download CSV</span>
			</div>
		</div>
	);
};
