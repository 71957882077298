import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SearchIcon } from 'assets/inline-svg';
import { getMerchantOwnersRequest } from 'redux/reducers/providerMerchant/reducer';
import { getMerchantOwners } from 'redux/reducers/providerMerchant/selectors';
import { noRepeat } from 'utils/arrayNoRepeat';
import {
	getProjectListMerchantRequest,
	getProjectListProviderRequest,
} from 'redux/reducers/merchantProjects/reducer';
import { getCurrencyNetwork } from 'redux/reducers/app/selectors';
import { getProjectList } from 'redux/reducers/merchantProjects/selectors';
import { getProviderAllCurrencyRequest } from 'redux/reducers/providerCurrency/reducer';
import { getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { getNetworkRequest } from 'redux/reducers/app/reducer';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { TextField } from 'v2Components/formComponents/TextField';
import { DatepickerField } from 'v2Components/formComponents/DatepickerField';
import { SelectFieldCurrency } from 'UI/SelectFieldCurrency/SelectFieldCurrency';

import { cryptoType, fiatType, transactionStatus } from './utils';
import { IFilters } from './types';
import styles from './styles.module.scss';

export const Filters: FC<IFilters> = ({ values, currencyTab }) => {
	const dispatch = useDispatch();
	const [merchantId, setMerchantId] = useState(null);
	const merchants = useSelector(getMerchantOwners);
	const projects = useSelector(getProjectList) || [];
	const network = useSelector(getCurrencyNetwork);
	const { data: merchantslist = [] } = merchants || {};
	const userType = useSelector(getUserType);
	const isCrypto = currencyTab === 'crypto';
	const networks = network.map((net) => ({
		label: net.currency_network_name,
		id: net.currency_network_name,
	}));
	const filtetNetworks = noRepeat(networks, 'id');
	useEffect(() => {
		if (merchantId) {
			userType === constUserTypes.PROVIDER
				? dispatch(getProjectListProviderRequest({ apiParams: { merchant_id: merchantId } }))
				: dispatch(getProjectListMerchantRequest({ apiParams: { merchant_id: merchantId } }));
		}
	}, [dispatch, merchantId, userType]);

	useEffect(() => {
		dispatch(getProviderAllCurrencyRequest());
		dispatch(getNetworkRequest());
		dispatch(getMerchantOwnersRequest({ apiParams: {} }));
	}, [dispatch]);

	return (
		<div className={styles.table__filter}>
			<SelectFieldCurrency />
			<SelectField name="type" title="Type" options={isCrypto ? cryptoType : fiatType} />
			<DatepickerField
				label="Date from"
				maxDate={(values?.dateTo as Date) || null}
				name="date_from"
				inFilter
				classNameCustom={styles.mb_0}
			/>
			<DatepickerField
				label="Date to"
				minDate={(values?.dateFrom as Date) || null}
				name="date_to"
				classNameCustom={styles.mb_0}
				inFilter
			/>
			<TextField name="amount_from" type="number" label="Amount From" placeholder="Enter" />
			<TextField name="amount_to" type="number" label="Amount To" placeholder="Enter" />
			<TextField
				name="transaction_id"
				label="Tx ID"
				placeholder="Index"
				classNameCustom={styles.mb_0}
				renderRightIcon={<SearchIcon />}
			/>
			<SelectField name="status" title="Status" options={transactionStatus} />
			{isCrypto ? (
				<TextField
					name="risk_score"
					label="Risk Score"
					placeholder="Index"
					classNameCustom={styles.mb_0}
					renderRightIcon={<SearchIcon />}
				/>
			) : null}
			{isCrypto ? (
				<div>
					<SelectField
						name="owner_merchant_id"
						title="Merchant"
						options={[{ id: 'all', id_merchant: 'All' }, ...merchantslist]}
						valueProp="id"
						labelProp="id_merchant"
						change={(value) => setMerchantId(value)}
					/>
					<SelectField
						name="project_id"
						title="Project"
						options={[{ id: 'all', name: 'All' }, ...projects]}
						valueProp="id"
						labelProp="name"
						disabled={!merchantId}
					/>
					<SelectField
						name="currency_network"
						title="Network"
						options={[{ id: 'All', label: 'All' }, ...filtetNetworks]}
						valueProp="id"
						labelProp="label"
					/>
					<TextField
						name="tx_hash"
						label="Tx Hash"
						placeholder="Index"
						classNameCustom={styles.mb_0}
						renderRightIcon={<SearchIcon />}
					/>
				</div>
			) : null}
		</div>
	);
};
