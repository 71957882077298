import { FC, useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getApprovementRequest,
	setCheckedIds,
	getApprovementDefaultRequest,
} from 'redux/reducers/providerApprovements/reducer';
import {
	getProviderApprovementsData,
	getProviderApprovementsLoading,
} from 'redux/reducers/providerApprovements/selectors';
import { Pagination } from 'UI/Pagination/Pagination';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import Table from 'v2Components/commomComponents/Table';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { MobileProviderInfo } from 'components/Provider/MobileProviderInfo/MobileProviderInfo';
import { Tabs } from 'v2Components/commomComponents/Tabs/Tabs';
import Main from 'v2Components/layoutsComponents/Main';
import { IProviderItem } from 'redux/reducers/providerApprovements/types';
import { IPropsTabs } from './types';
import TableRow from './TableRow';
import Header from '../../Header/Header';

const COUNT_ITEMS_PAGE = 15;

export const Merchant: FC<IPropsTabs> = () => {
	const dispatch = useDispatch();
	const approvementMerchant = useSelector(getProviderApprovementsData);
	const loading = useSelector(getProviderApprovementsLoading);
	const totalPages = approvementMerchant?.last_page || 1;
	const [currentPage, setCurrentPage] = useState(1);
	const [checked, setChecked] = useState<Array<number>>([]);
	const [withApprovemen, setWithApprovemen, removewithApprovemen] = useLocalStorage(
		'approvement',
		'',
	);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('ApprovementsMerchant');
	const params = useMemo(
		() => ({
			current_page: currentPage,
			per_page: COUNT_ITEMS_PAGE,
		}),
		[currentPage],
	);
	const isShowPagination = totalPages > 1 && !loading;
	const updateApprovementRequset = () => dispatch(getApprovementRequest({ params }));

	const fetchData = useCallback(() => {
		dispatch(getApprovementRequest({ params }));
		getApprovementDefaultRequest({ params: { current_page: 1, per_page: COUNT_ITEMS_PAGE } });
	}, [dispatch, params]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (approvementMerchant && !withApprovemen) {
			const newWith = approvementMerchant.data;
			setWithApprovemen(String(newWith));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [approvementMerchant, withApprovemen]);

	useEffect(() => {
		dispatch(setCheckedIds(checked));
	}, [dispatch, checked]);
	return (
		<Main
			header={
				<>
					<MobileProviderInfo />
					<Header title="Approvements" />
				</>
			}
		>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={approvementMerchant?.data || []}
				gridTemplateColumns={gridTemplateColumns}
				getRow={(item: IProviderItem) =>
					TableRow({ item, checked, setChecked, updateApprovementRequset })
				}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</PaginationWrapper>
			)}
		</Main>
	);
};
