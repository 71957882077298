import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import { MerchantSidebar } from 'layouts-elements/MerchantSidebar/MerchantSidebar';
import { getUserType } from 'redux/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import { constUserTypes } from 'services/constants/user-types';
import { ProviderHeader } from 'layouts-elements/ProviderHeader/ProviderHeader';
import { useLocation } from 'react-router';
import { routes } from 'routes/routes';
import { MerchantSidebarV2 } from 'v2Components/layoutsComponents/MerchantSidebar';
import { IMerchantLayoutProps } from './types';

export const MerchantLayout: FC<IMerchantLayoutProps> = ({ children, title, v2 }) => {
	const appTitle = APP_NAME || '';
	const layoutTitle = title ? ` | ${title}` : '';
	const userType = useSelector(getUserType);
	const { pathname } = useLocation();
	const isHaveRules =
		userType === constUserTypes.PROVIDER ||
		(!pathname.includes(routes.createMerchantProject) &&
			!pathname.includes(routes.duplicateMerchantProject));

	return (
		<>
			<Helmet>
				<title>{`${appTitle}${layoutTitle}`}</title>
			</Helmet>
			<div className="wrapper">
				<ProviderHeader />
				<main className="main">
					<section className="main-section">
						{isHaveRules && (v2 ? <MerchantSidebarV2 /> : <MerchantSidebar />)}
						{userType === constUserTypes.PROVIDER ? children : children}
					</section>
				</main>
			</div>
		</>
	);
};
