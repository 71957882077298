import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IProviderApi } from './types';

export const provider: IProviderApi = {
	// Merchant
	getMerchantOwners: (params) =>
		http.get(endpoint.provider.GET_MERCHANT_OWNERS, { params }).then(({ data }) => data),
	toggleMerchantOwnerBlock: (params) =>
		http.post(endpoint.provider.TOGGLE_MERCHANT_OWNER_BLOCK, params).then(({ data }) => data),
	providerRsetPassword: (params) =>
		http.post(endpoint.provider.PROVIDER_RESET_PASSWORD, params).then(({ data }) => data),
	providerResetPasswordMerchant: (params) =>
		http.post(endpoint.provider.PROVIDER_RESET_PASSWORD, params).then(({ data }) => data),
	deleteMerchantOwner: (params) =>
		http.delete(endpoint.provider.DELETE_MERCHANT_OWNER, { params }).then(({ data }) => data),
	postMerchantAndProject: (params) =>
		http.post(endpoint.provider.ADD_REGISTER_MERCHANT_AND_PROJECT, params).then(({ data }) => data),
	postMerchantOwnProject: (params) =>
		http.post(endpoint.provider.ADD_REGISTER_MERCHANT_OWN_PROJECT, params).then(({ data }) => data),
	// Administration
	provider_team_members: (params) =>
		http.get(endpoint.provider.TEAM_MEMBERS, { params }).then(({ data }) => data),
	merchant_team_members: (params) =>
		http.get(endpoint.provider.TEAM_MEMBERS_MERCHANT, { params }).then(({ data }) => data),
	getRoles: async ({ payload }) => {
		return http
			.get(
				payload === 'ApprovalManagement'
					? endpoint.merchant.GET_ROLES
					: endpoint.provider.GET_ROLES,
			)
			.then(({ data }) => data);
	},
	getUserProfile: (params) =>
		http.get(endpoint.provider.GET_USER_PROFILE, { params }).then(({ data }) => data),
	editProviderProfile: (params) =>
		http.post(endpoint.provider.EDIT_PROVIDER_PROFILE, params).then(({ data }) => data),
	editProviderRole: (params) =>
		http.post(endpoint.provider.EDIT_PROVIDER_ROLE, params).then(({ data }) => data),
	deleteProvider: (params) =>
		http.delete(endpoint.provider.DELETE_PROVIDER, { params }).then(({ data }) => data),
	deleteWhiteListIpRecord: (params) =>
		http.delete(endpoint.provider.DELETE_WHITE_LIST_IP_RECORD, { params }).then(({ data }) => data),
	deleteWhiteListIpRecordMerchant: (params) =>
		http
			.delete(endpoint.provider.DELETE_WHITE_LIST_IP_RECORD_MERCHANT, { params })
			.then(({ data }) => data),
	addNewAdmin: (params) =>
		http.post(endpoint.provider.ADD_NEW_ADMIN, params).then(({ data }) => data),
	addNewAdminMerchant: (params) =>
		http.post(endpoint.provider.ADD_NEW_ADMIN_MERCHANT, params).then(({ data }) => data),
	providerToggleBlock: (params) =>
		http.post(endpoint.provider.POST_TOGGLE_BLOCK, { ...params }).then(({ data }) => data),
	// TODO: Move to merchant api (// Profile)
	getMerchantProviderProfile: (params) =>
		http
			.get(endpoint.provider.GET_USER_PROFILE_MERCHANT(params.merchant_id))
			.then(({ data }) => data),
	getMerchantProfile: (params) =>
		http
			.get(endpoint.provider.GET_USER_PROFILE_MERCHANT(params.merchant_id))
			.then(({ data }) => data),
	postMerchantProviderProfile: (params) =>
		http.post(endpoint.provider.POST_USER_PROFILE_MERCHANT, params).then(({ data }) => data),
	postMerchantProviderToggleProfile: (params) =>
		http
			.post(endpoint.provider.POST_USER_PROFILE_MERCHANT_TOGGLE, { id: params.id })
			.then(({ data }) => data),
	// Market
	getMerchantProviderAllCurrency: () =>
		http.get(endpoint.provider.currency.GET_ALL_CURRENCY).then(({ data }) => data),
	getMerchantProviderAllCurrencySetting: (params) =>
		http
			.get(endpoint.provider.currency.GET_ALL_CURRENCY_SETTING, { params })
			.then(({ data }) => data),
	putMerchantProviderAllCurrencySetting: (params) =>
		http
			.put(endpoint.provider.currency.PUT_ALL_CURRENCY_SETTING, { ...params })
			.then(({ data }) => data),
	getProviderFee: () => http.get(endpoint.provider.fee.GET_FEE).then(({ data }) => data),
	putProviderFee: (params) =>
		http.put(endpoint.provider.fee.PUT_FEE, { ...params }).then(({ data }) => data),
	getProviderTransaction: (params) =>
		http
			.get(endpoint.provider.currency.GET_ALL_CURRENCY_TRANSACTIONS, { params })
			.then(({ data }) => data),
	getProviderTransactionFiat: (params) =>
		http
			.get(endpoint.provider.currency.GET_CURRENCY_TRANSACTIONS_FIAT, { params })
			.then(({ data }) => data),
	getProviderProfit: (params) =>
		http
			.get(endpoint.provider.currency.GET_ALL_CURRENCY_PROFIT, { params })
			.then(({ data }) => data),
	postProviderCreateWithdraw: (params) =>
		http
			.post(endpoint.provider.currency.POST_CREATE_WITHDRAWAL, { ...params })
			.then(({ data }) => data),
	getProviderDeposit: (params) =>
		http.get(endpoint.provider.currency.GET_DEPOSIT, { params }).then(({ data }) => data),
	// Wallet
	postProviderCreateFiatWithdraw: (params) =>
		http.post(endpoint.provider.POST_PROVIDER_FIAT_WITHDRAWAL, params).then(({ data }) => data),

	// EXPORT CSV
	getProviderExportCSVTransaction: (params) =>
		http
			.post(endpoint.provider.export.GET_TRANSACTIONS_CSV, { ...params })
			.then(({ data }) => data),
	getProviderExportCSVFiatWalletTransaction: (params) =>
		http
			.post(endpoint.provider.export.GET_TRANSACTIONS_FIAT_CSV, { ...params })
			.then(({ data }) => data),
	// Reports
	getReports: ({ params }) =>
		http.get(endpoint.provider.GET_REPORTS, { params }).then(({ data }) => data),
	// Remember!
	createReports: ({ type, project_id, ...rest }) => {
		const typeReportEndpoint = (typeTr: string) => {
			if (typeTr === 'transaction') {
				return endpoint.provider.CREATE_CRYPTO_TRANSACTIONS_REPORT;
			}
			if (typeTr === 'balance') {
				return endpoint.provider.CREATE_BALANCES_REPORT;
			}
			return endpoint.provider.CREATE_CURRENCY_FLOW_REPORT;
		};

		return http.post(typeReportEndpoint(type), { project_id, ...rest });
	},
	createCryptoTransactionsReport: ({ apiParams, is_crypto, report_format }) => {
		return http
			.post(
				is_crypto
					? `${endpoint.provider.CREATE_CRYPTO_TRANSACTIONS_REPORT}/${report_format || ''}`
					: `${endpoint.provider.CREATE_FIAT_TRANSACTIONS_REPORT}/${report_format || ''}`,
				{ ...apiParams },
			)
			.then(({ data }) => data);
	},
	downloadCryptoTransactionsReport: ({ apiParams, is_crypto, report_format }) => {
		return http
			.post(
				is_crypto
					? `${endpoint.provider.DOWNLOAD_CRYPTO_TRANSACTIONS_REPORT}/${report_format || ''}`
					: `${endpoint.provider.DOWNLOAD_FIAT_TRANSACTIONS_REPORT}/${report_format || ''}`,
				{ ...apiParams },
			)
			.then(({ data }) => data);
	},
	// ProviderWalletAddresses
	getProviderWalletAddresses: (params) =>
		http.get(endpoint.provider.GET_WALLET_ADDRESSES, { params }).then(({ data }) => data),
	getProviderWalletAddressesByClient: (params) =>
		http.get(endpoint.provider.GET_WALLET_ADDRESSES_CLIENT, { params }).then(({ data }) => data),
	// ProviderApprovements
	getProviderApprovementsMerchant: (params) =>
		http.get(endpoint.provider.GET_APPROVEMENTS_MERCHANT, { params }).then(({ data }) => data),
	getProviderApprovementsProvider: (params) =>
		http.get(endpoint.provider.GET_APPROVEMENTS_PROVIDER, { params }).then(({ data }) => data),
	putRequestKYCProvider: (payload) =>
		http.put(endpoint.provider.PUT_REQUEST_KYC_PROVIDER, payload).then(({ data }) => data),
	putRequestProviderRefound: (payload) =>
		http.put(endpoint.provider.PUT_PROVIDER_REFOUND, payload).then(({ data }) => data),
	putRequestProviderStatus: (payload) =>
		http.put(endpoint.provider.PUT_PROVIDER_STATUS, payload).then(({ data }) => data),
	getRequestProviderApprovement: (params) =>
		http.get(endpoint.provider.GET_PROVIDER_TRANSACTION(params)).then(({ data }) => data),
	postProviderCommentApprovement: (payload) =>
		http.post(endpoint.provider.POST_PROVIDER_ADD_COMMENT, payload).then(({ data }) => data),

	// FEE
	// getProviderWallets: (params) =>
	// 	http.get(endpoint.provider.wallets.GET_BALANCES).then(({ data }) => data),
	// putProviderWallets: (params) =>
	// 	http.put(endpoint.provider.wallets.PUT_BALANCES, { ...params }).then(({ data }) => data),
	getInvoices: (params) =>
		http.get(endpoint.provider.GET_INVOICES, { params }).then(({ data }) => data),
	createInvoice: (params) =>
		http.get(endpoint.provider.CREATE_INVOICE, { params }).then(({ data }) => data),
	downloadInvoicePDF: (params) =>
		http.get(endpoint.provider.GET_INVOICE_PDF, { params }).then(({ data }) => data),
	sendInvoicePDF: (params) =>
		http.post(endpoint.provider.SEND_INVOICE_PDF, { params }).then(({ data }) => data),
	sendInvoiceLink: (params) =>
		http.post(endpoint.provider.SEND_INVOICE_LINK, params).then(({ data }) => data),
	getInvoice: (params) =>
		http.get(endpoint.provider.GET_INVOICE, { params }).then(({ data }) => data),
	getInvoiceLink: (params) =>
		http.get(endpoint.provider.GET_INVOICE_LINK, { params }).then(({ data }) => data),
	exportCSVInvoiceProvider: (params) =>
		http.post(endpoint.provider.EXPORT_CSV_INVOICE_PROVIDER, params).then(({ data }) => data),
	getInvoiceStatus: (params) =>
		http.get(endpoint.provider.GET_PROVIDER_INVOICE_STATUS, { params }).then(({ data }) => data),

	getBankAccounts: () => http.get(endpoint.provider.GET_BANK_ACCOUNTS).then(({ data }) => data),
	addBankAccount: (params) =>
		http.post(endpoint.provider.ADD_BANK_ACCOUNT, params).then(({ data }) => data),
	editBankAccount: (params) =>
		http.put(endpoint.provider.EDIT_BANK_ACCOUNT, params).then(({ data }) => data),
	deleteBankAccount: (params) =>
		http.put(endpoint.provider.DELETE_BANK_ACCOUNT, params).then(({ data }) => data),
	editBankAccountInMerchant: (params) =>
		http
			.put(endpoint.provider.EDIT_BANK_ACCOUNT_MERCHANT, params.putParams, {
				params: params.getParams,
			})
			.then(({ data }) => data),
	getCryptoWallets: () => http.get(endpoint.provider.GET_CRYPTO_WALLET).then(({ data }) => data),
	getCryptoWalletsByCurrencySetting: (params) =>
		http
			.get(endpoint.provider.GET_CRYPTO_WALLET_BY_CURRENCY_SETTING, { params })
			.then(({ data }) => data),
	addCryptoWallet: (params) =>
		http.put(endpoint.provider.ADD_CRYPTO_WALLET, params).then(({ data }) => data),
	editCryptoWallet: (params) =>
		http.put(endpoint.provider.EDIT_CRYPTO_WALLET, params).then(({ data }) => data),
	deleteCryptoWallet: (params) =>
		http.put(endpoint.provider.DELETE_CRYPTO_WALLET, params).then(({ data }) => data),
	getTotalBalancesInFiat: () =>
		http.get(endpoint.provider.GET_TOTAL_BALANCES_IN_FIAT).then(({ data }) => data),
	// Orders
	getOrderLists: (params) =>
		http.get(endpoint.provider.GET_ORDERS_LIST, { params }).then(({ data }) => data),
	getOrderDetails: (params) =>
		http.get(endpoint.provider.GET_ORDER_DETAILS, { params }).then(({ data }) => data),
	createOrder: (params) =>
		http.post(endpoint.provider.CREATE_ORDER, params).then(({ data }) => data),
	// Payment transactions
	getPaymentTransactionsList: (params) =>
		http.get(endpoint.provider.GET_PAYMENT_TRANSACTIONS, { params }).then(({ data }) => data),
	getPaymentTransactionDetails: (params) =>
		http.get(endpoint.provider.GET_PAYMENT_TRANSACTION, { params }).then(({ data }) => data),
	getProjectTransactions: (params) =>
		http.get(endpoint.provider.GET_PROJECT_TRANSACTIONS, { params }).then(({ data }) => data),

	exportProviderTransactionsCsv: ({ apiParams, is_crypto }) =>
		http
			.post(
				is_crypto
					? endpoint.provider.EXPORT_PROVIDER_TRANSACTIONS_CSV
					: endpoint.provider.EXPORT_FIAT_TRANSACTION_CSV,
				{ ...apiParams },
			)
			.then(({ data }) => data),
	getApprovements: (params) =>
		http.get(endpoint.provider.GET_PROVIDER_APPROVEMENTS, { params }).then(({ data }) => data),
	verifyProject: (params) =>
		http.post(endpoint.provider.VERIFY_PROJECT, params).then(({ data }) => data),
	unverifyProject: (params) =>
		http.post(endpoint.provider.UNVERIFY_PROJECT, params).then(({ data }) => data),
	deleteProject: (params) =>
		http.post(endpoint.provider.DELETE_PROJECT, params).then(({ data }) => data),
	getOwnerMerchantId: (params) =>
		http.get(endpoint.provider.GET_OWNER_MERCHANT, { params }).then(({ data }) => data),
	// Liquidity
	getLiquidityProvider: (params) =>
		http.get(endpoint.provider.GET_LIQUIDITY_PROVIDER, { params }).then(({ data }) => data),
	getTabsLiquidityProvider: () =>
		http.get(endpoint.provider.GET_TABS_LIQUIDITY_PROVIDER).then(({ data }) => data),
	putLiquidityEdit: (params) =>
		http.put(endpoint.provider.PUT_LIQUIDITY_EDIT, params).then(({ data }) => data),
	getMerchants: (params) =>
		http.get(endpoint.provider.GET_MERCHANTS_LIST, { params }).then(({ data }) => data),
	getBankAccountNames: (params) =>
		http
			.get(endpoint.provider.GET_BANK_ACCOUNTS_CURRENCY_NAME, { params })
			.then(({ data }) => data),

	getTRXDelegation: (params) =>
		http.get(endpoint.provider.GET_TRX_DELEGATION, { params }).then(({ data }) => data),
	getTRXOverview: (params) =>
		http.get(endpoint.provider.GET_TRX_OVERVIEW, { params }).then(({ data }) => data),
	createTRXDelegation: (params) =>
		http.post(endpoint.provider.GET_TRX_DELEGATION, params).then(({ data }) => data),
	deleteTRXDelegation: (params) =>
		http.delete(endpoint.provider.DELETE_TRX_DELEGATION(params.id)).then(({ data }) => data),

	cancelAllUnstakings: () =>
		http.get(endpoint.provider.CANCEL_ALL_UNSTAKINGS).then(({ data }) => data),
	addUnstake: (params) =>
		http.delete(endpoint.provider.DELETE_TRX_STAKING(params.id)).then(({ data }) => data),
	addStake: (params) =>
		http.post(endpoint.provider.GET_TRX_STAKING, params).then(({ data }) => data),
	getTRXStaking: (params) =>
		http.get(endpoint.provider.GET_TRX_STAKING, { params }).then(({ data }) => data),

	// Transactions
	getProviderFiatTransaction: (params) =>
		http.get(endpoint.provider.GET_PROJECT_FIAT_TRANSACTIONS, { params }).then(({ data }) => data),
	showWalletFiatTransactionHistory: (params) =>
		http
			.get(endpoint.provider.SHOW_WALLET_FIAT_TRANSACTION_HISTORY, { params })
			.then(({ data }) => data),
	showWalletCryptoTransactionHistory: (params) =>
		http
			.get(endpoint.provider.SHOW_WALLET_CRYPTO_TRANSACTION_HISTORY, { params })
			.then(({ data }) => data),
	getLiquidityDepositAddress: (params) =>
		http.get(endpoint.provider.GET_LIQUIDITY_DEPOSIT_ADDRESS, { params }).then(({ data }) => data),
};
