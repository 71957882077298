import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IGetLiquidityDespositAddressParams,
	ILiquidityDepositAddressResponse,
} from 'services/api/provider/types';

import {
	putProviderLiqudityRequest,
	putProviderLiquditySuccess,
	getProviderLiqudityRequest,
	getDepositAddressRequest,
	getDepositAddressSuccess,
	getDepositAddressError,
	getProviderTabsLiqudityRequest,
	getProviderTabsLiquditySuccess,
	getProviderLiquditySuccess,
} from './reducer';
import {
	IComment,
	ICommentReducerRequest,
	ILiquidityRequest,
	ILiquidityResponse,
	TLiqudityProviderPayload,
	ILiquidity,
	ITabsLiquidityResponse,
} from './types';

function* getProviderLiqudityWorker({ payload }: PayloadAction<ILiquidityRequest>) {
	try {
		const response: ILiquidityResponse = yield call(api.provider.getLiquidityProvider, payload);
		yield put(getProviderLiquditySuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* getProviderTabsLiqudityWorker() {
	try {
		const response: ITabsLiquidityResponse = yield call(api.provider.getTabsLiquidityProvider);
		yield put(getProviderTabsLiquditySuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* putProviderLiqudityWorker({ payload }: PayloadAction<TLiqudityProviderPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: ILiquidity = yield call(api.provider.putLiquidityEdit, apiParams);
		yield put(putProviderLiquditySuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* createOrderWorker({ payload }: PayloadAction<any>) {
	try {
		const response: IComment = yield call(api.provider.createOrder, payload.body);
		yield payload.closePopup();
		yield window.scrollTo(0, document.body.scrollHeight);
	} catch (error) {
		showError(error);
	}
}

function* getDepositAddressWorker({ payload }: PayloadAction<IGetLiquidityDespositAddressParams>) {
	try {
		const response: ILiquidityDepositAddressResponse = yield call(
			api.provider.getLiquidityDepositAddress,
			payload,
		);
		yield put(getDepositAddressSuccess(response));
	} catch (error) {
		showError(error);
		yield put(getDepositAddressError());
	}
}

export function* providerLiquiditySaga() {
	yield takeEvery(getProviderTabsLiqudityRequest, getProviderTabsLiqudityWorker);
	yield takeEvery(getProviderLiqudityRequest, getProviderLiqudityWorker);
	yield takeEvery(putProviderLiqudityRequest, putProviderLiqudityWorker);
	yield takeEvery(getDepositAddressRequest, getDepositAddressWorker);
}
