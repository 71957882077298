import { FC } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import {
	TransactionsIcon,
	GearIcon,
	ClientsIcon,
	CallbackIcon,
	ReportIcon,
	AnalyticsIcon,
	DollarIcon,
	ComissionsIcon,
	OrdersIcon,
	WalletAddress,
} from 'assets/inline-svg';
import { routes } from 'routes/routes';
import { useProjectMatchUrl } from 'hooks';
import SidebarV2 from '../Sidebar';

export const ProjectSidebarV2: FC = () => {
	const projectUrl = useProjectMatchUrl();
	const { merchantId } = useParams<{ merchantId: string }>();
	const transactionsBaseMatch = useRouteMatch(
		`${routes.oneProjectBase}${routes.oneProject.transactionsBase}`,
	);

	const transactionDatailsMatch = useRouteMatch(
		`${routes.oneProjectBase}${routes.oneProject.transaction.details}`,
	);
	const transactionApprovementDatailsMatch = useRouteMatch(
		`${routes.oneProjectBase}${routes.oneProject.approvement.details}`,
	);

	const transactionsMatch = useRouteMatch(projectUrl + routes.oneProject.settingsBase);
	const invoicesBaseMatch = useRouteMatch(`${routes.oneProjectBase}${routes.oneProject.invoices}`);
	const clientMatch = useRouteMatch(projectUrl + routes.oneProject.client);
	const callbacksMatch = useRouteMatch(projectUrl + routes.oneProject.callbacksBase);
	const reportsBaseMatch = useRouteMatch(projectUrl + routes.oneProject.reportsBase);
	const analyticsBaseMatch = useRouteMatch(projectUrl + routes.oneProject.analytics);
	const ordersBaseMatch = useRouteMatch(projectUrl + routes.oneProject.orders);
	const walletProjectAddressBaseMatch = useRouteMatch(
		projectUrl + routes.oneProject.walletProjectAddressBase,
	);

	const comissionsBaseMatch = useRouteMatch(
		`${routes.oneProjectBase}${routes.oneProject.comissions}`,
	);

	const providerInfo = [
		{
			keyRoles: 'transactions',
			link: `${projectUrl}${routes.oneProject.transactions.transactions}${routes.oneProject.transactions.transactions}?type=crypto`,
			children: 'Transactions',
			icon: <TransactionsIcon />,
			getIsActive: () =>
				!!transactionsBaseMatch ||
				!!transactionDatailsMatch ||
				!!transactionApprovementDatailsMatch,
		},
		{
			keyRoles: '',
			link: `${projectUrl}${routes.oneProject.callbacksBase}`,
			children: 'Callbacks',
			icon: <CallbackIcon />,
			getIsActive: () => !!callbacksMatch,
		},
		{
			keyRoles: 'accounts',
			link: `${projectUrl}${routes.oneProject.accounts}${routes.oneProject.accountsTabs.accounts}?tabs=crypto`,
			children: 'Project wallets',
			icon: <ClientsIcon />,
			getIsActive: () => window.location.pathname.includes(routes.oneProject.accounts),
		},
		{
			keyRoles: '',
			link: `${projectUrl}${routes.oneProject.client}`,
			children: 'Clients',
			icon: <ClientsIcon />,
			getIsActive: () => !!clientMatch,
		},
		{
			keyRoles: 'Wallet addresses',
			link: `${projectUrl}${String(routes.oneProject.walletProjectAddressBase)}`,
			children: 'Wallet addresses',
			icon: <WalletAddress />,
			getIsActive: () => !!walletProjectAddressBaseMatch,
		},
		{
			keyRoles: '',
			link: `${projectUrl}${routes.oneProject.orders}}`,
			children: 'Orders',
			icon: <OrdersIcon />,
			getIsActive: () => !!ordersBaseMatch,
		},
		{
			keyRoles: '',
			link: `${projectUrl}${routes.oneProject.invoices}`,
			children: 'Invoices',
			icon: <DollarIcon />,
			getIsActive: () => !!invoicesBaseMatch,
		},
		{
			keyRoles: '',
			link: `${projectUrl}${routes.oneProject.reportsBase}`,
			children: 'Reports',
			icon: <ReportIcon />,
			getIsActive: () => !!reportsBaseMatch,
		},
		{
			keyRoles: 'analytics',
			link: `${projectUrl}${String(routes.oneProject.analytics)}`,
			children: 'Analytics',
			icon: <AnalyticsIcon />,
			getIsActive: () => !!analyticsBaseMatch,
		},
		{
			keyRoles: '',
			link: `${projectUrl}${routes.oneProject.comissions}`,
			children: 'Comissions',
			icon: <ComissionsIcon />,
			getIsActive: () => !!comissionsBaseMatch,
		},
		{
			keyRoles: '',
			link: `${projectUrl}${routes.oneProject.settingsBase}${routes.oneProject.settings.details}`,
			children: 'Project settings',
			icon: <GearIcon />,
			getIsActive: () => !!transactionsMatch,
		},
	];

	return (
		<SidebarV2
			sidebarItems={[
				{
					items: providerInfo,
				},
			]}
			isShowBack
		/>
	);
};
