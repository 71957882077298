/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useField } from 'formik';
import { getUserType } from 'redux/reducers/auth/selectors';
import { useSelector } from 'react-redux';
import { constUserTypes } from 'services/constants/user-types';
import { toBase64 } from 'utils/files';
import { IImageFieldProps } from './types';

const PictureInput: FC<IImageFieldProps> = ({ form, onChange, preventDefaultChange }) => {
	const [field, meta] = useField('file');
	const isShowError = meta.touched && meta.error;
	const userType = useSelector(getUserType);
	const [b64, setB64] = useState('');

	useEffect(() => {
		if (typeof form?.values?.file !== 'string') {
			(async () => {
				setB64(await toBase64(form?.values?.file));
			})();
		}
	}, [form?.values?.file]);

	return (
		<div>
			<div className="upload-file-box-title">
				<p>Project Logo</p>
			</div>
			<label
				className={
					userType === constUserTypes.PROVIDER
						? 'upload-file-box'
						: 'upload-file-box upload-file-box-custom'
				}
			>
				<input
					type="file"
					className="hidden"
					id="upload1"
					name="file"
					accept="image/*"
					onChange={(e: any) => {
						const file = e.target?.files[0];
						if (!preventDefaultChange) {
							form.setFieldValue('file', file);
						}
						onChange?.(file);
					}}
				/>
				<span className="upload-file">
					<span className="upload-file__icon-upload">
						<img src={b64 || meta.initialValue} alt="" />
					</span>
					<span className="upload-file__title">Drop your Image here or</span>
					<span className="upload-file__buttons">
						<label htmlFor="upload1" className="button button--size2 button--type3">
							Upload
						</label>
					</span>
					<span className="upload-file__text">
						{form?.values?.file?.name ? form?.values?.file?.name : 'Supports jpg, png and gif'}
					</span>

					<span className="upload-file__size">* Max file size 5Mb.</span>
				</span>
			</label>
			{isShowError && <p className="input-notification input-notification--error">{meta.error}</p>}
		</div>
	);
};

export default PictureInput;
