import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IAddBankAccountPayload,
	IAddCryptoWalletsPayload,
	IApiGetBankAccountsParams,
	IBankAccountItem,
	IDelBankAccountPayload,
	IEditBankAccountPayload,
	IEditCryptoWalletPayload,
	TBankAccountResponse,
	TCryptoWalletResponse,
	ICryptoMerchantWalletItem,
	IDeleteCryptoWalletPayload,
} from 'services/api/merchant/types';

import {
	addBankAccountRequest,
	addWithdrawalAddressRequest,
	deleteBankAccountRequest,
	deleteWithdrawalAddressRequest,
	editBankAccountMerchantSuccess,
	editBankAccountRequect,
	editWithdrawalAddressRequest,
	editWithdrawalAddressSuccess,
	getBankAccountsByOwnerRequest,
	getBankAccountsRequest,
	getBankAccountsSuccess,
	getWithdrawalAddressesByOwnerRequest,
	getWithdrawalAddressesRequest,
	getWithdrawalAddressSuccess,
	getBankAccountNameRequest,
	getBankAccountNameSuccess,
	setBankAccountName,
	addBankAccountFiatReferenceRequest,
	createFiatWithdrawalRequest,
	getProjectBankAccountRequest,
	getProjectBankAccountSuccess,
} from './reducer';

import {
	IApiGetBankAccountsNameRequest,
	IApiGetBankAccountsNameSuccess,
	IAddDepositFiatRefernceRequset,
	ICreateFiatWithdrawalRequset,
	IApiGetBankAccountsRequest,
	IApiGetBankAccountsByCurrencyResponse,
} from './types';

function* getBankAccountsWorker() {
	try {
		const response: TBankAccountResponse = yield call(api.merchant.getBankAccounts);
		yield put(getBankAccountsSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* getBankAccountsByOwnerWorker({ payload }: PayloadAction<IApiGetBankAccountsParams>) {
	try {
		const response: TBankAccountResponse = yield call(api.merchant.getBankAccountsByOwner, payload);
		yield put(getBankAccountsSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* addBankAccountWorker({ payload }: PayloadAction<IAddBankAccountPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.merchant.addBankAccount, apiParams);
		yield put(getBankAccountsRequest());
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* editBankAccountWorker({ payload }: PayloadAction<IEditBankAccountPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: IBankAccountItem = yield call(api.merchant.editBankAccount, apiParams);
		yield put(editBankAccountMerchantSuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteBankAccountWorker({ payload }: PayloadAction<IDelBankAccountPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: IBankAccountItem = yield call(api.merchant.deleteBankAccount, apiParams);
		yield put(editBankAccountMerchantSuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* getWithdrawalAddressesWorker() {
	try {
		const response: TCryptoWalletResponse = yield call(api.merchant.getCryptoWallets);
		yield put(getWithdrawalAddressSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* getWithdrawalAddressesByOwnerWorker({
	payload,
}: PayloadAction<IApiGetBankAccountsParams>) {
	try {
		const response: TCryptoWalletResponse = yield call(
			api.merchant.getCryptoWalletsByOwner,
			payload,
		);

		yield put(getWithdrawalAddressSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* addWithdrawalAddressWorker({ payload }: PayloadAction<IAddCryptoWalletsPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.merchant.addCryptoWallet, apiParams);
		yield put(
			getWithdrawalAddressesByOwnerRequest({ owner_merchant_id: apiParams.owner_merchant_id }),
		);
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* editWithdrawalAddressWorker({ payload }: PayloadAction<IEditCryptoWalletPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: ICryptoMerchantWalletItem = yield call(
			api.merchant.editCryptoWallet,
			apiParams,
		);
		yield put(editWithdrawalAddressSuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteWithdrawalAddressWorker({ payload }: PayloadAction<IDeleteCryptoWalletPayload>) {
	const { apiParams, onSuccess, onError } = payload;
	try {
		yield call(api.merchant.deleteCryptoWallet, apiParams);
		onSuccess?.();
	} catch (error: any) {
		onError?.(error);
		showError(error);
	}
}

function* getBankAccountNameWorker({ payload }: PayloadAction<IApiGetBankAccountsNameRequest>) {
	const { paramsApi, onSuccess } = payload;
	try {
		const response: IApiGetBankAccountsNameSuccess = yield call(
			api.provider.getBankAccountNames,
			paramsApi,
		);
		yield put(getBankAccountNameSuccess(response));
		onSuccess?.();
	} catch (error) {
		yield put(setBankAccountName());
		showError(error);
	}
}

function* addBankAccountFiatReferenceWorker({
	payload,
}: PayloadAction<IAddDepositFiatRefernceRequset>) {
	const { payload: Payload, onSuccess } = payload;
	try {
		yield call(api.merchant.addDepositFiatReference, Payload);
		// yield put(getBankAccountNameSuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}
function* createFiatWithdrawalWorker({ payload }: PayloadAction<ICreateFiatWithdrawalRequset>) {
	const { payload: Payload, onSuccess } = payload;
	try {
		yield call(api.merchant.createWithdrawalFiat, Payload);
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* getProjectBankAccountWorker({ payload }: PayloadAction<IApiGetBankAccountsRequest>) {
	const { params, onSuccess } = payload;
	try {
		const response: IApiGetBankAccountsByCurrencyResponse = yield call(
			api.merchant.getBankAccountByCurrency,
			params,
		);
		onSuccess?.();
		yield put(getProjectBankAccountSuccess(response));
	} catch (error) {
		showError(error);
	}
}

export function* merchantBankAccountSaga() {
	yield takeEvery(getBankAccountsRequest, getBankAccountsWorker);
	yield takeEvery(getBankAccountsByOwnerRequest, getBankAccountsByOwnerWorker);
	yield takeEvery(addBankAccountRequest, addBankAccountWorker);
	yield takeEvery(editBankAccountRequect, editBankAccountWorker);
	yield takeEvery(deleteBankAccountRequest, deleteBankAccountWorker);
	yield takeEvery(getWithdrawalAddressesRequest, getWithdrawalAddressesWorker);
	yield takeEvery(getWithdrawalAddressesByOwnerRequest, getWithdrawalAddressesByOwnerWorker);
	yield takeEvery(addWithdrawalAddressRequest, addWithdrawalAddressWorker);
	yield takeEvery(editWithdrawalAddressRequest, editWithdrawalAddressWorker);
	yield takeEvery(deleteWithdrawalAddressRequest, deleteWithdrawalAddressWorker);
	yield takeEvery(getBankAccountNameRequest, getBankAccountNameWorker);
	yield takeEvery(addBankAccountFiatReferenceRequest, addBankAccountFiatReferenceWorker);
	yield takeEvery(createFiatWithdrawalRequest, createFiatWithdrawalWorker);
	yield takeEvery(getProjectBankAccountRequest, getProjectBankAccountWorker);
}
