import { FC } from 'react';
import { LogoutIcon, ProfileMan, BlogIcon } from 'assets/inline-svg';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutMerchantRequest, logoutRequest } from 'redux/reducers/auth/reducer';
import { constUserTypes } from 'services/constants/user-types';
import { getUserType } from 'redux/reducers/auth/selectors';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { routes } from 'routes/routes';
import { IUserMenuProps } from './types';

export const UserMenu: FC<IUserMenuProps> = ({ menuRef, onClose, id }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);
	const [withDr, setWithDr, removewithDr] = useLocalStorage('withdrawal', '');
	const [withPend, setPend, removePend] = useLocalStorage('pending', '');
	const [withApprovemen, setWithApprovemen, removewithApprovemen] = useLocalStorage(
		'approvement',
		'',
	);

	const handleLogout = () => {
		dispatch(userType === constUserTypes.PROVIDER ? logoutRequest() : logoutMerchantRequest());
		onClose();
		if (userType === constUserTypes.PROVIDER) {
			removewithDr();
			removePend();
			removewithApprovemen();
		}
	};
	const handleInfo = () => {
		history.push(`/merchant/${String(id || '')}`);
		onClose();
	};

	return (
		<div className="header-admin-drop" ref={menuRef}>
			{userType === constUserTypes.MERCHANT && (
				<div className="header-admin-drop-item" onClick={handleInfo}>
					<ProfileMan />
					<span>My Profile</span>
				</div>
			)}
			{/* <div className="header-admin-drop-item">
				<BlogIcon />
				<span>Blog</span>
			</div> */}

			<div className="header-admin-drop-item red" onClick={handleLogout}>
				<LogoutIcon />
				<span>Log out</span>
			</div>
		</div>
	);
};
