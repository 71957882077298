import { FC } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { CreateInvoiceForm } from './CreateInvoiceForm/CreateInvoiceForm';
import { ICreateInvoicePopup } from './types';

export const CreateInvoicePopup: FC<ICreateInvoicePopup> = ({ isOpened, closePopup, ...rest }) => {
	const _rest = {
		closePopup,
		...rest,
	};
	return (
		<PopupModal open={isOpened} onClose={closePopup}>
			<div className="popup popup--width-460 invoice-popup">
				<button aria-label="button" type="button" className="popup__close" onClick={closePopup}>
					<PopUpCloseIcon />
				</button>
				<CreateInvoiceForm {..._rest} />
			</div>
		</PopupModal>
	);
};
