import { IStoreState } from 'redux/types';

export const getAdministration = (state: IStoreState) => state.administration;

export const getTeamMembers = (state: IStoreState) => getAdministration(state).teamMembers;
export const getTeamMembersLoading = (state: IStoreState) =>
	getAdministration(state).teamMembersLoading;

export const getRoles = (state: IStoreState) => getAdministration(state).roles;
// export const getWritableRoles = createSelector([getRoles], (roles) => {
// 	if (!roles) return null;
// 	return roles?.filter(({ code }) => code !== 'PROVIDER_SUPER_ADMIN');
// });
export const getRolesLoading = (state: IStoreState) => getAdministration(state).rolesLoading;

export const getUserProfile = (state: IStoreState) => getAdministration(state).userProfile;
export const getUserProfileLoading = (state: IStoreState) =>
	getAdministration(state).userProfileLoading;

export const getActionsLoading = (state: IStoreState) => getAdministration(state).actionsLoading;
export const postToggleBlock = (state: IStoreState) =>
	getAdministration(state).providerToggleBlockLoading;
