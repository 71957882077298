import { IStoreState } from 'redux/types';

export const getMerchantProfile = (state: IStoreState) => state.merchantProviderProfile;

export const getProfile = (state: IStoreState) => {
	return getMerchantProfile(state).merchantProviderProfile;
};
export const getProfileLoading = (state: IStoreState) => {
	return getMerchantProfile(state).merchantProviderProfileLoading;
};
export const getProfileToggleCheckedLoading = (state: IStoreState) => {
	return getMerchantProfile(state).editMerchantProviderProfileToggleChecked;
};
export const getProfileToggleLoading = (state: IStoreState) => {
	return getMerchantProfile(state).editMerchantProviderProfileToggleLoading;
};
