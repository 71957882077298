import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';

import {
	getProviderWalletsBalancesRequest,
	getProviderWalletsBalancesSuccess,
	putProviderWalletsBalancesRequest,
	putProviderWalletsBalancesSuccess,
	postProviderCreateWithdrawalRequest,
} from './reducer';

import { ICreateWithdrawalRequest } from './types';

function* getProviderWalletsBalancesRequestWorker({ payload }: PayloadAction<any>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: any[] = yield call(api.provider.getMerchantProviderAllCurrencySetting, apiParams);
		onSuccess?.();
		yield put(getProviderWalletsBalancesSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* putProviderWalletsBalancesRequestWorker({ payload }: PayloadAction<any>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: any[] = yield call(api.provider.putMerchantProviderAllCurrencySetting, apiParams);
		onSuccess?.();
		yield put(putProviderWalletsBalancesSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* postProviderCreateWithdrawalWorker({ payload }: PayloadAction<ICreateWithdrawalRequest>) {
	const { payload: Payload, onSuccess, onError } = payload;
	try {
		yield call(api.provider.postProviderCreateFiatWithdraw, Payload);
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

export function* providerWalletsSaga() {
	yield takeLatest(getProviderWalletsBalancesRequest, getProviderWalletsBalancesRequestWorker);
	yield takeLatest(putProviderWalletsBalancesRequest, putProviderWalletsBalancesRequestWorker);
	yield takeLatest(postProviderCreateWithdrawalRequest, postProviderCreateWithdrawalWorker);
}
