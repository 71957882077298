import { FC } from 'react';

export const Header: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Type</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Quantity</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Amount</p>
					</div>
				</div>
			</div>
		</div>
	);
};
