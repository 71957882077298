import ProjectClientEdit from 'v2Pages/Project/ProjectClients/ProjectClientEdit/ProjectClientEdit';
import Main from 'v2Components/layoutsComponents/Main';
import { routes } from 'routes/routes';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { Link } from 'react-router-dom';
import { useProjectMatchUrl } from 'hooks';

export const ProjectClientsEditPage = () => {
	const projectUrl = useProjectMatchUrl();
	return (
		<Main
			header={
				<Link to={`${projectUrl}${routes.oneProject.client}`}>
					<button type="button" className="back-page">
						<ArrowLeftIcon />
						<span>Back to clients</span>
					</button>
				</Link>
			}
		>
			<ProjectClientEdit />
		</Main>
	);
};
