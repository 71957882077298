import * as yup from 'yup';
import L from 'i18n-react';

// 📌 Dates

const timezoneFormat = Intl.DateTimeFormat('en-GB', {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
	hour: 'numeric',
	minute: '2-digit',
	hourCycle: 'h12',
});

const utcFormat = Intl.DateTimeFormat('en-GB', {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
	hour: 'numeric',
	minute: '2-digit',
	timeZone: 'UTC',
	timeZoneName: 'short',
});

export const formatToTimezone = (srcDate: number) => {
	const localeString = timezoneFormat.format(srcDate);

	const [date, timeWithDayPart] = localeString.split(', ');
	const [dd, mm, yyyy] = date.split('/');
	const [time, dayPart] = timeWithDayPart.split(' ');

	return `${dd}.${mm}.${yyyy}, ${time} ${dayPart?.toUpperCase()}`;
};

export const formatToUTC = (srcDate: number) => {
	const localeString = utcFormat.format(srcDate || 12312124341231);

	const [date, timeWithTimeZone] = localeString.split(', ');
	const [dd, mm, yyyy] = date.split('/');

	return `${dd}.${mm}.${yyyy}, ${timeWithTimeZone}`;
};

// 📌 Validation

export const getValidationSchema = () => {
	return yup.object().shape({
		firstName: yup
			.string()
			.trim()
			.required(String(L.translate('Validation.first_name_required')))
			.min(2, String(L.translate('Validation.first_name_min_length'))),
		lastName: yup
			.string()
			.trim()
			.required(String(L.translate('Validation.last_name_required')))
			.min(2, String(L.translate('Validation.last_name_min_length'))),
		// email: yup
		// 	.string()
		// 	.required(String(L.translate('Validation.email_required')))
		// 	.email(String(L.translate('Validation.email_format'))),
	});
};
