import TransactionDetails from 'components/Project/ProjectTransactions/TabsContent/TransactionDetails/TransactionDetails';
import { routes } from 'routes/routes';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from 'assets/inline-svg';
import Main from 'v2Components/layoutsComponents/Main';

export const ProviderTransactionsDetailsPage = () => {
	return (
		<Main
			header={
				<Link to={routes.oneProject.transactionsBase} className="back-page">
					<ArrowLeftIcon />
					<span>Back to Transactions</span>
				</Link>
			}
		>
			<TransactionDetails isProject />
		</Main>
	);
};
