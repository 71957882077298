import * as yup from 'yup';
import L from 'i18n-react';

// 📌 Validation

export const getValidationSchema = () => {
	return yup.object().shape({
		role: yup
			.object()
			.nullable()
			.required(String(L.translate('Validation.role_required'))),
		project: yup
			.object()
			.nullable()
			.required(String(L.translate('Validation.project_required'))),
	});
};
