import { useSelector } from 'react-redux';
import { getProviderCurrencyAllNoSettings } from 'redux/reducers/providerCurrency/selectors';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { DatepickerField } from 'v2Components/formComponents/DatepickerField';
import { TApiGetProviderAllCurrencyResponse } from 'services/api/provider/types';
import { SelectFieldCurrency } from 'UI/SelectFieldCurrency/SelectFieldCurrency';
import { TextField } from 'v2Components/formComponents/TextField';
import { statusOptions } from '../InvoicesTable/data';
import styles from './styles.module.scss';

interface IProps {
	filterProps: any;
}
export default ({ filterProps }: IProps) => {
	const currencies = useSelector(getProviderCurrencyAllNoSettings);
	const paidCurrencyArray = currencies.map((item: TApiGetProviderAllCurrencyResponse) => ({
		value: item.code,
		label: item.code,
	}));

	return (
		<>
			<SelectField title="Status" options={statusOptions} className={styles.mb_0} name="status" />
			<SelectFieldCurrency fieldName="currency_request" valueProp="code" />
			<SelectField
				name="currency_paid"
				className={styles.mb_0}
				title="Currency Paid"
				options={[
					{
						value: 'all',
						label: 'All',
					},
					...paidCurrencyArray,
				]}
			/>
			<TextField
				name="order_id"
				label="Order ID"
				placeholder="Select"
				classNameCustom={styles.mb_0}
			/>
			<DatepickerField
				label="Date from"
				maxDate={(filterProps.dateFrom as Date) || null}
				name="date_from"
				inFilter
				classNameCustom={styles.mb_0}
			/>
			<DatepickerField
				label="Date to"
				minDate={(filterProps.dateFrom as Date) || null}
				name="date_to"
				classNameCustom={styles.mb_0}
				inFilter
			/>
		</>
	);
};
