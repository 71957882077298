import { FC } from 'react';
import L from 'i18n-react';
import { TToastComponentProps } from './types';

export const ToastComponent: FC<TToastComponentProps> = ({ message, toastProps }) => {
	const title = String(L.translate(`ToastComponent.title.${toastProps.type}`, { notFound: '' }));

	return (
		<div className="notification__info">
			{title && (
				<div className="notification__title">
					<p>{title}</p>
				</div>
			)}

			<div className="notification__text">
				<p>{message}</p>
			</div>
		</div>
	);
};
