import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { Header } from 'v2Pages/Provider/AdministrationPage/Header/Header';
import { routes } from 'routes/routes';
import TeamMembers from 'v2Pages/Provider/AdministrationPage/TeamMembers/TeamMembers';
import { Roles } from 'v2Pages/Provider/AdministrationPage/Roles/Roles';
import { Tabs } from 'v2Components/commomComponents/Tabs/Tabs';
import Main from 'v2Components/layoutsComponents/Main';

export const AdministrationPage = () => {
	const { path } = useRouteMatch();
	const listContent = [
		{ link: `team-members`, value: 'User management' },
		{ link: `roles`, value: 'Roles' },
	];
	return (
		<Main header={<Header />}>
			<Tabs listContent={listContent} />
			<Switch>
				<Route exact path={`${path}${routes.administration.teamMembers}`} component={TeamMembers} />
				<Route exact path={`${path}${routes.administration.roles}`} component={Roles} />
				<Redirect to={`${path}${routes.administration.teamMembers}`} />
			</Switch>
		</Main>
	);
};
