import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	getMerchantProfileRequest,
	getProviderMerchantProfileRequest,
	getProviderMerchantProfileSuccess,
	postProviderMerchantProfileRequest,
	postProviderMerchantProfileSuccess,
	postProviderMerchantProfileToggleRequest,
	postProviderMerchantProfileToggleSuccess,
} from './reducer';
import {
	IProviderMerchantProfilePayload,
	IProviderMerchantProfilePostPayload,
	IProviderMerchantProfilePostResponse,
	IProviderMerchantProfileResponse,
	IProviderMerchantProfileToggleIdPayload,
} from './types';
import { notify } from '../../../utils/notify';

function* getMerchantProviderProfileWorker({
	payload,
}: PayloadAction<IProviderMerchantProfilePayload>) {
	try {
		const data: IProviderMerchantProfileResponse = yield call(
			api.provider.getMerchantProviderProfile,
			payload,
		);
		yield put(getProviderMerchantProfileSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* getMerchantProfileWorker({ payload }: PayloadAction<IProviderMerchantProfilePayload>) {
	try {
		const data: IProviderMerchantProfileResponse = yield call(
			api.provider.getMerchantProviderProfile,
			payload,
		);
		yield put(getProviderMerchantProfileSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* postMerchantProviderProfileWorker({
	payload,
}: PayloadAction<IProviderMerchantProfilePostPayload>) {
	try {
		const data: IProviderMerchantProfilePostResponse = yield call(
			api.provider.postMerchantProviderProfile,
			payload,
		);
		notify('Account was edited successfully', 'success');
		yield put(postProviderMerchantProfileSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* postMerchantProviderProfileToggleWorker({
	payload,
}: PayloadAction<IProviderMerchantProfileToggleIdPayload>) {
	try {
		yield call(api.provider.postMerchantProviderToggleProfile, payload);
		yield put(postProviderMerchantProfileToggleSuccess());
	} catch (error) {
		showError(error);
	}
}

export function* getMerchantProviderProfileSaga() {
	yield takeLatest(getProviderMerchantProfileRequest, getMerchantProviderProfileWorker);
	yield takeLatest(getMerchantProfileRequest, getMerchantProfileWorker);
	yield takeLatest(postProviderMerchantProfileRequest, postMerchantProviderProfileWorker);
	yield takeLatest(
		postProviderMerchantProfileToggleRequest,
		postMerchantProviderProfileToggleWorker,
	);
}
