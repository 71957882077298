import { IStoreState } from 'redux/types';
import { IMerchantResponse } from './types';

export const getMerchantApprovalManagement = (state: IStoreState) =>
	state.merchantApprovalManagement;

export const getMerchantApprovalManagementList = (state: IStoreState) => {
	return getMerchantApprovalManagement(state).merchantApprovalManagement;
};

export const getMerchantListLoader = (state: IStoreState) => {
	return getMerchantApprovalManagement(state).merchantApprovalListLoader;
};

export const getMerchantApprovalUserProfile = (state: IStoreState) => {
	return getMerchantApprovalManagement(state).userProfile;
};

export const getMerchantApprovalUserMyProfile = (state: IStoreState) => {
	return getMerchantApprovalManagement(state).userProfile;
};

export const getMerchantApprovalUserProfileLoading = (state: IStoreState) => {
	return getMerchantApprovalManagement(state).userProfileLoading;
};

export const getMerchantsList = (state: IStoreState) => {
	return getMerchantApprovalManagement(state).merchantList as Array<IMerchantResponse>;
};
