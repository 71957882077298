import { FC, useState } from 'react';
import { AuthLayout } from 'layouts/AuthLayout/AuthLayout';
import { AccountCreateSuccess } from 'components/Auth/AccountCreateSuccess/AccountCreateSuccess';
import { CreatePassword } from 'components/Auth/CreatePassword/CreatePassword';
import { TFirstLoginStep } from './types';

export const FirstLoginPage: FC = () => {
	const [step, setStep] = useState<TFirstLoginStep>('create-password');

	return (
		<AuthLayout title="Welcome">
			{step === 'create-password' && (
				<CreatePassword onSuccess={() => setStep('account-create-success')} />
			)}
			{step === 'account-create-success' && <AccountCreateSuccess />}
		</AuthLayout>
	);
};
