import { FC } from 'react';
import { Link } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import { routes } from 'routes/routes';
import { MagnifierIcon, PlusIcon } from 'assets/inline-svg';
import { IHeaderProps } from './types';
import styles from './styles.module.scss';

export const Header: FC<IHeaderProps> = ({ search, onSearchDebounce }) => {
	return (
		<div className={styles.header}>
			<div className="content-header__actions">
				<div className="content-search content-search--width-390">
					<div className="input input--mb-0">
						<div className="input-wrapper">
							{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
							{/* @ts-ignore */}
							<DebounceInput
								className="input-item input-item--right-icon"
								placeholder="Index by ID, Email or company name"
								value={search}
								onChange={(e) => onSearchDebounce(e.target.value)}
								debounceTimeout={400}
								// minLength={2}
							/>
							<span className="input-icon input-icon--right">
								<MagnifierIcon />
							</span>
						</div>
					</div>
				</div>

				<Link to={routes.createMerchant} className="button">
					<span className="btn-icon">
						<PlusIcon />
					</span>
					Create merchant account
				</Link>
			</div>
		</div>
	);
};
