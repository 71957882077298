import { IStoreState } from 'redux/types';

export const getProjectTransactionsState = (state: IStoreState) => state.projectTransactions;

export const getProjectTransactions = (state: IStoreState) =>
	getProjectTransactionsState(state).projectTransactions;

export const getProjectMerchantTransaction = (state: IStoreState) =>
	getProjectTransactionsState(state).projectMerchantTransactions;

export const getProjectTransactionsApprovements = (state: IStoreState) =>
	getProjectTransactionsState(state).projectTransactionsApprovements;

export const getViewApprovement = (state: IStoreState) =>
	getProjectTransactionsState(state).viewApprovement;

export const getTransactionsLoading = (state: IStoreState) =>
	getProjectTransactionsState(state).transactionsLoading;

export const getTransactionsFilter = (state: IStoreState) =>
	getProjectTransactionsState(state).transactionsFilter;
export const getTransactionsFiatFilter = (state: IStoreState) =>
	getProjectTransactionsState(state).transactionsFiatFilter;
export const getTransactionFiat = (state: IStoreState) =>
	getProjectTransactionsState(state).transactionsFiat;
export const getTransactionsFiatLoader = (state: IStoreState) =>
	getProjectTransactionsState(state).transactionsFiatLoading;
