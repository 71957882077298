import { FC } from 'react';
import { DebounceInput } from 'react-debounce-input';
import classNames from 'classnames';
import { Floating } from 'v2Components/UI/Floating';
import { IFieldProps } from './types';
import styles from './styles.module.scss';
import { conditionalStyles } from './field.helper';

const ErrorMessage: FC<{ errorMsg: string | undefined }> = ({ errorMsg }) => (
	<p className={classNames(styles.input__notification, styles['input__notification--error'])}>
		{errorMsg}
	</p>
);

const Field: FC<IFieldProps> = ({
	classNameInputItem,
	classNameCustom,
	isDebounce,
	label,
	renderRightIcon,
	widthInput,
	subDescription,
	rightAdornment,
	customErrorMsg,
	isShowError,
	error,
	...rest
}): JSX.Element => {
	const conditionalClasses = conditionalStyles({ widthInput });

	const renderErrorMsg = () => {
		if (customErrorMsg) {
			return <ErrorMessage errorMsg={customErrorMsg} />;
		}
		return isShowError && <ErrorMessage errorMsg={error} />;
	};

	const renderInput = (
		<Floating label={label}>
			{!(isDebounce || renderRightIcon) ? (
				<input
					className={classNames(styles['input-item'], classNameInputItem, conditionalClasses)}
					{...rest}
				/>
			) : (
				<div className={classNames(styles['input-wrapper'], classNameCustom, conditionalClasses)}>
					{isDebounce ? (
						<DebounceInput
							className={classNames(
								styles['input-item'],
								styles['input-item--right-icon'],
								classNameInputItem,
							)}
							debounceTimeout={500}
							onChange={rest.onChange || (() => {})}
							{...rest}
						/>
					) : (
						<input
							className={classNames(
								styles['input-item'],
								styles['input-item--right-icon'],
								classNameInputItem,
							)}
							{...rest}
						/>
					)}

					{renderRightIcon && (
						<div className={classNames(styles['input-icon'], styles['input-icon--right'])}>
							{renderRightIcon}
						</div>
					)}
				</div>
			)}
		</Floating>
	);

	return (
		<div
			className={classNames(
				styles.input,
				{
					[styles['input--error']]: customErrorMsg || isShowError,
					...conditionalClasses,
				},
				classNameCustom,
			)}
		>
			<div className={styles['input-wrapper']}>
				{renderInput}
				{!!rightAdornment && <div className={styles['input-text']}>{rightAdornment}</div>}
			</div>
			{subDescription ? (
				<div className={styles['input-description']}>
					<p>{subDescription}</p>
				</div>
			) : (
				''
			)}

			{renderErrorMsg()}
		</div>
	);
};

export default Field;
