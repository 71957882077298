import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import { useEffect, useState } from 'react';
import Main from 'v2Components/layoutsComponents/Main';
import { CryptoTable } from 'components/Project/ProjectTransactions/TabsContent/Transactions/CryptoTable/CryptoTable';
import { FiatTable } from 'components/Project/ProjectTransactions/TabsContent/Transactions/FiatTable/FiatTable';
import { endpoint } from 'services/endpoint';
import { useParams } from 'react-router-dom';
import WalletHeader from '../../Provider/WalletsPage/components/WalletsHeader';
import { TCurrency } from '../../Provider/TransactionsPage/Transactions/CryptoFiatSwitch/types';
import { getCreateReport } from './report.helper';

export default () => {
	const url_string = window.location.href;
	const urlObg = new URL(url_string);
	const tabsUrl = urlObg.searchParams.get('tabs');
	const activeTab = tabsUrl || 'crypto';
	const [currency, setCurrency] = useState<TCurrency>((tabsUrl as TCurrency) || 'crypto');
	const isCrypto = currency === 'crypto';
	const { projectId, merchantId } = useParams<{ projectId: string; merchantId: string }>();
	useEffect(() => {
		if (activeTab === 'crypto' || activeTab === 'fiat') {
			setCurrency(activeTab);
		}
	}, [activeTab]);

	return (
		<Main
			header={
				<WalletHeader
					url={urlObg}
					title="Transactions"
					downloadReportUrl={
						activeTab === 'crypto'
							? endpoint.project.DOWNLOAD_CRYPTO_TRANSACTIONS_REPORT
							: endpoint.project.DOWNLOAD_FIAT_TRANSACTIONS_REPORT
					}
					getCreateReport={(params, tab, reportFormat) =>
						getCreateReport(
							{
								...params,
								isCrypto: activeTab === 'crypto',
								project_id: projectId,
								owner_merchant_id: merchantId,
							},
							tab,
							reportFormat,
						)
					}
					hideTitle
				/>
			}
		>
			<ProjectContent className="transactions">
				{isCrypto ? <CryptoTable /> : <FiatTable />}
			</ProjectContent>
		</Main>
	);
};
