import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Pagination } from 'UI/Pagination/Pagination';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { getProviderWalletsAddressesClientData } from 'redux/reducers/providerWalletAddresses/selectors';
import { IGetMerchantOwnersPayload } from 'redux/reducers/providerMerchant/types';
import { getProviderWalletAddressesClientRequest } from 'redux/reducers/providerWalletAddresses/reducer';
import { routes } from 'routes/routes';
import styles from './styles.module.scss';

const WalletAddressUser = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const walletAddresses = useSelector(getProviderWalletsAddressesClientData);
	const url_string = window.location.href;
	const urlObg = new URL(url_string);
	const matchPath = `${urlObg.pathname.split('/')[urlObg.pathname.split('/').length - 1]}`;

	const [currentPage, setCurrentPage] = useState(1);
	const [firstLoading, setFirstLoading] = useState(true);
	const totalPages = walletAddresses?.last_page || 1;

	const getProviderWalletAddressesPayload: IGetMerchantOwnersPayload | null = useMemo(() => {
		if (!Number(matchPath)) {
			history.push('/wallet-addresses');
			return null;
		}
		return {
			apiParams: {
				current_page: currentPage,
				client_id: String(matchPath),
			},

			onFinally: () => setFirstLoading(false),
		};
	}, [currentPage, matchPath, history]);

	useEffect(() => {
		dispatch(getProviderWalletAddressesClientRequest(getProviderWalletAddressesPayload));
	}, [dispatch, getProviderWalletAddressesPayload]);

	return (
		<div className={styles.main__wrapper}>
			<Link to={routes.walletAddressBase} className="back-page">
				<ArrowLeftIcon />
				<span>Back to Wallet addresses</span>
			</Link>
			<div className="content-header">
				<p className="form-title">Client ID: {matchPath}</p>
			</div>
			<div className={styles.table} />
			{!firstLoading && totalPages > 1 && (
				<div className="pagination-block">
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</div>
			)}
		</div>
	);
};

export default WalletAddressUser;
