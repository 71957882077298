import { IStoreState } from 'redux/types';

export const getProviderCurrency = (state: IStoreState) => state.providerCurrencySetting;
// getProviderCurrencyAllNoSettings -{{api_endpoint_core}}/provider/all-currency
export const getProviderCurrencyAllNoSettings = (state: IStoreState) =>
	getProviderCurrency(state).providerAllCurrency;
// getProviderCurrencyAll -{{api_endpoint_core}}/provider/all-currency-setting
export const getProviderCurrencyAll = (state: IStoreState) =>
	getProviderCurrency(state).providerAllCurrencySetting;
export const getProviderCurrencyCrypto = (state: IStoreState) =>
	getProviderCurrency(state).providerAllCurrencySetting?.filter(
		(item) => item?.currency?.is_crypto,
	);
export const getProviderCurrencyFiat = (state: IStoreState) =>
	getProviderCurrency(state).providerAllCurrencySetting?.filter(
		(item) => !item?.currency?.is_crypto,
	);
export const getProviderCurrencyAllLoading = (state: IStoreState) =>
	getProviderCurrency(state).providerAllCurrencySettingLoading;

export const getProviderTransactionLoading = (state: IStoreState) =>
	getProviderCurrency(state).putProviderTransactionLoading;

export const getProviderTransactions = (state: IStoreState) =>
	getProviderCurrency(state).providerAllTransaction;
export const getProviderDepositAll = (state: IStoreState) =>
	getProviderCurrency(state).getProviderDeposit;
export const getProviderProfit = (state: IStoreState) =>
	getProviderCurrency(state).providerAllProfit;
export const getProviderTransactionsFiat = (state: IStoreState) =>
	getProviderCurrency(state).putProviderTransactionFiat;
export const getProviderTransactionsFiatLoading = (state: IStoreState) =>
	getProviderCurrency(state).putProviderTransactionFiatLoading;

export const getCurrencyCryptoWalletsLoading = (state: IStoreState) =>
	getProviderCurrency(state).walletsByCurrencySettingsLoading;
export const getCurrencyCryptoWallets = (state: IStoreState) =>
	getProviderCurrency(state).walletsByCurrencySettings;
export const getTransactionHistoryDetails = (state: IStoreState) =>
	getProviderCurrency(state).transactionHistoryDetails;
