import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getUserProfile, getUserProfileLoading } from 'redux/reducers/administration/selectors';
import {
	editProviderProfileRequest,
	getUserProfileRequest,
} from 'redux/reducers/administration/reducer';
import { TUserProfileFormOnSubmit } from 'v2Pages/Provider/UserProfilePage/UserProfileForm/types';
import { IEditProviderProfilePayload } from 'redux/reducers/administration/types';
import { notify } from 'utils/notify';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { UserProfileForm } from 'v2Pages/Provider/UserProfilePage/UserProfileForm/UserProfileForm';
import { ProviderLayout } from 'layouts/ProviderLayout/ProviderLayout';
import styles from './styles.module.scss';

export const UserProfilePage = () => {
	const dispatch = useDispatch();
	const { userId } = useParams<{ userId: string }>();

	const user = useSelector(getUserProfile);
	const loading = useSelector(getUserProfileLoading);

	useLayoutEffect(() => {
		dispatch(getUserProfileRequest({ provider_id: userId }));
	}, [dispatch, userId]);

	const handleSubmit: TUserProfileFormOnSubmit = ({ fullName }) =>
		user &&
		dispatch(
			editProviderProfileRequest({
				apiParams: {
					id: user.provider.id,
					full_name: fullName.trim(),
				},
				onSuccess: () => notify('User profile changed successfully', 'success'),
			}),
		);

	return (
		<ProviderLayout title="Users" v2>
			<div className={styles.main}>
				<Link to="/administration" className="back-page">
					<ArrowLeftIcon />
					<span>Back to Administration</span>
				</Link>
				<p className={styles.title}>User profile</p>
				<div className={styles.main__wrapper}>
					{!loading && user && <UserProfileForm user={user} onSubmit={handleSubmit} />}
				</div>
			</div>
		</ProviderLayout>
	);
};
