import { useState } from 'react';
import TableBodyForm from '../TableBodyForm';
import TableRowContent from '../TableRowContent';

interface IProps {
	item: any;
	handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
	setItem: any;
}
export default ({ item, handleSubmit, setItem }: IProps) => {
	const [changeableMode, setChangeableMode] = useState<boolean>(false);
	const switchOffChangeableMode = () => setChangeableMode(false);
	const switchOnChangeableMode = () => {
		setItem(item);
		setChangeableMode(true);
	};
	const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
		switchOffChangeableMode();
		handleSubmit(e);
	};
	if (changeableMode) {
		return TableBodyForm({ item, onSubmit, switchOffChangeableMode });
	}
	return TableRowContent({ item, switchOnChangeableMode });
};
