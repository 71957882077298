import moment from 'moment';
import { IFilter } from 'redux/reducers/app/types';
import { TApiGetProviderAllCurrencyResponse } from 'services/api/provider/types';
import { IGetCurrencyNetworkItem } from 'services/api/public/types';
import { IFilterElemItem } from 'v2Components/layoutsComponents/Filter/types';
import { statusOptions } from './data';

export const transactionTypeOptions = [
	{ label: 'All', value: 'all' },
	{ label: 'Deposit', value: 'deposit' },
	{ label: 'Withdrawal', value: 'withdrawal' },
	{ label: 'Receive funds', value: 'receive_funds' },
	{ label: 'Send commission', value: 'send_commission' },
	{ label: 'Refund', value: 'refund' },
	{ label: 'Return', value: 'return' },
];

export interface TransactionsHistory {
	id: string;
	value: string | number | Date | null;
	networks?: IGetCurrencyNetworkItem[];
	currencies?: TApiGetProviderAllCurrencyResponse[];
}

export const convertRequestParams = (props: IFilter, tab: string) =>
	({
		...props,
		date_from: props.date_from || undefined,
		date_to: props.date_to || undefined,
		amount_from: props.amount_from ? +props.amount_from : undefined,
		amount_to: props.amount_to ? +props.amount_to : undefined,
		currency_name: props.currency_name,
		type: props.type || undefined,
		transaction_id: props.transaction_id ? +props.transaction_id : undefined,
		currency_network: props.currency_network,
		status: props.status,
		tx_hash: props.tx_hash,
		risk_score: props.risk_score ? +props.risk_score : undefined,
		owner_merchant_id: props.owner_merchant_id ? +props.owner_merchant_id : undefined,
		project_id: props.project_id ? +props.project_id : undefined,
	} as IFilter);

export const getFilterInvoices = ({
	id,
	value,
	currencies,
}: TransactionsHistory): IFilterElemItem => {
	switch (id) {
		case 'date_from':
			return { identify: id, content: `from: ${moment(value).format('DD MMM YY, hh:mm a')}` };
		case 'date_to':
			return { identify: id, content: `from: ${moment(value).format('DD MMM YY, hh:mm a')}` };
		case 'currency_request': {
			const content = currencies?.find((item) => item.code === value);
			return { identify: id, content: content?.name || '', label: content?.code };
		}
		case 'status': {
			const content = statusOptions.find((item) => item.value === value);
			return { identify: id, content: content?.label || '' };
		}
		case 'order_id':
		case 'currency_paid':
			return { identify: id, content: value as string };
		default:
			return { identify: '', content: '' };
	}
};
