import { FC } from 'react';
import { IActionsBlockProps } from './types';

// eslint-disable-next-line
export const ActionsBlock: FC<IActionsBlockProps> = ({ user }) => {
	return (
		<div className="buttons">
			<button type="button" className="button button--type3">
				Reset Password
			</button>
		</div>
	);
};
