import { FC, useEffect } from 'react';
import { Link, NavLink, useRouteMatch } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useProjectMatchUrl } from 'hooks';
import {
	ClientsIcon,
	GearIcon,
	TransactionsIcon,
	ReportIcon,
	CallbackIcon,
	WalletAddress,
	OrdersIcon,
	ArrowLeftIcon,
	AnalyticsIcon,
} from 'assets/inline-svg';
import { constUserTypes } from 'services/constants/user-types';
import {
	setTransactionsStateFilter,
	setTransactionsStateFiatFilter,
} from 'redux/reducers/projectTransactions/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRole, getUserType } from 'redux/reducers/auth/selectors';
import { rolesProject } from 'layouts-elements/ProviderSidebar/roles';
import { getStatusBurger } from 'redux/reducers/app/selectors';
import classNames from 'classnames';
import { getBurgerReducer } from 'redux/reducers/app/reducer';

export const ProjectSidebar: FC = () => {
	const statusBurger = useSelector(getStatusBurger);
	const dispatch = useDispatch();
	const userRole = useSelector(getUserRole);
	const userType = useSelector(getUserType);
	const projectUrl = useProjectMatchUrl();
	const settingsBaseMatch = useRouteMatch(
		`${routes.oneProjectBase}${routes.oneProject.settingsBase}`,
	);
	const transactionsBaseMatch = useRouteMatch(
		`${routes.oneProjectBase}${routes.oneProject.transactionsBase}`,
	);

	const transactionDatailsMatch = useRouteMatch(
		`${routes.oneProjectBase}${routes.oneProject.transaction.details}`,
	);
	const transactionApprovementDatailsMatch = useRouteMatch(
		`${routes.oneProjectBase}${routes.oneProject.approvement.details}`,
	);

	const invoicesBaseMatch = useRouteMatch(`${routes.oneProjectBase}${routes.oneProject.invoices}`);

	const comissionsBaseMatch = useRouteMatch(
		`${routes.oneProjectBase}${routes.oneProject.comissions}`,
	);

	const onBurger = () => {
		dispatch(getBurgerReducer(!statusBurger));
		window.scrollTo(0, 0);
	};

	const resetFilter = () => {
		dispatch(
			setTransactionsStateFilter({
				page: 1,
				dateFrom: undefined,
				dateTo: undefined,
				amountFrom: undefined,
				amountTo: undefined,
				currencyName: undefined,
				type: undefined,
				transactionId: undefined,
				status: undefined,
				riskScore: undefined,
			}),
		);
		dispatch(
			setTransactionsStateFiatFilter({
				page: 1,
				dateFrom: undefined,
				dateTo: undefined,
				amountFrom: undefined,
				amountTo: undefined,
				currencyName: undefined,
				type: undefined,
				transactionId: undefined,
				status: undefined,
				riskScore: undefined,
			}),
		);
	};

	useEffect(() => {
		if (!transactionsBaseMatch && !transactionDatailsMatch) {
			resetFilter();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactionsBaseMatch, transactionDatailsMatch]);

	return (
		<div className={classNames('sidebar', { 'sidebar-active': statusBurger })}>
			{userType === constUserTypes.MERCHANT && (
				<Link to={routes.merchantProjectList} className="back-page">
					<ArrowLeftIcon />
					<span>Back to projects</span>
				</Link>
			)}
			<nav className="sidebar-nav">
				<ul className="sidebar-nav__list">
					<li>
						<NavLink
							onClick={onBurger}
							to={`${projectUrl}${routes.oneProject.settingsBase}`}
							isActive={() => !!settingsBaseMatch}
						>
							<span className="sidebar-nav-icon">
								<GearIcon />
							</span>
							<span className="sidebar-nav-text">Project settings</span>
						</NavLink>
					</li>
					<li>
						<NavLink
							onClick={onBurger}
							to={`${projectUrl}${routes.oneProject.transactions.transactions}${routes.oneProject.transactions.transactions}?type=crypto`}
							isActive={() =>
								!!transactionsBaseMatch ||
								!!transactionDatailsMatch ||
								!!transactionApprovementDatailsMatch
							}
						>
							<span className="sidebar-nav-icon">
								<TransactionsIcon />
							</span>
							<span className="sidebar-nav-text">Transactions</span>
						</NavLink>
					</li>

					{rolesProject.accounts.includes(userRole || '') && (
						<li>
							<NavLink
								onClick={onBurger}
								to={`${projectUrl}${routes.oneProject.accounts}${routes.oneProject.accountsTabs.accounts}${routes.oneProject.accountsTabs.crypto}`}
								isActive={() => window.location.pathname.includes(routes.oneProject.accounts)}
							>
								<span className="sidebar-nav-icon">
									<ClientsIcon />
								</span>
								<span className="sidebar-nav-text">Project wallets</span>
							</NavLink>
						</li>
					)}

					<li>
						<NavLink
							onClick={onBurger}
							// isActive={(match, location) => {
							// 	return (
							// 		location.pathname === '/merchant-client/client' ||
							// 		location.pathname === '/merchant-client/client/2'
							// 	);
							// }}
							to={`${projectUrl}${routes.oneProject.client}`}
						>
							<span className="sidebar-nav-icon">
								<ClientsIcon />
							</span>
							<span className="sidebar-nav-text">Clients</span>
						</NavLink>
					</li>

					<li>
						<NavLink onClick={onBurger} to={`${projectUrl}${routes.oneProject.callbacksBase}`}>
							<span className="sidebar-nav-icon">
								<CallbackIcon />
							</span>
							<span className="sidebar-nav-text">Callbacks</span>
						</NavLink>
					</li>

					<li>
						<NavLink onClick={onBurger} to={`${projectUrl}${routes.oneProject.reportsBase}`}>
							<span className="sidebar-nav-icon">
								<ReportIcon />
							</span>
							<span className="sidebar-nav-text">Reports</span>
						</NavLink>
					</li>
					{rolesProject.analytics.includes(userRole || '') && (
						<li>
							<NavLink
								onClick={onBurger}
								to={`${projectUrl}${String(routes.oneProject.analytics)}`}
							>
								<span className="sidebar-nav-icon">
									<AnalyticsIcon />
								</span>
								<span className="sidebar-nav-text">Analytics</span>
							</NavLink>
						</li>
					)}
					<li>
						<NavLink
							onClick={onBurger}
							to={`${projectUrl}${routes.oneProject.walletProjectAddressBase}`}
						>
							<span className="sidebar-nav-icon">
								<WalletAddress />
							</span>
							<span className="sidebar-nav-text">Wallet addresses</span>
						</NavLink>
					</li>
					<li>
						<NavLink
							onClick={onBurger}
							to={`${projectUrl}${routes.oneProject.invoices}`}
							isActive={() => !!invoicesBaseMatch}
						>
							<span className="sidebar-nav-icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 1V23"
										stroke="#4285F5"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M17 5H9.5C8.57174 5 7.6815 5.36875 7.02513 6.02513C6.36875 6.6815 6 7.57174 6 8.5C6 9.42826 6.36875 10.3185 7.02513 10.9749C7.6815 11.6313 8.57174 12 9.5 12H14.5C15.4283 12 16.3185 12.3687 16.9749 13.0251C17.6313 13.6815 18 14.5717 18 15.5C18 16.4283 17.6313 17.3185 16.9749 17.9749C16.3185 18.6313 15.4283 19 14.5 19H6"
										stroke="#4285F5"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							<span className="sidebar-nav-text">Invoices</span>
						</NavLink>
					</li>

					<li>
						<NavLink
							onClick={onBurger}
							to={`${projectUrl}${routes.oneProject.comissions}`}
							isActive={() => !!comissionsBaseMatch}
						>
							<span className="sidebar-nav-icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M19 5L5 19"
										stroke="#4285F5"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M6.5 9C7.88071 9 9 7.88071 9 6.5C9 5.11929 7.88071 4 6.5 4C5.11929 4 4 5.11929 4 6.5C4 7.88071 5.11929 9 6.5 9Z"
										stroke="#4285F5"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M17.5 20C18.8807 20 20 18.8807 20 17.5C20 16.1193 18.8807 15 17.5 15C16.1193 15 15 16.1193 15 17.5C15 18.8807 16.1193 20 17.5 20Z"
										stroke="#4285F5"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							<span className="sidebar-nav-text">Comissions</span>
						</NavLink>
					</li>

					{/* <li>
						<NavLink to="/">
							<span className="sidebar-nav-icon">
								<svg
									width="24"
									height="20"
									viewBox="0 0 24 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M17 19V17C17 15.9391 16.5786 14.9217 15.8284 14.1716C15.0783 13.4214 14.0609 13 13 13H5C3.93913 13 2.92172 13.4214 2.17157 14.1716C1.42143 14.9217 1 15.9391 1 17V19"
										stroke="#4285F5"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 7.20914 6.79086 9 9 9Z"
										stroke="#4285F5"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M23 19.0028V17.0028C22.9993 16.1165 22.7044 15.2556 22.1614 14.5551C21.6184 13.8547 20.8581 13.3544 20 13.1328"
										stroke="#4285F5"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M16 1.13281C16.8604 1.35311 17.623 1.85351 18.1676 2.55512C18.7122 3.25673 19.0078 4.11964 19.0078 5.00781C19.0078 5.89598 18.7122 6.75889 18.1676 7.4605C17.623 8.16211 16.8604 8.66251 16 8.88281"
										stroke="#4285F5"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							<span className="sidebar-nav-text">Clients</span>
						</NavLink>
					</li> */}

					<li>
						<NavLink onClick={onBurger} to={`${projectUrl}${routes.oneProject.orders}`}>
							<span className="sidebar-nav-icon">
								<OrdersIcon />
							</span>
							<span className="sidebar-nav-text">Orders</span>
						</NavLink>
					</li>

					{/* {userType === constUserTypes.PROVIDER &&
						rolesProject.finance_settings.includes(userRole || '') && (
							<li>
								<NavLink onClick={onBurger} to={`${projectUrl}${routes.oneProject.financeBase}`}>
									<span className="sidebar-nav-icon">
										<GearIcon />
									</span>
									<span className="sidebar-nav-text">Finance settings</span>
								</NavLink>
							</li>
						)} */}
				</ul>
			</nav>
		</div>
	);
};
