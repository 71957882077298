import { FC } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { TextField } from 'UI/Forms/TextField/TextField';
import { ICallbackUrlFormProps } from './types';

export const CallbackUrlForm: FC<ICallbackUrlFormProps> = ({ initValues, onSubmit }) => {
	const validationSchema = yup.object().shape({
		callbackUrl: yup.string().url('Please enter valid URL'),
	});

	return (
		<Formik
			initialValues={initValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({ dirty }) => (
				<Form className="api-item">
					<p className="block-title block-title--mb8">Callback URL</p>
					<p className="block-text block-text--mb24">
						We will send instant payment notifications to this URL
					</p>

					<div className="api-item-wrapper api-item-wrapper--callback-url">
						<TextField
							name="callbackUrl"
							label="Callback URL"
							placeholder="URL"
							classNameCustom="input--no-mb"
						/>

						<button className="button button--callback-url" type="submit" disabled={!dirty}>
							Save
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};
