import { PopUpCloseIcon } from 'assets/inline-svg';
import { ChangeEvent, FC, useState } from 'react';

interface IProps {
	onClose: () => void;
	onCreateComment: (value: string) => void;
}

export const ModalComment: FC<IProps> = ({ onClose, onCreateComment }) => {
	const [comment, setComment] = useState<string>('');

	const handleText = (event: ChangeEvent) => {
		const target = event.target as HTMLTextAreaElement;

		setComment(target.value);
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-460">
					<button aria-label="button" onClick={onClose} type="button" className="popup__close">
						<PopUpCloseIcon />
					</button>
					<div className="popup-header">
						<p className="popup-header__title popup-header__title--ta-l popup-header__title--mb-32">
							Add comment
						</p>
					</div>

					<div className="popup-body">
						<div className="input">
							<p className="input-name">Comment</p>
							<div className="input-wrapper">
								<div className="textarea">
									<textarea value={comment} onChange={handleText} placeholder="Enter text" />
								</div>
							</div>
						</div>
					</div>
					<div className="popup-button popup-button--type2">
						<button
							onClick={onClose}
							type="button"
							className="button button--full-width button--type2"
						>
							Cancel
						</button>
						<button
							disabled={!comment.trim()}
							onClick={() => onCreateComment(comment)}
							type="button"
							className="button button--full-width"
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
