/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IMerchantGetProjectReportsPayload,
	IGetReportsResponse,
	IPostReportsRequset,
	IProviderReportsAnalyticsStore,
	IReportProviderTransactionPayload,
} from 'redux/reducers/providerReportsAnalytics/types';

export const initialState: IProviderReportsAnalyticsStore = {
	reports: null,
	reportsLoading: false,
	viewCallback: null,
	viewCallbackLoading: false,
	exportCSVLoading: false,
	createReportTransactionProvider: false,
};
const providerReportsAnalytics = createSlice({
	name: '@@providerReportsAnalytics',
	initialState,
	reducers: {
		getReportsRequest: (state, action: PayloadAction<IMerchantGetProjectReportsPayload>) => {
			state.reportsLoading = true;
		},
		getReportsSuccess: (state, { payload }: PayloadAction<IGetReportsResponse>) => {
			state.reports = payload;
			state.reportsLoading = false;
		},
		getReportsUpdateRequest: (state, action: PayloadAction<IMerchantGetProjectReportsPayload>) => {
			state.reportsLoading = true;
		},
		getReportsError: (state) => {
			state.reports = null;
			state.reportsLoading = false;
		},
		createReportRequset: (state, action: PayloadAction<IPostReportsRequset>) => {},
		createReportTransactionProviderRequest: (
			state,
			action: PayloadAction<IReportProviderTransactionPayload>,
		) => {
			state.createReportTransactionProvider = true;
		},
		createReportTransactionProviderSuccess: (state) => {
			state.createReportTransactionProvider = false;
		},
	},
});
export default providerReportsAnalytics.reducer;
export const {
	getReportsRequest,
	getReportsSuccess,
	getReportsError,
	createReportRequset,
	getReportsUpdateRequest,
	createReportTransactionProviderRequest,
	createReportTransactionProviderSuccess,
} = providerReportsAnalytics.actions;
