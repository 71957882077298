import { Redirect, useRouteMatch } from 'react-router';
import { Tabs } from 'v2Components/commomComponents/Tabs/Tabs';
import { useEffect, useMemo } from 'react';
import {
	getProviderLiqudityRequest,
	getProviderTabsLiqudityRequest,
} from 'redux/reducers/providerLiquidity/reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
	getLiquidityList,
	getLiquidityListLoading,
	getProviderTabsLiqudityWorker,
	getProviderTabsLiqudityWorkerLoading,
} from 'redux/reducers/providerLiquidity/selectors';
import Main from 'v2Components/layoutsComponents/Main';
import Table from 'v2Components/commomComponents/Table';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { ILiquidity, ILiquidityTab } from 'redux/reducers/providerLiquidity/types';
import Loading from 'UI/Loading';
import TableRow from './TableRow';

const LIQUIDITY = '/liquidity';
export const LiquidityPage = () => {
	const dispatch = useDispatch();
	const { path } = useRouteMatch();
	const tabs = useSelector(getProviderTabsLiqudityWorker);
	const data = useSelector(getLiquidityList);
	const loading = useSelector(getLiquidityListLoading);
	const loadingTabs = useSelector(getProviderTabsLiqudityWorkerLoading);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('Liquidity');
	const url = new URL(window.location.href);
	const listContent = useMemo(
		() =>
			tabs?.map((tab: ILiquidityTab) => ({
				value: tab.name,
				link: tab.name,
			})) || [],
		[tabs],
	);

	const currentTab = useMemo(() => {
		const words = url.pathname.split('/');
		return words?.length ? words[words.length - 1] : null;
	}, [url.pathname]);

	useEffect(() => {
		if (currentTab && currentTab !== 'liquidity') {
			dispatch(
				getProviderLiqudityRequest({
					platform_name: currentTab,
				}),
			);
		}
	}, [currentTab, dispatch]);

	useEffect(() => {
		!tabs?.length && dispatch(getProviderTabsLiqudityRequest());
	}, [dispatch, tabs]);

	if (loadingTabs) {
		return (
			<Main>
				<Loading />
			</Main>
		);
	}

	if (url.pathname === LIQUIDITY && tabs?.length) {
		return <Redirect to={`${path}/${tabs[0].name}`} />;
	}

	return (
		<Main>
			<Tabs listContent={listContent} />
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={data}
				getRow={(item: ILiquidity) => TableRow({ item })}
				gridTemplateColumns={gridTemplateColumns}
			/>
		</Main>
	);
};
