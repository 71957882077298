import { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import { RoleSelect } from 'UI/RoleSelect/RoleSelect';
import { IApiGetRolesItem } from 'services/api/provider/types';
import ErrorMsg from 'UI/ErrorMsg';
import { IRolesFieldProps } from './types';

export const RoleField: FC<IRolesFieldProps> = ({ setShow, custom, roles, text = 'Role' }) => {
	const [field, meta, helpers] = useField<IApiGetRolesItem | null>('role');
	const isShowError = meta.touched && meta.error;

	const setTouchedTrue = useCallback(() => {
		helpers.setTouched(true);
		setShow?.(false);
	}, []); //eslint-disable-line

	return (
		<div className={classNames('select-block', { 'select-block--error': isShowError })}>
			<p className="select-block__name">{text}</p>

			<RoleSelect
				roles={roles}
				active={field.value}
				onChange={(newRole) => helpers.setValue(newRole)}
				onClose={setTouchedTrue}
			/>
			{custom ? <ErrorMsg msg="Choose the right field" /> : null}

			{isShowError && <p className="input-notification input-notification--error">{meta.error}</p>}
		</div>
	);
};
