import { FC } from 'react';
import { TriangleArrowDownIcon2, CheckIcon } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { ILiquidity } from 'redux/reducers/providerLiquidity/types';
import { IProps } from './types';

export const TypeSelect: FC<IProps> = ({ onChange, selected: item, options }) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (newItem: ILiquidity) => {
		onChange(newItem);
		setOpen(false);
	};

	return (
		<div className={`select select--type-choice ${open ? 'active' : ''}`}>
			<button
				type="button"
				className="select__current select__current--placeholder"
				ref={triggerRef}
				onClick={toggleOpen}
			>
				<span className="token">
					<span className="token__icon">
						<img src={item?.currency.img_path} alt="coin" />
					</span>
					<span className="token__name">{item?.currency.name}</span>
					<span className="token__transcription">
						{item?.currency_code.toUpperCase()}{' '}
						{item?.currency_code.toLowerCase() === item?.network.toLowerCase()
							? ''
							: `(${String(item?.network).toUpperCase()})`}
					</span>
				</span>
				<span className="select__current-arrow">
					<TriangleArrowDownIcon2 />
				</span>
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll select__drop-scroll--type2">
					<div className="select__drop-item">
						<ul>
							{options.map((e) => (
								<li key={`${String(e.provider_liquidity_id)}${String(e.network)}`}>
									<button
										type="button"
										className={
											item?.provider_liquidity_id === e.provider_liquidity_id &&
											item.network === e.network
												? 'active'
												: ''
										}
										onClick={() => handleSelect(e)}
									>
										<div className="token">
											<div className="token__icon">
												<img src={e.currency.img_path} alt="coin" />
											</div>
											<span className="token__name">{e.currency?.name}</span>
											<span className="token__transcription">
												{e.currency?.code?.toUpperCase()}{' '}
												{e.currency?.code?.toLowerCase() === e.network.toLowerCase()
													? ''
													: `(${String(e.network.toUpperCase())})`}
											</span>
										</div>
										<span>
											{item?.provider_liquidity_id === e.provider_liquidity_id &&
												item.network === e.network && <CheckIcon />}
										</span>
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
