import { PlusWhiteIcon } from 'assets/inline-svg';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

export const MerchantProjectsListHeader: FC = () => {
	return (
		<div className="content-header">
			<p className="form-title form-title--smaller">Project List</p>
			<Link to={routes.createMerchantProject} className="button">
				<span className="btn-icon">
					<PlusWhiteIcon />
				</span>
				Add project
			</Link>
		</div>
	);
};
