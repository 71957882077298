import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import L from 'i18n-react';
import { ExclamationRoundIcon, PencilWithLineIcon } from 'assets/inline-svg';
import { EditRole } from 'UI/Popups/EditRole/EditRole';
import { TEditRoleOnSubmit } from 'UI/Popups/EditRole/types';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { IEditProviderRolePayload } from 'redux/reducers/administration/types';
import { notify } from 'utils/notify';
import { editProviderRoleRequest } from 'redux/reducers/administration/reducer';
import { IRoleBlockProps, TRoleAction } from './types';

export const RoleBlock: FC<IRoleBlockProps> = ({ user }) => {
	const dispatch = useDispatch();
	const [action, setAction] = useState<TRoleAction>(null);

	const roleDescription = L.translate(
		`Administration.Roles.Description.${user.provider.role.code}`,
		{ notFound: '' },
	);

	const closeModal = () => setAction(null);

	const handleEditSubmit: TEditRoleOnSubmit = (newRole) => {
		const payload: IEditProviderRolePayload = {
			apiParams: {
				id: user.provider.id,
				role: newRole.code,
			},
			newRoleName: newRole.name,
			onSuccess: () => {
				closeModal();
				notify('Role changed successfully', 'success');
			},
		};
		dispatch(editProviderRoleRequest(payload));
	};

	return (
		<>
			<div className="block-title-wrap">
				<div className="block-title">
					<p>Role</p>
				</div>
			</div>

			<div className="table-block">
				<div className="table table--user-profile-role-project">
					<div className="table-body">
						<div className="tr">
							<div className="td">
								<p className="td-name">Role</p>
								<p className="td-value flex flex-m">
									{user.provider.role.name}

									{roleDescription && (
										<span className="tooltip-item">
											<span className="tooltip-item__icon">
												<ExclamationRoundIcon />
											</span>
											<span className="tooltip tooltip--top tooltip--center tooltip--no-arrow">
												<span className="tooltip__text">
													<span>{roleDescription}</span>
												</span>
											</span>
										</span>
									)}
								</p>
							</div>

							<div className="td td--right">
								<p className="td-name">Action</p>
								<div className="table-buttons">
									<button aria-label="button" type="button" onClick={() => setAction('edit')}>
										<PencilWithLineIcon />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<PopupModal open={action === 'edit'} onClose={closeModal}>
				<EditRole initRole={user.provider.role} onSubmit={handleEditSubmit} onClose={closeModal} />
			</PopupModal>
		</>
	);
};
