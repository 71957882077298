import { spawn } from 'redux-saga/effects';
import { appSaga } from './reducers/app/saga';
import { authSaga } from './reducers/auth/saga';
import { providerMerchantSaga } from './reducers/providerMerchant/saga';
import { administrationSaga } from './reducers/administration/saga';
import { merchantProjectsSaga } from './reducers/merchantProjects/saga';
import { getMerchantApprovalManagementSaga } from './reducers/merchantApprovalManagement/saga';
import { getMerchantProviderProfileSaga } from './reducers/merchantProfile/saga';
import { projectSettingsSaga } from './reducers/projectSettings/saga';
import { projectTransactionsSaga } from './reducers/projectTransactions/saga';
import { projectClientsSaga } from './reducers/projectClients/saga';
import { projectCallbacksSaga } from './reducers/projectCallbacks/saga';
import { providerCurrencySettingSaga } from './reducers/providerCurrency/saga';
import { providerFeeSaga } from './reducers/providerFee/saga';
import { providerWalletsSaga } from './reducers/providerWallets/saga';
import { providerExportCSVSaga } from './reducers/providerExportCSV/saga';
import { providerInvoicesSaga } from './reducers/providerInvoices/saga';
import { projectReportsSaga } from './reducers/projectReports/saga';
import { merchantAccountsSaga } from './reducers/merchantAccounts/saga';
import { projectBalanceSaga } from './reducers/projectBalance/saga';
import { providerWalletAddressesSaga } from './reducers/providerWalletAddresses/saga';
import { merchantBankAccountSaga } from './reducers/merchantBankAccount/saga';
import { providerBankAccountSaga } from './reducers/providerBankAccount/saga';
import { providerReportsAnalyticsSaga } from './reducers/providerReportsAnalytics/saga';
import { merchantInvoicesSaga } from './reducers/merchantInvoices/saga';
import { projectWalletAddressesSaga } from './reducers/projectWalletAddresses/saga';
import { providerOrdersSaga } from './reducers/providerOrders/saga';
import { merchantOrdersSaga } from './reducers/merchantOrders/saga';
import { merchantSettingsSaga } from './reducers/merchantSettings/saga';
import { merchantSecuritySaga } from './reducers/merchantSecurity/saga';
import { providerPaymentSaga } from './reducers/providerPaymentTrasaction/saga';
import { projectComissionsSaga } from './reducers/projectComissions/saga';
import { providerProjectApprovementsSaga } from './reducers/providerProjectApprovements/saga';
import { providerApprovementsSaga } from './reducers/providerApprovements/saga';
import { providerTransactionsSaga } from './reducers/providerTransactions/saga';
import { projectWithdrawalSaga } from './reducers/projectWithdrawal/saga';
import { paymentWidgetSaga } from './reducers/paymentWidget/saga';
import { merchantAnalyticsSaga } from './reducers/analyticsMerchant/saga';
import { providerLiquiditySaga } from './reducers/providerLiquidity/saga';
import { providerAnalyticsSaga } from './reducers/analyticsProvider/saga';
import { providerTRXManagerSaga } from './reducers/providerTRXManagement/saga';

export default function* rootSagas() {
	yield spawn(appSaga);
	yield spawn(authSaga);
	// components
	yield spawn(providerMerchantSaga);
	yield spawn(administrationSaga);
	yield spawn(providerCurrencySettingSaga);
	yield spawn(providerFeeSaga);
	yield spawn(providerWalletsSaga);
	yield spawn(providerExportCSVSaga);
	yield spawn(providerInvoicesSaga);
	yield spawn(providerBankAccountSaga);
	yield spawn(providerWalletAddressesSaga);
	yield spawn(providerReportsAnalyticsSaga);
	yield spawn(providerOrdersSaga);
	yield spawn(providerPaymentSaga);
	yield spawn(providerApprovementsSaga);
	yield spawn(providerTransactionsSaga);
	yield spawn(providerLiquiditySaga);
	yield spawn(providerAnalyticsSaga);
	yield spawn(providerTRXManagerSaga);
	// Merchant
	yield spawn(getMerchantApprovalManagementSaga);
	yield spawn(merchantProjectsSaga);
	yield spawn(getMerchantProviderProfileSaga);
	yield spawn(merchantBankAccountSaga);
	yield spawn(merchantInvoicesSaga);
	yield spawn(merchantSettingsSaga);
	yield spawn(merchantSecuritySaga);
	// Project
	yield spawn(projectSettingsSaga);
	yield spawn(projectTransactionsSaga);
	yield spawn(projectClientsSaga);
	yield spawn(projectCallbacksSaga);
	yield spawn(projectReportsSaga);
	yield spawn(projectBalanceSaga);
	yield spawn(merchantAccountsSaga);
	yield spawn(projectWalletAddressesSaga);
	yield spawn(merchantOrdersSaga);
	yield spawn(projectComissionsSaga);
	yield spawn(providerProjectApprovementsSaga);
	yield spawn(projectWithdrawalSaga);
	yield spawn(paymentWidgetSaga);
	yield spawn(merchantAnalyticsSaga);
}
