import { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getUserRole } from 'redux/reducers/auth/selectors';
import styles from '../styles.module.scss';
import { roles } from '../roles';

interface IPropsLi {
	link: string;
	children: ReactNode;
	icon: ReactNode;
	tag?: ReactNode;
	getIsActive: () => boolean;
}

interface IUl extends IPropsLi {
	keyRoles: string;
}

interface IProps {
	text?: string;
	children?: ReactNode;
	ul: IUl[];
}
const Li = ({ children, tag, link, icon, getIsActive = () => true }: IPropsLi) => {
	const getLinkStyles = (isActive: boolean) => {
		return classNames(styles.nav__link, { [styles.active]: isActive });
	};

	return (
		<li className={styles.nav__item}>
			<NavLink to={link} className={getLinkStyles} isActive={getIsActive}>
				<span className={styles.nav__icon}>{icon}</span>
				<span className={styles.nav__text}>{children}</span>
				{tag}
			</NavLink>
		</li>
	);
};

const SidebarBlock = ({ text, children, ul }: IProps) => {
	const userRole = useSelector(getUserRole);
	const [isHidden, setIsHidden] = useState(false);
	const condition = (li: any) =>
		roles[li.keyRoles] ? roles[li.keyRoles].includes(userRole || ' ') : true;
	return (
		<>
			{text && (
				<button
					className={styles.sidebar__title}
					type="button"
					onClick={() => setIsHidden(!isHidden)}
				>
					{text}
				</button>
			)}
			<ul className={classNames(styles.nav, { [styles.nav_hidden]: isHidden })}>
				{ul.map(
					(li) =>
						condition(li) && (
							<Li
								link={li.link}
								icon={li.icon}
								getIsActive={li.getIsActive}
								key={li.link}
								tag={li.tag}
							>
								{li.children}
							</Li>
						),
				)}
				{children}
			</ul>
		</>
	);
};
export default SidebarBlock;
