import { useState, ChangeEvent } from 'react';
import moment from 'moment';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { trimBottomLine, trimLastZeros } from 'utils/strings';
import { notify } from 'utils/notify';
import {
	putApprovementRequest,
	putRejectRequest,
	putResendRequest,
} from 'redux/reducers/projectWithdrawal/reducer';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { PopUpApprove } from '../../PopUpApprove';
import { PopUpReject } from '../../PopUpReject';
import { ITableProps } from './types';
import { PopUpResend } from '../../PopUpResend';

export const TableRow = ({ item, updateRequest, currencyTab }: ITableProps): TdItem[] => {
	const {
		id,
		user_id,
		owner_merchant_id,
		project_name,
		balance,
		currency,
		currency_network,
		amount,
		fee_network,
		hash_transaction,
		status,
		created_at,
		message,
		project_transaction_id,
	} = item;
	const dispatch = useDispatch();
	const { getViewDecimal } = useViewDecimals();
	const [amountInput, setAmountInput] = useState(String(amount));
	const [isModal, setIsModal] = useState(false);
	const [isModalReject, setIsModalReject] = useState(false);
	const [isModalResend, setIsModalResend] = useState(false);
	const closeModal = () => {
		setIsModalReject(false);
		setIsModalResend(false);
		setIsModal(false);
	};

	const viewDecimals = currency?.code
		? getViewDecimal(currency?.code, currency_network)
		: undefined;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => setAmountInput(e.currentTarget.value);
	const isCrypto = currencyTab === 'crypto';
	const handleRequest = () =>
		dispatch(
			putApprovementRequest({
				params: { amount: Number(amount), project_withdrawal_crypto_transaction_id: id },
				onSuccess: () => {
					notify('User request on withdrawal was approved successfully', 'success');
					updateRequest();
				},
				is_crypto: isCrypto,
			}),
		);
	const handleRejectRequest = () =>
		dispatch(
			putRejectRequest({
				params: { project_withdrawal_crypto_transaction_id: String(id) },
				onSuccess: () => {
					notify('User request on withdrawal was reject successfully', 'success');
					updateRequest();
				},
				is_crypto: isCrypto,
			}),
		);

	const handleResendRequest = () =>
		dispatch(
			putResendRequest({
				params: { amount: Number(amount), project_withdrawal_crypto_transaction_id: id },
				onSuccess: () => {
					notify('User request on withdrawal was resend successfully', 'success');
					updateRequest();
				},
				is_crypto: isCrypto,
			}),
		);

	return [
		{
			title: 'ID',
			children: (
				<Link
					to={`/transactions/${String(project_transaction_id)}/crypto/withdrawal`}
					className="td-value"
				>
					{id}
				</Link>
			),
		},
		{
			title: 'User ID',
			children: (
				<a href={`/administration/team-members/${user_id}`} className="link">
					{user_id}
				</a>
			),
		},
		{
			title: 'Merchant ID',
			children: (
				<a href={`/merchant/${owner_merchant_id}`} className="link">
					{owner_merchant_id}
				</a>
			),
		},
		{
			title: 'Project',
			children: project_name,
		},
		{
			title: 'Balance',
			children: trimLastZeros(balance?.toFixed(viewDecimals)),
		},
		{
			title: 'Currency',
			children: currency?.code?.toUpperCase(),
		},
		{
			title: 'Networks',
			children: currency_network.toUpperCase(),
		},
		{
			title: 'Value',
			children: amount,
		},
		/*		{
			title: 'Fee', - temporarily disabled
			children: trimLastZeros(fee_network?.toFixed(viewDecimals) || ''),
		}, */
		{
			title: 'Created',
			children:
				created_at &&
				`${moment(created_at).utc().format('YYYY-MM-DD HH:MM:SS').replace(' ', '\n')}UTC`,
		},
		{
			title: 'Message',
			children: trimBottomLine(message),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					{status === 'pending' && (
						<button onClick={() => setIsModal(true)} type="button" className="button button--size2">
							Approve
						</button>
					)}
					{status === 'accepted' && !hash_transaction && (
						<button
							onClick={() => setIsModalResend(true)}
							type="button"
							className="button button--size2"
						>
							Resend
						</button>
					)}
					<button
						onClick={() => setIsModalReject(true)}
						type="button"
						className="button button--type8 button--size2"
					>
						Reject
					</button>
					<PopupModal open={isModalResend} onClose={closeModal}>
						<PopUpResend onClose={closeModal} onAction={handleResendRequest} />
					</PopupModal>
					<PopupModal open={isModalReject} onClose={closeModal}>
						<PopUpReject onClose={closeModal} onAction={handleRejectRequest} />
					</PopupModal>
					<PopupModal open={isModal} onClose={closeModal}>
						<PopUpApprove
							onClose={closeModal}
							handleChange={handleChange}
							amount={amountInput}
							onAction={handleRequest}
						/>
					</PopupModal>
				</TableButtons>
			),
		},
	];
};
