import TransactionDetails from 'components/Project/ProjectTransactions/TabsContent/TransactionDetails/TransactionDetails';
import Main from 'v2Components/layoutsComponents/Main';
import BackLink from 'components/Project/ProjectTransactions/TabsContent/TransactionDetails/BackLink';

export const ProjectTransactionsDetailsPage = () => {
	return (
		<Main header={<BackLink isProject />}>
			<TransactionDetails isProject />
		</Main>
	);
};
