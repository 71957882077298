import { Redirect, Route, Switch } from 'react-router';
import { routes } from 'routes/routes';
import WalletAddressProvider from './WalletAddressProvider';
import WalletAddressUser from './WalletAddressUser';
import styles from './styles.module.scss';

export const WalletAddressesPage = () => {
	return (
		<div className={styles.main}>
			<Switch>
				<Route exact path={routes.walletAddressBase}>
					<WalletAddressProvider />
				</Route>
				<Route exact path={routes.walletAddress.profile}>
					<WalletAddressUser />
				</Route>
				<Redirect to={routes.walletAddressBase} />
			</Switch>
		</div>
	);
};
