import { PlusBlueIcon, TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { FC, useState, MouseEvent } from 'react';
import { EButtonName } from 'services/constants/account-btn';
import { IPropsPopUpSelectDrop } from '../SelectDropFrom/types';

export const SelectDropTo: FC<IPropsPopUpSelectDrop> = ({
	data,
	popUpOpen,
	onSubmit,
	defaultValue,
}) => {
	const [isSelect, setIsSelect] = useState<string>(defaultValue?.name || data?.[0]?.name || '');

	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (event: MouseEvent) => {
		const { name, id } = event.target as HTMLButtonElement;

		setIsSelect(name);
		setOpen(false);
	};

	return (
		<div className="select-block">
			<p className="select-block__name">To</p>
			<div className={`select select--height-55 ${open ? 'active' : ''}`}>
				<button
					ref={triggerRef}
					onClick={toggleOpen}
					type="button"
					className="select__current select__current-arrow-custom"
				>
					<span>{isSelect}</span>
					<span className="select__current-arrow">
						<TriangleArrowDownIcon2 />
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{data?.map((project) => (
									<li key={project.id}>
										<button
											id={String(project.id)}
											onClick={handleSelect}
											name={project.name}
											type="button"
										>
											{project.name}
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			{popUpOpen === EButtonName.WITDRAWAL && (
				<button type="button" className="button-add">
					<PlusBlueIcon />
					<span>Add a bank account</span>
				</button>
			)}
		</div>
	);
};
