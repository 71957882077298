import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import { TApiGetProjectMerchantTransactionResponse } from 'services/api/project/types';
import {
	IApiProviderTransactionResponse,
	IProfitRequest,
	IProviderDepositResponse,
	TApiGetProviderAllCurrencyResponse,
	IGetCryptoWalletsByCurrencySettingParams,
	TCryptoWalletResponse,
	IShowWalletFiatTransactionHistoryParams,
	IShowWalletCryptoTransactionHistoryParams,
} from 'services/api/provider/types';
import {
	getProviderAllCurrencyRequest,
	getProviderAllCurrencySuccess,
	getProviderAllCurrencySettingRequest,
	getProviderAllCurrencySettingRequestSuccess,
	getProviderCreateWithdrawalRequest,
	getProviderCreateWithdrawalSuccess,
	getProviderDepositRequest,
	getProviderDepositSuccess,
	getProviderTransactionRequest,
	getProviderTransactionSuccess,
	putProviderAllCurrencySettingRequest,
	putProviderAllCurrencySettingRequestSuccess,
	getProviderProfitRequest,
	getProviderProfitSuccess,
	getCryptoWalletsByCurrencyRequest,
	getCryptoWalletsByCurrencySuccess,
	getCryptoWalletsByCurrencyError,
	getProviderTransactionFiatRequest,
	getProviderTransactionFiatSuccess,
	showTransactionHistoryFiatRequest,
	showTransactionHistoryFiatSuccess,
	showTransactionHistoryCryptoRequest,
	showTransactionHistoryCryptoSuccess,
} from './reducer';
import {
	ICurrencyProfit,
	TApiGetDepositPayload,
	TApiGetProviderAllCurrencySettingPayload,
	TApiGetProviderAllCurrencySettingResponse,
	TApiGetProviderTransactionPayload,
	TApiPostProviderCreateWithdrawPayload,
	TApiPutProviderAllCurrencySettingPayload,
	TApiPutProviderAllCurrencySettingResponse,
} from './types';

function* getProviderAllCurrencyRequestWorker() {
	try {
		const data: TApiGetProviderAllCurrencyResponse[] = yield call(
			api.provider.getMerchantProviderAllCurrency,
		);
		yield put(getProviderAllCurrencySuccess(data));
	} catch (error) {
		showError(error);
	}
}
function* getProviderAllCurrencySettingRequestWorker({
	payload,
}: PayloadAction<TApiGetProviderAllCurrencySettingPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: TApiGetProviderAllCurrencySettingResponse[] = yield call(
			api.provider.getMerchantProviderAllCurrencySetting,
			apiParams,
		);
		onSuccess?.();
		yield put(getProviderAllCurrencySettingRequestSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* putProviderAllCurrencySettingRequestWorker({
	payload,
}: PayloadAction<TApiPutProviderAllCurrencySettingPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: TApiPutProviderAllCurrencySettingResponse = yield call(
			api.provider.putMerchantProviderAllCurrencySetting,
			apiParams,
		);
		onSuccess?.();
		yield put(putProviderAllCurrencySettingRequestSuccess(data));
	} catch (error) {
		showError(error);
	}
}
/// ///////////////////////////////// get-provider transaction
function* getProviderTransactionRequestWorker({
	payload,
}: PayloadAction<TApiGetProviderTransactionPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: IApiProviderTransactionResponse = yield call(
			api.provider.getProviderTransaction,
			apiParams,
		);
		onSuccess?.();
		yield put(getProviderTransactionSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* getProviderTransactionFiatWorker({
	payload,
}: PayloadAction<TApiGetProviderTransactionPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: IApiProviderTransactionResponse = yield call(
			api.provider.getProviderTransactionFiat,
			apiParams,
		);
		onSuccess?.();
		yield put(getProviderTransactionFiatSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* getProviderProfitRequestWorker({ payload }: PayloadAction<IProfitRequest>) {
	try {
		const data: Array<ICurrencyProfit> = yield call(api.provider.getProviderProfit, payload);
		yield put(getProviderProfitSuccess(data));
	} catch (error) {
		showError(error);
	}
}

/// ///////////////////////////////// get-provider create withdraw
function* postProviderCreateWithdrawWorker({
	payload,
}: PayloadAction<TApiPostProviderCreateWithdrawPayload>) {
	const { apiParams, onSuccess, onClose } = payload;
	try {
		yield call(api.provider.postProviderCreateWithdraw, apiParams);
		onSuccess?.();
		onClose?.();

		yield put(getProviderCreateWithdrawalSuccess());
	} catch (error) {
		showError(error);
	}
}

/// ///////////////////////////////// get-provider create deposit
function* postProviderDepositWorker({ payload }: PayloadAction<TApiGetDepositPayload>) {
	const { apiParams, onSuccess, onClose } = payload;
	try {
		const data: IProviderDepositResponse = yield call(api.provider.getProviderDeposit, apiParams);
		onSuccess?.();
		onClose?.();
		yield put(getProviderDepositSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* getCryptoWalletsByCurrencyWorker({
	payload,
}: PayloadAction<IGetCryptoWalletsByCurrencySettingParams>) {
	try {
		const data: TCryptoWalletResponse = yield call(
			api.provider.getCryptoWalletsByCurrencySetting,
			payload,
		);
		yield put(getCryptoWalletsByCurrencySuccess(data));
	} catch (error) {
		yield put(getCryptoWalletsByCurrencyError());
		showError(error);
	}
}

function* showTransactionHistoryFiatWorker({
	payload,
}: PayloadAction<IShowWalletFiatTransactionHistoryParams>) {
	try {
		const data: TApiGetProjectMerchantTransactionResponse = yield call(
			api.provider.showWalletFiatTransactionHistory,
			payload,
		);
		yield put(showTransactionHistoryFiatSuccess(data));
	} catch (error) {
		yield put(getCryptoWalletsByCurrencyError());
		showError(error);
	}
}

function* showTransactionHistoryCryptoWorker({
	payload,
}: PayloadAction<IShowWalletCryptoTransactionHistoryParams>) {
	try {
		const data: TApiGetProjectMerchantTransactionResponse = yield call(
			api.provider.showWalletCryptoTransactionHistory,
			payload,
		);
		yield put(showTransactionHistoryCryptoSuccess(data));
	} catch (error) {
		yield put(getCryptoWalletsByCurrencyError());
		showError(error);
	}
}

export function* providerCurrencySettingSaga() {
	yield takeLatest(getProviderAllCurrencyRequest, getProviderAllCurrencyRequestWorker);
	yield takeLatest(
		getProviderAllCurrencySettingRequest,
		getProviderAllCurrencySettingRequestWorker,
	);
	yield takeLatest(
		putProviderAllCurrencySettingRequest,
		putProviderAllCurrencySettingRequestWorker,
	);
	yield takeLatest(getProviderCreateWithdrawalRequest, postProviderCreateWithdrawWorker);
	yield takeLatest(getProviderTransactionRequest, getProviderTransactionRequestWorker);
	yield takeLatest(getProviderTransactionFiatRequest, getProviderTransactionFiatWorker);
	yield takeLatest(getProviderDepositRequest, postProviderDepositWorker);
	yield takeLatest(getProviderProfitRequest, getProviderProfitRequestWorker);
	yield takeLatest(getCryptoWalletsByCurrencyRequest, getCryptoWalletsByCurrencyWorker);
	yield takeLatest(showTransactionHistoryFiatRequest, showTransactionHistoryFiatWorker);
	yield takeLatest(showTransactionHistoryCryptoRequest, showTransactionHistoryCryptoWorker);
}
