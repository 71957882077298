import { useSelector } from 'react-redux';
import { getBalanceInDefaultCurrency } from 'redux/reducers/app/selectors';
import { trimLastZeros } from 'utils/strings';
import { useViewDecimals } from 'hooks/useViewDecimals';
import styles from './styles.module.scss';

export const BalanceInfo = () => {
	const balanceInDefaultCurrency = useSelector(getBalanceInDefaultCurrency);
	const { balance, currencyCode } = balanceInDefaultCurrency;
	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = currencyCode ? getViewDecimal(currencyCode) : undefined;

	if (balance == null || currencyCode == null) {
		return null;
	}

	return (
		<div className={styles.header}>
			<p>Balance:</p>
			<span>
				{trimLastZeros(balance?.toFixed(viewDecimals))} {currencyCode.toUpperCase()}
			</span>
		</div>
	);
};
