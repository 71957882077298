import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import { ProjectHeader } from 'layouts-elements/ProjectHeader/ProjectHeader';
import { ProjectSidebar } from 'layouts-elements/ProjectSidebar/ProjectSidebar';
import { IProjectLayoutProps } from './types';

export const ProjectLayout: FC<IProjectLayoutProps> = ({ children, title }) => {
	const appTitle = APP_NAME || '';
	const layoutTitle = title ? ` | ${title}` : '';

	return (
		<>
			<Helmet>
				<title>{`${appTitle}${layoutTitle}`}</title>
			</Helmet>

			<div className="wrapper">
				<ProjectHeader />

				<main className="main">
					<section className="main-section">
						<ProjectSidebar />
						{children}
					</section>
				</main>
			</div>
		</>
	);
};
