import { ReactNode, useEffect } from 'react';
import { Form } from 'formik';
import { DrawerBody, DrawerFooter, DrawerHeader, DrawerLayout } from '../Drawer';

interface IProps {
	show: boolean;
	setShow: (value: boolean) => void;
	title: string;
	className: string;
	onActionDrawer?: () => void;
	children: ReactNode;
	footer: ReactNode;
}
export default ({ show, setShow, title, className, onActionDrawer, children, footer }: IProps) => {
	useEffect(() => {
		document.body.style.overflow = show ? 'hidden' : 'auto';
		return () => {
			document.body.style.overflow = '';
		};
	}, [show]);
	return (
		<DrawerLayout openDrawer={show} setOpenDrawer={setShow} classNameLayout={className}>
			<DrawerHeader
				title={title}
				onCloseDrawer={() => setShow(false)}
				onActionDrawer={onActionDrawer}
			/>
			<Form>
				<DrawerBody>{children}</DrawerBody>
				<DrawerFooter>{footer}</DrawerFooter>
			</Form>
		</DrawerLayout>
	);
};
