import * as yup from 'yup';
import { TProjectLogoFile } from './ProjectLogoField/types';
import { IProjectStatus } from './ProjectStatusField/ProjectStatusSelect/types';

// Project statuses

export const projectStatuses: IProjectStatus[] = [
	{ name: 'Active', requestCode: 'active', responseCode: 1, activeClassName: 'green' },
	{ name: 'Inactive', requestCode: 'inactive', responseCode: 0, activeClassName: 'red' },
];

// Validation

export const getValidationSchema = (initLogoUrl: string) => {
	return yup.object().shape({
		projectName: yup.string().required('Required').min(2, 'Too short').max(100, 'Too long'),
		status: yup.object().nullable().required('Required'),
		contactFirstName: yup.string().required('Required').min(2, 'Too short').max(100, 'Too long'),
		contactLastName: yup.string().required('Required').min(2, 'Too short').max(100, 'Too long'),
		email: yup.string().required('Required').email('Please enter valid email'),
		phoneCode: yup.string().required('Required'),
		phoneNumber: yup.string().required('Required').min(2, 'Too short').max(25, 'Too long'),
		website: yup.string().required('Required').min(3, 'Too short').max(255, 'Too long'),
		logoFile: yup
			.mixed<TProjectLogoFile>()
			.test('required', 'Required', (value) => {
				if (!value) return !!initLogoUrl;
				if (value === 'delete') return false;
				return true;
			})
			.test('size-not-exceeded', 'Larger than maximum size', (value) => {
				if (!value || value === 'delete') {
					return true;
				}
				return value.size <= 5242880;
			}),
	});
};
