import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { Tabs } from 'v2Components/commomComponents/Tabs/Tabs';
import { routes } from 'routes/routes';
import Main from 'v2Components/layoutsComponents/Main';
import React from 'react';
import Staking from './Staking';
import Delegation from './Delegation';
import TRXOverview from './TRXOverview';
import DropdownDialogs from './DropdownDialogs';
import styles from './styles.module.scss';

export enum types {
	OVERVIEW = 'Overview',
	DELEGATION = 'Delegation',
	STAKING = 'Staking',
}

export const TRXManagementPage = () => {
	const { path } = useRouteMatch();
	const listContent = [
		{ link: `staking`, value: 'Staking' },
		{ link: `delegation`, value: 'Delegation' },
		{ link: `trx-overview`, value: 'TRX Overview' },
	];
	const matchTRXOverview = useRouteMatch(path + routes.trxManagement.trxOverview);
	const matchTRXDelegation = useRouteMatch(path + routes.trxManagement.delegation);
	const getType = () => {
		if (matchTRXOverview) return types.OVERVIEW;
		if (matchTRXDelegation) return types.DELEGATION;
		return types.STAKING;
	};
	return (
		<Main
			header={
				<div className={styles.header}>
					<Tabs listContent={listContent} isSwitch />
					<DropdownDialogs type={getType()} />
				</div>
			}
			noBackground={!!matchTRXOverview}
		>
			<Switch>
				<Route exact path={path + routes.trxManagement.staking} render={(props) => <Staking />} />
				<Route
					exact
					path={path + routes.trxManagement.delegation}
					render={(props) => <Delegation />}
				/>
				<Route
					exact
					path={path + routes.trxManagement.trxOverview}
					render={(props) => <TRXOverview />}
				/>
				<Redirect to={path + routes.trxManagement.staking} />
			</Switch>
		</Main>
	);
};
