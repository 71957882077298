import { PopUpCloseIcon } from 'assets/inline-svg';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getProviderCurrencyAll } from 'redux/reducers/providerCurrency/selectors';
import Loader from 'UI/Loader/Loader';
import { IPropsInvoiceOrder } from '../../types';

export const PopUpDetails: FC<IPropsInvoiceOrder> = ({ closeConfirm, onSubmit, data, loader }) => {
	const currencies = useSelector(getProviderCurrencyAll);
	const currencyRequestOpt = currencies?.find((item) => item.id === data?.currencyRequest);
	const currencyPaidOpt = currencies?.find((item) => item.id === data?.currencyPaid);
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<button aria-label="button" onClick={closeConfirm} type="button" className="popup__close">
						<PopUpCloseIcon />
					</button>
					<div className="popup-header">
						<p className="popup-header__title popup-header__title--ta-l">Confirm an invoice</p>
					</div>
					<div className="popup-body popup-body--type3">
						<div className="deposit-info-pop">
							<ul>
								<li>
									<span>Order ID:</span>
									<p>{data?.order_id}</p>
								</li>
								<li>
									<span>Currency:</span>
									<p>{currencyRequestOpt?.currency.code?.toUpperCase()}</p>
								</li>
								<li>
									<span>Amount (cost):</span>
									<p>{data?.amount_request}</p>
								</li>
								<li>
									<span>Pay in currency:</span>
									<p>{currencyPaidOpt?.currency.code?.toUpperCase()}</p>
								</li>
								<li>
									<span>Amount (Pay in currency):</span>
									<p>{data?.amount_to_pay}</p>
								</li>
								<li>
									<span>Expires Time:</span>
									<p>{`${String(data?.expires_time_minutes || 0)} min`}</p>
								</li>
								<li>
									<span>Client:</span>
									<p>{data?.client_email}</p>
								</li>
							</ul>
						</div>
					</div>
					<div className="popup-button popup-button--type2">
						<button
							onClick={closeConfirm}
							type="button"
							className="button button--full-width button--type2"
						>
							Cancel
						</button>
						<button onClick={onSubmit} type="button" className="button button--full-width">
							{loader ? <Loader small /> : 'Confirm'}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
