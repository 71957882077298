import { FC } from 'react';
import { PasswordHideIcon, PasswordShowIcon } from 'assets/inline-svg';
import { IPasswordInputProps } from './types';

export const PasswordInput: FC<IPasswordInputProps> = ({
	showPass,
	onToggleShowPass,
	...restProps
}) => {
	return (
		<div className="input-wrapper">
			<input
				className="input-item input-item--right-icon"
				type={showPass ? 'text' : 'password'}
				{...restProps}
			/>
			<button
				aria-label="button"
				type="button"
				className="show-pass"
				onClick={() => onToggleShowPass(!showPass)}
			>
				<PasswordHideIcon className="password-type" />
				<PasswordShowIcon className="text-type" />
			</button>
		</div>
	);
};
