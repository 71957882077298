import { FC } from 'react';
import * as yup from 'yup';
import L from 'i18n-react';
import { Formik, Form } from 'formik';
import { authPasswordRegexp } from 'utils/formValidation';
import { PasswordField } from './PasswordField/PasswordField';
import { AgreePrivacyPolicyField } from './AgreePrivacyPolicyField/AgreePrivacyPolicyField';
import { PasswordStrength } from './PasswordStrength/PasswordStrength';
import { ICreatePasswordFormProps } from './types';

export const CreatePasswordForm: FC<ICreatePasswordFormProps> = ({ onSubmit }) => {
	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required(String(L.translate('Validation.password_required')))
			.matches(authPasswordRegexp, String(L.translate('Validation.password_not_strong'))),
		confirmPassword: yup
			.string()
			.required(String(L.translate('Validation.confirm_password_required')))
			.oneOf([yup.ref('password')], String(L.translate('Validation.confirm_password_match'))),
		isAgreePrivacyPolicy: yup
			.bool()
			.oneOf([true], String(L.translate('Validation.agree_privacy_policy'))),
	});

	return (
		<Formik
			initialValues={{
				password: '',
				confirmPassword: '',
				isAgreePrivacyPolicy: false,
			}}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, values }) => (
				<Form className="form form--authorization">
					<div className="form__header">
						<p className="form-title ">Welcome to CryptoProcessing</p>
						<div className="form-text">
							<p>Create a password to use CryptoProcessing.</p>
						</div>
					</div>

					<div className="form__body">
						<PasswordField name="password" label="Create Password" placeholder="Create password" />

						<PasswordField
							name="confirmPassword"
							label="Confirm Password"
							placeholder="Confirm password"
						/>

						<div className="form-group">
							<PasswordStrength password={values.password} />
						</div>

						<div className="form-group">
							<div className="form-options">
								<AgreePrivacyPolicyField />
							</div>
						</div>
					</div>

					<div className="form-footer">
						<button type="submit" className="button button--full-width" disabled={isSubmitting}>
							Continue
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};
