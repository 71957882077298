export function capitalize(string?: string) {
	return (string?.charAt(0).toUpperCase() || '') + (string?.slice(1) || '');
}

export function trimRightSlash(string: string) {
	if (string[string.length - 1] !== '/') {
		return string;
	}
	return string.slice(0, -1);
}
export function trimBottomLine(string?: string) {
	return string?.replace(/_/g, ' ');
}

export function shortNotation(string: string, fromStart: number, fromEnd = 0): string {
	if (string?.length <= fromStart + fromEnd) {
		return string;
	}
	const leftPart = string?.slice(0, fromStart);
	const rightPart = fromEnd ? string?.slice(-fromEnd) : '';

	return `${leftPart}...${rightPart}`;
}

export function trimZerosAfterDot(num: string): string {
	return num.replace(/\.0*$/, '').replace(/0+$/, '');
}

export function trimLastZeros(num: string): string {
	const dotIdx = num.indexOf('.');
	if (dotIdx === -1) {
		return num;
	}
	const intPart = num.slice(0, dotIdx);
	const decimalPart = num.slice(dotIdx + 1).replace(/0+$/, '');
	if (decimalPart.length === 0) {
		return intPart;
	}
	return `${intPart}.${decimalPart}`;
}

export const urlSplit = (url: string): string => {
	return url.split('//')[1].split('/')[0];
};

export const priceFormat = (price?: number) => {
	const SIZE_SPLIT_NUMBER = 3;

	if (!price) return 0;
	const strReverse = (str: string) => str.split('').reverse().join('');
	const mod = price.toFixed(2).split('.')[1];
	const strPrice = strReverse(String(Math.floor(price)));
	const arrPrices = [];
	let i = 0;

	while (i + SIZE_SPLIT_NUMBER < strPrice.length) {
		arrPrices.push(strReverse(strPrice.slice(i, i + SIZE_SPLIT_NUMBER)));
		i += SIZE_SPLIT_NUMBER;
	}
	arrPrices.push(strReverse(strPrice.slice(i, strPrice.length)));
	return `${arrPrices.reverse().join(' ')}.${mod}`;
};
