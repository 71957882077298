import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { routes } from 'routes/routes';
import { useMemo } from 'react';
import { ProviderLayout } from 'layouts/ProviderLayout/ProviderLayout';
import { Merchant } from './TabsContent/Merchant/Merchant';
import { Provider } from './TabsContent/Provider/Provider';
import { ApprovementsDetails } from './TabsContent/Merchant/ApprovementsDetails/ApprovementsDetails';
import { ApprovementsDetailsProvider } from './TabsContent/Provider/ApprovementsDetailsProvider/ApprovementsDetailsProvider';

export const ApprovementsPage = () => {
	const { path } = useRouteMatch();
	const isDesktop = useMemo(() => window.screen.width > 1180, []);
	return (
		<ProviderLayout title="Approvements" v2={isDesktop}>
			<Switch>
				<Route exact path={path + routes.approvements.merchant} component={Merchant} />
				<Route
					exact
					path={path + routes.approvements.detailsMerchant}
					component={ApprovementsDetails}
				/>
				<Route exact path={path + routes.approvements.provider} component={Provider} />
				<Route
					exact
					path={path + routes.approvements.detailsProvider}
					component={ApprovementsDetailsProvider}
				/>
				<Redirect to={path + routes.approvements.merchant} />
			</Switch>
		</ProviderLayout>
	);
};
