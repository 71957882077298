import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api, showError } from 'services';
import { notify } from 'utils/notify';
import {
	createOrderInvoiceMerchantRequest,
	createOrderInvoiceMerchantSuccess,
	createOrderMerchantRequest,
	createOrderMerchantSuccess,
	createRefundMerchantRequest,
	getOrderDetailsMerchantRequest,
	getOrderDetailsMerchantSuccess,
	getOrdersMerchantRequest,
	getOrdersMerchantSuccess,
} from './reducer';

import {
	IComment,
	ICommentReducerRequest,
	IInvoiceOrderParams,
	IInvoiceOrderRequest,
	IInvoiceOrderResponse,
	IOrderShowRequest,
	IOrderShowResponse,
	IOrdersPagination,
	IOrdersRequest,
	IParamsSaga,
	IRefundRequest,
} from './types';

function* getOrdersWorker({ payload }: PayloadAction<IOrdersRequest>) {
	try {
		const response: IOrdersPagination = yield call(api.project.getOrderMerchantLists, payload);
		yield put(getOrdersMerchantSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* getOrderDetailsWorker({ payload }: PayloadAction<IOrderShowRequest>) {
	try {
		const response: IOrderShowResponse = yield call(api.project.getOrderMerchantDetails, payload);
		yield put(getOrderDetailsMerchantSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* createOrderWorker({ payload }: PayloadAction<ICommentReducerRequest>) {
	try {
		const response: IComment = yield call(api.project.createOrderMerchant, payload.body);
		yield put(createOrderMerchantSuccess(response));
		yield payload.closePopup();
		yield window.scrollTo(0, document.body.scrollHeight);
	} catch (error) {
		showError(error);
	}
}

function* createInvoiceOrderWorker({ payload }: PayloadAction<IInvoiceOrderParams>) {
	const { params, closePopup } = payload;

	try {
		const response: IInvoiceOrderResponse = yield call(
			api.project.createOrderInvoiceMerchant,
			params as IInvoiceOrderRequest,
		);
		yield closePopup();
		yield put(createOrderInvoiceMerchantSuccess(response));
	} catch (error) {
		showError(error);
		yield closePopup();
	}
}

function* createRefundWorker({ payload }: PayloadAction<IParamsSaga>) {
	const { apiParams, closePopup, onSuccess } = payload;

	try {
		yield call(api.project.refundMerchant, apiParams);
		yield closePopup?.('');
		onSuccess?.();
		yield notify('Refund successfully', 'success');
	} catch (error) {
		showError(error);
	}
}

export function* merchantOrdersSaga() {
	yield takeEvery(getOrdersMerchantRequest, getOrdersWorker);
	yield takeEvery(getOrderDetailsMerchantRequest, getOrderDetailsWorker);
	yield takeEvery(createOrderMerchantRequest, createOrderWorker);
	yield takeEvery(createOrderInvoiceMerchantRequest, createInvoiceOrderWorker);
	yield takeEvery(createRefundMerchantRequest, createRefundWorker);
}
