import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { api, showError } from 'services';
import { TGetCountriesResponse, TGetLanguagesResponse } from 'services/api/app/types';
import { IBalanceInDefaultParams, IBalanceInDefaultResponse } from 'services/api/project/types';
import { IGetCurrencyNetworkItem } from 'services/api/public/types';
import {
	getProjectWithdrawalRequest,
	getProjectWithdrawalFiatRequest,
} from '../projectWithdrawal/reducer';
import { getApprovementDefaultRequest } from '../providerApprovements/reducer';
import { getApprovementRequest } from '../providerProjectApprovements/reducer';
import {
	authorizedUserAppDataInit,
	getCountriesRequest,
	getCountriesSuccess,
	getCountriesError,
	getLanguagesRequest,
	getLanguagesSuccess,
	getLanguagesError,
	getBalanceInDefaultCurrencyRequest,
	getBalanceInDefaultCurrencyResponse,
	getNetworkRequest,
	getNetworkSuccess,
	authorizedUserAppDataInitMerchant,
} from './reducer';

function* getCountriesRequestWorker() {
	try {
		const response: TGetCountriesResponse = yield call(api.app.getCountries);
		yield put(getCountriesSuccess(response));
	} catch (error) {
		yield put(getCountriesError());
		showError(error);
	}
}

function* authorizedUserAppDataInitWorkerMerchant() {
	const params = {
		current_page: 1,
		per_page: 15,
	};

	yield put(getApprovementDefaultRequest({ params }));
}

function* authorizedUserAppDataInitWorker() {
	const params = {
		current_page: 1,
		per_page: 15,
	};

	yield put(getProjectWithdrawalRequest({ params, onFinally: () => '' }));
	yield put(getProjectWithdrawalFiatRequest({ params, onFinally: () => '' }));
	yield put(getApprovementDefaultRequest({ params }));
	yield put(getApprovementRequest(params));
}

function* getLanguagesRequestWorker() {
	try {
		const response: TGetLanguagesResponse = yield call(api.app.getLanguages);
		yield put(getLanguagesSuccess(response));
	} catch (error) {
		yield put(getLanguagesError());
		showError(error);
	}
}

function* getBalanceInDefaultCurrencyWorker({ payload }: PayloadAction<IBalanceInDefaultParams>) {
	try {
		const response: IBalanceInDefaultResponse = yield call(
			api.project.getBalanceInDefaultCurrency,
			payload,
		);
		yield put(getBalanceInDefaultCurrencyResponse(response));
	} catch (error) {
		showError(error);
	}
}

function* getCurrencyNetworkWorker() {
	try {
		const response: IGetCurrencyNetworkItem[] = yield call(api.public.getCurrencyNetwork);
		yield put(getNetworkSuccess(response));
	} catch (error) {
		showError(error);
	}
}

export function* appSaga() {
	yield takeLatest(authorizedUserAppDataInitMerchant, authorizedUserAppDataInitWorkerMerchant);
	yield takeLatest(authorizedUserAppDataInit, authorizedUserAppDataInitWorker);
	yield takeLatest(getCountriesRequest, getCountriesRequestWorker);
	yield takeLatest(getLanguagesRequest, getLanguagesRequestWorker);
	yield takeLatest(getBalanceInDefaultCurrencyRequest, getBalanceInDefaultCurrencyWorker);
	yield takeLatest(getNetworkRequest, getCurrencyNetworkWorker);
}
