import { FormikHelpers } from 'formik/dist/types';
import { ReactNode, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { IFilter } from 'redux/reducers/app/types';
import {
	DrawerBody,
	DrawerHeader,
	DrawerFooter,
	DrawerLayout,
} from 'v2Components/layoutsComponents/Drawer/';
import Button from 'v2Components/UI/Button';

interface ITransactionsHistoryCryptoFilter {
	showFilter: boolean;
	setShowFilter: (value: boolean) => void;
	renderBodyForm: ReactNode;
	onSubmit: (value: IFilter, actions: FormikHelpers<IFilter>) => void;
}

const FilterForm = ({
	showFilter,
	setShowFilter,
	renderBodyForm,
	onSubmit,
}: ITransactionsHistoryCryptoFilter): JSX.Element => {
	useEffect(() => {
		document.body.style.overflow = showFilter ? 'hidden' : 'auto';
		return () => {
			document.body.style.overflow = '';
		};
	}, [showFilter]);

	const onSubmitHandler = (value: IFilter, actions: FormikHelpers<IFilter>) => {
		const clearValues = { ...value };

		// Formik оставляет пустые строки для полей, что были очищены в форме,
		// убираем эти поля для удобства работы с Redux
		Object.entries(value).forEach(([fieldName, fieldValue]) => {
			if (fieldValue === '') {
				delete clearValues[fieldName];
			}
		});

		onSubmit(clearValues, actions);
	};

	return (
		<Formik initialValues={{}} onSubmit={onSubmitHandler}>
			{({ resetForm }) => (
				<DrawerLayout openDrawer={showFilter} setOpenDrawer={setShowFilter}>
					<Form>
						<DrawerHeader
							title="Filters"
							onCloseDrawer={() => setShowFilter(false)}
							onActionDrawer={resetForm}
						/>
						<DrawerBody>{renderBodyForm}</DrawerBody>
						<DrawerFooter>
							<Button size="regular" width="100%" type="submit">
								Apply Filters
							</Button>
						</DrawerFooter>
					</Form>
				</DrawerLayout>
			)}
		</Formik>
	);
};

export default FilterForm;
