import { PopUpCloseIcon } from 'assets/inline-svg';
import { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { TApiGetProjectListResponse } from 'services/api/merchant/types';
import { IProjectBalanceResponse, ITransferRequest } from 'redux/reducers/merchantAccounts/types';
import { useDispatch, useSelector } from 'react-redux';
import { cryptoTransferRequest } from 'redux/reducers/merchantAccounts/reducer';
import { getCurrenciesSelector } from 'redux/reducers/merchantAccounts/selectors';
import { Formik, Form } from 'formik';
import { TextField } from 'UI/Forms/TextField/TextField';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { IPropsPopUps } from '../../TableRowCrypto/types';
import { SelectDropFrom } from './SelectDropFrom/SelectDropFrom';
import { SelectDropCoin } from './SelectDropCoin/SelectDropCoin';
import { SelectDropTo } from './SelectDropTo/SelectDropTo';

interface IValues {
	amount: string;
}

const validationSchema = yup.object().shape({
	amount: yup
		.number()
		.required('Amount is requierd')
		.moreThan(0, 'Amount should be more than 0')
		.typeError('Amount must be a number'),
});

export const PopUpTransfer: FC<IPropsPopUps> = ({
	onPopUps,
	projectList,
	accountId,
	currencies,
	projectChose,
}) => {
	const currencyAll = useSelector(getCurrenciesSelector);
	const [data, setData] = useState<ITransferRequest>();
	const dispatch = useDispatch();
	const [isSelect, setIsSelect] = useState<IProjectBalanceResponse | null>(
		currencies?.length ? currencies.find((coin) => coin.id === accountId) || null : null,
	);

	const { getViewDecimal } = useViewDecimals();

	const viewDecimals = isSelect?.currency.code
		? getViewDecimal(isSelect?.currency.code)
		: undefined;

	const onData = (values: ITransferRequest) => {
		// const id = values;
		// if (!values.currency_setting_id) id.currency_setting_id = isSelect?.currency_setting_id;
		setData({ ...data, ...values });
	};

	const onSubmit = (values: IValues) => {
		const defaultData = {
			from_project_id: projectList?.[0]?.id,
			to_project_id: projectList?.filter((project) => project.id !== projectChose?.id)[0]?.id,
			currency_setting_id: isSelect?.currency_setting_id || currencies?.[0].currency.id,
			amount: values.amount,
		};
		dispatch(cryptoTransferRequest({ data: { ...defaultData, ...data }, closePopUp: onPopUps }));
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-540">
					<button
						aria-label="button"
						onClick={() => onPopUps('')}
						type="button"
						className="popup__close"
					>
						<PopUpCloseIcon />
					</button>
					<div className="popup-header">
						<p className="popup-header__title popup-header__title--ta-l ">Transfer</p>
					</div>
					<Formik
						initialValues={{
							amount: '',
						}}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ values, isSubmitting, setFieldValue }) => {
							const { amount } = values;

							return (
								<Form>
									<div className="popup-body popup-body--type3">
										<SelectDropFrom
											data={
												projectList?.filter(
													(project) => project.id === projectChose?.id,
												) as TApiGetProjectListResponse
											}
											accountId={accountId}
											onSubmit={onData}
										/>
										<SelectDropTo
											onSubmit={onData}
											data={
												projectList?.filter(
													(project) => project.id !== projectChose?.id && project.is_active === 1,
												) as TApiGetProjectListResponse
											}
											accountId={accountId}
										/>
										<div className="select-block">
											<p className="select-block__name">Currency</p>
											{currencies && (
												<SelectDropCoin
													onSubmit={onData}
													currencies={currencies}
													isSelect={isSelect}
													setIsSelect={setIsSelect}
													currencyAll={currencyAll}
													viewDecimals={viewDecimals}
												/>
											)}
										</div>
										<TextField
											name="amount"
											label="Amount"
											type="number"
											placeholder="0.00"
											inputProps={{
												onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => {
													const regex = /^[0-9.,]*$/;
													const input = event.key;
													if (!regex.test(input)) {
														event.preventDefault();
													}
												},
											}}
											classNameInputItem="input-item--pr-75"
											rightAdornment={
												<button
													aria-label="button"
													onClick={() => setFieldValue('amount', String(isSelect?.balance) || '')}
													type="button"
												>
													MAX
												</button>
											}
										/>
									</div>
									<div className="popup-button popup-button--type2">
										<button
											onClick={() => onPopUps('')}
											type="button"
											className="button button--full-width button--type2"
										>
											Cancel
										</button>
										<button
											disabled={!amount || isSubmitting}
											type="submit"
											className="button button--full-width"
										>
											Transfer
										</button>
									</div>
								</Form>
							);
						}}
					</Formik>
				</div>
			</div>
		</div>
	);
};
