import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IProviderWalletAddressesResponse,
	IWalletAddressesMerchantIdResponse,
	IWalletAddressesMerchantIdRequest,
} from './types';

import {
	getProviderWalletAddressesRequest,
	getProviderWalletAddressesSuccess,
	getProviderWalletAddressesClientRequest,
	getProviderWalletAddressesSuccessClient,
	getOwnerMerchantIdRequest,
	getOwnerMerchantIdSuccess,
} from './reducer';

function* getProviderWalletAddressesWorker({ payload }: PayloadAction<any>) {
	const { apiParams, onSuccess, onFinally } = payload;
	try {
		const data: IProviderWalletAddressesResponse = yield call(
			api.provider.getProviderWalletAddresses,
			apiParams,
		);

		yield put(getProviderWalletAddressesSuccess(data));
	} catch (error) {
		showError(error);
	} finally {
		onFinally?.();
	}
}

function* getProviderWalletAddressesClientWorker({ payload }: PayloadAction<any>) {
	const { apiParams, onSuccess, onFinally } = payload;
	try {
		const data: IProviderWalletAddressesResponse = yield call(
			api.provider.getProviderWalletAddressesByClient,
			apiParams,
		);

		yield put(getProviderWalletAddressesSuccessClient(data));
	} catch (error) {
		showError(error);
	} finally {
		onFinally?.();
	}
}

function* getOwnerMerchantIdWorker({ payload }: PayloadAction<IWalletAddressesMerchantIdRequest>) {
	const { params, checkId } = payload;
	try {
		const response: IWalletAddressesMerchantIdResponse = yield call(
			api.provider.getOwnerMerchantId,
			params,
		);
		if (response?.owner_merchant_id) {
			checkId(String(response?.owner_merchant_id));
		}

		yield put(getOwnerMerchantIdSuccess(response));
	} catch (error) {
		showError(error);
	}
}

export function* providerWalletAddressesSaga() {
	yield takeLatest(getProviderWalletAddressesRequest, getProviderWalletAddressesWorker);
	yield takeLatest(getProviderWalletAddressesClientRequest, getProviderWalletAddressesClientWorker);
	yield takeLatest(getOwnerMerchantIdRequest, getOwnerMerchantIdWorker);
}
