import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IMerchantGetProjectReportsPayload,
	IGetReportsResponse,
	IPostReportsRequset,
	IReportProviderTransactionPayload,
} from 'redux/reducers/projectReports/types';

import {
	getReportsRequest,
	getReportsSuccess,
	getReportsError,
	createReportRequset,
	getReportsUpdateRequest,
	createReportTransactionProviderRequest,
	createReportTransactionProviderSuccess,
} from './reducer';

function* getReportsRequestWorker({ payload }: PayloadAction<IMerchantGetProjectReportsPayload>) {
	try {
		const response: IGetReportsResponse = yield call(api.project.getReports, payload);
		yield put(getReportsSuccess(response));
	} catch (error) {
		yield put(getReportsError());
		showError(error);
	}
}

function* createReportRequestWorker({ payload }: PayloadAction<IPostReportsRequset>) {
	const { dataPayload, onSuccess } = payload;
	try {
		yield call(api.project.createReports, dataPayload);
		onSuccess?.();
	} catch (error) {
		// yield put(viewCallbackError());
		showError(error);
	}
}

function* createReportTransactionRequestWorker({
	payload,
}: PayloadAction<IReportProviderTransactionPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		createReportTransactionProviderSuccess();
		yield call(api.project.postReportsProviderTransaction, payload);
		onSuccess?.();
	} catch (error) {
		// yield put(viewCallbackError());
		showError(error);
	}
}

export function* projectReportsSaga() {
	yield takeLatest([getReportsRequest, getReportsUpdateRequest], getReportsRequestWorker);
	yield takeLatest(createReportRequset, createReportRequestWorker);
	yield takeLatest(createReportTransactionProviderRequest, createReportTransactionRequestWorker);
}
