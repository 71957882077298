import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from 'UI/Pagination/Pagination';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import {
	getProviderCurrencyAllLoading,
	getProviderWalletsAddressesData,
} from 'redux/reducers/projectWalletAddresses/selectors';
import { getProviderWalletAddressesRequest } from 'redux/reducers/projectWalletAddresses/reducer';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import Main from 'v2Components/layoutsComponents/Main';
import { BackToMerchantProjects } from 'UI/GoBackLinks/Provider/BackToMerchantProjects/BackToMerchantProjects';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import { getFilter } from 'redux/reducers/app/selectors';
import useFilterList from 'hooks/useFilterList';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Table from 'v2Components/commomComponents/Table';
import { IProviderItem } from 'redux/reducers/providerWalletAddresses/types';
import Filters from '../WalletAddressesTable/Filters/Filters';
import { getFilterWalletAddressesHistory } from '../../../Provider/WalletAddressesPage/WalletAddresses/WalletAddressesTable/filter.helper';
import TableRow from '../WalletAddressesTable/TableRow';
import styles from '../styles.module.scss';

export const WalletAddressProvider = () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const walletAddresses = useSelector(getProviderWalletsAddressesData);
	const loading = useSelector(getProviderCurrencyAllLoading);
	const [currentPage, setCurrentPage] = useState(1);
	const [firstLoading, setFirstLoading] = useState(true);
	const { projectId } = useParams<{ merchantId: string; projectId: string }>();
	const totalPages = walletAddresses?.last_page || 1;
	const location = useLocation();
	const [showFilter, setShowFilter] = useState(false);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('ProjectWalletAddress');
	const filterList = useFilterList(getFilterWalletAddressesHistory);
	const isShowPagination = !firstLoading && totalPages > 1;
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());

	const onSubmit = (data: any) => {
		setCurrentPage(1);
		dispatch(setFilter(data));
		setShowFilter(false);
	};

	useEffect(() => {
		location &&
			dispatch(
				getProviderWalletAddressesRequest({
					apiParams: {
						...filter,
						current_page: currentPage,
						project_id: projectId,
					},
					onFinally: () => setFirstLoading(false),
				}),
			);
	}, [currentPage, dispatch, filter, location, projectId]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<Main>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
				className={styles.filters}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<Filters />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				gridTemplateColumns={gridTemplateColumns}
				getRow={(item: IProviderItem) => TableRow({ item })}
				bodyItems={walletAddresses?.data || []}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</PaginationWrapper>
			)}
		</Main>
	);
};
