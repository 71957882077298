import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	addBankAccountRequest,
	addCryptoWalletRequest,
	getBankAccountsRequest,
	getCryptoWalletsRequest,
	getTotalBalancesInFiatRequest,
} from 'redux/reducers/providerBankAccount/reducer';
import {
	getBankAccountNotDeleted,
	getCryptoWalletList,
} from 'redux/reducers/providerBankAccount/selectors';
import { IAddCryptoWalletParams } from 'services/api/provider/types';
import { notify } from 'utils/notify';
import {
	getProviderAllCurrencyRequest,
	getProviderAllCurrencySettingRequest,
} from 'redux/reducers/providerCurrency/reducer';
import CryptoWallet from './CryptoWallet';
import BankAccounts from './BankAccounts';
import CryptoWalletForm from './CryptoWallet/CryptoWalletForm';
import BankAccountsForm from './BankAccounts/BankAccountsForm';
import NoAccount from './NoAccount';
import { TAddBankAccountFormValues } from './types';
import styles from './styles.module.scss';

export default () => {
	const dispatch = useDispatch();
	const bankList = useSelector(getBankAccountNotDeleted);
	const walletList = useSelector(getCryptoWalletList);
	const countItems = (bankList?.length || 0) + (walletList?.length || 0);
	const [showFilter, setShowFilter] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const handleAddBankAccount = (values: TAddBankAccountFormValues, resetForm: () => void) =>
		dispatch(
			addBankAccountRequest({
				apiParams: {
					...values,
					is_provider: true,
					currency_name: 'eur',
				},
				onSuccess: () => {
					setShowFilter(false);
					resetForm();
					notify('Bank account has been successfully added', 'success');
				},
			}),
		);
	const handleAddWallet = (values: IAddCryptoWalletParams, resetForm: () => void) =>
		dispatch(
			addCryptoWalletRequest({
				apiParams: values,
				onSuccess: () => {
					setOpenModal(false);
					resetForm();
					notify('Wallet has been successfully added', 'success');
				},
			}),
		);
	useEffect(() => {
		dispatch(getProviderAllCurrencySettingRequest({ apiParams: {} }));
		dispatch(getProviderAllCurrencyRequest());
		dispatch(getCryptoWalletsRequest());
		dispatch(getBankAccountsRequest());
		dispatch(getTotalBalancesInFiatRequest());
	}, [dispatch]);

	return (
		<div className={styles.main}>
			{countItems ? (
				<>
					<p className={styles.title}>Bank Accounts</p>
					<BankAccounts bankList={bankList} />
					<p className={styles.title}>Crypto Wallet</p>
					<CryptoWallet />
				</>
			) : (
				<NoAccount setShowFilter={setShowFilter} setOpenModal={setOpenModal} />
			)}
			<BankAccountsForm
				setShowFilter={setShowFilter}
				showFilter={showFilter}
				onSubmit={handleAddBankAccount}
			/>
			<CryptoWalletForm
				setShowFilter={setOpenModal}
				showFilter={openModal}
				onSubmit={handleAddWallet}
			/>
		</div>
	);
};
