import { useSelector } from 'react-redux';
import {
	getProviderCurrencyAllLoading,
	getProviderCurrencyCrypto,
} from 'redux/reducers/providerCurrency/selectors';
import { TApiGetProviderAllCurrencySettingResponse } from 'redux/reducers/providerCurrency/types';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Table from 'v2Components/commomComponents/Table';
import TableRow from './TableRow';
import { IInSubmitProps } from '../types';

interface IProps {
	onSubmit: (body: IInSubmitProps) => void;
}

const TableCrypto = ({ onSubmit }: IProps): JSX.Element => {
	const itemsCrypto = useSelector(getProviderCurrencyCrypto);
	const loading = useSelector(getProviderCurrencyAllLoading);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('WalletsBalancesCrypto');
	return (
		<Table
			loading={loading}
			headerItems={tableHeaderItems}
			bodyItems={itemsCrypto}
			getRow={(item: TApiGetProviderAllCurrencySettingResponse) =>
				TableRow({
					currency: item,
					onSubmit,
				})
			}
			gridTemplateColumns={gridTemplateColumns}
		/>
	);
};

export default TableCrypto;
