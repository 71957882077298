import { AuthHeader } from 'layouts-elements/AuthHeader/AuthHeader';
import { ProjectInvoiceLink } from 'components/Project/ProjectInvoiceLink/ProjectInvoiceLink';

export const ProjectInvoiceLinkPage = () => {
	return (
		<>
			<AuthHeader />
			<ProjectInvoiceLink />
		</>
	);
};
