import { FC } from 'react';
import Button from 'v2Components/UI/Button';
import { useSelect } from 'hooks';
import classNames from 'classnames';
import { PlusBlueIcon } from 'assets/inline-svg';
import styles from './styles.module.scss';
import { ReportCreateMenu } from './ReportCreateMenu';
import { IReportCreateButtonProps } from './types';

export const ReportCreateButton: FC<IReportCreateButtonProps> = ({ getCreateReport }) => {
	const { open, toggleOpen, triggerRef, dropRef } = useSelect();
	return (
		<div className={classNames(styles.reportCreateButton, { active: open })}>
			<button
				type="button"
				ref={triggerRef}
				onClick={toggleOpen}
				className={classNames(
					styles.reportCreateButton__wrapper,
					styles.reportCreateButton__button,
				)}
			>
				<PlusBlueIcon /> Create report
			</button>

			<ReportCreateMenu
				isOpen={open}
				menuRef={dropRef}
				onClose={toggleOpen}
				getCreateReport={getCreateReport}
			/>
		</div>
	);
};
