import { MouseEvent, FC, useState } from 'react';
import classNames from 'classnames';
import {
	DuplicateIcon,
	FileIcon,
	TogglerOffIcon,
	TogglerOnIcon,
	TrashIcon,
} from 'assets/inline-svg';
import { useMerchantMatchUrl, useSelect } from 'hooks';
import { useHistory } from 'react-router';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from 'utils/notify';
import {
	activateProjectMerchantRequest,
	activateProjectRequest,
	deactivateProjectMerchantRequest,
	deactivateProjectRequest,
	deleteProjectMerchantRequest,
	deleteProjectRequest,
} from 'redux/reducers/merchantProjects/reducer';
import { IDeleteProjectPayload } from 'redux/reducers/merchantProjects/types';
import { getUserId, getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { IProjectMenuProps, TModalAction } from './types';
import { ProjectMenuButton } from './ProjectMenuButton/ProjectMenuButton';
import { DeleteProject } from './DeleteProject/DeleteProject';
import styles from './styles.module.scss';

export const ProjectMenu: FC<IProjectMenuProps> = ({ item }) => {
	const history = useHistory();
	const merchantUrl = useMerchantMatchUrl();
	const merchantId = useSelector(getUserId);
	const dispatch = useDispatch();
	const { open, toggleOpen, triggerRef, dropRef } = useSelect();
	const userType = useSelector(getUserType);
	const [modalAction, setModalAction] = useState<TModalAction>(null);

	const closeModal = () => setModalAction(null);

	const handleProjectDetails = () =>
		history.push(`${merchantUrl || `/merchant/${String(merchantId)}`}/project/${item.id}`);

	const handleToggleActive = () => {
		const activateMsg = 'Activating a project successfully';
		const deactivateMsg = 'Deactivating a project successfully';

		const payload = {
			apiParams: { project_id: item.id },
			onSuccess: () => notify(item.is_active ? deactivateMsg : activateMsg, 'success'),
		};
		if (item.is_active) {
			dispatch(
				userType === constUserTypes.PROVIDER
					? deactivateProjectRequest(payload)
					: deactivateProjectMerchantRequest(payload),
			);
		} else {
			dispatch(
				userType === constUserTypes.PROVIDER
					? activateProjectRequest(payload)
					: activateProjectMerchantRequest(payload),
			);
		}
		toggleOpen();
	};

	const handleOpenMenu = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();

		toggleOpen();
	};

	const handleDelete = () => {
		setModalAction('delete');
		toggleOpen();
	};

	const handleDeleteConfirm = () => {
		const payload: IDeleteProjectPayload = {
			apiParams: { project_id: item.id },
			onSuccess: () => {
				closeModal();
				notify('Deleting a project successfully', 'success');
			},
		};
		dispatch(
			userType === constUserTypes.PROVIDER
				? deleteProjectRequest(payload)
				: deleteProjectMerchantRequest(payload),
		);
	};

	return (
		<div className={styles.projectMenu}>
			<button
				aria-label="button"
				type="button"
				className="project__btn"
				ref={triggerRef}
				onClick={handleOpenMenu}
			>
				<span />
				<span />
				<span />
			</button>
			<div
				className={classNames(styles.dropdown, { [styles.dropdown_active]: open })}
				ref={dropRef}
			>
				<ul className="dropdown-list">
					<li className="dropdown-item">
						<ProjectMenuButton
							title="Project details"
							icon={FileIcon}
							onClick={handleProjectDetails}
						/>
					</li>
					<li className="dropdown-item">
						<ProjectMenuButton
							title="Duplicate"
							icon={DuplicateIcon}
							projectId={item.id}
							merchantId={merchantId}
							merchantUrl={merchantUrl}
						/>
					</li>
					<li className="dropdown-item">
						<ProjectMenuButton
							title={item.is_active ? 'Deactivate' : 'Activate'}
							icon={item.is_active ? TogglerOnIcon : TogglerOffIcon}
							onClick={handleToggleActive}
						/>
					</li>
					<li className="dropdown-item">
						<ProjectMenuButton title="Delete" icon={TrashIcon} onClick={handleDelete} />
					</li>
				</ul>
			</div>
			<PopupModal open={modalAction === 'delete'} onClose={closeModal}>
				<DeleteProject onClose={closeModal} onDelete={handleDeleteConfirm} />
			</PopupModal>
		</div>
	);
};
