import { FC, useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { routes } from 'routes/routes';
import Main from 'v2Components/layoutsComponents/Main';
import { endpoint } from 'services/endpoint';
import { TCurrency } from './Transactions/CryptoFiatSwitch/types';
import { getCreateReport } from './Transactions/report.helper';
import FiatTable from './Transactions/FiatTable';
import CryptoTable from './Transactions/CryptoTable';
import WalletHeader from '../WalletsPage/components/WalletsHeader';

export const TransactionsPage: FC = () => {
	const { path } = useRouteMatch();
	const url_string = window.location.href;
	const urlObg = new URL(url_string);
	const paymentTransactionsMatch = useRouteMatch(path + routes.wallets.paymentTransactions);
	const transactionDetailsMatch = useRouteMatch(path + routes.wallets.transactionHistoryDetails);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tabs = searchParams.get('tabs');
	const [currencyTab, setCurrencyTab] = useState<TCurrency>((tabs as TCurrency) || 'crypto');

	useEffect(() => {
		const search = new URLSearchParams(location.search);
		const value = search.get('tabs');
		setCurrencyTab(value === 'fiat' ? 'fiat' : 'crypto');
	}, [location.search]);

	return (
		<Main
			header={
				<WalletHeader
					disabled={!!transactionDetailsMatch || !!paymentTransactionsMatch}
					url={urlObg}
					title="Transactions"
					downloadReportUrl={
						currencyTab === 'crypto'
							? endpoint.provider.DOWNLOAD_CRYPTO_TRANSACTIONS_REPORT
							: endpoint.provider.DOWNLOAD_FIAT_TRANSACTIONS_REPORT
					}
					getCreateReport={(params, tab, reportFormat) =>
						getCreateReport({ ...params, isCrypto: currencyTab === 'crypto' }, tab, reportFormat)
					}
				/>
			}
		>
			{currencyTab === 'crypto' ? (
				<CryptoTable currencyTab={currencyTab} />
			) : (
				<FiatTable currencyTab={currencyTab} />
			)}
		</Main>
	);
};
