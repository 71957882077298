import moment from 'moment';
import { FC } from 'react';
import { IHistory } from 'redux/reducers/providerOrders/types';

interface IProps {
	history: Array<IHistory>;
}

export const OrderHistory: FC<IProps> = ({ history }) => {
	return (
		<>
			<div className="block-title block-title--line2">
				<p>Status history</p>
			</div>
			<div className="approvements-list">
				<div className="approvements-column">
					{history.map((item) => (
						<div key={item.id} className="approvements-item approvements-item--status">
							<span>{moment(item.created_at).format('DD.MM.YYYY, HH:mm')} UTC</span>
							<span>Status created by Automator</span>
							<span className="approvements-item__status green">{item.status}</span>
						</div>
					))}
				</div>
			</div>
		</>
	);
};
