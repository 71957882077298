import moment from 'moment';
import { TApiGetProviderAllCurrencyResponse } from 'services/api/provider/types';
import { IGetCurrencyNetworkItem } from 'services/api/public/types';
import { IFilterElemItem } from 'v2Components/layoutsComponents/Filter/types';
import { TEventOptions } from './Filters/EventSelect/types';
import { TTypeOptions } from './Filters/TypeSelect/types';

export const OPTIONS_EVENT: TEventOptions = [
	{
		value: 'transaction_created',
		label: 'Transaction created',
	},
	{
		value: 'transaction_confirmed',
		label: 'Transaction confirmed',
	},
	{
		value: 'status_changed',
		label: 'Status changed',
	},
];

export const OPTIONS_TYPE: TTypeOptions = [
	{
		value: 'invoice',
		label: 'Invoice',
	},
	{
		value: 'deposit',
		label: 'Deposit',
	},
];

export interface TransactionsHistory {
	id: string;
	value: string | number | Date | null;
	networks?: IGetCurrencyNetworkItem[];
	currencies?: TApiGetProviderAllCurrencyResponse[];
}

export const getFilterProjectCallbacksHistory = ({
	id,
	value,
	networks,
	currencies,
}: TransactionsHistory): IFilterElemItem => {
	switch (id) {
		case 'date_from':
			return { identify: id, content: `from: ${moment(value).format('DD MMM YY, hh:mm a')}` };
		case 'date_to':
			return { identify: id, content: `from: ${moment(value).format('DD MMM YY, hh:mm a')}` };
		case 'event': {
			const content = OPTIONS_EVENT.find((event) => event.value === value);
			return { identify: id, content: content?.label || '' };
		}
		case 'type': {
			const content = OPTIONS_TYPE.find((event) => event.value === value);
			return { identify: id, content: content?.label || '' };
		}
		case 'response_code':
			return { identify: id, content: value as string };
		default:
			return { identify: '', content: '' };
	}
};
