import { FC, useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { routes } from 'routes/routes';
import { Link, useParams } from 'react-router-dom';
import { useProjectMatchUrl } from 'hooks';
import { ArrowLeftIcon } from 'assets/inline-svg';
import moment from 'moment';
import { getCallbackView } from 'redux/reducers/projectCallbacks/selectors';
import {
	viewCallbackRequest,
	resendCallbackRequest,
} from 'redux/reducers/projectCallbacks/reducer';
import { IResendCallbackPayload } from 'redux/reducers/projectCallbacks/types';
import { notify } from 'utils/notify';
import Main from 'v2Components/layoutsComponents/Main';
import classNames from 'classnames';
import { Tabs } from '../Tabs/Tabs';
import styles from './styles.module.scss';

export const ProjectCallbacksInfo: FC = () => {
	const dispatch = useDispatch();
	const projectUrl = useProjectMatchUrl();
	const [activeTab, setActiveTab] = useState('request');
	const { id } = useParams<{ id: string }>();
	const callbackView = useSelector(getCallbackView);
	const { callback, transaction } = callbackView?.data || {};

	const {
		created_at: createdAt,
		updated_at: updatedAt,
		creator_entity_id: creatorEntityId,
		request_body: requestBody,
		response_body: responseBody,
		response_code: responseCode,
		history,
	} = callback || {};

	const requestBodyParsed = requestBody && JSON?.parse(requestBody);
	let responseBodyParsed = responseBody;
	try {
		responseBodyParsed =
			responseBody && responseCode === 200 ? JSON?.parse(responseBody) : 'Not found response';
		// eslint-disable-next-line no-empty
	} catch (e) {}
	const requestBodyFormatted = requestBodyParsed && JSON?.stringify(requestBodyParsed, null, 4);
	const responseBodyFormatted =
		responseBodyParsed && responseCode === 200
			? JSON?.stringify(responseBodyParsed, null, 4)
			: responseBodyParsed;

	const {
		status,
		risk_score: riskScore,
		currency_name: currencyName,
		address_from,
	} = transaction || {};

	const statusColor = useMemo(() => (status === 'success' ? 'green' : ''), [status]);

	useEffect(() => {
		dispatch(viewCallbackRequest({ callback_id: +id }));
	}, [dispatch, id]);

	const handleResend = () => {
		const payload: IResendCallbackPayload = {
			apiParams: {
				id: Number(id),
			},
			onSuccess: () => notify('Callback was successfully resent', 'success'),
		};
		dispatch(resendCallbackRequest(payload));
	};

	return (
		<Main
			header={
				<Link to={projectUrl + routes.oneProject.callbacksBase} className="back-page">
					<ArrowLeftIcon />
					<span>Back to Callbacks</span>
				</Link>
			}
		>
			<div className="content-header">
				<div>
					<p className="form-title">Callback {id}</p>
					<p>
						<span className="grey">
							Status: <span className={classNames(statusColor, 'capitalize')}>{status}</span>
						</span>
					</p>
				</div>
				<div className="content-header__actions">
					<button className="button" type="button" onClick={handleResend}>
						Resend the status
					</button>
				</div>
			</div>
			<div className="fields-wrap">
				<div className="fields-item">
					<div className="fields-list">
						<div className="line-info-block">
							<div className="line-info line-info--type2">
								<p className="line-info__name">ID:</p>
								<p className="line-info__text">{id}</p>
							</div>
							<div className="line-info line-info--type2">
								<p className="line-info__name">Created:</p>
								<p className="line-info__text">
									{createdAt && moment(createdAt).utc().format('YYYY-MM-DD, HH:mm:ss')} UTC
								</p>
							</div>
							<div className="line-info line-info--type2">
								<p className="line-info__name">Address:</p>
								<p className="line-info__text">{address_from}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="fields-item">
					<div className="fields-list">
						<div className="line-info-block">
							<div className="line-info line-info--type2">
								<p className="line-info__name">TX ID</p>
								<p className="line-info__text">{creatorEntityId}</p>
							</div>
							<div className="line-info line-info--type2">
								<p className="line-info__name">Last attempt at:</p>
								<p className="line-info__text">
									{updatedAt && moment(updatedAt).utc().format('YYYY-MM-DD, HH:mm:ss')} UTC
								</p>
							</div>
							<div className="line-info line-info--type2">
								<p className="line-info__name">HTTP status:</p>
								<p className="line-info__text">{responseCode}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.request}>
				<Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
				{/* <div className="request-title">
					<p>Request</p>
				</div> */}
				<div className="request-info">
					<p>{activeTab === 'request' ? requestBodyFormatted : responseBodyFormatted}</p>
				</div>
			</div>
			{history && history.length > 0 && (
				<>
					<div className={styles.title}>Response history</div>
					<div className={styles.approvements}>
						{history.map((h) => (
							<div className={styles.approvements__item} key={h.id}>
								<span className="">
									{h.created_at && moment(h.created_at).utc().format('YYYY-MM-DD, HH:mm:ss')} UTC
								</span>
								<div className={styles.requestInfo}>
									<p>{h.response_body}</p>
								</div>
								<span className={styles.approvements__itemStatus}>{h.response_code}</span>
							</div>
						))}
					</div>
				</>
			)}
		</Main>
	);
};
