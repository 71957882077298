import { IFilter } from 'redux/reducers/app/types';
import { FilterForm } from 'v2Components/layoutsComponents/Filter';
import FilterCryptoBody from '../FilterCryptoBody';
import FilterFiatBody from '../FilterFiatBody';

interface ITransactionsHistoryCryptoFilter {
	showFilter: boolean;
	setShowFilter: (value: boolean) => void;
	currencyTab: string;
	onSubmit: (data: IFilter) => void;
}

const TransactionsHistoryFormFilter = ({
	showFilter,
	setShowFilter,
	currencyTab,
	onSubmit,
}: ITransactionsHistoryCryptoFilter): JSX.Element => {
	const isCrypto = currencyTab === 'crypto';

	return (
		<FilterForm
			showFilter={showFilter}
			setShowFilter={setShowFilter}
			onSubmit={onSubmit}
			renderBodyForm={
				isCrypto ? (
					<FilterCryptoBody isCrypto={isCrypto} showFilter={showFilter} />
				) : (
					<FilterFiatBody showFilter={showFilter} />
				)
			}
		/>
	);
};

export default TransactionsHistoryFormFilter;
