export const cryptoType = [
	{ label: 'All', value: 'all' },
	{ label: 'Deposit', value: 'deposit' },
	{ label: 'Payment', value: 'payment' },
	{ label: 'Withdrawal', value: 'withdrawal' },
	{ label: 'Auto withdrawal', value: 'auto_withdrawal' },
	{ label: 'Transfer', value: 'transfer' },
	{ label: 'Exchange', value: 'exchange' },
	{ label: 'Auto exchange', value: 'auto_exchange' },
	{ label: 'Refund', value: 'refund' },
];
export const fiatType = [
	{ label: 'ALL', value: 'all' },
	{ label: 'Deposit', value: 'deposit' },
	{ label: 'Withdrawal', value: 'withdrawal' },
	{ label: 'Transfer', value: 'transfer' },
	{ label: 'Exchange', value: 'exchange' },
	{ label: 'Auto exchange', value: 'auto_exchange' },
];
export const cryptoStatus = [
	{ label: 'All', value: 'all' },
	{ label: 'Pending', value: 'pending' },
	{ label: 'Success', value: 'success' },
	{ label: 'Failed', value: 'failed' },
];
export const fiatStatus = [
	{ label: 'All', value: 'all' },
	{ label: 'In approvement', value: 'in_approvement' },
	{ label: 'Success', value: 'success' },
	{ label: 'Rejected', value: 'rejected' },
];
