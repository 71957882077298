import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { getCountriesRequest } from 'redux/reducers/app/reducer';
import { getCountries } from 'redux/reducers/app/selectors';
import { TextField } from 'UI/Forms/TextField/TextField';
import { PhoneInputField } from 'UI/Forms/PhoneInputField/PhoneInputField';
import { toUTC } from 'utils/dates';
import { CountriesSelect } from './CountriesSelect/CountriesSelect';
import { IValues, IClientEditForm } from './types';

export type { IValues };

export const ClientEditForm: FC<IClientEditForm> = ({ client, onSubmit }) => {
	const dispatch = useDispatch();
	const countries = useSelector(getCountries);
	const { id, external_id } = client;
	const {
		first_name: firstName,
		last_name: lastName,
		email,
		phone,
		created_at: createdAt,
		city,
		citizenship,
		country,
		address,
		level_trust: levelTrust,
		level_kyc: levelKyc,
	} = client || {};

	const phoneCode = phone && phone.slice(0, phone.length - 9).replace('+', '');
	const phoneNumber = phone && phone.slice(phone.length - 9);

	const initialValues: IValues = {
		firstName: firstName || '',
		lastName: lastName || '',
		email: email || '',
		phoneCode: phoneCode || '',
		phoneNumber: phoneNumber || '',
		citizenship: citizenship || '',
		residency: country || '',
		time: createdAt ? toUTC(new Date(createdAt)) : '',
		address: address || '',
		levelTrust: levelTrust ? String(levelTrust) : '',
		levelKyc: levelKyc ? String(levelKyc) : '',
	};

	useEffect(() => {
		dispatch(getCountriesRequest());
	}, [dispatch]);

	const validationSchema = yup.object().shape({
		firstName: yup.string().required('First name is required!'),
		lastName: yup.string().required('Last name is required!'),
		// address: yup.string().required('Address is required!'),
		phone: yup
			.string()
			.matches(/^\+?\d+$/, 'Invalid phone number format')
			.min(4, 'Phone number is to short')
			.max(20, 'Phone number is too long'),
	});

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{({ dirty, resetForm, values }) => (
				<Form>
					<div className="content-header">
						<p className="form-title">Clients {id}</p>
						<div className="content-header">
							<button className="button button--type3" type="button" onClick={() => resetForm()}>
								Cancel
							</button>
							<button className="button" type="submit" disabled={!dirty}>
								Save
							</button>
						</div>
					</div>
					<div className="main-info-category">
						<h4 className="category-title">Main Information</h4>
						<div className="input-line input-line--type2">
							<TextField name="firstName" label="First name" />

							<TextField name="lastName" label="Last name" />

							<TextField name="email" label="Email" placeholder="Email" />

							<TextField
								name="time"
								label="Internal ID"
								inputProps={{ readOnly: true, value: external_id || '' }}
							/>
						</div>
						<div className="input-line">
							<div className="input">
								<p className="input-name">Citizenship</p>
								<div className="input-wrapper">
									<Field
										name="citizenship"
										className="input-item "
										placeholder="Citizenship"
										component={CountriesSelect}
									/>
									<ErrorMessage className="error-message" name="citizenship" component="span" />
								</div>
							</div>
							<div className="input">
								<p className="input-name">Residency</p>
								<div className="input-wrapper">
									<Field
										name="residency"
										className="input-item "
										placeholder="Citizenship"
										component={CountriesSelect}
									/>
									<ErrorMessage className="error-message" name="citizenship" component="span" />
								</div>
							</div>
							<TextField name="time" label="Time of creation" inputProps={{ readOnly: true }} />
							<PhoneInputField
								name="phoneNumber"
								codeFieldName="phoneCode"
								label="Phone"
								countries={countries || []}
							/>
						</div>
					</div>
					<div className="main-info-category">
						<h4 className="category-title">Other Information</h4>
						<div className="input-line">
							<div className="input">
								<p className="input-name">Address</p>
								<div className="input-wrapper">
									<Field name="address" className="input-item " placeholder="Address" />
									<ErrorMessage className="error-message" name="address" component="span" />
								</div>
							</div>
							<div className="input">
								<p className="input-name">Trust level</p>
								<div className="input-wrapper">
									<Field name="levelTrust" className="input-item " placeholder="Trust level" />
									<ErrorMessage className="error-message" name="levelTrust" component="span" />
								</div>
							</div>
							<div className="input">
								<p className="input-name">KYC Level</p>
								<div className="input-wrapper">
									<Field name="levelKyc" className="input-item " placeholder="KYC Level" />
									<ErrorMessage className="error-message" name="levelKyc" component="span" />
								</div>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
