import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SearchIcon } from 'assets/inline-svg';
import { getMerchantOwnersRequest } from 'redux/reducers/providerMerchant/reducer';
import { useParams } from 'react-router-dom';
import { getProviderAllCurrencyRequest } from 'redux/reducers/providerCurrency/reducer';
import { getNetworkRequest } from 'redux/reducers/app/reducer';
import { SelectFieldCurrency } from 'UI/SelectFieldCurrency/SelectFieldCurrency';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { TextField } from 'v2Components/formComponents/TextField';
import { DatepickerField } from 'v2Components/formComponents/DatepickerField';
import { IFilters } from './types';
import { cryptoType, fiatType, cryptoStatus, fiatStatus } from './utils';
import styles from './styles.module.scss';

export const Filters: FC<IFilters> = ({ values, isFiat }) => {
	const dispatch = useDispatch();
	const { merchantId: merchantTrue } = useParams<{
		projectId: string;
		merchantId: string;
	}>();
	useEffect(() => {
		if (merchantTrue) return;
		dispatch(getMerchantOwnersRequest({ apiParams: {} }));
	}, [dispatch, merchantTrue]);

	useEffect(() => {
		dispatch(getProviderAllCurrencyRequest());
		dispatch(getNetworkRequest());
	}, [dispatch]);
	return (
		<>
			<SelectFieldCurrency />
			<SelectField
				name="type"
				title="Type"
				options={isFiat ? fiatType : cryptoType}
				renderOption={({ label }) =>
					label === 'All' ? <span className={styles.selectAll}>{label}</span> : label || ''
				}
			/>
			<DatepickerField
				label="Date from"
				maxDate={(values?.dateTo as Date) || null}
				name="date_from"
				inFilter
			/>
			<DatepickerField
				label="Date to"
				minDate={(values?.dateFrom as Date) || null}
				name="date_to"
				inFilter
			/>
			<TextField name="amount_from" type="number" label="Amount From" placeholder="Enter" />
			<TextField name="amount_to" type="number" label="Amount To" placeholder="Enter" />
			<TextField
				name="transaction_id"
				label="Tx ID"
				placeholder="Index"
				renderRightIcon={<SearchIcon />}
			/>
			<SelectField name="status" title="Status" options={isFiat ? fiatStatus : cryptoStatus} />
		</>
	);
};
