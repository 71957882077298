/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { confirmResetPassRequest } from 'redux/reducers/auth/reducer';
import { SuccessIP } from 'UI/SuccessAuth/SuccessAuth';
import { Success } from 'UI/Success/Success';
import { ResetPasswordForm } from './ResetPasswordForm/ResetPasswordForm';

export const ResetPassword: FC = () => {
	const [success, setSuccess] = useState(false);
	const [emailSuccess, setEmailSuccess] = useState<string>('');
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');

	const createNewPassSubmit = (values: { password: string; totp: string }) => {
		dispatch(
			confirmResetPassRequest({
				apiParams: {
					...values,
					token: token || '',
				},
				history,
				onSuccess: setSuccess,
				onEmailSend: setEmailSuccess,
			}),
		);
	};

	const initialValues = {
		password: '',
		confirmPassword: '',
		totp: '',
	};

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleConfirmPassDisplay = (): void => {
		setIsShowConfirmPass(!isShowConfirmPass);
	};

	return (
		<main className="main">
			<section className="authorization-section">
				<div className="authorization-section__figure" />
				<button type="button" className="support-btn">
					<span className="support-btn__icon">
						<svg
							width="15"
							height="16"
							viewBox="0 0 15 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.75 1.25C10.1478 1.25 10.5294 1.40804 10.8107 1.68934C11.092 1.97064 11.25 2.35217 11.25 2.75L11.25 11.75L14.25 14.75L2.25 14.75C1.85218 14.75 1.47064 14.592 1.18934 14.3107C0.908035 14.0294 0.75 13.6478 0.75 13.25L0.749999 2.75C0.749999 2.35218 0.908035 1.97064 1.18934 1.68934C1.47064 1.40804 1.85217 1.25 2.25 1.25L9.75 1.25Z"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</span>
					Support
				</button>
				{success ? (
					emailSuccess ? (
						<Success emailSuccess={emailSuccess} />
					) : (
						<SuccessIP onClose={setSuccess} />
					)
				) : (
					<ResetPasswordForm
						createNewPassSubmit={createNewPassSubmit}
						initialValues={initialValues}
						handlePassDisplay={handlePassDisplay}
						handleConfirmPassDisplay={handleConfirmPassDisplay}
						isShowPass={isShowPass}
						isShowConfirmPass={isShowConfirmPass}
					/>
				)}
			</section>
		</main>
	);
};
