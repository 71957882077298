/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { Form, Formik } from 'formik';
import { TextField } from 'UI/Forms/TextField/TextField';
import { IResetPasswordForm } from './types';
import totpValidation from '../../../../../validations/totpValidation';

export const editBankPageSchema = () => {
	return Yup.object().shape({
		...totpValidation,
	});
};

export const ResetPasswordForm: FC<IResetPasswordForm> = ({ onClose, onSubmit }) => {
	const handleSubmit = ({ totp }: { totp: string }) => onSubmit(totp);

	return (
		<div className="popup popup--width-480">
			<button aria-label="button" type="button" className="popup__close" onClick={onClose}>
				<PopUpCloseIcon />
			</button>
			<Formik
				initialValues={{
					totp: '',
				}}
				validationSchema={editBankPageSchema}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{({ dirty, handleReset }) => (
					<Form>
						<div className="popup-header">
							<p className="popup-header__title popup-header__title--mb-32 popup-header__title--ta-l">
								Reset psassword
							</p>
						</div>

						<div className="popup-body">
							<TextField
								label="2FA 6-digit code"
								type="text"
								placeholder="Enter code"
								name="totp"
								maxLength={6}
								onlyNumbers
							/>
						</div>

						<div className="buttons-wrap">
							<div className="buttons">
								<button
									type="button"
									className="button button--type2"
									disabled={!dirty}
									onClick={() => {
										handleReset();
										onClose();
									}}
								>
									Cancel
								</button>
								<button type="submit" className="button" disabled={!dirty}>
									Reset
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};
