import { IFilter } from 'redux/reducers/app/types';
import { IFilterElemItem } from 'v2Components/layoutsComponents/Filter/types';
import { ISelect } from './types';

export const ROLES = [
	{ value: 'All', label: 'All', code: '' },
	{ value: 'MERCHANT_ADMIN', label: 'Administrator', code: 'MERCHANT_ADMIN' },
	{ value: 'MERCHANT_OPERATOR', label: 'Operator', code: 'MERCHANT_OPERATOR' },
	{ value: 'MERCHANT_FINANCIER', label: 'Financier', code: 'MERCHANT_FINANCIER' },
	{ value: 'MERCHANT_SUPPORT', label: 'Techsupport', code: 'MERCHANT_SUPPORT' },
];

export const STATUSES = [
	{ value: 'All', label: 'All', code: '' },
	{ value: 'verified', label: 'Active', code: 'verified' },
	{ value: 'unverified', label: 'Blocked', code: 'unverified' },
];

export const PROJECTS = [{ value: 0, label: 'All', code: '' }];

export interface TransactionsHistory {
	id: string;
	value: string | number | Date | null;
	projects?: Array<ISelect> | undefined;
}

export const convertRequestParams = (props: IFilter): IFilter => {
	return {
		...props,
		project: props.project || undefined,
		role: props.role || undefined,
		status: props.status || undefined,
	};
};

export const getFilterTeamMembersHistory = ({
	id,
	value,
	projects,
}: TransactionsHistory): IFilterElemItem => {
	switch (id) {
		case 'status': {
			const content = STATUSES.find((item) => item.value === value);
			return { identify: 'status', content: content?.label || '' };
		}
		case 'role': {
			const content = ROLES.find((item) => item.value === value);
			return { identify: 'role', content: content?.label || '' };
		}
		case 'project': {
			const _project = projects ? [...PROJECTS, ...projects] : PROJECTS;
			const content = _project.find((project) => project.value === value);
			return { identify: 'project', content: content?.label || '' };
		}
		default:
			return { identify: '', content: '' };
	}
};
