export const isFieldChanged = (
	currentValue: string | null | undefined,
	initialValue: string | number | null | undefined,
) => {
	if (!currentValue && !initialValue) {
		return false;
	}
	if (String(currentValue) === String(initialValue)) {
		return false;
	}

	return true;
};
