import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { routes } from 'routes/routes';
import { Form, Formik } from 'formik';

import { ArrowLeftIcon } from 'assets/inline-svg';
import { MobileMerchantInfo } from 'components/Merchant/MobileMerchantInfo/MobileMerchantInfo';
import { useMerchantMatchUrl } from 'hooks';
import { getBankAccountNotDeleted } from 'redux/reducers/merchantBankAccount/selectors';
import { getBankAccountsByOwnerRequest } from 'redux/reducers/merchantBankAccount/reducer';
import { TextField } from 'UI/Forms/TextField/TextField';
import { editBankAccountInMerchantRequest } from 'redux/reducers/providerBankAccount/reducer';
import { notify } from 'utils/notify';
import { TextareaField } from 'UI/Forms/TextareaField/TextareaField';
import { TEditBankAccountPageFormValues, TEditBankPageFormOnSubmit } from '../types';
import { editBankPageSchema } from '../BankAccounts/utils';

const EditBankProviderInMerchant = () => {
	const dispatch = useDispatch();
	const merchantUrl = useMerchantMatchUrl();
	const { merchantId, id } = useParams<{ merchantId: string; id: string }>();
	const bankList = useSelector(getBankAccountNotDeleted);

	const bankAccount = bankList?.find((el) => String(el.id) === id);

	const initialValues: TEditBankAccountPageFormValues = {
		reference: bankAccount?.reference || '',
		bic: bankAccount?.bic || '',
		bank_nickname: bankAccount?.bank_nickname || '',
		bank_name: bankAccount?.bank_name || '',
		bank_address: bankAccount?.bank_address || '',
		iban: bankAccount?.iban || '',
		notes: bankAccount?.notes || '',
		totp: '',
	};

	const onSubmit: TEditBankPageFormOnSubmit = (values, { setSubmitting }) => {
		dispatch(
			editBankAccountInMerchantRequest({
				apiParams: {
					putParams: {
						...values,
						bank_account_id: Number(id),
					},
					getParams: { owner_merchant_id: Number(merchantId) },
				},
				onSuccess: () => {
					notify('Bank account has been successfully edited', 'success');
					setSubmitting(false);
				},
				onError: () => {
					setSubmitting(false);
				},
			}),
		);
	};

	useEffect(() => {
		if (!bankList) dispatch(getBankAccountsByOwnerRequest({ owner_merchant_id: merchantId }));
	}, [bankList, dispatch, merchantId]);

	return (
		<div className="main-content">
			<MobileMerchantInfo />
			<Link to={merchantUrl + routes.oneMerchant.bankAccount} className="back-page">
				<ArrowLeftIcon />
				<span>Back Bank account</span>
			</Link>
			<div className="content-header">
				<p className="form-title">Edit account</p>
			</div>
			<div className="fields-wrap fields-wrap--one-col">
				{bankAccount && (
					<div className="fields-item">
						<Formik
							initialValues={initialValues}
							validationSchema={editBankPageSchema}
							onSubmit={onSubmit}
						>
							{({ isSubmitting }) => (
								<Form className="fields-list">
									<TextField
										name="bank_nickname"
										label="Bank nickname"
										placeholder="Enter banck nickname"
									/>
									<TextField name="bank_name" label="Bank Name" placeholder="Enter bank name" />
									<TextField
										name="bank_address"
										label="Bank Address"
										placeholder="Enter bank address"
									/>
									<TextField name="bic" label="SWIFT / BIC" placeholder="Enter SWIFT / BIC" />
									<TextField name="reference" label="Refernce" placeholder="Enter reference" />
									<TextField name="iban" label="IBAN" placeholder="Enter IBAN" />
									<TextareaField name="notes" label="Notes" placeholder="Enter your text here" />

									<TextField
										label="2FA 6-digit code"
										type="text"
										placeholder="Enter code"
										name="totp"
										maxLength={6}
										onlyNumbers
									/>

									<div className="fields-button">
										<Link
											to={`${merchantUrl}${routes.oneMerchant.bankAccount}`}
											className="button button--type2"
										>
											Cancel
										</Link>
										<button type="submit" className="button" disabled={isSubmitting}>
											Save change
										</button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				)}
			</div>
		</div>
	);
};

export default EditBankProviderInMerchant;
