import { useEffect, useRef, useState } from 'react';
import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { ICurrency, IProjectBalanceResponse } from 'redux/reducers/merchantAccounts/types';
import classNames from 'classnames';
import { useViewDecimals } from 'hooks/useViewDecimals';
import styles from './styles.module.scss';

interface IProps {
	label: string;
	value: IProjectBalanceResponse | null;
	setValue: (value: any) => void;
	options?: any[];
	hideBalance?: boolean;
}

interface IPropsOption {
	value: IProjectBalanceResponse;
}
const Option = ({ value }: IPropsOption) => {
	return (
		<div className={styles.option}>
			<img src={value?.currency?.img_path} alt="" className={styles.icon} />
			<p className={styles.name}>
				{value.currency?.name}
				<span className={styles.code}>{value.currency?.code}</span>
				{value.currency_network?.toUpperCase() !== value.currency?.code?.toUpperCase() && (
					<span> ({value.currency_network?.toUpperCase()})</span>
				)}
			</p>
		</div>
	);
};

const CurrencySelect = ({ label, value, setValue, options, hideBalance }: IProps) => {
	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = getViewDecimal(value?.currency?.code || '', value?.currency_network);
	const ref = useRef(null);
	const [open, setOpen] = useState(false);
	const change = (option: IProjectBalanceResponse) => {
		setValue(option);
		setOpen(false);
	};

	useEffect(() => {
		document.addEventListener('click', (e) => {
			const isCurrentClick = ref.current && e.composedPath().includes(ref.current);
			!isCurrentClick && setOpen(false);
		});
	}, []);
	return (
		<div className={classNames(styles.input, { [styles.mb_3]: hideBalance })}>
			<div className={styles.select} ref={ref}>
				<div className={styles.label}>{label}</div>
				<div className={styles.value} onClick={() => setOpen(!open)}>
					{value ? <Option value={value} /> : <p className={styles.placeholder}>Select currency</p>}
					<TriangleArrowDownIcon2 />
				</div>
				{open && !!options?.length && (
					<div className={styles.list}>
						{options.map((option) => (
							<div className={styles.list__li} key={option.id} onClick={() => change(option)}>
								<Option value={option} />
							</div>
						))}
					</div>
				)}
			</div>
			{!hideBalance && (
				<div className={styles.balance}>
					Balance:{' '}
					<span className={styles.balance__value}>{value?.balance.toFixed(viewDecimals)}</span>
					{value?.currency_network.toUpperCase()}
				</div>
			)}
		</div>
	);
};

export default CurrencySelect;
