import { TriangleArrowDownIcon2, CheckIcon } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { ISelectProps } from 'UI/GeneralSelect/types';

const NEW_USER_LABEL = 'New user';

export const SelectMerchant = <T extends Record<string, any>>({
	title,
	value,
	onChange,
	options,
	valueProp = 'value' as keyof T,
	labelProp = 'label' as keyof T,
	placeholder,
	className = '',
	uppercase = false,
}: ISelectProps<T>) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (_value: string | null) => {
		setOpen(false);
		onChange?.(_value);
	};

	let selected =
		value === null
			? NEW_USER_LABEL
			: options?.find((opt) => opt[valueProp] === value)?.[labelProp].full_name;

	selected = uppercase ? selected?.toUpperCase() : selected;

	return (
		<div className={`select-block ${String(className)}`}>
			<div className="select-block__title">
				<p>{title || ''}</p>
			</div>
			<div className={`select select--type-choice ${open ? 'active' : ''}`}>
				<button
					type="button"
					className="select__current select__current--placeholder"
					ref={triggerRef}
					onClick={toggleOpen}
				>
					{selected || placeholder || 'Select'}
					<span className="select__current-arrow">
						<TriangleArrowDownIcon2 />
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll select__drop-scroll--type2">
						<div className="select__drop-item">
							<ul>
								<li>
									<button
										type="button"
										className={value === null ? 'active' : ''}
										onClick={() => handleSelect(null)}
									>
										<span>{NEW_USER_LABEL}</span>
										<span>{value === null && <CheckIcon />}</span>
									</button>
								</li>
								{options?.map((option, index) => {
									const label = uppercase
										? option[labelProp]?.toUpperCase().full_name
										: option[labelProp].full_name;
									return (
										<li key={String(option[valueProp]) + String(index)}>
											<button
												type="button"
												className={value === option[valueProp] ? 'active' : ''}
												onClick={() => handleSelect(option[valueProp])}
											>
												<span>{label}</span>
												<span>{value === option[valueProp] && <CheckIcon />}</span>
											</button>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
