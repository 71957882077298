import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { api, showError } from 'services';
import { TApiGetMerchantOwnersResponse } from 'services/api/provider/types';
import {
	getMerchantOwnersError,
	getMerchantOwnersRequest,
	getMerchantOwnersSuccess,
	toggleMerchantOwnerBlockSuccess,
	toggleMerchantOwnerBlockRequest,
	deleteMerchantOwnerRequest,
	postMerchantAndProjectRequest,
	postMerchantAndProjectSuccess,
	postMerchantOwnProjectRequest,
	providerResetPasswordRequest,
} from './reducer';
import {
	ICreateAccountAndProjectPayload,
	IDeleteMerchantOwnerPayload,
	IGetMerchantOwnersPayload,
	IProviderResetPasswordPayload,
	IToggleMerchantOwnerBlockPayload,
	ICreateAccountAndProjectResponse,
} from './types';
import { notify } from '../../../utils/notify';

function* getMerchantOwnersWorker({ payload }: PayloadAction<IGetMerchantOwnersPayload>) {
	const { apiParams, onFinally } = payload;
	try {
		const data: TApiGetMerchantOwnersResponse = yield call(
			api.provider.getMerchantOwners,
			apiParams,
		);
		yield put(getMerchantOwnersSuccess(data));
	} catch (error) {
		yield put(getMerchantOwnersError());
		showError(error);
	} finally {
		onFinally?.();
	}
}

function* toggleMerchantOwnerBlockWorker(action: PayloadAction<IToggleMerchantOwnerBlockPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		yield call(api.provider.toggleMerchantOwnerBlock, apiParams);
		yield put(toggleMerchantOwnerBlockSuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteMerchantOwnerWorker({ payload }: PayloadAction<IDeleteMerchantOwnerPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.provider.deleteMerchantOwner, apiParams);
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* postMerchantAndProjectRequestWorker({
	payload,
}: PayloadAction<ICreateAccountAndProjectPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: ICreateAccountAndProjectResponse = yield call(
			api.provider.postMerchantAndProject,
			apiParams,
		);
		yield put(postMerchantAndProjectSuccess());
		notify(
			'Account was created.  A link to the user email with access has been sent' +
				' The project was created successfully',
			'success',
		);
		if (response) {
			onSuccess?.(String(response.user.id));
		}
	} catch (error) {
		showError(error);
	}
}

function* postMerchantOwnProjectRequestWorker({
	payload,
}: PayloadAction<ICreateAccountAndProjectPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.provider.postMerchantOwnProject, apiParams);
		yield put(postMerchantAndProjectSuccess());
		notify(
			'Account was created.  A link to the user email with access has been sent' +
				' The project was created successfully',
			'success',
		);
	} catch (error) {
		showError(error);
	}
}

function* providerResetPasswordWorker({ payload }: PayloadAction<IProviderResetPasswordPayload>) {
	try {
		yield call(api.provider.providerRsetPassword, payload);
		yield notify('Great, check your inbox. Check your mailbox for the instructions', 'info');
	} catch (error) {
		showError(error);
	}
}

export function* providerMerchantSaga() {
	yield takeLatest(getMerchantOwnersRequest, getMerchantOwnersWorker);
	yield takeLeading(toggleMerchantOwnerBlockRequest, toggleMerchantOwnerBlockWorker);
	yield takeLeading(deleteMerchantOwnerRequest, deleteMerchantOwnerWorker);
	yield takeLatest(postMerchantAndProjectRequest, postMerchantAndProjectRequestWorker);
	yield takeLatest(postMerchantOwnProjectRequest, postMerchantOwnProjectRequestWorker);
	yield takeLatest(providerResetPasswordRequest, providerResetPasswordWorker);
}
