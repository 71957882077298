import { FC } from 'react';
import payment2Icon from 'assets/inline-svg/payment/payment-2.svg';
import { useSelector } from 'react-redux';
import { getPaymentStatus } from 'redux/reducers/paymentWidget/selectors';
import T from 'i18n-react';
import { BackButton } from '../Buttons/BackButton';
import { PaymentHistory } from '../PaymentHistory/PaymentHistory';
import { IPaymentSuccess } from './types';

export const PaymentSuccess: FC<IPaymentSuccess> = ({ payment }) => {
	const paymentStatus = useSelector(getPaymentStatus);
	return (
		<div className="payment-boxes">
			<div className="payment-conf">
				<span className="payment-title">
					<img src={payment2Icon} alt="icon" />
					{T.translate('Success.title')}
				</span>
				<span className="payment-text">
					<span>
						{T.translate('Success.invoice')} {paymentStatus?.id} {T.translate('Success.text_01')}
					</span>
					<br /> {T.translate('Success.text_02')}
				</span>
			</div>
			<PaymentHistory payment={payment} type="success" />
			<BackButton />
		</div>
	);
};
