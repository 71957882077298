import { HTMLAttributes } from 'react';
import styles from './styles.module.scss';

type IDrawerFooter = HTMLAttributes<HTMLDivElement>;

const DrawerFooter = ({ children }: IDrawerFooter): JSX.Element => {
	return <div className={styles['drawer-footer']}>{children}</div>;
};

export default DrawerFooter;
