import { Transaction } from 'services/api/project/types';
import { ICurrency } from './ApprovementUsers';

interface IDictBoolean {
	[key: string]: boolean;
}

export const TYPES = {
	DEPOSIT: 'deposit',
	WITHDRAWAL: ['withdrawal', 'auto_withdrawal'],
	PAYMENT: 'payment',
	EXCHANGE: ['exchange', 'auto_exchange'],
	REFUND: ['refund', 'refund_payment', 'refund_deposit_project', 'refund_deposit_external'],
	TRANSFER: 'TRANSFER',
	RECEIVE_FUNDS: 'receive_funds',
	RETURN: 'return',
	SEND_COMMISSION: 'send_commission',
};
export const getPageType = (transactionTypes: string, isCrypto: boolean) => {
	const types: IDictBoolean = {
		DepositCrypto: transactionTypes === TYPES.DEPOSIT && isCrypto,
		DepositFiat: transactionTypes === TYPES.DEPOSIT && !isCrypto,
		WithdrawalCrypto: TYPES.WITHDRAWAL.includes(transactionTypes) && isCrypto,
		WithdrawalFiat: TYPES.WITHDRAWAL.includes(transactionTypes) && !isCrypto,
		Payment: transactionTypes === TYPES.PAYMENT,
		Exchange: TYPES.EXCHANGE.includes(transactionTypes),
		Refund: TYPES.REFUND.includes(transactionTypes),
		Transfer: transactionTypes?.toUpperCase() === TYPES.TRANSFER,
		ReceiveFunds: transactionTypes === TYPES.RECEIVE_FUNDS,
		Return: transactionTypes === TYPES.RETURN,
		SendCommission: transactionTypes === TYPES.SEND_COMMISSION,
	};
	return Object.keys(types).find((key) => types[key]);
};

export const getItemData = (transaction: Transaction) => {
	const {
		id,
		status,
		currency,
		balance_before,
		balance_after,
		from_balance_before,
		from_balance_after,
		currency_name,
		currency_code,
		currency_from,
		currency_to,
	} = transaction;
	const users = [balance_before, balance_after];
	from_balance_before != null && users.push(from_balance_before);
	from_balance_after != null && users.push(from_balance_after);
	const currencyResult: ICurrency = {
		from: {
			name: currency_from?.toUpperCase() || currency_name,
			code: currency_from?.toUpperCase() || currency_code,
		},
		to: {
			name: currency_to?.toUpperCase() || currency_name,
			code: currency_to?.toUpperCase() || currency_code,
		},
	};
	return {
		id,
		status,
		currency: currencyResult,
		balance_before,
		balance_after,
		from_balance_before,
		from_balance_after,
		users,
	};
};
