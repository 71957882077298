import classNames from 'classnames';
import { ProjectMenu } from 'v2Pages/Merchant/MerchantProjectsPage/MerchantProjects/ProjectItem/ProjectMenu/ProjectMenu';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserId } from 'redux/reducers/auth/selectors';
import { getProjectListMerchantRequest } from 'redux/reducers/merchantProjects/reducer';
import { getProjectList } from 'redux/reducers/merchantProjects/selectors';
import { fromBackFormat, toTimezoneDate } from 'utils/dates';
// import { MerchantProjectDropdown } from '../MerchantProjectDropdown/MerchantProjectDropdown';

export const MerchantProjectCard: FC = () => {
	const dispatch = useDispatch();
	const [activeCard, setActiveCard] = useState<number>(0);
	const projectList = useSelector(getProjectList);
	const merchantId = useSelector(getUserId);

	useEffect(() => {
		dispatch(getProjectListMerchantRequest({ apiParams: { merchant_id: Number(merchantId) } }));
	}, [dispatch, merchantId]);

	return (
		<div className="projects projects-custom">
			{projectList?.map((project) => (
				<div
					onMouseLeave={() => setActiveCard(0)}
					onMouseOver={() => setActiveCard(project.id)}
					onFocus={() => setActiveCard(project.id)}
					key={project.id}
					className={classNames('project', {
						'project--inactive': !project.is_active,
						active: activeCard === project.id,
					})}
				>
					<div className="project__top">
						<div className="project__head">
							<div className="project__icon">
								<img src={project.file_icon} alt="" />
							</div>
							<p className="project__title">{project.name}</p>

							{/* <MerchantProjectDropdown /> */}
							<ProjectMenu item={project} />
						</div>
						<div className="project__info">
							<p className="project__date">
								{project.created_at && toTimezoneDate(fromBackFormat(project.created_at))}
							</p>
							<p className={classNames('project__status', project.is_active ? 'green' : 'red')}>
								{project.is_active ? 'Active' : 'Inactive'}
							</p>
						</div>
					</div>
					<div className="project__bottom">
						<div className="project__wrapper">
							<p className="project__text">Balance:</p>
							<p className="project__number">
								{`${project.total_balance_in_default_currency?.toFixed(8)} ${
									project.default_currency
								}`}
							</p>
						</div>
						{project.id && (
							<Link to={`/merchant/${String(merchantId)}/project/${activeCard}`} className="button">
								Show
							</Link>
						)}
					</div>
				</div>
			))}
		</div>
	);
};
