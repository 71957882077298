/* eslint-disable @typescript-eslint/no-use-before-define */
import { ErrorIcon, SuccessIcon } from 'assets/inline-svg';
import { toast, ToastOptions, TypeOptions } from 'react-toastify';
import { ToastComponent } from 'UI/ToastComponent/ToastComponent';

export const notify = (message: string, type?: TypeOptions): void => {
	const options: ToastOptions = {
		position: 'top-right',
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		closeButton: false,
		type,
		icon: getIcon(type),
	};

	toast((props) => <ToastComponent {...props} message={message} />, options);
};

// Helper functions

function getIcon(type?: TypeOptions) {
	switch (type) {
		case 'success':
			return <SuccessIcon />;
		case 'error':
			return <ErrorIcon />;
		default:
			return undefined;
	}
}
