import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getWithdrawalFiat,
	getWithdrawalFiatLoading,
	getWithdrawal,
} from 'redux/reducers/projectWithdrawal/selectors';
import { useLocalStorage } from 'hooks/useLocalStorage';
import {
	getProjectWithdrawalFiatRequest,
	getProjectWithdrawalUpdate,
} from 'redux/reducers/projectWithdrawal/reducer';
import { Pagination } from 'UI/Pagination/Pagination';
import { getFilter } from 'redux/reducers/app/selectors';
import Table from 'v2Components/commomComponents/Table';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import useFilterList from 'hooks/useFilterList';
import { IWithdravalItem } from 'redux/reducers/projectWithdrawal/types';
import { TableRow } from './TableRow/TableRow';
import { IFiatTable } from './types';
import { getFilterTransactionsHistory } from '../../TransactionsPage/Transactions/filter.helper';
import { FiltersPending } from '../FiltersPending/FiltersPending';
import styles from './styles.module.scss';

export const FiatTable: FC<IFiatTable> = ({ currencyTab }) => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const withdrawal = useSelector(getWithdrawalFiat);
	const withdrawalCrypto = useSelector(getWithdrawal);
	const loading = useSelector(getWithdrawalFiatLoading);
	const [search, setSearch] = useState('');
	const [currencyId, setCurrencyId] = useState<number | string>();
	const [firstLoading, setFirstLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const { data = [], last_page: totalPages = 1 } = withdrawal || {};
	const [withDr, setWithDr, removewithDr] = useLocalStorage('withdrawal', '');
	const [showFilter, setShowFilter] = useState(false);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('PendingWithdrawalFiat');
	const isShowPagination = totalPages > 1 && !loading;
	const params = useMemo(
		() => ({
			current_page: currentPage,
			user_id: search || undefined,
			currency_id: currencyId,
		}),
		[currencyId, currentPage, search],
	);
	const updateRequest = () =>
		dispatch(getProjectWithdrawalUpdate({ params, onFinally: () => setFirstLoading(false) }));
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const onSubmit = (data: any) => {
		dispatch(setFilter(data));
		setCurrentPage(1);
		setShowFilter(false);
	};
	const filterList = useFilterList(getFilterTransactionsHistory);

	const fetchData = useCallback(() => {
		dispatch(getProjectWithdrawalFiatRequest({ params, onFinally: () => setFirstLoading(false) }));
	}, [dispatch, params]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		dispatch(
			getProjectWithdrawalFiatRequest({
				params: {
					current_page: 1,
					merchant_id: undefined,
				},
				onFinally: () => setFirstLoading(false),
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (withdrawal?.data?.length || withdrawalCrypto?.data?.length) {
			const newWith: any = withdrawalCrypto?.data.length
				? withdrawalCrypto?.data
				: withdrawal?.data;
			setWithDr(newWith);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [withdrawal, withdrawalCrypto]);

	useEffect(() => {
		dispatch(
			getProjectWithdrawalFiatRequest({
				params: {
					...filter,
					current_page: currentPage,
				},
			}),
		);
	}, [dispatch, filter, currentPage]);
	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);
	return (
		<>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
				className={styles.filters}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<FiltersPending />}
			/>
			<Table
				className={styles.table}
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={data}
				gridTemplateColumns={gridTemplateColumns}
				getRow={(item: IWithdravalItem) => TableRow({ item, updateRequest, currencyTab })}
			/>
			{isShowPagination && (
				<div className="pagination-block">
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</div>
			)}
		</>
	);
};
