import { ArrowLeftIcon } from 'assets/inline-svg';
import { roles } from 'layouts-elements/ProviderSidebar/roles';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getUserRole } from 'redux/reducers/auth/selectors';
import { getOrderDetailsRequest } from 'redux/reducers/providerOrders/reducer';
import { getOrderSelector } from 'redux/reducers/providerOrders/selectors';
import { routes } from 'routes/routes';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { trimLastZeros } from 'utils/strings';
import { Comments } from './Comments/Comments';
import { OrderHistory } from './OrderHistory/OrderHistory';
import styles from '../styles.module.scss';

export const OrderDetails: FC = () => {
	const order = useSelector(getOrderSelector);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const userRole = useSelector(getUserRole);
	const { getViewDecimal } = useViewDecimals();

	useEffect(() => {
		dispatch(getOrderDetailsRequest({ order_provider_id: id }));
	}, [dispatch, id]);

	const { currency_request, currency_paid } = order?.order || {};
	const viewDecimalsRequest = currency_request ? getViewDecimal(currency_request) : undefined;
	const viewDecimalsPaid = currency_paid ? getViewDecimal(currency_paid) : undefined;

	return (
		<>
			<div className="mobile-project-info">
				<div className="header-project">
					<div className="header-project__name">
						<p>Provider</p>
					</div>
				</div>
			</div>
			<Link to={`${routes.orders}`} className="back-page">
				<ArrowLeftIcon />
				<span>Back to Orders</span>
			</Link>
			<div className="content-header content-header--mb-16">
				<div className="content-header__group">
					<p className="form-title">{`Order ID ${String(order?.order.id)}`}</p>
				</div>
			</div>
			<div className={styles.main__details}>
				<div className="status-block">
					<p className="block-text block-text--fs-14 status-block__title">Status:</p>
					<p
						className={`status-value status-value--green status-custom ${
							order ? String(order.order.status.replaceAll(' ', '-').toLowerCase()) : ''
						}`}
					>
						{order?.order.status}
					</p>
				</div>
				<div className="approvements-list">
					<div className="approvements-column">
						<div className="approvements-item">
							<p className="approvements-item__title">Client ID:</p>
							<div className="approvements-item__value">
								<a href="/" className="link">
									{order?.order.provider_id}
								</a>
							</div>
						</div>
						<div className="approvements-item">
							<p className="approvements-item__title">Date&amp;Time:</p>
							<div className="approvements-item__value">
								<p>{moment(order?.order.created_at).format('DD.MM.YYYY, HH:mm')} UTC</p>
							</div>
						</div>
						<div className="approvements-item">
							<p className="approvements-item__title">Merchant ID:</p>
							<div className="approvements-item__value">
								<p>{order?.order.merchant_id}</p>
							</div>
						</div>
						<div className="approvements-item">
							<p className="approvements-item__title">Amount:</p>
							<div className="approvements-item__value">
								<p>
									{trimLastZeros(Number(order?.order.amount_to_pay).toFixed(viewDecimalsRequest))}
								</p>
							</div>
						</div>
					</div>
					<div className="approvements-column">
						<div className="approvements-item">
							<p className="approvements-item__title">Currecncy:</p>
							<div className="approvements-item__value">
								<p>{order?.order.currency_request.toUpperCase()}</p>
							</div>
						</div>
						<div className="approvements-item">
							<p className="approvements-item__title">Amount paid:</p>
							<div className="approvements-item__value">
								<p>{trimLastZeros(Number(order?.order.amount_paid).toFixed(viewDecimalsPaid))}</p>
							</div>
						</div>
						<div className="approvements-item">
							<p className="approvements-item__title">Currency paid:</p>
							<div className="approvements-item__value">
								<p>{order?.order.currency_paid.toUpperCase()}</p>
							</div>
						</div>
						{roles.ordersIn.includes(userRole || '') && (
							<div className="approvements-item">
								<p className="approvements-item__title">Invoices:</p>
								<div className="approvements-item__value">
									<p>
										<Link to={`${routes.invoices}/${id}`} className="link">
											View all
										</Link>
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
				{order?.status_history.length && <OrderHistory history={order.status_history} />}
				{order?.order_comments && (
					<Comments coments={order?.order_comments} orderId={order.order.id} />
				)}
			</div>
		</>
	);
};
