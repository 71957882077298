import { useState, ChangeEvent } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { trimLastZeros } from 'utils/strings';
import { notify } from 'utils/notify';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { putApprovementRequest, putRejectRequest } from 'redux/reducers/projectWithdrawal/reducer';
import { getProviderCurrencyAll } from 'redux/reducers/providerCurrency/selectors';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import { ITableProps } from './types';

export const TableRow = ({ item, updateRequest, currencyTab }: ITableProps): TdItem[] => {
	const {
		id,
		user_id,
		owner_merchant_id,
		project,
		fee_withdrawal,
		currency,
		user_nick_name,
		amount,
		bank_account,
		created_at,
		balance_before,
		project_transaction_id,
		currency_setting_id,
	} = item;
	const dispatch = useDispatch();
	const [amountInput, setAmountInput] = useState(String(amount));
	const [isModal, setIsModal] = useState(false);
	const [isModalReject, setIsModalReject] = useState(false);
	const closeModal = () => {
		setIsModalReject(false);
		setIsModal(false);
	};

	const allCurrencyNetworks = useSelector(getProviderCurrencyAll);

	const currencyNetwork = allCurrencyNetworks.find(
		(_item) => _item.id === currency_setting_id,
	)?.currency_network;

	const { getViewDecimal } = useViewDecimals();

	const viewDecimals =
		currency?.code && currencyNetwork ? getViewDecimal(currency?.code, currencyNetwork) : undefined;

	const isCrypto = currencyTab === 'crypto';

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => setAmountInput(e.currentTarget.value);

	const handleRequest = () =>
		dispatch(
			putApprovementRequest({
				params: { amount: Number(amount), project_withdrawal_fiat_transaction_id: id },
				onSuccess: () => {
					notify('User request on withdrawal was approved successfully', 'success');
					updateRequest();
				},
				is_crypto: isCrypto,
			}),
		);
	const handleRejectRequest = () =>
		dispatch(
			putRejectRequest({
				params: { project_withdrawal_fiat_transaction_id: String(id) },
				onSuccess: () => {
					notify('User request on withdrawal was reject successfully', 'success');
					updateRequest();
				},
				is_crypto: isCrypto,
			}),
		);

	return [
		{
			title: 'ID',
			children: (
				<Link
					to={`/transactions/${String(project_transaction_id)}/crypto/withdrawal`}
					className="td-value"
				>
					{id}
				</Link>
			),
		},
		{
			title: 'User ID',
			children: user_id,
		},
		{
			title: 'Merchant ID',
			children: (
				<a href={`/merchant/${owner_merchant_id}`} className="link">
					{owner_merchant_id}
				</a>
			),
		},
		{
			title: 'Project',
			children: project?.name,
		},
		{
			title: 'Balance',
			children: trimLastZeros(balance_before?.toFixed(viewDecimals)),
		},
		{
			title: 'Currency',
			children: currency?.code?.toUpperCase(),
		},
		{
			title: 'Value',
			children: amount,
		},
		{
			title: 'Fee',
			children: trimLastZeros(viewDecimals?.toFixed(viewDecimals) || ''),
		},
		{
			title: 'Created',
			children:
				created_at &&
				`${moment(created_at).utc().format('YYYY-MM-DD HH:MM:SS').replace(' ', '\n')}UTC`,
		},
		{
			title: 'Bank Acc.',
			children: bank_account?.name_account || '',
		},
		{
			title: 'Action',
			children: (
				<div className="table-buttons">
					<button onClick={() => setIsModal(true)} type="button" className="button button--size2">
						Approve
					</button>
					<button
						onClick={() => setIsModalReject(true)}
						type="button"
						className="button button--type8 button--size2"
					>
						Reject
					</button>
				</div>
			),
		},
	];
};
