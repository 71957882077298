import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getClientTransactionsRequest } from 'redux/reducers/projectClients/reducer';
import { getClientTransactions } from 'redux/reducers/projectClients/selectors';
import { Pagination } from 'UI/Pagination/Pagination';
import { TableRow } from './TableRow/TableRow';

export const TransactionHistory = () => {
	const dispatch = useDispatch();
	const [page, setPage] = useState<number>(1);
	const transactions = useSelector(getClientTransactions);

	const { id, projectId } = useParams<{ id: string; projectId: string }>();

	useEffect(() => {
		dispatch(
			getClientTransactionsRequest({
				client_id: +id,
				project_id: +projectId,
				current_page: page,
			}),
		);
	}, [dispatch, id, projectId, page]);

	const { data = [], last_page: totalPages = 1 } = transactions || {};

	return (
		<div className="main-info-category">
			<h4 className="category-title">Transaction History</h4>
			<div className="table-block">
				<div className="table table--transaction-history-client">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Type</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Tx Hash</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Data&amp;time</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Status</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Amount</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name">
									<p>Currency</p>
								</div>
							</div>
						</div>
					</div>
					<div className="table-body">
						{data.map((item) => (
							<TableRow key={item.id} item={item} />
						))}
					</div>
				</div>
			</div>
			{totalPages > 1 && (
				<div className="pagination-block">
					<Pagination
						pageCount={Number(totalPages)}
						onPageChange={(_page) => setPage(_page)}
						forcePage={Number(page)}
					/>
				</div>
			)}
		</div>
	);
};
