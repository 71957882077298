import { PopUpCloseIcon } from 'assets/inline-svg';
import { FC, useState, FocusEvent, useCallback, useEffect, useMemo } from 'react';
import { getBankAccountNameRequest } from 'redux/reducers/merchantBankAccount/reducer';
import { getProviderCurrencyAll } from 'redux/reducers/providerCurrency/selectors';
import { postProviderCreateWithdrawalRequest } from 'redux/reducers/providerWallets/reducer';
import { ICreateWithdrawal } from 'redux/reducers/providerWallets/types';
import { getBankAccountData } from 'redux/reducers/merchantBankAccount/selectors';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import L from 'i18n-react';
import { useDispatch, useSelector } from 'react-redux';
import { IApiGetBankAccountsNameParams } from 'redux/reducers/merchantBankAccount/types';
import { TextField } from 'UI/Forms/TextField/TextField';
import { TextareaField } from 'UI/Forms/TextareaField/TextareaField';
import { SelectField } from 'UI/Forms/SelectField/SelectField';
import { notify } from 'utils/notify';
import classNames from 'classnames';
import { IPropsPopUps } from './type';
import { TypeSelect } from '../TypeSelect/TypeSelect';
import { IItemOptions } from '../TypeSelect/types';
import style from './styles.module.scss';

interface IValues {
	isCustomName: boolean;
	amount: string;
	bank_name: string;
	iban_destination: string;
	bic: string;
	reference_to: string;
	comment: string;
	totp: string;
}

const initialValues: IValues = {
	isCustomName: false,
	amount: '',
	bank_name: '',
	iban_destination: '',
	bic: '',
	reference_to: '',
	comment: '',
	totp: '',
};

const PopUpWithdrawalFiat: FC<IPropsPopUps> = ({ onClosePopUps, item }) => {
	const dispatch = useDispatch();
	const bankAccountData = useSelector(getBankAccountData);
	const [optionsItem, setOptionsItem] = useState({
		id: item.id,
		img: item.img_path,
		currency_id: item.currency_id,
		currency_network: item.currency_network,
		currency_name: item.currency.name,
		code: item.currency.code,
		name: item.currency.name,
		hot_balance: item.hot_balance,
		provider_balance: item.provider_balance,
	});
	const currencies = useSelector(getProviderCurrencyAll).filter((_item) => _item.is_crypto === 0);
	const bankNameOptions = useMemo(
		() => [
			{ value: false, label: bankAccountData?.bank_name },
			{ value: true, label: 'Custom bank account' },
		],
		[bankAccountData],
	);

	const validationSchema = yup.object().shape({
		isCustomName: yup.boolean(),
		amount: yup
			.number()
			.required('Amount is requierd')
			.max(
				Number(optionsItem?.provider_balance || 0),
				`Amount should not be more than ${optionsItem?.provider_balance || 0}`,
			)
			.typeError('Amount must be a number'),
		bank_name: yup.string().when('isCustomName', {
			is: true,
			then: yup.string().required(String(L.translate('Validation.account_name_required'))),
		}),
		iban_destination: yup.string().when('isCustomName', {
			is: true,
			then: yup.string().required(String(L.translate('Validation.iban_required'))),
		}),
		bic: yup.string().when('isCustomName', {
			is: true,
			then: yup.string().required(String(L.translate('Validation.bic_required'))),
		}),
		totp: yup
			.string()
			.required('Totp code is required')
			.min(6, 'Totp is too short')
			.max(6, 'Totp is too long'),
	});

	useEffect(() => {
		if (optionsItem) {
			const payload: IApiGetBankAccountsNameParams = {
				currency_name: optionsItem.code,
			};
			dispatch(
				getBankAccountNameRequest({
					paramsApi: payload,
					onSuccess: () => {},
				}),
			);
		}
	}, [dispatch, optionsItem]);

	const disableScroll = useCallback((e: WheelEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);
	const handleInputFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.addEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInputBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.removeEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const regex = /^[0-9.,]*$/;
		const input = event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const onSubmit = (values: IValues) => {
		const { isCustomName, amount, totp, bank_name, iban_destination, bic, reference_to, comment } =
			values;
		const payload: ICreateWithdrawal = {
			currency_name: String(optionsItem?.code),
			amount: +amount,
			bank_name: isCustomName ? bank_name : bankAccountData?.bank_name || '',
			iban_destination: isCustomName ? iban_destination : bankAccountData?.iban || '',
			bic: isCustomName ? bic : bankAccountData?.bic || '',
			reference_to: isCustomName ? reference_to : bankAccountData?.reference || '',
			totp,
		};
		dispatch(
			postProviderCreateWithdrawalRequest({
				payload,
				onSuccess: () => {
					notify('Withdrawal created', 'success');
					onClosePopUps();
				},
				onError: () => {
					onClosePopUps();
				},
			}),
		);
	};

	const handleTypeChange = (_type: IItemOptions) => {
		setOptionsItem({
			id: _type.id,
			img: _type.img,
			currency_id: _type.currency_id,
			currency_network: _type.currency_network,
			currency_name: _type.currency_name,
			code: _type.code,
			name: _type.name,
			hot_balance: _type.hot_balance,
			provider_balance: _type.provider_balance,
		});
	};

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-540">
					<button
						aria-label="button"
						onClick={onClosePopUps}
						type="button"
						className="popup__close"
					>
						<PopUpCloseIcon />
					</button>
					<div className={style.popup__header}>
						<p className={style.popup__title}>Withdrawal</p>
					</div>
					<div className={style.popup__body}>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
						>
							{({ isSubmitting, values }) => (
								<Form>
									<div className={style.selectBlock}>
										<p className={style.selectBlock__name}>From</p>
										{currencies && (
											<TypeSelect
												item={optionsItem}
												onChange={handleTypeChange}
												options={currencies}
											/>
										)}
										<div className={style.selectInfo}>
											<p className={style.selectInfo__name}>Balance:</p>
											<p className={style.selectInfo__number}>
												{optionsItem?.provider_balance || '0'}
											</p>
										</div>
									</div>
									<SelectField name="isCustomName" title="Bank name" options={bankNameOptions} />
									<TextField name="amount" label="Amount" placeholder="0.00" onlyNumbers />
									{values.isCustomName && (
										<>
											<TextField name="bank_name" label="Bank name" placeholder="Enter name" />
											<TextField name="iban_destination" label="IBAN" placeholder="Enter IBAN" />
											<TextField name="bic" label="BIC" placeholder="Enter BIC" />
											<TextField
												name="reference_to"
												label="Reference"
												placeholder="Enter Reference"
											/>
										</>
									)}
									<TextareaField name="comment" label="Comment" placeholder="Enter your comment" />
									<TextField
										label="Enter the 2FA 6-digit Code"
										type="text"
										placeholder="Enter code"
										name="totp"
										maxLength={6}
										onlyNumbers
									/>
									<div className={style.popup__button}>
										<button
											onClick={onClosePopUps}
											type="button"
											className={classNames(style.button, style.button__clear)}
										>
											Cancel
										</button>

										<button type="submit" disabled={isSubmitting} className={style.button}>
											Withdrawal
										</button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</div>
	);
};
export default PopUpWithdrawalFiat;
