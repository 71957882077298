import { FC, useEffect } from 'react';
import T from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { notify } from 'utils/notify';
import { noRepeat } from 'utils/arrayNoRepeat';
import { IAvailableCurrency } from 'services/api/public/types';
import {
	getAvailableCurrenciesRequest,
	createPaymentRequest,
	createUpdatePaymentRequest,
} from 'redux/reducers/paymentWidget/reducer';
import {
	getAvailableCurrencies,
	getCreatePaymentLoading,
	getCreatedPayment,
} from 'redux/reducers/paymentWidget/selectors';
import {
	ICreatePaymentPayload,
	ICreateUpdatePaymentPayload,
} from 'redux/reducers/paymentWidget/types';
import { BackButton } from '../Buttons/BackButton';
import { PaymentBox } from './PaymentBox/PaymentBox';
import { IValues, IChooseCoinForm } from './types';

const initialValues: IValues = {
	email: '',
	currencyId: '',
};

export const ChooseCoinForm: FC<IChooseCoinForm> = ({
	onSuccess,
	networks,
	setNetworks,
	onExpired,
}) => {
	const dispatch = useDispatch();
	const currencies = useSelector(getAvailableCurrencies) || [];
	const paymentLoading = useSelector(getCreatePaymentLoading);
	const paymentOrder = useSelector(getCreatedPayment)?.payment || {};

	const currencyNoRepeat = noRepeat(currencies, 'currency_code') || [];

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const widgetKey = searchParams.get('widgetKey');
	const orderId = searchParams.get('internal_order_id');
	const currencyRequest = searchParams.get('order_currency');
	const amount = searchParams.get('order_price');
	const urlSuccess = searchParams.get('url_success');
	const urlError = searchParams.get('url_unsuccess');
	const url = searchParams.get('url');
	const lang = searchParams.get('lang');
	const clientId = searchParams.get('client_internal_id');
	const email = searchParams.get('client_email');
	const phone = searchParams.get('client_phone');

	useEffect(() => {
		if (!widgetKey) return;
		dispatch(
			getAvailableCurrenciesRequest({
				widget_key: widgetKey,
			}),
		);
		if (orderId && currencyRequest && amount) {
			const payload: ICreatePaymentPayload = {
				apiParams: {
					widget_key: widgetKey,
					internal_order_id: orderId,
					currency_request: currencyRequest,
					amount_request: +amount,
					payment_url_success: urlSuccess || '',
					payment_url_fail: urlError || '',
				},
			};
			dispatch(createPaymentRequest(payload));
		}

		/* eslint-disable-next-line */
	}, [dispatch, widgetKey]);

	const onSubmit = (value: IAvailableCurrency, net: boolean | IAvailableCurrency[] | null) => {
		const networkCurrencies = currencies.filter(
			(item) => String(item.currency_code) === String(value.currency_code),
		);
		if (networkCurrencies.length === 1 || net) {
			const currencyPaid = value && currencies?.find((item) => item.id === +value.id);
			const { currency_code: currency_paid, currency_network_name: currency_paid_network } =
				currencyPaid || {};
			if (!widgetKey || !currency_paid || !currency_paid_network || !paymentOrder.order_id) {
				return;
			}
			const payload: ICreateUpdatePaymentPayload = {
				apiParams: {
					widget_key: widgetKey,
					order_id: String(paymentOrder?.order_id),
					currency_paid,
					currency_paid_network,
				},
				onSuccess: (res: string) => {
					if (res === 'success') {
						notify(String(T.translate('Notify.successfully')), 'success');
						onSuccess?.();
					} else {
						onExpired?.();
					}
				},
			};
			dispatch(createUpdatePaymentRequest(payload));
			return;
		}
		if (networkCurrencies) {
			setNetworks(networkCurrencies);
		}
	};

	return (
		<div className="payment-boxes">
			<PaymentBox
				currencies={networks?.length ? networks || [] : currencyNoRepeat}
				onSubmit={onSubmit}
				network={!!networks}
				networks={networks}
				loading={networks?.length ? null : paymentLoading}
			/>
			<BackButton />
		</div>
	);
};
