import { PopUpCloseIcon, PlusBlueIcon } from 'assets/inline-svg';
import { FC, useState, FocusEvent, useCallback, ChangeEvent, useEffect } from 'react';
import { IProjectBalanceResponse, ITransferRequest } from 'redux/reducers/merchantAccounts/types';
import {
	getProjectBankAccountRequest,
	createFiatWithdrawalRequest,
} from 'redux/reducers/merchantBankAccount/reducer';
import { useMerchantMatchUrl } from 'hooks';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import { getUserType } from 'redux/reducers/auth/selectors';
import { getProjectWithdrawalFiatDataRequest } from 'redux/reducers/projectWithdrawal/reducer';
import { getWithdrawalDataFiatLimit } from 'redux/reducers/projectWithdrawal/selectors';
import { getBankAccountByCurrency } from 'redux/reducers/merchantBankAccount/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { trimLastZeros } from 'utils/strings';
import { IPropsPopUps } from '../../../TableRowCrypto/types';
import { SelectDropCoin } from '../../PopUpTransfer/SelectDropCoin/SelectDropCoin';
import { WithdrawalFiatInfo } from './WithdrawalFiatInfo/WithdrawalFiatInfo';
import { IErrors } from './WithdrawalFiatInfo/types';

export const PopUpWithdrawalFiat: FC<IPropsPopUps> = ({
	onPopUps,
	popUpOpen,
	currencies,
	accountId,
}) => {
	const [data, setData] = useState<ITransferRequest>();
	const dispatch = useDispatch();
	const limits = useSelector(getWithdrawalDataFiatLimit);
	const userType = useSelector(getUserType);
	const merchantUrl = useMerchantMatchUrl();
	const [errors, setErrors] = useState<IErrors>({});
	const bankAccountByCurrency = useSelector(getBankAccountByCurrency);
	const [totp, setTotp] = useState('');
	const { projectId, merchantId } = useParams<{ projectId: string; merchantId: string }>();
	const [accountBank, setAccountBank] = useState<string>('');
	const [accountIBAN, setAccountIBAN] = useState<string>('');
	const [accountBIC, setAccountBIC] = useState<string>('');
	const [amount, setAmount] = useState<string>('');
	const [isSelect, setIsSelect] = useState<IProjectBalanceResponse | null>(
		currencies?.find((coin) => coin.id === accountId) || null,
	);

	const isProvider = userType === constUserTypes.PROVIDER;

	const handleInputs = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;

		if (name === 'amount') {
			const error = +value > Number(isSelect?.balance) ? 'Not enough balance' : '';
			setErrors({ [name]: error });
		}
		if (name === 'totp' && value.length > 6) return;
		if (name === 'totp') {
			setTotp(value);
			return;
		}
		setAmount(value);
	};
	const maxBtn = (value: string) => {
		setAmount(value);
	};

	useEffect(() => {
		if (bankAccountByCurrency) {
			setAccountBank(bankAccountByCurrency.bank_name);
			setAccountIBAN(bankAccountByCurrency?.iban);
			setAccountBIC(bankAccountByCurrency?.bic);
		}
	}, [bankAccountByCurrency]);

	useEffect(() => {
		if (isSelect) {
			dispatch(
				getProjectBankAccountRequest({
					params: {
						owner_merchant_id: merchantId,
						currency_id: isSelect.currency_id,
					},
					onSuccess: () => {},
				}),
			);
		}
	}, [dispatch, isSelect, merchantId]);

	useEffect(() => {
		isSelect?.id &&
			amount &&
			projectId &&
			dispatch(
				getProjectWithdrawalFiatDataRequest({
					params: {
						project_id: Number(projectId),
						currency_setting_id: isSelect.currency_setting_id,
						amount: +amount,
					},
					onSuccess: () => {
						console.log('fd');
					},
				}),
			);

		return () => {
			document.body.style.overflow = '';
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, isSelect, amount]);
	/* eslint-disable */
	const onData = (values: ITransferRequest) => {
		setData({ ...data, ...values });
	};
	const disableScroll = useCallback((e: WheelEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);
	const handleInputFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.addEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInputBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.removeEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const regex = /^[0-9.,]*$/;
		const input = event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	const onSubmit = () => {
		isSelect &&
			dispatch(
				createFiatWithdrawalRequest({
					payload: {
						project_id: Number(projectId),
						currency_setting_id: Number(isSelect.currency_setting_id),
						amount: +amount,
						totp,
					},
					onSuccess: () => {
						onPopUps && onPopUps('');
					},
				}),
			);
	};

	const { getViewDecimal } = useViewDecimals();

	const viewDecimals = isSelect?.currency.code
		? getViewDecimal(isSelect?.currency.code)
		: undefined;

	const processingFee = amount ? limits?.platform_fee || 0 : 0;
	const willBeDebited = amount ? limits?.will_be_debited || 0 : 0;
	const willBeReceived = amount ? limits?.will_be_received || 0 : 0;

	return (
		<div className="popup popup--width-540">
			<button onClick={() => onPopUps && onPopUps('')} type="button" className="popup__close">
				<PopUpCloseIcon />
			</button>
			<div className="popup-header">
				<p className="popup-header__title popup-header__title--ta-l  ">Withdrawal</p>
			</div>
			<div className="popup-body popup-body--type3">
				<div className="select-block">
					<p className="select-block__name">From</p>
					{currencies && (
						<SelectDropCoin
							onSubmit={onData}
							currencies={currencies}
							popUpOpen={popUpOpen}
							isSelect={isSelect}
							setIsSelect={setIsSelect}
							viewDecimals={viewDecimals}
						/>
					)}
				</div>
				{/* {isProvider && (
					<Link to={`${merchantUrl}${routes.oneMerchant.bankAccount}`} className="button-add">
						<PlusBlueIcon />
						<span>Add a bank account</span>
					</Link>
				)} */}
				<div className="input">
					<p className="input-name">Name of bank</p>
					<div className="input-wrapper">
						<DebounceInput
							name="bank"
							className="input-item input-item--pr-75"
							type="text"
							value={accountBank}
							placeholder="Enter bank name"
							readOnly
							onFocus={handleInputFocus}
							onKeyPress={handleKeyPress}
							onBlur={handleInputBlur}
							onChange={handleInputs}
							debounceTimeout={500}
						/>
					</div>
				</div>
				<div className="input">
					<p className="input-name">IBAN</p>
					<div className="input-wrapper">
						<DebounceInput
							name="iban"
							className="input-item input-item--pr-75"
							placeholder="Enter IBAN"
							type="text"
							value={accountIBAN}
							readOnly
							onFocus={handleInputFocus}
							onKeyPress={handleKeyPress}
							onBlur={handleInputBlur}
							onChange={handleInputs}
							debounceTimeout={500}
						/>
					</div>
				</div>
				<div className="input">
					<p className="input-name">BIC</p>
					<div className="input-wrapper">
						<DebounceInput
							name="bic"
							className="input-item input-item--pr-75"
							type="text"
							placeholder="Enter BIC"
							value={accountBIC}
							readOnly
							onFocus={handleInputFocus}
							onKeyPress={handleKeyPress}
							onBlur={handleInputBlur}
							onChange={handleInputs}
							debounceTimeout={500}
						/>
					</div>
				</div>
				{limits && <WithdrawalFiatInfo isSelect={isSelect} limits={limits} amount={amount} />}
				<div className="input">
					<p className="input-name">Amount</p>
					<div className="input-wrapper">
						<DebounceInput
							name="amount"
							className="input-item input-item--pr-75"
							type="number"
							placeholder="0.00"
							// disabled={!isValidAddress}
							value={amount}
							onFocus={handleInputFocus}
							onBlur={handleInputBlur}
							onChange={handleInputs}
							debounceTimeout={500}
						/>
						{/* <input className="input-item input-item--pr-75" type="text" placeholder="0.00" /> */}
						<div className="input-text">
							<button onClick={() => maxBtn(String(isSelect?.balance) || '')} type="button">
								MAX
							</button>
							<p>{isSelect?.currency.code.toUpperCase()}</p>
						</div>
					</div>
					{errors?.amount ? (
						<p className="input-notification input-notification--error">{errors?.amount}</p>
					) : null}
					{/* <div className="input-info">
						<p className="input-info__name">MAX:</p>
						<p className="input-info__number">
							<span className="input-info__number-price">{isSelect?.withdraw_min} {isSelect?.currency.code.toUpperCase()}</span>
						</p>
					</div> */}
				</div>
				<div className="input">
					<p className="input-name">Enter the 2FA 6-digit Code</p>
					<div className="input-wrapper">
						<DebounceInput
							name="totp"
							className="input-item input-item--pr-75"
							type="number"
							placeholder="Enter totp"
							value={totp}
							onFocus={handleInputFocus}
							onBlur={handleInputBlur}
							onChange={handleInputs}
							debounceTimeout={500}
						/>
					</div>
				</div>
				<div className="deposit-info-pop">
					<ul>
						<li>
							<span>Processing fee:</span>
							<p>{`${trimLastZeros(processingFee.toFixed(viewDecimals))} ${
								isSelect?.currency.code.toUpperCase() || ''
							}`}</p>
						</li>
						<li>
							<span>Will be sent:</span>
							<p>{`${trimLastZeros(Number(willBeDebited).toFixed(viewDecimals))} ${
								isSelect?.currency.code.toUpperCase() || ''
							}`}</p>
						</li>
						<li>
							<strong>Will be received:</strong>
							<p>{`${trimLastZeros(willBeReceived.toFixed(viewDecimals))} ${
								isSelect?.currency.code.toUpperCase() || ''
							}`}</p>
						</li>
					</ul>
				</div>
			</div>
			<div className="popup-button popup-button--type2">
				<button
					onClick={() => onPopUps && onPopUps('')}
					type="button"
					className="button button--full-width button--type2"
				>
					Cancel
				</button>
				<button
					type="button"
					onClick={onSubmit}
					disabled={isProvider}
					className="button button--full-width"
				>
					Withdrawal
				</button>
			</div>
		</div>
	);
};
