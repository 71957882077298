import { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import { TextField } from 'UI/Forms/TextField/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { PlusIcon } from 'assets/inline-svg';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { notify } from 'utils/notify';
import {
	addNewUserRoleRequest,
	getUserMerchantProfileRequest,
} from 'redux/reducers/merchantApprovalManagement/reducer';
import { IPayloadRequset } from 'redux/reducers/merchantApprovalManagement/types';
import { getUserData, getUserType } from 'redux/reducers/auth/selectors';
import { getMerchantApprovalUserProfile } from 'redux/reducers/merchantApprovalManagement/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { IUserProfileFormProps, TAddNewUserFormOnSubmit } from './types';
import { TopInfoBlock } from './TopInfoBlock/TopInfoBlock';
import { TwoFABlock } from './TwoFABlock/TwoFABlock';
import { RoleBlock } from './RoleBlock/RoleBlock';
import { WhiteListIPBlock } from './WhiteListIPBlock/WhiteListIPBlock';
import { getValidationSchema } from './utils';
import { ActionsBlock } from './ActionsBlock/ActionsBlock';

import { AddNewUser } from './AddNewUser/AddNewUser';

export const UserProfileForm: FC<IUserProfileFormProps> = ({ user, onSubmit }) => {
	const [isAdminModal, setIsAdminModal] = useState(false);
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);
	const userData = useSelector(getUserData);
	const merchantProfile = useSelector(getMerchantApprovalUserProfile);
	const closeAdminModal = () => setIsAdminModal(false);
	const handleAddNewUser: TAddNewUserFormOnSubmit = (values, { setSubmitting }) => {
		const { email, firstName, role, project, lastName } = values;

		const payload: IPayloadRequset = {
			apisParams: {
				role: role?.code as string,
				project_id: Number(project?.id),
				merchant_id: Number(user.merchant.id),
			},
			onError: () => setSubmitting(false),
			userType,
			onSuccess: () => {
				closeAdminModal();
				notify('User add', 'success');

				// const payload: TApiGetIApiMerchantApprovalManagementParamsPayload = {
				// 	apiParams: {
				// 		merchant_owner_id: merchantId,
				// 		per_page: 15,
				// 		current_page: 1,
				// 	},
				// };
				dispatch(
					getUserMerchantProfileRequest({ userType, params: { merchant_id: user.merchant.id } }),
				);
			},
		};
		dispatch(addNewUserRoleRequest(payload));
	};

	return (
		<Formik
			initialValues={{
				firstName: `${String(user?.merchant?.first_name)}` || '',
				lastName: `${String(user?.merchant?.last_name)}` || '',
				email: user?.merchant?.email || '',
			}}
			validationSchema={getValidationSchema()}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({ dirty, handleReset }) => (
				<Form>
					<div className="content-header">
						<p className="form-title">User profile</p>
					</div>

					<div className="fields-wrap">
						<div className="fields-item">
							<div className="fields-list">
								<TopInfoBlock user={user} />

								<TextField name="firstName" label="First name" />

								<TextField name="lastName" label="Last name" />

								<TextField name="email" label="Email" inputProps={{ readOnly: true }} />

								{((merchantProfile?.merchant.email && userData?.role.code === 'PROVIDER_ADMIN') ||
									userData?.is_owner) && (
									<TwoFABlock
										user={userData}
										email={merchantProfile?.merchant.email || ''}
										userType={constUserTypes.MERCHANT}
									/>
								)}
							</div>
						</div>
					</div>

					<div className="block-title-wrap">
						<div className="block-title">
							<p>Role and project</p>
						</div>
						<button type="button" className="button" onClick={() => setIsAdminModal(true)}>
							<PlusIcon />
							Add new role
						</button>
					</div>

					<RoleBlock user={user} />

					<WhiteListIPBlock user={user} />

					<div className="buttons-wrap">
						<ActionsBlock user={user} />

						<div className="buttons">
							<button
								type="button"
								className="button button--type2"
								disabled={!dirty}
								onClick={handleReset}
							>
								Cancel
							</button>
							<button type="submit" className="button" disabled={!dirty}>
								Save change
							</button>
						</div>
					</div>
					<PopupModal open={isAdminModal} onClose={closeAdminModal}>
						<AddNewUser onSubmit={handleAddNewUser} onClose={closeAdminModal} user={user} />
					</PopupModal>
				</Form>
			)}
		</Formik>
	);
};
