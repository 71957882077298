import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon } from 'assets/inline-svg';
import { capitalize, shortNotation, trimBottomLine, trimLastZeros } from 'utils/strings';
import { notify } from 'utils/notify';
import StatusText from 'UI/StatusText/StatusText';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import { toUTC } from 'utils/dates';
import LinkDetails from 'v2Components/commomComponents/Table/Td/LinkDetails';
import { ITableRow } from './types';

export const TableRow = ({ item, number }: ITableRow): TdItem[] => {
	const {
		id,
		user_id,
		user_type,
		owner_merchant_id,
		owner_merchant_nick_name,
		project_id,
		created_at,
		type,
		currency_name,
		currency_code,
		currency_network,
		status,
		amount,
		hash_transaction,
		operation_sign,
	} = item;

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tabsUrl = searchParams.get('tabs');
	const detailsUrl = `/transactions/${String(id)}/${tabsUrl || 'crypto'}/${String(type)}`;
	let userLink = (user_id || user_id === 0) && `/administration/team-members/${String(user_id)}`;
	if (user_type === 'merchant' && (owner_merchant_id || owner_merchant_id === 0)) {
		userLink = `/merchant/${String(owner_merchant_id)}`;
	}
	if (
		user_type === 'client' &&
		(owner_merchant_id || owner_merchant_id === 0) &&
		(project_id || project_id === 0) &&
		(user_id || user_id === 0)
	) {
		userLink = `/merchant/${String(owner_merchant_id)}/project/${String(
			project_id,
		)}/client/${String(user_id)}`;
	}
	const typeFormatted = [
		'refund_payment',
		'refund_deposit_project',
		'refund_deposit_external',
	].includes(String(type))
		? 'Refund'
		: type;

	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = currency_code ? getViewDecimal(currency_code, currency_network) : undefined;
	const handleAddressCopy = () => {
		notify('Address copied successfully!', 'info');
	};
	const amountColor = !!operation_sign && (operation_sign === '+' ? 'green' : 'red');
	return [
		{
			title: '№',
			children: number,
		},
		{
			title: 'Tx ID',
			children: <Link to={detailsUrl}>{id}</Link>,
		},
		{
			title: 'User ID',
			children: userLink && (
				<Link to={userLink} className="link">
					{user_id}
				</Link>
			),
		},
		{
			title: 'Merchant ID',
			children: typeof owner_merchant_id === 'number' && (
				<Link to={`/merchant/${owner_merchant_id}`} className="link">
					{owner_merchant_nick_name}
				</Link>
			),
		},
		{
			title: 'Project',
			children: typeof owner_merchant_id === 'number' && typeof project_id === 'number' && (
				<Link to={`/merchant/${owner_merchant_id}/project/${project_id}/settings/details`}>
					{project_id}
				</Link>
			),
		},
		{
			title: 'Date&Time',
			children: created_at && toUTC(created_at).replace(',', ''),
		},
		{
			title: 'Currency',
			children: (
				<>
					<p>{currency_name && capitalize(currency_name)}</p>
					<span className="tooltip tooltip--width-170 tooltip--top tooltip--center tooltip--arrow-bottom-center">
						<span className="tooltip__text tooltip__text--center">
							<span>Currency that exchanged</span>
						</span>
					</span>
				</>
			),
		},
		{
			title: 'Type',
			children: typeFormatted && capitalize(trimBottomLine(typeFormatted)),
		},
		{
			title: 'Amount',
			children: (
				<p className={amountColor || ''}>{trimLastZeros(Number(amount).toFixed(viewDecimals))}</p>
			),
		},
		{
			title: 'Status',
			children: status && StatusText(status),
		},
		{
			title: 'TX Hash',
			children: (
				<div className="table-hash">
					{hash_transaction && hash_transaction !== '-' && (
						<>
							<p className="td-value">
								{hash_transaction && shortNotation(hash_transaction, 3, 3)?.toUpperCase()}
							</p>
							<CopyToClipboard text={String(hash_transaction)} onCopy={handleAddressCopy}>
								<button aria-label="button" type="button">
									<CopyIcon />
								</button>
							</CopyToClipboard>
						</>
					)}
				</div>
			),
		},
		{
			title: 'Details',
			children: <LinkDetails detailsUrl={detailsUrl} />,
		},
	];
};
