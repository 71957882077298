import { ChangeEvent, useState } from 'react';

export default function useInputNum(initialValue: string) {
	const [value, setValue] = useState(initialValue);

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value.replace(/[^\d.]/g, ''));
	};

	return {
		value,
		onChange,
	};
}
