import { CheckIcon, TriangleArrowDownIcon2 } from 'assets/inline-svg';
import classNames from 'classnames';
import { useSelect } from 'hooks';
import { FC } from 'react';
import { IApiGetRolesItem } from 'services/api/provider/types';
import { IRoleSelectProps } from './types';

export const RoleSelect: FC<IRoleSelectProps> = ({
	roles,
	active: activeRole,
	onChange,
	onClose,
}) => {
	const { open, setOpen, triggerRef, dropRef } = useSelect({ onClose });

	const handleClick = () => {
		if (open) {
			setOpen(false);
			onClose?.();
			return;
		}
		if (roles?.length) {
			setOpen(true);
		}
	};

	const handleChange = (newRole: IApiGetRolesItem) => {
		setOpen(false);
		// onClose() then onChange() - important for correct validation
		onClose?.();
		onChange(newRole);
	};

	return (
		<div className={classNames('select', 'select--type-choice', { active: open })}>
			<button type="button" className="select__current" ref={triggerRef} onClick={handleClick}>
				{activeRole?.name || 'Select role'}

				<span className="select__current-arrow">
					<TriangleArrowDownIcon2 />
				</span>
			</button>

			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select__drop-item">
						<ul>
							{roles?.map((role) => (
								<li key={role.code}>
									<button
										className={classNames({ active: role.code === activeRole?.code })}
										type="button"
										onClick={() => handleChange(role)}
									>
										<span>{role.name}</span>

										<span>
											<CheckIcon />
										</span>
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
