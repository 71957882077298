import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLeading } from 'redux-saga/effects';
import { routes } from 'routes/routes';
import { api, showError } from 'services';
import {
	IApiCreatePasswordResponse,
	IApiLoginResponse,
	IRequest2FaReset,
	IFirstLoginResponse,
} from 'services/api/auth/types';
import { notify } from 'utils/notify';
import {
	getApprovementDefaultRequest,
	getApprovementProviderDefaultRequest,
} from 'redux/reducers/providerApprovements/reducer';
import {
	getProjectWithdrawalRequest,
	getProjectWithdrawalFiatRequest,
} from 'redux/reducers/projectWithdrawal/reducer';
import { getApprovementRequest } from 'redux/reducers/providerProjectApprovements/reducer';
import {
	confirIpAddressRequest,
	confirmResetPassRequest,
	createPasswordError,
	createPasswordRequest,
	createPasswordSuccess,
	firstLoginRequest,
	firstLoginSuccess,
	forgotPasswordRequest,
	loginError,
	loginMerchantRequest,
	loginRequest,
	loginSuccess,
	logoutError,
	logoutMerchantRequest,
	logoutRequest,
	logoutSuccess,
	reset2faMerchantRequest,
	reset2faProviderRequest,
	reset2faRequest,
} from './reducer';
import {
	IConfirIpAddressRequest,
	ICreatePasswordPayload,
	IForgotPasswordRequest,
	ILoginPayload,
	IParams2faREstProviderSlice,
	IParams2faREstSlice,
	IResetPassRequest,
} from './types';

function* loginWorker({ payload: { apiParams, onError } }: PayloadAction<ILoginPayload>) {
	try {
		const data: IApiLoginResponse = yield call(api.auth.login, apiParams);
		yield put(loginSuccess(data));
		const params = {
			current_page: 1,
			per_page: 15,
		};
		yield put(getApprovementRequest({ ...params }));
		yield put(getApprovementDefaultRequest({ params }));
		yield put(getApprovementProviderDefaultRequest({ params }));
		yield put(getProjectWithdrawalRequest({ params, onFinally: () => '' }));
		yield put(getProjectWithdrawalFiatRequest({ params, onFinally: () => '' }));
	} catch (error) {
		yield put(loginError());
		onError?.();
		showError(error);
	}
}

function* loginMerchantWorker({ payload: { apiParams, onError } }: PayloadAction<ILoginPayload>) {
	try {
		const data: IApiLoginResponse = yield call(api.auth.loginMerchant, apiParams);
		yield put(loginSuccess(data));
	} catch (error) {
		yield put(loginError());
		onError?.();
		showError(error);
	}
}

function* logoutWorker() {
	try {
		yield call(api.auth.logout);
		yield put(logoutSuccess());
	} catch (error) {
		yield put(logoutError());
		showError(error);
	}
}

function* logoutMerchantWorker() {
	try {
		yield call(api.auth.logoutMerchant);
		yield put(logoutSuccess());
	} catch (error) {
		yield put(logoutError());
		showError(error);
	}
}

function* createPasswordWorker({ payload }: PayloadAction<ICreatePasswordPayload>) {
	const { apiParams, onSuccess, onError } = payload;
	try {
		const res: IApiCreatePasswordResponse = yield call(api.auth.createPassword, apiParams);
		yield put(createPasswordSuccess());
		const { user_type } = res.change_password.original;
		onSuccess?.(user_type);
	} catch (error) {
		yield put(createPasswordError());
		onError?.();
		showError(error);
	}
}

function* confirmResetPassWorker({ payload }: PayloadAction<IResetPassRequest>) {
	const { apiParams, onSuccess, onEmailSend } = payload;
	try {
		const data: { status: string; user_type: string } = yield call(
			api.auth.confirmResetPass,
			apiParams,
		);

		yield onEmailSend(data.user_type);
		onSuccess(true);
	} catch (error) {
		onSuccess(true);
		// showError(error);
	}
}

function* confirIpAddressWorker({ payload }: PayloadAction<IConfirIpAddressRequest>) {
	const { apiParams, setMessage, isError } = payload;
	try {
		const data: { status: string; msg: string } = yield call(api.auth.confirIpAddress, apiParams);
		yield setMessage(data.msg);
	} catch (error: any) {
		setMessage(error?.response?.data?.msg);
		isError(true);
		showError(error);
	}
}

function* forgotPasswordWorker({ payload }: PayloadAction<IForgotPasswordRequest>) {
	const { apiParams, pathname, goToEmail } = payload;
	try {
		yield routes.login.includes(pathname)
			? call(api.auth.forgotPasswordProvider, apiParams)
			: call(api.auth.forgotPasswordMerchant, apiParams);
		yield goToEmail(true);
	} catch (error) {
		showError(error);
	}
}

function* firstLoginWorker({ payload }: PayloadAction<{ token: string }>) {
	try {
		const data: IFirstLoginResponse = yield call(api.auth.firstLogin, payload);
		yield put(firstLoginSuccess(data));
	} catch (error) {
		yield put(logoutError());
		showError(error);
	}
}

function* request2FaResetWorker({ payload }: PayloadAction<IParams2faREstSlice>) {
	const { apiParams } = payload;

	try {
		yield call(api.auth.request2FaReset, apiParams);
	} catch (error) {
		yield put(logoutError());
		showError(error);
	}
}

function* request2FaResetProviderWorker({ payload }: PayloadAction<IParams2faREstProviderSlice>) {
	const { apiParams } = payload;

	try {
		yield call(api.auth.request2FaResetProvider, apiParams);
	} catch (error) {
		yield put(logoutError());
		showError(error);
	}
}

function* request2FaResetMerchantWorker({ payload }: PayloadAction<IParams2faREstProviderSlice>) {
	const { apiParams } = payload;

	try {
		yield call(api.auth.request2FaResetMerchant, apiParams);
	} catch (error) {
		yield put(logoutError());
		showError(error);
	}
}

export function* authSaga() {
	yield takeLeading(createPasswordRequest, createPasswordWorker);
	yield takeLeading(loginRequest, loginWorker);
	yield takeLeading(logoutRequest, logoutWorker);
	yield takeLeading(loginMerchantRequest, loginMerchantWorker);
	yield takeLeading(logoutMerchantRequest, logoutMerchantWorker);
	yield takeLeading(confirmResetPassRequest, confirmResetPassWorker);
	yield takeLeading(confirIpAddressRequest, confirIpAddressWorker);
	yield takeLeading(forgotPasswordRequest, forgotPasswordWorker);
	yield takeLeading(firstLoginRequest, firstLoginWorker);
	yield takeLeading(reset2faRequest, request2FaResetWorker);
	yield takeLeading(reset2faMerchantRequest, request2FaResetMerchantWorker);
	yield takeLeading(reset2faProviderRequest, request2FaResetProviderWorker);
}
