import { HTMLAttributes } from 'react';
import Button from 'v2Components/UI/Button';
import { CloseAccountIcon, TrashBlueIcon } from 'assets/inline-svg';
import styles from './styles.module.scss';

interface IDrawerHeader extends HTMLAttributes<HTMLDivElement> {
	title: string;
	onActionDrawer?: () => void;
	onCloseDrawer: () => void;
}

const DrawerHeader = ({ title, onCloseDrawer, onActionDrawer }: IDrawerHeader): JSX.Element => {
	return (
		<div className={styles['drawer-header']}>
			<div className={styles['drawer-header__left']}>{title}</div>
			<div className={styles['drawer-header__right']}>
				{onActionDrawer && (
					<Button icon clear onClick={onActionDrawer}>
						<TrashBlueIcon />
					</Button>
				)}
				<Button icon close onClick={onCloseDrawer}>
					<CloseAccountIcon />
				</Button>
			</div>
		</div>
	);
};

export default DrawerHeader;
