import { FC } from 'react';
import { Form, Formik } from 'formik';
import { TextField } from 'UI/Forms/TextField/TextField';
import { IUserProfileFormProps } from './types';
import { TopInfoBlock } from './TopInfoBlock/TopInfoBlock';
import { TwoFABlock } from './TwoFABlock/TwoFABlock';
import { RoleBlock } from './RoleBlock/RoleBlock';
import { WhiteListIPBlock } from './WhiteListIPBlock/WhiteListIPBlock';
import { getValidationSchema } from './utils';
import { ActionsBlock } from './ActionsBlock/ActionsBlock';
import styles from './styles.module.scss';

export const UserProfileForm: FC<IUserProfileFormProps> = ({ user, onSubmit }) => {
	return (
		<Formik
			initialValues={{
				fullName: user.provider.name,
				email: user.provider.email,
			}}
			validationSchema={getValidationSchema()}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({ dirty, handleReset }) => (
				<Form>
					<div className={styles.fields__wrap}>
						<div className="fields-item">
							<div className="fields-list">
								<TopInfoBlock user={user} />

								<TextField name="fullName" label="Full Name" />

								<TextField name="email" label="Email" inputProps={{ readOnly: true }} />

								{user.provider.role.name !== 'PROVIDER_ADMIN' && <TwoFABlock user={user} />}
							</div>
						</div>
					</div>

					<RoleBlock user={user} />

					<WhiteListIPBlock user={user} />

					<div className="buttons-wrap">
						<ActionsBlock user={user} />

						<div className="buttons">
							<button
								type="button"
								className="button button--type2"
								disabled={!dirty}
								onClick={handleReset}
							>
								Cancel
							</button>
							<button type="submit" className="button" disabled={!dirty}>
								Save change
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
