import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getNetworkRequest } from 'redux/reducers/app/reducer';
import { getProviderAllCurrencyRequest } from 'redux/reducers/providerCurrency/reducer';
import TransactionsHistoryFiat from './TransactionsHistoryFiat';
import TransactionsHistoryCrypto from './TransactionsHistoryCrypto';
import { IPropsTabs } from './types';

const TransactionsHistory: FC<IPropsTabs> = ({ currency }) => {
	const dispatch = useDispatch();
	const isCrypto = useMemo(() => currency === 'crypto', [currency]);
	useEffect(() => {
		dispatch(getProviderAllCurrencyRequest());
		dispatch(getNetworkRequest());
	}, [dispatch]);

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{isCrypto ? (
				<TransactionsHistoryCrypto currency={currency} />
			) : (
				<TransactionsHistoryFiat currency={currency} />
			)}
		</>
	);
};

export default TransactionsHistory;
