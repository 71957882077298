import { Form, Formik } from 'formik';
import { FC } from 'react';
import { TextField } from 'UI/Forms/TextField/TextField';
import { RoleDescription } from './RoleDescription/RoleDescription';
import { RoleField } from './RoleField/RoleField';
import { ProjectField } from './ProjectField/ProjectField';
import { IAddNewAdminFormProps, IAddNewUserFormValues } from './types';
import { getValidationSchema } from './utils';

export const AddNewUserForm: FC<IAddNewAdminFormProps> = ({
	roles,
	projects,
	onSubmit,
	onCancel,
}) => {
	const initialValues: IAddNewUserFormValues = {
		firstName: '',
		lastName: '',
		email: '',
		role: null,
		project: null,
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={getValidationSchema()}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, values }) => (
				<Form>
					<div className="popup-header">
						<p className="popup-header__title popup-header__title--mb-32 popup-header__title--ta-l">
							Add new user
						</p>
					</div>

					<div className="popup-body">
						<TextField name="firstName" label="First name" placeholder="Enter first name" />

						<TextField name="lastName" label="Last name" placeholder="Enter last name" />

						<TextField name="email" label="Email" placeholder="Enter email" />

						<RoleField roles={roles} />

						{/* <RoleDescription role={values.role} /> */}

						<ProjectField project={projects} />
					</div>

					<div className="popup-button popup-button--type2">
						<button
							type="button"
							className="button button--full-width button--type2"
							onClick={onCancel}
						>
							Cancel
						</button>
						<button type="submit" className="button button--full-width" disabled={isSubmitting}>
							Add user
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};
