import { MerchantProjectCard } from 'components/Merchant/MerchantProjectsList/MerchantProjectCard/MerchantProjectCard';
import { MerchantProjectsListHeader } from 'components/Merchant/MerchantProjectsList/MerchantProjectsListHeader/MerchantProjectsListHeader';
import { ProviderHeader } from 'layouts-elements/ProviderHeader/ProviderHeader';
import { FC } from 'react';

export const MerchantProjectsListPage: FC = () => {
	return (
		<div className="wrapper">
			<ProviderHeader />

			<main className="main">
				<section className="main-section">
					<div className="content-container">
						<div className="main-content main-content--type3">
							<MerchantProjectsListHeader />
							<MerchantProjectCard />
						</div>
					</div>
				</section>
			</main>
		</div>
	);
};
