import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { api, showError } from 'services';
import { constUserTypes } from 'services/constants/user-types';
import { IDeleteWhiteListIpRecordPayload } from '../administration/types';
import {
	changePasswordRequest,
	getMerchantSecurityWhiteListRequest,
	deleteAllMerchantSecurityWhiteListRequest,
	getMerchantSecurityWhiteListResponse,
	deleteWhiteListIpRequest,
	deleteWhiteListIpSuccess,
	deleteAllMerchantSecurityWhiteList,
} from './reducer';
import {
	IFormPayload,
	IParamasWhiteList,
	IParamasWhiteListDelete,
	IWhiteListResponse,
} from './types';
// import { notify } from '../../../utils/notify';

function* putChangePasswordRequestWorker({ payload }: PayloadAction<IFormPayload>) {
	const { apiPayload, onSuccess, onError, userType } = payload;
	try {
		yield userType === constUserTypes.MERCHANT
			? call(api.merchant.putPassword, apiPayload)
			: call(api.merchant.putPasswordProvider, apiPayload);
		// yield put(getMerchantSettingsSuccess(data));
		onSuccess?.();
	} catch (error) {
		onError?.();
		showError(error);
	}
}

function* getMerchantSecurityWhiteListWorker({ payload }: PayloadAction<IParamasWhiteList>) {
	const { params, userType } = payload;

	try {
		const response: Array<IWhiteListResponse> = yield userType === constUserTypes.MERCHANT
			? call(api.merchant.getWhiteList)
			: call(api.merchant.getWhiteListProvider, params);
		yield put(getMerchantSecurityWhiteListResponse(response));
	} catch (error) {
		showError(error);
	}
}

function* deleteAllMerchantSecurityWhiteListWorker({
	payload,
}: PayloadAction<IParamasWhiteListDelete>) {
	const { params, userType } = payload;

	try {
		yield userType === constUserTypes.MERCHANT
			? call(api.merchant.deleteAllWhiteList)
			: call(api.merchant.deleteAllWhiteListProvider, params);
		yield put(deleteAllMerchantSecurityWhiteList());
	} catch (error) {
		showError(error);
	}
}

function* deleteWhiteListIpRecordWorker(action: PayloadAction<IDeleteWhiteListIpRecordPayload>) {
	const { apiParams, onSuccess, userType } = action.payload;
	try {
		yield userType === constUserTypes.PROVIDER
			? call(api.provider.deleteWhiteListIpRecord, apiParams)
			: call(api.provider.deleteWhiteListIpRecordMerchant, apiParams);
		yield put(deleteWhiteListIpSuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

export function* merchantSecuritySaga() {
	yield takeLatest(changePasswordRequest, putChangePasswordRequestWorker);
	yield takeLatest(
		deleteAllMerchantSecurityWhiteListRequest,
		deleteAllMerchantSecurityWhiteListWorker,
	);
	yield takeLeading(getMerchantSecurityWhiteListRequest, getMerchantSecurityWhiteListWorker);
	yield takeLeading(deleteWhiteListIpRequest, deleteWhiteListIpRecordWorker);
}
