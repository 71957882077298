/* eslint-disable jsx-a11y/anchor-is-valid */
import { ExclamationRoundIcon } from 'assets/inline-svg';
import classNames from 'classnames';
import { FC } from 'react';
import { IPasswordStrengthProps } from './types';

const uppercaseRegexp = /[A-Z]/;
const lowercaseRegexp = /[a-z]/;
const numberRegexp = /[0-9]/;
const specialSymRegexp = /[_#?!@$%^&*-]/;
const minLengthRegexp = /.{8,}/;

export const PasswordStrength: FC<IPasswordStrengthProps> = ({ password }) => {
	const passwordScores = (() => {
		const matchCount =
			Number(uppercaseRegexp.test(password)) +
			Number(lowercaseRegexp.test(password)) +
			Number(numberRegexp.test(password)) +
			Number(specialSymRegexp.test(password)) +
			Number(minLengthRegexp.test(password));

		return matchCount ? matchCount - 1 : 0;
	})();

	const strengthItems = (() => {
		const items = [];
		for (let i = 1; i <= 4; i += 1) {
			items.push({ id: i, isStrong: i <= passwordScores });
		}
		return items;
	})();

	return (
		<div className="password-options">
			<div className="password-options__header">
				<p className="input-name">Password strength</p>

				<div className="tooltip-item">
					<span className="tooltip-item__icon">
						<ExclamationRoundIcon />
					</span>
					<span className="tooltip tooltip--top tooltip--right tooltip--no-arrow">
						<span className="tooltip__text">
							<span>
								Password must be at least 8 characters long and include 1 uppercase letter, 1
								lowercase letter, 1 number, 1 special symbol (_#?!@$%^&*-)
							</span>
						</span>
					</span>
				</div>
			</div>

			<div className="password-strength">
				{strengthItems.map(({ id, isStrong }) => (
					<div
						key={id}
						className={classNames(
							'password-strength__item',
							'active',
							isStrong ? 'password-strength__item--strong' : 'password-strength__item--weak',
						)}
					/>
				))}
			</div>
		</div>
	);
};
