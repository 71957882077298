import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	getMerchantApprovalManagementProviderRequest,
	getMerchantApprovalManagementRequest,
} from 'redux/reducers/merchantApprovalManagement/reducer';
import {
	IGetMerchantApprovalManagementItem,
	TApiGetIApiMerchantApprovalManagementParamsPayload,
} from 'redux/reducers/merchantApprovalManagement/types';
import { Pagination } from 'UI/Pagination/Pagination';
import {
	getMerchantApprovalManagementList,
	getMerchantListLoader,
} from 'redux/reducers/merchantApprovalManagement/selectors';
import { getProjectList } from 'redux/reducers/merchantProjects/selectors';
import { getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { IApiGetProjectListItem } from 'services/api/merchant/types';
import useFilterList from 'hooks/useFilterList';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { getFilter } from 'redux/reducers/app/selectors';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import FilterWrapper from 'v2Components/layoutsComponents/Filter/FilterWrapper';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import Table from 'v2Components/commomComponents/Table';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { IFilter } from 'redux/reducers/app/types';
import { ISelect } from './types';
import { convertRequestParams, getFilterTeamMembersHistory } from './filter.helper';
import { Filters } from './Filters';
import TableRow from './TableBody/TableBody';
import styles from './styles.module.scss';

const TeamMembers = () => {
	const dispatch = useDispatch();
	const managementList = useSelector(getMerchantApprovalManagementList);
	const loading = useSelector(getMerchantListLoader);
	const projectList = useSelector(getProjectList);
	const userType = useSelector(getUserType);
	const filter = useSelector(getFilter);
	const { merchantId } = useParams<{ merchantId: string }>();
	const totalPages = managementList?.last_page || 1;
	const [search, setSearch] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [showFilter, setShowFilter] = useState(false);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('MerchantTeamMembers');
	const tryOnEmpty = (data: any) =>
		Object.keys(data)
			.filter((key: string) => data[key] !== '')
			.reduce((acc: any, key: string) => ({ ...acc, [key]: data[key] }), {});

	const projects: Array<ISelect> | undefined = useMemo(
		() =>
			projectList?.map((item: IApiGetProjectListItem, id) => ({
				value: id + 1,
				label: item.name,
				code: item.name,
			})),
		[projectList],
	);
	const filterList = useFilterList((getFilterHistory) =>
		getFilterTeamMembersHistory({
			...getFilterHistory,
			projects,
		}),
	);

	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());
	const onSubmit = (data: IFilter) => {
		dispatch(setFilter(convertRequestParams(data)));
		setCurrentPage(1);
		setShowFilter(false);
	};
	useEffect(() => {
		const payload: TApiGetIApiMerchantApprovalManagementParamsPayload = {
			apiParams: {
				...tryOnEmpty({ ...filter, merchant_owner_id: merchantId, name_email: search || '' }),
				current_page: currentPage,
			},
		};
		userType === constUserTypes.PROVIDER
			? dispatch(getMerchantApprovalManagementRequest(payload))
			: dispatch(getMerchantApprovalManagementProviderRequest(payload));
	}, [dispatch, filter, currentPage, userType, merchantId, search]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
				className={styles.filters__button}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<Filters projects={projects} />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={managementList?.data || []}
				getRow={(item: IGetMerchantApprovalManagementItem) => TableRow({ item })}
				gridTemplateColumns={gridTemplateColumns}
			/>
			{totalPages > 1 && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</PaginationWrapper>
			)}
		</>
	);
};

export default TeamMembers;
