import { FC } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { IConfirmProps } from './types';

export const CommentPopup: FC<IConfirmProps> = ({
	title,
	body,
	onClose,
	onConfirm,
	comment,
	setComment,
}) => {
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-460">
					<button aria-label="button" type="button" onClick={onClose} className="popup__close">
						<PopUpCloseIcon />
					</button>
					<div className="popup-header">
						<p className="popup-header__title popup-header__title--ta-l popup-header__title--mb-32">
							{title}
						</p>
					</div>

					<div className="popup-body">
						<div className="input">
							<p className="input-name">Comment</p>
							<div className="input-wrapper">
								<div className="textarea">
									<textarea value={comment} onChange={setComment} placeholder="Enter text" />
								</div>
							</div>
						</div>
					</div>
					<div className="popup-button popup-button--type2">
						<button
							onClick={onClose}
							type="button"
							className="button button--full-width button--type2"
						>
							Cancel
						</button>
						<button onClick={onConfirm} type="button" className="button button--full-width">
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
