import Dialog from '@mui/material/Dialog';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertIcon } from 'assets/inline-svg';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import { getProjectBalanceList } from 'redux/reducers/merchantAccounts/selectors';
import Button from 'v2Components/UI/Button';
import { getProjectList } from 'redux/reducers/merchantProjects/selectors';
import { cryptoTransferRequest } from 'redux/reducers/merchantAccounts/reducer';
import { IProjectBalanceResponse } from 'redux/reducers/merchantAccounts/types';
import ProjectSelect from '../../components/ProjectSelect';
import CurrencySelect from '../../components/CurrencySelect';
import CurrencyAmount from '../../components/CurrencyAmount';
import styles from './styles.module.scss';

interface IModalTransferProps {
	defaultFromId: number;
	closePopUp: () => void;
	open: boolean;
}

const ModalTransfer: FC<IModalTransferProps> = ({ defaultFromId, closePopUp, open }) => {
	const dispatch = useDispatch();
	const project = useSelector(getProjectDetails);
	const [amount, setAmount] = useState(0);
	const [warning, setWarning] = useState();
	const accounts = useSelector(getProjectBalanceList);
	const [currencyFrom, setCurrencyFrom] = useState<IProjectBalanceResponse | null>(null);
	const accountList = useMemo(
		() => accounts?.filter((account) => account.is_crypto === 1 && account.is_active) || [],
		[accounts],
	);
	const projectList = useSelector(getProjectList);
	const [projectChose, setProjectChose] = useState<any | null>(
		projectList?.find((item) => item.id === project?.id),
	);
	const [projectTo, setProjectTo] = useState<any | null>(
		projectList?.find((item) => item.id !== project?.id),
	);

	const isDisabled = useMemo(
		() => !!warning || !amount || !projectChose || !projectTo || !currencyFrom || amount < 0,
		[amount, currencyFrom, projectChose, projectTo, warning],
	);
	const onSubmit = () => {
		if (!!warning || !amount || !projectChose || !projectTo || !currencyFrom) {
			return;
		}
		const defaultData = {
			from_project_id: projectChose.id,
			to_project_id: projectTo.id,
			currency_setting_id: currencyFrom.currency_setting_id,
			amount,
		};
		dispatch(cryptoTransferRequest({ data: { ...defaultData }, closePopUp }));
	};
	const getRate = (value?: number) => value && setAmount(value);
	useEffect(() => {
		!projectChose && setProjectChose(projectList?.find((item) => item.id === project?.id));
		!projectTo && setProjectTo(projectList?.find((item) => item.id !== project?.id));
	}, [project?.id, projectChose, projectList, projectTo]);

	useEffect(() => {
		setProjectTo(projectList?.find((item) => item.id !== projectChose?.id));
	}, [projectChose, projectList]);

	useEffect(() => {
		if (open) {
			setAmount(0);
		} else {
			setCurrencyFrom(accounts?.find((coin) => coin.id === defaultFromId) || null);
		}
	}, [accounts, defaultFromId, open]);

	return (
		<div className={styles.content}>
			<div className={styles.head}>
				{project?.file_icon && (
					<span className={styles.logo}>
						<img src={project.file_icon} alt="" />
					</span>
				)}
				<div>
					<p className={styles.title}>Transfer</p>
					<p className={styles.subtitle}>{project?.name}</p>
				</div>
			</div>
			<div className={styles.body}>
				<ProjectSelect
					label="From"
					options={projectList || []}
					value={projectChose}
					setValue={setProjectChose}
					currencyCode={currencyFrom?.currency_network}
				/>
				<ProjectSelect
					label="To"
					options={projectList?.filter((item) => projectChose?.id !== item.id) || []}
					value={projectTo}
					setValue={setProjectTo}
					currencyCode={currencyFrom?.currency_network}
				/>
				<CurrencySelect
					label="Currency"
					options={accountList}
					value={currencyFrom}
					setValue={setCurrencyFrom}
					hideBalance
				/>
				{warning && (
					<div className={styles.warning}>
						<div className={styles.warning__icon}>
							<AlertIcon />
						</div>
						<div>
							<p className={styles.warning__title}>Attention</p>
							<div className={styles.warning__text}>
								Please, note, that {warning} on current project is set to auto withdrawal. Received
								amount will be sent to an external address
							</div>
						</div>
					</div>
				)}
				<CurrencyAmount
					getRate={getRate}
					value={amount}
					network={currencyFrom?.currency_network}
					code={currencyFrom?.currency?.code}
					max={currencyFrom?.balance}
				/>
			</div>
			<div className={styles.buttons}>
				<Button onClick={closePopUp} type="button" className={styles.button} light>
					Cancel
				</Button>
				<Button type="button" className={styles.button} disabled={isDisabled} onClick={onSubmit}>
					Transfer
				</Button>
			</div>
		</div>
	);
};

export default ModalTransfer;
