import { FC, useState, useRef, useMemo, useCallback, useEffect } from 'react';
import T from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { getAvailableCurrencies, getPaymentStatus } from 'redux/reducers/paymentWidget/selectors';
import { getMerchantInvoiceStatusRequest } from 'redux/reducers/paymentWidget/reducer';
import { IGetMerchantInvoiceStatusPayload } from 'redux/reducers/paymentWidget/types';
import { shortNotation } from 'utils/strings';
import paymentIcon from 'assets/inline-svg/payment/payment-1.svg';
import { dateToUTC } from 'utils/dates';
import payment4Icon from 'assets/inline-svg/payment/payment-4.svg';
import { notify } from 'utils/notify';
import { copyToClipboard } from 'utils/clipboard';
import { PaymentAddressIcon, PaymentErrorIcon, PaymentWarningIcon } from 'assets/inline-svg';
import { getDateDiffFormatted } from './utils';
import { IMakePayment } from './types';
import { PaymentTime } from '../PaymentTime/PaymentTime';
import { Steps } from '../PaymentWidget';

export const UnderPayment: FC<IMakePayment> = ({
	payment,
	onSuccess,
	onExpired,
	durationTime,
	setChangeStatus,
	newInvoce,
}) => {
	const dispatch = useDispatch();
	const currencies = useSelector(getAvailableCurrencies);

	const [restTime, setRestTime] = useState<{ mm: string; ss: string }>({
		mm: '15',
		ss: '00',
	});

	const { mm, ss } = restTime;

	const {
		amount,
		currency,
		amount_to_pay,
		currency_paid,
		currency_paid_network,
		address,
		qr_address,
		rate,
		due_date: dueDateStr,
		invoice_id,
	} = payment || {};

	// @TODO: Лучше получать изображение с сервера
	const currencyPaidObj = currencies?.find(
		(item) => item.currency_code === currency_paid?.toLowerCase(),
	);

	const statusState = useSelector(getPaymentStatus);

	const intervalRef = useRef<number | null>(null);
	const intervalInvoiceRequestRef = useRef<number | null>(null);
	const dueDate = useMemo(() => new Date(dueDateStr || ''), [dueDateStr]);

	const timerTime = new Date(dueDate).getTime() - dateToUTC(new Date()).getTime();

	const intervalCallback = useCallback(() => {
		const date = dateToUTC(new Date());
		const { mm: _mm, ss: _ss } = getDateDiffFormatted(dueDate, date);
		setRestTime({ mm: _mm, ss: _ss });
	}, [dueDate]);

	const invoiceRequestIntervalCallback = useCallback(() => {
		if (!invoice_id && invoice_id !== 0) {
			return;
		}
		const payload: IGetMerchantInvoiceStatusPayload = {
			apiParams: {
				invoice_id: statusState?.extra_invoive_id
					? Number(statusState?.extra_invoive_id)
					: +invoice_id,
			},
			onSuccess: (resp) => {
				const { status } = resp;

				switch (status) {
					case 'in_progress' || 'in_approvement' || 'risk_processing ':
						setChangeStatus?.(Steps.Progress);
						break;
					case 'overpaid':
						setChangeStatus?.(Steps.Overpaid);
						break;
					case 'underpaid' || 'new':
						setChangeStatus?.(Steps.Underpaid);
						break;
					case 'overdue' || 'overdue_and_paid':
						setChangeStatus?.(Steps.Overdu);
						break;
					case 'paid' || 'blocked':
						onSuccess?.();
						break;
					default:
				}
			},
		};
		dispatch(getMerchantInvoiceStatusRequest(payload));
		/* eslint-disable-next-line */
	}, [dispatch, invoice_id, onSuccess, setChangeStatus, statusState]);

	const onLoad = useCallback(() => {
		if (!intervalRef.current) {
			intervalRef.current = window.setInterval(intervalCallback, 1000);
		}
		if (!intervalInvoiceRequestRef.current) {
			intervalInvoiceRequestRef.current = window.setInterval(invoiceRequestIntervalCallback, 10000);
		}
	}, [intervalCallback, invoiceRequestIntervalCallback]);

	const clearIntervals = () => {
		if (intervalRef.current) {
			window.clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
		if (intervalInvoiceRequestRef.current) {
			window.clearInterval(intervalInvoiceRequestRef.current);
			intervalInvoiceRequestRef.current = null;
		}
	};

	const handleCopyAddress = () => {
		statusState?.address &&
			copyToClipboard(statusState?.address, () => {
				notify(String(T.translate('Payment.text_copy')), 'success');
			});
	};

	useEffect(() => {
		if (mm === '00' && ss === '00') {
			setChangeStatus?.(Steps.Overdu);
		}
		/* eslint-disable-next-line */
	}, [mm, ss]);

	useEffect(() => {
		clearIntervals();
		onLoad();
		return () => {
			clearIntervals();
		};
	}, [onLoad]);

	const calculateProgressBarWidth = (currentTime: number, totalTime: number) => {
		const progressPercentage = ((totalTime - currentTime) / totalTime) * 100;
		return progressPercentage;
	};

	return (
		<div className="payment-boxes">
			<div className="payment-conf">
				<span className="payment-title">
					<img src={payment4Icon} alt="icon" />
					{T.translate('Underpaid.title')}
				</span>
				<div className="payment-warng">
					<PaymentWarningIcon />
					{statusState?.extra_invoive_id || statusState?.status === 'underpaid' ? (
						<img src={paymentIcon} alt="icon" />
					) : (
						<span className="payment-warng_text">
							{T.translate('Underpaid.text_01')}{' '}
							<strong>
								{statusState?.old_amount_paid} {currencyPaidObj?.currency_code?.toUpperCase()}
							</strong>
							. {T.translate('Underpaid.text_02')}{' '}
							<strong>
								{Math.abs(Number(statusState?.old_issue))}{' '}
								{currencyPaidObj?.currency_code?.toUpperCase()}
							</strong>{' '}
							{T.translate('Underpaid.text_03')}
						</span>
					)}
				</div>
			</div>
			{statusState?.extra_invoive_id || statusState?.status === 'underpaid' ? (
				<img src={paymentIcon} alt="icon" />
			) : (
				<div className="payment-send">
					<div className="payment-code">
						<img src={statusState?.address_qr || ''} alt="img" />
					</div>
					<div className="payment-content">
						<span className="payment-sub">{T.translate('Underpaid.send')}</span>
						<span className="payment-us">
							{Math.abs(Number(statusState?.amount_to_pay))} {currency_paid}
						</span>
						<span className="payment-number">
							1 {currency_paid?.toUpperCase()} ~ {statusState?.rate} {currency?.toUpperCase()}
						</span>
						<div className="payment-addess">
							<span className="payment-addess_label">
								{T.translate('Underpaid.this')} {currency_paid_network?.toUpperCase()}{' '}
								{T.translate('Underpaid.address')}:
							</span>
							<span className="payment-addess_wrap">
								<input
									className="payment-addess_input"
									type="text"
									value={
										(statusState?.address && shortNotation(statusState?.address, 15, 20)) || '---'
									}
									id="myInput"
									readOnly
								/>
								<button
									aria-label="button"
									type="button"
									className="payment-addess_btn"
									onClick={handleCopyAddress}
								>
									<PaymentAddressIcon />
								</button>
								<span className="payment-addess_tooltip">{T.translate('Underpaid.copy')}</span>
							</span>
						</div>
						<span className="payment-error">
							<PaymentErrorIcon />
							{T.translate('Underpaid.copy_text_01')} {currency_paid?.toUpperCase()} (
							{currency_paid_network?.toUpperCase()} {T.translate('Underpaid.copy_text_02')})
							{T.translate('Underpaid.copy_text_03')} {currency_paid_network?.toUpperCase()}{' '}
							{T.translate('Underpaid.copy_text_04')}.
						</span>
					</div>
				</div>
			)}
			<PaymentTime
				width={`${calculateProgressBarWidth(Number(timerTime), Number(durationTime))}%`}
				minets={mm}
				seconds={ss}
				message="Awaiting payment"
			/>
		</div>
	);
};
