import classNames from 'classnames';
import { useField } from 'formik';
import { FC } from 'react';
import { ProjectStatusSelect } from './ProjectStatusSelect/ProjectStatusSelect';
import { IProjectStatus } from './ProjectStatusSelect/types';
import { IProjectStatusFieldProps } from './types';

export const ProjectStatusField: FC<IProjectStatusFieldProps> = ({ statuses }) => {
	const [field, meta, helpers] = useField<IProjectStatus | null>('status');

	const isShowError = meta.touched && meta.error;

	return (
		<div className={classNames('select-block', { 'select-block--error': isShowError })}>
			<p className="select-block__name">Project status:</p>

			<ProjectStatusSelect
				statuses={statuses}
				active={field.value}
				onChange={(newStatus) => helpers.setValue(newStatus)}
				onClose={() => helpers.setTouched(true)}
			/>

			{isShowError && <p className="input-notification input-notification--error">{meta.error}</p>}
		</div>
	);
};
