// *******************************
// @TODO Не используется в проэкте
// Можно удалять
// *******************************

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProviderWalletsStore, ICreateWithdrawalRequest } from './types';

export const initialState: IProviderWalletsStore = {
	// components Index
	providerWalletsBalances: [],
	providerWalletsBalancesLoading: false,
	putProviderWalletsBalancesLoading: false,
	// Index History
	providerWalletsTransactionsHistory: [],
	providerWalletsTransactionsHistoryLoading: false,
	putProviderWalletsTransactionsHistoryLoading: false,
	// Index History
	providerWalletsProfit: [],
	providerWalletsProfitLoading: false,
	putProviderWalletsProfitLoading: false,
};

const providerWallets = createSlice({
	name: '@@providerWallets',
	initialState,
	reducers: {
		getProviderWalletsBalancesRequest: (state, _: PayloadAction<any>) => {
			state.providerWalletsBalancesLoading = true;
		},
		getProviderWalletsBalancesSuccess: (state, action: PayloadAction<any[]>) => {
			state.providerWalletsBalancesLoading = false;
			state.providerWalletsBalances = action.payload;
		},
		putProviderWalletsBalancesRequest: (state, _: PayloadAction<any>) => {
			state.putProviderWalletsBalancesLoading = true;
		},
		putProviderWalletsBalancesSuccess: (state, action: PayloadAction<any>) => {
			const result = state.providerWalletsBalances.map((obj: any) => {
				return obj.id === action.payload.id ? { ...obj, ...action.payload } : obj;
			});
			state.putProviderWalletsBalancesLoading = false;
			state.providerWalletsProfit = result;
		},
		postProviderCreateWithdrawalRequest: (
			state,
			action: PayloadAction<ICreateWithdrawalRequest>,
		) => {},
	},
});

export default providerWallets.reducer;
export const {
	getProviderWalletsBalancesRequest,
	getProviderWalletsBalancesSuccess,
	putProviderWalletsBalancesRequest,
	putProviderWalletsBalancesSuccess,
	postProviderCreateWithdrawalRequest,
} = providerWallets.actions;
