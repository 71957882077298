import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { getProfile } from 'redux/reducers/merchantProfile/selectors';
import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import {
	getProjectListMerchantRequest,
	getProjectListProviderRequest,
} from 'redux/reducers/merchantProjects/reducer';
import { getProjectList } from 'redux/reducers/merchantProjects/selectors';
import { IGetProjectListPayload } from 'redux/reducers/merchantProjects/types';
import { getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { IApiGetProjectListItem } from 'services/api/merchant/types';
import { routes } from 'routes/routes';
import { useSelect } from 'hooks';
import classNames from 'classnames';
import { ProjectSelectItem } from './ProjectSelectItem/ProjectSelectItem';

const { oneProjectBase } = routes;

const oneProjectBaseRegExp = new RegExp(oneProjectBase.replace(/:merchantId|:projectId/g, '\\d+'));

export const ProjectInfo = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const merchant = useSelector(getProfile);
	const project = useSelector(getProjectDetails);
	const projectList = useSelector(getProjectList);
	const { merchantId, projectId } = useParams<{ merchantId: string; projectId: string }>();
	const userType = useSelector(getUserType);

	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const { pathname, search } = location;

	const handleSelect = (item: IApiGetProjectListItem) => {
		const { id } = item;
		let newProjectBase = oneProjectBaseRegExp.exec(pathname)?.[0];

		newProjectBase = newProjectBase?.replace(/\/project\/\d+/, `/project/${id}`);

		if (newProjectBase?.endsWith('/')) {
			newProjectBase = newProjectBase.slice(0, newProjectBase.length - 1);
		}

		const newUrl =
			newProjectBase && `${pathname.replace(oneProjectBaseRegExp, newProjectBase)}${search || ''}`;

		newUrl && history.replace(newUrl);

		setOpen(false);
	};

	useEffect(() => {
		if (projectList) {
			return;
		}
		const payload: IGetProjectListPayload = {
			apiParams: { merchant_id: merchantId },
		};
		userType === constUserTypes.PROVIDER
			? dispatch(getProjectListProviderRequest(payload))
			: dispatch(getProjectListMerchantRequest(payload));
	}, [dispatch, merchantId, projectList, userType]);

	return (
		<div
			className={classNames(
				'select select--height-55 select--type-choice select--type-choice-type2',
				{ active: open },
			)}
		>
			<button type="button" ref={triggerRef} onClick={toggleOpen} className="select__current">
				<span className="token token--type3">
					{project?.file_icon && (
						<span className="token__icon">
							<img src={project.file_icon} alt="" />
						</span>
					)}

					<span className="token__name">
						<span className="token__id">{merchant?.merchant.id_merchant}</span>
						{project?.name}
					</span>
				</span>
				<span className="select__current-arrow">
					<TriangleArrowDownIcon2 />
				</span>
			</button>

			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll select__drop-scroll--type2">
					<div className="select__drop-item">
						<ul>
							{projectList?.map((item) => (
								<li key={item.id}>
									<ProjectSelectItem
										item={item}
										onSelect={handleSelect}
										active={item.id === +projectId}
									/>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
