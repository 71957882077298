import { FC } from 'react';
import { PencilWithLineIcon, CheckIcon, CloseAccountIcon, EyeIcon } from 'assets/inline-svg';
import LinkDetails from 'v2Components/commomComponents/Table/Td/LinkDetails';
import { IPropsAction } from './types';

export const Actions: FC<IPropsAction> = ({ active, onEdit, accountId, onSubmit }) => {
	return (
		<LinkDetails detailsUrl={`/approvements/merchant/${accountId}`}>
			{active === accountId ? (
				<>
					<button aria-label="button" onClick={onSubmit} type="button">
						<CheckIcon />
					</button>
					<button aria-label="button" onClick={() => onEdit(null)} type="button">
						<CloseAccountIcon />
					</button>
				</>
			) : (
				<button aria-label="button" onClick={() => onEdit(accountId)} type="button">
					<PencilWithLineIcon />
				</button>
			)}
		</LinkDetails>
	);
};
