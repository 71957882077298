import { TextField } from 'v2Components/formComponents/TextField';
import { SearchIcon } from 'assets/inline-svg';
import { SelectFieldCurrency } from 'UI/SelectFieldCurrency/SelectFieldCurrency';

export const FiltersPending = () => {
	return (
		<>
			<TextField
				name="merchant_id"
				label="Merchant ID"
				placeholder="Index by Merchant ID"
				renderRightIcon={<SearchIcon />}
			/>
			<SelectFieldCurrency />
		</>
	);
};
