import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { showClientRequest, editClientRequest } from 'redux/reducers/projectClients/reducer';
import { getShowClient, getShowClientLoading } from 'redux/reducers/projectClients/selectors';
import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import { CardArrowDownIcon } from 'assets/inline-svg';
import { notify } from 'utils/notify';
import { isFieldChanged } from 'utils/form';
import { trimLastZeros } from 'utils/strings';
import { isExist } from 'utils/numbers';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { IEditClientParams } from 'services/api/projectClients/types';
import { KYCDocuments } from './KYCDocuments/KYCDocuments';
import { TransactionHistory } from './TransactionHistory/TransactionHistory';
import { ClientEditForm, IValues } from './ClientEditForm/ClientEditForm';
import styles from './styles.module.scss';

const ProjectClientEdit = () => {
	const dispatch = useDispatch();
	const showClient = useSelector(getShowClient);
	const loading = useSelector(getShowClientLoading);
	const { client, total_incoming_transactions: totalIncomingTransactions } = showClient || {};
	const { amount, default_currency_code: defaultCurrencyCode } = totalIncomingTransactions || {};
	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = defaultCurrencyCode ? getViewDecimal(defaultCurrencyCode) : undefined;
	const { id, projectId } = useParams<{ id: string; projectId: string }>();

	useEffect(() => {
		dispatch(showClientRequest({ client_id: +id, project_id: +projectId }));
	}, [dispatch, id, projectId]);

	const handleSubmit = (values: IValues) => {
		const {
			firstName,
			lastName,
			phoneCode,
			phoneNumber,
			citizenship,
			residency,
			address,
			levelTrust,
			levelKyc,
			email,
		} = values;
		const phone = `+${String(phoneCode)}${String(phoneNumber)}`;
		const payload: IEditClientParams = {
			apiParams: {
				client_id: +id,
				first_name: isFieldChanged(firstName, client?.first_name) ? firstName : undefined,
				last_name: isFieldChanged(lastName, client?.last_name) ? lastName : undefined,
				phone: isFieldChanged(phone, client?.phone) ? phone : undefined,
				citizenship: isFieldChanged(citizenship, client?.citizenship) ? citizenship : undefined,
				country: isFieldChanged(residency, client?.country) ? residency : undefined,
				address: isFieldChanged(address, client?.address) ? address : undefined,
				level_trust:
					levelTrust && isFieldChanged(levelTrust, client?.level_trust) ? +levelTrust : undefined,
				level_kyc:
					levelKyc && isFieldChanged(levelKyc, client?.level_trust) ? +levelKyc : undefined,
				email,
			},
			onSuccess: () => {
				notify('Client edited successfully', 'success');
			},
		};
		dispatch(editClientRequest(payload));
	};

	return (
		<ProjectContent>
			<div className={styles.main}>
				{!loading && client && <ClientEditForm client={client} onSubmit={handleSubmit} />}
				<KYCDocuments />
				<TransactionHistory />
				<div className="main-info-category">
					<h4 className="category-title">Balance</h4>
					<div className="balance-block">
						<div className="balance-block__icon">
							<CardArrowDownIcon />
						</div>
						<div className="balance-block__info">
							<p className="balance-block__name">Total incoming transactions</p>
							<p className="balance-block__count">
								{totalIncomingTransactions
									? `${
											isExist(amount) ? trimLastZeros(Number(amount)?.toFixed(viewDecimals)) : ''
									  } ${String(defaultCurrencyCode)?.toUpperCase()}`
									: ''}
							</p>
						</div>
					</div>
				</div>
			</div>
		</ProjectContent>
	);
};

export default ProjectClientEdit;
