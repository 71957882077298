import { FC } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';

interface IProps {
	onForgotPass: () => void;
}

export const LinksGroup: FC<IProps> = ({ onForgotPass }) => {
	return (
		<div className="form-group form-group--mt-0">
			<div className="form-options">
				<Link to={routes.reset2fa} className="link">
					Reset 2FA
				</Link>

				<button type="button" onClick={onForgotPass} className="link">
					Forgot Password?
				</button>
			</div>
		</div>
	);
};
