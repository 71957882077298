import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAnalyticsResponse, IProviderAnalyticsStore } from './types';

export const providerAnalyticsStore = (state: IStoreState) => state.providerAnalytics;

export const getListProviderAnlyticsSelector = createSelector(
	[providerAnalyticsStore],
	(providerAnalytics: IProviderAnalyticsStore): IAnalyticsResponse | null =>
		providerAnalytics.analyticsList,
);

export const getProviderLoadingSelector = createSelector(
	[providerAnalyticsStore],
	(providerAnalytics: IProviderAnalyticsStore): boolean => providerAnalytics.analyticsLoading,
);
