import Button from 'v2Components/UI/Button';
import { TrashBlueIcon } from 'assets/inline-svg';
import classNames from 'classnames';
import { FilterItem } from './index';
import { IFilterWrapper } from './types';
import styles from './styles.module.scss';

const FilterWrapper = ({
	filterList,
	deleteItemFilter,
	onClearFilter,
	onOpenFilter,
	className,
}: IFilterWrapper): JSX.Element => {
	return (
		<div className={classNames(styles.filter__wrapper, className)}>
			<div className={styles.filter__content}>
				{filterList.length > 0 && (
					<>
						<div className={styles.filter__area}>
							{filterList.map((itemFilter) => (
								<FilterItem
									key={itemFilter.identify}
									content={itemFilter.content}
									label={itemFilter.label}
									identify={itemFilter.identify}
									onDelete={deleteItemFilter}
								/>
							))}
						</div>
						<Button clear size="small" onClick={onClearFilter} className={styles.filter__delete}>
							<TrashBlueIcon /> Clear
						</Button>
					</>
				)}
			</div>
			<Button
				filterBadge={!!filterList.length}
				filter
				onClick={onOpenFilter}
				className={styles.filter__button}
			>
				Filters
			</Button>
		</div>
	);
};

export default FilterWrapper;
