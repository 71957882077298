import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from '../../../services';
import {
	getProviderExportCSVTransactionRequest,
	getProviderExportCSVTransactionSuccess,
	getProviderExportCSVTransactionFiatWalletRequest,
	getProviderExportCSVTransactionFiatWalletSuccess,
} from './reducer';
import { downloadCSVFunc } from '../../../utils/downloadCSVFunc';
import { IProviderExportCSVTransactionPayload } from './types';

function* getProviderExportCSVTransactionWorker({
	payload,
}: PayloadAction<IProviderExportCSVTransactionPayload>) {
	const { nameFile, apiParams, onSuccess } = payload;
	try {
		const data: string = yield call(api.provider.getProviderExportCSVTransaction, apiParams);
		onSuccess?.();
		downloadCSVFunc(nameFile, data);
		yield put(getProviderExportCSVTransactionSuccess(data));
	} catch (error) {
		showError(error);
	}
}
function* getProviderExportCSVFiatWalletTransactionWorker({
	payload,
}: PayloadAction<IProviderExportCSVTransactionPayload>) {
	const { nameFile, apiParams, onSuccess } = payload;
	try {
		const data: string = yield call(
			api.provider.getProviderExportCSVFiatWalletTransaction,
			apiParams,
		);
		onSuccess?.();
		downloadCSVFunc(nameFile, data);
		yield put(getProviderExportCSVTransactionFiatWalletSuccess(data));
	} catch (error) {
		showError(error);
	}
}

export function* providerExportCSVSaga() {
	yield takeLatest(getProviderExportCSVTransactionRequest, getProviderExportCSVTransactionWorker);
	yield takeLatest(
		getProviderExportCSVTransactionFiatWalletRequest,
		getProviderExportCSVFiatWalletTransactionWorker,
	);
}
