import { ArrowLeftIcon } from 'assets/inline-svg';
import { useProjectMatchUrl } from 'hooks';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getOrderDetailsMerchantRequest } from 'redux/reducers/merchantOrders/reducer';
import { getOrderSelector } from 'redux/reducers/merchantOrders/selectors';
import { routes } from 'routes/routes';
import { trimLastZeros } from 'utils/strings';
import { isExist } from 'utils/numbers';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import Main from 'v2Components/layoutsComponents/Main';
import { Comments } from './Comments/Comments';
import { OrderHistory } from './OrderHistory/OrderHistory';

export const OrderDetails: FC = () => {
	const order = useSelector(getOrderSelector);
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { merchantId } = useParams<{ merchantId: string }>();
	const projectUrl = useProjectMatchUrl();

	const { getViewDecimal } = useViewDecimals();

	const viewDecimalsRequest = order?.order.currency_request
		? getViewDecimal(order?.order.currency_request)
		: undefined;

	useEffect(() => {
		dispatch(getOrderDetailsMerchantRequest({ order_merchant_id: id }));
	}, [dispatch, id]);

	return (
		<Main
			header={
				<button type="button" onClick={() => window.history.back()} className="back-page">
					<ArrowLeftIcon />
					<span>Back to Orders</span>
				</button>
			}
		>
			<ProjectContent>
				<div className="mobile-project-info">
					<div className="header-project">
						<div className="header-project__name">
							<p>Provider</p>
						</div>
					</div>
				</div>

				<div className="content-header content-header--mb-32">
					<div className="content-header__group">
						<p className="form-title">{`Order ID ${String(order?.order.id)}`}</p>
						<div className="status-block">
							<p className="block-text block-text--fs-14 status-block__title">Status:</p>
							<p
								className={`status-value status-value--green status-custom ${
									order ? String(order.order.status.replaceAll(' ', '-').toLowerCase()) : ''
								}`}
							>
								{order?.order.status}
							</p>
						</div>
					</div>
				</div>
				<div className="approvements-list">
					<div className="approvements-column">
						<div className="approvements-item">
							<p className="approvements-item__title">Client ID:</p>
							<div className="approvements-item__value">
								<a href="/" className="link">
									{order?.order.client_id}
								</a>
							</div>
						</div>
						<div className="approvements-item">
							<p className="approvements-item__title">Date&amp;Time:</p>
							<div className="approvements-item__value">
								<p>
									{order
										? `${moment(order?.order.created_at).format('DD.MM.YYYY, HH:mm')} UTC`
										: null}
								</p>
							</div>
						</div>
						<div className="approvements-item">
							<p className="approvements-item__title">Merchant ID:</p>
							<div className="approvements-item__value">
								<p>{merchantId}</p>
							</div>
						</div>
						<div className="approvements-item">
							<p className="approvements-item__title">Amount:</p>
							<div className="approvements-item__value">
								<p>
									{order &&
										isExist(order?.order.amount_to_pay) &&
										trimLastZeros(order?.order.amount_to_pay.toFixed(viewDecimalsRequest))}
								</p>
							</div>
						</div>
					</div>
					<div className="approvements-column">
						<div className="approvements-item">
							<p className="approvements-item__title">Currecncy:</p>
							<div className="approvements-item__value">
								<p>{order?.order.currency_request.toUpperCase()}</p>
							</div>
						</div>
						<div className="approvements-item">
							<p className="approvements-item__title">Amount paid:</p>
							<div className="approvements-item__value">
								<p>
									{order &&
										isExist(order?.order.amount_paid) &&
										trimLastZeros(order?.order.amount_paid.toFixed(viewDecimalsRequest))}
								</p>
							</div>
						</div>
						<div className="approvements-item">
							<p className="approvements-item__title">Currency paid:</p>
							<div className="approvements-item__value">
								<p>{order?.order.currency_paid.toUpperCase()}</p>
							</div>
						</div>
						<div className="approvements-item">
							<p className="approvements-item__title">Invoices:</p>
							<div className="approvements-item__value">
								<p>
									<Link to={`${projectUrl}${routes.oneProject.invoices}/${id}`} className="link">
										View all
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
				{order?.status_history.length && <OrderHistory history={order.status_history} />}
				{order?.order_comments && (
					<Comments coments={order?.order_comments} orderId={order.order.id} />
				)}
			</ProjectContent>
		</Main>
	);
};
