import { PopUpCloseIcon, CheckIcon } from 'assets/inline-svg';
import { FC, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { notify } from 'utils/notify';
import { putApprovementRefoundRequest } from 'redux/reducers/providerApprovements/reducer';
import { IProviderRefoundRequest } from 'redux/reducers/providerApprovements/types';
import { IPropsPopUps } from '../TabsContent/Merchant/types';

export const PopUpRefun: FC<IPropsPopUps> = ({ onAction, closePopup, valueAmount, code, id }) => {
	const dispatch = useDispatch();
	const [refound, setRefound] = useState({
		amount: valueAmount || '',
		address: '',
		include_fee: 0,
		id,
	});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value, checked } = e.currentTarget;
		setRefound({ ...refound, [`${name}`]: name === 'include_fee' ? Number(checked) : value });
	};

	const handleRequestRefound = () => {
		// if (Object.values(refound).filter((item) => item === '').length > 0) return;
		setIsSubmitting(true);
		const payloadData: IProviderRefoundRequest = {
			params: refound,
			onSuccess: () => {
				setIsSubmitting(false);
				closePopup?.();
				notify('Refund successfully done', 'success');
			},
			onError: () => {
				setIsSubmitting(false);
			},
		};
		dispatch(putApprovementRefoundRequest(payloadData));
	};
	const handleMax = () => {
		setRefound({ ...refound, amount: String(valueAmount) });
	};

	const submitDisabled = !refound.amount || !refound.address || isSubmitting;

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-460">
					<button
						aria-label="button"
						name="close"
						onClick={onAction}
						type="button"
						className="popup__close"
					>
						<PopUpCloseIcon />
					</button>
					<div className="popup-header">
						<p className="popup-header__title popup-header__title--ta-l ">Refund</p>
					</div>

					<div className="popup-body popup-body--type3">
						<div className="input">
							<p className="input-name">Refund Amount</p>
							<div className="input-wrapper">
								<input
									value={refound.amount}
									onChange={handleChange}
									name="amount"
									className="input-item input-item--pr-75"
									type="text"
									placeholder="0 BTC"
								/>
								<div className="input-text">
									<button onClick={handleMax} type="button">
										MAX
									</button>
									<p>
										{valueAmount} {code?.toUpperCase()}
									</p>
								</div>
							</div>
						</div>

						<div className="input input--mb-16">
							<p className="input-name">Wallet address</p>
							<div className="input-wrapper">
								<input
									value={refound.address}
									onChange={handleChange}
									name="address"
									className="input-item"
									type="text"
									placeholder="Enter wallet address"
								/>
							</div>
						</div>
					</div>
					<div className="checkbox checkbox--mb-0">
						<label className="checkbox__label">
							<input
								onChange={handleChange}
								name="include_fee"
								type="checkbox"
								className="hidden"
								checked={Boolean(refound.include_fee)}
							/>
							<span className="checkbox__item">
								<span className="checkbox__item-icon">
									<CheckIcon />
								</span>
							</span>
							<span className="checkbox__text">Network Fee by Provider</span>
						</label>
					</div>
					<div className="popup-button popup-button--type2">
						<button
							onClick={onAction}
							type="button"
							className="button button--full-width button--type2"
						>
							Cancel
						</button>
						<button
							onClick={handleRequestRefound}
							disabled={submitDisabled}
							type="button"
							className="button button--full-width"
						>
							Refund
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
