import classNames from 'classnames';
import { IFloating } from './types';
import styles from './styles.module.scss';

export const Floating = ({ label, children, className }: IFloating): JSX.Element => {
	return (
		<div className={classNames(styles.floating, className)}>
			{label && <p className={styles.floating__label}>{label}</p>}
			{children}
		</div>
	);
};
