import { FC } from 'react';
import L from 'i18n-react';
import { IRoleDescriptionProps } from './types';

export const RoleDescription: FC<IRoleDescriptionProps> = ({ role }) => {
	const roleDescription = role
		? L.translate(`Administration.Roles.Description.${role.code}`, { notFound: '' })
		: null;

	return roleDescription ? (
		<div className="pop-text">
			<p>{roleDescription}</p>
		</div>
	) : null;
};
