import { IApprovementCallback } from 'services/api/project/types';
import styles from './styles.module.scss';

export interface IPropsApprovementCallbackItem {
	response_code: number;
	request_body: string;
	response_body: string;
}
interface IProps {
	callbacks: IApprovementCallback[];
}
const ApprovementCallbackItem = ({
	response_code,
	request_body,
	response_body,
}: IPropsApprovementCallbackItem) => {
	const requestBody = JSON.parse(request_body)?.data;
	const getResponse = () => {
		try {
			return JSON.parse(response_body);
		} catch (e) {
			return response_body;
		}
	};
	const responseBody = getResponse();

	return (
		<div className={styles.user}>
			<p className={styles.user__value}> Request body</p>
			<div className={styles.user__text}>
				<ul>
					{Object.keys(requestBody).map((key) => (
						<li key={key}>
							<b>{key}</b>: {requestBody[key]}
						</li>
					))}
				</ul>
			</div>
			<p className={styles.user__value}> Response body</p>
			<div className={styles.user__text}>
				{typeof responseBody === 'string' && (
					<span>
						<b>Success:</b> {String(responseBody)}
					</span>
				)}
				{typeof responseBody !== 'string' && (
					<ul>
						<li>
							<b>Success:</b> {String(responseBody?.success)}
						</li>
						{!!responseBody?.error?.message && (
							<li>
								<b>Error:</b> {responseBody.error.message}
							</li>
						)}
					</ul>
				)}
			</div>
			<p className={styles.user__value}>Status: {response_code}</p>
		</div>
	);
};

const ApprovementCallbacks = ({ callbacks }: IProps) => {
	return (
		<div className={styles.users}>
			{callbacks.map((callback) => (
				<ApprovementCallbackItem
					key={callback.id}
					response_code={callback.response_code}
					request_body={callback.request_body}
					response_body={callback.response_body}
				/>
			))}
		</div>
	);
};

export default ApprovementCallbacks;
