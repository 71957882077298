import { FC, ChangeEvent } from 'react';
import InputMask from 'react-input-mask';
import { Select } from 'UI/GeneralSelect/GeneralSelect';
import { IPhoneInput } from './types';

export const PhoneInput: FC<IPhoneInput> = ({
	number,
	code,
	onChangeCode,
	onChangeNumber,
	countries,
	isSelectError,
	isInputError,
	openToUp,
	placeholder,
}) => {
	const handleChangeNumber = (e: ChangeEvent<HTMLInputElement>) => {
		// @TODO: Использовалось для маски, пока не удалять
		// const formattedValue = e.target.value.replace(/\+|-|_|\(|\)|\s/g, '');
		const { value } = e.target;
		if (!/^\d*$/.test(value)) {
			return;
		}
		onChangeNumber(e.target.value);
	};
	return (
		<div
			className={`phone-input ${isSelectError ? 'select-block--error' : ''} ${
				isInputError ? 'input--error' : ''
			} ${openToUp ? 'open-to-up' : ''}`}
		>
			<Select
				className="phone-input--code-select"
				options={countries}
				value={code}
				onChange={onChangeCode}
				valueProp="iso"
				renderOption={(option) => (
					<>
						<span>
							{option.name}
							<span className="country-code">&nbsp;&nbsp;+{option.code}</span>
						</span>{' '}
					</>
				)}
				renderSelected={(option) => `+${String(option.code)}`}
			/>
			<input
				className="input-item"
				value={number}
				onChange={handleChangeNumber}
				maxLength={13}
				placeholder={placeholder}
			/>

			{/* @TODO: Использовалось для маски, пока не удалять */}
			{/* <InputMask
				mask="(99) 999-99-99"
				className="input-item phone-input--input"
				value={number}
				onChange={handleChangeNumber}
			>
				{(inputProps: unknown) => <input {...inputProps} type="tel" />}
			</InputMask> */}
		</div>
	);
};
