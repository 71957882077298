import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IMerchantApi } from './types';

export const merchant: IMerchantApi = {
	// Projects
	getProjectListProvider: (params) =>
		http.get(endpoint.merchant.GET_PROJECT_LIST, { params }).then(({ data }) => data),
	getProjectListMerchant: () =>
		http.get(endpoint.merchant.GET_PROJECT_LIST_MERCHANT).then(({ data }) => data),
	activateProject: (params) =>
		http.post(endpoint.merchant.ACTIVATE_PROJECT, params).then(({ data }) => data),
	deactivateProject: (params) =>
		http.post(endpoint.merchant.DEACTIVATE_PROJECT, params).then(({ data }) => data),
	activateProjectMerchant: (params) =>
		http.post(endpoint.merchant.ACTIVATE_PROJECT_MERCHANT, params).then(({ data }) => data),
	deactivateProjectMerchant: (params) =>
		http.post(endpoint.merchant.DEACTIVATE_PROJECT_MERCHANT, params).then(({ data }) => data),
	deleteProject: (params) =>
		http.delete(endpoint.merchant.DELETE_PROJECT, { params }).then(({ data }) => data),
	deleteMerchantNotProvider: (params) =>
		http
			.delete(endpoint.merchant.DELETE_MERCHANT_NOT_PROVIDER, { params })
			.then(({ data }) => data),
	deleteMerchantProject: (params) =>
		http.delete(endpoint.merchant.DELETE_MERCHANT_PROJECT, { params }).then(({ data }) => data),
	postProject: (params) =>
		http.post(endpoint.merchant.CREATE_PROJECT, params).then(({ data }) => data),
	postOwnProject: (params) =>
		http.post(endpoint.merchant.CREATE_OWN_PROJECT, params).then(({ data }) => data),
	// Management
	getApprovaManagementlList: (params) =>
		http.get(endpoint.merchant.GET_APPROVAL_MANAGEMENT_LIST, { params }).then(({ data }) => data),
	getApprovaManagementlListProvider: (params) =>
		http
			.get(endpoint.merchant.GET_APPROVAL_NOT_PROVIDER_MANAGEMENT_LIST, { params })
			.then(({ data }) => data),
	deleteMerchant: (params) =>
		http
			.delete(endpoint.merchant.DELETE_MERCHANT, { params: { id: params.id } })
			.then(({ data }) => data),
	deleteMerchantRole: ({ owner_merchant_id, project_id, merchant_id }) =>
		http
			.post(endpoint.merchant.DELETE_MERCHANT_ROLE, { owner_merchant_id, project_id, merchant_id })
			.then(({ data }) => data),
	deleteMerchantRoleNotProvider: ({ project_id, merchant_id }) =>
		http
			.post(endpoint.merchant.DELETE_MERCHANT_ROLE_NOT_PROVIDER, { project_id, merchant_id })
			.then(({ data }) => data),
	blockMerchant: (payload) =>
		http.post(endpoint.merchant.POST_MERCHANT_BLOCK, payload).then(({ data }) => data),
	blockMerchantNotProvider: (payload) =>
		http.post(endpoint.merchant.POST_MERCHANT_BLOCK_NOT_PROVIDER, payload).then(({ data }) => data),
	addNewUser: (payload) =>
		http.post(endpoint.merchant.POST_ADD_NEW_USER, payload).then(({ data }) => data),
	addNewUserMerchant: (payload) =>
		http.post(endpoint.merchant.POST_ADD_NEW_USER_MERCHANT, payload).then(({ data }) => data),
	addNewRoleUser: (payload) =>
		http.post(endpoint.merchant.POST_ADD_NEW_ROLE_USER, payload).then(({ data }) => data),
	addNewRoleUserMerchant: (payload) =>
		http.post(endpoint.merchant.POST_ADD_NEW_ROLE_USER_MERCHANT, payload).then(({ data }) => data),
	getMerchantUserProfile: (params) =>
		http.get(endpoint.merchant.POST_MERCHANT_USER_PROFILE, { params }).then(({ data }) => data),
	getMerchantProfile: (params) =>
		http.get(endpoint.merchant.GET_MERCHANT_USER_MY_PROFILE, { params }).then(({ data }) => data),
	getMerchantMyProfile: (params) =>
		http.get(endpoint.merchant.GET_MERCHANT_USER_MY_PROFILE, { params }).then(({ data }) => data),
	editMerchantUserProfile: (payload) =>
		http.post(endpoint.merchant.POST_MERCHANT_EDIT_PROFILE, payload).then(({ data }) => data),
	editMerchantUserProvider: (payload) =>
		http.post(endpoint.merchant.POST_MERCHANT_EDIT_PROVIDER, payload).then(({ data }) => data),
	resetPasswordMerchantUserProfile: (payload) =>
		http.post(endpoint.merchant.RESET_MERCHANT_PROFILE_PASSWORD, payload).then(({ data }) => data),
	editMerchantRole: (params) =>
		http.post(endpoint.merchant.EDIT_MERCHANT_ROLE, params).then(({ data }) => data),
	editMerchantRoleNotProvider: (params) =>
		http.post(endpoint.merchant.EDIT_MERCHANT_ROLE_NOT_PROVIDER, params).then(({ data }) => data),
	editMerchantNotProviderRole: (params) =>
		http.post(endpoint.merchant.EDIT_MERCHANT_NOT_PROVIDER_ROLE, params).then(({ data }) => data),
	getBankAccounts: () => http.get(endpoint.merchant.GET_BANK_ACCOUNTS).then(({ data }) => data),
	getBankAccountsByOwner: (params) =>
		http.get(endpoint.merchant.GET_BANK_ACCOUNTS, { params }).then(({ data }) => data),
	addBankAccount: (payload) =>
		http.post(endpoint.merchant.ADD_BANK_ACCOUNT, payload).then(({ data }) => data),
	editBankAccount: (params) =>
		http.put(endpoint.merchant.EDIT_BANK_ACCOUNT, params).then(({ data }) => data),
	deleteBankAccount: (params) =>
		http.put(endpoint.merchant.DELETE_BANK_ACCOUNT, params).then(({ data }) => data),
	getCryptoWallets: () =>
		http.get(endpoint.merchant.GET_AUTO_WITHDRAWAL_ADDRESS).then(({ data }) => data),
	getCryptoWalletsByOwner: (params) =>
		http.get(endpoint.merchant.GET_AUTO_WITHDRAWAL_ADDRESS, { params }).then(({ data }) => data),
	addCryptoWallet: (params) =>
		http.post(endpoint.merchant.ADD_AUTO_WITHDRAWAL_ADDRESS, params).then(({ data }) => data),
	getInvoices: (params) =>
		http.get(endpoint.merchant.GET_INVOICES, { params }).then(({ data }) => data),
	getInvoice: (params) =>
		http.get(endpoint.merchant.GET_INVOICE, { params }).then(({ data }) => data),
	getInvoiceLink: (params) =>
		http.get(endpoint.merchant.GET_INVOICE_LINK, { params }).then(({ data }) => data),
	createInvoice: (params) =>
		http.post(endpoint.merchant.CREATE_INVOICE, params).then(({ data }) => data),
	downloadInvoicePDF: (params) =>
		http.get(endpoint.merchant.GET_INVOICE_PDF, { params }).then(({ data }) => data),
	sendInvoicePDF: (params) =>
		http.post(endpoint.merchant.SEND_INVOICE_PDF, { params }).then(({ data }) => data),
	sendInvoiceLink: (params) =>
		http.post(endpoint.merchant.SEND_INVOICE_LINK, params).then(({ data }) => data),
	exportCSVInvoiceMerchant: (params) =>
		http.post(endpoint.merchant.EXPORT_CSV_INVOICE_MERCHANT, params).then(({ data }) => data),
	getInvoiceStatus: (params) =>
		http.get(endpoint.merchant.GET_MERCHANT_INVOICE_STATUS, { params }).then(({ data }) => data),

	editCryptoWallet: (params) =>
		http.put(endpoint.merchant.EDIT_AUTO_WITHDRAWAL_ADDRESS, params).then(({ data }) => data),
	deleteCryptoWallet: (params) =>
		http.put(endpoint.merchant.DELETE_AUTO_WITHDRAWAL_ADDRESS, params).then(({ data }) => data),
	// Settings
	getSettings: () => http.get(endpoint.merchant.GET_SETTINGS).then(({ data }) => data),
	getSettingsProvider: (params) =>
		http.get(endpoint.merchant.GET_SETTINGS_PROVIDER, { params }).then(({ data }) => data),
	postSettings: (payload) =>
		http.put(endpoint.merchant.POST_SETTINGS, payload).then(({ data }) => data),
	postSettingsProvider: (payload) =>
		http.put(endpoint.merchant.POST_SETTINGS_PROVIDER, payload).then(({ data }) => data),
	getSettingsLanguage: () =>
		http.get(endpoint.merchant.GET_SETTINGS_LANGUAGE).then(({ data }) => data),
	// Security
	putPassword: (payload) =>
		http.put(endpoint.merchant.PUT_PASSWORD, payload).then(({ data }) => data),
	putPasswordProvider: (payload) =>
		http.put(endpoint.merchant.PUT_PASSWORD_PROVIDER, payload).then(({ data }) => data),
	getWhiteList: () => http.get(endpoint.merchant.GET_WHITE_LIST).then(({ data }) => data),
	getWhiteListProvider: (params) =>
		http.get(endpoint.merchant.GET_WHITE_LIST_PROVIDER, { params }).then(({ data }) => data),
	deleteAllWhiteList: () =>
		http.delete(endpoint.merchant.DELETE_WHITE_LIST).then(({ data }) => data),
	deleteAllWhiteListProvider: (params) =>
		http.delete(endpoint.merchant.DELETE_WHITE_LIST_PROVIDER, { params }).then(({ data }) => data),
	getMerchants: () => http.get(endpoint.merchant.GET_MERCHANTS_LIST).then(({ data }) => data),
	addDepositFiatReference: (payload) =>
		http.put(endpoint.merchant.ADD_DEPOSIT_FIAT_REFERENCE, payload).then(({ data }) => data),
	createWithdrawalFiat: (payload) =>
		http.post(endpoint.merchant.CREATE_WITHDRAWAL_FIAT, payload).then(({ data }) => data),
	// Transactions
	getMerchantFiatTransaction: (params) =>
		http
			.get(endpoint.merchant.GET_MERCHANT_PROJECT_FIAT_TRANSACTIONS, { params })
			.then(({ data }) => data),
	getFiatTransactionFiatView: (payload) =>
		http.get(endpoint.merchant.GET_VIEW_RELATED_MERCHANT_FIAT(payload)).then(({ data }) => data),
	getBankAccountByCurrency: (params) =>
		http.get(endpoint.merchant.GET_BANK_ACCOUNT_BY_CURRENCY, { params }).then(({ data }) => data),
};
