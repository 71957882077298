import { TdItem } from 'v2Components/commomComponents/Table/Td';
import { shortNotation } from 'utils/strings';
import { CopyIcon } from 'assets/inline-svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notify } from 'utils/notify';
import moment from 'moment/moment';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { IDelegationItem } from 'redux/reducers/providerTRXManagement/types';
import DropdownDialogItem from '../UndelegateDialog';
import styles from './styles.module.scss';

const DELEGATE = 'delegate';
interface IProps {
	item: IDelegationItem;
}
export const TableRow = ({ item }: IProps): TdItem[] => {
	const handleIbanCopy = () => notify('Address copied successfully!', 'info');
	const resource = item?.meta?.raw_data?.contract?.[0]?.parameter?.value.resource;
	return [
		{
			title: 'Resource',
			children: resource || '',
		},
		{
			title: 'Address',
			children: (
				<CopyToClipboard text={String(item.address_from)} onCopy={handleIbanCopy}>
					<div className={styles.account__value}>
						<p className={styles.copy__value}>
							{item.address_from && shortNotation(item.address_from, 5, 5)?.toUpperCase()}
						</p>
						<button aria-label="button" type="button" className={styles.copy}>
							<CopyIcon />
						</button>
					</div>
				</CopyToClipboard>
			),
		},
		{
			title: 'Receiver',
			children: (
				<CopyToClipboard text={String(item.address_to)} onCopy={handleIbanCopy}>
					<div className={styles.account__value}>
						<p className={styles.copy__value}>
							{item.address_to && shortNotation(item.address_to, 5, 5)?.toUpperCase()}
						</p>
						<button aria-label="button" type="button" className={styles.copy}>
							<CopyIcon />
						</button>
					</div>
				</CopyToClipboard>
			),
		},
		{
			title: 'Created',
			children: moment(item.created_at).format('DD.MM.YYYY'),
		},
		{
			title: 'TxID',
			children: (
				<p className={styles.copy__value}>
					{item.tx_hash && shortNotation(item.tx_hash, 5, 5)?.toUpperCase()}
				</p>
			),
		},
		{
			title: 'Amount',
			children: item.amount,
		},
		{
			title: 'Type',
			children: <div className={styles.type}>{item.delegation_type}</div>,
		},
		{
			title: 'Action',
			children: item.delegation_type === DELEGATE && (
				<TableButtons right>
					<DropdownDialogItem id={item.id} />
				</TableButtons>
			),
		},
	];
};
