import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import { IProjectInfo } from 'redux/reducers/analyticsMerchant/types';
import { toUTCDate } from 'utils/dates';

interface IProps {
	height: number;
	params: Array<IProjectInfo> | undefined;
}

export const ChartjsLine: FC<IProps> = ({ height, params = [] }) => {
	const data = {
		labels: params?.map((analytic) => toUTCDate(analytic.updated_at)),
		datasets: [
			{
				type: 'line',
				data: params?.map((analytic) => analytic.amount || analytic.quantity || 0),
				fill: true,
				backgroundColor: [
					'rgba(204, 222, 253, 0.8)',
					'rgba(237, 243, 254, 0.5)',
					'rgba(251, 252, 254, 0.2)',
				],
				borderColor: '#4285f5',
				borderWidth: 1,
				pointRadius: 3,
				pointHoverRadius: 4,
			},
		],
	};

	const options = {
		legend: {
			display: false,
		},
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			xAxes: [
				{
					gridLines: {
						display: true,
					},
				},
			],
			yAxes: [
				{
					ticks: {
						stepSize: 100,
					},
				},
			],
		},
		elements: {
			line: {
				tension: 0,
			},
		},
	};

	return <Line options={options} data={data} height={height} />;
};
