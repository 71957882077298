import { IPaymentArray } from 'services/api/public/types';

interface IObject extends IPaymentArray {
	status: string;
}
export const findLastObjectWithStatus = (objects: IObject[], status: string): IObject | undefined =>
	objects
		.slice()
		.reverse()
		.find((currentObject: IObject) => currentObject.status === status);
