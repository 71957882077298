import { useState, useEffect } from 'react';

export const getStorageValue = <T>(key: string, defaultValue: T): T => {
	const saved = localStorage.getItem(key);
	return saved && saved !== 'undefined' ? JSON.parse(saved) : defaultValue;
};

export const useLocalStorage = <T>(
	key: string,
	defaultValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] => {
	const [value, setValue] = useState<T>(() => {
		return getStorageValue<T>(key, defaultValue);
	});

	const removeValue = () => {
		localStorage.removeItem(key);
		setValue(defaultValue);
	};

	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	return [value, setValue, removeValue];
};
