import { IStoreState } from 'redux/types';

export const getMerchantInvoicesState = (state: IStoreState) => state.merchantInvoices;

export const getMerchantInvoices = (state: IStoreState) =>
	getMerchantInvoicesState(state).merchantInvoices;

export const getMerchantInvoicesLoading = (state: IStoreState) =>
	getMerchantInvoicesState(state).merchantInvoicesLoading;

export const getSaveLoading = (state: IStoreState) => getMerchantInvoicesState(state).saveLoading;

export const getInvoiceLoading = (state: IStoreState) =>
	getMerchantInvoicesState(state).invoiceLoading;

export const getInvoice = (state: IStoreState) => getMerchantInvoicesState(state).invoice;

export const getInvoiceStatus = (state: IStoreState) => getMerchantInvoicesState(state).status;
