import { FC } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { IDeleteUserAccount } from './types';

const DeleteUserAccount: FC<IDeleteUserAccount> = ({ onClose, onSubmit, id, owner_id }) => {
	return (
		<div className="popup popup--width-480">
			<button aria-label="button" type="button" className="popup__close">
				<PopUpCloseIcon
					onClick={(e) => {
						if (e.target === e.currentTarget) {
							onClose();
						}
					}}
				/>
			</button>
			<div className="popup-header">
				<p className="popup-header__title">Delete a user account</p>
			</div>
			<div className="popup-body">
				<p className="popup-text">Are you sure you want to delete the user account?</p>
			</div>
			<div className="popup-button popup-button--type2">
				<button
					onClick={() => {
						onClose();
					}}
					type="button"
					className="button button--full-width button--type2"
				>
					Cancel
				</button>
				<button
					onClick={() => onSubmit(id, owner_id)}
					type="button"
					className="button button--full-width button--type4"
				>
					Delete
				</button>
			</div>
		</div>
	);
};

export default DeleteUserAccount;
