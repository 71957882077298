import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
	children?: ReactNode;
	right?: boolean;
}

export default ({ children, right }: IProps) => {
	return <div className={classNames(styles.buttons, { [styles.right]: right })}>{children}</div>;
};
