import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { ISelectProps } from './types';

export const CurrencySelect = <V extends string | number | null>({
	options,
	title,
	value,
	onChange,
	onClick,
	valueProp = 'id',
	helperNotification,
	disabled,
}: ISelectProps<V>) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (_value: V) => {
		setOpen(false);
		onChange?.(_value);
	};

	const handleClick = () => {
		toggleOpen();
		onClick?.();
	};

	const selectedValue = options?.find((opt) => opt[valueProp] === value);

	const { code, name, img_path: imgPath } = selectedValue || {};

	return (
		<div className={`select-block ${disabled ? 'disabled' : ''}`}>
			<div className="select-block__name">
				<p>{title}</p>
			</div>
			<div className={`select select--height-55 ${open ? 'active' : ''}`}>
				<button
					type="button"
					className="select__current"
					disabled={disabled}
					ref={triggerRef}
					onClick={handleClick}
				>
					<span className="token">
						<span className="token__icon">
							<img src={imgPath} alt="" />
						</span>
						<span className="token__name">{name}</span>
						<span className="token__transcription">{code?.toUpperCase()}</span>
					</span>
					<span className="select__current-arrow">
						<TriangleArrowDownIcon2 />
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{options?.map((option) => (
									<li key={option.code}>
										<button
											type="button"
											className={value === option[valueProp] ? 'active' : ''}
											onClick={() => handleSelect(option[valueProp] as V)}
										>
											<span className="token">
												<span className="token__icon">
													<img src={option.img_path} alt="" />
												</span>
												<span className="token__name">{option.name}</span>
												<span className="token__transcription">{option.code?.toUpperCase()}</span>
											</span>
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			{helperNotification}
		</div>
	);
};
