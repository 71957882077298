/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProviderFeeStore, TApiPutProviderFeePayload, TApiPutProviderFeeResponse } from './types';

export const initialState: IProviderFeeStore = {
	getProviderFee: [],
	getProviderFeeLoading: false,
	putProviderFeeLoading: false,
};
const providerFee = createSlice({
	name: '@@providerFee',
	initialState,
	reducers: {
		getProviderFeeRequest: (state) => {
			state.getProviderFeeLoading = true;
		},
		getProviderFeeSuccess: (state, action: PayloadAction<TApiPutProviderFeeResponse[]>) => {
			state.getProviderFeeLoading = false;
			state.getProviderFee = action.payload;
		},
		putProviderFeeRequest: (state, action: PayloadAction<TApiPutProviderFeePayload>) => {
			state.getProviderFeeLoading = true;
		},
		putProviderFeeSuccess: (state, action: PayloadAction<TApiPutProviderFeeResponse>) => {
			const result = state.getProviderFee.map((obj: TApiPutProviderFeeResponse) => {
				return obj.id === action.payload.id ? { ...obj, ...action.payload } : obj;
			});
			state.putProviderFeeLoading = false;
			state.getProviderFee = result;
		},
	},
});

export default providerFee.reducer;
export const {
	getProviderFeeRequest,
	getProviderFeeSuccess,
	putProviderFeeRequest,
	putProviderFeeSuccess,
} = providerFee.actions;
