import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusIcon, UnionImg } from 'assets/inline-svg';
import { notify } from 'utils/notify';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { IBankAccountItem } from 'services/api/provider/types';
import {
	addBankAccountRequest,
	deleteBankAccountRequest,
	getBankAccountsByOwnerRequest,
} from 'redux/reducers/merchantBankAccount/reducer';
import { useParams } from 'react-router-dom';
import { getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { editBankAccountInMerchantRequest } from 'redux/reducers/providerBankAccount/reducer';
import { TAddBankAccountFormValues, TEditBankPageFormOnSubmit } from '../types';
import BankAccountItem from './BankAccountItem';
import DeleteBankModal from '../modal/DeleteBankModal';
import styles from './styles.module.scss';
import BankAccountsForm from './BankAccountsForm';
import BankAccountsFormEdit from './BankAccountsEdit';

interface IProps {
	bankList: IBankAccountItem[] | undefined;
}
export default ({ bankList }: IProps) => {
	const dispatch = useDispatch();
	const [item, setItem] = useState<any>({
		currency_id: null,
		currency_name: null,
		name_account: null,
		iban: null,
		reference: null,
		bic: null,
	});
	const [showFilter, setShowFilter] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openDelModal, setOpenDelModal] = useState(false);
	const { merchantId } = useParams<{ merchantId: string }>();
	const userType = useSelector(getUserType);
	const isProvider = userType === constUserTypes.PROVIDER;
	const closeDelModal = () => setOpenDelModal(false);
	const closeModal = () => setOpenModal(false);
	const openEditModal = (_item: any) => {
		setItem(_item);
		setOpenModal(true);
	};

	const openDeleteModal = (_item: any) => {
		setItem(_item);
		setOpenDelModal(true);
	};
	const handleAddBankAccount = (values: TAddBankAccountFormValues, resetForm: () => void) => {
		const apiParams = isProvider
			? {
					...values,
					owner_merchant_id: +merchantId,
					is_provider: false,
			  }
			: values;
		dispatch(
			addBankAccountRequest({
				apiParams,
				onSuccess: () => {
					resetForm();
					closeModal();
					notify('Bank account has been successfully added', 'success');
					dispatch(getBankAccountsByOwnerRequest({ owner_merchant_id: +merchantId }));
				},
			}),
		);
	};
	const handleDeleteBankAccount = () => {
		dispatch(
			deleteBankAccountRequest({
				apiParams: { bank_account_id: item.id },
				onSuccess: () => {
					notify('Bank account has been successfully deleted', 'success');
				},
			}),
		);
		setOpenDelModal(false);
	};
	const handleEditBankAccount: TEditBankPageFormOnSubmit = (values, { setSubmitting }) => {
		dispatch(
			editBankAccountInMerchantRequest({
				apiParams: {
					putParams: {
						bank_account_id: Number(item.id),
						...values,
					},
					getParams: { owner_merchant_id: Number(merchantId) },
				},
				onSuccess: () => {
					notify('Bank account has been successfully edited', 'success');
					setSubmitting(false);
				},
				onError: () => {
					setSubmitting(false);
				},
			}),
		);
	};

	return (
		<div className={styles.accounts}>
			{bankList?.map((el) => (
				<BankAccountItem
					key={el.id}
					item={el}
					openEditModal={openEditModal}
					openDeleteModal={openDeleteModal}
				/>
			))}
			<div className={styles.append} onClick={() => setShowFilter(true)}>
				<div className={styles.append__icon}>
					<PlusIcon />
				</div>
				<p className={styles.append__text}>
					Add New <br /> Bank Account
				</p>
				<div className={styles.append__union}>
					<UnionImg />
				</div>
			</div>
			<PopupModal open={openDelModal} onClose={closeDelModal}>
				<DeleteBankModal onClose={closeDelModal} onSubmit={handleDeleteBankAccount} />
			</PopupModal>
			<BankAccountsFormEdit
				showFilter={openModal}
				setShowFilter={closeModal}
				onSubmit={handleEditBankAccount}
				item={item}
			/>
			<BankAccountsForm
				setShowFilter={setShowFilter}
				showFilter={showFilter}
				onSubmit={handleAddBankAccount}
			/>
		</div>
	);
};
