import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import { ProviderHeaderV2 } from 'v2Components/layoutsComponents/ProviderHeader';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getStatusBurger } from 'redux/reducers/app/selectors';
import { MerchantSidebarV2 } from 'v2Components/layoutsComponents/MerchantSidebar';
import { IDashboardLayoutProps } from './types';
import styles from './styles.module.scss';
import { MerchantLayout } from '../MerchantLayout/MerchantLayout';

export const MerchantLayoutV2: FC<IDashboardLayoutProps> = ({ children, title, v2 }) => {
	const appTitle = APP_NAME || '';
	const layoutTitle = title ? ` | ${title}` : '';
	const statusBurger = useSelector(getStatusBurger);
	if (!v2) {
		return <MerchantLayout title={title}> {children}</MerchantLayout>;
	}
	return (
		<>
			<Helmet>
				<title>{`${appTitle}${layoutTitle}`}</title>
			</Helmet>
			<div className="wrapper">
				<ProviderHeaderV2 />
				<main className={classNames(styles.main, { [styles.active]: statusBurger })}>
					<section className="main-section">
						<MerchantSidebarV2 />
						{children}
					</section>
				</main>
			</div>
		</>
	);
};
