import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { notify } from 'utils/notify';
import { api, showError } from 'services';
import { constUserTypes } from 'services/constants/user-types';
import { IMerchantParams } from 'services/api/provider/types';
import {
	getMerchantApprovalManagementRequest,
	getMerchantApprovalManagementSuccess,
	deleteMerchantRequest,
	postBlockRequest,
	addNewUserRequest,
	updateMerchantApprovalManagementRequest,
	getUserMerchantProfileRequest,
	getMerchantUserProfileSuccess,
	editMerchantUserProfileRequest,
	resetMerchantUserProfileRequest,
	editMerchantRoleRequest,
	addNewUserRoleRequest,
	deleteMerchantRoleRequest,
	getMerchantApprovalManagementProviderRequest,
	updateMerchantApprovalRequest,
	editMerchantNotProviderRoleRequest,
	addNewUserMerchantRequest,
	postBlockMerchantRequest,
	setListMerchantResponse,
	getListMerchantRequest,
	getProviderListMerchantRequest,
} from './reducer';
import {
	TApiGetIApiMerchantApprovalManagementParamsPayload,
	IGetMerchantApprovalManagementResponse,
	IDeleteMerchantRequset,
	IBlockMerchantRequset,
	IPayloadRequset,
	IEditMerchantRequset,
	IApiGetUserProfileResponse,
	IEditMerchantRolePayload,
	IDeleteMerchantRoleRequset,
	IGetUserApproveMerchantSagaRequset,
	IMerchantResponse,
} from './types';
import { IProviderResetPasswordSaga } from '../providerMerchant/types';

function* getMerchantApprovalManagementWorker({
	payload,
}: PayloadAction<TApiGetIApiMerchantApprovalManagementParamsPayload>) {
	try {
		const response: IGetMerchantApprovalManagementResponse = yield call(
			api.merchant.getApprovaManagementlList,
			payload.apiParams,
		);
		yield put(getMerchantApprovalManagementSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* getMerchantApprovalManagementProviderWorker({
	payload,
}: PayloadAction<TApiGetIApiMerchantApprovalManagementParamsPayload>) {
	try {
		const response: IGetMerchantApprovalManagementResponse = yield call(
			api.merchant.getApprovaManagementlListProvider,
			payload.apiParams,
		);
		yield put(getMerchantApprovalManagementSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* getMerchantDeleteWorker({ payload }: PayloadAction<IDeleteMerchantRequset>) {
	const { onSuccess, id, userType } = payload;
	try {
		yield userType === constUserTypes.PROVIDER
			? call(api.merchant.deleteMerchant, payload)
			: call(api.merchant.deleteMerchantNotProvider, { id });
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteMerchantRoleWorker({ payload }: PayloadAction<IDeleteMerchantRoleRequset>) {
	const { onSuccess, userType } = payload;
	try {
		yield userType === constUserTypes.PROVIDER
			? call(api.merchant.deleteMerchantRole, payload)
			: call(api.merchant.deleteMerchantRoleNotProvider, payload);
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* getMerchantBlockWorker({ payload }: PayloadAction<IBlockMerchantRequset>) {
	try {
		yield call(api.merchant.blockMerchant, payload);
		notify(payload?.is_block === true ? 'Block user' : 'Unblock user', 'success');
		// yield put(getMerchantApprovalManagementSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* getMerchantBlockNotProviderWorker({ payload }: PayloadAction<IBlockMerchantRequset>) {
	try {
		yield call(api.merchant.blockMerchantNotProvider, payload);
		notify(payload?.is_block === true ? 'Block user' : 'Unblock user', 'success');
		// yield put(getMerchantApprovalManagementSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* getaddNewUserWorker({ payload }: PayloadAction<IPayloadRequset>) {
	const { apisParams, onSuccess, onError } = payload;
	try {
		yield call(api.merchant.addNewUser, apisParams);
		// notify(payload?.is_block === true ? 'Block user' : 'Unblock user', 'success');
		// yield put(getMerchantApprovalManagementSuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
		onError?.();
	}
}

function* getAddNewUserMerchantWorker({ payload }: PayloadAction<IPayloadRequset>) {
	const { apisParams, onSuccess, onError } = payload;
	try {
		yield call(api.merchant.addNewUserMerchant, apisParams);
		// notify(payload?.is_block === true ? 'Block user' : 'Unblock user', 'success');
		// yield put(getMerchantApprovalManagementSuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
		onError?.();
	}
}

function* addNewUserRoleRequestWorker({ payload }: PayloadAction<IPayloadRequset>) {
	const { apisParams, onSuccess, onError, userType } = payload;
	try {
		yield userType === constUserTypes.PROVIDER
			? call(api.merchant.addNewRoleUser, apisParams)
			: call(api.merchant.addNewRoleUserMerchant, apisParams);
		// notify(payload?.is_block === true ? 'Block user' : 'Unblock user', 'success');
		// yield put(getMerchantApprovalManagementSuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
		onError?.();
	}
}

function* getUserProfileMerchantWorker({
	payload,
}: PayloadAction<IGetUserApproveMerchantSagaRequset>) {
	const { params, userType } = payload;

	try {
		const response: IApiGetUserProfileResponse = yield userType === constUserTypes.PROVIDER
			? call(api.merchant.getMerchantUserProfile, params)
			: call(api.merchant.getMerchantProfile, params);
		yield put(getMerchantUserProfileSuccess(response));
	} catch (error) {
		// yield put(getUserProfileError());
		showError(error);
	}
}

function* editUserProfileMerchantWorker({ payload }: PayloadAction<IEditMerchantRequset>) {
	const { apisParams, onSuccess, userType } = payload;
	try {
		yield userType === constUserTypes.PROVIDER
			? call(api.merchant.editMerchantUserProfile, apisParams)
			: call(api.merchant.editMerchantUserProvider, apisParams);

		onSuccess?.();
	} catch (error) {
		// yield put(getUserProfileError());
		showError(error);
	}
}

function* resetPasswordUserProfileMerchantWorker({
	payload,
}: PayloadAction<IProviderResetPasswordSaga>) {
	const { paramApi, userType } = payload;

	try {
		yield userType === constUserTypes.PROVIDER
			? call(api.provider.providerResetPasswordMerchant, paramApi)
			: call(api.merchant.resetPasswordMerchantUserProfile, paramApi);
		notify('Resset password', 'success');
	} catch (error) {
		// yield put(getUserProfileError());
		showError(error);
	}
}

function* editProviderRoleWorker({ payload }: PayloadAction<IEditMerchantRolePayload>) {
	const { apiParams, onSuccess, userType } = payload;
	try {
		yield userType === constUserTypes.PROVIDER
			? call(api.merchant.editMerchantRole, apiParams)
			: call(api.merchant.editMerchantNotProviderRole, apiParams);
		// yield put(editProviderRoleSuccess(payload));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* editMerchantRoleWorker({ payload }: PayloadAction<IEditMerchantRolePayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.merchant.editMerchantNotProviderRole, apiParams);
		// yield put(editProviderRoleSuccess(payload));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* getMerchantWorker() {
	try {
		const response: Array<IMerchantResponse> = yield call(api.merchant.getMerchants);
		yield put(setListMerchantResponse(response));
	} catch (error) {
		showError(error);
	}
}

function* getProviderListMerchantWorker({ payload }: PayloadAction<IMerchantParams>) {
	try {
		const response: Array<IMerchantResponse> = yield call(api.provider.getMerchants, payload);
		yield put(setListMerchantResponse(response));
	} catch (error) {
		showError(error);
	}
}

export function* getMerchantApprovalManagementSaga() {
	yield takeLatest(
		[getMerchantApprovalManagementRequest, updateMerchantApprovalManagementRequest],
		getMerchantApprovalManagementWorker,
	);
	yield takeLatest(
		[getMerchantApprovalManagementProviderRequest, updateMerchantApprovalRequest],
		getMerchantApprovalManagementProviderWorker,
	);
	yield takeLatest(deleteMerchantRequest, getMerchantDeleteWorker);
	yield takeLatest(getUserMerchantProfileRequest, getUserProfileMerchantWorker);
	yield takeLatest(postBlockRequest, getMerchantBlockWorker);
	yield takeLatest(postBlockMerchantRequest, getMerchantBlockNotProviderWorker);
	yield takeLatest(addNewUserRequest, getaddNewUserWorker);
	yield takeLatest(addNewUserMerchantRequest, getAddNewUserMerchantWorker);
	yield takeLatest(addNewUserRoleRequest, addNewUserRoleRequestWorker);
	yield takeLatest(editMerchantUserProfileRequest, editUserProfileMerchantWorker);
	yield takeLatest(resetMerchantUserProfileRequest, resetPasswordUserProfileMerchantWorker);
	yield takeLatest(editMerchantRoleRequest, editProviderRoleWorker);
	yield takeLatest(editMerchantNotProviderRoleRequest, editMerchantRoleWorker);
	yield takeLatest(deleteMerchantRoleRequest, deleteMerchantRoleWorker);
	yield takeLatest(getListMerchantRequest, getMerchantWorker);
	yield takeLatest(getProviderListMerchantRequest, getProviderListMerchantWorker);
}
