import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import success from '../../assets/styles/images/success.png';

export const Success: FC<{ emailSuccess: string }> = ({ emailSuccess }) => {
	const { pathname } = useLocation();

	return (
		<div className="authorization-box">
			<div className="form form--authorization">
				<div className="form__header">
					<div className="form-img">
						<img src={success} alt="letter-icon" />
					</div>
					<p className="form-title form-title--center">Success</p>
					<div className="form-text form-text--center">
						<p>
							{!pathname.includes('first-time-login')
								? 'Your password has been successfully changed'
								: 'Account created successfully. Please log in'}
						</p>
					</div>
				</div>
				<div className="form-footer">
					<Link
						to={emailSuccess === 'provider' ? '/login' : '/login-merchant'}
						className="button button--full-width"
					>
						Log in
					</Link>
				</div>
			</div>
		</div>
	);
};
