import { useFormikContext } from 'formik';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
	getExchangeProviderRateFeeRequest,
	getExchangeRateFeeRequest,
} from 'redux/reducers/merchantAccounts/reducer';
import {
	getExchangeProviderRateFeeSelector,
	getExchangeRateFeeSelector,
} from 'redux/reducers/merchantAccounts/selectors';
import { CurrencySelectField } from 'UI/Forms/CurrencySelectField/CurrencySelectField';
import { CurrencyNetworkSelectField } from 'UI/Forms/CurrencyNetworkSelectField/CurrencyNetworkSelectField';
import { SelectField } from 'UI/Forms/SelectField/SelectField';
import { TextField } from 'UI/Forms/TextField/TextField';
import { timeOptions } from 'v2Pages/Provider/InvoicesPage/InvoicesTable/CreateInvoicePopup/CreateInvoiceForm/data';
import { convertExponentialToDecimal } from 'utils/numbers';
import { IInitialValuesOrders, IPropsOrderInvoice } from '../../types';

export const FormInvoiseOrder: FC<IPropsOrderInvoice> = ({
	onAction,
	orderId,
	currencies,
	currencySettings,
	defaultValues,
}) => {
	const { values, dirty, resetForm, setFieldValue } = useFormikContext<IInitialValuesOrders>();
	const dispatch = useDispatch();
	const { projectId } = useParams<{ projectId: string }>();
	const [defaultValuesInit, setDefaultValuesInit] = useState(false);

	useEffect(() => {
		if (!defaultValuesInit && currencies?.length > 0 && defaultValues) {
			const { currency_code, amount_request } = defaultValues;
			const currency = currencies?.find((item) => item.code === currency_code);
			setDefaultValuesInit(true);
			currency && setFieldValue('currencyRequest', currency.id);
			(amount_request || amount_request === 0) &&
				setFieldValue('amount_request', Math.abs(amount_request));
		}
	}, [currencies, defaultValues, defaultValuesInit, setFieldValue]);

	const rateAndFee = useSelector(getExchangeRateFeeSelector);
	const rateAndFeeProvider = useSelector(getExchangeProviderRateFeeSelector);

	const { rate } = rateAndFee || rateAndFeeProvider || {};

	useEffect(() => {
		const { currencyRequest, amount_request, currencyPaid } = values;
		if (!currencyRequest || !amount_request || !currencyPaid) {
			return;
		}

		const currencyRequestOption = currencies?.find((opt) => opt.id === +currencyRequest);
		const currencyPaidOption = currencySettings?.find((opt) => opt.id === +currencyPaid);

		/*		projectId
			? dispatch(
					getExchangeRateFeeRequest({
						amount: +amount_request,
						currency_code_from: currencyRequestOption?.code || '',
						currency_code_to: currencyPaidOption?.currency.code || '',
						from_project_id: projectId,
						is_exchange: 0,
					}),
			  )
			: dispatch(
					getExchangeProviderRateFeeRequest({
						currency_code_from: currencyRequestOption?.code || '',
						currency_code_to: currencyPaidOption?.currency.code || '',
					}),
			  ); */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencies, dispatch, values.currencyRequest, values.amount_request, values.currencyPaid]);

	useEffect(() => {
		if ((!rate && !Number.isInteger(rate)) || !values.amount_request) {
			return;
		}
		const { currencyPaid } = values;
		if (!currencyPaid) {
			return;
		}
		const currencyPaidOption = currencySettings?.find((opt) => opt.id === +currencyPaid);

		setFieldValue('order_id', orderId);
		const amountPay = Number(rate) * +values.amount_request;
		setFieldValue('rate', rate);
		setFieldValue('amount_to_pay', convertExponentialToDecimal(amountPay));
		setFieldValue('currency_paid', currencyPaidOption?.currency.code);
		setFieldValue('currency_paid_network', currencyPaidOption?.currency_network);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		orderId,
		rate,
		setFieldValue,
		values.amount_request,
		values.currencyPaid,
		values.currencyRequest,
	]);

	return (
		<>
			<div className="popup-header">
				<p className="popup-header__title popup-header__title--ta-l ">Create an invoice</p>
			</div>
			<div className="popup-body popup-body--type3">
				<div className="order-line">
					<p className="order-line__text">Order ID:</p>
					<a href="/" className="link link--type3">
						{orderId}
					</a>
				</div>
				<div className="select-block">
					<CurrencySelectField
						name="currencyRequest"
						title="Currency"
						options={currencies}
						valueProp="id"
						withNetworkName
						disabled
					/>
				</div>
				<TextField name="amount_request" label="Amount (cost)" placeholder="Amount" onlyNumbers />
				<div className="select-block">
					<CurrencyNetworkSelectField
						name="currencyPaid"
						title="Pay in currency"
						options={currencySettings}
						valueProp="id"
						withNetworkName
					/>
				</div>
				<TextField
					inputProps={{
						disabled: true,
					}}
					name="amount_to_pay"
					label="Amount (Pay in currency)"
					placeholder="Amount"
				/>

				<SelectField name="expires_time_minutes" title="Expires Time" options={timeOptions} />
				<TextField name="client_email" label="Client email" />
			</div>

			{/* <a href="/" className="link link--type3 link--center link--mt-16">
				 More actions 
			</a> */}
			<div className="popup-button popup-button--type2">
				<button
					onClick={(event: MouseEvent) => {
						onAction(event, '');
						resetForm();
					}}
					type="button"
					className="button button--full-width button--type2"
				>
					Cancel
				</button>
				<button type="submit" className="button button--full-width" disabled={!dirty}>
					Save
				</button>
			</div>
		</>
	);
};
