/* eslint-disable react-hooks/exhaustive-deps */
import { CopyIcon, InfoIcon, CloseAccountIcon } from 'assets/inline-svg';
import { ChangeEvent, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
	getCurrenciesSelector,
	getProjectBalanceList,
} from 'redux/reducers/merchantAccounts/selectors';
import { IProjectBalanceRequest } from 'redux/reducers/merchantAccounts/types';
import { getProjectList } from 'redux/reducers/merchantProjects/selectors';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import { EButtonName } from 'services/constants/account-btn';
import { cutWithDrawalAddress } from 'utils/cutWithdrawalAdddress';
import { notify } from 'utils/notify';
import { shortNotation, trimLastZeros } from 'utils/strings';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { AccountAction } from '../AccountAction/AccountAction';
import { PopUpDepositCrypto } from '../PopUps/PopUpDepositCrypto/PopUpDepositCrypto';
import { PopUpTransfer } from '../PopUps/PopUpTransfer/PopUpTransfer';
import { PopUpWithdrawal } from '../PopUps/PopUpWithdrawal/PopUpWithdrawal';
import { IPropsAccountTableBody } from './types';
import { AccountDrop } from './AccountDrop/AccountDrop';

const NOT_SET = 'not set';
const TableRowCrypto = ({ accountList, account, handleSubmit }: IPropsAccountTableBody) => {
	const {
		currency: { code: currency_code },
		currency_network,
	} = account;
	const { merchantId, projectId } = useParams<{ merchantId: string; projectId: string }>();
	const [active, setActive] = useState<number | null | string>(null);
	const [idCoin, setIdCoin] = useState<number | null>(null);
	const [accountId, setAccountId] = useState<number | null>(null);
	const [popUpOpen, setPopUpOpen] = useState('');
	const [balanceLimit, setBalanceLimit] = useState<string>('0');
	const [balance, setBalance] = useState('');
	const [reference, setReference] = useState('');
	const [current, setCurrent] = useState<string | null>(null);
	const [network, setNetwork] = useState<string>('');
	const dispatch = useDispatch();
	const currencies = useSelector(getCurrenciesSelector);
	const [didMount, setDidMount] = useState(true);
	const projectList = useSelector(getProjectList);
	const projectChose = useSelector(getProjectDetails);
	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = getViewDecimal(currency_code, currency_network);

	const onEdit = (value: number | null | string, id?: number) => {
		setActive(value);
		setIdCoin(id || null);
		setBalanceLimit(
			String(
				accountList.find(({ id: accountItemId }) => accountItemId === value)?.auto_withdrawal_limit,
			),
		);
		setBalance(
			String(accountList.find(({ id: accountItemId }) => accountItemId === value)?.balance) || '',
		);
		setReference(
			String(
				accountList?.find(({ id: accountItemId }) => accountItemId === value)
					?.deposit_fiat_reference || '',
			),
		);
		setCurrent('');
	};

	const onPopUps = (value: string, id?: number) => {
		if (projectList?.find((project) => project.id === +projectId)?.is_active) {
			setAccountId(id || null);
			setPopUpOpen(value);
			return;
		}
		if (
			!projectList?.find((project) => project.id === +projectId)?.is_active &&
			EButtonName.TRANSFER !== value &&
			EButtonName.EXCHANGE !== value
		) {
			setAccountId(id || null);
			setPopUpOpen(value);
			return;
		}
		notify('The project is not activated', 'info');
	};

	const handleInput = (event: ChangeEvent) => {
		const { value, name } = event.target as HTMLInputElement;
		name === 'limit' && setBalanceLimit(value);
		name === 'balance' && setBalance(value);
		name === 'deposit_fiat_reference' && setReference(value);
		setCurrent(null);
	};

	const putCurrency = (name: string, name_network: string) => {
		setCurrent(name);
	};
	const onSubmit = (event?: React.SyntheticEvent) => {
		event?.preventDefault();
		const data: IProjectBalanceRequest = {};
		data.auto_withdrawal_limit = null;
		if (balanceLimit) {
			data.auto_withdrawal_limit = +balanceLimit;
			data.auto_exchange_to_currency_name = null;
		}
		if (reference) {
			data.deposit_fiat_reference = reference;
		}
		if (current === NOT_SET) {
			/*			const defaultData: IAutoExchangeRequest = {
				from_balance_id:
					accounts?.find((acc) => acc.currency.code === account?.auto_exchange?.auto_exchange_code)
						?.id || '',
			};
			dispatch(
				cryptoAutoExchangeRequest({
					data: { ...defaultData },
					closePopUp: () => {},
				}),
			); */
		}

		handleSubmit(
			data,
			active as number,
			currencies?.find(
				(currency) => currency.currency_network === network && currency.currency_code === current,
			)?.id || null,
		);
		onEdit(null);
	};

	const handleAddressCopy = () => notify('Address id copied successfully!', 'info');
	const clearBalanceLimit = () => setBalanceLimit('');
	const address = account?.auto_withdrawal_address?.address;

	const exchangeNetworkName = account?.auto_exchange?.auto_exchange_network_name;
	const exchangeNetworkCode = account?.auto_exchange?.auto_exchange_code;

	useEffect(() => {
		if (didMount) {
			setDidMount(false);
		} else {
			setBalanceLimit('');
			setReference('');
			setBalance('');
			setCurrent('');
		}
	}, [dispatch]);

	return [
		{
			title: 'Currency',
			children: (
				<span className="token token--type2">
					<span className="token__icon">
						<img src={account.currency.img_path} alt="" />
					</span>
					<span className="token__name-wrap">
						<span className="token__name">{account.currency.name}</span>
						<br />
						<span className="token__transcription">
							{account.currency.code.toLowerCase() === account.network_name.toLowerCase()
								? account.network_name.toUpperCase()
								: `${account.currency.code.toUpperCase()}, ${account.network_name.toUpperCase()}`}
						</span>
					</span>
				</span>
			),
		},
		{
			title: 'Address',
			children: (
				<div className="copy-address copy-address--type2">
					{account.deposit_address ? (
						<>
							<p title={account?.deposit_address || ''}>
								{account.deposit_address ? cutWithDrawalAddress(account.deposit_address) : '-'}
							</p>
							<CopyToClipboard text={account.deposit_address || ''} onCopy={handleAddressCopy}>
								<button aria-label="button" type="button">
									<CopyIcon />
								</button>
							</CopyToClipboard>
						</>
					) : (
						''
					)}
				</div>
			),
		},
		{
			title: 'Balance',
			children: trimLastZeros(Number(account.balance).toFixed(viewDecimals)),
		},
		{
			title: 'Balance limit',
			children:
				active === account.id ? (
					<div className="input input--mb-0">
						<div className="input-wrapper">
							<input
								// required
								onChange={handleInput}
								className="input-item input-item--type-td input-item--right-icon"
								type="number"
								name="limit"
								value={balanceLimit || ''}
								// disabled={!account?.auto_withdrawal_address?.address}
							/>
							<button
								aria-label="button"
								type="button"
								onClick={clearBalanceLimit}
								className="input-clear input-clear--right35"
							>
								<CloseAccountIcon />
							</button>
							<div className="input-icon input-icon--right tooltip-item tooltip-item--absolute">
								{!account?.auto_withdrawal_address?.address && (
									<span className="tooltip-item__icon">
										<InfoIcon />
									</span>
								)}
								<span className="tooltip tooltip--top tooltip--center tooltip--no-arrow">
									<span className="tooltip__text tooltip__text--ta-c">
										<span>Cannot edit Balance limit if Backup wallet is not installed</span>
									</span>
								</span>
							</div>
						</div>
					</div>
				) : (
					<p className="td-value">
						{trimLastZeros(Number(account.auto_withdrawal_limit).toFixed(viewDecimals))}
					</p>
				),
		},
		{
			title: 'Auto Exchange',
			children:
				active === account.id && account.auto_exchange?.auto_exchange_code ? (
					<AccountDrop
						setCurrent={putCurrency}
						network={network}
						current={current as string}
						nameCurrent={account.auto_exchange?.auto_exchange_code}
						currencies={currencies?.filter(
							(currency) =>
								!!currency.is_exchangeable && currency.id !== idCoin && currency.is_crypto,
						)}
						currencyRow={account.currency_name}
					/>
				) : (
					<p className="td-value td-value--custom">
						{exchangeNetworkCode === exchangeNetworkName
							? exchangeNetworkCode || ''
							: exchangeNetworkName || ''}
					</p>
				),
		},
		{
			title: 'Reserve wallet',
			children: account.auto_withdrawal_address ? (
				<div className="copy-address copy-address--type2">
					{address && (
						<>
							<p className="td-value flex flex-m">
								<span className="tooltip-item">
									{shortNotation(String(address) || '', 6, 6)}
									<span
										style={{ wordWrap: 'break-word' }}
										className="tooltip tooltip--width-320 tooltip--top tooltip--center tooltip--arrow-bottom-center"
									>
										<span className="tooltip__text tooltip__text--ta-c">
											<span>{address}</span>
										</span>
									</span>
								</span>
							</p>
							<CopyToClipboard text={String(address)} onCopy={handleAddressCopy}>
								<button aria-label="button" type="button">
									<CopyIcon />
								</button>
							</CopyToClipboard>
						</>
					)}
				</div>
			) : (
				''
			),
		},
		{
			title: 'Action',
			children: (
				<AccountAction
					onEdit={onEdit}
					active={active}
					account={account}
					onPopUps={onPopUps}
					onSubmit={onSubmit}
				/>
			),
		},
	];
};

export default TableRowCrypto;
