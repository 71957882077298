import { FC, ReactNode } from 'react';
import styles from './styles.module.scss';

interface IProps {
	value: boolean;
	handleSwitchToggle: () => void;
	defaultChecked?: boolean;
	children: ReactNode;
}
const SwitchCheckbox: FC<IProps> = ({ handleSwitchToggle, value, defaultChecked, children }) => {
	return (
		<label className={styles.switch}>
			<span className={styles.switch__tooltip}>{children}</span>
			<input
				onChange={handleSwitchToggle}
				checked={value}
				type="checkbox"
				className={styles.switch__input}
				defaultChecked={defaultChecked}
			/>
			<span className={styles.switch__toggler} />
		</label>
	);
};

export default SwitchCheckbox;
