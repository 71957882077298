/* eslint-disable react-hooks/exhaustive-deps */
import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { FC, MouseEvent, useEffect } from 'react';
import { IPropsAccountDrop } from '../types';

export const AccountDrop: FC<IPropsAccountDrop> = ({
	nameCurrent,
	setCurrent,
	current,
	currencies,
	currencyRow,
	network,
}) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (event: MouseEvent) => {
		const { name, id } = event.target as HTMLInputElement;
		setOpen(false);
		setCurrent(name, id);
	};

	return (
		<div className={`select  select--max-width-120 select--max-width-155 ${open ? 'active' : ''}`}>
			<button
				ref={triggerRef}
				onClick={toggleOpen}
				type="button"
				className="select__current select__current-arrow-custom"
			>
				<span>
					{(current &&
						`${String(current.toUpperCase() || nameCurrent?.toUpperCase())} ${String(
							current !== network ? network.toUpperCase() : '',
						)}`) ||
						nameCurrent?.toUpperCase()}
				</span>
				<span className="select__current-arrow">
					<TriangleArrowDownIcon2 />
				</span>
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select__drop-item">
						<ul>
							<li>
								<button name={nameCurrent} id={nameCurrent} onClick={handleSelect} type="button">
									{nameCurrent?.toUpperCase()}
								</button>
							</li>
							<li>
								<button name="not set" onClick={handleSelect} type="button">
									NOT SET
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
