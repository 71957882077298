import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IGetAvailableCurrenciesParams,
	IAvailableCurrency,
	ICreatePaymentResponse,
	IGetMerchantInvoiceStatusResponse,
	ICreateClientResponse,
} from 'services/api/public/types';

import {
	IPaymentWidgetStore,
	ICreatePaymentPayload,
	IGetMerchantInvoiceStatusPayload,
	IUpdatePaymentPayload,
	ICreateUpdatePaymentPayload,
} from './types';

/* eslint-disable no-param-reassign */

export const initialState: IPaymentWidgetStore = {
	availableCurrencies: null,
	availableCurrenciesLoading: false,
	createPaymentLoading: false,
	payment: null,
	updatePayment: null,
	merchantInvoiceStatus: null,
	merchantInvoice: null,
	paymentNotify: null,
	createUpdatePaymentLoading: false,
};

const paymentWidget = createSlice({
	name: '@@paymentWidget',
	initialState,
	reducers: {
		getAvailableCurrenciesRequest: (state, _: PayloadAction<IGetAvailableCurrenciesParams>) => {
			state.availableCurrenciesLoading = true;
		},
		getAvailableCurrenciesSuccess: (state, { payload }: PayloadAction<IAvailableCurrency[]>) => {
			state.availableCurrencies = payload;
			state.availableCurrenciesLoading = false;
		},
		getAvailableCurrenciesError: (state) => {
			state.availableCurrenciesLoading = false;
		},
		createPaymentRequest: (state, { payload }: PayloadAction<ICreatePaymentPayload>) => {
			state.createPaymentLoading = true;
		},
		createPaymentSuccess: (state, { payload }: PayloadAction<ICreatePaymentResponse>) => {
			state.createPaymentLoading = false;
			state.payment = payload;
		},
		createPaymentError: (state) => {
			state.createPaymentLoading = false;
		},
		createUpdatePaymentRequest: (
			state,
			{ payload }: PayloadAction<ICreateUpdatePaymentPayload>,
		) => {
			state.createUpdatePaymentLoading = true;
		},
		createUpdatePaymentSuccess: (state, { payload }: PayloadAction<ICreatePaymentResponse>) => {
			state.createPaymentLoading = false;
			state.updatePayment = payload;
		},
		createUpdatePaymentError: (state) => {
			state.createPaymentLoading = false;
		},
		getMerchantInvoiceStatusRequest: (
			state,
			{ payload }: PayloadAction<IGetMerchantInvoiceStatusPayload>,
		) => {},
		getMerchantInvoiceStatusStatus: (
			state,
			{ payload }: PayloadAction<IGetMerchantInvoiceStatusResponse>,
		) => {
			const { status } = payload;
			state.merchantInvoiceStatus = status;
			if (payload.extra_invoice_id && state.updatePayment) {
				state.updatePayment.payment.invoice_id = payload.extra_invoice_id;
			}
			if (state.merchantInvoice?.id !== payload.id && state.updatePayment) {
				state.updatePayment.payment.due_date = payload.due_date;
			}
			state.merchantInvoice = payload;
		},

		getUpdatePaymentRequest: (state, { payload }: PayloadAction<IUpdatePaymentPayload>) => {},
		getUpdatePaymentSuccess: (state, { payload }: PayloadAction<ICreateClientResponse>) => {
			state.paymentNotify = payload;
		},
	},
});

export default paymentWidget.reducer;
export const {
	getAvailableCurrenciesRequest,
	getAvailableCurrenciesSuccess,
	getAvailableCurrenciesError,
	createPaymentRequest,
	createPaymentSuccess,
	createPaymentError,
	getMerchantInvoiceStatusRequest,
	getMerchantInvoiceStatusStatus,
	getUpdatePaymentRequest,
	getUpdatePaymentSuccess,
	createUpdatePaymentRequest,
	createUpdatePaymentSuccess,
	createUpdatePaymentError,
} = paymentWidget.actions;
