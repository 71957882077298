import StatusText from 'UI/StatusText/StatusText';
import styles from './styles.module.scss';

interface IProps {
	status?: string;
	id?: number;
}
export default ({ status, id }: IProps) => {
	return (
		<div className={styles.status}>
			<p className={styles.status__title}>{id}</p>
			<div className={styles.status__block}>
				<span className={styles.status__label}>Status:</span>
				{StatusText(status || '')}
			</div>
		</div>
	);
};
