import { PopUpCloseIcon } from 'assets/inline-svg';
import { ChangeEvent, FC } from 'react';
import { useSelector } from 'react-redux';
import { getLoadingSmsSelector } from 'redux/reducers/analyticsMerchant/selectors';
import Loader from 'UI/Loader/Loader';

interface IProps {
	text: string;
	onSubmit: () => void;
	onPopup: () => void;
	handleText: (event: ChangeEvent) => void;
}

export const AddDifferent: FC<IProps> = ({ text, onSubmit, onPopup, handleText }) => {
	const loadingSms = useSelector(getLoadingSmsSelector);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-480">
					<button aria-label="button" onClick={onPopup} type="button" className="popup__close">
						<PopUpCloseIcon />
					</button>
					<div className="popup-header">
						<p className="popup-header__title popup-header__title--ta-l">
							Add Different Statistics
						</p>
					</div>
					<div className="popup-body popup-body--type2">
						<div className="input">
							<p className="input-name">
								Discribe what statistics would you like to keep track of?
							</p>
							<div className="input-wrapper">
								<div className="textarea">
									<textarea value={text} onChange={handleText} placeholder="Enter your text" />
								</div>
							</div>
						</div>
					</div>
					<div className="popup-button popup-button--type2">
						<button
							onClick={onPopup}
							type="button"
							className="button button--full-width button--type2"
						>
							Cancel
						</button>
						<button
							disabled={!text}
							onClick={onSubmit}
							type="button"
							className="button button--full-width"
						>
							{loadingSms ? <Loader small /> : 'Send'}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
