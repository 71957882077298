export const routes = {
	// Auth
	index: '',
	firstLogin: '/first-time-login',
	login: '/login',

	// Auth Merchant
	firstLoginMerchant: '/first-login-merchant',
	loginMerchant: '/login-merchant',

	// components / Index
	transactions: '/transactions',
	transactionDetails: '/transactions/:transactionId/:currencyTabs/:transactionTypes',
	// components / Merchant
	merchantManagement: '/merchants',
	invoices: '/invoices',
	providerInvoiceLink: '/get-provider-invoice',
	merchantInvoiceLink: '/get-merchant-invoice',
	createMerchant: '/merchants/create',
	// components / Market
	userProfile: '/administration/team-members/:userId',
	administrationBase: '/administration',
	administration: {
		teamMembers: '/team-members',
		roles: '/roles',
	},
	reportsAnalyticsBase: '/reports-analytics',
	analytics: '/analytics',
	reports: '/reports',
	// Liqudity components
	liquidityBase: '/liquidity',
	liquidity: {
		binance: '/binance',
		kraken: '/kraken',
		huobi: '/huobi',
	},
	// trxManagementBase
	trxManagementBase: '/trx-management',
	trxManagement: {
		staking: '/staking',
		delegation: '/delegation',
		trxOverview: '/trx-overview',
	},
	// components / components
	walletsBase: '/wallets',
	wallets: {
		balances: '/balances',
		transactionHistory: '/transaction-history',
		transactionHistoryDetails:
			'/transaction-history/:transactionId/:currencyTabs/:transactionTypes',
		paymentTransactions: '/payment-transactions',
		paymentTransactionsDetails: '/payment-transactions/:id',
		profit: '/profit',
	},
	// components / Market
	marketBase: '/market',
	market: {
		currencies: '/currencies',
		feeManagement: '/fee-management',
		limitation: '/limitation',
		freeWithdraw: '/free-withdraw',
	},
	bankAccount: '/accounts',

	// components / Ordrs
	orders: '/orders-provider',
	orderDetails: '/orders-provider/:id',

	// One Project / Reports
	approvementsBase: '/approvements',
	approvements: {
		merchant: '/merchant',
		provider: '/provider',
		detailsMerchant: '/merchant/:id',
		detailsProvider: '/provider/:id',
	},

	// components / Pending Withdrawal
	pendingWithdrawal: '/pending-withdrawal',

	// components / components
	walletAddressBase: '/wallet-addresses',
	walletAddress: {
		profile: '/wallet-addresses/:id',
	},
	// One Merchant
	merchantProjectList: '/merchant-project-list',
	createMerchantProject: '/add-project',
	duplicateMerchantProject: '/duplicate-project',
	// oneMerchantUerProfile: `/merchant/:merchantId/approval-management/user-management/:projectId`,
	oneMerchantBase: '/merchant/:merchantId',
	oneMerchant: {
		// One Merchant / Profile
		profile: '/profile',
		// One Merchant / Settings
		settings: '/settings',
		// One Merchant / Security
		security: '/security',
		// One Merchant / Projects
		merchantProjects: '/projects',
		createProject: '/projects/create',
		// One Merchant / Approval Management
		approvalManagement: '/approval-management',
		approvalManagementTabs: {
			teamMembers: '/user-management',
			teamMembersUserProfile: '/user-management/:merchantId',
			roles: '/roles',
		},
		bankAccount: '/bank-account',
		editBankAccount: '/bank-account/edit/:id',
	},

	projectApprovements: '/project-approvements',
	resetPassword: '/reset-password',
	reset2fa: '/reset-2fa',
	confirmIpAddress: '/confirm-ip',

	// One Project
	oneProjectBase: `/merchant/:merchantId/project/:projectId`,
	oneProject: {
		// One Project / Project Settings
		settingsBase: '/settings',
		settings: {
			details: '/details',
			api: '/api',
			widget: '/widget',
		},
		// One Project / Index
		transactionsBase: '/transactions',
		transactions: {
			transactions: '/transactions',
			approvements: '/approvements',
		},
		approvement: {
			details: '/approvement/details/:transactionId/:currencyTabs/:transactionTypes',
		},
		transaction: {
			details: '/transaction/details/:transactionId/:currencyTabs/:transactionTypes',
		},
		// One Project / Clients
		client: '/client',
		clientEdit: '/client/:id',
		// One Project / Callbacks
		callbacksBase: '/callbacks',
		callbacks: {
			item: '/callbacks/:id',
		},
		// One Project / Finance settings
		financeBase: '/finance-settings',
		finance: {
			currencies: '/currencies',
			limitations: '/limitations',
			autolimit: '/autolimit',
			autoconvert: '/autoconvert',
		},
		// One Project / Clients
		accounts: '/accounts',
		accountsTabs: {
			accounts: '/accounts',
			currencySettings: '/currency-settings',
			crypto: '/crypto',
			fiat: '/fiat',
			currencies: '/currencies',
			limitation: '/limitation',
		},
		// One Project / components-addresses
		walletProjectAddressBase: '/wallet-addresses',
		walletProjectAddress: {
			profile: '/wallet-addresses/:id',
		},
		// One Project / Index
		invoices: '/invoices',
		// One Project / Orders
		orders: '/order-merchant/',
		orderDetails: '/details/:id',

		// One Project / Reports
		reportsBase: '/reports',

		// Comissions
		comissions: '/comissions',
		analytics: '/analytics',
	},
	paymentMethod: '/payment-method',
};
