import { useState } from 'react';
import { EditIcon, CheckIcon, CloseAccountIcon } from 'assets/inline-svg';
import classNames from 'classnames';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { IProps } from '../types';
import styles from './styles.module.scss';

export const TableRow = ({ onSubmit, item, active, setActive, onEditSubmit }: IProps): TdItem[] => {
	const [viewDecimals, setViewDecimals] = useState(item.view_decimals);
	return [
		{
			title: 'Currency',
			children: (
				<span className={classNames(styles.token, styles.tokenType2)}>
					<span className={styles.token__icon}>
						<img src={item?.currency.img_path} alt={item?.currency.name} />
					</span>
					<span className={styles.token__nameWrap}>
						<span className={styles.token__name}>{item?.currency.name}</span> <br />
						<span className={styles.token__transcription}>
							{item?.currency.code === item?.currency_network_name
								? item?.currency.code.toUpperCase()
								: `${item?.currency.code.toUpperCase()}, ${item?.currency_network_name.toUpperCase()}`}
						</span>
					</span>
				</span>
			),
		},
		{
			title: 'Deposit',
			children: (
				<div className={classNames(styles.switch, styles.tdValue)}>
					<label className={styles.switch__label}>
						<input
							onChange={() =>
								onSubmit({
									currency_setting_id: item.id,
									active: !item.is_depositable,
									field: 'is_depositable',
								})
							}
							className={styles.hidden}
							value={item.is_depositable}
							checked={Boolean(item.is_depositable)}
							type="checkbox"
						/>
						<span className={styles.switch__toggler} />
					</label>
				</div>
			),
		},
		{
			title: 'Withdrawal',
			children: (
				<div className={classNames(styles.switch, styles.tdValue)}>
					<label className={styles.switch__label}>
						<input
							onChange={() =>
								onSubmit({
									currency_setting_id: item.id,
									active: !item.is_withdrawable,
									field: 'is_withdrawable',
								})
							}
							className={styles.hidden}
							value={item.is_withdrawable}
							checked={Boolean(item.is_withdrawable)}
							type="checkbox"
						/>
						<span className={styles.switch__toggler} />
					</label>
				</div>
			),
		},
		{
			title: 'Exchange',
			children: (
				<div className={classNames(styles.switch, styles.tdValue)}>
					<label className={styles.switch__label}>
						<input
							onChange={() =>
								onSubmit({
									currency_setting_id: item.id,
									active: !item.is_exchangeable,
									field: 'is_exchangeable',
								})
							}
							className={styles.hidden}
							value={item.is_exchangeable}
							checked={Boolean(item.is_exchangeable)}
							type="checkbox"
						/>
						<span className={styles.switch__toggler} />
					</label>
				</div>
			),
		},
		{
			title: 'View decimals',
			children: active ? (
				<div className={classNames(styles.input, styles.inputMb_0)}>
					<div className={styles.inputWrapper}>
						<input
							className={classNames(
								styles.inputItem,
								styles.inputItemTypeTd,
								styles.inputItemMaxWidth_100px,
							)}
							type="text"
							placeholder="0"
							value={viewDecimals}
							onChange={(e) => setViewDecimals(e.target.value ? +e.target.value : undefined)}
						/>
					</div>
				</div>
			) : (
				<p className={styles.tdValue}>{item.view_decimals}</p>
			),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					{active ? (
						<>
							<button
								className={styles.tdCheck}
								aria-label="button"
								type="button"
								onClick={() =>
									onEditSubmit({ currency_setting_id: item.id, view_decimals: viewDecimals })
								}
							>
								<CheckIcon />
							</button>
							<button aria-label="button" type="button" onClick={() => setActive(null)}>
								<CloseAccountIcon />
							</button>
						</>
					) : (
						<button aria-label="button" type="button" onClick={() => setActive(item.id)}>
							<EditIcon />
						</button>
					)}
				</TableButtons>
			),
		},
	];
};
