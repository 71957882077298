import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { getCountriesRequest } from 'redux/reducers/app/reducer';
import { getCountries } from 'redux/reducers/app/selectors';
import { TextField } from 'UI/Forms/TextField/TextField';
import { PhoneInputField } from 'UI/Forms/PhoneInputField/PhoneInputField';
import { ProjectInfo } from './ProjectInfo/ProjectInfo';
import { ProjectStatusField } from './ProjectStatusField/ProjectStatusField';
import { IProjectDetailsFormProps, IProjectDetailsFormValues } from './types';
import { getValidationSchema, projectStatuses } from './utils';
import { ProjectLogoField } from './ProjectLogoField/ProjectLogoField';
import styles from './styles.module.scss';

export const ProjectDetailsForm: FC<IProjectDetailsFormProps> = ({ project, onSubmit }) => {
	const dispatch = useDispatch();
	const initStatus = projectStatuses.find(({ responseCode }) => responseCode === project.is_active);
	const countries = useSelector(getCountries);
	const initValues: IProjectDetailsFormValues = {
		projectName: project.name,
		status: initStatus || null,
		contactFirstName: project.contact_first_name,
		contactLastName: project.contact_last_name,
		email: project.email,
		phoneCode: project.phone_code,
		phoneNumber: project.phone,
		website: project.website,
		logoFile: null,
	};

	useEffect(() => {
		if (!countries) {
			dispatch(getCountriesRequest());
		}
	}, [countries, dispatch]);

	return (
		<Formik
			initialValues={initValues}
			validationSchema={getValidationSchema(project.file_icon)}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({ dirty, handleReset, isSubmitting }) => (
				<Form>
					<div className={styles.fields}>
						<div className="fields-item">
							<div className="fields-list">
								<ProjectInfo project={project} />

								<TextField
									name="projectName"
									label="Project name"
									placeholder="Enter project name"
								/>

								<ProjectStatusField statuses={projectStatuses} />

								<TextField
									name="contactFirstName"
									label="Contact person first name"
									placeholder="Enter contact person first name"
								/>

								<TextField
									name="contactLastName"
									label="Contact person last name"
									placeholder="Enter contact person last name"
								/>

								<TextField name="email" label="Email" placeholder="Enter email" />

								<PhoneInputField
									name="phoneNumber"
									codeFieldName="phoneCode"
									label="Phone number"
									placeholder="Enter phone number"
									countries={countries || []}
								/>

								<TextField
									name="website"
									label="Website"
									placeholder="Enter URL"
									classNameCustom={styles.mb_0}
								/>
							</div>
						</div>

						<div className={styles.fields__item}>
							<ProjectLogoField initLogoUrl={project.file_icon} />
							<div className={styles.fields__buttons}>
								<button
									type="button"
									className="button button--type2"
									disabled={!dirty || isSubmitting}
									onClick={handleReset}
								>
									Cancel
								</button>
								<button type="submit" className="button" disabled={!dirty || isSubmitting}>
									Save change
								</button>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
