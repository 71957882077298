import { ProjectApprovementsDetailsPage } from 'pages/Project/ProjectApprovementsDetailsPage/ProjectApprovementsDetailsPage';
import { ProjectClientsEditPage } from 'pages/Project/ProjectClientsEdit/ProjectClientsEdit';
import { ProjectTransactionsDetailsPage } from 'pages/Project/ProjectTransactionsDetailsPage/ProjectTransactionsDetailsPage';
import ProjectComissionsPage from 'v2Pages/Project/ProjectComissionsPage';
import ProjectAnalytics from 'v2Pages/Project/ProjectAnalytics';
import ProjectInvoicesPage from 'v2Pages/Project/ProjectInvoicesPage';
import ProjectCallbacksPage from 'v2Pages/Project/ProjectCallbacksPage';
import ProjectClientsPage from 'v2Pages/Project/ProjectClients';
import ProjectSettingsPage from 'v2Pages/Project/ProjectSettingsPage';
import ProjectTransactionsPage from 'v2Pages/Project/ProjectTransactionsPage';
import ProjectWalletAddressesPage from 'v2Pages/Project/ProjectWalletAddressesPage';
import ProjectReportsPage from 'v2Pages/Project/ProjectReportsPage';
import ProjectOrdersPage from 'v2Pages/Project/ProjectOrdersPage';
import { EParams } from 'services/constants/params';
import { ProjectAccounts } from 'v2Pages/Project/ProjectAccounts';
import { ProjectCallbacksInfo } from 'v2Pages/Project/ProjectCallbacksPage/ProjectCallbackInfo/ProjectCallbeckInfo';
import { routes } from './routes';

export const titleList = {
	[routes.oneProject.transaction.details]: 'Project details transactions',
	[routes.oneProject.financeBase]: 'Finance settings',
	[routes.oneProject.clientEdit]: 'Project transactions',
	[routes.oneProject.approvement.details]: 'Project details transactions',
	[routes.oneProject.walletProjectAddressBase]: 'Wallet Addresses',
	[routes.oneProject.transactions.transactions]: 'Project transactions',
	[routes.oneProject.settingsBase]: 'Project settings',
	[routes.oneProject.reportsBase]: 'Project reports',
	[routes.oneProject.orders]: 'Orders',
	[routes.oneProject.invoices + EParams.ID]: 'Index Project invoices',
	[routes.oneProject.comissions]: 'Comissions',
	[routes.oneProject.client]: 'Project clients',
	[routes.oneProject.callbacksBase]: 'Project callbacks',
	[routes.oneProject.analytics]: 'Analytics',
	[routes.oneProject.accounts + EParams.TAB + EParams.SUBTAB]: 'Project wallets',
};

export const routeListProject = [
	// One Project / Project Settings
	{
		exact: false,
		path: routes.oneProject.settingsBase,
		component: ProjectSettingsPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// One Project / Index
	{
		exact: false,
		path: routes.oneProject.transactions.transactions,
		component: ProjectTransactionsPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.oneProject.transaction.details,
		component: ProjectTransactionsDetailsPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.oneProject.approvement.details,
		component: ProjectApprovementsDetailsPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// One Project / ProjectWallets
	{
		exact: false,
		path: routes.oneProject.accounts,
		component: ProjectAccounts,
		role: 'Administrator, Financier',
	},
	// One Project / Client
	{
		exact: true,
		path: routes.oneProject.client,
		component: ProjectClientsPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.oneProject.clientEdit,
		component: ProjectClientsEditPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// One Project / Callbacks
	{
		exact: true,
		path: routes.oneProject.callbacks.item,
		component: ProjectCallbacksInfo,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.oneProject.callbacksBase,
		component: ProjectCallbacksPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// // One Project / Finance settings
	// {
	// 	exact: false,
	// 	path: routes.oneProject.financeBase,
	// 	component: ProjectFinanceSettingsPage,
	// 	role: 'Administrator, Financier',
	// },
	// One Project / Reports
	{
		exact: false,
		path: routes.oneProject.reportsBase,
		component: ProjectReportsPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// One Project / WalletAddresses
	{
		exact: false,
		path: routes.oneProject.walletProjectAddressBase,
		component: ProjectWalletAddressesPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// One Project / Index Project invoices
	{
		exact: false,
		path: `${routes.oneProject.invoices}${EParams.ID}`,
		component: ProjectInvoicesPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// One Project / Orders
	{
		exact: false,
		path: routes.oneProject.orders,
		component: ProjectOrdersPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.oneProject.comissions,
		component: ProjectComissionsPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	// Analytics
	{
		exact: false,
		path: routes.oneProject.analytics,
		component: ProjectAnalytics,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
];
