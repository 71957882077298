import { NoResults } from 'UI/NoResults/NoResults';
import Loading from 'UI/Loading';
import classNames from 'classnames';
import { IMerchantInvoicesItem } from 'services/api/merchant/types';
import {
	Data,
	IApiGetMerchantOwnersItem,
	IDepositCryptoTransactionItem,
	IProviderInvoicesItem,
} from 'services/api/provider/types';
import { IProviderItem } from 'redux/reducers/providerApprovements/types';
import { IOrdersResponse } from 'redux/reducers/providerOrders/types';
import { IWithdravalItem } from 'redux/reducers/projectWithdrawal/types';
import { IGetMerchantApprovalManagementItem } from 'redux/reducers/merchantApprovalManagement/types';
import { IApiGetProjectTransactionsItem } from 'services/api/project/types';
import { IProjectBalanceResponse } from 'redux/reducers/merchantAccounts/types';
import { IProjectClientItem } from 'services/api/projectClients/types';
import { ICurrencyProfit } from 'redux/reducers/providerCurrency/types';
import { ReactNode } from 'react';
import { Td } from './Td';
import styles from './styles.module.scss';
import Tooltip from './Td/Tooltip';

export type TRow =
	| IMerchantInvoicesItem
	| IProviderInvoicesItem
	| Data
	| IProviderItem
	| IApiGetMerchantOwnersItem
	| IWithdravalItem
	| IDepositCryptoTransactionItem
	| IGetMerchantApprovalManagementItem
	| IApiGetProjectTransactionsItem
	| IProjectBalanceResponse
	| IProjectClientItem
	| ICurrencyProfit
	| IOrdersResponse;

interface IProps {
	headerItems: any[];
	bodyItems: TRow[];
	lastWidth?: number;
	gridTemplateColumns?: string;
	getRow: any;
	className?: string;
	loading: boolean;
}

interface TTooltip {
	title: string;
	text: ReactNode;
}

export default ({
	headerItems,
	bodyItems,
	gridTemplateColumns,
	lastWidth,
	getRow,
	className,
	loading,
}: IProps) => {
	const isHaveItems = !loading && !!bodyItems?.length;
	const isShow = (condition?: boolean) => condition === true || condition == null;
	const gridRepeat = `repeat(${headerItems.length - +!!lastWidth}, auto)${
		lastWidth ? ` ${lastWidth}px` : ''
	}`;
	const isRight = (index: number) => !lastWidth && index + 1 === headerItems.length;
	return (
		<div className={classNames(styles.table, className)}>
			<div className={styles.table__content} style={{ gridTemplateColumns: gridRepeat }}>
				{headerItems.map((td: TTooltip | string, index: number) => (
					<Td key={String(td)} isRight={isRight(index)} isHeader>
						{typeof td === 'string' ? td : <Tooltip title={td.title}> {td.text}</Tooltip>}
					</Td>
				))}
				{isHaveItems &&
					bodyItems.map((tr, index) =>
						getRow(tr, index).map(
							(td: any) =>
								isShow(td.condition) && (
									<Td isLast={index + 1 === bodyItems?.length} title={td.title} key={td.title}>
										{td.children}
									</Td>
								),
						),
					)}
			</div>
			{!loading && !bodyItems?.length && <NoResults />}
			{loading && <Loading />}
		</div>
	);
};
