import { IDataFee } from './types';

const inputs = {
	firstInput: {
		label: 'Fee, %',
		placeholder: 'Fee, %',
		name: 'fee_percent',
	},
	secondInput: {
		label: 'Fee, Fixed',
		placeholder: 'Fee, Fixed',
		name: 'fee_plus_usd',
	},
};

export const dataComissions: Array<IDataFee> = [
	{
		id: 1,
		fee_name: 'Cryptocurrency payment:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
	{
		id: 2,
		fee_name: 'Fee crypto to crypto exchange:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
	{
		id: 3,
		fee_name: 'Fee crypto to fiat exchange:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
	{
		id: 4,
		fee_name: 'Fee fiat to crypto exchange:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
	{
		id: 5,
		fee_name: 'Fee transfer crypto between crypto projects:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
	{
		id: 6,
		fee_name: 'Fee transfer fiat between crypto projects:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
	{
		id: 7,
		fee_name: 'Fee deposit:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
	{
		id: 8,
		fee_name: 'Fee deposit with auto exchange crypto to fiat:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
	{
		id: 9,
		fee_name: 'Fee deposit with auto exchange crypto to crypto:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
	{
		id: 10,
		fee_name: 'Fee withdrawal crypto:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
	{
		id: 11,
		fee_name: 'Fee withdrawal fiat:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
	{
		id: 12,
		fee_name: 'Fee spread:',
		description: 'Cryptocurrency input to any of the merchant accounts opened in this project',
		...inputs,
	},
];
