export const reportsType: Array<TReports> = [
	{ id: 0, name: 'Transaction report', code: 'transaction' },
	{ id: 1, name: 'Balance', code: 'balance' },
	{ id: 2, name: 'Currency flow ', code: 'flow ' },
];

export interface TReports {
	id: number;
	name: string;
	code: string;
}
export const reportsTypeSelected: Array<TReportsType> = [
	{ value: 0, label: 'All', code: '' },
	{ value: 1, label: 'Transaction report', code: 'MERCHANT_TRANSACTION_REPORT' },
	{ value: 2, label: 'Balances', code: 'MERCHANT_BALANCES_REPORT' },
	{ value: 3, label: 'Currency flow ', code: 'MERCHANT_CURRENCY_FLOW_REPORT' },
];

export interface TReportsType {
	value: number;
	label: string;
	code: string;
}
