interface Map {
	[key: string]: string;
}

export const roles: Map = {
	transactions: 'Administrator, Operator, Financier, Techsupport',
	merchant: 'Administrator, Operator, Financier, Techsupport',
	invoices: 'Administrator, Financier, Techsupport',
	comissions: 'Administrator, Operator, Financier, Techsupport',
	administration: 'Administrator, Operator, Financier, Techsupport',
	wallets: 'Administrator, Financier',
	bank_account: 'Administrator, Financier',
	market: 'Administrator, Operator, Financier, Techsupport',
	liquidity: 'Administrator, Financier',
	blog: 'Administrator, Operator, Financier, Techsupport',
	reports_analytics: 'Administrator, Financier',
	approvements: 'Administrator, Financier',
	projects_approvements: 'Administrator, Operator, Financier, Techsupport',
	pending_withdr: 'Administrator, Financier',
	tickets: 'Administrator, Operator, Financier, Techsupport',
	payment_pransactions: 'Administrator, Operator, Financier, Techsupport',
	translations: 'Administrator, Operator, Financier, Techsupport',
	orders: 'Administrator, Operator, Financier, Techsupport',
	ordersIn: 'Administrator, Financier',
	wallet_addresses: 'Administrator, Operator, Financier, Techsupport',
};

export const rolesProject = {
	project_settings: 'Administrator, Techsupport',
	transactions: 'Administrator, Operator, Financier, Techsupport',
	accounts: 'Administrator, Financier',
	clients: 'Administrator, Operator, Financier, Techsupport',
	callbacks: 'Administrator, Operator, Financier, Techsupport',
	reports: 'Administrator, Operator, Financier, Techsupport',
	wallet_addresses: 'Administrator, Operator, Financier, Techsupport',
	invoices: 'Administrator, Operator, Financier, Techsupport',
	comissions: 'Administrator, Operator, Financier, Techsupport',
	orders: 'Administrator, Operator, Financier, Techsupport',
	finance_settings: 'Administrator, Financier',
	analytics: 'Administrator, Financier',
};

export const rolesProfileWerchant = {
	profile: 'Administrator',
	settings: 'Administrator, Operator, Financier, Techsupport',
	security: 'Administrator, Operator, Financier, Techsupport',
	approval_management: 'Administrator, Operator, Financier, Techsupport',
	bank_account: 'Administrator, Operator, Financier, Techsupport',
	projects: 'Administrator, Operator, Financier, Techsupport',
};
