/* eslint-disable jsx-a11y/anchor-is-valid */
import { TrashIcon } from 'assets/inline-svg';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteWhiteListIpRecordRequest } from 'redux/reducers/administration/reducer';
import { IDeleteWhiteListIpRecordPayload } from 'redux/reducers/administration/types';
import { DeleteConfirm } from 'UI/Popups/DeleteConfirm/DeleteConfirm';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { toUTC } from 'utils/dates';
import { notify } from 'utils/notify';
import { IWhiteListIpItemProps } from './types';

export const WhiteListIpItem: FC<IWhiteListIpItemProps> = ({ item }) => {
	const dispatch = useDispatch();
	const [isDeleteModal, setIsDeleteModal] = useState(false);

	const closeModal = () => setIsDeleteModal(false);

	const handleDeleteConfirm = () => {
		const payload: IDeleteWhiteListIpRecordPayload = {
			apiParams: { id: item.id },
			onSuccess: () => {
				closeModal();
				notify('IP deleted successfully', 'success');
			},
		};
		dispatch(deleteWhiteListIpRecordRequest(payload));
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Id</p>
				<p className="td-value">
					<a className="link">{item.id}</a>
				</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Last used at</p>
				<p className="td-value">
					{typeof item.last_used_at === 'number' ? toUTC(item.last_used_at * 1000) : '-'}
				</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">IP</p>
				<p className="td-value">{item.ip}</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					<button aria-label="button" type="button" onClick={() => setIsDeleteModal(true)}>
						<TrashIcon />
					</button>
				</div>
			</div>

			<PopupModal open={isDeleteModal} onClose={closeModal}>
				<DeleteConfirm
					title="Delete IP from white list"
					body={`Are you sure you want to delete IP ${item.ip}?`}
					onDelete={handleDeleteConfirm}
					onClose={closeModal}
				/>
			</PopupModal>
		</div>
	);
};
