import { FC } from 'react';
import { useField } from 'formik';
import { rolesProfileWerchant } from 'layouts-elements/ProviderSidebar/roles';
import { ITextFieldProps } from './types';

export const CheckBoxField: FC<ITextFieldProps> = ({
	name,
	onClick,
	userRole,
	valueDefault,
	activTogle,
}) => {
	const [field, meta] = useField(name);
	const isShowError = meta.touched && meta.error;

	return (
		<div className="checkbox checkbox--mb-0 ">
			<label className="switch__label">
				<input
					className="hidden"
					{...field}
					type="checkbox"
					onClick={onClick}
					value={field.value}
					checked={
						rolesProfileWerchant.profile.includes(userRole || '') && activTogle
							? field.value
							: valueDefault
					}
				/>
				<span className="switch__toggler" />
			</label>
			{isShowError && <p className="input-notification input-notification--error">{meta.error}</p>}
		</div>
	);
};
