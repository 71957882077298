import { useMerchantMatchUrl } from 'hooks';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserType } from 'redux/reducers/auth/selectors';
import { routes } from 'routes/routes';
import { constUserTypes } from 'services/constants/user-types';
import { IProjectMenuButtonProps } from './types';

export const ProjectMenuButton: FC<IProjectMenuButtonProps> = ({
	title,
	icon: Icon,
	onClick,
	projectId,
}) => {
	const userType = useSelector(getUserType);
	const merchantUrl = useMerchantMatchUrl();

	return title !== 'Duplicate' ? (
		<button
			onClick={onClick}
			type="button"
			className="dropdown-item__link dropdown-item__link--button"
		>
			<span className="dropdown-item__icon">
				<Icon />
			</span>

			<p className="dropdown-item__name">{title}</p>
		</button>
	) : (
		<Link
			to={
				userType === constUserTypes.MERCHANT
					? `${routes.duplicateMerchantProject}/${String(projectId)}`
					: `${merchantUrl}/projects${routes.duplicateMerchantProject}/${String(projectId)}`
			}
			className="dropdown-item__link dropdown-item__link--button"
		>
			<span className="dropdown-item__icon">
				<Icon />
			</span>

			<p className="dropdown-item__name">{title}</p>
		</Link>
	);
};
