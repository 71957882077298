import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api, showError } from 'services';
import { notify } from 'utils/notify';
import {
	IInvoiceOrderParams,
	IInvoiceOrderRequest,
	IInvoiceOrderResponse,
	IParamsSaga,
} from '../merchantOrders/types';

import {
	createOrderInvoiceProviderRequest,
	createOrderInvoiceProviderSuccess,
	createOrderRequest,
	createOrderSuccess,
	createRefundRequest,
	getOrderDetailsRequest,
	getOrderDetailsSuccess,
	getOrdersRequest,
	getOrdersSuccess,
} from './reducer';
import {
	IComment,
	ICommentReducerRequest,
	IOrderShowRequest,
	IOrderShowResponse,
	IOrdersPagination,
	IOrdersRequest,
} from './types';

function* getOrdersWorker({ payload }: PayloadAction<IOrdersRequest>) {
	try {
		const response: IOrdersPagination = yield call(api.provider.getOrderLists, payload);
		yield put(getOrdersSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* getOrderDetailsWorker({ payload }: PayloadAction<IOrderShowRequest>) {
	try {
		const response: IOrderShowResponse = yield call(api.provider.getOrderDetails, payload);
		yield put(getOrderDetailsSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* createOrderWorker({ payload }: PayloadAction<ICommentReducerRequest>) {
	try {
		const response: IComment = yield call(api.provider.createOrder, payload.body);
		yield put(createOrderSuccess(response));
		yield payload.closePopup();
		yield window.scrollTo(0, document.body.scrollHeight);
	} catch (error) {
		showError(error);
	}
}

function* createInvoiceProviderOrderWorker({ payload }: PayloadAction<IInvoiceOrderParams>) {
	const { params, closePopup } = payload;

	try {
		const response: IInvoiceOrderResponse = yield call(
			api.project.createOrderInvoiceProvider,
			params as IInvoiceOrderRequest,
		);
		yield closePopup();
		yield put(createOrderInvoiceProviderSuccess(response));
	} catch (error) {
		showError(error);
		yield closePopup();
	}
}

function* createRefundWorker({ payload }: PayloadAction<IParamsSaga>) {
	const { apiParams, closePopup, onSuccess } = payload;

	try {
		yield call(api.project.refundProvider, apiParams);
		yield closePopup?.('');
		onSuccess?.();
		yield notify('Refund successfully', 'success');
	} catch (error) {
		showError(error);
	}
}

export function* providerOrdersSaga() {
	yield takeEvery(getOrdersRequest, getOrdersWorker);
	yield takeEvery(getOrderDetailsRequest, getOrderDetailsWorker);
	yield takeEvery(createOrderRequest, createOrderWorker);
	yield takeEvery(createOrderInvoiceProviderRequest, createInvoiceProviderOrderWorker);
	yield takeEvery(createRefundRequest, createRefundWorker);
}
