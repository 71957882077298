import { CloseFill } from 'assets/inline-svg';
import styles from './styles.module.scss';
import { IFilterItem } from './types';

const FilterItem = ({ content, label, identify, onDelete }: IFilterItem): JSX.Element => {
	const deleteItem = () => onDelete(identify);
	return (
		<div className={styles['filter-item']}>
			{content} {label && <span className={styles['filter-item--label']}>{label}</span>}
			<div onClick={deleteItem} className={styles['filter-item--delete']}>
				<CloseFill />
			</div>
		</div>
	);
};

export default FilterItem;
