/* eslint-disable no-nested-ternary */
import { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import {
	forgotPasswordRequest,
	loginMerchantRequest,
	loginRequest,
} from 'redux/reducers/auth/reducer';
import { ILoginPayload } from 'redux/reducers/auth/types';
import { routes } from 'routes/routes';
import { ForgotPass } from 'UI/ForgotPass/ForgotPass';
import { SuccessAuth } from 'UI/SuccessAuth/SuccessAuth';
import { LoginForm } from './LoginForm/LoginForm';
import { TLoginFormOnSubmit } from './LoginForm/types';

export const Login: FC = () => {
	const [isForgotPass, setIsForgotPass] = useState<boolean>(false);
	const [emailForgot, setEmailForgot] = useState<string>('');
	const [goToEmail, setGoToEmail] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const handleSubmit: TLoginFormOnSubmit = (values, { setSubmitting }) => {
		const { email, password, totp } = values;

		const payload: ILoginPayload = {
			apiParams: { email, password, totp, captcha: '00000000' },
			onError: () => setSubmitting(false),
		};

		dispatch(
			routes.login.includes(pathname) ? loginRequest(payload) : loginMerchantRequest(payload),
		);
	};

	const forgotPassword = () => {
		dispatch(
			forgotPasswordRequest({
				apiParams: { email: emailForgot },
				pathname,
				goToEmail: setGoToEmail,
			}),
		);
	};

	const onForgotPass = () => {
		setIsForgotPass(!isForgotPass);
	};

	const handleInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setEmailForgot(value);
	};

	return (
		<div className="authorization-box">
			{isForgotPass ? (
				goToEmail ? (
					<SuccessAuth onClose={setGoToEmail} />
				) : (
					<ForgotPass
						emailForgot={emailForgot}
						forgotPassword={forgotPassword}
						handleInput={handleInput}
					/>
				)
			) : (
				<LoginForm onSubmit={handleSubmit} onForgotPass={onForgotPass} />
			)}
		</div>
	);
};
