import { MouseEvent, useState } from 'react';
import { useSelect } from 'hooks';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DotsIcon } from 'assets/inline-svg';
import { PopUpInvoice } from 'v2Pages/Provider/OrdersPage/Orders/TableRow/PopUpInvoice';
import { PopUpRefun } from 'v2Pages/Provider/OrdersPage/Orders/TableRow/PopUpRefun';
import { getSaveLoading } from 'redux/reducers/merchantInvoices/selectors';
import { createOrderInvoiceMerchantSuccess } from 'redux/reducers/merchantOrders/reducer';
import { orderStatus } from 'services/constants/orderStatus';
import { SaveInvoicePopup } from 'v2Pages/Provider/InvoicesPage/InvoicesTable/SaveInvoicePopup/SaveInvoicePopup';
import { notify } from 'utils/notify';
import { trimLastZeros } from 'utils/strings';
import { toMaxDecimals, isExist, formatAmount } from 'utils/numbers';
import { useViewDecimals } from 'hooks/useViewDecimals';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import DateFormat from 'v2Components/commomComponents/DateFormat';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { roles } from 'layouts-elements/ProviderSidebar/roles';
import { getUserRole } from 'redux/reducers/auth/selectors';
import { MenuButton } from '@mui/base';
import { MenuItem } from '@mui/material';
import styles from './styles.module.scss';
import { IPropsBodyTable } from './types';

const TableRow = ({
	onSaveSubmit,
	itemIdToSave,
	isMerchant,
	onRefetch,
	order,
}: IPropsBodyTable) => {
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState<number>(0);
	const [isOpenModal, setIsOpenModal] = useState<string>('');
	const [orderId, setOrderId] = useState<number | string | undefined>('');
	const [defaultValues, setDefaultValues] = useState<{
		currency_code?: string;
		amount_request?: number;
	}>({});

	const { getViewDecimal } = useViewDecimals();
	const { currency_request, issue, currency_paid } = order;
	const viewDecimalsRequest = currency_request ? getViewDecimal(currency_request) : undefined;
	const viewDecimalsPaid = currency_paid ? getViewDecimal(currency_paid) : undefined;
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const { merchantId, projectId } = useParams<{ merchantId: string; projectId: string }>();
	const saveLoading = useSelector(getSaveLoading);
	const userRole = useSelector(getUserRole);
	const onAction = (
		event: MouseEvent,
		name: string,
		id?: string | number | undefined,
		status?: string,
	) => {
		if (order) {
			setDefaultValues({
				currency_code: currency_paid,
				amount_request: issue || issue === 0 ? Math.abs(issue) : undefined,
			});
		}
		!(name === 'refun' && !orderStatus.includes(status || ''))
			? setIsOpenModal(name)
			: notify('Order status does not allow a refund', 'info');
		setIsOpen(0);
		setOpen(false);
		setOrderId(id);
	};

	const closeSavePopup = () => dispatch(createOrderInvoiceMerchantSuccess(null));

	return [
		{
			title: 'ID',
			children: (
				<Link
					to={`/merchant/${merchantId}/project/${projectId}/order-merchant/details/${order.id}`}
					className="td-value"
				>
					{order.id}
				</Link>
			),
		},
		{
			title: 'Date',
			children: <DateFormat created_at={order.created_at} />,
		},
		{
			title: 'Client ID',
			children: order.client_id,
		},
		{
			title: 'Оrder №',
			children: order.id,
		},
		{
			title: 'Amount',
			children:
				isExist(order.amount_request) &&
				trimLastZeros(order.amount_request?.toFixed(viewDecimalsRequest)),
		},
		{
			title: 'Currency',
			children: order.currency_request?.toUpperCase(),
		},
		{
			title: 'Amount paid',
			children: formatAmount(order.amount_paid, viewDecimalsPaid),
		},
		{
			title: 'Currency paid',
			children: order.currency_paid?.toUpperCase(),
		},
		{
			title: 'Invoice ID',
			children: order.link_exchange_id,
		},
		{
			title: 'Status',
			children: (
				<p className={`td-value status-custom ${order?.status.replaceAll(' ', '-').toLowerCase()}`}>
					{order.status}
				</p>
			),
		},
		{
			title: 'Issue',
			children: toMaxDecimals(order.issue, 8),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<div ref={triggerRef} onClick={toggleOpen}>
						<SaveInvoicePopup
							isOpened={!!itemIdToSave}
							loading={saveLoading}
							onClose={closeSavePopup}
							onSubmit={onSaveSubmit}
							isMerchant={isMerchant}
						/>
						{isOpenModal === 'invoice' && orderId && (
							<PopUpInvoice onAction={onAction} orderId={orderId} defaultValues={defaultValues} />
						)}
						{isOpenModal === 'refun' && orderId && (
							<PopUpRefun
								onAction={onAction}
								orderId={orderId}
								setIsOpenModal={setIsOpenModal}
								order={order}
								onSuccess={onRefetch}
							/>
						)}
						<Dropdown>
							<MenuButton>
								<DotsIcon />
							</MenuButton>
							<Menu className={styles.menu}>
								<MenuItem className={styles.menu__item}>
									<Link
										ref={dropRef}
										to={`/merchant/${merchantId}/project/${projectId}/order-merchant/details/${order.id}`}
									>
										More info
									</Link>
								</MenuItem>
								{roles.ordersIn.includes(userRole || '') && (
									<MenuItem
										onClick={(event: MouseEvent) =>
											onAction(event, 'refun', order.id, order.status)
										}
										className={styles.menu__item}
									>
										Refund
									</MenuItem>
								)}
								<MenuItem
									onClick={(event: MouseEvent) =>
										onAction(event, 'invoice', order.id, order.status)
									}
									className={styles.menu__item}
								>
									Invoice
								</MenuItem>
							</Menu>
						</Dropdown>
					</div>
				</TableButtons>
			),
		},
	];
};

export default TableRow;
