import Loader from '../Loader/Loader';
import styles from './styles.module.scss';

export default () => {
	return (
		<div className={styles.loading}>
			<Loader />
		</div>
	);
};
