import { EditIcon } from 'assets/inline-svg';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { trimLastZeros } from 'utils/strings';
import classNames from 'classnames';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import { TApiGetProviderAllCurrencySettingResponse } from 'redux/reducers/providerCurrency/types';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import style from './styles.module.scss';

export interface IProps {
	item: TApiGetProviderAllCurrencySettingResponse;
	switchOnChangeableMode: () => void;
}

export default ({ item, switchOnChangeableMode }: IProps): TdItem[] => {
	const {
		currency_network,
		withdrawal_max_once,
		withdrawal_max_daily,
		withdrawal_max_monthly,
		deposit_min,
		deposit_max,
		withdraw_min,
		currency,
		withdrawal_amount_free,
	} = item;
	const viewDecimals = 6;
	return [
		{
			title: 'Currency',
			children: (
				<span className={classNames(style.token, style.tokenType2)}>
					<span className={style.token__icon}>
						<img src={currency.img_path} alt={currency.name} />
					</span>
					<span className={style.token__nameWrap}>
						<span className={style.token__name}>{currency.name}</span> <br />
						<span className={style.token__transcription}>
							{currency.code === currency_network
								? currency.code.toUpperCase()
								: `${currency.code.toUpperCase()}, ${currency_network.toUpperCase()}`}
						</span>
					</span>
				</span>
			),
		},
		{
			title: 'Withdrawal, Once',
			children: (
				<p className={style.td__value}>
					{trimLastZeros(withdrawal_max_once.toFixed(viewDecimals))}
				</p>
			),
		},
		{
			title: 'Withdrawal, Daily',
			children: (
				<p className={style.td__value}>
					{trimLastZeros(withdrawal_max_daily.toFixed(viewDecimals))}
				</p>
			),
		},
		{
			title: 'Withdrawal, Monthly',
			children: (
				<p className={style.td__value}>
					{trimLastZeros(withdrawal_max_monthly.toFixed(viewDecimals))}
				</p>
			),
		},
		{
			title: 'Deposit, MIN',
			children: (
				<p className={style.td__value}>{trimLastZeros(deposit_min.toFixed(viewDecimals))}</p>
			),
		},
		{
			title: 'Deposit, MAX',
			children: (
				<p className={style.td__value}>{trimLastZeros(deposit_max.toFixed(viewDecimals))}</p>
			),
		},
		{
			title: 'Withdrawal, MIN',
			children: (
				<p className={style.td__value}>{trimLastZeros(withdraw_min.toFixed(viewDecimals))}</p>
			),
		},
		{
			title: 'Free Withdraw',
			children: (
				<p className={style.td__value}>
					{trimLastZeros(withdrawal_amount_free.toFixed(viewDecimals))}
				</p>
			),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<button
						aria-label="button"
						type="button"
						onClick={() => {
							switchOnChangeableMode();
						}}
					>
						<EditIcon />
					</button>
				</TableButtons>
			),
		},
	];
};
