import { useEffect, useMemo, useRef, useState } from 'react';
import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { ICurrency, IProjectBalanceResponse } from 'redux/reducers/merchantAccounts/types';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
	label: string;
	currencyCode?: string;
	value: any | null;
	setValue: (value: any) => void;
	options?: any[];
}

interface IPropsOption {
	value: ICurrency;
}
const Option = ({ value }: IPropsOption) => {
	return (
		<div className={styles.option}>
			<p className={styles.name}>{value.name}</p>
		</div>
	);
};

const ProjectSelect = ({ label, value, setValue, options, currencyCode }: IProps) => {
	const ref = useRef(null);
	const [open, setOpen] = useState(false);
	const change = (option: IProjectBalanceResponse) => {
		if (option.id !== value.id) {
			setValue(option);
			setOpen(false);
		}
	};

	const currencyBalance = useMemo(() => {
		const balances: any[] = Object.values(value?.balances || {});
		return balances?.find((balance: any) => balance?.currency_network === currencyCode)?.balance;
	}, [currencyCode, value]);

	useEffect(() => {
		document.addEventListener('click', (e) => {
			const isCurrentClick = ref.current && e.composedPath().includes(ref.current);
			!isCurrentClick && setOpen(false);
		});
	}, []);
	return (
		<div className={styles.input}>
			<div className={styles.select} ref={ref}>
				<div className={styles.label}>{label}</div>
				<div className={styles.value} onClick={() => setOpen(!open)}>
					{value ? <Option value={value} /> : <p className={styles.placeholder}>Select currency</p>}
					<TriangleArrowDownIcon2 />
				</div>
				{open && !!options?.length && (
					<div className={styles.list}>
						{options.map((option) => (
							<div
								className={classNames(styles.list__li, { [styles.active]: option.id === value.id })}
								key={option.id}
								onClick={() => change(option)}
							>
								<Option value={option} />
							</div>
						))}
					</div>
				)}
			</div>
			<div className={styles.balance}>
				Balance: <span className={styles.balance__value}>{currencyBalance?.toFixed(6)}</span>
				{currencyBalance && currencyCode?.toUpperCase()}
			</div>
		</div>
	);
};

export default ProjectSelect;
