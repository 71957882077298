import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyIcon, ArrowDownIcon, EditIcon, YesIcon, NoIcon } from 'assets/inline-svg';
import { trimLastZeros } from 'utils/strings';
import { notify } from 'utils/notify';
import { useViewDecimals } from 'hooks/useViewDecimals';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { useState } from 'react';
import {
	ICurrency,
	ILiquidity,
	TLiqudityProviderPayload,
} from 'redux/reducers/providerLiquidity/types';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { useFormik } from 'formik';
import { putProviderLiqudityRequest } from 'redux/reducers/providerLiquidity/reducer';
import { useDispatch } from 'react-redux';
import Deposit from '../TabsContent/PopUp/Deposit/Deposit';

export interface ISubbmit {
	provider_liquidity_id: number;
	amount_min: number;
}

export interface IProps {
	item: ILiquidity;
	currencies?: Array<ICurrency>;
}

const TableRow = ({ item, currencies }: IProps) => {
	const dispatch = useDispatch();
	const [depositPopUp, setDepositPopUp] = useState(false);
	const depositPopUpModal = () => setDepositPopUp(false);
	const [changeableMode, setChangeableMode] = useState<boolean>(false);
	const [amountMin, setAmountMin] = useState(item.amount_min);
	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = item.currency?.code
		? getViewDecimal(item.currency?.code, item.network)
		: undefined;
	const switchOnChangeableMode = () => setChangeableMode(true);
	const switchOffChangeableMode = () => setChangeableMode(false);
	const initialValues = {
		amount_min: '',
		balance: '',
	};
	const handleOpenModalDeposit = () => setDepositPopUp(!depositPopUp);
	const handleAddressCopy = () => notify('Address id copied successfully!', 'info');

	const onSubmit = (body: ISubbmit) => {
		const payload: TLiqudityProviderPayload = {
			apiParams: {
				provider_liquidity_id: body.provider_liquidity_id,
				amount_min: Number(body.amount_min),
			},
			onSuccess: () => {
				setAmountMin(body.amount_min);
				notify('Currency Selected edit success', 'success');
			},
		};
		dispatch(putProviderLiqudityRequest(payload));
	};

	const formik = useFormik({
		initialValues,
		onSubmit: (values, actions) => {
			actions.setSubmitting(false);
			const body: ISubbmit = {
				provider_liquidity_id: item.id,
				amount_min: Number(values.amount_min),
			};
			onSubmit(body);
			switchOffChangeableMode();
		},
	});

	return [
		{
			title: 'Currency',
			children: (
				<span className="token token--type2">
					<span className="token__icon">
						<img src={item.currency.img_path} alt={item.currency.name} />
					</span>
					<span className="token__name">
						{item.currency.name}{' '}
						{`${
							item?.network?.toLowerCase() === item?.currency_code?.toLowerCase()
								? ''
								: `(${String(item?.network?.toUpperCase())})`
						}`}
					</span>
				</span>
			),
		},
		{
			title: 'Address',
			children: (
				<div className="copy-address copy-address--type2">
					<p className="td-value ">{item.deposit_address}</p>
					<span className="tooltip tooltip--width-320 tooltip--top tooltip--center tooltip--arrow-bottom-center">
						<span className="tooltip__text tooltip__text--ta-c">
							<span>{item.deposit_address}</span>
						</span>
					</span>
					{!!item.deposit_address && (
						<CopyToClipboard text={item.deposit_address || ''} onCopy={handleAddressCopy}>
							<button aria-label="button" type="button">
								<CopyIcon />
							</button>
						</CopyToClipboard>
					)}
				</div>
			),
		},
		{
			title: 'Balance',
			children: trimLastZeros(Number(item.balance).toFixed(viewDecimals)),
		},
		{
			title: 'Amount, MIN',
			children: changeableMode ? (
				<div className="input-wrapper">
					<input
						className="input-item input-item--type-td input-item--max-width-100px"
						type="number"
						name="amount_min"
						placeholder="0"
						value={formik.values.amount_min}
						onChange={formik.handleChange}
					/>
				</div>
			) : (
				trimLastZeros(Number(amountMin).toFixed(viewDecimals))
			),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					{changeableMode ? (
						<>
							{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
							<button aria-label="button" type="submit" onClick={formik.submitForm}>
								<YesIcon />
							</button>
							<button aria-label="button" type="button" onClick={switchOffChangeableMode}>
								<NoIcon />
							</button>
						</>
					) : (
						<>
							<button
								onClick={handleOpenModalDeposit}
								type="button"
								className="button button--type7 button--size-td"
							>
								<ArrowDownIcon />
								Deposit
							</button>
							<button
								aria-label="button"
								onClick={() => {
									switchOnChangeableMode();
								}}
								type="button"
							>
								<EditIcon />
							</button>
						</>
					)}
					<PopupModal open={depositPopUp} onClose={depositPopUpModal}>
						<Deposit onClose={depositPopUpModal} item={item} />
					</PopupModal>
				</TableButtons>
			),
		},
	];
};

export default TableRow;
