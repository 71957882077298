import { FC, useState } from 'react';
import { PasswordHideIcon, PasswordShowIcon } from 'assets/inline-svg';
import classNames from 'classnames';
import { useField } from 'formik';

import { IPasswordFieldProps } from './types';

export const PasswordField: FC<IPasswordFieldProps> = ({ name, label, placeholder }) => {
	const [field, meta] = useField(name);
	const [isShowPass, setIsShowPass] = useState(false);

	const isShowError = meta.touched && meta.error;

	return (
		<div className={classNames('input', { 'input--error': isShowError })}>
			<p className="input-name">{label}</p>

			<div className="input-wrapper">
				<input
					{...field}
					className="input-item input-item--right-icon"
					type={isShowPass ? 'text' : 'password'}
					placeholder={placeholder}
				/>
				<button
					aria-label="button"
					type="button"
					className="show-pass"
					onClick={() => setIsShowPass((state) => !state)}
				>
					<PasswordHideIcon className="password-type" />
					<PasswordShowIcon className="text-type" />
				</button>
			</div>

			{isShowError && <p className="input-notification input-notification--error">{meta.error}</p>}
		</div>
	);
};
