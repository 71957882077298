import { FC } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { IDeleteConfirmProps } from './types';

export const DeleteConfirm: FC<IDeleteConfirmProps> = ({ title, body, onClose, onDelete }) => {
	return (
		<div className="popup popup--width-480">
			<button aria-label="button" type="button" className="popup__close" onClick={onClose}>
				<PopUpCloseIcon />
			</button>

			<div className="popup-header">
				<p className="popup-header__title">{title}</p>
			</div>

			<div className="popup-body">
				<p className="popup-text">{body}</p>
			</div>

			<div className="popup-button popup-button--type2">
				<button type="button" className="button button--full-width button--type2" onClick={onClose}>
					Cancel
				</button>
				<button
					type="button"
					className="button button--full-width button--type4"
					onClick={onDelete}
				>
					Delete
				</button>
			</div>
		</div>
	);
};
