import { IlSuccessIcon } from 'assets/inline-svg';
import Button from 'v2Components/UI/Button';
import { FC } from 'react';
import styles from './styles.module.scss';

interface IModalConfirmProps {
	onClick: () => void;
}
const ModalConfirm: FC<IModalConfirmProps> = ({ onClick }) => {
	return (
		<div className={styles.modal}>
			<IlSuccessIcon />
			<h2 className={styles.title}>Successful</h2>
			<p className={styles.description}>Your request has been sent.</p>
			<Button onClick={onClick} className={styles.button}>
				Back to wallets
			</Button>
		</div>
	);
};

export default ModalConfirm;
