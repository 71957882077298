import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IApiGetProjectDetailsParams,
	IEditProjectBalancePayload,
	IProjectBalanceItem,
	IGetDataToDepositFiatResponse,
} from 'services/api/project/types';
import {
	cryptoAutoExchangeRequest,
	cryptoDepositRequest,
	cryptoDepositResponse,
	cryptoExchangeRequest,
	cryptoExchangeResponse,
	cryptoTransferRequest,
	cryptoTransferResponse,
	cryptoWitdrawalRequest,
	cryptoWitdrawalResponse,
	editMerchantProjectBalanceRequest,
	editProjectBalanceRequest,
	editProjectBalanceSuccess,
	getCheckWithdrawlRequest,
	getCheckWithdrawlRespose,
	getCurrenciesRequest,
	getCurrenciesSuccess,
	getExchangeProviderRateFeeRequest,
	getExchangeProviderRateFeeRespose,
	getExchangeRateFeeRequest,
	getExchangeRateFeeRespose,
	getProjectBalanceRequest,
	getProjectBalanceSuccess,
	getProjectMerchantBalanceRequest,
	getWithdrawlFeeRequest,
	getWithdrawlFeeRespose,
	getWithdrawlLimitsRequest,
	getWithdrawlLimitsRespose,
	getProjectToBalanceRequest,
	getProjectMerchantToBalanceRequest,
	updateProjectBalanceRequest,
	updateProjectBalanceSuccess,
	getProjectToBalanceSuccess,
	getProjectBalanceError,
	getDataToDepositFiatRequest,
	getDataToDepositFiatSuccess,
	getDataToDepositFiatError,
} from './reducer';
import {
	ICheckWithdrawlResponse,
	ICheckWithdrawSaga,
	ICryptoExchange,
	ICurrencySettings,
	IDataAutoExchangeRequest,
	IDataTransferRequest,
	IDataWitdrawalRequest,
	IDepositRequest,
	IDepositResponse,
	IExchangeProviderRateFeeRequest,
	IExchangeProviderRateFeeResponse,
	IExchangeRateFeeRequest,
	IExchangeRateFeeResponse,
	IProjectBalanceRequest,
	IProjectBalanceResponse,
	IWithdrawlFeeRequest,
	IWithdrawlFeeResponse,
	IWithdrawlLimitsRequest,
	IWithdrawlLimitsResponse,
	IGetDataToDepositFiatPayload,
	IGetProjectBalancePayload,
} from './types';

import { notify } from '../../../utils/notify';

function* getProjectBalanceWorker({ payload }: PayloadAction<IApiGetProjectDetailsParams>) {
	try {
		const data: Array<IProjectBalanceResponse> = yield call(
			api.project.getProjectBalanceList,
			payload,
		);
		yield put(getProjectBalanceSuccess(data));
	} catch (error) {
		yield put(getProjectBalanceError());
		showError(error);
	}
}

function* updateProjectBalanceWorker({ payload }: PayloadAction<IEditProjectBalancePayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: IProjectBalanceItem = yield call(
			api.project.editProviderProjectBalance,
			apiParams,
		);
		onSuccess?.();
		yield put(updateProjectBalanceSuccess(response));
	} catch (error) {
		yield put(getProjectBalanceError());
		showError(error);
	}
}

function* getProjectToBalanceWorker({ payload }: PayloadAction<IGetProjectBalancePayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: Array<IProjectBalanceResponse> = yield call(
			api.project.getProjectBalanceList,
			apiParams,
		);
		onSuccess?.();
		yield put(getProjectToBalanceSuccess(data));
	} catch (error) {
		yield put(getProjectBalanceError());
		showError(error);
	}
}

function* getProjectMerchantBalanceWorker({ payload }: PayloadAction<IApiGetProjectDetailsParams>) {
	try {
		const data: Array<IProjectBalanceResponse> = yield call(
			api.project.getProjectMerchantBalanceList,
			payload,
		);
		yield put(getProjectBalanceSuccess(data));
	} catch (error) {
		yield put(getProjectBalanceError());
		showError(error);
	}
}

function* getProjectToMerchantBalanceWorker({ payload }: PayloadAction<IGetProjectBalancePayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: Array<IProjectBalanceResponse> = yield call(
			api.project.getProjectMerchantBalanceList,
			apiParams,
		);
		onSuccess?.();
		yield put(getProjectToBalanceSuccess(data));
	} catch (error) {
		yield put(getProjectBalanceError());
		showError(error);
	}
}

function* editProjectBalanceWorker({ payload }: PayloadAction<IProjectBalanceRequest>) {
	try {
		const data: IProjectBalanceResponse = yield call(api.project.editProjectBalance, payload);
		yield put(editProjectBalanceSuccess(data));
		notify('Changes saved successfully', 'success');
	} catch (error) {
		yield put(getProjectBalanceError());
		showError(error);
	}
}

function* editMerchantProjectBalanceWorker({ payload }: PayloadAction<IProjectBalanceRequest>) {
	try {
		const data: IProjectBalanceResponse = yield call(
			api.project.editProjectBalanceMerchant,
			payload,
		);
		yield put(editProjectBalanceSuccess(data));
		notify('Changes saved successfully', 'success');
	} catch (error) {
		yield put(getProjectBalanceError());
		showError(error);
	}
}

function* getCurrenciesWorker() {
	try {
		const data: Array<ICurrencySettings> = yield call(api.project.getCurrencies);
		yield put(getCurrenciesSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* cryptoTransferWorker({ payload }: PayloadAction<IDataTransferRequest>) {
	try {
		const response: IProjectBalanceResponse = yield call(
			api.project.createProjectTransfer,
			payload.data,
		);
		notify('Transfer is successful', 'info');
		yield put(cryptoTransferResponse(response));
		payload.closePopUp('');
	} catch (error) {
		showError(error);
	}
}

function* cryptoExchangeWorker({ payload }: PayloadAction<IDataTransferRequest>) {
	try {
		const response: ICryptoExchange = yield call(api.project.createProjectExchange, payload.data);
		yield put(cryptoExchangeResponse(response));
		notify('Exchange set up successfully', 'success');
		payload.closePopUp('');
	} catch (error) {
		payload.onError?.();
		showError(error);
	}
}

function* cryptoAutoExchangeWorker({ payload }: PayloadAction<IDataAutoExchangeRequest>) {
	try {
		const data: IProjectBalanceResponse = yield call(
			api.project.createProjectAutoExchange,
			payload.data,
		);
		yield put(editProjectBalanceSuccess(data));
		notify('Auto-exchange set up successfully', 'success');
		payload.closePopUp('');
	} catch (error) {
		showError(error);
	}
}

function* cryptoWithdrawalWorker({ payload }: PayloadAction<IDataWitdrawalRequest>) {
	try {
		const response: { balance_after: number; currency: { code: string } } = yield call(
			api.project.createProjectWithdrawalExchange,
			payload.data,
		);
		yield put(
			cryptoWitdrawalResponse({
				balance_after: response.balance_after,
				id: payload.data.project_balance_id,
			}),
		);
		yield notify(
			`Amount (${response.currency.code.toUpperCase()}) Wallet adress was sent successfully`,
			'success',
		);
		payload.closePopUp('');
	} catch (error) {
		showError(error);
	}
}

function* cryptoDepositWorker({ payload }: PayloadAction<IDepositRequest>) {
	try {
		const data: IDepositResponse = yield call(api.project.createProjectDepositExchange, payload);
		yield put(cryptoDepositResponse({ data, id: payload.project_balance_id }));
	} catch (error) {
		showError(error);
	}
}

function* getExchangeRateFeeWorker({ payload }: PayloadAction<IExchangeRateFeeRequest>) {
	try {
		const data: IExchangeRateFeeResponse = yield call(api.project.getExchangeRateFee, payload);
		yield put(getExchangeRateFeeRespose(data));
	} catch (error) {
		showError(error);
	}
}

function* getExchangeProviderRateFeeWorker({
	payload,
}: PayloadAction<IExchangeProviderRateFeeRequest>) {
	try {
		const data: IExchangeProviderRateFeeResponse = yield call(
			api.project.getExchangeProviderRateFee,
			payload,
		);
		yield put(getExchangeProviderRateFeeRespose(data));
	} catch (error) {
		showError(error);
	}
}

function* getWithdrawlLimitsWorker({ payload }: PayloadAction<IWithdrawlLimitsRequest>) {
	try {
		const data: IWithdrawlLimitsResponse = yield call(api.project.getWithdrawalLimits, payload);
		yield put(getWithdrawlLimitsRespose(data));
	} catch (error) {
		showError(error);
	}
}

function* getWithdrawlFeeWorker({ payload }: PayloadAction<IWithdrawlFeeRequest>) {
	try {
		const data: IWithdrawlFeeResponse = yield call(api.project.getWithdrawalFee, payload);
		yield put(getWithdrawlFeeRespose(data));
	} catch (error) {
		showError(error);
	}
}

function* getCheckWithdrawlWorker({ payload }: PayloadAction<ICheckWithdrawSaga>) {
	const { paramsApi, setAddressError } = payload;
	try {
		const data: ICheckWithdrawlResponse = yield call(api.project.getCheckWithdrawal, paramsApi);
		yield put(getCheckWithdrawlRespose(data.is_valid));
		yield setAddressError?.(true);
	} catch (error) {
		showError(error);
	}
}

function* getDataToDepositFiatWorker({ payload }: PayloadAction<IGetDataToDepositFiatPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: IGetDataToDepositFiatResponse = yield call(
			api.project.getDataToDepositFiat,
			apiParams,
		);
		yield put(getDataToDepositFiatSuccess(data));
		onSuccess?.();
	} catch (error) {
		getDataToDepositFiatError();
		showError(error);
	}
}

export function* merchantAccountsSaga() {
	yield takeLatest(getProjectBalanceRequest, getProjectBalanceWorker);
	yield takeEvery(updateProjectBalanceRequest, updateProjectBalanceWorker);
	yield takeLatest(getProjectMerchantBalanceRequest, getProjectMerchantBalanceWorker);
	yield takeLatest(editProjectBalanceRequest, editProjectBalanceWorker);
	yield takeLatest(editMerchantProjectBalanceRequest, editMerchantProjectBalanceWorker);
	yield takeLatest(getCurrenciesRequest, getCurrenciesWorker);
	yield takeLatest(cryptoTransferRequest, cryptoTransferWorker);
	yield takeLatest(cryptoExchangeRequest, cryptoExchangeWorker);
	yield takeLatest(cryptoAutoExchangeRequest, cryptoAutoExchangeWorker);
	yield takeLatest(cryptoWitdrawalRequest, cryptoWithdrawalWorker);
	yield takeLatest(cryptoDepositRequest, cryptoDepositWorker);
	yield takeLatest(getExchangeRateFeeRequest, getExchangeRateFeeWorker);
	yield takeLatest(getExchangeProviderRateFeeRequest, getExchangeProviderRateFeeWorker);
	yield takeLatest(getWithdrawlLimitsRequest, getWithdrawlLimitsWorker);
	yield takeLatest(getWithdrawlFeeRequest, getWithdrawlFeeWorker);
	yield takeLatest(getCheckWithdrawlRequest, getCheckWithdrawlWorker);
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	yield takeLatest(getProjectToBalanceRequest, getProjectToBalanceWorker);
	yield takeLatest(getProjectMerchantToBalanceRequest, getProjectToMerchantBalanceWorker);
	yield takeLatest(getDataToDepositFiatRequest, getDataToDepositFiatWorker);
}
