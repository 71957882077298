import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilter } from 'redux/reducers/app/selectors';
import { routes } from 'routes/routes';
import SwitchCryptoFiatRoute from 'v2Components/layoutsComponents/SwitchCryptoFiatRoute';
import { ReportDownloadButton } from 'v2Components/layoutsComponents/ReportDownloadButton';
import { ReportCreateButton } from 'v2Components/layoutsComponents/ReportCreateButton';
import { IProps } from './types';
import styles from './styles.module.scss';

const WalletsHeader: FC<IProps> = ({
	disabled = false,
	url,
	title,
	downloadReportUrl,
	getCreateReport,
	hideTitle,
}) => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const tabsUrl = url.searchParams.get('tabs') || 'crypto';
	const activeTab = !disabled ? tabsUrl : '';
	const showDownloadButton = [
		routes.wallets.balances,
		routes.wallets.transactionHistory,
		routes.wallets.paymentTransactions,
		routes.oneProject.transactionsBase,
	].find((link) => url.pathname.includes(link));
	const showCreateReportButton = [routes.oneProject.transactionsBase].find((link) =>
		url.pathname.includes(link),
	);

	return (
		<div className={styles.walletsHeader__actions}>
			<SwitchCryptoFiatRoute activeTab={activeTab} />
			<div className={styles.walletsHeader__buttons}>
				{showDownloadButton && <ReportDownloadButton downloadReportUrl={downloadReportUrl} />}
				{showCreateReportButton && <ReportCreateButton getCreateReport={getCreateReport} />}
			</div>
		</div>
	);
};

export default WalletsHeader;
