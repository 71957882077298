import { FC } from 'react';
import T from 'i18n-react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { TextField } from 'UI/Forms/TextField/TextField';
import { IUpdatePaymentPayload } from 'redux/reducers/paymentWidget/types';
import { getUpdatePaymentRequest } from 'redux/reducers/paymentWidget/reducer';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { notify } from 'utils/notify';
import { getCreatedPayment } from 'redux/reducers/paymentWidget/selectors';
import { IFormNotify, IValues } from './types';

const initialValues: IValues = {
	email: '',
	currencyId: '',
};

export const FormNotify: FC<IFormNotify> = ({ payment, onSuccess, viewNotify }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const paymentOrder = useSelector(getCreatedPayment)?.payment || {};

	const widgetKey = searchParams.get('widgetKey');
	const validationSchema = yup.object().shape({
		email: yup.string().required('Email is required').email('Invalid email format'),
	});

	const onSubmit = (value: IValues) => {
		const { email, currencyId } = value;
		if (!widgetKey || !email || !paymentOrder) {
			return;
		}
		const payload: IUpdatePaymentPayload = {
			apiParams: {
				widget_key: widgetKey || '',
				email,
				external_id: String(paymentOrder.client?.external_id) || '',
				phone_code: String(paymentOrder.client?.phone_code) || '',
				phone: String(paymentOrder.client?.phone) || '',
				client_uuid: String(paymentOrder.client?.uuid) || '',
			},
			onSuccess: () => {
				notify('Notify created', 'success');
				onSuccess?.();
			},
		};
		dispatch(getUpdatePaymentRequest(payload));
	};

	return (
		<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			<Form>
				{viewNotify ? (
					<div className="payment-notify">
						<label className="payment-notify_input">
							<span className="payment-notify_label">{T.translate('Notify.title')}</span>
							<TextField
								classNameCustom="text-custom"
								name="email"
								classNameInputItem="payment-notify_point"
								placeholder="Enter Email"
							/>
							<span className="payment-notify_error">{T.translate('Notify.text')}</span>
						</label>
						<button className="payment-btn payment-notify_btn" type="submit">
							{T.translate('Notify.text_01')}
						</button>
					</div>
				) : (
					<span className="payment-thank">{T.translate('Notify.text_02')}</span>
				)}
			</Form>
		</Formik>
	);
};
