import { useDispatch, useSelector } from 'react-redux';
import {
	getMerchantOwnersList,
	getMerchantOwnersListLoading,
	getMerchantOwnersPageCount,
} from 'redux/reducers/providerMerchant/selectors';
import { useEffect, useMemo, useState } from 'react';
import { IGetMerchantOwnersPayload } from 'redux/reducers/providerMerchant/types';
import { getMerchantOwnersRequest } from 'redux/reducers/providerMerchant/reducer';
import { MobileProviderInfo } from 'components/Provider/MobileProviderInfo/MobileProviderInfo';
import { Header } from 'v2Pages/Provider/MerchantManagementPage/Header/Header';
import { Pagination } from 'UI/Pagination/Pagination';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import Table from 'v2Components/commomComponents/Table';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Main from 'v2Components/layoutsComponents/Main';
import { IApiGetMerchantOwnersItem } from 'services/api/provider/types';
import { TableRow } from './MerchantTable/TableRow/TableRow';

export const MerchantManagementPage = () => {
	const dispatch = useDispatch();
	const items = useSelector(getMerchantOwnersList);
	const loading = useSelector(getMerchantOwnersListLoading);
	const pageCount = useSelector(getMerchantOwnersPageCount);
	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState('');
	const [firstLoading, setFirstLoading] = useState(true);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('MerchantManagement');
	const getMerchantsPayload: IGetMerchantOwnersPayload = useMemo(
		() => ({
			apiParams: {
				current_page: currentPage,
				...(search ? { filter: search } : {}),
			},
			onFinally: () => setFirstLoading(false),
		}),
		[currentPage, search],
	);

	const handleSearchDebounce = (newSearch: string) => {
		setSearch(newSearch);
		setCurrentPage(1);
	};
	const handleRefreshMerchants = () => dispatch(getMerchantOwnersRequest(getMerchantsPayload));

	useEffect(() => {
		dispatch(getMerchantOwnersRequest(getMerchantsPayload));
	}, [dispatch, getMerchantsPayload]);
	const isShowPagination = !firstLoading && pageCount > 1 && !loading;
	return (
		<Main
			header={
				<>
					<MobileProviderInfo />
					<Header search={search} onSearchDebounce={handleSearchDebounce} />
				</>
			}
		>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={items || []}
				getRow={(item: IApiGetMerchantOwnersItem) =>
					TableRow({ item, onRefreshMerchants: handleRefreshMerchants })
				}
				gridTemplateColumns={gridTemplateColumns}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination pageCount={pageCount} forcePage={currentPage} onPageChange={setCurrentPage} />
				</PaginationWrapper>
			)}
		</Main>
	);
};
