import { FC, useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAvailableCurrencies, getPaymentStatus } from 'redux/reducers/paymentWidget/selectors';
import { getMerchantInvoiceStatusRequest } from 'redux/reducers/paymentWidget/reducer';
import { IGetMerchantInvoiceStatusPayload } from 'redux/reducers/paymentWidget/types';
import T from 'i18n-react';
import { shortNotation } from 'utils/strings';
import { dateToUTC } from 'utils/dates';
import { notify } from 'utils/notify';
import { copyToClipboard } from 'utils/clipboard';
import { getDateDiffFormatted } from './utils';
import { IMakePayment } from './types';
import { PaymentTime } from '../PaymentTime/PaymentTime';
import { FormNotify } from '../FormNotify/FormNotify';
import { Steps } from '../PaymentWidget';

export const MakePayment: FC<IMakePayment> = ({
	payment,
	onSuccess,
	onExpired,
	durationTime,
	setChangeStatus,
	viewNotify,
	setPaymentStep,
	setNotify,
}) => {
	const dispatch = useDispatch();
	const currencies = useSelector(getAvailableCurrencies);

	const [restTime, setRestTime] = useState<{ mm: string; ss: string }>({
		mm: '15',
		ss: '00',
	});

	const { mm, ss } = restTime;

	const {
		amount,
		currency,
		amount_to_pay,
		currency_paid,
		currency_paid_network,
		address,
		qr_address,
		rate,
		due_date: dueDateStr,
		invoice_id,
	} = payment || {};

	// @TODO: Лучше получать изображение с сервера
	const currencyPaidObj = currencies?.find(
		(item) => item.currency_code === currency_paid?.toLowerCase(),
	);

	const currencyPaidImg = currencyPaidObj?.img_path;

	const statusState = useSelector(getPaymentStatus);

	const intervalRef = useRef<number | null>(null);
	const intervalInvoiceRequestRef = useRef<number | null>(null);
	const dueDate = useMemo(() => new Date(dueDateStr || ''), [dueDateStr]);

	const timerTime = new Date(dueDate).getTime() - dateToUTC(new Date()).getTime();

	const intervalCallback = useCallback(() => {
		const date = dateToUTC(new Date());
		const { mm: _mm, ss: _ss } = getDateDiffFormatted(dueDate, date);
		setRestTime({ mm: _mm, ss: _ss });
	}, [dueDate]);

	const invoiceRequestIntervalCallback = useCallback(() => {
		if (!invoice_id && invoice_id !== 0) {
			return;
		}
		const payload: IGetMerchantInvoiceStatusPayload = {
			apiParams: {
				invoice_id: +invoice_id,
			},
			onSuccess: (resp) => {
				const { status } = resp;
				console.log(status);
				switch (status) {
					case 'in_progress' || 'in_approvement' || 'risk_processing ':
						setChangeStatus?.(Steps.Progress);
						break;
					case 'overpaid':
						setChangeStatus?.(Steps.Overpaid);
						break;
					case 'underpaid':
						setChangeStatus?.(Steps.Underpaid);
						break;
					case 'overdue' || 'overdue_and_paid':
						setChangeStatus?.(Steps.Overdu);
						break;
					case 'paid' || 'blocked':
						onSuccess?.();
						break;

					default:
				}
			},
		};
		dispatch(getMerchantInvoiceStatusRequest(payload));
		/* eslint-disable-next-line */
	}, [dispatch, invoice_id, onSuccess, setChangeStatus]);

	const onLoad = useCallback(() => {
		if (!intervalRef.current) {
			intervalRef.current = window.setInterval(intervalCallback, 1000);
		}
		if (!intervalInvoiceRequestRef.current) {
			intervalInvoiceRequestRef.current = window.setInterval(invoiceRequestIntervalCallback, 10000);
		}
	}, [intervalCallback, invoiceRequestIntervalCallback]);

	const clearIntervals = () => {
		if (intervalRef.current) {
			window.clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
		if (intervalInvoiceRequestRef.current) {
			window.clearInterval(intervalInvoiceRequestRef.current);
			intervalInvoiceRequestRef.current = null;
		}
	};

	const handleCopyAddress = () => {
		address &&
			copyToClipboard(address, () => {
				notify(String(T.translate('Payment.text_copy')), 'success');
			});
	};

	useEffect(() => {
		if (mm === '00' && ss === '00') {
			setPaymentStep?.('1');
			setChangeStatus?.(Steps.Overdu);
		}
		/* eslint-disable-next-line */
	}, [mm, ss]);

	useEffect(() => {
		clearIntervals();
		onLoad();
		return () => {
			clearIntervals();
		};
	}, [onLoad]);

	const calculateProgressBarWidth = (currentTime: number, totalTime: number) => {
		const progressPercentage = ((totalTime - currentTime) / totalTime) * 100;
		return progressPercentage;
	};

	return (
		<>
			<div className="payment-send">
				<div className="payment-code">
					<img src={qr_address} alt="img" />
				</div>
				<div className="payment-content">
					<span className="payment-sub">{T.translate('Payment.send')}</span>
					<span className="payment-us">
						{amount_to_pay} {currency_paid}
					</span>
					<span className="payment-number">
						1 {currency_paid?.toUpperCase()} ~ {rate} {currency?.toUpperCase()}
					</span>
					<div className="payment-addess">
						<span className="payment-addess_label">
							{T.translate('Payment.this')} {currency_paid_network?.toUpperCase()}{' '}
							{T.translate('Payment.address')}:
						</span>
						<span className="payment-addess_wrap">
							<input
								className="payment-addess_input"
								type="text"
								value={address && shortNotation(address, 15, 20)}
								id="myInput"
								readOnly
							/>
							<button
								aria-label="button"
								type="button"
								className="payment-addess_btn"
								onClick={handleCopyAddress}
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M18.9844 21V6.98438H8.01562V21H18.9844ZM18.9844 5.01562C19.5156 5.01562 19.9844 5.21875 20.3906 5.625C20.7969 6 21 6.45313 21 6.98438V21C21 21.5312 20.7969 22 20.3906 22.4062C19.9844 22.8125 19.5156 23.0156 18.9844 23.0156H8.01562C7.48438 23.0156 7.01562 22.8125 6.60938 22.4062C6.20312 22 6 21.5312 6 21V6.98438C6 6.45313 6.20312 6 6.60938 5.625C7.01562 5.21875 7.48438 5.01562 8.01562 5.01562H18.9844ZM15.9844 0.984375V3H3.98438V17.0156H2.01562V3C2.01562 2.46875 2.20312 2 2.57812 1.59375C2.98438 1.1875 3.45312 0.984375 3.98438 0.984375H15.9844Z" />
								</svg>
							</button>
							<span className="payment-addess_tooltip">{T.translate('Payment.copy')}</span>
						</span>
					</div>
					<span className="payment-error">
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M8.65625 9.34375V6.65625H7.34375V9.34375H8.65625ZM8.65625 12V10.6562H7.34375V12H8.65625ZM0.65625 14L8 1.34375L15.3438 14H0.65625Z"
								fill="#FF5A35"
							/>
						</svg>
						{T.translate('Payment.copy_text_01')} {currency_paid?.toUpperCase()} (
						{currency_paid_network?.toUpperCase()} {T.translate('Payment.copy_text_02')})
						{T.translate('Payment.copy_text_03')} {currency_paid_network?.toUpperCase()}{' '}
						{T.translate('Payment.copy_text_04')}
					</span>
				</div>
			</div>
			<PaymentTime
				width={`${calculateProgressBarWidth(Number(timerTime), Number(durationTime))}%`}
				minets={mm}
				seconds={ss}
				message="Awaiting payment"
			/>
			<FormNotify payment={payment} viewNotify={viewNotify} onSuccess={setNotify} />
		</>
	);
};
