/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInvoiceOrderParams, IInvoiceOrderResponse, IParamsSaga } from '../merchantOrders/types';
import {
	IComment,
	ICommentReducerRequest,
	IOrderShowRequest,
	IOrderShowResponse,
	IOrdersPagination,
	IOrdersRequest,
	IProviderOrdersStore,
} from './types';

export const initialState: IProviderOrdersStore = {
	ordersList: null,
	order: null,
	orderInvoice: null,
	ordersListLoading: false,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const providerOrders = createSlice({
	name: '@@providerOrders',
	initialState,
	reducers: {
		getOrdersRequest: (state, _: PayloadAction<IOrdersRequest>) => {
			state.ordersListLoading = true;
		},
		getOrdersSuccess: (state, { payload }: PayloadAction<IOrdersPagination>) => {
			const providerOrdersState = state;
			providerOrdersState.ordersList = payload;
			state.ordersListLoading = false;
		},

		getOrderDetailsRequest: (state, _: PayloadAction<IOrderShowRequest>) => state,
		getOrderDetailsSuccess: (state, { payload }: PayloadAction<IOrderShowResponse>) => {
			const providerOrdersState = state;
			providerOrdersState.order = payload;
		},

		createOrderRequest: (state, _: PayloadAction<ICommentReducerRequest>) => state,
		createOrderSuccess: (state, { payload }: PayloadAction<IComment>) => {
			const providerOrdersState = state;
			providerOrdersState.order?.order_comments.push(payload);
		},

		createOrderInvoiceProviderRequest: (state, _: PayloadAction<IInvoiceOrderParams>) => state,
		createOrderInvoiceProviderSuccess: (
			state,
			{ payload }: PayloadAction<IInvoiceOrderResponse | null>,
		) => {
			const providerOrdersState = state;
			providerOrdersState.orderInvoice = payload;
		},
		createRefundRequest: (state, _: PayloadAction<IParamsSaga>) => state,
	},
});

export default providerOrders.reducer;
export const {
	getOrdersRequest,
	getOrdersSuccess,
	getOrderDetailsRequest,
	getOrderDetailsSuccess,
	createOrderRequest,
	createOrderSuccess,
	createOrderInvoiceProviderRequest,
	createOrderInvoiceProviderSuccess,
	createRefundRequest,
} = providerOrders.actions;
