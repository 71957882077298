import { FC, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getWithdrawal,
	getWithdrawalFiat,
	getWithdrawalLoading,
} from 'redux/reducers/projectWithdrawal/selectors';
import { useLocalStorage } from 'hooks/useLocalStorage';
import {
	getProjectWithdrawalRequest,
	getProjectWithdrawalUpdate,
} from 'redux/reducers/projectWithdrawal/reducer';
import { Pagination } from 'UI/Pagination/Pagination';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import Table from 'v2Components/commomComponents/Table';
import { getFilter } from 'redux/reducers/app/selectors';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import useFilterList from 'hooks/useFilterList';
import { IWithdravalItem } from 'redux/reducers/projectWithdrawal/types';
import { TableRow } from './TableRow/TableRow';
import { ICryptoTable } from './types';
import { FiltersPending } from '../FiltersPending/FiltersPending';
import { getFilterTransactionsHistory } from '../../TransactionsPage/Transactions/filter.helper';
import styles from './styles.module.scss';

export const CryptoTable: FC<ICryptoTable> = ({ currencyTab }) => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const withdrawal = useSelector(getWithdrawal);
	const withdrawalFiat = useSelector(getWithdrawalFiat);
	const loading = useSelector(getWithdrawalLoading);
	const [search, setSearch] = useState('');
	const [showFilter, setShowFilter] = useState(false);
	const [withDr, setWithDr, removewithDr] = useLocalStorage('withdrawal', '');
	const [currencyId, setCurrencyId] = useState<number | string>();
	const [firstLoading, setFirstLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const { data = [], last_page: totalPages = 1 } = withdrawal || {};
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('PendingWithdrawalCrypto');
	const isShowPagination = totalPages > 1 && !loading;
	const filterList = useFilterList(getFilterTransactionsHistory);
	const params = useMemo(
		() => ({
			current_page: currentPage,
			merchant_id: search || undefined,
			currency_id: currencyId,
		}),
		[currencyId, currentPage, search],
	);
	const updateRequest = () =>
		dispatch(getProjectWithdrawalUpdate({ params, onFinally: () => setFirstLoading(false) }));
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const onSubmit = (data: any) => {
		dispatch(setFilter(data));
		setCurrentPage(1);
		setShowFilter(false);
	};
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));

	const onClearFilter = () => dispatch(clearFilter());

	useEffect(() => {
		dispatch(
			getProjectWithdrawalRequest({
				params: {
					...filter,
					current_page: currentPage,
				},
			}),
		);
	}, [dispatch, filter, currentPage]);

	useEffect(() => {
		if (withdrawal?.data.length || withdrawalFiat?.data.length) {
			const newWith: any = withdrawal?.data.length ? withdrawal?.data : withdrawalFiat?.data;
			setWithDr(newWith);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [withdrawal?.data, withdrawalFiat?.data]);
	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);
	return (
		<>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
				className={styles.filters}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<FiltersPending />}
			/>
			<Table
				className={styles.table}
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={data}
				gridTemplateColumns={gridTemplateColumns}
				getRow={(item: IWithdravalItem) => TableRow({ item, updateRequest, currencyTab })}
			/>
			{isShowPagination && (
				<div className="pagination-block">
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</div>
			)}
		</>
	);
};
