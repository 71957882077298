import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { Orders } from 'components/Merchant/Orders/Orders';
import { OrderDetails } from 'components/Merchant/Orders/OrdersDetails/OrdersDetails';

export default () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={path}>
				<Orders />
			</Route>
			<Route exact path={`${path}details/:id`}>
				<OrderDetails />
			</Route>
			<Redirect to={path} />
		</Switch>
	);
};
