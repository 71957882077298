import { FC } from 'react';
import { ITextProps } from './types';

export const CheckBox: FC<ITextProps> = ({ value, name, onClick }) => {
	return (
		<div className="switch">
			<label className="switch__label">
				<input
					className="hidden"
					type="checkbox"
					name={name}
					onChange={onClick}
					checked={Boolean(value)}
					// defaultChecked={Boolean(value)}
				/>
				<span className="switch__toggler" />
			</label>
		</div>
	);
};
