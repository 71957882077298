import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showPaymentTrasactionRequest } from 'redux/reducers/providerPaymentTrasaction/reducer';
import { getShowPaymentTrasactionSelector } from 'redux/reducers/providerPaymentTrasaction/selectors';
import ApprovementStatus from '../../../Project/ProjectTransactions/TabsContent/TransactionDetails/ApprovementStatus';
import ApprovementStatusHistory from '../../../Project/ProjectTransactions/TabsContent/TransactionDetails/ApprovementStatusHistory';
import ApprovementsList from '../../../Project/ProjectTransactions/TabsContent/TransactionDetails/ApprovementsList';

export const PaymentTransactionsDetails: FC = () => {
	const { id } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const transaction = useSelector(getShowPaymentTrasactionSelector);

	useEffect(() => {
		dispatch(
			showPaymentTrasactionRequest({
				provider_payment_transaction_id: id,
			}),
		);
	}, [dispatch, id]);

	const { status } = transaction?.transaction || {};
	return (
		<>
			<div className="mobile-project-info">
				<div className="header-project">
					<div className="header-project__name">
						<p>Provider</p>
					</div>
				</div>
			</div>
			<ApprovementStatus status={status} id={transaction?.transaction.id} />
			{transaction?.transaction && (
				<>
					<ApprovementsList tag="Payment" item={transaction} type="Payment" />
					<ApprovementStatusHistory statusesHistory={transaction.transaction_history} />
				</>
			)}
		</>
	);
};
