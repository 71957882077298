import { PopUpCloseIcon } from 'assets/inline-svg';
import { FC } from 'react';
import { IDeactivateAccountProps } from './types';

export const DeactivateAccount: FC<IDeactivateAccountProps> = ({ onDeactivate, onClose }) => {
	return (
		<div className="popup popup--width-480">
			<button aria-label="button" type="button" className="popup__close" onClick={onClose}>
				<PopUpCloseIcon />
			</button>

			<div className="popup-header">
				<p className="popup-header__title">Deactivate a merchant account</p>
			</div>

			<div className="popup-body">
				<p className="popup-text">Are you sure you want to deactivate the merchant account?</p>
			</div>

			<div className="popup-button popup-button--type2">
				<button type="button" className="button button--full-width button--type2" onClick={onClose}>
					Cancel
				</button>
				<button
					type="button"
					className="button button--full-width button--type4"
					onClick={onDeactivate}
				>
					Deactivate
				</button>
			</div>
		</div>
	);
};
