import { FC } from 'react';
import { Field, Form, Formik } from 'formik';
import ErrorMsg from 'UI/ErrorMsg';
import { PasswordHideIcon, PasswordShowIcon } from 'assets/inline-svg';
import * as yup from 'yup';
import L from 'i18n-react';
import { authPasswordRegexp } from 'utils/formValidation';
import totpValidation from '../../../../validations/totpValidation';
import { IInitialValues, IPropsForm } from '../types';

export const ResetPasswordForm: FC<IPropsForm> = ({
	initialValues,
	createNewPassSubmit,
	handlePassDisplay,
	handleConfirmPassDisplay,
	isShowPass,
	isShowConfirmPass,
}) => {
	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required(String(L.translate('Validation.password_required')))
			.matches(authPasswordRegexp, String(L.translate('Validation.password_not_strong'))),
		confirmPassword: yup
			.string()
			.required(String(L.translate('Validation.confirm_password_required')))
			.oneOf([yup.ref('password')], String(L.translate('Validation.confirm_password_match'))),
		...totpValidation,
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: IInitialValues, { resetForm, setSubmitting }) => {
				createNewPassSubmit({
					password: values.password,
					totp: values.totp,
				});
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, errors }) => {
				return (
					<Form className="authorization-box">
						<div className="form form--authorization">
							<div className="form__header">
								<p className="form-title ">Reset password</p>
							</div>
							<div className="form__body">
								<div className="input">
									<p className="input-name">Create New Password</p>
									<div className="input-wrapper">
										<Field
											className="input-item input-item--right-icon"
											type={isShowPass ? 'text' : 'password'}
											placeholder="Password"
											name="password"
											required
										/>
										<button
											aria-label="button"
											onClick={handlePassDisplay}
											type="button"
											className="show-pass"
										>
											<PasswordHideIcon className="password-type" />
											<PasswordShowIcon className="text-type" />
										</button>
									</div>
									{errors.password ? <ErrorMsg msg={errors.password} /> : null}
								</div>
								<div className="input">
									<p className="input-name">Confirm New Password</p>
									<div className="input-wrapper">
										<Field
											className="input-item input-item--right-icon"
											type={isShowConfirmPass ? 'text' : 'password'}
											placeholder="Confirm password"
											name="confirmPassword"
											required
										/>
										<button
											aria-label="button"
											onClick={handleConfirmPassDisplay}
											type="button"
											className="show-pass"
										>
											<PasswordHideIcon className="password-type" />
											<PasswordShowIcon className="text-type" />
										</button>
									</div>
									{errors.confirmPassword ? <ErrorMsg msg={errors.confirmPassword} /> : null}
								</div>
								<div className="input">
									<p className="input-name">Enter the 2FA 6-digit Code</p>
									<div className="input-wrapper">
										<Field
											className="input-item"
											type="text"
											placeholder="Enter the 2FA 6-digit Code"
											name="totp"
											required
										/>
									</div>
								</div>
							</div>
							<div className="form-footer">
								<button
									type="submit"
									disabled={!(isValid && dirty) || isSubmitting}
									className="button button--full-width"
								>
									Reset Password
								</button>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
