import { FC, useEffect, useState, useCallback, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	downloadMerchantInvoicePDFRequest,
	sendMerchantInvoiceRequest,
} from 'redux/reducers/merchantInvoices/reducer';
import { getInvoiceLinkRequest } from 'redux/reducers/providerInvoices/reducer';
import { ISendInvoicePayload } from 'redux/reducers/providerInvoices/types';
import {
	createOrderInvoiceProviderSuccess,
	getOrdersRequest,
} from 'redux/reducers/providerOrders/reducer';
import {
	getOrderInvoiceIdSelector,
	getOrdersListSelector,
	getOrdersListSelectorLoading,
} from 'redux/reducers/providerOrders/selectors';
import { Pagination } from 'UI/Pagination/Pagination';
import { notify } from 'utils/notify';
import { TVariantValue } from 'v2Pages/Provider/InvoicesPage/types';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import Table from 'v2Components/commomComponents/Table';
import { getFilter } from 'redux/reducers/app/selectors';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { getSaveLoading } from 'redux/reducers/providerInvoices/selectors';
import { orderStatus } from 'services/constants/orderStatus';
import { useSelect } from 'hooks';
import useFilterList from 'hooks/useFilterList';
import { IOrdersResponse } from 'redux/reducers/providerOrders/types';
import { ReportDownloadButton } from 'v2Components/layoutsComponents/ReportDownloadButton';
import { endpoint } from 'services/endpoint';
import { OrdersFilter } from './OrdersFilter/OrdersFilter';
import { getFilterInvoices } from '../../InvoicesPage/InvoicesTable/filter.helper';
import TableRow from './TableRow';
import styles from './styles.module.scss';
import { SaveInvoicePopup } from '../../InvoicesPage/InvoicesTable/SaveInvoicePopup/SaveInvoicePopup';
import { PopUpInvoice } from './TableRow/PopUpInvoice';
import { PopUpRefun } from './TableRow/PopUpRefun';

export const Orders: FC = () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const ordersList = useSelector(getOrdersListSelector);
	const loading = useSelector(getOrdersListSelectorLoading);
	const [currentPage, setCurrentPage] = useState(1);
	const totalPages = ordersList?.last_page || 1;
	const [dateFrom, setDateFrom] = useState<Date | null>(null);
	const [dateTo, setDateTo] = useState<Date | null>(null);
	const [showFilter, setShowFilter] = useState(false);
	const itemIdToSave = useSelector(getOrderInvoiceIdSelector);
	const [isOpen, setIsOpen] = useState<number>(0);
	const [isOpenModal, setIsOpenModal] = useState<string>('');
	const [orderId, setOrderId] = useState<number | string | undefined>('');
	const [defaultValues, setDefaultValues] = useState<{
		currency_code?: string;
		amount_request?: number;
	}>({});
	const saveLoading = useSelector(getSaveLoading);
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const closeSavePopup = () => {
		dispatch(createOrderInvoiceProviderSuccess(null));
	};
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('Orders');
	const handleSaveSubmit = (value: TVariantValue) => {
		if (itemIdToSave == null) {
			return;
		}
		switch (value) {
			case 'DOWNLOAD_IN_PDF': {
				dispatch(downloadMerchantInvoicePDFRequest({ apiParams: { invoice_id: itemIdToSave } }));
				break;
			}
			case 'SEND_PDF_TO_EMAIL': {
				const payload: ISendInvoicePayload = {
					apiParams: {
						type: 'pdf',
						invoice_id: itemIdToSave,
					},
					onSuccess: () => {
						notify('PDF invoice sent successfully', 'success');
					},
				};
				dispatch(sendMerchantInvoiceRequest(payload));
				break;
			}
			case 'GENERATE_LINK':
				dispatch(
					getInvoiceLinkRequest({
						apiParams: { invoice_id: itemIdToSave },
						onSuccess: (link) => {
							notify('Link to invoice generated successfully', 'success');
						},
					}),
				);
				break;
			case 'GENERATE_LINK_AND_SEND_TO_EMAIL': {
				const payload: ISendInvoicePayload = {
					apiParams: {
						type: 'link',
						invoice_id: itemIdToSave,
					},
					onSuccess: () => {
						notify('Link to invoice generated and sent successfully', 'success');
					},
				};
				dispatch(sendMerchantInvoiceRequest(payload));
				break;
			}
			default:
				break;
		}
	};

	const fetchOrders = useCallback(() => {
		dispatch(
			getOrdersRequest({
				current_page: currentPage,
			}),
		);
	}, [currentPage, dispatch]);

	const filterList = useFilterList(getFilterInvoices);
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());
	const onAction = (
		event: MouseEvent,
		name: string,
		id?: string | number | undefined,
		status?: string,
	) => {
		const order = ordersList?.data?.find((orderItem) => orderItem.id === id);
		if (order) {
			const { issue, currency_paid } = order;
			setDefaultValues({
				currency_code: currency_paid,
				amount_request: issue || issue === 0 ? Math.abs(issue) : undefined,
			});
		}

		!(name === 'refun' && !orderStatus.includes(status || ''))
			? setIsOpenModal(name)
			: notify('Order status does not allow a refund', 'info');
		setIsOpen(0);
		setOpen(false);
		setOrderId(id);
	};
	const isShowPagination = totalPages > 1 && !loading;
	const onSubmit = (data: any) => {
		dispatch(setFilter(data));
		setCurrentPage(1);
		setShowFilter(false);
	};
	const downloadReportUrl = endpoint.provider.DOWNLOAD_ORDERS_REPORT;

	useEffect(() => {
		dispatch(
			getOrdersRequest({
				date_from: (filter.date_from as Date) || undefined,
				date_to: (filter.date_to as Date) || undefined,
				status: (filter.status as string)
					?.toLowerCase()
					.replaceAll('_', ' ')
					.includes('All status'.toLowerCase())
					? ''
					: (filter.status as string),
				current_page: currentPage,
			}),
		);
	}, [currentPage, dispatch, filter.date_from, filter.date_to, filter.status]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<>
			<div className="mobile-project-info">
				<div className="header-project">
					<div className="header-project__name">
						<p>Provider</p>
					</div>
				</div>
			</div>
			<div className={styles.header}>
				<ReportDownloadButton downloadReportUrl={downloadReportUrl} />
			</div>
			<div className={styles.main__wrapper}>
				<FilterWrapper
					filterList={filterList}
					deleteItemFilter={deleteItemFilter}
					onClearFilter={onClearFilter}
					onOpenFilter={() => setShowFilter(true)}
					className={styles.filters}
				/>
				<FilterForm
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					onSubmit={onSubmit}
					renderBodyForm={<OrdersFilter dateTo={dateTo} dateFrom={dateFrom} />}
				/>
				<SaveInvoicePopup
					isOpened={!!itemIdToSave}
					loading={saveLoading}
					onClose={closeSavePopup}
					onSubmit={handleSaveSubmit}
				/>
				{isOpenModal === 'invoice' && orderId && (
					<PopUpInvoice onAction={onAction} orderId={orderId} defaultValues={defaultValues} />
				)}
				{isOpenModal === 'refun' && orderId && (
					<PopUpRefun
						onAction={onAction}
						orderId={orderId}
						setIsOpenModal={setIsOpenModal}
						order={ordersList?.data?.filter((order) => order.id === orderId)[0]}
						onSuccess={fetchOrders}
					/>
				)}
				<Table
					loading={loading}
					headerItems={tableHeaderItems}
					gridTemplateColumns={gridTemplateColumns}
					bodyItems={ordersList?.data || []}
					getRow={(order: IOrdersResponse) =>
						TableRow({
							order,
							onSaveSubmit: handleSaveSubmit,
							itemIdToSave,
							onRefetch: fetchOrders,
						})
					}
				/>
				{isShowPagination && (
					<PaginationWrapper>
						<Pagination
							pageCount={totalPages}
							forcePage={currentPage}
							onPageChange={setCurrentPage}
						/>
					</PaginationWrapper>
				)}
			</div>
		</>
	);
};
