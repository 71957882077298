export const statusOptions = [
	{
		value: 'all',
		label: 'All',
	},
	{
		value: 'new',
		label: 'New',
	},
	{
		value: 'paid',
		label: 'Paid',
	},
	{
		value: 'underpaid',
		label: 'Underpaid',
	},
	{
		value: 'overpaid',
		label: 'Overpaid',
	},
	{
		value: 'overdue',
		label: 'Overdue',
	},
	{
		value: 'risk_processing',
		label: 'Risk Processing',
	},
	{
		value: 'in_approvement',
		label: 'In Approvement',
	},
	{
		value: 'blocked',
		label: 'Blocked',
	},
	{
		value: 'in_progress',
		label: 'In Progress',
	},
	{
		value: 'returned',
		label: 'Returned',
	},
	{
		value: 'overdue_and_paid',
		label: 'Overdue & Paid',
	},
];
