import { FC } from 'react';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Floating } from 'v2Components/UI/Floating';
import { CustomInput } from './CustomInput';
import { IDatepicker } from './types';
import styles from './styles.module.scss';

export const Datepicker: FC<IDatepicker> = ({
	value,
	onChange,
	label,
	maxDate,
	minDate,
	portalId,
	noMB,
	width,
}) => {
	return (
		<Floating
			label={label}
			className={classNames(styles['datepicker-input'], {
				[styles['datepicker-input__no-mb']]: noMB,
				[styles['datepicker-input__width-240']]: width === '240',
			})}
		>
			<DatePicker
				selected={value}
				onChange={(date: Date) => {
					onChange(date);
				}}
				peekNextMonth
				showMonthDropdown
				showYearDropdown
				showTimeSelect
				dropdownMode="select"
				dateFormat="dd.MM.yyyy: h:mm aa"
				maxDate={maxDate}
				minDate={minDate}
				customInput={
					<CustomInput
						closeElement={() => {
							setTimeout(() => {
								onChange(null);
							}, 0);
						}}
						noMB={noMB}
					/>
				}
				portalId={portalId}
			/>
		</Floating>
	);
};
