import { capitalize, trimBottomLine } from 'utils/strings';

const StatusText = (text: string) => {
	const textView = capitalize(trimBottomLine(text));
	const viewTextReturn = () => {
		if (text === 'success') {
			return <p className="green">{textView}</p>;
		}
		if (text === 'error' || text === 'unsuccessful' || text === 'rejected') {
			return <p className="red">{textView}</p>;
		}
		if (
			text === 'in_progress' ||
			text === 'pending' ||
			text === 'risk_processing' ||
			text === 'limit_exceeded' ||
			text === 'in_approvement' ||
			text === 'blocked' ||
			text === 'returned'
		) {
			return <p className="gray">{textView}</p>;
		}
		return <p>{textView}</p>;
	};
	return <>{viewTextReturn()}</>;
};

export default StatusText;
