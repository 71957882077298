import { ChangeEvent, FC, useState } from 'react';
import { IComissionsProps } from './types';
import styles from './styles.module.scss';

export const ComissionsProvider: FC<IComissionsProps> = ({
	comissionsRespnse,
	onSubmit,
	comission,
	index,
}) => {
	const [activeInput, setActiveInput] = useState<number | null>(null);
	const [firstInput, setFirstInput] = useState<string>(
		String(comissionsRespnse[index]?.fee_percent),
	);
	const [secondInput, setSecondInput] = useState<string>(
		String(comissionsRespnse[index]?.fee_plus_usd),
	);

	const handleInputs = (event: ChangeEvent) => {
		const { value, name } = event.target as HTMLInputElement;
		name === 'fee_percent' ? setFirstInput(value) : setSecondInput(value);
	};

	const onActive = (id: number | null) => {
		setTimeout(() => {
			setActiveInput(id);
		}, 200);
	};

	const onInpuBlur = (id: null) => {
		if (
			id === null &&
			Number(firstInput) === comissionsRespnse[index]?.fee_percent &&
			Number(secondInput) === comissionsRespnse[index]?.fee_plus_usd
		) {
			setActiveInput(id);
		}
	};

	const handleMouseWheelBehavior = (e: React.BaseSyntheticEvent<WheelEvent, EventTarget>) => {
		e.target.blur();
	};

	return (
		<div className={styles.comission__item}>
			<div className="block-title block-title--fs-18 comission-item__title">
				<p>{comissionsRespnse[index]?.fee_view_name}</p>
			</div>
			{/* <div className="block-text block-text--fs-14 block-text--line-165  comission-item__text">
				<p>{comission.description}</p>
			</div> */}
			<div className="comission-item__input">
				<div className="input">
					<div className="input__name">
						<p>{comission.firstInput.label}</p>
					</div>
					<div className="input-wrapper">
						<input
							onChange={handleInputs}
							onWheel={(e) => {
								handleMouseWheelBehavior(e);
							}}
							onFocus={() => onActive(comission.id)}
							onBlur={() => onInpuBlur(null)}
							className="input-item"
							type="number"
							name={comission.firstInput.name}
							placeholder={comission.firstInput.placeholder}
							value={firstInput}
						/>
					</div>
				</div>
				<div className="input">
					<div className="input__name">
						<p>{comission.secondInput.label}</p>
					</div>
					<div className="input-wrapper">
						<input
							onChange={(e) => {
								handleInputs(e);
							}}
							onWheel={(e) => {
								handleMouseWheelBehavior(e);
							}}
							onFocus={() => onActive(comission.id)}
							onBlur={() => onInpuBlur(null)}
							className="input-item"
							type="number"
							name={comission.secondInput.name}
							placeholder={comission.secondInput.placeholder}
							value={secondInput}
						/>
					</div>
				</div>
				{activeInput === comission.id && (
					<button
						disabled={
							Number(firstInput) === comissionsRespnse[index]?.fee_percent &&
							Number(secondInput) === comissionsRespnse[index]?.fee_plus_usd
						}
						onClick={() => {
							onSubmit(firstInput, secondInput, comissionsRespnse[index].id);
							setActiveInput(null);
						}}
						type="button"
						className="button button--size3"
					>
						Save
					</button>
				)}
			</div>
		</div>
	);
};
