import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import { getProjectList } from 'redux/reducers/merchantProjects/selectors';
import { useLayoutEffect, useState } from 'react';
import { IGetProjectListPayload } from 'redux/reducers/merchantProjects/types';
import { constUserTypes } from 'services/constants/user-types';
import {
	getProjectListMerchantRequest,
	getProjectListProviderRequest,
} from 'redux/reducers/merchantProjects/reducer';
import { getProviderAllCurrencyRequest } from 'redux/reducers/providerCurrency/reducer';
import { PlusIcon, ShipIcon } from 'assets/inline-svg';
import { routes } from 'routes/routes';
import { Link } from 'react-router-dom';
import { useMerchantMatchUrl } from 'hooks';
import MerchantProjectCard from './MerchantProjects/MerchantProjectCard';
import styles from './styles.module.scss';

export default () => {
	const dispatch = useDispatch();
	const oneMerchantUrl = useMerchantMatchUrl();
	const { merchantId } = useParams<{ merchantId: string }>();
	const userType = useSelector(getUserType);
	const projectList = useSelector(getProjectList);
	const [firstLoading, setFirstLoading] = useState(true);

	useLayoutEffect(() => {
		const payload: IGetProjectListPayload = {
			apiParams: { merchant_id: merchantId },
			onFinally: () => setFirstLoading(false),
		};
		dispatch(getProviderAllCurrencyRequest());
		userType === constUserTypes.PROVIDER
			? dispatch(getProjectListProviderRequest(payload))
			: dispatch(getProjectListMerchantRequest(payload));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, merchantId]);

	return (
		<div className={styles.main}>
			{!firstLoading && (
				<div className={styles.projects}>
					{projectList?.map((item) => (
						<MerchantProjectCard key={item.id} item={item} />
					))}
					<Link to={oneMerchantUrl + routes.oneMerchant.createProject} className={styles.append}>
						<div className={styles.append__icon}>
							<PlusIcon />
						</div>
						<p className={styles.append__text}>
							Add New <br /> Project
						</p>
						<div className={styles.append__union}>
							<ShipIcon />
						</div>
					</Link>
				</div>
			)}
		</div>
	);
};
