import { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getReports, getReportsLoading } from 'redux/reducers/providerReportsAnalytics/selectors';
import { getReportsRequest } from 'redux/reducers/providerReportsAnalytics/reducer';
import { Pagination } from 'UI/Pagination/Pagination';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import FilterWrapper from 'v2Components/layoutsComponents/Filter/FilterWrapper';
import { getFilter } from 'redux/reducers/app/selectors';
import { getTransactionsRequest } from 'redux/reducers/providerTransactions/reducer';
import Table from 'v2Components/commomComponents/Table';
import useFilterList from 'hooks/useFilterList';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Filters from './Filters/Filters';
import { dateToString } from './utils';
import TableRow from './ReportsContent/TableRow';
import styles from './styles.module.scss';
import { getFilterReportsHistory } from '../../../ReportsAnalyticsPage/ProviderAnalytics/filter.helper';

export const Reports = () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const reports = useSelector(getReports);
	const loading = useSelector(getReportsLoading);
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const totalPages = reports?.last_page || 1;
	const [currentPage, setCurrentPage] = useState(1);
	const [showFilter, setShowFilter] = useState(false);
	const isShowPagination = totalPages > 1 && !loading;
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('Reports');
	const filterList = useFilterList(getFilterReportsHistory);
	const onSubmit = (data: any) => {
		dispatch(setFilter(data));
		setCurrentPage(1);
		setShowFilter(false);
	};
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());

	useEffect(() => {
		dispatch(
			getReportsRequest({
				params: {
					type: filter.type ? (filter.type as string) : undefined,
					per_page: 15,
					date_from: filter.date_from ? dateToString(filter.date_from as Date) : undefined,
					date_to: filter.date_to ? dateToString(filter.date_to as Date) : undefined,
					current_page: currentPage,
				},
			}),
		);
		dispatch(
			getTransactionsRequest({
				...filter,
				current_page: currentPage,
			}),
		);
	}, [currentPage, dispatch, filter]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);
	return (
		<>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
				className={styles.filters}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<Filters dateTo={endDate} dateFrom={startDate} />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={reports?.data || []}
				gridTemplateColumns={gridTemplateColumns}
				className={styles.table}
				getRow={TableRow}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</PaginationWrapper>
			)}
		</>
	);
};
