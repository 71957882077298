import { FC } from 'react';
import { AuthLayout } from 'layouts/AuthLayout/AuthLayout';
import { ResetPassword } from 'components/Auth/ResetPassword/ResetPassword';

export const ResetPasswordPage: FC = () => {
	return (
		<AuthLayout title="Reset password">
			<ResetPassword />
		</AuthLayout>
	);
};
