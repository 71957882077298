import CopyToClipboard from 'react-copy-to-clipboard';
import classNames from 'classnames';
import Tooltip from 'v2Components/UI/Tooltip';
import { notify } from 'utils/notify';
import { shortNotation } from 'utils/strings';
import { CopyIcon } from 'assets/inline-svg';
import Button from 'v2Components/UI/Button';
import { EWidthTooltip } from '../../../../UI/Tooltip/type';
import styles from './styles.module.scss';

export interface ICopyAddress {
	value?: string | number;
	hideTooltip?: boolean;
	widthTooltip?: EWidthTooltip;
	href?: string;
	size?: number;
}

const CHAR_WIDTH = 19;
export const CopyAddress = ({
	value,
	hideTooltip,
	widthTooltip,
	href,
	size = 6,
}: ICopyAddress): JSX.Element => {
	const handleAddressCopy = () => {
		notify('Address copied successfully!', 'info');
	};

	const renderTooltip = (
		<Tooltip
			value={value || ''}
			shortValue={value ? shortNotation(String(value), size, size) : '-'}
			width={widthTooltip}
		/>
	);
	return (
		<div className={classNames(styles['copy-address'], styles['copy-address__type2'])}>
			<p
				className={classNames('flex flex-m', {
					[styles['copy-address__show-tooltip']]: !hideTooltip,
				})}
				style={{ width: CHAR_WIDTH * size }}
			>
				{href ? (
					<a href={href} className="link" target="_blank" rel="noreferrer">
						{renderTooltip}
					</a>
				) : (
					renderTooltip
				)}
			</p>
			{value && (
				<CopyToClipboard text={String(value)} onCopy={handleAddressCopy}>
					<Button type="button" simple="small">
						<CopyIcon />
					</Button>
				</CopyToClipboard>
			)}
		</div>
	);
};
