import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import { routes } from 'routes/routes';
import { constUserTypes } from 'services/constants/user-types';
import { UserProfile } from 'components/Merchant/UserProfile/UserProfile';
import { Tabs } from 'v2Components/commomComponents/Tabs/Tabs';
import Main from 'v2Components/layoutsComponents/Main';
import Header from '../../Provider/MarketPage/components/Header';
import TeamMembers from './TabsContent/TeamMembers/TeamMembers';
import { Roles } from '../../Provider/AdministrationPage/Roles/Roles';

export default () => {
	const { path } = useRouteMatch();
	const url_string = window.location.href;
	const urlObg = new URL(url_string);
	const matchPath = `/${urlObg.pathname.split('/')[urlObg.pathname.split('/').length - 1]}`;
	const userType = useSelector(getUserType);
	const isHaveRules =
		matchPath === routes.oneMerchant.approvalManagementTabs.roles ||
		matchPath === routes.oneMerchant.approvalManagementTabs.teamMembers;
	const listContent = [
		{ link: `user-management`, value: 'Team members' },
		{ link: `roles`, value: 'Roles' },
	];
	return (
		<Main header={isHaveRules ? userType === constUserTypes.PROVIDER && <Header /> : null}>
			{/* <CurrencyButtons />
			<Tabs /> */}
			{isHaveRules
				? userType === constUserTypes.PROVIDER && <Tabs listContent={listContent} />
				: null}
			<Switch>
				<Route
					exact
					path={`${path}${routes.oneMerchant.approvalManagementTabs.teamMembers}`}
					component={TeamMembers}
				/>
				<Route
					exact
					path={`${path}${routes.oneMerchant.approvalManagementTabs.roles}`}
					component={() => Roles({ isMerchant: true })}
				/>
				<Route
					exact
					path={`${path}${routes.oneMerchant.approvalManagementTabs.teamMembersUserProfile}`}
					component={UserProfile}
				/>
				<Redirect to={`${path}${routes.oneMerchant.approvalManagementTabs.teamMembers}`} />
			</Switch>
		</Main>
	);
};
