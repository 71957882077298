import axios, { AxiosError } from 'axios';
import L from 'i18n-react';
import { notify } from 'utils/notify';
import { capitalize } from 'utils/strings';

const NEW_ERROR_V = 2;
const INTERNAL_SERVER_ERROR = 500;
const TOO_MANY_REQUESTS = 429;

function getResponseErrorMsg(error: AxiosError) {
	if (!error.response?.data) {
		return null;
	}
	const { message, errors } = error.response.data;

	if (message && typeof message === 'string') {
		return message;
	}
	if (Array.isArray(errors)) {
		const firstError = errors.flat()[0];

		if (firstError && typeof firstError === 'string') {
			return firstError;
		}
	}
	return null;
}

function formatMsgToHuman(message: string) {
	return capitalize(message).split('_').join(' ');
}

export const showError = (error: any) => {
	if (!axios.isAxiosError(error)) {
		return;
	}

	const errorInfo = error?.response?.data ?? {};
	if (errorInfo._v === NEW_ERROR_V) {
		errorInfo.messages?.forEach((message: string) => {
			notify(message, 'error');
		});
		return;
	}

	let humanMsg = '';
	const responseMsg = getResponseErrorMsg(error);

	if (responseMsg) {
		const translatedMsg = String(L.translate(`Errors.${responseMsg}`, { notFound: '' }));
		humanMsg = translatedMsg || formatMsgToHuman(responseMsg);
	} else {
		humanMsg = String(L.translate('Errors.something_went_wrong'));
	}

	switch (error.response?.status) {
		case TOO_MANY_REQUESTS: // Too Many Requests
			notify(String(L.translate(`Errors.too_many_requests`)), 'error');
			break;
		case INTERNAL_SERVER_ERROR: // Internal Server Error
			notify(String(L.translate(`Errors.internal_server_error`)), 'error');
			break;
		default:
			notify(humanMsg, 'error');
	}
};
