import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { confirIpAddressRequest } from 'redux/reducers/auth/reducer';
import { YesIcon, SpinnerIcon, NoIcon } from 'assets/inline-svg';
import inbox from '../../../assets/styles/images/inbox.png';

export const ConfirmIpAddress: FC = () => {
	const [message, setMessage] = useState<string>('');
	const [isError, setIsError] = useState(false);
	const dispatch = useDispatch();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');

	useEffect(() => {
		setMessage('');
		setIsError(false);
		dispatch(
			confirIpAddressRequest({
				apiParams: {
					token: token || '',
				},
				setMessage,
				isError: setIsError,
			}),
		);
	}, [dispatch, token]);

	return (
		<main className="main">
			<section className="authorization-section">
				<div className="authorization-section__figure" />
				<button type="button" className="support-btn">
					<span className="support-btn__icon">
						<svg
							width="15"
							height="16"
							viewBox="0 0 15 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.75 1.25C10.1478 1.25 10.5294 1.40804 10.8107 1.68934C11.092 1.97064 11.25 2.35217 11.25 2.75L11.25 11.75L14.25 14.75L2.25 14.75C1.85218 14.75 1.47064 14.592 1.18934 14.3107C0.908035 14.0294 0.75 13.6478 0.75 13.25L0.749999 2.75C0.749999 2.35218 0.908035 1.97064 1.18934 1.68934C1.47064 1.40804 1.85217 1.25 2.25 1.25L9.75 1.25Z"
								stroke="white"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</span>
					Support
				</button>
				<div className="authorization-box">
					<div className="form form--authorization">
						<div className="form__header">
							<div className="form-img">
								<img src={inbox} alt="letter-icon" />
							</div>
							<p className="form-title form-title--center">IP address confirmation</p>
							<div className="form-text form-text--center">
								{message ? (
									<div className="flex flex-m flex-c">
										{isError ? <NoIcon /> : <YesIcon />}
										<p>{message}</p>
									</div>
								) : (
									<div className="flex flex-m flex-c">
										<p>Confirmation in progress</p>
										<div className="load">
											<SpinnerIcon />
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};
