import { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { routes } from 'routes/routes';
import { IPropsPopUps } from '../../TableRowCrypto/types';
import { PopUpWithdrawalCrypto } from './PopUpWithdrawalCrypto/PopUpWithdrawalCrypto';
import { PopUpWithdrawalFiat } from './PopUpWithdrawalFiat/PopUpWithdrawalFiat';

export const PopUpWithdrawal: FC<IPropsPopUps> = ({
	onPopUps,
	popUpOpen,
	currencies,
	accountId,
	projectList,
	projectChose,
}) => {
	const url_string = window.location.href;
	const url = new URL(url_string);
	const activeTab = url.searchParams.get('tabs') || 'crypto';

	const filteredCurrencies = currencies?.filter((item) => !!item.is_withdrawable);

	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				{activeTab === 'crypto' && (
					<PopUpWithdrawalCrypto
						onPopUps={onPopUps}
						currencies={filteredCurrencies}
						popUpOpen={popUpOpen}
						accountId={accountId}
					/>
				)}
				{activeTab === 'fiat' && (
					<PopUpWithdrawalFiat
						onPopUps={onPopUps}
						popUpOpen={popUpOpen}
						currencies={filteredCurrencies}
						accountId={accountId}
						projectList={projectList}
						projectChose={projectChose}
					/>
				)}
			</div>
		</div>
	);
};
