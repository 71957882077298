import { ButtonHTMLAttributes, LegacyRef } from 'react';
import classNames from 'classnames';
import { Badge, FilterIcon } from 'assets/inline-svg';
import styles from './styles.module.scss';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	size?: 'regular' | 'big' | 'small';
	width?: string;
	active?: boolean;
	link?: boolean;
	action?: boolean;
	tableAction?: boolean;
	clear?: boolean;
	close?: boolean;
	light?: boolean;
	icon?: boolean;
	noBorder?: boolean;
	pagination?: boolean;
	filter?: boolean;
	filterBadge?: boolean;
	simple?: 'small' | 'regular';
	switchStatus?: 'selected' | 'unselected';
}

const Button = ({
	className,
	active,
	action,
	tableAction,
	clear,
	light,
	link,
	children,
	icon,
	noBorder,
	size,
	width,
	pagination,
	type = 'button',
	filter,
	filterBadge,
	switchStatus,
	simple,
	close,
	...restProps
}: IButton): JSX.Element => {
	return (
		<button
			className={classNames(styles.button, className, {
				[styles['button__type-primary']]:
					!light && !action && !icon && !simple && !tableAction && !link,
				[styles['button__type-primary-light']]: light,
				[styles['button__type-primary-link']]: link,
				[styles.button__size2]: size === 'regular' || filter,
				[styles.button__size4]: size === 'small',
				[styles.button__icon]: icon,
				[styles['button__type-no-border']]: icon || noBorder || filter,
				[styles['button__type-action']]: action,
				[styles['button__type-table-action']]: tableAction,
				[styles['button__type-pagination']]: pagination,
				[styles['button__type-clear']]: clear,
				[styles['button__type-close']]: close,
				[styles['button__switch-status']]: switchStatus,
				[styles['button__switch-status--selected']]: switchStatus === 'selected',
				[styles['button__switch-status--unselected']]: switchStatus === 'unselected',
				[styles.active]: active,
				[styles['button__type--simple-small']]: simple === 'small',
				[styles['button__type--simple-regular']]: simple === 'regular',
			})}
			style={{ width }}
			// eslint-disable-next-line react/button-has-type
			type={type}
			{...restProps}
		>
			{children}
			{filter && (
				<span className={styles['button__filter-icon']}>
					<FilterIcon className={styles['filter-icon']} />
					{filterBadge && <Badge className={styles['filter-badge']} />}
				</span>
			)}
		</button>
	);
};
export default Button;
