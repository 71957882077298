import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IGetProjectClientParams,
	TGetProjectClientsResponse,
	IActivateClientParams,
	IGetShowClientParams,
	IGetShowClientResponse,
	IEditClientParams,
	IClient,
	TGetClientDocumentsResponse,
	IDeleteClientDocumentParams,
	IClientTransactionsParams,
	TClientTransactions,
} from 'services/api/projectClients/types';
import {
	IAddClientDocumentPayload,
	IDeleteClientPayload,
	IGetClientDocumentsPayload,
} from './types';
import {
	getClientsError,
	getClientsRequest,
	getClientsSuccess,
	showClientRequest,
	showClientSuccess,
	showClientError,
	editClientRequest,
	editClientSuccess,
	editClientError,
	getClientTransactionsRequest,
	getClientTransactionsSuccess,
	getClientTransactionsError,
	activateClientRequest,
	activateClientSuccess,
	activateClientError,
	deleteClientRequest,
	deleteClientSuccess,
	deleteClientError,
	getClientDocumentsSuccess,
	getClientDocumentsError,
	deleteClientDocumentSuccess,
	getClientDocumentsRequest,
	addClientDocumentRequest,
	deleteClientDocumentRequest,
} from './reducer';

function* getClientsWorker({ payload }: PayloadAction<IGetProjectClientParams>) {
	try {
		const response: TGetProjectClientsResponse = yield call(api.projectClients.getClients, payload);
		yield put(getClientsSuccess(response));
	} catch (error) {
		yield put(getClientsError());
		showError(error);
	}
}

function* showClientRequestWorker({ payload }: PayloadAction<IGetShowClientParams>) {
	try {
		const response: IGetShowClientResponse = yield call(api.projectClients.showClients, payload);
		yield put(showClientSuccess(response));
	} catch (error) {
		yield put(showClientError());
		showError(error);
	}
}

function* editClientRequestWorker(action: PayloadAction<IEditClientParams>) {
	const {
		payload: { apiParams, onSuccess },
	} = action;
	try {
		const response: IClient = yield call(api.projectClients.editClient, apiParams);
		yield put(editClientSuccess(response));
		onSuccess?.();
	} catch (error) {
		yield put(editClientError());
		showError(error);
	}
}

function* getClientTransactionsRequestWorker({
	payload,
}: PayloadAction<IClientTransactionsParams>) {
	try {
		const response: TClientTransactions = yield call(
			api.projectClients.getClientTransactions,
			payload,
		);
		yield put(getClientTransactionsSuccess(response));
	} catch (error) {
		yield put(getClientTransactionsError());
		showError(error);
	}
}

function* activateClientRequestWorker({ payload }: PayloadAction<IActivateClientParams>) {
	try {
		yield call(api.projectClients.activateClient, payload);
		yield put(activateClientSuccess(payload));
	} catch (error) {
		yield put(activateClientError());
		showError(error);
	}
}

function* deleteClientRequestWorker({ payload }: PayloadAction<IDeleteClientPayload>) {
	const { onSuccess } = payload;
	try {
		yield call(api.projectClients.deleteClient, payload);
		yield put(deleteClientSuccess(payload));
		onSuccess();
	} catch (error) {
		yield put(deleteClientError());
		showError(error);
	}
}

function* getClientDocumentsWorker(action: PayloadAction<IGetClientDocumentsPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		const data: TGetClientDocumentsResponse = yield call(
			api.projectClients.getClientDocuments,
			apiParams,
		);
		yield put(getClientDocumentsSuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(getClientDocumentsError());
		showError(error);
	}
}

function* addClientDocumentWorker(action: PayloadAction<IAddClientDocumentPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		yield call(api.projectClients.addClientDocument, apiParams);
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteClientDocumentWorker({ payload }: PayloadAction<IDeleteClientDocumentParams>) {
	try {
		yield call(api.projectClients.deleteClientDocument, payload);
		yield put(deleteClientDocumentSuccess(payload));
	} catch (error) {
		showError(error);
	}
}

export function* projectClientsSaga() {
	yield takeLatest(getClientsRequest, getClientsWorker);
	yield takeLatest(showClientRequest, showClientRequestWorker);
	yield takeLatest(editClientRequest, editClientRequestWorker);
	yield takeLatest(getClientTransactionsRequest, getClientTransactionsRequestWorker);
	yield takeLatest(activateClientRequest, activateClientRequestWorker);
	yield takeLatest(deleteClientRequest, deleteClientRequestWorker);
	yield takeLeading(getClientDocumentsRequest, getClientDocumentsWorker);
	yield takeLeading(addClientDocumentRequest, addClientDocumentWorker);
	yield takeLeading(deleteClientDocumentRequest, deleteClientDocumentWorker);
}
