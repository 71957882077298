import { FC } from 'react';
import ApprovementItem from '../ApprovementItem';
import styles from './styles.module.scss';
import GetApprovementList from './approvement-list';

interface IProps {
	item: any;
	type: string;
	isProject?: boolean;
	tag: string;
	auto?: boolean;
}

const ApprovementsList: FC<IProps> = ({ tag, item, type, isProject, auto }) => {
	const approvements = GetApprovementList({ tag, item, type, isProject, auto });
	return (
		<div className={styles.approvements__items}>
			{approvements.map((approvement, index) => (
				<ApprovementItem
					label={approvement.label}
					isLast={index + 2 >= approvements.length}
					key={approvement.label}
				>
					{approvement.children}
				</ApprovementItem>
			))}
		</div>
	);
};

export default ApprovementsList;
