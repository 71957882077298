import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProjectBalanceList,
	getProjectBalanceLoading,
} from 'redux/reducers/merchantAccounts/selectors';
import { getUserType } from 'redux/reducers/auth/selectors';
import {
	IProjectBalanceRequest,
	IProjectBalanceResponse,
} from 'redux/reducers/merchantAccounts/types';
import { constUserTypes } from 'services/constants/user-types';
import {
	editMerchantProjectBalanceRequest,
	editProjectBalanceRequest,
	getProjectBalanceRequest,
	getProjectMerchantBalanceRequest,
	updateProjectBalanceRequest,
} from 'redux/reducers/merchantAccounts/reducer';
import { IEditProjectBalancePayload } from 'services/api/project/types';
import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Table from 'v2Components/commomComponents/Table';
import TableRow from 'components/Project/ProjectFinanceSettings/Limitations/TableRow/TableRow';

interface IProps {
	search: string;
}
export default ({ search }: IProps) => {
	const { url: matchUrl } = useRouteMatch();
	const { projectId } = useParams<{
		projectId: string;
	}>();
	const accountList = useSelector(getProjectBalanceList);
	const loading = useSelector(getProjectBalanceLoading);
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);

	const isProvider = userType === constUserTypes.PROVIDER;
	const typeHeader = isProvider ? 'AccountsCurrencySettings' : 'AccountsCurrencySettingsMerchant';
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription(typeHeader);
	const handleSubmit = (value: IProjectBalanceRequest, id: number, autoId: number | null) => {
		const data: IProjectBalanceRequest = {
			project_balance_id: id,
			auto_exchange_to_currency_setting_id: autoId,
			...value,
		};
		const query =
			userType === constUserTypes.PROVIDER
				? editProjectBalanceRequest
				: editMerchantProjectBalanceRequest;
		dispatch(query(data));
	};

	const updateBalance = useCallback(
		(params: IEditProjectBalancePayload) => {
			dispatch(updateProjectBalanceRequest(params));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch],
	);

	const [valuesInput, setValuesInput] = useState<IProjectBalanceRequest>({
		withdrawal_max_once: null,
		withdrawal_max_daily: null,
		withdrawal_max_monthly: null,
		withdraw_min: null,
	});

	const handleInputs = (e: ChangeEvent<HTMLInputElement>, autoId: number | null) => {
		const { value, name, type, id } = e.target;
		type === 'checkbox' && handleSubmit({ is_active: +name ? 0 : 1 }, +id, autoId);
		setValuesInput({
			...valuesInput,
			[name]: value,
		});
	};

	useEffect(() => {
		const query =
			userType === constUserTypes.PROVIDER
				? getProjectBalanceRequest
				: getProjectMerchantBalanceRequest;
		dispatch(query({ project_id: projectId, search_value: search || undefined }));
	}, [dispatch, search, matchUrl, userType, projectId]);

	return (
		<ProjectContent>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={accountList || []}
				getRow={(item: IProjectBalanceResponse) =>
					TableRow({
						updateBalance,
						item,
						handleInputs,
					})
				}
				gridTemplateColumns={gridTemplateColumns}
			/>
		</ProjectContent>
	);
};
