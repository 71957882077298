import { FC, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProviderTransactionRequest } from 'redux/reducers/providerCurrency/reducer';
import {
	getProviderTransactionLoading,
	getProviderTransactions,
} from 'redux/reducers/providerCurrency/selectors';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { getFilter } from 'redux/reducers/app/selectors';
import { Pagination, PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import { IFilter } from 'redux/reducers/app/types';
import { routes } from 'routes/routes';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { cryptoTableRow } from 'v2Pages/Provider/WalletsPage/components/TabsContent/TransactionsHistory/CryptoTable/CryptoTableRow';
import useFilterList from 'hooks/useFilterList';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Table from 'v2Components/commomComponents/Table';
import { IApiProviderTransactionItem } from 'services/api/provider/types';
import { IPropsTabs } from '../types';
import TransactionsHistoryFormFilter from '../TransactionsHistoryFormFilter';
import { convertRequestParams, getFilterTransactionsHistory } from '../../filter.helper';

const TransactionsHistoryCrypto: FC<IPropsTabs> = ({ currency }) => {
	const dispatch = useDispatch();
	const { getViewDecimal } = useViewDecimals();
	const item = useSelector(getProviderTransactions);
	const filter = useSelector(getFilter);
	const loading = useSelector(getProviderTransactionLoading);
	const { last_page: totalPages = 1 } = item || {};
	const [showFilter, setShowFilter] = useState(false);
	const isCrypto = useMemo(() => currency === 'crypto', [currency]);
	const filterList = useFilterList(getFilterTransactionsHistory);
	const isShowPagination = isCrypto && totalPages > 1 && !loading;
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription(
		'TransactionsHistoryCrypto',
	);
	const onClearFilter = () => dispatch(clearFilter());
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const setCurrentPage = (value: number) => dispatch(setFilter({ ...filter, current_page: value }));
	const onOpenFilter = () => setShowFilter(true);
	const onSubmit = (data: IFilter) => {
		const requestParams = convertRequestParams(
			data,
			`${routes.wallets.transactionHistory}/${String(currency)}`,
		);
		dispatch(
			setFilter({
				...requestParams,
				current_page: 1,
			}),
		);
		setShowFilter(false);
	};
	useEffect(() => {
		isCrypto &&
			dispatch(
				getProviderTransactionRequest({
					apiParams: filter,
				}),
			);
	}, [dispatch, isCrypto, filter]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);
	return (
		<>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={onOpenFilter}
			/>
			<TransactionsHistoryFormFilter
				setShowFilter={setShowFilter}
				showFilter={showFilter}
				currencyTab={String(currency)}
				onSubmit={onSubmit}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={item?.data || []}
				getRow={(historyCryptoItem: IApiProviderTransactionItem) =>
					cryptoTableRow({
						historyCryptoItem,
						getViewDecimal,
					})
				}
				gridTemplateColumns={gridTemplateColumns}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={filter.current_page as number}
						onPageChange={setCurrentPage}
					/>
				</PaginationWrapper>
			)}
		</>
	);
};

export default TransactionsHistoryCrypto;
