import { trimLastZeros } from 'utils/strings';
import { Token } from 'v2Components/UI/Token';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { IProfitTableRow } from './type';

export const TableRow = ({ profit, getViewDecimal }: IProfitTableRow) => {
	const {
		currency_code,
		currency_name,
		deposit,
		exchange,
		auto_exchange,
		icon_path,
		internal_transfer,
		invoice,
		payment,
		total,
		withdraw,
		auto_withdrawal,
	} = profit;

	const viewDecimals = getViewDecimal(currency_code);

	return [
		{
			title: 'Currency',
			children: (
				<Token
					name={currency_name}
					code={currency_code.toUpperCase()}
					img={icon_path}
					type="second"
				/>
			),
		},
		{
			title: 'Payment',
			children: trimLastZeros(Number(payment).toFixed(viewDecimals)),
		},
		{
			title: 'Deposit',
			children: trimLastZeros(Number(deposit).toFixed(viewDecimals)),
		},
		{
			title: 'Withdraw',
			children: trimLastZeros(Number(withdraw).toFixed(viewDecimals)),
		},
		{
			title: 'Exchange',
			children: trimLastZeros(Number(exchange).toFixed(viewDecimals)),
		},
		{
			title: 'Auto - exchange',
			children: trimLastZeros(Number(auto_exchange).toFixed(viewDecimals)),
		},
		{
			title: 'Invoice',
			children: trimLastZeros(Number(invoice).toFixed(viewDecimals)),
		},
		{
			title: 'Auto - withdrawal',
			children: trimLastZeros(Number(auto_withdrawal).toFixed(viewDecimals)),
		},
		{
			title: 'Internal transfer',
			children: trimLastZeros(Number(internal_transfer).toFixed(viewDecimals)),
		},
		{
			title: 'Total Amount',
			children: (
				<TableButtons right>{trimLastZeros(Number(total).toFixed(viewDecimals))} </TableButtons>
			),
		},
	];
};
