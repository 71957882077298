import { useState } from 'react';
import { useSelector } from 'react-redux';
import { notify } from 'utils/notify';
import CopyToClipboard from 'react-copy-to-clipboard';
import { shortNotation } from 'utils/strings';
import { CopyIcon, DeleteIcon, EditIcon, TheeDotsHorizontalIcon } from 'assets/inline-svg';
import { getProviderCurrencyAll } from 'redux/reducers/providerCurrency/selectors';
import classNames from 'classnames';
import { ICryptoWalletItemProps } from '../../types';
import styles from './styles.module.scss';

export default ({ item, openEditModal, openDeleteModal }: ICryptoWalletItemProps) => {
	const currencyAll = useSelector(getProviderCurrencyAll);
	const currency = currencyAll.find(({ id }) => id === item.currency_id);
	const [openMenu, setOpenMenu] = useState(false);

	const handleIbanCopy = () => notify('Address copied successfully!', 'info');
	const openEdit = () => {
		openEditModal(item);
		setOpenMenu(false);
	};
	const openDelete = () => {
		openDeleteModal(item);
		setOpenMenu(false);
	};
	const wallet = item.reserve_wallet || item.deposit_wallet;
	return (
		<div className={styles.account}>
			<div className={styles.account__edit}>
				<div className={styles.account__editButton} onClick={() => setOpenMenu(!openMenu)}>
					<TheeDotsHorizontalIcon />
				</div>
				{openMenu && (
					<div className={styles.account__menu}>
						<div className={styles.account__menuItem} onClick={openEdit}>
							<EditIcon /> <span>Edit</span>
						</div>
						<div
							className={classNames(styles.account__menuItem, styles.account__menuItem_delete)}
							onClick={openDelete}
						>
							<DeleteIcon /> <span>Delete</span>
						</div>
					</div>
				)}
			</div>
			<div className={styles.account__icon}>
				{currency && <img src={currency?.img_path} alt={currency?.currency_network} />}
			</div>
			<p className={styles.account__name}>{item.reserve_wallet_name}</p>
			<div className={styles.account__currency}>
				<div>
					<p className={styles.account__label}>Currency</p>
					<p className={styles.account__value}>{item.currency_name}</p>
				</div>
				<div>
					<p className={styles.account__label}>Network</p>
					<p className={styles.account__value}>{currency?.currency_network?.toUpperCase()}</p>
				</div>
			</div>
			<div>
				<p className={styles.account__label}>Address</p>
				<CopyToClipboard text={String(wallet)} onCopy={handleIbanCopy}>
					<div className={styles.account__value}>
						<p className={styles.copy__value}>
							{wallet && shortNotation(wallet, 12, 8)?.toUpperCase()}
						</p>
						<button aria-label="button" type="button" className={styles.copy}>
							<CopyIcon />
						</button>
					</div>
				</CopyToClipboard>
			</div>
		</div>
	);
};
