import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiGetProjectDetailsParams,
	IEditProjectBalancePayload,
	IProjectBalanceItem,
	TApiGetProjectBalanceResponse,
} from '../../../services/api/project/types';

import { IProjectBalanceStore } from './types';

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */

export const initialState: IProjectBalanceStore = {
	projectBalance: null,
	projectBalanceLoading: false,
};

const projectBalance = createSlice({
	name: '@@projectBalance',
	initialState,
	reducers: {
		getProjectBalanceRequest: (state, _: PayloadAction<IApiGetProjectDetailsParams>) => {
			state.projectBalanceLoading = true;
		},
		getProjectBalanceMerchantRequest: (state, _: PayloadAction<IApiGetProjectDetailsParams>) => {
			state.projectBalanceLoading = true;
		},
		getProjectBalanceSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetProjectBalanceResponse>,
		) => {
			state.projectBalance = payload;
			state.projectBalanceLoading = false;
		},
		updateProjectBalanceRequest: (state, action: PayloadAction<IEditProjectBalancePayload>) => {},
		updateMerchantProjectBalanceRequest: (
			state,
			action: PayloadAction<IEditProjectBalancePayload>,
		) => {},
		updateProjectBalanceSuccess: (state, { payload }: PayloadAction<IProjectBalanceItem>) => {
			if (!state.projectBalance) return;
			state.projectBalance = state.projectBalance?.map((el) =>
				el.id === payload.id ? { ...el, ...payload } : el,
			);
		},
	},
});

export default projectBalance.reducer;
export const {
	getProjectBalanceRequest,
	getProjectBalanceMerchantRequest,
	getProjectBalanceSuccess,
	updateProjectBalanceRequest,
	updateMerchantProjectBalanceRequest,
	updateProjectBalanceSuccess,
} = projectBalance.actions;
