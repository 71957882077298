import Field from 'v2Components/UI/Field';
import { SearchIcon } from 'assets/inline-svg';
import FilterWrapper from 'v2Components/layoutsComponents/Filter/FilterWrapper';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import useFilterList from 'hooks/useFilterList';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getFilter } from 'redux/reducers/app/selectors';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import Table from 'v2Components/commomComponents/Table';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import { Pagination } from 'UI/Pagination/Pagination';
import {
	getTRXDelegation,
	getTRXDelegationLoading,
} from 'redux/reducers/providerTRXManagement/selectors';
import { getTRXDelegationRequest } from 'redux/reducers/providerTRXManagement/reducer';
import { IDelegationItem } from 'redux/reducers/providerTRXManagement/types';
import { IFilter } from 'redux/reducers/app/types';
import {
	convertRequestParams,
	getFilterTransactionsHistory,
} from '../../TransactionsPage/Transactions/filter.helper';
import { TableRow } from './TableRow';
import styles from './styles.module.scss';

const Delegation = () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const delegation = useSelector(getTRXDelegation);
	const loading = useSelector(getTRXDelegationLoading);
	const [showFilter, setShowFilter] = useState(false);
	const filterList = useFilterList(getFilterTransactionsHistory);
	const [search, setSearch] = useState('');
	const [page, setPage] = useState(0);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('TRXManagementDelegation');
	const { data = [], last_page: totalPages = 1, per_page: perPage } = delegation || {};

	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());
	const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
	const isShowPagination = totalPages > 1 && !loading;
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const onSubmit = (data: IFilter) => {
		dispatch(setFilter(convertRequestParams(data, 'crypto')));
		setPage(1);
		setShowFilter(false);
	};
	useEffect(() => {
		dispatch(getTRXDelegationRequest({}));
	}, [dispatch, filter, page, search]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<>
			<div className={styles.filters}>
				<Field
					value={search}
					onChange={onChangeSearch}
					placeholder="Search (Tx ID, Tx Hash)"
					renderRightIcon={<SearchIcon />}
					classNameCustom={styles.search}
					classNameInputItem={styles.search__input}
					widthInput={340}
					label="Search (Tx ID, Tx Hash)"
					isDebounce
				/>
				<FilterWrapper
					filterList={filterList}
					deleteItemFilter={deleteItemFilter}
					onClearFilter={onClearFilter}
					onOpenFilter={() => setShowFilter(true)}
					className={styles.filters__button}
				/>
			</div>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<div />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={data}
				getRow={(item: IDelegationItem) => TableRow({ item })}
				gridTemplateColumns={gridTemplateColumns}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={Number(totalPages)}
						onPageChange={setPage}
						forcePage={Number(page)}
					/>
				</PaginationWrapper>
			)}
		</>
	);
};

export default Delegation;
