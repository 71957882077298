import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { api, showError } from 'services';
import { constUserTypes } from 'services/constants/user-types';
import { ISettingsProviderSaga } from 'services/api/merchant/types';
import {
	getMerchantSettingsRequest,
	getMerchantSettingsSuccess,
	getMerchantSettingsLanguagesRequest,
	postMerchantSettingsSuccess,
} from './reducer';
import { ICreateSettingsPayload, ISettingsResponse } from './types';

function* getMerchantSettingsWorker({ payload }: PayloadAction<ISettingsProviderSaga>) {
	const { userType, params } = payload;

	try {
		const data: ISettingsResponse = yield userType === constUserTypes.MERCHANT
			? call(api.merchant.getSettings)
			: call(api.merchant.getSettingsProvider, params);
		yield put(getMerchantSettingsSuccess(data));
	} catch (error) {
		// yield put(getProjectListError());
		showError(error);
	}
}

function* getMerchantSettingsLanguagesWorker() {
	try {
		yield call(api.merchant.getSettingsLanguage);
		// yield put(getMerchantSettingsLanguagesSuccess(apiParams));
	} catch (error) {
		showError(error);
	}
}

function* postMerchantSettingsWorker({ payload }: PayloadAction<ICreateSettingsPayload>) {
	const { apiPayload, onSuccess, onError, userType } = payload;
	try {
		if (userType === constUserTypes.MERCHANT) {
			yield call(api.merchant.postSettings, apiPayload);
		} else {
			yield call(api.merchant.postSettingsProvider, apiPayload);
		}
		// yield put(getMerchantSettingsLanguagesSuccess(apiPayload));
		onSuccess?.();
	} catch (error) {
		showError(error);
		onError?.();
	}
}

export function* merchantSettingsSaga() {
	yield takeLatest(getMerchantSettingsRequest, getMerchantSettingsWorker);
	yield takeLatest(postMerchantSettingsSuccess, postMerchantSettingsWorker);
	yield takeLeading(getMerchantSettingsLanguagesRequest, getMerchantSettingsLanguagesWorker);
}
