import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import { trimLastZeros } from 'utils/strings';
import { CopyAddress } from 'v2Components/commomComponents/Table/CellComponents';
import Button from 'v2Components/UI/Button';
import LinkDetails from 'v2Components/commomComponents/Table/Td/LinkDetails';
import { IPaymentTransactionsTableRow } from './type';

export const TableRow = ({
	paymentTransactionsItem,
	getViewDecimal,
	setInvoiceLink,
}: IPaymentTransactionsTableRow) => {
	const {
		id,
		currency,
		currency_network,
		amount,
		invoice_provider_id,
		merchant_id,
		created_at,
		hash_transaction,
		tx_url,
	} = paymentTransactionsItem;

	const viewDecimals = getViewDecimal(currency.code, currency_network);

	return [
		{
			title: 'TX ID',
			children: <Link to={`/wallets/payment-transactions/${String(id)}`}>{id}</Link>,
		},
		{
			title: 'Invoice ID',
			children: (
				<Button type="button" onClick={() => setInvoiceLink(Number(invoice_provider_id))} link>
					{invoice_provider_id}
				</Button>
			),
		},
		{
			title: 'Merchant ID',
			children: (
				<Link
					to={`/merchant/${merchant_id}`}
					className={`link ${merchant_id ? '' : 'unactive--color'}`}
				>
					{merchant_id || '---'}
				</Link>
			),
		},
		{
			title: 'Date&Time',
			children: created_at && `${moment(created_at).format('DD.MM.YYYY, HH:mm')} UTC`,
		},
		{
			title: 'Type',
			className: 'status-custom',
		},
		{
			title: 'Currency',
			children: `${String(currency?.code?.toUpperCase())}(${String(
				currency_network?.toUpperCase(),
			)})`,
		},
		{
			title: 'Amount',
			children: trimLastZeros(Number(amount).toFixed(viewDecimals)),
		},
		{
			title: 'TX Hash',
			children: <CopyAddress value={hash_transaction} widthTooltip="auto" href={tx_url} />,
		},
		{
			title: 'Details',
			children: <LinkDetails detailsUrl={`/wallets/payment-transactions/${id}`} />,
		},
	];
};
