import { NavLink } from 'react-router-dom';
import Button from 'v2Components/UI/Button';
import styles from './styles.module.scss';

interface ISwitchRoute {
	activeTab: string;
}

const SwitchCryptoFiatRoute = ({ activeTab }: ISwitchRoute): JSX.Element => {
	return (
		<div className={styles.tabs}>
			<NavLink to="?tabs=crypto">
				<Button switchStatus={!activeTab || activeTab === 'crypto' ? 'selected' : 'unselected'}>
					Crypto
				</Button>
			</NavLink>
			<NavLink to="?tabs=fiat">
				<Button
					switchStatus={activeTab === 'fiat' ? 'selected' : 'unselected'}
					disabled={!activeTab}
				>
					Fiat
				</Button>
			</NavLink>
		</div>
	);
};

export default SwitchCryptoFiatRoute;
