import { FC } from 'react';
import { TriangleArrowDownIcon2, CheckIcon } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { TApiGetProviderAllCurrencySettingResponse } from 'redux/reducers/providerCurrency/types';
import classNames from 'classnames';
import { IProps } from './types';
import style from './styles.module.scss';

export const TypeSelect: FC<IProps> = ({ onChange, item, options }) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (newItem: TApiGetProviderAllCurrencySettingResponse) => {
		const body = {
			id: newItem.id,
			img: newItem.img_path,
			currency_id: newItem.currency_id,
			currency_network: newItem.currency_network,
			currency_name: newItem.currency.name,
			code: newItem.currency.code,
			name: newItem.currency.name,
			hot_balance: newItem.hot_balance,
			provider_balance: newItem.provider_balance,
		};

		onChange(body);
		setOpen(false);
	};
	return (
		<div className={classNames(style.select, { [style.select_active]: open })}>
			<button type="button" className={style.select__current} ref={triggerRef} onClick={toggleOpen}>
				{/* <div className="selectInput"> */}
				<span className={style.token}>
					<span className={style.token__icon}>
						<img src={item.img} alt="coin" />
					</span>
					<span className={style.token__name}>{item.currency_name}</span>
					<span className={style.token__transcription}>
						{item.code.toUpperCase()}{' '}
						{item.code.toLowerCase() === item.currency_network.toLowerCase()
							? ''
							: `(${item.currency_network.toUpperCase()})`}
					</span>
				</span>
				{/* </div> */}
				<span className={style.select__currentArrow}>
					<TriangleArrowDownIcon2 />
				</span>
			</button>
			<div className={style.select__drop} ref={dropRef}>
				<div className={style.select__dropScroll}>
					<div>
						<ul>
							{options.map((e) => (
								<li key={e.id}>
									<button
										type="button"
										className={classNames(style.select__button, {
											[style.select__buttonActive]: item.id === e.id,
										})}
										onClick={() => handleSelect(e)}
									>
										<div className={style.token}>
											<div className={style.token__icon}>
												<img src={e.img_path} alt="coin" />
											</div>
											<span className={style.token__name}>{e.currency?.name}</span>
											<span className={style.token__transcription}>
												{e.currency?.code?.toUpperCase()}{' '}
												{e.currency?.code?.toLowerCase() === e.currency_network.toLowerCase()
													? ''
													: `(${e.currency_network.toUpperCase()})`}
											</span>
										</div>
										<span>{item.id === e.id && <CheckIcon />}</span>
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
