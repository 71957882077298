import { FC, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import {
	getProviderInvoicesRequest,
	downloadInvoicePDFRequest,
	sendInvoiceRequest,
	createInvoiceRequest,
	getInvoiceLinkRequest,
	downloadCSVRequest,
} from 'redux/reducers/providerInvoices/reducer';
import {
	getProviderInvoices,
	getProviderInvoicesLoading,
	getSaveLoading,
} from 'redux/reducers/providerInvoices/selectors';
import {
	ICreateInvoicePayload,
	IGetProviderInvoicesPayload,
	ISendInvoicePayload,
} from 'redux/reducers/providerInvoices/types';
import { TProviderInvoiceStatus } from 'services/api/provider/types';
import { notify } from 'utils/notify';
import { dateToString } from 'utils/dates';
import { InvoicesTable } from 'v2Pages/Provider/InvoicesPage/InvoicesTable/InvoicesTable';
import { IValues } from 'v2Pages/Provider/InvoicesPage/InvoicesTable/CreateInvoicePopup/CreateInvoiceForm/CreateInvoiceForm';
import { getFilter } from 'redux/reducers/app/selectors';
import { endpoint } from 'services/endpoint';
import { clearFilter } from 'redux/reducers/app/reducer';

import { TVariantValue } from './types';

export const InvoicesPage: FC = () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const saveLoading = useSelector(getSaveLoading);
	const invoices = useSelector(getProviderInvoices);
	const loading = useSelector(getProviderInvoicesLoading);
	const orderId = useParams<{ id: string }>();
	const [currentPage, setCurrentPage] = useState(1);
	const [status, setStatus] = useState<string | null>(null);
	const [order, setOrder] = useState<string | null>(orderId.id || null);
	const [currency, setCurrency] = useState<string | null>(null);
	const [paidCurrency, setPaidCurrency] = useState<string | null>(null);
	const [dateFrom, setDateFrom] = useState<Date | null>(null);
	const [dateTo, setDateTo] = useState<Date | null>(null);
	const [itemIdToSave, setItemIdToSave] = useState<number | null>(null);

	const fetchInvoices = useCallback(() => {
		const payload: IGetProviderInvoicesPayload = {
			apiParams: {
				current_page: currentPage,
				status: (status as TProviderInvoiceStatus) || undefined,
				currency_request: currency || undefined,
				currency_paid: paidCurrency || undefined,
				order_id: order || undefined,
				date_from: dateFrom ? dateToString(dateFrom) : undefined,
				date_to: dateTo ? dateToString(dateTo) : undefined,
			},
		};
		dispatch(getProviderInvoicesRequest(payload));
	}, [currency, currentPage, dateFrom, dateTo, dispatch, order, status, paidCurrency]);

	const handleCreateSubmit = (values: IValues, onFinally?: () => void) => {
		const {
			currencyRequest,
			amountRequest,
			currencyPaid,
			currencyPaidNetwork,
			rate,
			expiresTimeMinutes,
			clientEmail,
		} = values;
		if (
			!currencyRequest ||
			!amountRequest ||
			!currencyPaid ||
			!currencyPaidNetwork ||
			!expiresTimeMinutes ||
			!clientEmail
		) {
			notify('Not all fields are filled in', 'error');
			return;
		}
		const payload: ICreateInvoicePayload = {
			apiParams: {
				currency_request: currencyRequest,
				amount_request: +amountRequest,
				currency_paid: currencyPaid,
				currency_paid_network: currencyPaidNetwork,
				rate: +(rate || 0),
				expires_time_minutes: +expiresTimeMinutes,
				client_email: clientEmail,
			},
			onSuccess: (id) => {
				notify('Invoice was successfully created', 'success');
				setItemIdToSave(id);
				fetchInvoices();
				onFinally?.();
			},
			onFinally,
		};
		dispatch(createInvoiceRequest(payload));
	};

	const handleAfterSaveSubmit = (value: TVariantValue) => {
		if (itemIdToSave == null) {
			return;
		}
		switch (value) {
			case 'DOWNLOAD_IN_PDF': {
				dispatch(downloadInvoicePDFRequest({ apiParams: { invoice_id: itemIdToSave } }));
				break;
			}
			case 'SEND_PDF_TO_EMAIL': {
				const payload: ISendInvoicePayload = {
					apiParams: {
						type: 'pdf',
						invoice_id: itemIdToSave,
					},
					onSuccess: () => {
						notify('PDF invoice sent successfully', 'success');
					},
				};
				dispatch(sendInvoiceRequest(payload));
				break;
			}
			case 'GENERATE_LINK':
				dispatch(
					getInvoiceLinkRequest({
						apiParams: { invoice_id: itemIdToSave },
						onSuccess: (link) => {
							notify('Link to invoice generated successfully', 'success');
						},
					}),
				);
				break;
			case 'GENERATE_LINK_AND_SEND_TO_EMAIL': {
				const payload: ISendInvoicePayload = {
					apiParams: {
						type: 'link',
						invoice_id: itemIdToSave,
					},
					onSuccess: () => {
						notify('Link to invoice generated and sent successfully', 'success');
					},
				};
				dispatch(sendInvoiceRequest(payload));
				break;
			}
			default:
				break;
		}
	};

	const handleDownloadCSV = () => {
		dispatch(
			downloadCSVRequest({
				nameFile: 'components invoices',
				apiParams: {
					status: (filter.status as TProviderInvoiceStatus) || undefined,
					currency_request: (filter.currency_request as string) || undefined,
					order_provider_id: (filter.order_id as string) || undefined,
					date_from: filter.date_from ? dateToString(filter.date_from as Date) : undefined,
					date_to: filter.date_to ? dateToString(filter.date_to as Date) : undefined,
				},
			}),
		);
	};

	const filterProps = {
		status,
		currency,
		dateFrom,
		dateTo,
		order,
		paidCurrency,
		onCurrencyPaid: setPaidCurrency,
		onStatusChange: setStatus,
		onCurrencyChange: setCurrency,
		onDateFromChange: setDateFrom,
		onDateToChange: setDateTo,
		onOrderChange: setOrder,
	};

	useEffect(() => {
		dispatch(
			getProviderInvoicesRequest({
				apiParams: {
					...filter,
					current_page: currentPage,
				},
			}),
		);
	}, [currentPage, dispatch, filter]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<InvoicesTable
			invoices={invoices}
			filterProps={filterProps}
			saveLoading={saveLoading}
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			loading={loading}
			itemIdToSave={itemIdToSave}
			setItemIdToSave={setItemIdToSave}
			onCreateSubmit={handleCreateSubmit}
			onSaveSubmit={handleAfterSaveSubmit}
			downloadReportUrl={endpoint.provider.DOWNLOAD_INVOICES_REPORT}
		/>
	);
};
