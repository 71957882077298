import { useState, FC } from 'react';
import { TriangleArrowDownIcon2, CheckIcon } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { ISelectProps } from './types';

export const Select: FC<ISelectProps> = ({ options, setSelect, sleceted, selectMb, selectWd }) => {
	const [value, setValue] = useState('Select' || sleceted);
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (_value: string, __filename: string) => {
		setValue(_value);
		setOpen(false);
		setSelect(__filename, _value);
	};

	return (
		<div className={`select-block ${String(selectMb) || ''}`}>
			<div className={`select select--type-choice ${open ? 'active' : ''}`}>
				<button
					type="button"
					className="select__current select__current--placeholder"
					ref={triggerRef}
					onClick={toggleOpen}
				>
					{options?.find((opt) => opt.label === value)?.label || 'Select'}
					<span className="select__current-arrow">
						<TriangleArrowDownIcon2 />
					</span>
				</button>
				<div className={`select__drop ${String(selectWd)}`} ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{options.map((option) => (
									<li key={option.value}>
										<button
											type="button"
											className={value === option.label ? 'active' : ''}
											onClick={() => handleSelect(option.label, option.code)}
										>
											<span>{option.label}</span>
											<span>{value === option.label && <CheckIcon />}</span>
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
