import { FC, useState } from 'react';
import { useLocation } from 'react-router';
import { ICreatePasswordPayload } from 'redux/reducers/auth/types';
import { notify } from 'utils/notify';
import { TwoFactorAuth } from '../TwoFactorAuth/TwoFactorAuth';
import { CreatePasswordForm } from './CreatePasswordForm/CreatePasswordForm';
import { TCreatePasswordFormOnSubmit } from './CreatePasswordForm/types';
import { ICreatePasswordProps } from './types';

export const CreatePassword: FC<ICreatePasswordProps> = ({ onSuccess }) => {
	const location = useLocation();
	const [open2fa, setOpen2fa] = useState<boolean>(false);
	const [data, setData] = useState<ICreatePasswordPayload | null>(null);

	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get('token');

	const handleSubmit: TCreatePasswordFormOnSubmit = ({ password }, { setSubmitting }) => {
		if (!token) {
			setSubmitting(false);
			notify('You need an invitation to log in', 'info');
			return;
		}
		setData({
			apiParams: { token, password, totp: '' },
			onSuccess,
			onError: () => setSubmitting(false),
		});
		setOpen2fa(true);
		// dispatch(createPasswordRequest(payload));
	};

	return (
		<div className="authorization-box">
			{open2fa ? <TwoFactorAuth data={data} /> : <CreatePasswordForm onSubmit={handleSubmit} />}
		</div>
	);
};
