import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLeading } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	getTRXOverviewRequest,
	getTRXOverviewSuccess,
	getTRXDelegationRequest,
	getTRXDelegationSuccess,
	getTRXStakingRequest,
	getTRXStakingSuccess,
	createTRXDelegationRequest,
	createTRXDelegationSuccess,
	deleteTRXDelegationRequest,
	deleteTRXDelegationSuccess,
	cancelAllUnstakingsSuccess,
	cancelAllUnstakingsRequest,
	addUnstakeSuccess,
	addUnstakeRequest,
	addStakeRequest,
	addStakeSuccess,
} from './reducer';
import {
	ICreateTRXDelegation,
	IDelegationParams,
	IDelete,
	IOverviewItem,
	IStakingParams,
	TDelegationItem,
	TOverviewParams,
	TStakingItem,
} from './types';

function* getTRXStaking({ payload }: PayloadAction<IStakingParams>) {
	try {
		const data: TStakingItem = yield call(api.provider.getTRXStaking, payload);
		yield put(getTRXStakingSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* getTRXDelegation({ payload }: PayloadAction<IDelegationParams>) {
	try {
		const data: TDelegationItem = yield call(api.provider.getTRXDelegation, payload);
		yield put(getTRXDelegationSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* getTRXOverview({ payload }: PayloadAction<TOverviewParams>) {
	try {
		const data: IOverviewItem = yield call(api.provider.getTRXOverview, payload);
		yield put(getTRXOverviewSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* createTRXDelegation({ payload }: PayloadAction<ICreateTRXDelegation>) {
	try {
		yield call(api.provider.createTRXDelegation, payload);
		yield put(createTRXDelegationSuccess());
	} catch (error) {
		showError(error);
	}
}

function* deleteTRXDelegation({ payload }: PayloadAction<IDelete>) {
	try {
		yield call(api.provider.deleteTRXDelegation, payload);
		yield put(deleteTRXDelegationSuccess());
	} catch (error) {
		showError(error);
	}
}

function* cancelAllUnstakings() {
	try {
		yield call(api.provider.cancelAllUnstakings);
		yield put(cancelAllUnstakingsSuccess());
	} catch (error) {
		showError(error);
	}
}

function* addUnstake({ payload }: PayloadAction<IDelete>) {
	try {
		yield call(api.provider.addUnstake, payload);
		yield put(addUnstakeSuccess());
	} catch (error) {
		showError(error);
	}
}

function* addStake({ payload }: PayloadAction<IStakingParams>) {
	try {
		yield call(api.provider.addStake, payload);
		yield put(addStakeSuccess());
	} catch (error) {
		showError(error);
	}
}

export function* providerTRXManagerSaga() {
	yield takeLeading(getTRXStakingRequest, getTRXStaking);
	yield takeLeading(addStakeRequest, addStake);
	yield takeLeading(addUnstakeRequest, addUnstake);
	yield takeLeading(cancelAllUnstakingsRequest, cancelAllUnstakings);
	yield takeLeading(getTRXDelegationRequest, getTRXDelegation);
	yield takeLeading(getTRXOverviewRequest, getTRXOverview);
	yield takeLeading(createTRXDelegationRequest, createTRXDelegation);
	yield takeLeading(deleteTRXDelegationRequest, deleteTRXDelegation);
}
