import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProfile, getProfileLoading } from 'redux/reducers/merchantProfile/selectors';
import { getMerchantApprovalUserProfile } from 'redux/reducers/merchantApprovalManagement/selectors';
import { getUserData, getUserRole, getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { IToggleMerchantOwnerBlockPayload } from 'redux/reducers/providerMerchant/types';
import { notify } from 'utils/notify';
import { rolesProfileWerchant } from 'layouts-elements/ProviderSidebar/roles';
import {
	providerResetPasswordRequest,
	toggleMerchantOwnerBlockRequest,
} from 'redux/reducers/providerMerchant/reducer';
import { postProviderMerchantProfileRequest } from 'redux/reducers/merchantProfile/reducer';
import { reset2faMerchantRequest, reset2faProviderRequest } from 'redux/reducers/auth/reducer';
import { getUserMerchantProfileRequest } from 'redux/reducers/merchantApprovalManagement/reducer';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import Main from 'v2Components/layoutsComponents/Main';
import { MerchantInfo } from './Profile/MerchantInfo';
import ProfileItem from './Profile/ProfileItem';
import { IProfileFormValues, TProfileFormOnSubmit } from './Profile/types';
import { DeactivateAccount } from './Profile/DeactivateAccount/DeactivateAccount';
import styles from './styles.module.scss';

export default () => {
	const dispatch = useDispatch();
	const item = useSelector(getProfile);
	const loading = useSelector(getProfileLoading);
	const user = useSelector(getMerchantApprovalUserProfile);
	const userRole = useSelector(getUserRole);
	const userType = useSelector(getUserType);
	const userData = useSelector(getUserData);

	// const loading = useSelector(getProfileToggleLoading);
	// const checked = useSelector(getProfileToggleCheckedLoading);

	const [isModal, setIsModal] = useState(false);
	const [activTogle, setActivTogle] = useState(true);
	const { merchantId } = useParams<{ merchantId: string }>();
	const isProvider = userType === constUserTypes.PROVIDER;

	const [stateAccount, setValueState] = useState<IProfileFormValues>({
		activateMerchant: !item?.merchant?.blocked,
		firstName: item?.merchant.first_name,
		lastName: item?.merchant.last_name,
		companyName: item?.merchant.company_name,
		idMerchant: item?.merchant.id_merchant,
		email: item?.merchant.email,
		phone: item?.merchant.phone,
		country: item?.merchant.country,
		city: item?.merchant.city,
		addressLineOne: item?.merchant.address_1,
		addressLineTwo: item?.merchant.address_2,
	});

	const closeModal = () => {
		setIsModal(false);
		setActivTogle(false);
	};

	useEffect(() => {
		setValueState({
			activateMerchant: !item?.merchant?.blocked,
			firstName: item?.merchant.first_name,
			lastName: item?.merchant.last_name,
			companyName: item?.merchant.company_name,
			idMerchant: item?.merchant.id_merchant,
			email: item?.merchant.email,
			phone: item?.merchant.phone,
			country: item?.merchant.country,
			city: item?.merchant.city,
			addressLineOne: item?.merchant.address_1,
			addressLineTwo: item?.merchant.address_2,
		});
	}, [
		item?.merchant?.blocked,
		item?.merchant.address_1,
		item?.merchant.address_2,
		item?.merchant.city,
		item?.merchant.company_name,
		item?.merchant.country,
		item?.merchant.email,
		item?.merchant.first_name,
		item?.merchant.id_merchant,
		item?.merchant.last_name,
		item?.merchant.phone,
	]);

	const toggleBlockRequest = (isBlock: boolean) => {
		const blockMsg = 'User was deactivated successfully';
		const unblockMsg = 'User was activated successfully';

		const params: IToggleMerchantOwnerBlockPayload = {
			apiParams: { id: item?.merchant?.id, is_block: isBlock ? 1 : 0 },
			onSuccess: () => {
				closeModal();
				notify(isBlock ? blockMsg : unblockMsg, 'success');
			},
		};
		rolesProfileWerchant.profile.includes(userRole || '')
			? dispatch(toggleMerchantOwnerBlockRequest(params))
			: notify(`You don't have enough rights`, 'info');
	};

	const handleBlockToggle = () => {
		if (!stateAccount.activateMerchant) {
			toggleBlockRequest(false);
			rolesProfileWerchant.profile.includes(userRole || '') &&
				setValueState({ ...stateAccount, activateMerchant: true });
			return;
		}
		setIsModal(true);
	};

	const handleDeactivateAccount = () => {
		toggleBlockRequest(true);
		rolesProfileWerchant.profile.includes(userRole || '') &&
			setValueState({ ...stateAccount, activateMerchant: false });
	};

	// const handleChange = (e: MouseEvent<HTMLInputElement>) => {
	// 	dispatch(
	// 		postProviderMerchantProfileToggleRequest({
	// 			id: merchantId,
	// 			checked: e.currentTarget.checked,
	// 		}),
	// 	);
	// };
	const onSubmit: TProfileFormOnSubmit = (values) => {
		// if (stateAccount.activateMerchant !== values.activateMerchant) {
		// 	dispatch(postProviderMerchantProfileToggleRequest({ id: merchantId }));
		// }

		const data = {
			merchant_id: Number(merchantId),
			email: values.email,
			phone_number: values.phone,
			first_name: values.firstName,
			last_name: values.lastName,
			company_name: values.companyName,
			id_merchant: values.idMerchant,
			country: values.country,
			city: values.city,
			address_1: values.addressLineOne,
			address_2: values.addressLineTwo,
		};
		rolesProfileWerchant.profile.includes(userRole || '')
			? dispatch(postProviderMerchantProfileRequest(data))
			: notify(`You don't have enough rights`, 'info');
		setValueState(values);
	};

	const resetTwoFactor = () => {
		const payload = {
			email: isProvider ? item?.merchant?.email || '' : (userData?.email as string),
			user_type: constUserTypes.MERCHANT,
		};

		dispatch(
			isProvider
				? reset2faProviderRequest({
						apiParams: payload,
				  })
				: reset2faMerchantRequest({
						apiParams: payload,
				  }),
		);
	};

	const resetPassword = () => dispatch(providerResetPasswordRequest({ merchant_id: merchantId }));

	useLayoutEffect(() => {
		userType === constUserTypes.MERCHANT &&
			dispatch(getUserMerchantProfileRequest({ userType, params: { merchant_id: merchantId } }));
	}, [dispatch, merchantId, userType]);

	if (userType === constUserTypes.MERCHANT) {
		return (
			user && (
				<Main>
					<MerchantInfo profile={user} />
				</Main>
			)
		);
	}
	return (
		<div className={styles.main}>
			{!loading && (
				<ProfileItem
					onSubmit={onSubmit}
					valueState={stateAccount}
					resetTwoFactor={resetTwoFactor}
					resetPassword={resetPassword}
					handleChange={handleBlockToggle}
					userRole={userRole}
					activTogle={activTogle}
				/>
			)}
			<PopupModal open={isModal} onClose={closeModal}>
				<DeactivateAccount onDeactivate={handleDeactivateAccount} onClose={closeModal} />
			</PopupModal>
		</div>
	);
};
