import { FC, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { TextField } from 'UI/Forms/TextField/TextField';
import classNames from 'classnames';
import Button from 'v2Components/UI/Button';
import { IProps } from '../types';
import styles from './styles.module.scss';

const TableBody: FC<IProps> = ({ onSubmit, item, isHaveAdded = false }) => {
	const [isChanged, setIsChanged] = useState(false);
	const { fee_percent, fee_plus_usd } = item;

	const initialValues = {
		fee_percent: fee_percent || '',
		fee_plus_usd: fee_plus_usd || '',
	};
	const validationSchema = () =>
		yup.object().shape({
			fee_percent: yup.number().required('Required field'),
			fee_plus_usd: yup.number().required('Required field'),
		});
	const onKeyDown = () => setIsChanged(true);
	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={initialValues}
			onSubmit={(values, actions) => {
				actions.setSubmitting(false);
				const body = {
					fee_id: item.id,
					...values,
				};
				onSubmit(body);
			}}
		>
			{({ isSubmitting, isValid, handleSubmit }) => (
				<form onSubmit={handleSubmit} className={styles.form}>
					<div className={styles.feeManagement__item}>
						<div className={styles.feeManagement__title}>
							<p>{item?.fee_view_name}</p>
						</div>
						<div className={styles.feeManagement__line}>
							<TextField
								classNameInputItem={styles.feeManagement__input}
								classNameCustom={styles.feeManagement__label}
								name="fee_percent"
								type="number"
								inputProps={{ onKeyDown }}
							/>
							<TextField
								classNameInputItem={styles.feeManagement__input}
								classNameCustom={styles.feeManagement__label}
								name="fee_plus_usd"
								type="number"
								inputProps={{ onKeyDown }}
							/>
							{isHaveAdded ? (
								<TextField
									classNameInputItem={styles.feeManagement__input}
									classNameCustom={styles.feeManagement__label}
									name="additional"
									type="number"
									inputProps={{ onKeyDown }}
								/>
							) : (
								<div className={styles.feeManagement__hide} />
							)}
							<Button
								disabled={!isValid || isSubmitting}
								type="submit"
								className={classNames(
									styles.feeManagement__button,
									isChanged ? styles.feeManagement__buttonShow : '',
								)}
							>
								Save
							</Button>
						</div>
					</div>
				</form>
			)}
		</Formik>
	);
};

export default TableBody;
