import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { getAuthAll, getIsAuth, getIsNotAuth } from 'redux/reducers/auth/selectors';

const locationHelper = locationHelperBuilder({});

export const isAuth = connectedRouterRedirect({
	redirectPath: '/login',
	authenticatedSelector: getIsAuth,
	wrapperDisplayName: 'FrontUserIsAuthenticated',
});

export const isGuest = connectedRouterRedirect({
	redirectPath: (state, ownProps) =>
		locationHelper.getRedirectQueryParam(ownProps) || '/transactions',
	allowRedirectBack: false,
	authenticatedSelector: getIsNotAuth,
	wrapperDisplayName: 'FrontUserIsNotAuthenticated',
});

export const isAll = connectedRouterRedirect({
	redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
	allowRedirectBack: false,
	authenticatedSelector: getAuthAll,
	wrapperDisplayName: 'FrontUserIsNotAuthenticated',
});
