import { Route, Switch, useRouteMatch } from 'react-router';
import { Tabs } from 'v2Components/commomComponents/Tabs/Tabs';
import Main from 'v2Components/layoutsComponents/Main';
import SwitchCryptoFiatRoute from 'v2Components/layoutsComponents/SwitchCryptoFiatRoute';
import { useState } from 'react';
import AccountsCurrencySettings from './CurrencySettings';
import AccountsAccounts from './ProjectWallets';

export const ProjectAccounts = () => {
	const { path } = useRouteMatch();
	const url_string = window.location.href;
	const url = new URL(url_string);
	const activeTab = url.searchParams.get('tabs') || 'crypto';
	const [search, setSearch] = useState('');
	const listContent = [
		{ link: `accounts?tabs=${activeTab}`, value: 'Accounts' },
		{ link: `currency-settings`, value: 'Currency settings' },
	];
	const currencySettingsMatch = useRouteMatch(`${path}/currency-settings`);
	return (
		<Main header={!currencySettingsMatch && <SwitchCryptoFiatRoute activeTab={activeTab} />}>
			<Tabs listContent={listContent} />
			<Switch>
				<Route exact path={`${path}/accounts`} render={(props) => <AccountsAccounts />} />
				<Route
					exact
					path={`${path}/currency-settings`}
					render={(props) => <AccountsCurrencySettings search={search} />}
				/>
			</Switch>
		</Main>
	);
};
