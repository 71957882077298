import { AuthMain } from 'layouts-elements/AuthMain/AuthMain';
import { AuthHeader } from 'layouts-elements/AuthHeader/AuthHeader';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import { IAuthLayoutProps } from './types';

export const AuthLayout: FC<IAuthLayoutProps> = ({ children, title }) => {
	const appTitle = APP_NAME || '';
	const layoutTitle = title ? ` | ${title}` : '';

	return (
		<>
			<Helmet>
				<title>{`${appTitle}${layoutTitle}`}</title>
			</Helmet>

			<div className="wrapper">
				<AuthHeader />

				<AuthMain>{children}</AuthMain>
			</div>
		</>
	);
};
