import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAnalyticsResponse, IMerchantAnalyticsStore } from './types';

export const merchantAnalyticsStore = (state: IStoreState) => state.merchantAnalytics;

export const getListAnlyticsSelector = createSelector(
	[merchantAnalyticsStore],
	(merchantAnalytics: IMerchantAnalyticsStore): IAnalyticsResponse | null =>
		merchantAnalytics.analyticsList,
);

export const getLoadingSelector = createSelector(
	[merchantAnalyticsStore],
	(merchantAnalytics: IMerchantAnalyticsStore): boolean => merchantAnalytics.analyticsLoading,
);

export const getLoadingSmsSelector = createSelector(
	[merchantAnalyticsStore],
	(merchantAnalytics: IMerchantAnalyticsStore): boolean => merchantAnalytics.sendSms,
);
