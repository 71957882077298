import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import { TProviderApprovements, IGetApprovementsParams } from 'services/api/provider/types';
import {
	deleteProjectError,
	deleteProjectRequest,
	deleteProjectSuccess,
	getApprovementRequest,
	getApprovementsError,
	getApprovementsSuccess,
	unverifyProjectError,
	unverifyProjectRequest,
	unverifyProjectSuccess,
	verifyProjectError,
	verifyProjectRequest,
	verifyProjectSuccess,
} from './reducer';
import { IVerifyProjectPayload } from './types';

function* getApprovementRequestWorker({ payload }: PayloadAction<IGetApprovementsParams>) {
	try {
		const response: TProviderApprovements = yield call(api.provider.getApprovements, payload);
		yield put(getApprovementsSuccess(response));
	} catch (error) {
		yield put(getApprovementsError());
		showError(error);
	}
}

function* verifyProjectRequestWorker({ payload }: PayloadAction<IVerifyProjectPayload>) {
	try {
		const {
			apiParams: { project_id },
		} = payload;
		yield call(api.provider.verifyProject, { project_id });
		yield put(verifyProjectSuccess(payload));
	} catch (error) {
		yield put(verifyProjectError());
		showError(error);
	}
}

function* unverifyProjectRequestWorker({ payload }: PayloadAction<IVerifyProjectPayload>) {
	try {
		const {
			apiParams: { project_id },
		} = payload;
		yield call(api.provider.unverifyProject, { project_id });
		yield put(unverifyProjectSuccess(payload));
	} catch (error) {
		yield put(unverifyProjectError());
		showError(error);
	}
}

function* deleteProjectRequestWorker({ payload }: PayloadAction<IVerifyProjectPayload>) {
	try {
		const {
			apiParams: { project_id },
		} = payload;
		yield call(api.provider.deleteProject, { project_id });
		yield put(deleteProjectSuccess(payload));
	} catch (error) {
		yield put(deleteProjectError());
		showError(error);
	}
}

export function* providerProjectApprovementsSaga() {
	yield takeEvery(getApprovementRequest, getApprovementRequestWorker);
	yield takeEvery(verifyProjectRequest, verifyProjectRequestWorker);
	yield takeEvery(unverifyProjectRequest, unverifyProjectRequestWorker);
	yield takeEvery(deleteProjectRequest, deleteProjectRequestWorker);
}
