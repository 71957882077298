/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IProviderMerchantProfilePayload,
	IProviderMerchantProfilePostPayload,
	IProviderMerchantProfileStore,
	IProviderMerchantProfileToggleIdPayload,
} from './types';

export const initialState: IProviderMerchantProfileStore = {
	merchantProviderProfile: null,
	merchantProviderProfileLoading: false,
	editMerchantProviderProfileLoading: false,
	editMerchantProviderProfileToggleChecked: false,
	editMerchantProviderProfileToggleLoading: false,
};

const merchantProviderProfile = createSlice({
	name: '@@merchantProviderMerchantProfile',
	initialState,
	reducers: {
		getProviderMerchantProfileRequest: (
			state,
			_: PayloadAction<IProviderMerchantProfilePayload>,
		) => {
			state.merchantProviderProfileLoading = true;
			state.merchantProviderProfile = null;
		},
		getMerchantProfileRequest: (state, _: PayloadAction<IProviderMerchantProfilePayload>) => {
			state.merchantProviderProfileLoading = true;
			state.merchantProviderProfile = null;
		},
		getProviderMerchantProfileSuccess: (state, action: PayloadAction<any>) => {
			state.merchantProviderProfileLoading = false;
			state.editMerchantProviderProfileToggleChecked =
				action.payload.merchant.status === 'verified';
			state.merchantProviderProfile = action.payload;
		},
		postProviderMerchantProfileRequest: (
			state,
			_: PayloadAction<IProviderMerchantProfilePostPayload>,
		) => {
			state.editMerchantProviderProfileLoading = true;
		},
		postProviderMerchantProfileSuccess: (state, action: PayloadAction<any>) => {
			state.merchantProviderProfile = action.payload;
			state.merchantProviderProfileLoading = false;
			state.editMerchantProviderProfileLoading = false;
		},
		postProviderMerchantProfileToggleRequest: (
			state,
			action: PayloadAction<IProviderMerchantProfileToggleIdPayload>,
		) => {
			state.merchantProviderProfileLoading = true;
			// state.editMerchantProviderProfileToggleChecked = action.payload.checked;
		},
		postProviderMerchantProfileToggleSuccess: (state) => {
			// /* eslint-disable */
			// debugger;
			state.editMerchantProviderProfileToggleChecked =
				!state.editMerchantProviderProfileToggleChecked;
			state.editMerchantProviderProfileToggleLoading = false;
		},
	},
});

export default merchantProviderProfile.reducer;
export const {
	postProviderMerchantProfileRequest,
	postProviderMerchantProfileSuccess,
	getProviderMerchantProfileRequest,
	getProviderMerchantProfileSuccess,
	postProviderMerchantProfileToggleRequest,
	postProviderMerchantProfileToggleSuccess,
	getMerchantProfileRequest,
} = merchantProviderProfile.actions;
