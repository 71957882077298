import classNames from 'classnames';
import style from './styles.module.scss';

const TableHeader = () => {
	return (
		<div className={style.table__header}>
			<div className={style.tr}>
				<div className={style.td}>
					<div className={style.td__name}>
						<p>Currency</p>
					</div>
				</div>
				<div className={style.td}>
					<div className={style.td__name}>
						<p>Currency on which autoconvert</p>
					</div>
				</div>
				<div className={classNames(style.td, style.td_right)}>
					<div className={style.td__name}>
						<p>Action</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default TableHeader;
