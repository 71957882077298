import { FC } from 'react';
import { ITabs } from './type';

export const Tabs: FC<ITabs> = ({ activeTab, setActiveTab }) => {
	return (
		<div className="tabs">
			<button
				type="button"
				onClick={() => setActiveTab('request')}
				className={`tabs__item ${activeTab === 'request' ? 'active' : ''}`}
			>
				Request
			</button>
			<button
				type="button"
				onClick={() => setActiveTab('response')}
				className={`tabs__item ${activeTab === 'response' ? 'active' : ''}`}
			>
				Response
			</button>
		</div>
	);
};
