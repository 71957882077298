import { FC } from 'react';
import { useSelect } from 'hooks';
import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import classNames from 'classnames';
import { IVerifySelect, TOption, TValue } from './types';
import styles from './styles.module.scss';

export type { TValue };

const OPTIONS: TOption[] = [
	{
		name: 'Verified',
		value: 'verified',
	},
	{
		name: 'Unverified',
		value: 'unverified',
	},
	{
		name: 'Deleted',
		value: 'deleted',
	},
];

export const VerifySelect: FC<IVerifySelect> = ({ value, onChange }) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const handleSelect = (_value: TValue) => () => {
		setOpen(false);
		onChange?.(_value);
	};
	return (
		<div className={styles.select}>
			<div className={classNames('select', { active: open })}>
				<button type="button" className="select__current" ref={triggerRef} onClick={toggleOpen}>
					{OPTIONS.find((option) => option.value === value)?.name}
					<span className="select__current-arrow">
						<TriangleArrowDownIcon2 />
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{OPTIONS.map((option) => (
									<li key={option.value}>
										<button type="button" onClick={handleSelect(option.value)}>
											{option.name}
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
