import { IStoreState } from 'redux/types';

export const getProviderTransactionsStore = (state: IStoreState) => state.providerTransactions;

export const getTransactions = (state: IStoreState) =>
	getProviderTransactionsStore(state).transactions;

export const getTransactionsLoading = (state: IStoreState) =>
	getProviderTransactionsStore(state).transactionsLoading;
export const getTransactionsFilter = (state: IStoreState) =>
	getProviderTransactionsStore(state).transactionsFilter;
export const getTransactionsSearch = (state: IStoreState) =>
	getProviderTransactionsStore(state).transactionsSearch;
export const getTransactionsFiatFilter = (state: IStoreState) =>
	getProviderTransactionsStore(state).transactionsFiatFilter;
export const getTransactionsFiat = (state: IStoreState) =>
	getProviderTransactionsStore(state).transactionsFiat;
export const getTransactionsFiatSearch = (state: IStoreState) =>
	getProviderTransactionsStore(state).transactionsFiatSearch;
export const getTransactionsFiatLoader = (state: IStoreState) =>
	getProviderTransactionsStore(state).transactionsFiatLoading;
