import { IFilter } from 'redux/reducers/app/types';
import { TApiGetProviderAllCurrencyResponse } from 'services/api/provider/types';
import { IGetCurrencyNetworkItem } from 'services/api/public/types';
import { IApiMerchantGetProjectTransactionsParams } from 'services/api/project/types';

export interface TransactionsHistory {
	id: string;
	value: string | number | Date | null;
	networks?: IGetCurrencyNetworkItem[];
	currencies?: TApiGetProviderAllCurrencyResponse[];
}

export const convertRequestParams = (
	props: IFilter,
	tab: string,
): IApiMerchantGetProjectTransactionsParams => {
	return {
		...props,
		date_from: (props.date_from as Date) || undefined,
		date_to: (props.date_to as Date) || undefined,
		amount_from: props.amount_from ? +props.amount_from : undefined,
		amount_to: props.amount_to ? +props.amount_to : undefined,
		type: (props.type as string) || undefined,
		transaction_id: props.transaction_id ? +props.transaction_id : undefined,
		status: (props.status as string) || undefined,
		risk_score: props.risk_score ? +props.risk_score : undefined,
		project_id: props.project_id ? +props.project_id : undefined,
		per_page: (props.per_page as number) || undefined,
		current_page: props.current_page || undefined,
		currency_name: (props.currency_name as string) || undefined,
		creator_entity_id: (props.creator_entity_id as number) || undefined,
		hash_transaction: (props.hash_transaction as string) || undefined,
	};
};
