import { FC } from 'react';
import T from 'i18n-react';
import payment6Icon from 'assets/inline-svg/payment/payment-6.svg';
import payment7Icon from 'assets/inline-svg/payment/payment-7.svg';
import payment8Icon from 'assets/inline-svg/payment/payment-8.svg';
import payment9Icon from 'assets/inline-svg/payment/payment-9.svg';
import moment from 'moment';
import { getPaymentStatus } from 'redux/reducers/paymentWidget/selectors';
import { useSelector } from 'react-redux';
import { findLastObjectWithStatus } from '../utils';
import { IPaymentSuccess } from '../PaymentSuccess/types';

export const PaymentHistory: FC<IPaymentSuccess> = ({ payment: { created_at }, type }) => {
	const paymentStatus = useSelector(getPaymentStatus);
	return (
		<div className="payment-history">
			<span className="payment-inv">{T.translate('History.title')}</span>
			<ul className="payment-history_list">
				<li className="payment-history_point">
					<img className="payment-history_img" src={payment6Icon} alt="icon" />
					<span className="payment-history_name">I{T.translate('History.sub_title')}</span>
					<span className="payment-history_date">
						{(paymentStatus?.created_at &&
							moment(paymentStatus?.created_at).format('DD.MM.YYYY, HH:mm:ss')) ||
							(created_at && moment(created_at).format('DD.MM.YYYY, HH:mm:ss')) ||
							''}
					</span>
				</li>
				<li className="payment-history_point">
					<img className="payment-history_img" src={payment7Icon} alt="icon" />
					<span className="payment-history_name">{T.translate('History.sub_title_01')}</span>
					<span className="payment-history_number">
						+{paymentStatus?.invoice_history[0]?.amount}{' '}
						{paymentStatus?.invoice_history[0]?.currency?.code?.toUpperCase() || ''}
					</span>
					<span className="payment-history_date">
						{moment(paymentStatus?.invoice_history[0]?.created_at).format('DD.MM.YYYY, HH:mm:ss') ||
							''}
					</span>
					<a
						href={paymentStatus?.invoice_history[0]?.tx_url}
						target="_blank"
						className="payment-history_id"
						rel="noreferrer"
					>
						ID: <span>{paymentStatus?.invoice_history[0]?.hash_transaction}</span>
					</a>
				</li>
				<li className="payment-history_point">
					<img className="payment-history_img" src={payment8Icon} alt="icon" />
					<span className="payment-history_name">{T.translate('History.text')}</span>
					<span className="payment-history_date">
						{moment(
							paymentStatus &&
								paymentStatus.invoice_history &&
								findLastObjectWithStatus(paymentStatus.invoice_history, 'in_progress')?.created_at,
						).format('DD.MM.YYYY, HH:mm:ss') || ''}
					</span>
				</li>

				{type !== 'progress' && (
					<li className="payment-history_point">
						<img className="payment-history_img" src={payment9Icon} alt="icon" />
						<span className="payment-history_name">{T.translate('History.text_01')}</span>
						<span className="payment-history_date">
							{moment(
								paymentStatus &&
									paymentStatus.invoice_history &&
									findLastObjectWithStatus(paymentStatus?.invoice_history, 'success')?.created_at,
							).format('DD.MM.YYYY, HH:mm:ss') || ''}
						</span>
					</li>
				)}
			</ul>
		</div>
	);
};
