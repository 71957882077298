import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IGetWirthdravalResponse,
	IPutWirthdravalApprovelRequest,
	IProviderWithdravalayload,
	IPutApproveRejectRequest,
	IProjectWithdrawalFiatDataRequest,
	IProjectWithdrawalFiatDataResponse,
	IPutApproveResendRequest,
} from 'redux/reducers/projectWithdrawal/types';
import {
	getProjectWithdrawalSuccess,
	getProjectWithdrawalUpdate,
	putRejectRequest,
	putApprovementRequest,
	getProjectWithdrawalRequest,
	getProjectWithdrawalFiatDataRequest,
	getProjectWithdrawalFiatDataSuccess,
	getProjectWithdrawalFiatSuccess,
	getProjectWithdrawalFiatRequest,
	putResendRequest,
} from './reducer';

function* getProviderWithdrawalWorker({ payload }: PayloadAction<IProviderWithdravalayload>) {
	const { params } = payload;
	try {
		const response: IGetWirthdravalResponse = yield call(api.project.getProviderWithdrawal, params);
		yield put(getProjectWithdrawalSuccess(response));
	} catch (error) {
		// yield put(getReportsError());
		showError(error);
	}
}

function* putApprovementRequestWorker({ payload }: PayloadAction<IPutWirthdravalApprovelRequest>) {
	const { params, onSuccess, is_crypto } = payload;
	try {
		yield call(api.project.putAppoveWithdrawal, payload);
		onSuccess?.();
		const request = is_crypto
			? getProjectWithdrawalRequest({ params: { current_page: 1 }, onFinally: () => '' })
			: getProjectWithdrawalFiatRequest({
					params: { current_page: 1 },
					onFinally: () => '',
			  });
	} catch (error) {
		showError(error);
	}
}

function* putRejectRequestWorker({ payload }: PayloadAction<IPutApproveRejectRequest>) {
	const { params, onSuccess, is_crypto } = payload;
	try {
		yield call(api.project.putRejectWithdrawal, payload);
		onSuccess?.();
		const request = is_crypto
			? getProjectWithdrawalRequest({ params: { current_page: 1 }, onFinally: () => '' })
			: getProjectWithdrawalFiatRequest({
					params: { current_page: 1 },
					onFinally: () => '',
			  });
		yield put(request);
	} catch (error) {
		showError(error);
	}
}

function* putRejectResendWorker({ payload }: PayloadAction<IPutApproveResendRequest>) {
	const { params, onSuccess, is_crypto } = payload;
	try {
		yield call(api.project.putResendWithdrawal, payload);
		onSuccess?.();
		const request = is_crypto
			? getProjectWithdrawalRequest({ params: { current_page: 1 }, onFinally: () => '' })
			: getProjectWithdrawalFiatRequest({
					params: { current_page: 1 },
					onFinally: () => '',
			  });
		yield put(request);
	} catch (error) {
		showError(error);
	}
}

function* getProjectWithdrawalFiatDataWorker({
	payload,
}: PayloadAction<IProjectWithdrawalFiatDataRequest>) {
	const { params, onSuccess } = payload;
	try {
		const response: IProjectWithdrawalFiatDataResponse = yield call(
			api.project.getWithdrawalDataFiat,
			params,
		);
		onSuccess?.();

		yield put(getProjectWithdrawalFiatDataSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* getProviderWithdrawalFiatWorker({ payload }: PayloadAction<IProviderWithdravalayload>) {
	const { params } = payload;
	try {
		const response: IGetWirthdravalResponse = yield call(
			api.project.getProviderFiatWithdrawal,
			params,
		);
		yield put(getProjectWithdrawalFiatSuccess(response));
	} catch (error) {
		// yield put(getReportsError());
		showError(error);
	}
}
function* getProviderWithdrawalFiatDefaultWorker({
	payload,
}: PayloadAction<IProviderWithdravalayload>) {
	const { params } = payload;
	try {
		const response: IGetWirthdravalResponse = yield call(
			api.project.getProviderFiatWithdrawal,
			params,
		);
		yield put(getProjectWithdrawalFiatSuccess(response));
	} catch (error) {
		// yield put(getReportsError());
		showError(error);
	}
}

export function* projectWithdrawalSaga() {
	yield takeLatest(
		[getProjectWithdrawalRequest, getProjectWithdrawalUpdate],
		getProviderWithdrawalWorker,
	);
	yield takeLatest(putApprovementRequest, putApprovementRequestWorker);
	yield takeLatest(putRejectRequest, putRejectRequestWorker);
	yield takeLatest(putResendRequest, putRejectResendWorker);
	yield takeLatest(getProjectWithdrawalFiatDataRequest, getProjectWithdrawalFiatDataWorker);
	yield takeLatest(
		[getProjectWithdrawalFiatRequest, getProjectWithdrawalUpdate],
		getProviderWithdrawalFiatWorker,
	);
}
