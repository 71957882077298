import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import { notify } from 'utils/notify';
import {
	getMerchantAnalyticsRequest,
	getMerchantAnalyticsResponse,
	merchantAnalyticsResetState,
	smsMerchantAnalyticsRequest,
	smsMerchantAnalyticsResponse,
} from './reducer';
import { IAnalyticsRequest, IAnalyticsResponse, ISmsReducer } from './types';

function* getMerchantAnalyticsWorker({ payload }: PayloadAction<IAnalyticsRequest>) {
	try {
		const data: IAnalyticsResponse = yield call(api.project.getMerchantAnalytics, payload);
		yield put(getMerchantAnalyticsResponse(data));
	} catch (error) {
		showError(error);
		yield put(merchantAnalyticsResetState());
	}
}

function* postSmsMerchantAnalyticsWorker({ payload }: PayloadAction<ISmsReducer>) {
	try {
		yield call(api.project.postSmsAnalytics, payload.paramsApi);
		yield put(smsMerchantAnalyticsResponse());
		yield notify('Request created successfully', 'success');
		yield payload.close();
		yield payload.clearText('');
	} catch (error) {
		showError(error);
	}
}

export function* merchantAnalyticsSaga() {
	yield takeLatest(getMerchantAnalyticsRequest, getMerchantAnalyticsWorker);
	yield takeLatest(smsMerchantAnalyticsRequest, postSmsMerchantAnalyticsWorker);
}
