/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiActivateProjectParams,
	IApiCreateProjectParams,
	IApiDeactivateProjectParams,
	IApiDeleteProjectParams,
	TApiGetProjectListResponse,
} from 'services/api/merchant/types';
import {
	IActivateProjectPayload,
	ICreateProjectPayload,
	IDeactivateProjectPayload,
	IDeleteProjectPayload,
	IGetProjectListPayload,
	IMerchantProjectsStore,
} from './types';

export const initialState: IMerchantProjectsStore = {
	projectList: null,
	projectCreate: null,
	projectCreateLoading: false,
};

const merchantProjects = createSlice({
	name: '@@merchantProjects',
	initialState,
	reducers: {
		getProjectListMerchantRequest: (state, _: PayloadAction<IGetProjectListPayload>) => state,
		getProjectListProviderRequest: (state, _: PayloadAction<IGetProjectListPayload>) => state,
		getProjectListSuccess: (state, action: PayloadAction<TApiGetProjectListResponse>) => {
			state.projectList = action.payload;
		},
		getProjectListError: (state) => {
			state.projectList = null;
		},

		activateProjectRequest: (state, _: PayloadAction<IActivateProjectPayload>) => state,
		activateProjectMerchantRequest: (state, _: PayloadAction<IActivateProjectPayload>) => state,
		activateProjectSuccess: (state, action: PayloadAction<IApiActivateProjectParams>) => {
			if (!state.projectList) return;
			const idNum = Number(action.payload.project_id);
			state.projectList = state.projectList.map((project) => {
				return project.id === idNum ? { ...project, is_active: 1 } : project;
			});
		},

		deactivateProjectRequest: (state, _: PayloadAction<IDeactivateProjectPayload>) => state,
		deactivateProjectMerchantRequest: (state, _: PayloadAction<IDeactivateProjectPayload>) => state,
		deactivateProjectSuccess: (state, action: PayloadAction<IApiDeactivateProjectParams>) => {
			if (!state.projectList) return;
			const idNum = Number(action.payload.project_id);
			state.projectList = state.projectList.map((project) => {
				return project.id === idNum ? { ...project, is_active: 0 } : project;
			});
		},

		deleteProjectRequest: (state, _: PayloadAction<IDeleteProjectPayload>) => state,
		deleteProjectMerchantRequest: (state, _: PayloadAction<IDeleteProjectPayload>) => state,
		deleteProjectSuccess: (state, action: PayloadAction<IApiDeleteProjectParams>) => {
			if (!state.projectList) return;
			const idNum = Number(action.payload.project_id);
			state.projectList = state.projectList.filter(({ id }) => id !== idNum);
		},
		postProjectMerchantCreateRequest: (state, _: PayloadAction<ICreateProjectPayload>) => {
			state.projectCreateLoading = true;
		},
		postOwnProjectMerchantCreateRequest: (state, _: PayloadAction<ICreateProjectPayload>) => {
			state.projectCreateLoading = true;
		},
		postProjectMerchantCreateSuccess: (state) => {
			state.projectCreate = null;
			state.projectCreateLoading = false;
		},
	},
});

export default merchantProjects.reducer;
export const {
	getProjectListMerchantRequest,
	getProjectListSuccess,
	getProjectListError,
	activateProjectRequest,
	activateProjectSuccess,
	deactivateProjectRequest,
	deactivateProjectSuccess,
	deleteProjectRequest,
	deleteProjectMerchantRequest,
	deleteProjectSuccess,
	postProjectMerchantCreateRequest,
	postProjectMerchantCreateSuccess,
	getProjectListProviderRequest,
	postOwnProjectMerchantCreateRequest,
	activateProjectMerchantRequest,
	deactivateProjectMerchantRequest,
} = merchantProjects.actions;
