import { FC } from 'react';
import { IAuthMainProps } from './types';

export const AuthMain: FC<IAuthMainProps> = ({ children }) => {
	return (
		<main className="main">
			<section className="authorization-section">{children}</section>
		</main>
	);
};
