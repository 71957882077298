import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getStatusBurger } from 'redux/reducers/app/selectors';
import { ProjectSidebarV2 } from 'v2Components/layoutsComponents/ProjectSidebar';
import { ProjectHeaderV2 } from 'v2Components/layoutsComponents/ProjectHeader/ProjectHeaderV2';
import { IDashboardLayoutProps } from './types';
import styles from './styles.module.scss';
import { ProjectLayout } from '../ProjectLayout/ProjectLayout';

export const ProjectLayoutV2: FC<IDashboardLayoutProps> = ({ children, title, v2 }) => {
	const appTitle = APP_NAME || '';
	const layoutTitle = title ? ` | ${title}` : '';
	const statusBurger = useSelector(getStatusBurger);
	if (!v2) {
		return <ProjectLayout title={title}> {children} </ProjectLayout>;
	}
	return (
		<>
			<Helmet>
				<title>{`${appTitle}${layoutTitle}`}</title>
			</Helmet>

			<div className="wrapper">
				<ProjectHeaderV2 />
				<main className={classNames(styles.main, { [styles.active]: statusBurger })}>
					<section className="main-section">
						<ProjectSidebarV2 />
						{children}
					</section>
				</main>
			</div>
		</>
	);
};
