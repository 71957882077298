import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { FC } from 'react';
import { trimLastZeros } from 'utils/strings';
import { isExist } from 'utils/numbers';
import { IProjectBalanceResponse } from 'redux/reducers/merchantAccounts/types';
import { IPropsSelectCoins } from '../../../TableRowCrypto/types';

export const SelectDropCoin: FC<IPropsSelectCoins> = ({
	currencies,
	onSubmit,
	isSelect,
	setIsSelect,
	accountList,
	cloneCheck,
	type,
	viewDecimals,
}) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (coin: IProjectBalanceResponse) => {
		setIsSelect(coin);
		setOpen(false);
	};

	return (
		<>
			<div className={`select select--height-55 ${open ? 'active' : ''}`}>
				<button ref={triggerRef} onClick={toggleOpen} type="button" className="select__current">
					<span className="token">
						<span className="token__icon">
							<img src={isSelect?.currency.img_path} alt="" />
						</span>
						<span className="token__name">{isSelect?.currency.name}</span>
						<span className="token__transcription">
							{isSelect?.currency.code.toUpperCase()}{' '}
							{isSelect?.currency?.code?.toLowerCase() === isSelect?.currency_network?.toLowerCase()
								? ''
								: `(${String(isSelect?.currency_network?.toUpperCase())})`}
						</span>
					</span>
					<span className="select__current-arrow">
						<TriangleArrowDownIcon2 />
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{currencies?.map((coin) => {
									return (
										(cloneCheck?.currency_id === coin.currency_id
											? cloneCheck?.currency_network !== coin.currency_network
											: cloneCheck?.currency.code !== coin.currency.code) && (
											<li key={coin.id}>
												<button
													disabled={
														accountList?.some(
															(project) =>
																project?.auto_exchange?.auto_exchange_code === coin.currency.code &&
																project?.auto_exchange?.auto_exchange_network ===
																	coin.currency_network,
														) ||
														(cloneCheck?.currency_id === coin.currency_id &&
															cloneCheck?.currency_network === coin.currency_network)
													}
													onClick={() => handleSelect(coin)}
													name={coin.currency.code}
													type="button"
												>
													<span className="token">
														<span className="token__icon">
															<img src={coin.currency.img_path} alt="" />
														</span>
														<span className="token__name">{coin.currency.name}</span>
														<span className="token__transcription">
															{coin.currency.code.toUpperCase()}{' '}
															{coin.currency.code.toLowerCase() ===
															coin.currency_network.toLowerCase()
																? ''
																: `(${coin.currency_network.toUpperCase()})`}
														</span>
													</span>
												</button>
											</li>
										)
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
			{/* {popUpOpen !== EButtonName.EXCHANGE && ( */}
			<div
				// className={`select-info ${popUpOpen === EButtonName.EXCHANGE ? 'select-info--custom' : ''}`}
				className="select-info"
			>
				<p className="select-info__name">Balance:</p>
				<p className="select-info__number">
					{isExist(viewDecimals) && isSelect?.balance !== undefined
						? trimLastZeros(isSelect?.balance.toFixed(viewDecimals))
						: isSelect?.balance}
				</p>
			</div>
			{/* )} */}
		</>
	);
};
