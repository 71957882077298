// import { useEffect } from 'react';
// import { useParams } from 'react-router';
// import { useDispatch, useSelector } from 'react-redux';
// import { IViewTransactionAprovementPayload } from 'redux/reducers/projectTransactions/types';
// import { getViewApprovement } from 'redux/reducers/projectTransactions/selectors';
// import { viewTransactionApprovemientRequest } from 'redux/reducers/projectTransactions/reducer';
import ApprovementDetails from 'components/Project/ProjectTransactions/TabsContent/ApprovementDetails/ApprovementDetails';
import { ProjectLayout } from 'layouts/ProjectLayout/ProjectLayout';

export const ProjectApprovementsDetailsPage = () => {
	// const dispatch = useDispatch();
	// const item = useSelector(getViewApprovement);
	// const { transactionId } = useParams<{ transactionId: string }>();
	// useEffect(() => {
	// 	const payload: IViewTransactionAprovementPayload = {
	// 		apiParams: { transaction_approvement_id: +transactionId },
	// 	};
	// 	dispatch(viewTransactionApprovemientRequest(payload));
	// }, [dispatch, transactionId]);
	return <ApprovementDetails />;
};
