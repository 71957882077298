import { useDispatch, useSelector } from 'react-redux';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
	getTotalBalancesInFiatRequest,
	addBankAccountRequest as addProviderBankAccountRequest,
} from 'redux/reducers/providerBankAccount/reducer';
import { notify } from 'utils/notify';
import {
	getBankAccountNotDeleted,
	getWalletsList,
} from 'redux/reducers/merchantBankAccount/selectors';
import {
	addWithdrawalAddressRequest,
	getWithdrawalAddressesByOwnerRequest,
	addBankAccountRequest as addMerchantBankAccountRequest,
	getBankAccountsByOwnerRequest,
} from 'redux/reducers/merchantBankAccount/reducer';
import { getProfile } from 'redux/reducers/merchantProfile/selectors';
import { getUserType } from 'redux/reducers/auth/selectors';
import {
	getProviderAllCurrencyRequest,
	getProviderAllCurrencySettingRequest,
} from 'redux/reducers/providerCurrency/reducer';
import { constUserTypes } from 'services/constants/user-types';
import { IAddCryptoWalletParams } from 'services/api/merchant/types';

import NoAccount from '../../Provider/BankAccountPage/NoAccount';
import CryptoWalletForm from './CryptoWallet/CryptoWalletForm';
import BankAccountsForm from './BankAccounts/BankAccountsForm';
import { TAddBankAccountFormValues } from './types';
import BankAccounts from './BankAccounts';
import CryptoWallet from './CryptoWallet';
import styles from './styles.module.scss';

const MerchantBankAccountPage: FC = () => {
	const dispatch = useDispatch();
	const bankList = useSelector(getBankAccountNotDeleted);
	const walletList = useSelector(getWalletsList);
	const { merchantId } = useParams<{ merchantId: string }>();
	const countItems = (bankList?.length || 0) + (walletList?.length || 0);
	const [showFilter, setShowFilter] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const userData = useSelector(getProfile);
	const userType = useSelector(getUserType);
	const isProvider = userType === constUserTypes.PROVIDER;

	const handleAddBankAccount = (values: TAddBankAccountFormValues, resetForm: () => void) => {
		const apiParams = isProvider
			? {
					...values,
					owner_merchant_id: +merchantId,
					is_provider: false,
			  }
			: values;
		const request = isProvider ? addProviderBankAccountRequest : addMerchantBankAccountRequest;
		resetForm();
		dispatch(
			request({
				apiParams,
				onSuccess: () => {
					setShowFilter(false);
					notify('Bank account has been successfully added', 'success');
					dispatch(getBankAccountsByOwnerRequest({ owner_merchant_id: +merchantId }));
				},
			}),
		);
	};
	const handleAddWallet = (values: IAddCryptoWalletParams, resetForm: () => void) => {
		const payload: IAddCryptoWalletParams = {
			currency_setting_id: values.currency_setting_id,
			name_address: values.name_address,
			address: values.address,
			is_crypto: 1,
		};
		if (userType === constUserTypes.PROVIDER)
			payload.owner_merchant_id = userData?.merchant.owner_merchant_id;
		dispatch(
			addWithdrawalAddressRequest({
				apiParams: payload,
				onSuccess: () => {
					setOpenModal(false);
					resetForm();
					notify('Wallet has been successfully added', 'success');
				},
			}),
		);
	};

	const getWithdrawalAddresses = useCallback(() => {
		userData?.merchant.owner_merchant_id &&
			userType === constUserTypes.PROVIDER &&
			dispatch(
				getWithdrawalAddressesByOwnerRequest({
					owner_merchant_id: userData.merchant.owner_merchant_id,
				}),
			);
		userType === constUserTypes.MERCHANT &&
			dispatch(getWithdrawalAddressesByOwnerRequest({ owner_merchant_id: merchantId }));
	}, [dispatch, merchantId, userData?.merchant?.owner_merchant_id, userType]);

	useEffect(() => {
		getWithdrawalAddresses();
		dispatch(getTotalBalancesInFiatRequest());
		dispatch(getProviderAllCurrencySettingRequest({ apiParams: {} }));
		dispatch(getProviderAllCurrencyRequest());
		dispatch(getBankAccountsByOwnerRequest({ owner_merchant_id: merchantId }));
	}, [dispatch, getWithdrawalAddresses, merchantId]);

	return (
		<div className={styles.main}>
			{countItems ? (
				<>
					<p className={styles.title}>Bank Accounts</p>
					<BankAccounts bankList={bankList} />
					<p className={styles.title}>Crypto Wallet</p>
					<CryptoWallet updateItems={getWithdrawalAddresses} />
				</>
			) : (
				<NoAccount setShowFilter={setShowFilter} setOpenModal={setOpenModal} />
			)}
			<BankAccountsForm
				setShowFilter={setShowFilter}
				showFilter={showFilter}
				onSubmit={handleAddBankAccount}
			/>
			<CryptoWalletForm
				setShowFilter={setOpenModal}
				showFilter={openModal}
				onSubmit={handleAddWallet}
			/>
		</div>
	);
};

export default MerchantBankAccountPage;
