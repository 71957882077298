import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getProviderCurrencyAll } from 'redux/reducers/providerCurrency/selectors';
import { getProviderAllCurrencySettingRequest } from 'redux/reducers/providerCurrency/reducer';

let isFetched = false;

export const useViewDecimals = () => {
	const dispatch = useDispatch();
	const currencies = useSelector(getProviderCurrencyAll);
	useEffect(() => {
		if (currencies.length === 0 && !isFetched) {
			isFetched = true;
			dispatch(getProviderAllCurrencySettingRequest({ apiParams: {} }));
		}
	}, [currencies.length, dispatch]);

	const getViewDecimal: (code: string, networkCode?: string) => number | undefined = (
		code,
		networkCode,
	) => {
		const result = currencies.filter(
			(item) =>
				item?.currency_code?.toUpperCase() === code?.toUpperCase() &&
				(networkCode ? item.currency_network === networkCode : true),
		)?.[0];
		return result?.view_decimals;
	};

	return { getViewDecimal };
};
