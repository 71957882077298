import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	TApiGetProjectMerchantTransactionResponse,
	TApiGetProjectTransactionsApprovementResponse,
	TApiGetProjectTransactionsResponse,
} from 'services/api/project/types';
import { downloadCSVFunc } from 'utils/downloadCSVFunc';
import {
	IDepositCryptoTransactionParams,
	TDepositCryptoTransactions,
} from 'services/api/provider/types';
import {
	getMerchantTransactionError,
	getMerchantTransactionRequest,
	getMerchantTransactionSuccess,
	getProjectTransactionsApprovementsError,
	getProjectTransactionsApprovementsRequest,
	getProjectTransactionsApprovementsSuccess,
	getProjectTransactionsError,
	getProjectTransactionsRequest,
	getProjectTransactionsSuccess,
	viewTransactionApprovemientRequest,
	viewTransactionApprovemientSuccess,
	viewTransactionApprovemientError,
	exportCsvProjectTransactionsRequest,
	exportCsvProjectTransactionsSuccess,
	exportCsvProjectTransactionsError,
	exportCsvProjectApprovementsRequest,
	exportCsvProjectApprovementsSuccess,
	exportCsvProjectApprovementsError,
	createReportTransactionProjectRequest,
	createReportTransactionProjectSuccess,
	createReportTransactionProjectError,
	createReportApprovementProjectRequest,
	createReportApprovementProjectSuccess,
	createReportApprovementProjectError,
	createFiatTransactionProjectRequest,
	createFiatTransactionProjectSuccess,
	getTransactionsFiatRequest,
	getTransactionsSuccess,
	getTransactionsError,
} from './reducer';
import {
	IMerchantGetProjectTransactionPayload,
	IMerchantGetProjectTransactionsApprovementPayload,
	IMerchantGetProjectTransactionsPayload,
	IViewTransactionAprovementPayload,
	IExportCsvTransactionsPayload,
	ICreateReportProjectTransactionPayload,
	ICreateFiatTransactionsPayload,
} from './types';

function* getProjectTransactionsWorker(
	action: PayloadAction<IMerchantGetProjectTransactionsPayload>,
) {
	const { apiParams, onSuccess } = action.payload;

	try {
		const data: TApiGetProjectTransactionsResponse = yield call(
			api.project.getProjectTransactions,
			apiParams,
		);
		yield put(getProjectTransactionsSuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(getProjectTransactionsError());
		showError(error);
	}
}
function* getProjectMerchantTransactionWorker(
	action: PayloadAction<IMerchantGetProjectTransactionPayload>,
) {
	const { apiParams, onSuccess, is_crypto } = action.payload;

	try {
		const data: TApiGetProjectMerchantTransactionResponse = yield call(
			api.project.getProjectMerchantTransactions,
			action.payload,
		);
		yield put(getMerchantTransactionSuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(getMerchantTransactionError());
		showError(error);
	}
}
function* getProjectTransactionsApprovementsWorker(
	action: PayloadAction<IMerchantGetProjectTransactionsApprovementPayload>,
) {
	const { apiParams, onSuccess } = action.payload;
	try {
		const data: TApiGetProjectTransactionsApprovementResponse = yield call(
			api.project.getProjectMerchantTransactionsApprovements,
			apiParams,
		);
		yield put(getProjectTransactionsApprovementsSuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(getProjectTransactionsApprovementsError());
		showError(error);
	}
}

function* viewTransactionApprovemientRequestWorker(
	action: PayloadAction<IViewTransactionAprovementPayload>,
) {
	const { apiParams, onSuccess } = action.payload;

	try {
		const data: TApiGetProjectMerchantTransactionResponse = yield call(
			api.project.viewTransactionAprovement,
			apiParams,
		);
		yield put(viewTransactionApprovemientSuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(viewTransactionApprovemientError());
		showError(error);
	}
}

function* exportCsvProjectTransactionsWorker(action: PayloadAction<IExportCsvTransactionsPayload>) {
	const { nameFile, apiParams, onSuccess } = action.payload;

	try {
		const data: string = yield call(api.project.exportCsvTransactions, action?.payload);
		yield put(exportCsvProjectTransactionsSuccess());
		downloadCSVFunc(nameFile, data);
		onSuccess?.();
	} catch (error) {
		yield put(exportCsvProjectTransactionsError());
		showError(error);
	}
}

function* exportCsvProjectApprovementsWorker(action: PayloadAction<IExportCsvTransactionsPayload>) {
	const { nameFile, apiParams, onSuccess } = action.payload;

	try {
		const data: string = yield call(api.project.exportCsvProjectApprovement, apiParams);
		yield put(exportCsvProjectApprovementsSuccess());
		downloadCSVFunc(nameFile, data);
		onSuccess?.();
	} catch (error) {
		yield put(exportCsvProjectApprovementsError());
		showError(error);
	}
}

function* createReportTransactionProjectWorker(
	action: PayloadAction<ICreateReportProjectTransactionPayload>,
) {
	const { apiParams, onSuccess } = action.payload;

	try {
		yield call(api.project.postReportsProviderTransaction, action.payload);
		yield put(createReportTransactionProjectSuccess());
		onSuccess?.();
	} catch (error) {
		yield put(createReportTransactionProjectError());
		showError(error);
	}
}

function* createReportApprovementProjectWorker(
	action: PayloadAction<ICreateReportProjectTransactionPayload>,
) {
	const { apiParams, onSuccess } = action.payload;

	try {
		yield call(api.project.postReportsProjectApprovement, apiParams);
		yield put(createReportApprovementProjectSuccess());
		onSuccess?.();
	} catch (error) {
		yield put(createReportApprovementProjectError());
		showError(error);
	}
}

function* createFiatTransactionProjectWorker(
	action: PayloadAction<ICreateFiatTransactionsPayload>,
) {
	const { apiParams, onSuccess } = action.payload;

	try {
		yield call(api.project.createFiatTransactions, apiParams);
		yield put(createFiatTransactionProjectSuccess());
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}
function* getTransactionsFiatWorker({ payload }: PayloadAction<IDepositCryptoTransactionParams>) {
	try {
		const response: TDepositCryptoTransactions = yield call(
			api.project.getProjectFiatTransaction,
			payload,
		);
		yield put(getTransactionsSuccess(response));
	} catch (error) {
		yield put(getTransactionsError());
		showError(error);
	}
}

export function* projectTransactionsSaga() {
	yield takeLatest(getProjectTransactionsRequest, getProjectTransactionsWorker);
	yield takeLatest(getMerchantTransactionRequest, getProjectMerchantTransactionWorker);
	yield takeLatest(
		getProjectTransactionsApprovementsRequest,
		getProjectTransactionsApprovementsWorker,
	);
	yield takeLatest(viewTransactionApprovemientRequest, viewTransactionApprovemientRequestWorker);
	yield takeLatest(exportCsvProjectTransactionsRequest, exportCsvProjectTransactionsWorker);
	yield takeLatest(exportCsvProjectApprovementsRequest, exportCsvProjectApprovementsWorker);
	yield takeLatest(createReportTransactionProjectRequest, createReportTransactionProjectWorker);
	yield takeLatest(createReportApprovementProjectRequest, createReportApprovementProjectWorker);
	yield takeLatest(createFiatTransactionProjectRequest, createFiatTransactionProjectWorker);
	yield takeLatest(getTransactionsFiatRequest, getTransactionsFiatWorker);
}
