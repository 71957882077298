import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	getProviderAnalyticsRequest,
	getProviderAnalyticsResponse,
	providerAnalyticsResetState,
} from './reducer';
import { IAnalyticsRequest, IAnalyticsResponse } from './types';

// import { notify } from '../../../utils/notify';

function* getProviderAnalyticsWorker({ payload }: PayloadAction<IAnalyticsRequest>) {
	try {
		const data: IAnalyticsResponse = yield call(api.project.getProviderAnalytics, payload);
		yield put(getProviderAnalyticsResponse(data));
	} catch (error) {
		showError(error);
		yield put(providerAnalyticsResetState());
	}
}

export function* providerAnalyticsSaga() {
	yield takeLatest(getProviderAnalyticsRequest, getProviderAnalyticsWorker);
}
