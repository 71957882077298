import { FC, useEffect, useState, useCallback, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import {
	getProviderAllCurrencySettingRequest,
	putProviderAllCurrencySettingRequest,
} from 'redux/reducers/providerCurrency/reducer';
import {
	TApiGetProviderAllCurrencySettingPayload,
	TApiPutProviderAllCurrencySettingPayload,
} from 'redux/reducers/providerCurrency/types';
import { SearchIcon } from 'assets/inline-svg';
import Field from 'v2Components/UI/Field';
import { notify } from 'utils/notify';
import { IInSubmitProps, IPropsTabs } from './types';
import style from './styles.module.scss';
import TableCrypto from './TableCrypto';
import TableFiat from './TableFiat';

const Balances: FC<IPropsTabs> = ({ currency }) => {
	const dispatch = useDispatch();
	const [search, setSearch] = useState('');
	const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);

	const isCrypto = currency === 'crypto';

	const fetchAllCurrencySettings = useCallback(() => {
		const payload: TApiGetProviderAllCurrencySettingPayload = {
			apiParams: {
				search_value: search === '' || search.length <= 1 ? undefined : search,
			},
		};
		dispatch(getProviderAllCurrencySettingRequest(payload));
	}, [dispatch, search]);

	const onSubmit = useCallback(
		(body: IInSubmitProps) => {
			const payload: TApiPutProviderAllCurrencySettingPayload = {
				apiParams: {
					...body,
				},
				onSuccess: () => {
					notify('CurrencySelected edit success', 'success');
					fetchAllCurrencySettings();
				},
			};
			dispatch(putProviderAllCurrencySettingRequest(payload));
		},
		[dispatch, fetchAllCurrencySettings],
	);

	useEffect(() => {
		fetchAllCurrencySettings();
	}, [fetchAllCurrencySettings, currency]);

	return (
		<>
			<Field
				value={search}
				onChange={onChangeSearch}
				placeholder="Search by currency and address"
				renderRightIcon={<SearchIcon />}
				classNameCustom={style.mv_3}
				widthInput={340}
				isDebounce
			/>
			{isCrypto ? <TableCrypto onSubmit={onSubmit} /> : <TableFiat />}
		</>
	);
};

export default Balances;
