import { ChangeEvent, useState } from 'react';
import { toUTC } from 'utils/dates';
import { CheckIcon, CopyIcon } from 'assets/inline-svg';
import { IProviderStatusRequest } from 'redux/reducers/providerApprovements/types';
import { putApprovementStatusRequest } from 'redux/reducers/providerApprovements/reducer';
import { shortNotation, capitalize, trimLastZeros } from 'utils/strings';
import { notify } from 'utils/notify';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { useDispatch } from 'react-redux';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import { Link } from 'react-router-dom';
import StatusText from 'UI/StatusText/StatusText';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IProps } from './types';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import { Actions } from '../Actions/Actions';
/* eslint-disable */

const SIZE_HASH = 3;

export default ({ item, checked, setChecked, updateApprovementRequset }: IProps): TdItem[] => {
	const {
		created_at,
		project_id,
		status,
		comments_count,
		date_status_change,
		status_changer_id,
		transaction_type,
		risk_score,
		hash_transaction,
		currency_code,
		amount,
		is_kyc_requested,
		id,
		owner_merchant_id,
		user_id,
		owner_merchant_nick_name,
	} = item;

	const dispatch = useDispatch();
	const [active, setActive] = useState<number | null>(null);
	const [isModal, setIsModal] = useState(false);

	const { getViewDecimal } = useViewDecimals();

	const viewDecimals = getViewDecimal(currency_code);

	const closeModal = () => setIsModal(false);
	const [isOpenModal, setIsOpenModal] = useState<string>('');
	const statusBy = (types: string) => {
		if (types === 'in_approvement') {
			return <p className="td-value green">New</p>;
		}
		if (types === 'blocked') {
			return <p className="td-value red">Blocked</p>;
		}

		return '---';
	};
	const [filters, setFilters] = useState({
		status: '',
		name: '',
	});

	const onEdit = (value: number | null) => {
		setActive(value);
	};

	const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
		if (!checked.includes(id)) {
			setChecked([...checked, id]);
			return;
		}
		const newArr = checked.filter((check: number) => String(check) !== String(id));
		setChecked(newArr);
	};

	const handleAddressCopy = () => notify('Address copied successfully!', 'info');

	const changeFilter = (value: string, name: string) => {
		setIsOpenModal(value);
		setFilters({ ...filters, status: value, name });
	};

	const onAction = (event: React.MouseEvent) => {
		const { name } = event.target as HTMLButtonElement;
		setIsOpenModal(name);
		setActive(null);
	};

	const closePopup = () => {
		setIsOpenModal('');
		setActive(null);
	};

	const handleConfirm = () => {
		if (filters.status === 'refound') return;
		const payloadData: IProviderStatusRequest = {
			params: { id, status: filters.status },
			onSuccess: () => {
				notify('make request', 'success');
				updateApprovementRequset();
			},
		};
		closeModal();
		setActive(null);
		dispatch(putApprovementStatusRequest(payloadData));
	};

	const detailsUrl = `/approvements/merchant/${id}`;
	const userLink = `/merchant/${String(owner_merchant_id)}/project/${String(
		project_id,
	)}/client/${String(user_id)}`;
	return [
		{
			title: '№',
			children: (
				<div className="flex flex-m  flex--gap-12">
					<div className="checkbox checkbox--mb-0">
						<label className="checkbox__label">
							<input
								onChange={handleChecked}
								checked={checked.includes(id)}
								type="checkbox"
								className="hidden"
							/>
							<span className="checkbox__item">
								<span className="checkbox__item-icon">
									<CheckIcon />
								</span>
							</span>
						</label>
					</div>
					<p>{id}</p>
				</div>
			),
		},
		{
			title: 'Tx ID',
			children: <Link to={detailsUrl}>{id}</Link>,
		},
		{
			title: 'User ID',
			children: userLink && (
				<Link to={userLink} className="link">
					{user_id}
				</Link>
			),
		},
		{
			title: 'Merchant ID',
			children: typeof owner_merchant_id === 'number' && (
				<Link to={`/merchant/${owner_merchant_id}`} className="link">
					{owner_merchant_nick_name}
				</Link>
			),
		},
		{
			title: 'Project',
			children: (
				<Link to={`/merchant/${owner_merchant_id}/project/${project_id}/settings/details`}>
					{project_id}
				</Link>
			),
		},
		{
			title: 'Date&Time',
			children: toUTC(String(created_at)),
		},
		{
			title: 'Currency',
			children: currency_code?.toLocaleUpperCase(),
		},
		{
			title: 'Type',
			children: capitalize(transaction_type || ''),
		},
		{
			title: 'Amount',
			children: trimLastZeros(amount.toFixed(viewDecimals)),
		},
		{
			title: 'Status',
			children: status && StatusText(status),
		},
		{
			title: 'Tx Hash',
			children: (
				<div className="table-hash">
					{hash_transaction && hash_transaction !== '-' && (
						<>
							<p className="td-value">
								{hash_transaction &&
									shortNotation(hash_transaction, SIZE_HASH, SIZE_HASH)?.toUpperCase()}
							</p>
							<CopyToClipboard text={String(hash_transaction)} onCopy={handleAddressCopy}>
								<button aria-label="button" type="button">
									<CopyIcon />
								</button>
							</CopyToClipboard>
						</>
					)}
				</div>
			),
		},
		{
			title: 'Details',
			children: (
				<>
					<Actions
						onEdit={onEdit}
						active={active}
						accountId={id}
						onSubmit={() => setIsModal(true)}
					/>
					<PopupModal open={isModal} onClose={closeModal}>
						<ConfirmPopup
							title="Confirm status change"
							body="Confirm this action to change status"
							onConfirm={handleConfirm}
							onClose={closeModal}
						/>
					</PopupModal>
				</>
			),
		},
	];
};
