import { MouseEventHandler, useEffect } from 'react';
import classNames from 'classnames';
import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { Floating } from 'v2Components/UI/Floating';
import { ISelectProps } from './types';
import styles from './styles.module.scss';

export const Select = <T extends Record<string, any>>({
	title,
	value,
	onChange,
	options,
	valueProp = 'value' as keyof T,
	labelProp = 'label' as keyof T,
	renderOption,
	renderSelected,
	placeholder,
	className = '',
	uppercase = false,
	disabled,
	role,
	helperNotification,
	isTable,
	isShowError,
	onClick,
	arrow = 'end',
}: ISelectProps<T>) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (_value: string | number) => {
		setOpen(false);
		onChange?.(String(_value)?.toLowerCase() === 'all' ? undefined : _value);
	};

	const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
		toggleOpen();
		onClick?.(e);
	};

	const selectedOption = options?.find((opt) => opt[valueProp] === value);

	let selectedLabel = selectedOption?.[labelProp];

	if (uppercase) {
		selectedLabel = selectedLabel?.toUpperCase();
	}

	// контроль за отображением дроп меню сверху/снизу нашего селекта
	useEffect(() => {
		const buttonRef = triggerRef.current;
		const setOptionsTop = async (e: PointerEvent) => {
			await new Promise((resolve) => {
				setTimeout(resolve, 0);
			});
			const buttonElement = e.target as HTMLButtonElement;
			const dropElement = buttonElement.nextElementSibling as HTMLDivElement;
			const showOptionTop =
				window.innerHeight - (buttonElement?.offsetHeight || 0) - e.clientY - 10 <
					dropElement?.offsetHeight && e.clientY > dropElement?.offsetHeight;
			if (showOptionTop) {
				dropElement?.classList.add(styles['select__drop--top']);
			} else {
				dropElement?.classList.add(styles['select__drop--bottom']);
			}
		};
		const deleteClasses: EventListenerOrEventListenerObject = () => {
			const dropClassList = buttonRef?.nextElementSibling.classList;
			if (dropClassList && dropClassList.value.includes(styles['select__drop--bottom'])) {
				dropClassList.remove(styles['select__drop--bottom']);
			}
			if (dropClassList && dropClassList.value.includes(styles['select__drop--top'])) {
				dropClassList.remove(styles['select__drop--top']);
			}
		};

		buttonRef.addEventListener('click', setOptionsTop);
		document.addEventListener('click', deleteClasses);
		return () => {
			buttonRef.removeEventListener('click', setOptionsTop);
			document.removeEventListener('click', deleteClasses);
		};
	});

	return (
		<div
			className={classNames(
				styles['select-block'],
				{
					[styles['select--disabled']]: disabled,
					[styles['select-block__table']]: isTable,
					[styles['select-block--error']]: isShowError,
				},
				className,
			)}
		>
			<Floating
				label={title || ''}
				className={classNames(styles.select, styles['select--type-choice'], {
					[styles.active]: open,
					[styles.error]: isShowError,
				})}
				classNameLabel={styles['select-block__title']}
			>
				<button
					disabled={disabled}
					type="button"
					className={classNames(styles.select__current, {
						[styles['select__current--placeholder']]: !selectedOption,
						[styles['select__current--arrow-start']]: arrow === 'start',
					})}
					ref={triggerRef}
					onClick={handleClick}
				>
					{arrow === 'start' && (
						<span
							className={classNames(
								styles['select__current-arrow'],
								styles['select__current-arrow--start'],
							)}
						>
							<TriangleArrowDownIcon2 />
						</span>
					)}
					{(selectedOption && renderSelected?.(selectedOption)) ||
						selectedLabel ||
						role ||
						placeholder ||
						'All'}
					{arrow === 'end' && (
						<span className={styles['select__current-arrow']}>
							<TriangleArrowDownIcon2 />
						</span>
					)}
				</button>
				<div className={styles.select__drop} ref={dropRef}>
					<div
						className={classNames(
							styles['select__drop-scroll'],
							styles['select__drop-scroll--type2'],
						)}
					>
						<div className={styles['select__drop-item']}>
							<ul className={styles['select__drop-list']}>
								{options?.map((option, index) => {
									const label = uppercase ? option[labelProp]?.toUpperCase() : option[labelProp];
									return (
										<li
											className={styles['select__drop-list--item']}
											key={option.id || String(option[valueProp]) + String(index)}
										>
											<button
												type="button"
												className={classNames({ [styles.active]: value === option[valueProp] })}
												onClick={() => handleSelect(option[valueProp])}
											>
												<span>{renderOption ? renderOption(option) : label}</span>
											</button>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</Floating>
			{helperNotification}
		</div>
	);
};
