import { FC } from 'react';
import * as yup from 'yup';
import L from 'i18n-react';
import { Formik, Form } from 'formik';
import { authPasswordRegexp } from 'utils/formValidation';
import { ILoginFormProps } from './types';
import { EmailField } from './EmailField/EmailField';
import { PasswordField } from './PasswordField/PasswordField';
import { TwoFaField } from './TwoFaField/TwoFaField';
import { LinksGroup } from './LinksGroup/LinksGroup';

export const LoginForm: FC<ILoginFormProps> = ({ onSubmit, onForgotPass }) => {
	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required(String(L.translate('Validation.email_required')))
			.email(String(L.translate('Validation.email_format'))),
	});

	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
				totp: '',
			}}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ isSubmitting }) => (
				<Form className="form form--authorization">
					<div className="form__header">
						<p className="form-title ">Log in Cryptonix</p>
					</div>

					<div className="form__body">
						<EmailField />
						<PasswordField />
						<TwoFaField />
						<LinksGroup onForgotPass={onForgotPass} />
					</div>

					<div className="form-footer form-footer--column">
						<button type="submit" className="button button--full-width" disabled={isSubmitting}>
							Log in
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};
