import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IProjectApi } from './types';

export const project: IProjectApi = {
	// Project settings
	getProjectDetails: (params) =>
		http.get(endpoint.project.GET_PROJECT_DETAILS, { params }).then(({ data }) => data),
	getProjectDetailsMerchant: (params) =>
		http.get(endpoint.project.GET_PROJECT_DETAILS_MERCHANT, { params }).then(({ data }) => data),
	changeProjectDetails: (params) =>
		http.post(endpoint.project.CHANGE_PROJECT_DETAILS, params).then(({ data }) => data),
	changeProjectDetailsMerchant: (params) =>
		http.post(endpoint.project.CHANGE_PROJECT_DETAILS_MERCHANT, params).then(({ data }) => data),
	getApi: (params) => http.get(endpoint.project.GET_API, { params }).then(({ data }) => data),
	getApiMerchant: (params) =>
		http.get(endpoint.project.GET_API_MERCHANT, { params }).then(({ data }) => data),
	createApiKey: (params) =>
		http.post(endpoint.project.CREATE_API_KEY, params).then(({ data }) => data),
	updateApiKey: (params) =>
		http.put(endpoint.project.UPDATE_API_KEY, params).then(({ data }) => data),
	deleteApiKey: ({ id }) =>
		http.delete(endpoint.project.DELETE_API_KEY(id)).then(({ data }) => data),
	saveCallbackUrl: (params) =>
		http.put(endpoint.project.SAVE_CALLBACK_URL, params).then(({ data }) => data),
	clearCallbackUrl: (params) =>
		http.post(endpoint.project.CLEAR_CALLBACK_URL, params).then(({ data }) => data),
	generateCallbacksecret: (params) =>
		http.put(endpoint.project.GENERATE_CALLBACK_SECRET, params).then(({ data }) => data),
	generateWidgetKey: (params) =>
		http.post(endpoint.project.GENERATE_WIDGET_KEY, params).then(({ data }) => data),
	generateWidgetKeyMerchant: (params) =>
		http.post(endpoint.project.GENERATE_WIDGET_KEY_MERCHANT, params).then(({ data }) => data),
	// TRANSACTIONS
	getProjectTransactions: (params) =>
		http.get(endpoint.project.GET_PROJECT_TRANSACTIONS, { params }).then(({ data }) => data),
	getProjectMerchantTransactions: ({ apiParams, is_crypto }) =>
		http
			.get(
				is_crypto
					? endpoint.project.GET_PROJECT_MERCHANT_TRANSACTION
					: endpoint.project.GET_VIEW_RELATED_MERCHANT_FIAT,
				{ params: apiParams },
			)
			.then(({ data }) => data),
	exportCsvTransactions: ({ apiParams, is_crypto }) =>
		http
			.post(
				is_crypto
					? endpoint.project.EXPORT_CSV_PROJECT_TRANSACTIONS
					: endpoint.project.EXPORT_CSV_PROJECT_TRANSACTIONS_FIAT,
				{ ...apiParams },
			)
			.then(({ data }) => data),
	createFiatTransactions: (params) =>
		http.post(endpoint.project.CREATE_FIAT_TRANSACTIONS, params).then(({ data }) => data),
	getProjectFiatTransaction: (params) =>
		http.get(endpoint.project.GET_MERCHANT_FIAT_TRANSACTION, { params }).then(({ data }) => data),
	// Index
	getProjectMerchantTransactionsApprovements: (params) =>
		http
			.get(endpoint.project.GET_PROJECT_MERCHANT_TRANSACTION_APPROVEMENTS, { params })
			.then(({ data }) => data),
	viewTransactionAprovement: (params) =>
		http.get(endpoint.project.VIEW_TRANSACTION_APROVEMENT, { params }).then(({ data }) => data),
	// Callbacks
	getCallbacks: (params) =>
		http.get(endpoint.project.GET_CALLBACKS, { params }).then(({ data }) => data),
	viewCallback: (params) => http.get(endpoint.project.VIEW_CALLBACK, { params }),
	// EXPORT CSV
	getProjectExportCSVTransaction: (params) =>
		http.post(endpoint.project.GET_CALLBACKS_CSV, { ...params }).then(({ data }) => data),
	resendCallback: (params) =>
		http.post(endpoint.project.RESEND_CALLBACK, params).then(({ data }) => data),
	// Finance settings
	editProviderProjectBalance: (params) =>
		http.put(endpoint.project.EDIT_PROVIDER_PROJECT_BALANCE, params).then(({ data }) => data),
	editMerchantProjectBalance: (params) =>
		http.put(endpoint.project.EDIT_MERCHANT_PROJECT_BALANCE, params).then(({ data }) => data),
	// ProjectWallets
	getProjectBalanceList: (params) =>
		http.get(endpoint.project.GET_PROJECT_BALANCE, { params }).then(({ data }) => data),
	getProjectMerchantBalanceList: (params) =>
		http.get(endpoint.project.GET_PROJECT_MERCHANT_BALANCE, { params }).then(({ data }) => data),
	editProjectBalance: (params) =>
		http.put(endpoint.project.EDIT_PROJECT_BALANCE, params).then(({ data }) => data),
	editProjectBalanceMerchant: (params) =>
		http.put(endpoint.project.EDIT_MERCHANT_PROJECT_BALANCE, params).then(({ data }) => data),
	getBalanceInDefaultCurrency: (params) =>
		http.get(endpoint.project.GET_BALANCE_IN_DEFAULT_CURRENCY, { params }).then(({ data }) => data),
	getCurrencies: () => http.get(endpoint.project.ALL_CURRENCY).then(({ data }) => data),
	createProjectTransfer: (params) =>
		http.post(endpoint.project.CREATE_PROJECT_TRANSFER, params).then(({ data }) => data),
	createProjectExchange: (params) =>
		http.post(endpoint.project.CREATE_PROJECT_EXCHANGE, params).then(({ data }) => data),
	createProjectAutoExchange: (params) =>
		http.put(endpoint.project.AUTO_PROJECT_EXCHANGE, params).then(({ data }) => data),
	createProjectWithdrawalExchange: (params) =>
		http.post(endpoint.project.CREATE_WITHDRAWAL_EXCHANGE, params).then(({ data }) => data),
	createProjectDepositExchange: (params) =>
		http.get(endpoint.project.CREATE_DEPOSIT_EXCHANGE, { params }).then(({ data }) => data),
	getExchangeRateFee: (params) =>
		http.post(endpoint.project.GET_EXCHANGE_RATE_FEE, params).then(({ data }) => data),
	getExchangeProviderRateFee: (params) =>
		http.get(endpoint.provider.GET_EXCHANGE_PROVIDER_RATE_FEE, { params }).then(({ data }) => data),
	getWithdrawalLimits: (params) =>
		http.get(endpoint.project.WITHDRAWAL_LIMITS, { params }).then(({ data }) => data),
	getWithdrawalFee: (params) =>
		http.get(endpoint.project.WITHDRAWAL_FEE, { params }).then(({ data }) => data),
	getCheckWithdrawal: (params) =>
		http.get(endpoint.project.CHECK_WITHDRAWAL, { params }).then(({ data }) => data),

	// Reports
	getReports: ({ params }) =>
		http.get(endpoint.project.GET_REPORTS, { params }).then(({ data }) => data),
	createReports: ({ type, ...rest }) => {
		const typeReportEndpoint = (typeTr: string) => {
			if (typeTr === 'transaction') {
				return endpoint.project.CREATE_TRANSACTION_REPORTS;
			}
			if (typeTr === 'balance') {
				return endpoint.project.CREATE_BALANCES_REPORTS;
			}
			return endpoint.project.CREATE_FLOW_REPORTS;
		};

		return http.post(typeReportEndpoint(type), rest);
	},
	postReportsProviderTransaction: ({ apiParams, is_crypto, report_format }) =>
		http
			.post(
				is_crypto
					? `${endpoint.project.CREATE_CRYPTO_TRANSACTIONS_REPORT}/${report_format || ''}`
					: `${endpoint.project.CREATE_FIAT_TRANSACTIONS_REPORT}/${report_format || ''}`,
				{ ...apiParams },
			)
			.then(({ data }) => data),
	postReportsProjectApprovement: (params) =>
		http.post(endpoint.project.CREATE_APPROVEMENT_REPORT, params).then(({ data }) => data),
	exportCsvProjectApprovement: (params) =>
		http.post(endpoint.project.EXPORT_CSV_TRANSACTION_APPROVEMENT, params).then(({ data }) => data),
	getProjectWalletAddresses: (params) =>
		http.get(endpoint.project.GET_WALLET_ADDRESSES, { params }).then(({ data }) => data),
	getProjectWalletAddressesByClient: (params) =>
		http.get(endpoint.project.GET_WALLET_ADDRESSES_CLIENT, { params }).then(({ data }) => data),
	getOrderMerchantLists: (params) =>
		http.get(endpoint.merchant.GET_ORDERS_LIST, { params }).then(({ data }) => data),
	getOrderMerchantDetails: (params) =>
		http.get(endpoint.merchant.GET_ORDER_DETAILS, { params }).then(({ data }) => data),
	createOrderMerchant: (params) =>
		http.post(endpoint.merchant.CREATE_ORDER, params).then(({ data }) => data),
	createOrderInvoiceMerchant: (params) =>
		http.post(endpoint.merchant.CREATE_INVOICE_ORDER, params).then(({ data }) => data),
	createOrderInvoiceProvider: (params) =>
		http.post(endpoint.provider.CREATE_INVOICE_ORDER, params).then(({ data }) => data),
	refundMerchant: (params) =>
		http.post(endpoint.merchant.REFOUND_ORDER, params).then(({ data }) => data),
	refundProvider: (params) =>
		http.post(endpoint.provider.REFOUND_ORDER, params).then(({ data }) => data),
	// Comissions
	getComissions: (params) =>
		http.get(endpoint.project.GET_COMISSIONS, { params }).then(({ data }) => data),
	getComissionsMerchant: (params) =>
		http.get(endpoint.project.GET_COMISSIONS_MERCHANT, { params }).then(({ data }) => data),
	putComissionsMerchant: (payload) =>
		http.put(endpoint.project.PUT_COMISSIONS_MERCHANT, payload).then(({ data }) => data),
	// Get Withdrawal
	getProviderWithdrawal: (params) => {
		return http.get(endpoint.project.GET_PROVIDER_WITHDRAWAL, { params }).then(({ data }) => data);
	},
	getProviderFiatWithdrawal: (params) => {
		return http
			.get(endpoint.project.GET_PROVIDER_WITHDRAWAL_FIAT, { params })
			.then(({ data }) => data);
	},
	putAppoveWithdrawal: async ({ params, is_crypto }) => {
		if (is_crypto) {
			return http
				.put(
					endpoint.project.PUT_APPROVE_WITHDRAWAL(
						String(params?.project_withdrawal_crypto_transaction_id),
						String(params?.amount),
					),
				)
				.then(({ data }) => data);
		}
		return http.put(endpoint.project.PUT_APPROVE_WITHDRAWAL_FIAT, params).then(({ data }) => data);
	},
	putRejectWithdrawal: async ({ params, is_crypto }) => {
		if (is_crypto) {
			return http
				.put(
					endpoint.project.PUT_REJECT_WITHDRAWAL(params?.project_withdrawal_crypto_transaction_id),
				)
				.then(({ data }) => data);
		}
		return http.put(endpoint.project.PUT_REJECT_WITHDRAWAL_FIAT, params).then(({ data }) => data);
	},
	putResendWithdrawal: async ({ params, is_crypto }) =>
		http.put(endpoint.project.PUT_RESEND_WITHDRAWAL, params).then(({ data }) => data),
	getWithdrawalDataFiat: (params) =>
		http.get(endpoint.project.GET_WITHDRAWAL_DATA_FIAT, { params }).then(({ data }) => data),
	// Analytics
	getMerchantAnalytics: (params) =>
		http.get(endpoint.project.GET_MERCHANT_ANALYTICS, { params }).then(({ data }) => data),
	getProviderAnalytics: (params) =>
		http.get(endpoint.project.GET_PROVIDER_ANALYTICS, { params }).then(({ data }) => data),
	postSmsAnalytics: (params) =>
		http.post(endpoint.project.POST_SMS_ANALYTICS, params).then(({ data }) => data),
	getDataToDepositFiat: (params) =>
		http.get(endpoint.project.POST_DATA_TO_DEPOSIT_FIAT, { params }).then(({ data }) => data),
};
