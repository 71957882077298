import { TransactionStatus } from 'models';

export const cryptoType = [
	{ label: 'All', value: 'all' },
	{ label: 'Deposit', value: 'deposit' },
	{ label: 'Payment', value: 'payment' },
	{ label: 'Withdrawal', value: 'withdrawal' },
	{ label: 'Auto withdrawal', value: 'auto_withdrawal' },
	{ label: 'Transfer', value: 'transfer' },
	{ label: 'Exchange', value: 'exchange' },
	{ label: 'Auto exchange', value: 'auto_exchange' },
	{ label: 'Refund', value: 'refund' },
];
export const fiatType = [
	{ label: 'All', value: 'all' },
	{ label: 'Deposit', value: 'deposit' },
	{ label: 'Withdrawal', value: 'withdrawal' },
	{ label: 'Transfer', value: 'transfer' },
	{ label: 'Exchange', value: 'exchange' },
	{ label: 'Auto exchange', value: 'auto_exchange' },
];
export const transactionStatus = [
	{ label: 'All', value: 'all' },
	{ label: 'Accepted', value: TransactionStatus.ACCEPTED },
	{ label: 'Approved', value: TransactionStatus.APPROVED },
	{ label: 'Blocked', value: TransactionStatus.BLOCKED },
	{ label: 'In approvement', value: TransactionStatus.IN_APPROVEMENT },
	{ label: 'In progress', value: TransactionStatus.IN_PROGRESS },
	{ label: 'Limit exceeded', value: TransactionStatus.LIMIT_EXCEEDED },
	{ label: 'Pending', value: TransactionStatus.PENDING },
	{ label: 'Rejected', value: TransactionStatus.REJECTED },
	{ label: 'Returned', value: TransactionStatus.RETURNED },
	{ label: 'Risk processing', value: TransactionStatus.RISK_PROCESSING },
	{ label: 'Success', value: TransactionStatus.SUCCESS },
	{ label: 'Unsuccessful', value: TransactionStatus.UNSUCCESSFUL },
];
