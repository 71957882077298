/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiProviderTransactionResponse,
	IProfitRequest,
	IProviderDepositResponse,
	TApiGetProviderAllCurrencyResponse,
	IGetCryptoWalletsByCurrencySettingParams,
	TCryptoWalletResponse,
	IShowWalletFiatTransactionHistoryParams,
	IShowWalletCryptoTransactionHistoryParams,
} from 'services/api/provider/types';
import { TApiGetProjectMerchantTransactionResponse } from 'services/api/project/types';
import {
	ICurrencyProfit,
	IProviderCurrencySettingStore,
	TApiGetDepositPayload,
	TApiGetProviderAllCurrencySettingPayload,
	TApiGetProviderAllCurrencySettingResponse,
	TApiGetProviderTransactionPayload,
	TApiPostProviderCreateWithdrawPayload,
	TApiPutProviderAllCurrencySettingPayload,
	TApiPutProviderAllCurrencySettingResponse,
} from './types';

export const initialState: IProviderCurrencySettingStore = {
	providerAllCurrency: [],
	providerAllCurrencyLoading: false,
	providerAllCurrencySetting: [],
	providerAllCurrencySettingLoading: false,
	putProviderAllCurrencySettingLoading: false,
	providerAllTransaction: null,
	providerAllProfit: null,
	putProviderTransactionLoading: false,
	postProviderCreateWithdraw: false,
	getProviderDeposit: null,
	getProviderDepositLoading: false,
	walletsByCurrencySettingsLoading: false,
	walletsByCurrencySettings: null,
	putProviderTransactionFiatLoading: false,
	putProviderTransactionFiat: null,
	transactionHistoryDetails: null,
};
const providerCurrencySetting = createSlice({
	name: '@@providerCurrencySetting',
	initialState,
	reducers: {
		getProviderAllCurrencyRequest: (state) => {
			state.providerAllCurrencyLoading = true;
		},
		getProviderAllCurrencySuccess: (
			state,
			action: PayloadAction<TApiGetProviderAllCurrencyResponse[]>,
		) => {
			state.providerAllCurrencyLoading = false;
			state.providerAllCurrency = action.payload;
		},
		getProviderAllCurrencySettingRequest: (
			state,
			_: PayloadAction<TApiGetProviderAllCurrencySettingPayload>,
		) => {
			state.providerAllCurrencySettingLoading = true;
		},
		getProviderAllCurrencySettingRequestSuccess: (
			state,
			action: PayloadAction<TApiGetProviderAllCurrencySettingResponse[]>,
		) => {
			state.providerAllCurrencySettingLoading = false;
			state.providerAllCurrencySetting = action.payload;
		},
		putProviderAllCurrencySettingRequest: (
			state,
			_: PayloadAction<TApiPutProviderAllCurrencySettingPayload>,
		) => {
			state.providerAllCurrencySettingLoading = true;
		},
		putProviderAllCurrencySettingRequestSuccess: (
			state,
			action: PayloadAction<TApiPutProviderAllCurrencySettingResponse>,
		) => {
			const result = state.providerAllCurrencySetting.map(
				(obj: TApiGetProviderAllCurrencySettingResponse) => {
					return obj.id === action.payload.id ? { ...obj, ...action.payload } : obj;
				},
			);
			state.providerAllCurrencySettingLoading = false;
			state.providerAllCurrencySetting = result;
		},
		/// ///////////////////////////////// get-provider transaction
		getProviderTransactionRequest: (state, _: PayloadAction<TApiGetProviderTransactionPayload>) => {
			state.putProviderTransactionLoading = true;
		},
		getProviderTransactionSuccess: (
			state,
			action: PayloadAction<IApiProviderTransactionResponse>,
		) => {
			state.putProviderTransactionLoading = false;
			state.providerAllTransaction = action.payload;
		},
		getProviderTransactionFiatRequest: (
			state,
			_: PayloadAction<TApiGetProviderTransactionPayload>,
		) => {
			state.putProviderTransactionFiatLoading = true;
		},
		getProviderTransactionFiatSuccess: (
			state,
			action: PayloadAction<IApiProviderTransactionResponse>,
		) => {
			state.putProviderTransactionFiatLoading = false;
			state.putProviderTransactionFiat = action.payload;
		},
		getProviderTransactionFiatError: (state) => {
			state.putProviderTransactionFiatLoading = false;
		},
		getProviderProfitRequest: (state, _: PayloadAction<IProfitRequest>) => {
			state.putProviderTransactionLoading = true;
		},
		getProviderProfitSuccess: (state, action: PayloadAction<Array<ICurrencyProfit>>) => {
			state.putProviderTransactionLoading = false;
			state.providerAllProfit = action.payload;
		},
		/// ///////////////////////////////// create withdrawal
		getProviderCreateWithdrawalRequest: (
			state,
			_: PayloadAction<TApiPostProviderCreateWithdrawPayload>,
		) => {
			state.postProviderCreateWithdraw = true;
		},
		getProviderCreateWithdrawalSuccess: (state) => {
			state.postProviderCreateWithdraw = false;
		},
		/// ///////////////////////////////// create deposit
		getProviderDepositRequest: (state, _: PayloadAction<TApiGetDepositPayload>) => {
			state.getProviderDepositLoading = true;
		},
		getProviderDepositSuccess: (state, action: PayloadAction<IProviderDepositResponse>) => {
			state.getProviderDepositLoading = false;
			state.getProviderDeposit = action.payload;
		},
		getCryptoWalletsByCurrencyRequest: (
			state,
			action: PayloadAction<IGetCryptoWalletsByCurrencySettingParams>,
		) => {
			state.walletsByCurrencySettingsLoading = true;
		},
		getCryptoWalletsByCurrencySuccess: (
			state,
			{ payload }: PayloadAction<TCryptoWalletResponse>,
		) => {
			state.walletsByCurrencySettingsLoading = false;
			state.walletsByCurrencySettings = payload;
		},
		getCryptoWalletsByCurrencyError: (state) => {
			state.walletsByCurrencySettingsLoading = false;
		},
		showTransactionHistoryFiatRequest: (
			state,
			action: PayloadAction<IShowWalletFiatTransactionHistoryParams>,
		) => state,
		showTransactionHistoryFiatSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetProjectMerchantTransactionResponse>,
		) => {
			state.transactionHistoryDetails = payload;
		},
		showTransactionHistoryCryptoRequest: (
			state,
			action: PayloadAction<IShowWalletCryptoTransactionHistoryParams>,
		) => state,
		showTransactionHistoryCryptoSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetProjectMerchantTransactionResponse>,
		) => {
			state.transactionHistoryDetails = payload;
		},
	},
});

export default providerCurrencySetting.reducer;
export const {
	getProviderAllCurrencyRequest,
	getProviderAllCurrencySuccess,
	getProviderAllCurrencySettingRequest,
	getProviderAllCurrencySettingRequestSuccess,
	putProviderAllCurrencySettingRequest,
	putProviderAllCurrencySettingRequestSuccess,
	getProviderTransactionRequest,
	getProviderTransactionSuccess,
	getProviderCreateWithdrawalRequest,
	getProviderCreateWithdrawalSuccess,
	getProviderDepositRequest,
	getProviderDepositSuccess,
	getProviderProfitRequest,
	getProviderProfitSuccess,
	getCryptoWalletsByCurrencyRequest,
	getCryptoWalletsByCurrencySuccess,
	getCryptoWalletsByCurrencyError,
	getProviderTransactionFiatRequest,
	getProviderTransactionFiatSuccess,
	getProviderTransactionFiatError,
	showTransactionHistoryFiatRequest,
	showTransactionHistoryFiatSuccess,
	showTransactionHistoryCryptoRequest,
	showTransactionHistoryCryptoSuccess,
} = providerCurrencySetting.actions;
