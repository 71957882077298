import { ChangeEvent, useCallback, useEffect, useMemo, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProviderCurrencyCrypto } from 'redux/reducers/providerCurrency/selectors';
import { TextField } from 'v2Components/formComponents/TextField';
import { Formik } from 'formik';
import Button from 'v2Components/UI/Button';
import { getCheckAddressSelector } from 'redux/reducers/merchantAccounts/selectors';
import debounce from 'lodash.debounce';
import FormSideModal from 'v2Components/layoutsComponents/FormSideModal';
import { TSelectElement } from 'UI/CurrencySettingSelect/CurrencySettingSelect';
import { ICheckWithdrawlRequest } from 'redux/reducers/merchantAccounts/types';
import { ICryptoMerchantWalletItem, ICryptoWalletItem } from 'services/api/merchant/types';
import { getProviderAllCurrencySettingRequest } from 'redux/reducers/providerCurrency/reducer';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { DefaultToken, Token } from 'v2Components/UI/Token';
import { IEditCryptoWalletFormValues, TEditWalletFormOnSubmit } from '../../types';
import styles from './styles.module.scss';
import { addWalletSchema } from '../../BankAccounts/utils';

interface ICryptoWalletEditProps {
	item: ICryptoMerchantWalletItem;
	showFilter: boolean;
	setShowFilter: (value: boolean) => void;
	onSubmit: TEditWalletFormOnSubmit;
}

const CryptoWalletEdit: FC<ICryptoWalletEditProps> = ({
	item,
	showFilter,
	setShowFilter,
	onSubmit,
}) => {
	const dispatch = useDispatch();
	const currencyList = useSelector(getProviderCurrencyCrypto);
	const isValidAddress = useSelector(getCheckAddressSelector);
	const [activCurrency, setActivCurrency] = useState<string | number>();

	const initialValues: IEditCryptoWalletFormValues = {
		currency_setting_id: item.currency_setting?.currency?.id,
		name_address: item.name_address,
		address: item.address,
		currency_network: item.currency_setting?.currency_network_name,
	};

	const checkWalletAddressDebounced = useMemo(
		() =>
			debounce((address: string) => {
				const selectedCurrency = currencyList.find(({ id }) => id === item.currency_setting_id);
				const { currency_network } = selectedCurrency || {};
			}, 500),
		[currencyList, item.currency_setting_id],
	);

	const checkWalletAddress = useCallback(
		(address: string) => checkWalletAddressDebounced(address),
		[checkWalletAddressDebounced],
	);

	useEffect(() => {
		if (!currencyList.length) {
			dispatch(getProviderAllCurrencySettingRequest({ apiParams: {} }));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencyList, dispatch, activCurrency]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={addWalletSchema}
			onSubmit={onSubmit}
			enableReinitialize
		>
			{({ resetForm, setFieldValue, values }) => {
				const checkAddressRequest = debounce((payload: ICheckWithdrawlRequest) => {}, 500);

				const checkAddress = (address: string, currency_setting_id: number) => {
					const selectedCurrency = currencyList.find((_item) => _item.id === currency_setting_id);
					const { currency_network } = selectedCurrency || {};
					if (!currency_network) return;
					address &&
						currency_setting_id &&
						checkAddressRequest({
							address,
							currency_id: currency_setting_id,
							currency_network,
						});
				};

				const handleAddressChange = (event: ChangeEvent) => {
					const { value } = event.target as HTMLInputElement;
					setFieldValue('address', value);
					const { currency_setting_id } = values;
					currency_setting_id && checkAddress(value, +currency_setting_id);
				};

				const handleCurrencyChange = (_item: TSelectElement | null) => {
					const { address } = values;
					const { id } = _item || {};
					id && address && checkAddress(address, id);
				};

				return (
					<FormSideModal
						className={styles.accounts__drawer}
						onActionDrawer={resetForm}
						title="Edit Wallet"
						show={showFilter}
						setShow={setShowFilter}
						footer={
							<>
								<Button
									size="regular"
									width="100%"
									type="button"
									className={styles.cancel}
									onClick={() => setShowFilter(false)}
								>
									Cancel
								</Button>
								<Button size="regular" width="100%" type="submit">
									Change
								</Button>
							</>
						}
					>
						<SelectField
							disabled
							title="Currency"
							name="currency_setting_id"
							options={[...currencyList]}
							valueProp="id"
							renderSelected={(selectedValue) =>
								(selectedValue && (
									<Token
										code={
											selectedValue.currency_code === 'All'
												? selectedValue.currency_code
												: (selectedValue.currency_code || '').toUpperCase()
										}
										name={selectedValue.currency_network_name}
									/>
								)) || <DefaultToken />
							}
							renderOption={({ currency_code, img_path, currency_network_name }) => (
								<Token
									code={
										currency_code === 'All' ? currency_code : (currency_code || '').toUpperCase()
									}
									name={currency_network_name}
								/>
							)}
						/>
						<TextField name="address" label="Address" placeholder="Enter wallet address" />
						<TextField disabled name="currency_network" label="Network" />
						<TextField name="name_address" label="Nickname" placeholder="Enter nickname" />
					</FormSideModal>
				);
			}}
		</Formik>
	);
};

export default CryptoWalletEdit;
