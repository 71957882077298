import { Link } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import StatusText from 'UI/StatusText/StatusText';
import { useParams } from 'react-router';
import { CopyIcon } from 'assets/inline-svg';
import { capitalize, shortNotation, trimBottomLine, trimLastZeros } from 'utils/strings';
import { notify } from 'utils/notify';
import { useViewDecimals } from 'hooks/useViewDecimals';
import DateFormat from 'v2Components/commomComponents/DateFormat';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import LinkDetails from 'v2Components/commomComponents/Table/Td/LinkDetails';
import { IProps } from './types';

const TableRow = ({ item, number }: IProps): TdItem[] => {
	const { merchantId, projectId } = useParams<{ merchantId: string; projectId: string }>();
	const url_string = window.location.href;
	const urlObg = new URL(url_string);
	const tabsUrl = urlObg.searchParams.get('tabs');
	const handleAddressCopy = () => notify('Address copied successfully!', 'info');
	const {
		id,
		user_id,
		created_at,
		type,
		currency_name,
		currency_code,
		currency_network,
		amount,
		status,
		hash_transaction,
		operation_sign,
	} = item;

	const { getViewDecimal } = useViewDecimals();

	const viewDecimals = getViewDecimal(currency_code, currency_network);

	const detailsUrl = `/merchant/${merchantId}/project/${projectId}/transaction/details/${id}/${
		tabsUrl || 'crypto'
	}/${type}`;

	const clientUrl = `/merchant/${merchantId}/project/${projectId}/client/${user_id}`;

	const typeFormatted = [
		'refund_payment',
		'refund_deposit_project',
		'refund_deposit_external',
	].includes(String(type))
		? 'Refund'
		: type;
	const amountColor = !!operation_sign && (operation_sign === '+' ? 'green' : 'red');

	return [
		{
			title: '№',
			children: number,
		},
		{
			title: 'Tx ID',
			children: <Link to={detailsUrl}>{id}</Link>,
		},
		{
			title: 'User ID',
			children: !!user_id && <Link to={clientUrl}>{user_id}</Link>,
		},
		{
			title: 'Date&Time',
			children: <DateFormat created_at={created_at} />,
		},
		{
			title: 'Type',
			children: capitalize(trimBottomLine(typeFormatted)),
		},
		{
			title: 'Currency',
			children: currency_name,
		},
		{
			title: 'Amount',
			children: (
				<span className={amountColor || ''}>{trimLastZeros(amount.toFixed(viewDecimals))}</span>
			),
		},
		{
			title: 'Status',
			children: StatusText(status || '-'),
		},
		{
			title: 'TX Hash',
			children: (
				<div className="copy-address copy-address--type2">
					{hash_transaction && hash_transaction !== '-' && (
						<>
							<p className="td-value flex flex-m">
								<span className="tooltip-item">
									{shortNotation(String(hash_transaction) || '', 6, 6)}
									<span
										style={{ wordWrap: 'break-word' }}
										className="tooltip tooltip--width-320 tooltip--top tooltip--center tooltip--arrow-bottom-center"
									>
										<span className="tooltip__text tooltip__text--ta-c">
											<span>{hash_transaction}</span>
										</span>
									</span>
								</span>
							</p>
							<CopyToClipboard text={String(hash_transaction)} onCopy={handleAddressCopy}>
								<button aria-label="button" type="button">
									<CopyIcon />
								</button>
							</CopyToClipboard>
						</>
					)}
				</div>
			),
		},
		{
			title: 'Details',
			children: <LinkDetails detailsUrl={detailsUrl} />,
		},
	];
};

export default TableRow;
