import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IIProviderApprovementsResponse,
	IProviderApprovementsRequest,
	IProviderKycRequest,
	IProviderRefoundRequest,
	IProviderStatusRequest,
	IProviderCommentRequest,
	IProviderApprovementsDetails,
} from './types';

import {
	getApprovementRequest,
	getApprovementSuccess,
	getApprovementError,
	getApprovementProviderRequest,
	getApprovementProviderSuccess,
	getApprovementInitial,
	putApprovementKYCRequest,
	putApprovementRefoundRequest,
	putApprovementStatusRequest,
	getApprovementDetailsSuccess,
	postApprovementCommentRequest,
	getApprovementDetailsRequest,
	getApprovementDefaultRequest,
	getApprovementDefaultSuccess,
	getApprovementProviderDefaultRequest,
	getApprovementProviderDefaultSuccess,
} from './reducer';

function* getProviderApprovementMerchantWorker({
	payload,
}: PayloadAction<IProviderApprovementsRequest>) {
	const { params } = payload;
	try {
		const data: IIProviderApprovementsResponse = yield call(
			api.provider.getProviderApprovementsMerchant,
			params,
		);

		yield put(getApprovementSuccess(data));
		// yield put(getApprovementDefaultRequest({ params: { current_page: 1, per_page: 15 } }));
		// yield put(getApprovementProviderDefaultRequest({ params: { current_page: 1, per_page: 15 } }));
	} catch (error) {
		getApprovementError();
		showError(error);
		getApprovementInitial();
	}
}
function* getProviderApprovementDefaultMerchantWorker({
	payload,
}: PayloadAction<IProviderApprovementsRequest>) {
	const { params } = payload;
	try {
		const data: IIProviderApprovementsResponse = yield call(
			api.provider.getProviderApprovementsMerchant,
			params,
		);
		// const dataSec: IIProviderApprovementsResponse = yield call(
		// 	api.provider.getProviderApprovementsProvider,
		// 	params,
		// );
		// yield put(getApprovementProviderDefaultSuccess(dataSec));
		yield put(getApprovementDefaultSuccess(data));
	} catch (error) {
		getApprovementError();
		showError(error);
	}
}
function* getApprovementsProviderWorker({ payload }: PayloadAction<IProviderApprovementsRequest>) {
	const { params } = payload;
	try {
		const data: IIProviderApprovementsResponse = yield call(
			api.provider.getProviderApprovementsProvider,
			params,
		);

		yield put(getApprovementProviderSuccess(data));
		// yield put(getApprovementDefaultRequest({ params: { current_page: 1, per_page: 15 } }));
		// yield put(getApprovementProviderDefaultRequest({ params: { current_page: 1, per_page: 15 } }));
	} catch (error) {
		getApprovementError();
		showError(error);
		getApprovementInitial();
	}
}

function* putApprovementKYCWorker({ payload }: PayloadAction<IProviderKycRequest>) {
	const { onSuccess, transaction_approvement_ids } = payload;
	try {
		yield call(api.provider.putRequestKYCProvider, {
			transaction_approvement_ids,
		});
		onSuccess?.();
		// yield put(getApprovementProviderSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* putApprovementRefoundWorker({ payload }: PayloadAction<IProviderRefoundRequest>) {
	const { onSuccess, onError, params } = payload;
	try {
		yield call(api.provider.putRequestProviderRefound, params);
		onSuccess?.();
	} catch (error) {
		showError(error);
		onError?.();
	}
}

function* putApprovementStatusWorker({ payload }: PayloadAction<IProviderStatusRequest>) {
	const { onSuccess, params } = payload;
	try {
		yield call(api.provider.putRequestProviderStatus, params);
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* getApprovementDetailsWorker({ payload }: PayloadAction<string>) {
	try {
		const response: IProviderApprovementsDetails = yield call(
			api.provider.getRequestProviderApprovement,
			payload,
		);
		yield put(getApprovementDetailsSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* postApprovementCommentWorker({ payload }: PayloadAction<IProviderCommentRequest>) {
	const { onSuccess, payloadApi } = payload;
	try {
		yield call(api.provider.postProviderCommentApprovement, payloadApi);
		onSuccess?.();
		// yield put(getApprovementDetailsSuccess(response));
	} catch (error) {
		showError(error);
	}
}

export function* providerApprovementsSaga() {
	yield takeLatest(getApprovementRequest, getProviderApprovementMerchantWorker);
	yield takeLatest(getApprovementProviderRequest, getApprovementsProviderWorker);
	yield takeLatest(putApprovementKYCRequest, putApprovementKYCWorker);
	yield takeLatest(putApprovementRefoundRequest, putApprovementRefoundWorker);
	yield takeLatest(putApprovementStatusRequest, putApprovementStatusWorker);
	yield takeLatest(getApprovementDetailsRequest, getApprovementDetailsWorker);
	yield takeLatest(postApprovementCommentRequest, postApprovementCommentWorker);
	yield takeLatest(
		[getApprovementDefaultRequest, getApprovementProviderDefaultRequest],
		getProviderApprovementDefaultMerchantWorker,
	);
}
