import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAddBankAccountPayload,
	IBankAccountItem,
	IEditBankAccountPayload,
	TBankAccountResponse,
	IDeleteBankAccountPayload,
	IEditBankAccountInMerchantPayload,
	TCryptoWalletResponse,
	IAddCryptoWalletPayload,
	IEditCryptoWalletPayload,
	ICryptoWalletItem,
	IDelCryptoWalletPayload,
	TGetTotalBalancesInFiatResponse,
} from 'services/api/provider/types';

import { IProviderBankAccountStore } from './types';

export const initialState: IProviderBankAccountStore = {
	bankAccountList: null,
	cryptoWalletList: null,
	balancesInFiat: null,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

const providerBankAccount = createSlice({
	name: '@@providerBankAccount',
	initialState,
	reducers: {
		getBankAccountsRequest: (state) => state,
		getBankAccountsSuccess: (state, { payload }: PayloadAction<TBankAccountResponse>) => {
			state.bankAccountList = payload;
		},
		addBankAccountRequest: (state, _: PayloadAction<IAddBankAccountPayload>) => state,
		editBankAccountRequect: (state, _: PayloadAction<IEditBankAccountPayload>) => state,
		editBankAccountSuccess: (state, { payload }: PayloadAction<IBankAccountItem>) => {
			const result = state.bankAccountList?.map((el) =>
				el.id === payload.id ? { ...el, ...payload } : el,
			);
			if (result) {
				state.bankAccountList = result;
			}
		},
		deleteBankAccountRequest: (state, _: PayloadAction<IDeleteBankAccountPayload>) => state,
		editBankAccountInMerchantRequest: (
			state,
			_: PayloadAction<IEditBankAccountInMerchantPayload>,
		) => state,

		getCryptoWalletsRequest: (state) => state,

		getCryptoWalletsSuccess: (state, { payload }: PayloadAction<TCryptoWalletResponse>) => {
			state.cryptoWalletList = payload;
		},
		addCryptoWalletRequest: (state, _: PayloadAction<IAddCryptoWalletPayload>) => state,
		editCryptoWalletRequest: (state, _: PayloadAction<IEditCryptoWalletPayload>) => state,
		editCryptoWalletSuccess: (state, { payload }: PayloadAction<ICryptoWalletItem>) => {
			const result = state.cryptoWalletList?.map((el) =>
				el.id === payload.id ? { ...el, ...payload } : el,
			);
			if (result) {
				state.cryptoWalletList = result;
			}
		},
		deleteCryptoWalletRequest: (state, _: PayloadAction<IDelCryptoWalletPayload>) => state,
		getTotalBalancesInFiatRequest: (state) => state,
		getTotalBalancesInFiatSuccess: (
			state,
			{ payload }: PayloadAction<TGetTotalBalancesInFiatResponse>,
		) => {
			state.balancesInFiat = payload;
		},
	},
});

export default providerBankAccount.reducer;
export const {
	getBankAccountsRequest,
	getBankAccountsSuccess,
	addBankAccountRequest,
	editBankAccountRequect,
	editBankAccountSuccess,
	deleteBankAccountRequest,
	editBankAccountInMerchantRequest,
	getCryptoWalletsRequest,
	getCryptoWalletsSuccess,
	addCryptoWalletRequest,
	editCryptoWalletRequest,
	editCryptoWalletSuccess,
	deleteCryptoWalletRequest,
	getTotalBalancesInFiatRequest,
	getTotalBalancesInFiatSuccess,
} = providerBankAccount.actions;
