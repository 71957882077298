import { PopUpCloseIcon } from 'assets/inline-svg';
import { ChangeEvent, FC, FocusEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCheckWithdrawlRequest,
	getWithdrawlFeeRequest,
	getWithdrawlFeeRespose,
	getWithdrawlLimitsRequest,
} from 'redux/reducers/merchantAccounts/reducer';
import {
	getCheckAddressSelector,
	getWithdrawlFeeSelector,
	getWithdrawlLimitsSelector,
} from 'redux/reducers/merchantAccounts/selectors';
import { IProjectBalanceResponse, ITransferRequest } from 'redux/reducers/merchantAccounts/types';
import { EButtonName } from 'services/constants/account-btn';
import ErrorMsg from 'UI/ErrorMsg';
import { trimLastZeros } from 'utils/strings';
import { isExist } from 'utils/numbers';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { IPropsPopUps } from '../../../TableRowCrypto/types';
import { SelectDropCoin } from '../../PopUpTransfer/SelectDropCoin/SelectDropCoin';
import { WithdrawalConfirm } from './WithdrawalConfirm/WithdrawalConfirm';
import { WithdrawalCryptoInfo } from './WithdrawalCryptoInfo/WithdrawalCryptoInfo';

export const PopUpWithdrawalCrypto: FC<IPropsPopUps> = ({
	onPopUps,
	currencies,
	popUpOpen,
	accountId,
}) => {
	const dispatch = useDispatch();
	const [amount, setAmount] = useState<string>('');
	const [address, setAddress] = useState<string>('');
	const [data, setData] = useState<ITransferRequest>();
	const [openConfirm, setOpenConfirm] = useState<boolean>(false);
	const limits = useSelector(getWithdrawlLimitsSelector);
	const fee = useSelector(getWithdrawlFeeSelector);
	const isValidAddress = useSelector(getCheckAddressSelector);
	const [addressError, setAddressError] = useState<boolean>(false);
	const [isSelect, setIsSelect] = useState<IProjectBalanceResponse | null>(
		currencies?.find((coin) => coin.id === accountId) || null,
	);

	const { getViewDecimal } = useViewDecimals();

	const viewDecimals = isSelect?.currency.code
		? getViewDecimal(isSelect?.currency.code)
		: undefined;

	const onData = (values: ITransferRequest) => {
		setData({ ...data, ...values });
	};

	const handleButton = (event: MouseEvent) => {
		const target = event.currentTarget as HTMLButtonElement;
		onPopUps(target.name);
	};

	const handleInputs = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setAddressError(false);
		name === 'address' ? setAddress(value) : setAmount(value);

		!value && dispatch(getWithdrawlFeeRespose(null));

		if (name === 'address' && value && isSelect?.id) {
			dispatch(
				getCheckWithdrawlRequest({
					paramsApi: {
						address: value,
						currency_id: +isSelect.currency_id,
						currency_network: isSelect.currency_network,
					},
					setAddressError,
				}),
			);
			// dispatch(
			// 	getWithdrawlFeeRequest({
			// 		project_balance_id: +isSelect.id,
			// 		amount: +value,
			// 		// address: value,
			// 	}),
			// );
		} else {
			isSelect?.id &&
				+value &&
				name === 'amount' &&
				address &&
				isValidAddress &&
				dispatch(
					getWithdrawlFeeRequest({
						project_balance_id: +isSelect.id,
						amount: +value,
						address,
					}),
				);
		}
		// if (name === 'amount' && +value && isSelect?.id) {
		// 	dispatch(
		// 		getWithdrawlFeeRequest({
		// 			project_balance_id: +isSelect.id,
		// 			amount: +value,
		// 			// address: value,
		// 		}),
		// 	);
		// } else {
		// 	isSelect &&
		// 		value &&
		// 		dispatch(
		// 			getCheckWithdrawlRequest({
		// 				paramsApi: {
		// 					address: value,
		// 					currency_id: +isSelect.currency_id,
		// 					currency_network: isSelect.currency_network,
		// 				},
		// 				setAddressError,
		// 			}),
		// 		);
		// }
	};

	const maxBtn = (value: string) => {
		setAmount(value);
		isSelect?.id &&
			dispatch(
				getWithdrawlFeeRequest({
					project_balance_id: isSelect.id,
					amount: +value,
				}),
			);
	};

	const tryReceived =
		fee?.platform_fee &&
		fee?.network_fee &&
		amount &&
		Number(trimLastZeros(Number(fee?.platform_fee).toFixed(viewDecimals))) +
			Number(trimLastZeros(Number(fee?.network_fee).toFixed(viewDecimals))) >=
			Number(trimLastZeros(Number(amount).toFixed(viewDecimals)))
			? 'The commission amount exceeds the withdrawal amount. Please enter another amount'
			: '';

	const isValidImputs = () => {
		return (
			limits &&
			address &&
			amount &&
			isValidAddress &&
			limits.single_limit_used + +amount <= limits.single_limit &&
			limits.daily_limit_used + +amount <= limits.daily_limit &&
			limits.monthly_limit_used + +amount <= limits.monthly_limit &&
			!tryReceived
		);
	};

	useEffect(() => {
		isSelect?.id && dispatch(getWithdrawlLimitsRequest({ project_balance_id: isSelect.id }));
		amount &&
			isSelect &&
			dispatch(
				getWithdrawlFeeRequest({
					project_balance_id: +isSelect.id,
					amount: +amount,
				}),
			);

		isSelect &&
			address &&
			dispatch(
				getCheckWithdrawlRequest({
					paramsApi: {
						address,
						currency_id: +isSelect.currency_id,
						currency_network: isSelect.currency_network,
					},
				}),
			);
		return () => {
			document.body.style.overflow = '';
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, isSelect]);

	useEffect(() => {
		return () => {
			dispatch(getWithdrawlFeeRespose(null));
		};
	}, [dispatch]);

	const disableScroll = useCallback((e: WheelEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);
	const handleInputFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.addEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInputBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.removeEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		const regex = /^[0-9.,]*$/;
		const input = event.key;
		if (!regex.test(input)) {
			event.preventDefault();
		}
	};

	return openConfirm && limits ? (
		<WithdrawalConfirm
			setOpenConfirm={setOpenConfirm}
			onPopUps={onPopUps}
			limits={limits}
			amount={amount}
			isSelect={isSelect}
			address={address}
			fee={fee?.platform_fee as number}
		/>
	) : (
		<div className="popup popup--width-540">
			<button
				aria-label="button"
				onClick={() => onPopUps('')}
				type="button"
				className="popup__close"
			>
				<PopUpCloseIcon />
			</button>
			<div className="popup-header">
				<p className="popup-header__title popup-header__title--ta-l ">Withdrawal</p>
			</div>
			<div className="popup-body popup-body--type3">
				<div className="select-block">
					<p className="select-block__name">Wallet</p>
					{currencies && (
						<SelectDropCoin
							onSubmit={onData}
							currencies={currencies}
							popUpOpen={popUpOpen}
							isSelect={isSelect}
							setIsSelect={setIsSelect}
							viewDecimals={viewDecimals}
						/>
					)}
				</div>
				{limits && <WithdrawalCryptoInfo isSelect={isSelect} limits={limits} amount={amount} />}
				<div className="input">
					<p className="input-name">Address</p>
					<div className="input-wrapper">
						<DebounceInput
							name="address"
							className="input-item "
							type="text"
							placeholder={String(`External ${isSelect?.currency_name || ''} address`)}
							value={address.trim()}
							onChange={handleInputs}
							debounceTimeout={500}
						/>
						{!isValidAddress && address && addressError && <ErrorMsg msg="Incorrect address!" />}
					</div>
				</div>
				<div className="input">
					<p className="input-name">Amount</p>
					<div className="input-wrapper">
						<DebounceInput
							name="amount"
							className="input-item input-item--pr-75"
							type="text"
							placeholder="Enter"
							// disabled={!isValidAddress}
							value={amount}
							onKeyPress={handleKeyPress}
							onFocus={handleInputFocus}
							onBlur={handleInputBlur}
							onChange={handleInputs}
							debounceTimeout={500}
						/>
						<div className="input-text">
							<button onClick={() => maxBtn(String(isSelect?.balance) || '')} type="button">
								MAX
							</button>
							<p>{isSelect?.currency.code.toUpperCase()}</p>
						</div>
					</div>
				</div>
				<div className="deposit-info-pop">
					<ul>
						{/* <li>
							<span>Miner fee:</span>
							<p>{`0 ${isSelect?.currency_network.toUpperCase() || ''}`}</p>
						</li> */}
						<li>
							<span>Platform Fee:</span>
							<p>{`${
								isExist(fee?.platform_fee)
									? trimLastZeros(Number(fee?.platform_fee).toFixed(viewDecimals))
									: 0
							} ${isSelect?.currency.code.toUpperCase() || ''}`}</p>
						</li>
						<li>
							<span>Network Fee:</span>
							<p>{`${
								isExist(fee?.network_fee) && amount
									? trimLastZeros(Number(fee?.network_fee).toFixed(viewDecimals))
									: 0
							} ${isSelect?.currency.code.toUpperCase() || ''}`}</p>
						</li>
						<li>
							<span>Will be sent:</span>
							<p>{`${isExist(amount) ? trimLastZeros(Number(amount).toFixed(viewDecimals)) : 0} ${
								isSelect?.currency.code.toUpperCase() || ''
							}`}</p>
						</li>
						<li>
							<span>Will be received:</span>
							{tryReceived ? (
								<p>0 {isSelect?.currency.code.toUpperCase() || ''}</p>
							) : (
								<p>
									{' '}
									{`${
										(fee && isExist(fee?.platform_fee)
											? trimLastZeros(
													(Number(amount) - Number(fee.platform_fee)).toFixed(viewDecimals),
											  )
											: amount) || 0
									} ${isSelect?.currency.code.toUpperCase() || ''}`}
								</p>
							)}
						</li>
					</ul>
				</div>
			</div>
			{tryReceived ? (
				<p className="input-notification input-notification--error">{tryReceived}</p>
			) : null}
			<div className="popup-button popup-button--type2">
				<button
					onClick={() => onPopUps('')}
					type="button"
					className="button button--full-width button--type2"
				>
					Cancel
				</button>
				<button
					disabled={!isValidImputs()}
					onClick={() => setOpenConfirm(true)}
					type="button"
					className="button button--full-width"
				>
					Withdrawal
				</button>
			</div>
			<div className="button--mt-custom">
				<button
					name={EButtonName.TRANSFER}
					type="button"
					onClick={handleButton}
					className="link link--type2 link--mt-24 link--mt-custom"
				>
					Transfer
				</button>
			</div>
		</div>
	);
};
