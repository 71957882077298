/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IGetCallbacksParams,
	TGetCallbacksResponse,
	IViewCallbackParams,
	IViewCallbackResponse,
	IProjectCallbacksExportCSVPayload,
} from 'services/api/project/types';
import { IProjectCallbacksStore, IResendCallbackPayload } from './types';

export const initialState: IProjectCallbacksStore = {
	callbacks: null,
	callbacksLoading: false,
	viewCallback: null,
	viewCallbackLoading: false,
	exportCSVLoading: false,
};
const projectCallbacks = createSlice({
	name: '@@projectCallbacks',
	initialState,
	reducers: {
		getCallbacksRequest: (state, action: PayloadAction<IGetCallbacksParams>) => {
			state.callbacksLoading = true;
		},
		getCallbacksSuccess: (state, { payload }: PayloadAction<TGetCallbacksResponse>) => {
			state.callbacks = payload;
			state.callbacksLoading = false;
		},
		getCallbacksError: (state) => {
			state.callbacks = null;
			state.callbacksLoading = false;
		},
		viewCallbackRequest: (state, action: PayloadAction<IViewCallbackParams>) => {
			state.viewCallbackLoading = true;
		},
		viewCallbackSuccess: (state, { payload }: PayloadAction<IViewCallbackResponse>) => {
			state.viewCallback = payload;
			state.viewCallbackLoading = false;
		},
		viewCallbackError: (state) => {
			state.viewCallback = null;
			state.viewCallbackLoading = false;
		},
		exportCSVRequest: (state, action: PayloadAction<IProjectCallbacksExportCSVPayload>) => {
			state.exportCSVLoading = true;
		},
		exportCSVSuccess: (state) => {
			state.exportCSVLoading = false;
		},
		resendCallbackRequest: (state, action: PayloadAction<IResendCallbackPayload>) => {},
	},
});

export default projectCallbacks.reducer;
export const {
	getCallbacksRequest,
	getCallbacksSuccess,
	getCallbacksError,
	viewCallbackRequest,
	viewCallbackSuccess,
	viewCallbackError,
	exportCSVRequest,
	exportCSVSuccess,
	resendCallbackRequest,
} = projectCallbacks.actions;
