/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiLoginResponse, IRequest2FaReset, IFirstLoginResponse } from 'services/api/auth/types';
import {
	IAuthStore,
	IConfirIpAddressRequest,
	ICreatePasswordPayload,
	IForgotPasswordRequest,
	ILoginPayload,
	IParams2faREstProviderSlice,
	IParams2faREstSlice,
	IResetPassRequest,
} from './types';

export const initialState: IAuthStore = {
	token: null,
	userData: null,
	tokenExpiredAt: null,

	actionsLoading: false,
	firstLogin: null,
};

const auth = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		loginRequest: (state, action: PayloadAction<ILoginPayload>) => {
			state.actionsLoading = true;
		},

		loginMerchantRequest: (state, action: PayloadAction<ILoginPayload>) => {
			state.actionsLoading = true;
		},

		loginSuccess: (state, { payload }: PayloadAction<IApiLoginResponse>) => {
			const { token, user_data, token_expired_at } = payload; // eslint-disable-line
			state.token = token;
			state.userData = user_data;
			state.tokenExpiredAt = token_expired_at;
			state.actionsLoading = false;
		},
		loginError: (state) => {
			state.token = null;
			state.userData = null;
			state.tokenExpiredAt = null;
			state.actionsLoading = false;
		},

		logoutRequest: setActionsLoading,
		logoutMerchantRequest: setActionsLoading,
		logoutSuccess: (state) => {
			state.token = null;
			state.userData = null;
			state.tokenExpiredAt = null;
			state.actionsLoading = false;
		},
		logoutError: unsetActionsLoading,

		createPasswordRequest: (state, action: PayloadAction<ICreatePasswordPayload>) => {
			state.actionsLoading = true;
		},
		createPasswordSuccess: unsetActionsLoading,
		createPasswordError: unsetActionsLoading,

		confirmResetPassRequest: (state, action: PayloadAction<IResetPassRequest>) => {
			state.actionsLoading = true;
		},

		confirIpAddressRequest: (state, action: PayloadAction<IConfirIpAddressRequest>) => {
			state.actionsLoading = true;
		},

		forgotPasswordRequest: (state, action: PayloadAction<IForgotPasswordRequest>) => {},

		firstLoginRequest: (state, action: PayloadAction<{ token: string }>) => {},
		firstLoginSuccess: (state, { payload }: PayloadAction<IFirstLoginResponse>) => {
			const { QR_Image: qr, secret } = payload;
			state.firstLogin = {
				qr,
				secretCode: secret,
			};
		},
		// firstLoginResponse: (state, action: PayloadAction<IForgotPasswordRequest>) => {},

		reset2faRequest: (state, action: PayloadAction<IParams2faREstSlice>) => {},

		reset2faProviderRequest: (state, action: PayloadAction<IParams2faREstProviderSlice>) => {},
		reset2faMerchantRequest: (state, action: PayloadAction<IParams2faREstProviderSlice>) => {},

		authInitState: () => initialState,
	},
});

// Helper functions

function setActionsLoading(state: IAuthStore) {
	state.actionsLoading = true;
}
function unsetActionsLoading(state: IAuthStore) {
	state.actionsLoading = false;
}

export default auth.reducer;
export const {
	loginRequest,
	loginSuccess,
	loginError,
	logoutRequest,
	logoutSuccess,
	logoutError,
	createPasswordRequest,
	createPasswordSuccess,
	createPasswordError,
	loginMerchantRequest,
	logoutMerchantRequest,
	confirmResetPassRequest,
	confirIpAddressRequest,
	forgotPasswordRequest,
	firstLoginRequest,
	firstLoginSuccess,
	// firstLoginResponse,
	reset2faRequest,
	reset2faProviderRequest,
	reset2faMerchantRequest,
	authInitState,
} = auth.actions;
