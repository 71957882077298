/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProviderExportCSVStore, IProviderExportCSVTransactionPayload } from './types';

export const initialState: IProviderExportCSVStore = {
	getProviderCSVTransaction: '',
	getProviderCSVTransactionLoading: false,
	getProviderCSVTransactionFiat: '',
	getProviderCSVTransactionFiatLoading: false,
};

const providerExportCSV = createSlice({
	name: '@@providerExportCSV',
	initialState,
	reducers: {
		getProviderExportCSVTransactionRequest: (
			state,
			action: PayloadAction<IProviderExportCSVTransactionPayload>,
		) => {
			state.getProviderCSVTransactionLoading = true;
		},
		getProviderExportCSVTransactionSuccess: (state, action: PayloadAction<string>) => {
			state.getProviderCSVTransaction = action.payload;
			state.getProviderCSVTransactionLoading = false;
		},
		getProviderExportCSVTransactionFiatWalletRequest: (
			state,
			action: PayloadAction<IProviderExportCSVTransactionPayload>,
		) => {
			state.getProviderCSVTransactionFiatLoading = true;
		},
		getProviderExportCSVTransactionFiatWalletSuccess: (state, action: PayloadAction<string>) => {
			state.getProviderCSVTransactionFiat = action.payload;
			state.getProviderCSVTransactionFiatLoading = false;
		},
	},
});
export default providerExportCSV.reducer;
export const {
	getProviderExportCSVTransactionRequest,
	getProviderExportCSVTransactionSuccess,
	getProviderExportCSVTransactionFiatWalletRequest,
	getProviderExportCSVTransactionFiatWalletSuccess,
} = providerExportCSV.actions;
