import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import { WEB_API_URL } from 'services/constants/env';
import {
	TProviderInvoices,
	IProviderInvoice,
	IGetInvoiceResponse,
	IGetInvoiceParams,
	IGetInvoiceStatusResponse,
} from 'services/api/provider/types';
import { downloadFile } from 'utils/files';
import { downloadCSVFunc } from 'utils/downloadCSVFunc';
import {
	getProviderInvoicesRequest,
	getProviderInvoicesSuccess,
	getProviderInvoicesError,
	createInvoiceRequest,
	createInvoiceSuccess,
	createInvoiceError,
	downloadInvoicePDFRequest,
	downloadInvoicePDFSuccess,
	downloadInvoicePDFError,
	sendInvoiceRequest,
	sendInvoiceSuccess,
	sendInvoiceError,
	getInvoiceLinkRequest,
	getInvoiceLinkSuccess,
	getInvoiceLinkError,
	getInvoiceRequest,
	getInvoiceSuccess,
	getInvoiceError,
	getInvoiceStatusRequest,
	getInvoiceStatusSuccess,
	downloadCSVRequest,
	downloadCSVSuccess,
	downloadCSVError,
} from './reducer';
import {
	IGetProviderInvoicesPayload,
	ICreateInvoicePayload,
	IDownloadInvoicePDFPayload,
	IGetInvoiceLinkPayload,
	IDownloadCSVProviderInvoicesPayload,
	IGetInvoiceStatusPayload,
} from './types';

function* getProviderInvoicesRequestWorker(action: PayloadAction<IGetProviderInvoicesPayload>) {
	const { apiParams, onSuccess } = action.payload;

	try {
		const data: TProviderInvoices = yield call(api.provider.getInvoices, apiParams);
		yield put(getProviderInvoicesSuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(getProviderInvoicesError());
		showError(error);
	}
}

function* createInvoiceRequestWorker(action: PayloadAction<ICreateInvoicePayload>) {
	const { apiParams, onSuccess, onFinally } = action.payload;
	try {
		const data: IProviderInvoice = yield call(api.provider.createInvoice, apiParams);
		yield put(createInvoiceSuccess(data));
		onSuccess?.(data.id);
	} catch (error) {
		yield put(createInvoiceError());
		showError(error);
		onFinally?.();
	}
}

function* downloadInvoicePDFRequestWorker(action: PayloadAction<IDownloadInvoicePDFPayload>): any {
	const { apiParams, onSuccess } = action.payload;
	try {
		const data = yield call(api.provider.downloadInvoicePDF, apiParams);
		const link = `${String(WEB_API_URL).replace('/api', '')}${String(data)}`;
		downloadFile(link);
		yield put(downloadInvoicePDFSuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(downloadInvoicePDFError());
		showError(error);
	}
}

function* sendInvoiceRequestWorker(action: PayloadAction<any>): any {
	const { apiParams, onSuccess } = action.payload;
	try {
		const data: any = yield call(api.provider.sendInvoiceLink, apiParams);
		yield put(sendInvoiceSuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(sendInvoiceError());
		showError(error);
	}
}

function* getInvoiceRequestWorker(action: PayloadAction<IGetInvoiceParams>) {
	try {
		const data: IGetInvoiceResponse = yield call(api.provider.getInvoice, action.payload);
		yield put(getInvoiceSuccess(data));
	} catch (error) {
		yield put(getInvoiceError());
		showError(error);
	}
}

function* getInvoiceStatusWorker({ payload }: PayloadAction<IGetInvoiceStatusPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: IGetInvoiceStatusResponse = yield call(api.provider.getInvoiceStatus, apiParams);
		yield put(getInvoiceStatusSuccess(data));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* getInvoiceLinkRequestWorker(action: PayloadAction<IGetInvoiceLinkPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		const link: string = yield call(api.provider.getInvoiceLink, apiParams);
		window.open(link);
		yield put(getInvoiceLinkSuccess(link));
		onSuccess?.(link);
	} catch (error) {
		yield put(getInvoiceLinkError());
		showError(error);
	}
}

function* downloadCSVRequestWorker(action: PayloadAction<IDownloadCSVProviderInvoicesPayload>) {
	const { nameFile, apiParams, onSuccess } = action.payload;
	try {
		const data: string = yield call(api.provider.getProviderExportCSVTransaction, apiParams);
		onSuccess?.();
		downloadCSVFunc(nameFile, data);
		yield put(downloadCSVSuccess(data));
	} catch (error) {
		yield put(downloadCSVError());
		showError(error);
	}
}

export function* providerInvoicesSaga() {
	yield takeLatest(getProviderInvoicesRequest, getProviderInvoicesRequestWorker);
	yield takeLatest(createInvoiceRequest, createInvoiceRequestWorker);
	yield takeLatest(downloadInvoicePDFRequest, downloadInvoicePDFRequestWorker);
	yield takeLatest(sendInvoiceRequest, sendInvoiceRequestWorker);
	yield takeLatest(getInvoiceLinkRequest, getInvoiceLinkRequestWorker);
	yield takeLatest(getInvoiceRequest, getInvoiceRequestWorker);
	yield takeLatest(getInvoiceStatusRequest, getInvoiceStatusWorker);
	yield takeLatest(downloadCSVRequest, downloadCSVRequestWorker);
}
