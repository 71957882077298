/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TApiGetProjectMerchantTransactionResponse,
	TApiGetProjectTransactionsApprovementResponse,
	TApiGetProjectTransactionsResponse,
} from 'services/api/project/types';
import {
	IDepositCryptoTransactionParams,
	TDepositCryptoTransactions,
} from 'services/api/provider/types';
import {
	IMerchantGetProjectTransactionPayload,
	IMerchantGetProjectTransactionsApprovementPayload,
	IMerchantGetProjectTransactionsPayload,
	IProjectTransactionsStore,
	IViewTransactionAprovementPayload,
	IExportCsvTransactionsPayload,
	ICreateReportProjectTransactionPayload,
	ITransactionFilter,
	ICreateFiatTransactionsPayload,
	IGetReferenceByCurrencyRequest,
	IGetReferenceByCurrencySuccess,
} from './types';

export const initialState: IProjectTransactionsStore = {
	projectTransactions: null,
	projectTransactionsApprovements: null,
	projectMerchantTransactions: null,
	transactionsFiat: null,
	transactionsFiatLoading: false,
	viewApprovement: null,
	exportCsvTransactionsLoading: false,
	createReportProjectTransaction: false,
	transactionsLoading: false,
	transactionsFilter: { page: 1 },
	transactionsFiatFilter: { page: 1 },
	referenceByCurrency: null,
};

const projectTransactions = createSlice({
	name: '@@projectTransactions',
	initialState,
	reducers: {
		getProjectTransactionsRequest: (
			state,
			action: PayloadAction<IMerchantGetProjectTransactionsPayload>,
		) => {
			state.transactionsLoading = true;
		},
		getProjectTransactionsSuccess: (
			state,
			action: PayloadAction<TApiGetProjectTransactionsResponse>,
		) => {
			state.transactionsLoading = false;
			state.projectTransactions = action.payload;
		},
		getProjectTransactionsError: (state) => {
			state.transactionsLoading = false;
			state.projectTransactions = null;
		},
		// aprov
		getProjectTransactionsApprovementsRequest: (
			state,
			action: PayloadAction<IMerchantGetProjectTransactionsApprovementPayload>,
		) => {
			state.transactionsLoading = true;
		},
		getProjectTransactionsApprovementsSuccess: (
			state,
			action: PayloadAction<TApiGetProjectTransactionsApprovementResponse>,
		) => {
			state.transactionsLoading = false;
			state.projectTransactionsApprovements = action.payload;
		},
		getProjectTransactionsApprovementsError: (state) => {
			state.transactionsLoading = false;
			state.projectTransactionsApprovements = null;
		},
		// getMerchantTransaction
		getMerchantTransactionRequest: (
			state,
			action: PayloadAction<IMerchantGetProjectTransactionPayload>,
		) => state,
		getMerchantTransactionSuccess: (
			state,
			action: PayloadAction<TApiGetProjectMerchantTransactionResponse>,
		) => {
			state.projectMerchantTransactions = action.payload;
		},
		getMerchantTransactionError: (state) => {
			state.projectTransactions = null;
		},
		viewTransactionApprovemientRequest: (
			state,
			action: PayloadAction<IViewTransactionAprovementPayload>,
		) => state,
		viewTransactionApprovemientSuccess: (
			state,
			action: PayloadAction<TApiGetProjectMerchantTransactionResponse>,
		) => {
			state.viewApprovement = action.payload;
		},
		viewTransactionApprovemientError: (state) => {
			state.viewApprovement = null;
		},
		exportCsvProjectTransactionsRequest: (
			state,
			action: PayloadAction<IExportCsvTransactionsPayload>,
		) => {
			state.exportCsvTransactionsLoading = true;
		},
		exportCsvProjectTransactionsSuccess: (state) => {
			state.exportCsvTransactionsLoading = false;
		},
		exportCsvProjectTransactionsError: (state) => {
			state.exportCsvTransactionsLoading = false;
		},
		exportCsvProjectApprovementsRequest: (
			state,
			action: PayloadAction<IExportCsvTransactionsPayload>,
		) => {
			state.exportCsvTransactionsLoading = true;
		},
		exportCsvProjectApprovementsSuccess: (state) => {
			state.exportCsvTransactionsLoading = false;
		},
		exportCsvProjectApprovementsError: (state) => {
			state.exportCsvTransactionsLoading = false;
		},
		createReportTransactionProjectRequest: (
			state,
			action: PayloadAction<ICreateReportProjectTransactionPayload>,
		) => {
			state.createReportProjectTransaction = true;
		},
		createReportTransactionProjectSuccess: (state) => {
			state.createReportProjectTransaction = false;
		},
		createReportTransactionProjectError: (state) => {
			state.createReportProjectTransaction = false;
		},
		createReportApprovementProjectRequest: (
			state,
			action: PayloadAction<ICreateReportProjectTransactionPayload>,
		) => {
			state.createReportProjectTransaction = true;
		},
		createReportApprovementProjectSuccess: (state) => {
			state.createReportProjectTransaction = false;
		},
		createReportApprovementProjectError: (state) => {
			state.createReportProjectTransaction = false;
		},
		setTransactionsStateFilter: (
			state,
			{ payload }: PayloadAction<Partial<ITransactionFilter>>,
		) => {
			state.transactionsFilter = { ...state.transactionsFilter, ...payload };
		},
		setTransactionsStateFiatFilter: (
			state,
			{ payload }: PayloadAction<Partial<ITransactionFilter>>,
		) => {
			state.transactionsFiatFilter = { ...state.transactionsFiatFilter, ...payload };
		},
		createFiatTransactionProjectRequest: (
			state,
			action: PayloadAction<ICreateFiatTransactionsPayload>,
		) => {
			// state.createReportProjectTransaction = true;
		},
		createFiatTransactionProjectSuccess: (state) => {
			// state.createReportProjectTransaction = false;
		},
		// createReportApprovementProjectError: (state) => {
		// 	state.createReportProjectTransaction = false;
		// },
		getTransactionsFiatRequest: (state, action: PayloadAction<IDepositCryptoTransactionParams>) => {
			state.transactionsFiatLoading = true;
		},
		getTransactionsSuccess: (state, { payload }: PayloadAction<TDepositCryptoTransactions>) => {
			state.transactionsFiat = payload;
			state.transactionsFiatLoading = false;
		},
		getTransactionsError: (state) => {
			state.transactionsFiat = null;
			state.transactionsFiatLoading = false;
		},
		getProviderReferenceRequest: (
			state,
			action: PayloadAction<IGetReferenceByCurrencyRequest>,
		) => {},
		getProviderReferenceSuccess: (state, action: PayloadAction<IGetReferenceByCurrencySuccess>) => {
			state.referenceByCurrency = action.payload;
		},
	},
});

export default projectTransactions.reducer;
export const {
	getProjectTransactionsRequest,
	getProjectTransactionsSuccess,
	getProjectTransactionsError,
	getProjectTransactionsApprovementsRequest,
	getProjectTransactionsApprovementsSuccess,
	getProjectTransactionsApprovementsError,
	getMerchantTransactionRequest,
	getMerchantTransactionSuccess,
	getMerchantTransactionError,
	viewTransactionApprovemientRequest,
	viewTransactionApprovemientSuccess,
	viewTransactionApprovemientError,
	exportCsvProjectTransactionsRequest,
	exportCsvProjectTransactionsSuccess,
	exportCsvProjectTransactionsError,
	exportCsvProjectApprovementsRequest,
	exportCsvProjectApprovementsSuccess,
	exportCsvProjectApprovementsError,
	createReportTransactionProjectRequest,
	createReportTransactionProjectSuccess,
	createReportTransactionProjectError,
	createReportApprovementProjectRequest,
	createReportApprovementProjectSuccess,
	createReportApprovementProjectError,
	setTransactionsStateFilter,
	createFiatTransactionProjectRequest,
	createFiatTransactionProjectSuccess,
	getTransactionsFiatRequest,
	getTransactionsSuccess,
	getTransactionsError,
	getProviderReferenceRequest,
	getProviderReferenceSuccess,
	setTransactionsStateFiatFilter,
} = projectTransactions.actions;
