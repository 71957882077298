/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IProviderApprovementsStore,
	IIProviderApprovementsResponse,
	IProviderApprovementsRequest,
	IProviderKycRequest,
	IProviderRefoundRequest,
	IProviderStatusRequest,
	IProviderApprovementsDetails,
	IProviderCommentRequest,
} from './types';

export const initialState: IProviderApprovementsStore = {
	providerApprovementsMerchants: null,
	providerApprovementsMerchantLoading: false,
	providerApprovementsProviderLoading: false,
	providerApprovementsDefaultMerchants: null,
	providerApprovementsDefault: null,
	providerApprovementsProvider: null,
	providerApprovementsDetails: null,
	merchantKYCids: [],
	providerKYCids: [],
};

const providerApprovements = createSlice({
	name: '@@providerApprovements',
	initialState,
	reducers: {
		getApprovementRequest: (state, _: PayloadAction<IProviderApprovementsRequest>) => {
			state.providerApprovementsMerchantLoading = true;
		},
		getApprovementSuccess: (state, { payload }: PayloadAction<IIProviderApprovementsResponse>) => {
			state.providerApprovementsMerchants = payload;
			state.providerApprovementsMerchantLoading = false;
		},
		getApprovementError: (state) => {
			state.providerApprovementsMerchantLoading = false;
		},

		getApprovementDefaultRequest: (state, _: PayloadAction<IProviderApprovementsRequest>) => {},
		getApprovementDefaultSuccess: (
			state,
			{ payload }: PayloadAction<IIProviderApprovementsResponse>,
		) => {
			state.providerApprovementsDefaultMerchants = payload;
		},

		getApprovementProviderRequest: (state, _: PayloadAction<IProviderApprovementsRequest>) => {
			// state.providerApprovementsProviderLoading = true;
		},
		getApprovementProviderSuccess: (
			state,
			{ payload }: PayloadAction<IIProviderApprovementsResponse>,
		) => {
			state.providerApprovementsProvider = payload;
			// state.providerApprovementsProviderLoading = false;
		},
		getApprovementProviderDefaultRequest: (
			state,
			_: PayloadAction<IProviderApprovementsRequest>,
		) => {},
		getApprovementProviderDefaultSuccess: (
			state,
			{ payload }: PayloadAction<IIProviderApprovementsResponse>,
		) => {
			state.providerApprovementsDefault = payload;
		},
		setCheckedIds: (state, { payload }: PayloadAction<any>) => {
			state.merchantKYCids = payload;
			state.providerKYCids = payload;
		},
		putApprovementKYCRequest: (state, _: PayloadAction<IProviderKycRequest>) => {
			// state.providerApprovementsProviderLoading = true;
		},
		putApprovementRefoundRequest: (state, _: PayloadAction<IProviderRefoundRequest>) => {
			// state.providerApprovementsProviderLoading = true;
		},
		putApprovementStatusRequest: (state, _: PayloadAction<IProviderStatusRequest>) => {
			// state.providerApprovementsProviderLoading = true;
		},
		getApprovementDetailsSuccess: (
			state,
			{ payload }: PayloadAction<IProviderApprovementsDetails>,
		) => {
			state.providerApprovementsDetails = payload;
		},
		getApprovementDetailsRequest: (state, _: PayloadAction<string>) => {},
		postApprovementCommentRequest: (state, _: PayloadAction<IProviderCommentRequest>) => {},
		getApprovementInitial: () => initialState,
	},
});

export default providerApprovements.reducer;
export const {
	getApprovementRequest,
	getApprovementSuccess,
	getApprovementProviderRequest,
	getApprovementProviderSuccess,
	getApprovementInitial,
	getApprovementError,
	putApprovementKYCRequest,
	putApprovementRefoundRequest,
	putApprovementStatusRequest,
	getApprovementDetailsRequest,
	getApprovementDetailsSuccess,
	postApprovementCommentRequest,
	setCheckedIds,
	getApprovementDefaultRequest,
	getApprovementDefaultSuccess,
	getApprovementProviderDefaultRequest,
	getApprovementProviderDefaultSuccess,
} = providerApprovements.actions;
