import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { getCountriesRequest } from 'redux/reducers/app/reducer';
import { getCountries } from 'redux/reducers/app/selectors';
import { TextField } from 'UI/Forms/TextField/TextField';
import { SelectField } from 'UI/Forms/SelectField/SelectField';
import { PhoneInputField } from 'UI/Forms/PhoneInputField/PhoneInputField';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { ICreateAccountProps } from '../types';
import { validationSchema } from './schema';

const CreateMerchantAccount: FC<ICreateAccountProps> = ({ onSubmit, valueState }) => {
	const dispatch = useDispatch();
	const countries = useSelector(getCountries);

	useEffect(() => {
		dispatch(getCountriesRequest());
	}, [dispatch]);

	return (
		<Formik
			initialValues={valueState}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			initialTouched={{
				firstName: true,
				lastName: true,
			}}
		>
			{({ isSubmitting, resetForm }) => (
				<Form className="main-content">
					<div className="mobile-project-info">
						<div className="header-project">
							<div className="header-project__name">
								<p>Provider</p>
							</div>
						</div>
					</div>
					<Link className="link-create" to="/merchants">
						<button type="button" className="back-page">
							<ArrowLeftIcon />
							<span>Back to Merchant management</span>
						</button>
					</Link>
					<div className="content-header">
						<p className="form-title">Create merchant account</p>
					</div>
					<div className="fields-wrap">
						<div className="fields-item">
							<div className="fields-list">
								<div className="fields-list__title">
									<p>Contact person </p>
								</div>
								<TextField label="First name" placeholder="Enter first name" name="firstName" />
								<TextField label="Last name" placeholder="Enter last name" name="lastName" />
								<TextField
									label="Company name (legal entity name)"
									placeholder="Enter company name"
									name="companyName"
								/>
								<TextField
									label="Company short name"
									type="text"
									placeholder="Company short name"
									name="idMerchant"
								/>
								<TextField label="Email address" placeholder="Enter email address" name="email" />
								<PhoneInputField
									name="phoneNumber"
									codeFieldName="phoneCode"
									label="Phone"
									countries={countries || []}
								/>
							</div>
							<div className="fields-list">
								<div className="fields-list__title">
									<p>Location info</p>
								</div>
								<SelectField
									name="country"
									title="Country"
									placeholder="Enter country"
									options={countries || []}
									valueProp="name"
									labelProp="name"
								/>
								<TextField label="City" placeholder="Enter city" name="city" />
								<TextField
									label="Address Line 1"
									placeholder="Enter address"
									name="addressLineOne"
								/>
								<TextField
									label="Address Line 2"
									placeholder="Enter address"
									name="addressLineTwo"
								/>
							</div>
							<div className="fields-button">
								<button type="button" className="button button--type2" onClick={() => resetForm()}>
									Cancel
								</button>
								<button disabled={isSubmitting} type="submit" className="button">
									Continue
								</button>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default CreateMerchantAccount;
