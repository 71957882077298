/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaymentTrasactionsParams } from 'services/api/provider/types';
import {
	IPaymentTrasactionPagination,
	IProviderPaymentTrasactionStore,
	IShowPaymentTrasactionRequest,
	IShowPaymentTrasactionResponse,
} from './types';

export const initialState: IProviderPaymentTrasactionStore = {
	paymentTrasactions: null,
	paymentTrasaction: null,
	paymentTrasactionsLoading: false,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const providerPaymentTrasaction = createSlice({
	name: '@@providerPaymentTrasaction',
	initialState,
	reducers: {
		getPaymentTrasactionRequest: (state, { payload }: PayloadAction<IPaymentTrasactionsParams>) => {
			state.paymentTrasactionsLoading = true;
			return state;
		},
		getPaymentTrasactionSuccess: (
			state,
			{ payload }: PayloadAction<IPaymentTrasactionPagination>,
		) => {
			state.paymentTrasactionsLoading = false;
			const providerPaymentTrasactionState = state;
			providerPaymentTrasactionState.paymentTrasactions = payload;
		},

		showPaymentTrasactionRequest: (state, _: PayloadAction<IShowPaymentTrasactionRequest>) => state,
		showPaymentTrasactionSuccess: (
			state,
			{ payload }: PayloadAction<IShowPaymentTrasactionResponse>,
		) => {
			const providerPaymentTrasactionState = state;
			providerPaymentTrasactionState.paymentTrasaction = payload;
		},
	},
});

export default providerPaymentTrasaction.reducer;
export const {
	getPaymentTrasactionRequest,
	getPaymentTrasactionSuccess,
	showPaymentTrasactionRequest,
	showPaymentTrasactionSuccess,
} = providerPaymentTrasaction.actions;
