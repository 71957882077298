import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import {
	getExchangeProviderRateFeeSelector,
	getExchangeRateFeeSelector,
	getAccountLoading,
} from 'redux/reducers/merchantAccounts/selectors';
import {
	getExchangeProviderRateFeeRequest,
	getExchangeRateFeeRequest,
} from 'redux/reducers/merchantAccounts/reducer';
import { ProjectInfo } from 'layouts-elements/ProjectInfo/ProjectInfo';
import { TextField } from 'UI/Forms/TextField/TextField';
import { CurrencyNetworkSelectField } from 'UI/Forms/CurrencyNetworkSelectField/CurrencyNetworkSelectField';
import { SelectField } from 'UI/Forms/SelectField/SelectField';
import { useParams } from 'react-router';
import { ICreateInvoiceFormInner, IValues } from './types';
import { timeOptions } from './data';

export const CreateInvoiceFormInner: FC<ICreateInvoiceFormInner> = ({
	currencies,
	isMerchant,
	closePopup,
}) => {
	const { values, dirty, resetForm, setFieldValue, isSubmitting } = useFormikContext<IValues>();
	const dispatch = useDispatch();
	const rateAndFee = useSelector(getExchangeRateFeeSelector);
	const rateAndFeeProvider = useSelector(getExchangeProviderRateFeeSelector);
	const accountLoading = useSelector(getAccountLoading);
	const { rate } = rateAndFee || rateAndFeeProvider || {};

	useEffect(() => {
		const { currencyRequest, amountRequest, currencyPaid } = values;
		if (
			!currencyRequest ||
			!amountRequest ||
			!Number(amountRequest) ||
			+amountRequest <= 0 ||
			!currencyPaid
		) {
			return;
		}
		const currencyRequestOption = currencies?.find((opt) => opt.id === +currencyRequest);
		const currencyPaidOption = currencies?.find((opt) => opt.id === +currencyPaid);
		/* projectId
			? dispatch(
					getExchangeRateFeeRequest({
						amount: +amountRequest,
						currency_code_from: currencyRequestOption?.currency.code || '',
						currency_code_to: currencyPaidOption?.currency.code || '',
						from_project_id: projectId,
						is_exchange: 0,
					}),
			  )
			: dispatch(
					getExchangeProviderRateFeeRequest({
						currency_code_from: currencyRequestOption?.currency.code || '',
						currency_code_to: currencyPaidOption?.currency.code || '',
					}),
			  ); */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currencies, dispatch, values.currencyRequest, values.amountRequest, values.currencyPaid]);

	const { currencyRequest, currencyPaid } = values;

	useEffect(() => {
		if (
			!currencyRequest ||
			!currencyPaid ||
			(!rate && !Number.isInteger(rate)) ||
			!values.amountRequest
		) {
			return;
		}
		const amountPay = Number(rate) * +values.amountRequest;
		setFieldValue('rate', rate);
		setFieldValue('amountToPay', amountPay);
	}, [currencyPaid, currencyRequest, rate, setFieldValue, values.amountRequest]);

	const disabled = isSubmitting || !dirty || accountLoading;

	return (
		<>
			<div className="popup-header">
				<p className="popup-header__title popup-header__title--ta-l ">Create an invoice</p>
			</div>

			{isMerchant && (
				<div className="select-block select-block--project-select">
					<ProjectInfo />
				</div>
			)}

			<div className="popup-body popup-body--type3">
				<CurrencyNetworkSelectField
					name="currencyRequest"
					title="Currency"
					options={currencies}
					valueProp="id"
					withNetworkName
				/>

				<TextField name="amountRequest" label="Amount" onlyNumbers />

				<CurrencyNetworkSelectField
					name="currencyPaid"
					title="Pay in currency"
					options={currencies}
					valueProp="id"
					withNetworkName
				/>

				<TextField name="amountToPay" label="Amount (Pay in currency)" />

				<SelectField name="expiresTimeMinutes" title="Expires Time" options={timeOptions} />

				<TextField name="clientEmail" label="Client email" />
			</div>
			<div className="popup-button popup-button--type2">
				<button
					type="button"
					className="button button--full-width button--type2"
					onClick={closePopup}
				>
					Cancel
				</button>
				<button type="submit" className="button button--full-width" disabled={disabled}>
					Save
				</button>
			</div>
		</>
	);
};
