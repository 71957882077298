import { FC, useEffect } from 'react';
import { ProviderAvatarIcon, UserInfoArrow } from 'assets/inline-svg';
import {
	getProviderApprovementsDefault,
	getApprovementsDefault,
} from 'redux/reducers/providerApprovements/selectors';
import { getProviderApprovements } from 'redux/reducers/providerProjectApprovements/selectors';
import { getWithdrawal, getWithdrawalFiat } from 'redux/reducers/projectWithdrawal/selectors';
import { useLocalStorage } from 'hooks/useLocalStorage';
import classNames from 'classnames';
import { useSelect } from 'hooks';
import { useSelector } from 'react-redux';
import { getUserData, getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { Link } from 'react-router-dom';
import { UserMenuV2 } from './UserMenu';
import styles from './styles.module.scss';

export const UserInfoV2: FC = () => {
	const userData = useSelector(getUserData);
	const userType = useSelector(getUserType);
	const [withDr, setWithDr] = useLocalStorage('withdrawal', '');
	const [withPend, setPend] = useLocalStorage('pending', '');
	const [withApprovemen, setWithApprovemen] = useLocalStorage('approvement', '');

	const approvementMerchant = useSelector(getProviderApprovementsDefault);
	const approvementProvider = useSelector(getApprovementsDefault);
	const approvements = useSelector(getProviderApprovements);
	const withdrawal = useSelector(getWithdrawal);
	const withdrawalDefaultFiat = useSelector(getWithdrawalFiat);

	const { open, toggleOpen, triggerRef, dropRef } = useSelect();

	useEffect(() => {
		if ((withdrawal && !withDr) || (withdrawalDefaultFiat && !withDr)) {
			const newData: any = withdrawal?.data || withdrawalDefaultFiat?.data;
			setWithDr(newData);
		}
		if (approvements && !withPend) {
			const newData: any = approvements.data;
			setPend(newData);
		}
		if (approvementMerchant || (approvementProvider && !withApprovemen)) {
			const newData: any = approvementMerchant?.data || approvementProvider?.data;
			setWithApprovemen(newData);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		withdrawal?.data,
		withdrawalDefaultFiat?.data,
		approvements,
		approvementMerchant,
		approvementProvider,
	]);

	return (
		<div className={classNames(styles.userInfo, { [styles.active]: open })}>
			{userType === constUserTypes.MERCHANT && (
				<Link to={`/merchant/${userData?.id || ''}`} className={styles.userInfo__avatar}>
					<ProviderAvatarIcon />
				</Link>
			)}
			<button
				type="button"
				className={styles.userInfo__wrapper}
				ref={triggerRef}
				onClick={toggleOpen}
			>
				{userType === constUserTypes.PROVIDER && (
					<span className={styles.userInfo__avatar}>
						<ProviderAvatarIcon />
					</span>
				)}
				<span className={styles.userInfo__name}>
					<span className={styles.userInfo__role}>{userData?.role?.name || ''}</span>
					<span className={styles.userInfo__fullName}>{userData?.full_name || ''}</span>
				</span>
				<span className={styles.userInfo__arrow}>
					<UserInfoArrow />
				</span>
			</button>

			<UserMenuV2 isOpen={open} menuRef={dropRef} onClose={toggleOpen} id={String(userData?.id)} />
		</div>
	);
};
