import { IStoreState } from 'redux/types';

const paymentWidget = (state: IStoreState) => state.paymentWidget;

export const getAvailableCurrencies = (state: IStoreState) =>
	paymentWidget(state).availableCurrencies;

export const getAvailableCurrenciesLoading = (state: IStoreState) =>
	paymentWidget(state).availableCurrenciesLoading;

export const getCreatedPayment = (state: IStoreState) => paymentWidget(state).payment;

export const getCreatePaymentLoading = (state: IStoreState) =>
	paymentWidget(state).createPaymentLoading;

export const getPaymentStatus = (state: IStoreState) => paymentWidget(state).merchantInvoice;

export const getCreatedUpdatePayment = (state: IStoreState) => paymentWidget(state).updatePayment;
