import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProviderFeeRequest, putProviderFeeRequest } from 'redux/reducers/providerFee/reducer';
import { getProviderFeeState } from 'redux/reducers/providerFee/selectors';
import {
	TApiGetProviderFeeResponse,
	TApiPutProviderFeePayload,
} from 'redux/reducers/providerFee/types';
import { notify } from 'utils/notify';
import { IOnSubmit } from './types';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import styles from './styles.module.scss';

const ID_SHOW_ADDITIONAL_FILED = 11;

const FeeManagement = () => {
	const dispatch = useDispatch();
	const item = useSelector(getProviderFeeState);
	useEffect(() => {
		dispatch(getProviderFeeRequest());
	}, [dispatch]);
	const onSubmit = (body: IOnSubmit) => {
		const payload: TApiPutProviderFeePayload = {
			apiParams: {
				...body,
			},
			onSuccess: () => {
				notify('Fee edit success', 'success');
			},
		};
		dispatch(putProviderFeeRequest(payload));
	};
	return (
		<div>
			<TableHeader />
			<div className={styles.tableContainer}>
				{item?.map((e: TApiGetProviderFeeResponse) => (
					<TableBody
						key={e.id}
						item={e}
						onSubmit={onSubmit}
						isHaveAdded={e.id === ID_SHOW_ADDITIONAL_FILED}
					/>
				))}
			</div>
		</div>
	);
};

export default FeeManagement;
