import { ArrowLeftIcon } from 'assets/inline-svg';
import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import {
	getUserMerchantProfileRequest,
	editMerchantUserProfileRequest,
} from 'redux/reducers/merchantApprovalManagement/reducer';
import {
	getMerchantApprovalUserProfile,
	getMerchantApprovalUserProfileLoading,
} from 'redux/reducers/merchantApprovalManagement/selectors';
import { IEditMerchantRequset } from 'redux/reducers/merchantApprovalManagement/types';
import { notify } from 'utils/notify';
import { getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { TUserProfileFormOnSubmit } from './UserProfileForm/types';
import { UserProfileForm } from './UserProfileForm/UserProfileForm';

export const UserProfile = () => {
	const dispatch = useDispatch();
	const { merchantId } = useParams<{ merchantId: string }>();
	const history = useHistory();

	const user = useSelector(getMerchantApprovalUserProfile);
	const loading = useSelector(getMerchantApprovalUserProfileLoading);
	const userType = useSelector(getUserType);

	useLayoutEffect(() => {
		dispatch(getUserMerchantProfileRequest({ userType, params: { merchant_id: merchantId } }));
	}, [dispatch, merchantId, userType]);

	const handleSubmit: TUserProfileFormOnSubmit = ({ lastName, firstName, email }) => {
		if (!user) return;

		const payload: IEditMerchantRequset =
			userType === constUserTypes.PROVIDER
				? {
						apisParams: {
							merchant_id: user.merchant.id,
							first_name: firstName.trim() || user.merchant.first_name,
							last_name: lastName.trim() || user.merchant.last_name,
						},
						onSuccess: () => notify('User profile changed successfully', 'success'),
						userType,
				  }
				: {
						apisParams: {
							merchant_id: user.merchant.id,
							email: email.trim() || user.merchant.email,
							full_name:
								`${firstName.trim()} ${lastName.trim()}` ||
								`${user.merchant.first_name} ${user.merchant.last_name}`,
						},
						onSuccess: () => notify('User profile changed successfully', 'success'),
						userType,
				  };
		dispatch(editMerchantUserProfileRequest(payload));
	};

	return (
		<>
			{userType === constUserTypes.PROVIDER && (
				<div
					onClick={() => {
						history.goBack();
					}}
					style={{ cursor: 'pointer' }}
					className="back-page"
				>
					<ArrowLeftIcon />
					<span>Back to Administration</span>
				</div>
			)}

			{!loading && user && <UserProfileForm user={user} onSubmit={handleSubmit} />}
		</>
	);
};
