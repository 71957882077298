import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { DatepickerField } from 'v2Components/formComponents/DatepickerField';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { TextField } from 'v2Components/formComponents/TextField';
import { SearchIcon } from 'assets/inline-svg';
import { noRepeat } from 'utils/arrayNoRepeat';
import { capitalize, trimBottomLine } from 'utils/strings';
import { getProviderCurrencyAllNoSettings } from 'redux/reducers/providerCurrency/selectors';
import { getCurrencyNetwork, getFilter } from 'redux/reducers/app/selectors';
import { IFilter } from 'redux/reducers/app/types';
import { DefaultToken, Token } from 'v2Components/UI/Token';

interface IPaymentTransactionsFormBody {
	showFilter: boolean;
}

export const PaymentTransactionsFormBody = ({
	showFilter,
}: IPaymentTransactionsFormBody): JSX.Element => {
	const { values, resetForm, setValues } = useFormikContext<IFilter>();
	const filter = useSelector(getFilter);

	const currencies = useSelector(getProviderCurrencyAllNoSettings) || [];
	const network = useSelector(getCurrencyNetwork);

	const networks = network.map((net) => {
		return {
			id: net.currency_network,
			label: trimBottomLine(capitalize(net.currency_network_name)),
		};
	});

	const filterNetworks = noRepeat(networks, 'id');

	useEffect(() => {
		showFilter ? setValues({ ...filter }) : resetForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetForm, setValues, showFilter]);

	return (
		<div className="transition-filter-box">
			<SelectField
				title="Currency"
				name="currency_name"
				options={[
					{
						id: 0,
						code: 'All',
						name: '',
						img_path: '',
						icon: '',
						is_active: 1,
						is_crypto: 1,
					},
					...currencies,
				]}
				valueProp="name"
				renderSelected={(selectedValue) =>
					(selectedValue && (
						<Token
							code={
								selectedValue.code === 'All'
									? selectedValue.code
									: (selectedValue.code || '').toUpperCase()
							}
							name={selectedValue.name}
						/>
					)) || <DefaultToken />
				}
				renderOption={({ code, name, img_path }) => (
					<Token code={code === 'All' ? code : (code || '').toUpperCase()} name={name} />
				)}
			/>
			<DatepickerField
				label="Date from"
				maxDate={(values?.dateTo as Date) || null}
				name="date_from"
				inFilter
			/>
			<DatepickerField
				label="Date to"
				minDate={(values?.dateFrom as Date) || null}
				name="date_to"
				inFilter
			/>
			<TextField name="amount" type="number" label="Amount" placeholder="Enter" />
			<TextField
				name="merchant_id"
				label="Merchant ID"
				placeholder="Index"
				renderRightIcon={<SearchIcon />}
			/>
			<TextField
				name="hash_transaction"
				label="Tx Hash"
				placeholder="Index"
				renderRightIcon={<SearchIcon />}
			/>
			<SelectField
				name="currency_network"
				title="Network"
				options={[{ id: '', label: 'All' }, ...filterNetworks]}
				valueProp="id"
				labelProp="label"
				lastElem
			/>
		</div>
	);
};
