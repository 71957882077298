import { PlusIcon } from 'assets/inline-svg';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
	addNewAdminMerchantRequest,
	addNewAdminRequest,
	updateTeamMembersRequest,
	updateTeamNotProviderMembersRequest,
} from 'redux/reducers/administration/reducer';
import { getTeamMembers } from 'redux/reducers/administration/selectors';
import { IAddNewAdminPayload } from 'redux/reducers/administration/types';
import { getUserType } from 'redux/reducers/auth/selectors';
import { IProviderTeamMembersParams } from 'services/api/provider/types';
import { constUserTypes } from 'services/constants/user-types';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { notify } from 'utils/notify';
import { AddNewAdmin } from './AddNewAdmin/AddNewAdmin';
import { TAddNewAdminFormOnSubmit } from './AddNewAdmin/AddNewAdminForm/types';
import styles from './styles.module.scss';

export const Header = () => {
	const dispatch = useDispatch();
	const teamMembersMatch = useRouteMatch('/administration/team-members');
	const userType = useSelector(getUserType);

	const teamMembers = useSelector(getTeamMembers);
	const [isAdminModal, setIsAdminModal] = useState(false);

	const closeAdminModal = () => setIsAdminModal(false);

	const handleAddNewAdmin: TAddNewAdminFormOnSubmit = (values, { setSubmitting }) => {
		const { email, fullName, role } = values;

		const payload: IAddNewAdminPayload = {
			apiParams: { email, full_name: fullName, role: role?.code as string },
			onError: () => setSubmitting(false),
			onSuccess: () => {
				closeAdminModal();
				notify('The notification email was successfully sent to the user', 'success');

				if (teamMembersMatch) {
					const params: IProviderTeamMembersParams = {
						per_page: 15,
						current_page: teamMembers?.current_page || 1,
					};
					userType === constUserTypes.PROVIDER
						? dispatch(updateTeamMembersRequest(params))
						: dispatch(updateTeamNotProviderMembersRequest(params));
				}
			},
		};
		userType === constUserTypes.PROVIDER
			? dispatch(addNewAdminRequest(payload))
			: dispatch(addNewAdminMerchantRequest(payload));
	};

	return (
		<div className={styles.header}>
			<button className="button" type="button" onClick={() => setIsAdminModal(true)}>
				<span className="btn-icon">
					<PlusIcon />
				</span>
				Add a new admin
			</button>

			<PopupModal open={isAdminModal} onClose={closeAdminModal}>
				<AddNewAdmin onSubmit={handleAddNewAdmin} onClose={closeAdminModal} />
			</PopupModal>
		</div>
	);
};
