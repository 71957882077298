import { IStoreState } from 'redux/types';

export const getProviderApprovementsStore = (state: IStoreState) =>
	state.providerProjectApprovements;

export const getProviderApprovements = (state: IStoreState) =>
	getProviderApprovementsStore(state).approvements;

export const getProviderApprovementsLoading = (state: IStoreState) =>
	getProviderApprovementsStore(state).approvementsLoading;
