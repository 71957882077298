import moment from 'moment';
import { IFilter } from 'redux/reducers/app/types';
import { TApiGetProviderAllCurrencyResponse } from 'services/api/provider/types';
import { IGetCurrencyNetworkItem } from 'services/api/public/types';
import { IFilterElemItem } from 'v2Components/layoutsComponents/Filter/types';
import { transactionStatus } from './Filters/utils';

export const transactionTypeOptions = [
	{ label: 'All', value: 'all' },
	{ label: 'Deposit', value: 'deposit' },
	{ label: 'Withdrawal', value: 'withdrawal' },
	{ label: 'Receive funds', value: 'receive_funds' },
	{ label: 'Send commission', value: 'send_commission' },
	{ label: 'Refund', value: 'refund' },
	{ label: 'Return', value: 'return' },
];

export interface TransactionsHistory {
	id: string;
	value: string | number | Date | null;
	networks?: IGetCurrencyNetworkItem[];
	currencies?: TApiGetProviderAllCurrencyResponse[];
}

export const convertRequestParams = (props: IFilter, tab: string) => {
	switch (tab) {
		case 'crypto': {
			return {
				...props,
				date_from: props.date_from || undefined,
				date_to: props.date_to || undefined,
				amount_from:
					props.amount_from != null ? parseFloat(props.amount_from as string) : undefined,
				amount_to: props.amount_to != null ? parseFloat(props.amount_to as string) : undefined,
				currency_name: props.currency_name,
				type: props.type || undefined,
				transaction_id: props.transaction_id ? +props.transaction_id : undefined,
				currency_network: props.currency_network,
				status: props.status,
				tx_hash: props.tx_hash,
				risk_score: props.risk_score ? +props.risk_score : undefined,
				owner_merchant_id: props.owner_merchant_id ? +props.owner_merchant_id : undefined,
				project_id: props.project_id ? +props.project_id : undefined,
			} as IFilter;
		}
		case 'fiat':
			return {
				...props,
				date_from: props.date_from || undefined,
				date_to: props.date_to || undefined,
				amount_from: props.amount_from ? +props.amount_from : undefined,
				amount_to: props.amount_to ? +props.amount_to : undefined,
				currency_code: props.currency_code,
				type: props.type || undefined,
				transaction_id: props.transaction_id ? +props.transaction_id : undefined,
				status: props.status,
				risk_score: props.risk_score ? +props.risk_score : undefined,
				project_id: props.project_id ? +props.project_id : undefined,
				owner_merchant_id: props.owner_merchant_id ? +props.owner_merchant_id : undefined,
			};
		default:
			return {};
	}
};

export const getFilterTransactionsHistory = ({
	id,
	value,
	networks,
	currencies,
}: TransactionsHistory): IFilterElemItem => {
	switch (id) {
		case 'date_from':
			return { identify: id, content: `from: ${moment(value).format('DD MMM YY, hh:mm a')}` };
		case 'date_to':
			return { identify: id, content: `from: ${moment(value).format('DD MMM YY, hh:mm a')}` };
		case 'currency_id': {
			const content = currencies?.find((item) => item.id === value);
			return { identify: id, content: content?.name || '', label: content?.code };
		}
		case 'currency_name': {
			const content = currencies?.find((item) => item.name === value);
			return { identify: id, content: content?.name || '', label: content?.code };
		}
		case 'currency_code': {
			const content = currencies?.find((item) => item.code === value);
			return { identify: id, content: content?.name || '', label: content?.code };
		}
		case 'transaction_type': {
			const content = transactionTypeOptions.find((item) => item.value === value);
			return { identify: id, content: content?.label || '' };
		}
		case 'currency_network': {
			const content = networks?.find((item) => item.currency_network === value);
			return { identify: id, content: content?.currency_name || '', label: content?.currency_code };
		}
		case 'status': {
			const content = transactionStatus.find((item) => item.value === value);
			return { identify: id, content: content?.label || '' };
		}
		case 'project_id':
		case 'owner_merchant_id':
		case 'risk_score':
		case 'tx_hash':
		case 'type':
		case 'amount_from':
		case 'amount_to':
		case 'amount':
		case 'hash_transaction':
		case 'transaction_id':
		case 'merchant_id':
			return { identify: id, content: value as string };
		default:
			return { identify: '', content: '' };
	}
};
