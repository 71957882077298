import * as yup from 'yup';
import L from 'i18n-react';

// 📌 Validation

export const getValidationSchema = () => {
	return yup.object().shape({
		firstName: yup
			.string()
			.required(String(L.translate('Validation.full_name_required')))
			.min(2, String(L.translate('Validation.full_name_min_length'))),
		lastName: yup
			.string()
			.required(String(L.translate('Validation.last_name_required')))
			.min(2, String(L.translate('Validation.last_name_min_length'))),
		email: yup
			.string()
			.required(String(L.translate('Validation.email_required')))
			.email(String(L.translate('Validation.email_format'))),
		role: yup
			.object()
			.nullable()
			.required(String(L.translate('Validation.role_required'))),
		project: yup
			.object()
			.nullable()
			.required(String(L.translate('Validation.project_required'))),
	});
};
