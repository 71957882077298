import { FC, useState } from 'react';
import { useParams } from 'react-router';
import { PlusIcon } from 'assets/inline-svg';
import { useDispatch, useSelector } from 'react-redux';
import { getApi } from 'redux/reducers/projectSettings/selectors';
import { ICreateApiKeyPayload } from 'redux/reducers/projectSettings/types';
import { notify } from 'utils/notify';
import { createApiKeyRequest } from 'redux/reducers/projectSettings/reducer';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { trimInnerSpaces } from './utils';
import { TAddEditApiKeyFormOnSubmit } from './AddEditApiKeyForm/types';
import { ApiKeyItem } from './ApiKeyItem/ApiKeyItem';
import { AddEditApiKeyForm } from './AddEditApiKeyForm/AddEditApiKeyForm';
import { AddApiKeyResult } from './AddApiKeyResult/AddApiKeyResult';

export const ApiKeys: FC = () => {
	const dispatch = useDispatch();
	const { projectId } = useParams<{ projectId: string }>();
	const api = useSelector(getApi);
	const [isAddModal, setIsAddModal] = useState(false);
	const [isCreatedModal, setIsCreatedModal] = useState(false);

	const closeAddModal = () => setIsAddModal(false);

	const closeCreatedModal = () => setIsCreatedModal(false);

	const handleAddSubmit: TAddEditApiKeyFormOnSubmit = ({ description, ip_list, totp }) => {
		const ipListFormatted = ip_list && trimInnerSpaces(ip_list);
		const payload: ICreateApiKeyPayload = {
			apiParams: { project_id: projectId, description, ip_list: ipListFormatted, totp },
			onSuccess: () => {
				closeAddModal();
				notify('The API key has been successfully added', 'success');
				setIsCreatedModal(true);
			},
		};
		dispatch(createApiKeyRequest(payload));
	};

	return (
		<div className="api-item">
			<p className="block-title block-title--mb16">API Keys</p>
			<p className="block-text block-text--mb32">You can add and manage all your API keys here</p>

			{api?.api_keys.map((apiKey) => (
				<ApiKeyItem key={apiKey.public} item={apiKey} />
			))}

			{api?.api_keys.length === 0 && (
				<button className="button button--w209" type="button" onClick={() => setIsAddModal(true)}>
					<span className="btn-icon">
						<PlusIcon />
					</span>
					Add an API key
				</button>
			)}

			<PopupModal open={isAddModal} onClose={closeAddModal}>
				<AddEditApiKeyForm onSubmit={handleAddSubmit} onClose={closeAddModal} />
			</PopupModal>

			<PopupModal open={isCreatedModal} onClose={closeCreatedModal}>
				<AddApiKeyResult onClose={closeCreatedModal} />
			</PopupModal>
		</div>
	);
};
