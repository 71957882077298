import { FC } from 'react';
import { ProviderAvatarBoldIcon } from 'assets/inline-svg';
import { toUTC } from 'utils/dates';
import { ICommentProps } from './types';

export const Comment: FC<ICommentProps> = ({ item }) => {
	const { provider_id, created_at, comment } = item;
	return (
		<div className="approvements-user">
			<div className="approvements-user__icon">
				<ProviderAvatarBoldIcon />
			</div>
			<div className="approvements-user__info">
				<div className="approvements-user__name">
					<p>{provider_id}</p>
				</div>
				<div className="approvements-user__date">
					<p>{created_at && toUTC(new Date(created_at))}</p>
				</div>
				<div className="approvements-user__text">
					<p>{comment}</p>
				</div>
			</div>
		</div>
	);
};
