/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiToggleMerchantOwnerBlockParams,
	TApiGetMerchantOwnersResponse,
} from 'services/api/provider/types';
import {
	ICreateAccountAndProjectPayload,
	IDeleteMerchantOwnerPayload,
	IGetMerchantOwnersPayload,
	IProviderMerchantStore,
	IProviderResetPasswordPayload,
	IToggleMerchantOwnerBlockPayload,
} from './types';

export const initialState: IProviderMerchantStore = {
	merchantOwners: null,
	createMerchantAndProject: false,
	getMerchantOwnersLoading: false,
};

const providerMerchant = createSlice({
	name: '@@providerMerchant',
	initialState,
	reducers: {
		getMerchantOwnersRequest: (state, _: PayloadAction<IGetMerchantOwnersPayload>) => {
			state.getMerchantOwnersLoading = true;
			return state;
		},
		getMerchantOwnersSuccess: (state, action: PayloadAction<TApiGetMerchantOwnersResponse>) => {
			state.merchantOwners = action.payload;
			state.getMerchantOwnersLoading = false;
		},
		getMerchantOwnersError: (state) => {
			state.merchantOwners = null;
		},

		toggleMerchantOwnerBlockRequest: (state, _: PayloadAction<IToggleMerchantOwnerBlockPayload>) =>
			state,
		toggleMerchantOwnerBlockSuccess: (
			state,
			{ payload }: PayloadAction<IApiToggleMerchantOwnerBlockParams>,
		) => {
			const { id, is_block } = payload; // eslint-disable-line
			if (!state.merchantOwners?.data) {
				return;
			}
			state.merchantOwners.data = state.merchantOwners.data.map((merchant) =>
				merchant.merchant_id === id ? { ...merchant, is_blocked: is_block } : merchant,
			);
		},

		deleteMerchantOwnerRequest: (state, _: PayloadAction<IDeleteMerchantOwnerPayload>) => state,

		postMerchantAndProjectRequest: (
			state,
			action: PayloadAction<ICreateAccountAndProjectPayload>,
		) => {
			state.createMerchantAndProject = true;
		},
		postMerchantOwnProjectRequest: (
			state,
			action: PayloadAction<ICreateAccountAndProjectPayload>,
		) => {
			state.createMerchantAndProject = true;
		},
		postMerchantAndProjectSuccess: (state) => {
			state.createMerchantAndProject = false;
		},
		providerResetPasswordRequest: (state, _: PayloadAction<IProviderResetPasswordPayload>) => state,
	},
});

export default providerMerchant.reducer;
export const {
	getMerchantOwnersRequest,
	getMerchantOwnersSuccess,
	getMerchantOwnersError,
	toggleMerchantOwnerBlockRequest,
	toggleMerchantOwnerBlockSuccess,
	deleteMerchantOwnerRequest,
	postMerchantAndProjectRequest,
	postMerchantOwnProjectRequest,
	postMerchantAndProjectSuccess,
	providerResetPasswordRequest,
} = providerMerchant.actions;
