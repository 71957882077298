import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IPublicApi } from './types';

export const publicApi: IPublicApi = {
	getAvailableCurrencies: (params) =>
		http.get(endpoint.paymentWidget.GET_AVAILABLE_CURRENCIES, { params }).then(({ data }) => data),
	createPayment: (params) =>
		http.post(endpoint.paymentWidget.CREATE_PAYMENT, params).then(({ data }) => data),
	getMerchantInvoiceStatus: (params) =>
		http
			.get(endpoint.paymentWidget.GET_MERCHANT_INVOICE_STATUS, { params })
			.then(({ data }) => data),
	getCurrencyNetwork: () => http.get(endpoint.app.GET_CURRENCY_NETWORK).then(({ data }) => data),
	updatePayment: (params) =>
		http.post(endpoint.paymentWidget.GET_UPDATE_NOTIFY, params).then(({ data }) => data),
	createUpdatePayment: (params) =>
		http.post(endpoint.paymentWidget.CREATE_UPDATE_PAYMENT, params).then(({ data }) => data),
};
