/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	ICreateTRXDelegation,
	IDelegationParams,
	IDelete,
	IOverviewItem,
	IProviderTRXManagementStore,
	IStakingParams,
	TDelegationItem,
	TOverviewParams,
	TStakingItem,
} from './types';

export const initialState: IProviderTRXManagementStore = {
	delegation: null,
	delegationLoading: false,
	staking: null,
	stakingLoading: false,
	overview: null,
	overviewLoading: false,
};

const providerTRXManagement = createSlice({
	name: '@@providerTransactions',
	initialState,
	reducers: {
		getTRXDelegationRequest: (state, action: PayloadAction<IDelegationParams>) => {
			state.delegationLoading = true;
		},
		getTRXDelegationSuccess: (state, { payload }: PayloadAction<TDelegationItem>) => {
			state.delegation = payload;
			state.delegationLoading = false;
		},
		getTRXStakingRequest: (state, action: PayloadAction<IStakingParams>) => {
			state.stakingLoading = true;
		},
		getTRXStakingSuccess: (state, { payload }: PayloadAction<TStakingItem>) => {
			state.staking = payload;
			state.stakingLoading = false;
		},
		getTRXOverviewRequest: (state, action: PayloadAction<TOverviewParams>) => {
			state.overviewLoading = true;
		},
		getTRXOverviewSuccess: (state, { payload }: PayloadAction<IOverviewItem>) => {
			state.overview = payload;
			state.overviewLoading = false;
		},
		createTRXDelegationRequest: (state, action: PayloadAction<ICreateTRXDelegation>) => {},
		createTRXDelegationSuccess: (state) => {},
		deleteTRXDelegationRequest: (state, action: PayloadAction<IDelete>) => {},
		deleteTRXDelegationSuccess: (state) => {},
		cancelAllUnstakingsRequest: (state) => {},
		cancelAllUnstakingsSuccess: (state) => {},
		addUnstakeRequest: (state, action: PayloadAction<IDelete>) => {},
		addUnstakeSuccess: (state) => {},
		addStakeRequest: (state, action: PayloadAction<IStakingParams>) => {},
		addStakeSuccess: (state) => {},
	},
});

export default providerTRXManagement.reducer;
export const {
	addStakeRequest,
	addStakeSuccess,
	addUnstakeRequest,
	addUnstakeSuccess,
	cancelAllUnstakingsRequest,
	cancelAllUnstakingsSuccess,
	getTRXOverviewRequest,
	getTRXOverviewSuccess,
	getTRXDelegationRequest,
	getTRXDelegationSuccess,
	getTRXStakingRequest,
	getTRXStakingSuccess,
	createTRXDelegationRequest,
	createTRXDelegationSuccess,
	deleteTRXDelegationRequest,
	deleteTRXDelegationSuccess,
} = providerTRXManagement.actions;
