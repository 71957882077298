import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTRXOverviewRequest } from 'redux/reducers/providerTRXManagement/reducer';
import { getTRXOverviewLoading } from 'redux/reducers/providerTRXManagement/selectors';
import Loading from 'UI/Loading';
import AddressInformation from './AddressInformation';
import OverviewItem from './OverviewItem';
import styles from './styles.module.scss';

const TRXOverview = () => {
	const dispatch = useDispatch();
	const [page, setPage] = useState(0);
	const loading = useSelector(getTRXOverviewLoading);
	useEffect(() => {
		dispatch(getTRXOverviewRequest({}));
	}, [dispatch, page]);

	if (loading) {
		return <Loading />;
	}

	return (
		<div className={styles.list}>
			<AddressInformation />
			<OverviewItem />
		</div>
	);
};

export default TRXOverview;
