import { useSelector } from 'react-redux';
import {
	getProjectDetails,
	getProjectDetailsLoading,
} from 'redux/reducers/projectSettings/selectors';
import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import Loading from 'UI/Loading';
import { WidgetKey } from './WidgetKey/WidgetKey';
import styles from './styles.module.scss';

export const WidgetTab = () => {
	const projectDetails = useSelector(getProjectDetails);
	const loading = useSelector(getProjectDetailsLoading);
	if (loading) {
		return <Loading />;
	}

	return (
		<ProjectContent>
			{projectDetails && (
				<div className={styles.container}>
					<WidgetKey />
				</div>
			)}
		</ProjectContent>
	);
};
