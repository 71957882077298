import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface IApprovement {
	children: ReactNode;
	label: string;
	isLast?: boolean;
}
export default ({ children, label, isLast }: IApprovement) => {
	return (
		<div className={classNames(styles.approvement, { [styles.approvementLast]: isLast })}>
			<p className={styles.approvement__title}>{label}:</p>
			{children}
		</div>
	);
};
