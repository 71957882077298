import * as yup from 'yup';
import L from 'i18n-react';
import 'yup-phone-lite';

export const validationSchema = yup.object().shape({
	firstName: yup
		.string()
		.required('First name is required')
		.min(2, 'Too Short!')
		.max(255, 'Too Long!')
		.matches(/^[A-Za-z]+$/, `Invalid format`),
	lastName: yup
		.string()
		.required('Last name is required')
		.min(2, 'Too Short!')
		.max(255, 'Too Long!')
		.matches(/^[A-Za-z]+$/, `Invalid format`),
	companyName: yup
		.string()
		.required('Company name is required')
		.min(2, 'Too Short!')
		.max(255, 'Too Long!'),
	idMerchant: yup.string().required('Company short name is required'),
	email: yup
		.string()
		.required(String(L.translate('Validation.email_required')))
		.email(String(L.translate('Validation.email_format'))),
	phoneCode: yup.string().required('Phone code is required'),
	phoneNumber: yup
		.string()
		.when('phoneCode', (phoneCode, schema) => schema.phone(phoneCode, 'Phone is not valid'))
		.required('Phone number is required'),
	country: yup.string().required('Country is required').min(2, 'Too Short!').max(25, 'Too Long!'),
	city: yup.string().required('City is required').min(2, 'Too Short!').max(25, 'Too Long!'),
	addressLineOne: yup
		.string()
		.required('Address Line One is required')
		.min(2, 'Too Short!')
		.max(255, 'Too Long!'),
	addressLineTwo: yup.string().min(2, 'Too Short!').max(255, 'Too Long!'),
});
