import classNames from 'classnames';
import { useField } from 'formik';

export const EmailField = () => {
	const [field, meta] = useField('email');

	const isShowError = meta.touched && meta.error;

	return (
		<div className={classNames('input', { 'input--error': isShowError })}>
			<p className="input-name">Email</p>

			<div className="input-wrapper">
				<input {...field} className="input-item" placeholder="Email" />
			</div>

			{isShowError && <p className="input-notification input-notification--error">{meta.error}</p>}
		</div>
	);
};
