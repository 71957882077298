// import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
// import { useSelect } from 'hooks';
import { FC, useState, useEffect } from 'react';
import { IPropsPopUpSelectDrop } from './types';

export const SelectDropFrom: FC<IPropsPopUpSelectDrop> = ({ data, onSubmit }) => {
	const [isSelect, setIsSelect] = useState<string>(data?.length ? data[0].name : '');
	// const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	// const handleSelect = (event: MouseEvent) => {
	// 	const { name, id } = event.target as HTMLButtonElement;

	// 	setIsSelect(name);
	// 	setOpen(false);
	// 	onSubmit({ from_project_id: id });
	// };

	return (
		<div className="select-block">
			<p className="select-block__name">From</p>
			{/* ${open ? 'active' : ''} */}
			<div className={`select select--height-55 `}>
				<button
					// ref={triggerRef}
					// onClick={toggleOpen}
					type="button"
					className="select__current select__current-arrow-custom"
				>
					<span>{isSelect}</span>
					<span className="select__current-arrow">{/* <TriangleArrowDownIcon2 /> */}</span>
				</button>
				{/* <div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{data?.map((project) => (
									<li key={project.id}>
										<button
											id={String(project.id)}
											onClick={handleSelect}
											name={project.name}
											type="button"
										>
											{project.name}
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
};
