/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IComission,
	IComissionRequest,
	IGetComissionsParams,
	TComissions,
} from 'services/api/project/types';
import { IProjectComissionsStore } from './types';

export const initialState: IProjectComissionsStore = {
	comissionsLoading: false,
	comissions: null,
};

const projectComissions = createSlice({
	name: '@@projectComissions',
	initialState,
	reducers: {
		// 📌 Get comissions
		getProjectCommissionsRequest: (state, action: PayloadAction<IGetComissionsParams>) => {
			state.comissionsLoading = true;
		},
		getProjectCommissionsMerchantRequest: (state, action: PayloadAction<IGetComissionsParams>) => {
			state.comissionsLoading = true;
		},
		getProjectCommissionsSuccess: (state, action: PayloadAction<TComissions>) => {
			state.comissions = action.payload;
			state.comissionsLoading = false;
		},
		editProjectCommissionsMerchantRequest: (state, action: PayloadAction<IComissionRequest>) => {},
		editProjectCommissionsSuccess: (state, action: PayloadAction<IComission>) => {
			state.comissions?.forEach((comission) => {
				if (comission.id === action.payload.id) {
					comission.fee_percent = action.payload.fee_percent;
					comission.fee_plus_usd = action.payload.fee_plus_usd;
				}
			});
		},
		getProjectCommissionsError: (state) => {
			state.comissionsLoading = false;
		},
	},
});

export default projectComissions.reducer;
export const {
	getProjectCommissionsRequest,
	getProjectCommissionsSuccess,
	getProjectCommissionsError,
	getProjectCommissionsMerchantRequest,
	editProjectCommissionsMerchantRequest,
	editProjectCommissionsSuccess,
} = projectComissions.actions;
