/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IGetWirthdravalResponse,
	IProjectWithdrawalStore,
	IPutWirthdravalApprovelRequest,
	IProviderWithdravalayload,
	IPutApproveRejectRequest,
	IProjectWithdrawalFiatDataRequest,
	IProjectWithdrawalFiatDataResponse,
	IPutApproveResendRequest,
} from 'redux/reducers/projectWithdrawal/types';

export const initialState: IProjectWithdrawalStore = {
	withdrawal: null,
	withdrawalLoading: false,
	withdrawalLimitData: null,
	withdrawalFiat: null,
	withdrawalFiatLoading: false,
};
const projectWithdrawal = createSlice({
	name: '@@projectWithdrawal',
	initialState,
	reducers: {
		getProjectWithdrawalRequest: (state, action: PayloadAction<IProviderWithdravalayload>) => {
			state.withdrawalLoading = true;
		},
		getProjectWithdrawalSuccess: (state, { payload }: PayloadAction<IGetWirthdravalResponse>) => {
			return {
				...state,
				withdrawal: {
					...payload,
				},
				withdrawalLoading: false,
			};
		},
		getProjectWithdrawalUpdate: (state, action: PayloadAction<IProviderWithdravalayload>) => {
			state.withdrawalLoading = true;
		},
		putApprovementRequest: (state, action: PayloadAction<IPutWirthdravalApprovelRequest>) => {},
		putRejectRequest: (state, action: PayloadAction<IPutApproveRejectRequest>) => {},
		putResendRequest: (state, action: PayloadAction<IPutApproveResendRequest>) => {},
		getProjectWithdrawalFiatDataRequest: (
			state,
			action: PayloadAction<IProjectWithdrawalFiatDataRequest>,
		) => {},
		getProjectWithdrawalFiatDataSuccess: (
			state,
			{ payload }: PayloadAction<IProjectWithdrawalFiatDataResponse>,
		) => {
			state.withdrawalLimitData = payload;
		},
		getProjectWithdrawalFiatRequest: (state, action: PayloadAction<IProviderWithdravalayload>) => {
			state.withdrawalFiatLoading = true;
		},
		getProjectWithdrawalFiatSuccess: (
			state,
			{ payload }: PayloadAction<IGetWirthdravalResponse>,
		) => {
			state.withdrawalFiat = payload;
			state.withdrawalFiatLoading = false;
		},
	},
});
export default projectWithdrawal.reducer;
export const {
	getProjectWithdrawalSuccess,
	getProjectWithdrawalUpdate,
	putApprovementRequest,
	putRejectRequest,
	putResendRequest,
	getProjectWithdrawalRequest,
	getProjectWithdrawalFiatDataRequest,
	getProjectWithdrawalFiatDataSuccess,
	getProjectWithdrawalFiatSuccess,
	getProjectWithdrawalFiatRequest,
} = projectWithdrawal.actions;
