import { TdItem } from 'v2Components/commomComponents/Table/Td';
import { priceFormat, shortNotation } from 'utils/strings';
import { CopyIcon, EyeIcon } from 'assets/inline-svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notify } from 'utils/notify';
import moment from 'moment/moment';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { IStakingItem } from 'redux/reducers/providerTRXManagement/types';
import LinkDetails from 'v2Components/commomComponents/Table/Td/LinkDetails';
import styles from './styles.module.scss';

interface IProps {
	item: IStakingItem;
}
export const TableRow = ({ item }: IProps): TdItem[] => {
	const handleIbanCopy = () => notify('Address copied successfully!', 'info');
	const detailsUrl = `/trx-management/${String(item.id)}`;
	const resource = item?.meta?.raw_data?.contract?.[0]?.parameter?.value;
	return [
		{
			title: 'Resource',
			children: resource.resource || '',
		},
		{
			title: 'Address',
			children: (
				<CopyToClipboard text={String(item.owner_address)} onCopy={handleIbanCopy}>
					<div className={styles.account__value}>
						<p className={styles.copy__value}>
							{item.owner_address && shortNotation(item.owner_address, 5, 5)?.toUpperCase()}
						</p>
						<button aria-label="button" type="button" className={styles.copy}>
							<CopyIcon />
						</button>
					</div>
				</CopyToClipboard>
			),
		},
		{
			title: 'Balance Before/After',
			children: (
				<div className={styles.balance}>
					<span className={styles.balance__before}>
						{priceFormat(resource.frozen_balance || resource.unfreeze_balance)}
					</span>
					<span className={styles.balance__after}>
						{priceFormat(resource.unfreeze_balance || resource.frozen_balance)}
					</span>
				</div>
			),
		},
		{
			title: 'Created',
			children: moment(item.created_at).format('DD.MM.YYYY'),
		},
		{
			title: 'TxID',
			children: (
				<p className={styles.copy__value}>
					{item.tx_hash && shortNotation(item.tx_hash, 5, 5)?.toUpperCase()}
				</p>
			),
		},
		{
			title: 'Amount',
			children: item.amount,
		},
		{
			title: 'Type',
			children: item.staking_type,
		},
		{
			title: 'Meta',
			children: <LinkDetails detailsUrl={detailsUrl} />,
		},
	];
};
