import { FC } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { IDeleteMerchantPopup } from './types';

const DeleteMerchantPopup: FC<IDeleteMerchantPopup> = ({ onClose, onSubmit }) => {
	return (
		<div className="popup popup--width-480">
			<button aria-label="button" type="button" className="popup__close">
				<PopUpCloseIcon
					onClick={(e) => {
						if (e.target === e.currentTarget) {
							onClose();
						}
					}}
				/>
			</button>
			<div className="popup-header">
				<p className="popup-header__title">Delete merchant</p>
			</div>
			<div className="popup-body">
				<p className="popup-text">Are you sure you want to delete the merchant?</p>
			</div>
			<div className="popup-button popup-button--type2">
				<button
					onClick={() => {
						onClose();
					}}
					type="button"
					className="button button--full-width button--type2"
				>
					Cancel
				</button>
				<button
					onClick={onSubmit}
					type="button"
					className="button button--full-width button--type4"
				>
					Delete
				</button>
			</div>
		</div>
	);
};

export default DeleteMerchantPopup;
