/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { CheckIcon, PopUpCloseIcon } from 'assets/inline-svg';
import { PopUpRefund } from 'components/Merchant/Orders/PopUpRefund/PopUpRefund';
import { ChangeEvent, FC, MouseEvent, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCheckWithdrawlRequest } from 'redux/reducers/merchantAccounts/reducer';
import { getCheckAddressSelector } from 'redux/reducers/merchantAccounts/selectors';
import { createRefundMerchantRequest } from 'redux/reducers/merchantOrders/reducer';
import { createRefundRequest } from 'redux/reducers/providerOrders/reducer';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import { getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import ErrorMsg from 'UI/ErrorMsg';
import { notify } from 'utils/notify';
import { convertExponentialToDecimal } from 'utils/numbers';
import { IPropsPopUps } from '../types';

export const PopUpRefun: FC<IPropsPopUps> = ({
	onAction,
	orderId,
	setIsOpenModal,
	order,
	onSuccess,
}) => {
	const dispatch = useDispatch();
	const { merchantId } = useParams<{ merchantId: string }>();
	const [openPopup, setOpenPopup] = useState<boolean>(false);
	const [checkbox, setCheckbox] = useState<string>('');
	const [checkboxNetwork, setCheckboxNetwork] = useState<boolean>(false);
	const [amount, setAmount] = useState<string>('');
	const [address, setAddress] = useState<string>('');
	const [addressError, setAddressError] = useState<boolean>(false);
	const isValidAddress = useSelector(getCheckAddressSelector);
	const userType = useSelector(getUserType);
	const projectDetails = useSelector(getProjectDetails);
	const { name: projectName } = projectDetails || {};
	let amountTimeoutId: ReturnType<typeof setTimeout>;
	const network = order?.invoices.find(
		(item) => item.currency_paid === order.currency_paid,
	)?.currency_network;

	const isMerchant = userType === constUserTypes.MERCHANT;

	// eslint-disable-next-line consistent-return
	const handleInputs = (event: ChangeEvent) => {
		const { value, name, id } = event.target as HTMLInputElement;
		setAddressError(false);
		if (name !== 'amount') return setAddress(value);
		if (+value <= Number(id)) return setAmount(value);
		clearTimeout(amountTimeoutId);
		amountTimeoutId = setTimeout(
			() =>
				notify(`Maximum refund amount ${id} ${order?.currency_paid.toUpperCase() || ''}`, 'info'),
			500,
		);
		setAmount(id);
	};

	const handleChackbox = (event: ChangeEvent) => {
		const { name, value, id } = event.target as HTMLInputElement;
		if (name === 'is_network_fee_by_provider') {
			setCheckboxNetwork(!checkboxNetwork);
			return;
		}

		if (+id <= 0) {
			notify('Unavailable', 'info');
			return;
		}

		if (checkbox === value) {
			setCheckbox('');
			amount === id && setAmount('');
			return;
		}
		setCheckbox(value);
		setAmount(id);
	};

	const resetForm = () => {
		setCheckbox('');
		setCheckboxNetwork(false);
		setAddress('');
		setAmount('');
	};

	const onSubmit = () => {
		merchantId
			? dispatch(
					createRefundMerchantRequest({
						apiParams: {
							is_network_fee_by_project: checkboxNetwork,
							address,
							amount: +amount,
							order_merchant_id: orderId as number,
						},
						closePopup: setIsOpenModal,
						onSuccess,
					}),
			  )
			: dispatch(
					createRefundRequest({
						apiParams: {
							is_network_fee_by_provider: checkboxNetwork,
							address,
							amount: +amount,
							order_provider_id: orderId as number,
						},
						closePopup: setIsOpenModal,
						onSuccess,
					}),
			  );
	};

	useEffect(() => {
		const invoice = order?.invoices.find((item) => item.currency_paid === order.currency_paid);
		if (address && invoice) {
			dispatch(
				getCheckWithdrawlRequest({
					paramsApi: {
						address,
						currency_id: +invoice.currency_id,
						currency_network: invoice.currency_network,
					},
					setAddressError,
				}),
			);
		}

		return () => {
			document.body.style.overflow = '';
		};
	}, [address]);

	const closePopup = () => {
		setOpenPopup(false);
	};

	return openPopup ? (
		<PopUpRefund
			onSubmit={onSubmit}
			closePopup={closePopup}
			refund={amount}
			network={network as string}
		/>
	) : (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-460">
					<button
						onClick={(event: MouseEvent) => onAction(event, '')}
						type="button"
						className="popup__close"
						aria-label="button"
					>
						<PopUpCloseIcon />
					</button>
					<div className="popup-header">
						<p className="popup-header__title popup-header__title--ta-l ">Refund</p>
					</div>

					<div className="popup-body popup-body--type3">
						<div className="input input--mb-8">
							<p className="input-name">Refund Amount</p>
							<div className="input-wrapper">
								<input
									name="amount"
									id={String(order?.amount_paid || '0')}
									className="input-item "
									type="number"
									placeholder="Enter amount"
									value={amount.trim()}
									onChange={handleInputs}
								/>
							</div>
						</div>

						<div className="checkbox checkbox--mb-8 checkbox-custom">
							<label className="checkbox__label">
								<input
									// disabled={!order?.issue}
									id={String(order?.amount_paid || '0')}
									name="amount"
									type="checkbox"
									value="order_amount"
									className="hidden"
									checked={checkbox === 'order_amount'}
									onChange={handleChackbox}
								/>
								<span className="checkbox__item">
									<span className="checkbox__item-icon">
										<CheckIcon />
									</span>
								</span>
								<span className="checkbox__text">{`Order amount ${String(
									order?.amount_paid || 0,
								)} ${String(order?.currency_paid.toUpperCase() || '')}`}</span>
							</label>
						</div>
						{order?.status === 'overpaid' && (
							<div className="checkbox checkbox--mb-16 checkbox-custom">
								<label className="checkbox__label">
									<input
										disabled={Number(order?.issue) <= 0}
										id={String(order?.issue)}
										name="amount"
										type="checkbox"
										value="over_paid_amount"
										className="hidden"
										checked={checkbox === 'over_paid_amount'}
										onChange={handleChackbox}
									/>
									<span className="checkbox__item">
										<span className="checkbox__item-icon">
											<CheckIcon />
										</span>
									</span>
									<span className="checkbox__text">{`Over paid amount ${String(
										(order?.issue && convertExponentialToDecimal(order.issue)) || 0,
									)} ${String(order?.currency_paid.toUpperCase() || '')}`}</span>
								</label>
							</div>
						)}

						<div className="input input--mb-16">
							<p className="input-name">Wallet address</p>
							<div className="input-wrapper">
								<DebounceInput
									name="address"
									className="input-item "
									type="text"
									placeholder="Enter address"
									value={address.trim()}
									onChange={handleInputs}
									debounceTimeout={500}
								/>
								{!isValidAddress && address && addressError && (
									<ErrorMsg msg="Incorrect address!" />
								)}
							</div>
						</div>
					</div>
					<div className="checkbox checkbox--mb-0">
						<label className="checkbox__label">
							<input
								name="is_network_fee_by_provider"
								onChange={handleChackbox}
								type="checkbox"
								className="hidden"
								checked={checkboxNetwork}
							/>
							<span className="checkbox__item">
								<span className="checkbox__item-icon">
									<CheckIcon />
								</span>
							</span>
							<span className="checkbox__text">
								{isMerchant ? `Network Fee by (${projectName || ''})` : 'Fee by components'}
							</span>
						</label>
					</div>
					<div className="popup-button popup-button--type2">
						<button
							onClick={resetForm}
							type="button"
							className="button button--full-width button--type2"
						>
							Cancel
						</button>
						<button
							disabled={!address || !isValidAddress || !amount}
							type="button"
							className="button button--full-width"
							onClick={() => setOpenPopup(true)}
						>
							Refund
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
