import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IAuthApi, IRequest2FaReset, IRequest2FaResetProvider } from './types';

export const auth: IAuthApi = {
	createPassword: (params) =>
		http.post(endpoint.auth.CREATE_PASSWORD, params).then(({ data }) => data),
	confirmResetPass: (params) =>
		http.post(endpoint.auth.CONFIRM_RESET_PUB_PASS, params).then(({ data }) => data),
	confirIpAddress: (params) =>
		http.post(endpoint.auth.CONFIRM_IP_PUB_ADDRESS, params).then(({ data }) => data),
	forgotPasswordMerchant: (params) =>
		http.post(endpoint.auth.FORGOT_PASSWORD_MERCHANT, params).then(({ data }) => data),
	forgotPasswordProvider: (params) =>
		http.post(endpoint.auth.FORGOT_PASSWORD_PROVIDER, params).then(({ data }) => data),
	login: (params) => http.post(endpoint.auth.LOGIN, params).then(({ data }) => data),
	loginMerchant: (params) =>
		http.post(endpoint.authMerchant.LOGIN, params).then(({ data }) => data),
	logout: () => http.post(endpoint.auth.LOGOUT).then(({ data }) => data),
	logoutMerchant: () => http.post(endpoint.authMerchant.LOGOUT).then(({ data }) => data),
	firstLogin: (payload) => http.post(endpoint.auth.FIRST_LOGIN, payload).then(({ data }) => data),
	request2FaReset: (payload: IRequest2FaReset) =>
		http.post(endpoint.auth.REQUEST_2FA_RESET, payload).then(({ data }) => data),
	request2FaResetProvider: (payload: IRequest2FaResetProvider) =>
		http.post(endpoint.auth.RESET_2FA_PROVIDER, payload).then(({ data }) => data),
	request2FaResetMerchant: (payload: IRequest2FaResetProvider) =>
		http.post(endpoint.auth.RESET_2FA_MERCHANT, payload).then(({ data }) => data),
};
