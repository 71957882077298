import React, { useState } from 'react';
import { PopUpCloseIcon, TransferIcon } from 'assets/inline-svg';
import { Dialog } from '@mui/material';
import styles from './styles.module.scss';
import WithdrawalToTransfer from '../WithdrawalToTransfer';
import ModalOpen from '../components/ModalOpen';

interface IProps {
	defaultFromId: number;
}
const ModalTransfer = ({ defaultFromId }: IProps) => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => setOpen(true);
	const closePopUp = () => setOpen(false);
	return (
		<>
			<ModalOpen handleClickOpen={handleClickOpen} label="Transfer">
				<TransferIcon />
			</ModalOpen>
			<Dialog open={open} onClose={closePopUp}>
				<div className={styles.close} onClick={closePopUp}>
					<PopUpCloseIcon />
				</div>
				<WithdrawalToTransfer
					defaultFromId={defaultFromId}
					open={open}
					closePopUp={closePopUp}
					isTransfer
				/>
			</Dialog>
		</>
	);
};

export default ModalTransfer;
