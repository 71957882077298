import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IGetDataToDepositFiatResponse } from 'services/api/project/types';
import { merchantAccountsSaga } from './saga';
import {
	IDepositResponse,
	IExchangeRateFeeResponse,
	ImerchantAccountsStore,
	IProjectBalanceResponse,
	IWithdrawlFeeResponse,
	IWithdrawlLimitsResponse,
	ICurrencySettings,
	IExchangeProviderRateFeeResponse,
} from './types';

export const merchantAccountsStore = (state: IStoreState) => state.merchantAccounts;

export const getAccountLoading = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): boolean => merchantAccounts.accountLoading,
);

export const getProjectBalanceList = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): Array<IProjectBalanceResponse> | null =>
		merchantAccounts.projectBalanceList,
);
export const getProjectBalanceLoading = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): boolean => merchantAccounts.projectBalanceLoading,
);
export const getProjectToBalanceList = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): Array<IProjectBalanceResponse> | null =>
		merchantAccounts.projectToBalanceList,
);

export const getCurrenciesSelector = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): Array<ICurrencySettings> | null =>
		merchantAccounts.currencies,
);

export const getExchangeRateFeeSelector = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): IExchangeRateFeeResponse | null =>
		merchantAccounts.rateAndFee,
);

export const getExchangeProviderRateFeeSelector = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): IExchangeProviderRateFeeResponse | null =>
		merchantAccounts.rateAndFeeProvider,
);

export const getDepositSelector = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): IDepositResponse | null => merchantAccounts.deposit,
);

export const getDepositLoader = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): boolean => merchantAccounts.depositLoader,
);

export const getWithdrawlLimitsSelector = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): IWithdrawlLimitsResponse | null =>
		merchantAccounts.withdrawlLimits,
);

export const getWithdrawlFeeSelector = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): IWithdrawlFeeResponse | null =>
		merchantAccounts.withdrawlFee,
);

export const getCheckAddressSelector = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): boolean => merchantAccounts.isValidAddress,
);

export const getDataToDepositFiatLoading = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): boolean => merchantAccounts.dataToFepositFiatLoading,
);

export const getDataToDepositFiat = createSelector(
	[merchantAccountsStore],
	(merchantAccounts: ImerchantAccountsStore): IGetDataToDepositFiatResponse | null =>
		merchantAccounts.dataToFepositFiat,
);
