import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	addBankAccountRequest,
	deleteBankAccountRequest,
	editBankAccountRequect,
} from 'redux/reducers/providerBankAccount/reducer';
import { PlusIcon, UnionImg } from 'assets/inline-svg';
import { notify } from 'utils/notify';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { IBankAccountItem } from 'services/api/provider/types';
import { TAddBankAccountFormValues } from '../types';
import BankAccountItem from './BankAccountItem';
import BankAccountsForm from './BankAccountsForm';
import BankAccountsFormEdit from './BankAccountsEdit';
import DeleteBankModal from '../modal/DeleteBankModal';
import styles from './styles.module.scss';

interface IProps {
	bankList: IBankAccountItem[] | undefined;
}
export default ({ bankList }: IProps) => {
	const dispatch = useDispatch();
	const [item, setItem] = useState<any>({
		currency_id: null,
		currency_name: null,
		name_account: null,
		iban: null,
		reference: null,
		bic: null,
	});
	const [showFilter, setShowFilter] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openDelModal, setOpenDelModal] = useState(false);
	const closeDelModal = () => setOpenDelModal(false);
	const closeModal = () => setOpenModal(false);
	const openEditModal = (_item: any) => {
		setItem(_item);
		setOpenModal(true);
	};

	const openDeleteModal = (_item: any) => {
		setItem(_item);
		setOpenDelModal(true);
	};
	const handleAddBankAccount = (values: TAddBankAccountFormValues, resetForm: () => void) => {
		dispatch(
			addBankAccountRequest({
				apiParams: {
					...values,
					is_provider: true,
					currency_name: 'eur',
				},
				onSuccess: () => {
					setShowFilter(false);
					resetForm();
					notify('Bank account has been successfully added', 'success');
				},
			}),
		);
	};
	const handleDeleteBankAccount = () => {
		dispatch(
			deleteBankAccountRequest({
				apiParams: { bank_account_id: item.id },
				onSuccess: () => {
					notify('Bank account has been successfully deleted', 'success');
				},
			}),
		);
		setOpenDelModal(false);
	};
	const handleEditBankAccount = (values: TAddBankAccountFormValues) => {
		dispatch(
			editBankAccountRequect({
				apiParams: { bank_account_id: item.id, ...values },
				onSuccess: () => {
					closeModal();
					notify('Bank account has been successfully edited', 'success');
				},
			}),
		);
	};

	return (
		<div className={styles.accounts}>
			{bankList?.map((el) => (
				<BankAccountItem
					key={el.id}
					item={el}
					openEditModal={openEditModal}
					openDeleteModal={openDeleteModal}
				/>
			))}
			<div className={styles.append} onClick={() => setShowFilter(true)}>
				<div className={styles.append__icon}>
					<PlusIcon />
				</div>
				<p className={styles.append__text}>
					Add New <br /> Bank Account
				</p>
				<div className={styles.append__union}>
					<UnionImg />
				</div>
			</div>
			<PopupModal open={openDelModal} onClose={closeDelModal}>
				<DeleteBankModal onClose={closeDelModal} onSubmit={handleDeleteBankAccount} />
			</PopupModal>
			<BankAccountsFormEdit
				showFilter={openModal}
				setShowFilter={closeModal}
				onSubmit={handleEditBankAccount}
				item={item}
			/>
			<BankAccountsForm
				setShowFilter={setShowFilter}
				showFilter={showFilter}
				onSubmit={handleAddBankAccount}
			/>
		</div>
	);
};
