import { FC } from 'react';
import classNames from 'classnames';
import { LogoutIcon, ProfileMan } from 'assets/inline-svg';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutMerchantRequest, logoutRequest } from 'redux/reducers/auth/reducer';
import { constUserTypes } from 'services/constants/user-types';
import { getUserType } from 'redux/reducers/auth/selectors';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { routes } from 'routes/routes';
import { IUserMenuProps } from './types';
import styles from './styles.module.scss';

export const UserMenuV2: FC<IUserMenuProps> = ({ isOpen, menuRef, onClose, id }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);
	const [, , removewithDr] = useLocalStorage('withdrawal', '');
	const [, , removePend] = useLocalStorage('pending', '');
	const [, , removewithApprovemen] = useLocalStorage('approvement', '');

	const handleLogout = () => {
		dispatch(userType === constUserTypes.PROVIDER ? logoutRequest() : logoutMerchantRequest());
		onClose();
		if (userType === constUserTypes.PROVIDER) {
			removewithDr();
			removePend();
			removewithApprovemen();
		}
	};
	const handleInfo = () => {
		history.push(`/merchant/${String(id || '')}`);
		onClose();
	};

	return (
		<div className={classNames(styles.menu, { [styles.active]: isOpen })} ref={menuRef}>
			{userType === constUserTypes.MERCHANT && (
				<div className={styles.menu__item} onClick={handleInfo}>
					<ProfileMan />
					<span>My Profile</span>
				</div>
			)}

			<div className={classNames(styles.menu__item, styles.red)} onClick={handleLogout}>
				<LogoutIcon />
				<span>Log out</span>
			</div>
		</div>
	);
};
