import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { IRolesProjectArray } from 'redux/reducers/merchantApprovalManagement/types';
import { getRolesRequest } from 'redux/reducers/administration/reducer';
import { IRoleBlockProps } from './types';
import { RoleItem } from './RoleItem/RoleItem';

export const RoleBlock: FC<IRoleBlockProps> = ({ user }) => {
	const dispatch = useDispatch();
	const [deletePopUp, setDeletePopUp] = useState(false);

	const deletePopUpModal = () => setDeletePopUp(false);

	// const roleDescription = L.translate(
	// 	`Administration.Roles.Description.${String(user?.merchant?.role?.code)}`,
	// 	{ notFound: '' },
	// );

	useEffect(() => {
		dispatch(getRolesRequest({ payload: 'ApprovalManagement' }));
	}, [dispatch]);

	return (
		<>
			<div className="block-title-wrap">
				<div className="block-title">
					<p>Role</p>
				</div>
			</div>

			<div className="table-block">
				<div className="table table--user-profile-role-list">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>Project</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Role</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>
					<div className="table-body">
						{Array.isArray(user?.merchant?.role) &&
							user?.merchant?.role?.map((roleItem: IRolesProjectArray) => (
								<RoleItem key={roleItem.project.uuid} roleItem={roleItem} />
							))}
					</div>
				</div>
			</div>
		</>
	);
};
