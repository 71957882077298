import { FC, useState } from 'react';
import { PlusIcon } from 'assets/inline-svg';
import { useDispatch, useSelector } from 'react-redux';
import { getApi } from 'redux/reducers/projectSettings/selectors';
import { useParams } from 'react-router';
import { IGenerateCallbackSecretPayload } from 'redux/reducers/projectSettings/types';
import { notify } from 'utils/notify';
import { generateCallbackSecretRequest } from 'redux/reducers/projectSettings/reducer';
import { TotpPopup, IValues } from 'UI/Popups/TotpPopup/TotpPopup';
import { PasswordInput } from '../PasswordInput/PasswordInput';

export const CallbackSecret: FC = () => {
	const dispatch = useDispatch();
	const { projectId } = useParams<{ projectId: string }>();
	const api = useSelector(getApi);

	const [isShowPass, setIsShowPass] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	const handleGenerateNewKey = (values: IValues) => {
		const { totp } = values;
		const payload: IGenerateCallbackSecretPayload = {
			apiParams: { project_id: projectId, totp },
			onSuccess: () => {
				notify('Callback key has been successfully generated', 'success');
				setOpenModal(false);
			},
		};
		dispatch(generateCallbackSecretRequest(payload));
	};

	return (
		<div className="api-item">
			<p className="block-title block-title--mb8">Callback secret</p>
			<p className="block-text block-text--mb24">Use this key to authorize API callbacks</p>

			<div className="api-item-wrapper">
				<div className="input input--no-mb">
					<p className="input-name">Callback key</p>
					<PasswordInput
						placeholder="Callback key"
						value={api?.callback_secret || ''}
						readOnly
						showPass={isShowPass}
						onToggleShowPass={setIsShowPass}
					/>
				</div>

				<div className="fields-button fields-button--mt0 action-button--size">
					{/* <button
						className="button button--show-key"
						type="button"
						onClick={() => setIsShowPass(!isShowPass)}
					>
						{isShowPass ? 'Hide Key' : 'Show Key'}
					</button> */}

					<button className="button button--link" type="button" onClick={() => setOpenModal(true)}>
						<span className="btn-icon">
							<PlusIcon className="plus-icon--blue-color" />
						</span>
						Generate a new key
					</button>
				</div>
			</div>
			{openModal && (
				<TotpPopup onSubmit={handleGenerateNewKey} onClose={() => setOpenModal(false)} />
			)}
		</div>
	);
};
