import { FC } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getBurgerReducer } from 'redux/reducers/app/reducer';
import { getStatusBurger } from 'redux/reducers/app/selectors';
import { BurgerCloseIcon, BurgerIcon } from 'assets/inline-svg';

export const BurgerBtn: FC = () => {
	const dispatch = useDispatch();
	const statusBurger = useSelector(getStatusBurger);

	const onStatusBurger = () => {
		dispatch(getBurgerReducer(!statusBurger));
	};

	return (
		<button
			aria-label="button"
			onClick={onStatusBurger}
			type="button"
			className={classNames('nav-btn', { active: statusBurger })}
		>
			<BurgerIcon />
			<BurgerCloseIcon />
		</button>
	);
};
