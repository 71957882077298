import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProjectBalanceList,
	getProjectBalanceLoading,
} from 'redux/reducers/merchantAccounts/selectors';
import { getUserType } from 'redux/reducers/auth/selectors';
import {
	IGetDataToDepositFiatPayload,
	IProjectBalanceRequest,
	IProjectBalanceResponse,
} from 'redux/reducers/merchantAccounts/types';
import { constUserTypes } from 'services/constants/user-types';
import {
	editMerchantProjectBalanceRequest,
	editProjectBalanceRequest,
	getProjectBalanceRequest,
	getProjectMerchantBalanceRequest,
	getProjectToBalanceRequest,
} from 'redux/reducers/merchantAccounts/reducer';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Table from 'v2Components/commomComponents/Table';
import { clearFilter } from 'redux/reducers/app/reducer';
import { IGetProjectListPayload } from 'redux/reducers/merchantProjects/types';
import {
	getProjectListMerchantRequest,
	getProjectListProviderRequest,
} from 'redux/reducers/merchantProjects/reducer';
import TableRow from '../TableRowCrypto';

export default () => {
	const dispatch = useDispatch();
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('AccountsAccountsCrypto');
	const { merchantId, projectId } = useParams<{ merchantId: string; projectId: string }>();
	const loading = useSelector(getProjectBalanceLoading);
	const accounts = useSelector(getProjectBalanceList);
	const accountList = useMemo(
		() => accounts?.filter((account) => account.is_crypto === 1 && account.is_active) || [],
		[accounts],
	);
	const userType = useSelector(getUserType);
	const handleSubmit = (value: IProjectBalanceRequest, id: number, autoId: number | null) => {
		const data: IProjectBalanceRequest = {
			project_balance_id: id,
			auto_exchange_to_currency_setting_id: autoId,
			...value,
		};
		const query =
			userType === constUserTypes.PROVIDER
				? editProjectBalanceRequest
				: editMerchantProjectBalanceRequest;
		dispatch(query(data));
	};

	useEffect(() => {
		const payload: IGetProjectListPayload = {
			apiParams: { merchant_id: merchantId },
		};
		userType === constUserTypes.PROVIDER
			? dispatch(getProjectListProviderRequest(payload))
			: dispatch(getProjectListMerchantRequest(payload));
		const query =
			userType === constUserTypes.PROVIDER
				? getProjectBalanceRequest
				: getProjectMerchantBalanceRequest;
		dispatch(query({ project_id: projectId, is_crypto: 1 }));
		dispatch(
			getProjectToBalanceRequest({
				apiParams: {
					project_id: projectId,
					is_crypto: 1,
				},
				onSuccess: () => {},
			}),
		);
	}, [dispatch, merchantId, projectId, userType]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<Table
			loading={loading}
			headerItems={tableHeaderItems}
			bodyItems={accountList}
			lastWidth={228}
			getRow={(account: IProjectBalanceResponse) =>
				TableRow({
					accountList,
					account,
					handleSubmit,
				})
			}
			gridTemplateColumns={gridTemplateColumns}
		/>
	);
};
