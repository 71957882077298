import { FC, useState, useEffect } from 'react';
import { CurrencySelectItem } from 'UI/CurrencySelectItem/CurrencySelectItem';
import { useDispatch, useSelector } from 'react-redux';
import { ICurrency } from 'redux/reducers/providerCurrency/types';
import { textSearchValid } from 'utils/textSearchValid';
import { getProviderAllCurrencyRequest } from 'redux/reducers/providerCurrency/reducer';
import { getProviderCurrencyAllNoSettings } from 'redux/reducers/providerCurrency/selectors';
import { ICurrencyProps } from './types';

export const CurrencyBox: FC<ICurrencyProps> = ({ fields, setFields }) => {
	const items = useSelector(getProviderCurrencyAllNoSettings);
	const dispatch = useDispatch();
	const [search, setSearch] = useState('');

	useEffect(() => {
		dispatch(getProviderAllCurrencyRequest());
	}, [dispatch]);

	// const handleCurrency = (currency: ICurrency) => {
	// 	setFields({ ...fields, default_currency_id: currency?.id });
	// };
	const handleCurrency = (currency: ICurrency) => {
		setFields(null, 'default_currency_id', String(currency?.id));
	};

	const filterData = (searchCoin: string, data: Array<ICurrency>) => {
		return data?.filter((item: ICurrency) => textSearchValid(item?.name, searchCoin));
	};

	const data = filterData(search, items);

	return (
		<CurrencySelectItem
			selectedElArray={data}
			label="Default Currency"
			placeholder=""
			selectedElId={fields.default_currency_id || undefined}
			setSelectedToRequset={handleCurrency}
			className="select--height-58"
			setSearch={setSearch}
			search={search}
		/>
	);
};
