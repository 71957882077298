import { FC } from 'react';
import { CheckIcon } from 'assets/inline-svg';
import { IProjectSelectItem } from './types';

export const ProjectSelectItem: FC<IProjectSelectItem> = ({ item, onSelect, active }) => {
	const { name } = item;
	return (
		<button type="button" className="active" onClick={() => onSelect(item)}>
			<span>{name}</span>
			{active && (
				<span>
					<CheckIcon />
				</span>
			)}
		</button>
	);
};
