import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAddCryptoWalletPayload } from 'services/api/provider/types';
import {
	IDelBankAccountPayload,
	IEditBankAccountPayload,
	IAddBankAccountPayload,
	TBankAccountResponse,
	TCryptoWalletResponse,
	IApiGetBankAccountsParams,
	IBankAccountItem,
	IAddCryptoWalletsPayload,
	IEditCryptoWalletPayload,
	ICryptoMerchantWalletItem,
	IDeleteCryptoWalletPayload,
} from 'services/api/merchant/types';

import {
	IMerchantBankAccountStore,
	IApiGetBankAccountsNameRequest,
	IApiGetBankAccountsNameSuccess,
	IAddDepositFiatRefernceRequset,
	ICreateFiatWithdrawalRequset,
	IApiGetBankAccountsRequest,
	IApiGetBankAccountsByCurrencyResponse,
} from './types';

export const initialState: IMerchantBankAccountStore = {
	loading: false,
	bankAccountList: null,
	cryptoWalletList: null,
	bankAccountData: null,
	bankAccountByCurrency: null,
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

const merchantBankAccount = createSlice({
	name: '@@merchantBankAccount',
	initialState,
	reducers: {
		getBankAccountsByOwnerRequest: (state, _: PayloadAction<IApiGetBankAccountsParams>) => state,
		getBankAccountsRequest: (state) => state,
		getBankAccountsSuccess: (state, { payload }: PayloadAction<TBankAccountResponse>) => {
			state.bankAccountList = payload;
		},
		addBankAccountRequest: (state, _: PayloadAction<IAddBankAccountPayload>) => state,
		editBankAccountRequect: (state, _: PayloadAction<IEditBankAccountPayload>) => state,
		editBankAccountMerchantSuccess: (state, { payload }: PayloadAction<IBankAccountItem>) => {
			const result = state.bankAccountList?.map((el) =>
				el.id === payload.id ? { ...el, ...payload } : el,
			);
			if (result) {
				state.bankAccountList = result;
			}
		},
		deleteBankAccountRequest: (state, _: PayloadAction<IDelBankAccountPayload>) => state,

		getWithdrawalAddressesRequest: (state) => state,
		getWithdrawalAddressesByOwnerRequest: (state, _: PayloadAction<IApiGetBankAccountsParams>) => {
			state.loading = true;
			return state;
		},
		getWithdrawalAddressSuccess: (state, { payload }: PayloadAction<TCryptoWalletResponse>) => {
			state.loading = false;
			state.cryptoWalletList = payload;
		},
		addWithdrawalAddressRequest: (state, _: PayloadAction<IAddCryptoWalletsPayload>) => state,
		addWithdrawalAddressResponse: (
			state,
			{ payload }: PayloadAction<ICryptoMerchantWalletItem>,
		) => {
			state.cryptoWalletList?.unshift(payload);
		},
		editWithdrawalAddressRequest: (state, _: PayloadAction<IEditCryptoWalletPayload>) => state,
		editWithdrawalAddressSuccess: (
			state,
			{ payload }: PayloadAction<ICryptoMerchantWalletItem>,
		) => {
			const result = state.cryptoWalletList?.map((el) =>
				el.id === payload.id ? { ...el, ...payload } : el,
			);
			if (result) {
				state.cryptoWalletList = result;
			}
		},
		deleteWithdrawalAddressRequest: (state, _: PayloadAction<IDeleteCryptoWalletPayload>) => state,
		addCryptoWalletMerchantRequest: (state, _: PayloadAction<IAddCryptoWalletPayload>) => state,
		getBankAccountNameRequest: (state, _: PayloadAction<IApiGetBankAccountsNameRequest>) => state,
		getBankAccountNameSuccess: (
			state,
			{ payload }: PayloadAction<IApiGetBankAccountsNameSuccess>,
		) => {
			state.bankAccountData = payload;
		},
		setBankAccountName: (state) => {
			state.bankAccountData = null;
		},
		addBankAccountFiatReferenceRequest: (state, _: PayloadAction<IAddDepositFiatRefernceRequset>) =>
			state,
		createFiatWithdrawalRequest: (state, _: PayloadAction<ICreateFiatWithdrawalRequset>) => state,
		getProjectBankAccountRequest: (state, _: PayloadAction<IApiGetBankAccountsRequest>) => state,
		getProjectBankAccountSuccess: (
			state,
			{ payload }: PayloadAction<IApiGetBankAccountsByCurrencyResponse>,
		) => {
			state.bankAccountByCurrency = payload;
		},
	},
});

export default merchantBankAccount.reducer;
export const {
	getBankAccountsRequest,
	getBankAccountsByOwnerRequest,
	getBankAccountsSuccess,
	addBankAccountRequest,
	editBankAccountRequect,
	editBankAccountMerchantSuccess,
	deleteBankAccountRequest,
	getWithdrawalAddressesRequest,
	getWithdrawalAddressesByOwnerRequest,
	getWithdrawalAddressSuccess,
	addWithdrawalAddressRequest,
	addWithdrawalAddressResponse,
	editWithdrawalAddressRequest,
	editWithdrawalAddressSuccess,
	deleteWithdrawalAddressRequest,
	addCryptoWalletMerchantRequest,
	getBankAccountNameRequest,
	getBankAccountNameSuccess,
	setBankAccountName,
	addBankAccountFiatReferenceRequest,
	createFiatWithdrawalRequest,
	getProjectBankAccountRequest,
	getProjectBankAccountSuccess,
} = merchantBankAccount.actions;
