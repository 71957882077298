import { ChangeEvent, FC, useEffect, useState } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getProviderCreateWithdrawalRequest } from 'redux/reducers/providerCurrency/reducer';
import { getCheckAddressSelector } from 'redux/reducers/merchantAccounts/selectors';
import { getCheckWithdrawlRequest } from 'redux/reducers/merchantAccounts/reducer';
import { ICheckWithdrawlRequest } from 'redux/reducers/merchantAccounts/types';
import * as yup from 'yup';
import debounce from 'lodash.debounce';
import { TextField } from 'UI/Forms/TextField/TextField';
import { getProviderCurrencyAll } from 'redux/reducers/providerCurrency/selectors';
import { notify } from 'utils/notify';
import { TApiPostProviderCreateWithdrawPayload } from 'redux/reducers/providerCurrency/types';
import classNames from 'classnames';
import { IOnSubmit, IProps } from './types';
import { TypeSelect } from './TypeSelect/TypeSelect';
import { IItemOptions } from './TypeSelect/types';
import style from './styles.module.scss';

const Withdraw: FC<IProps> = ({ onClose, item, isCrypto }) => {
	const dispatch = useDispatch();
	const isValidAddress = useSelector(getCheckAddressSelector);
	const [optionsItem, setOptionsItem] = useState({
		id: item.id,
		img: item.img_path,
		currency_id: item.currency_id,
		currency_network: item.currency_network,
		currency_name: item.currency.name,
		code: item.currency.code,
		name: item.currency.name,
		hot_balance: item.hot_balance,
		provider_balance: item.provider_balance,
	});
	const initialValues = {
		amount: '',
		address: '',
		code: '',
	};

	const currencies = useSelector(getProviderCurrencyAll).filter(
		(_item) => _item.is_crypto === (isCrypto ? 1 : 0),
	);

	const onSubmit = ({ values }: IOnSubmit) => {
		const payload: TApiPostProviderCreateWithdrawPayload = {
			apiParams: {
				amount: values.amount,
				address: values.address,
				currency_id: optionsItem.currency_id,
				currency_network: optionsItem.currency_network,
				totp: values.code,
			},

			onSuccess: () => {
				notify('Index success', 'success');
				onClose();
			},
		};
		dispatch(getProviderCreateWithdrawalRequest(payload));
	};

	const validationSchema = yup.object().shape({
		amount: yup
			.number()
			.min(0.00000001, 'Amount can not be less then 0,00000001')
			.max(+optionsItem.hot_balance, `Amount can not be greater than ${optionsItem.hot_balance}`)
			.required('Amount is required'),
		address: yup
			.string()
			.required('Address is required')
			.min(1, 'Address is required')
			.max(200, 'Address is too long'),
		// code: yup
		// 	.string()
		// 	.required('Code is required')
		// 	.min(6, 'Code is required')
		// 	.max(6, 'Code is too long'),
	});

	const checkWalletAddress = debounce((payload: ICheckWithdrawlRequest) => {
		dispatch(getCheckWithdrawlRequest({ paramsApi: payload }));
	}, 500);

	const onAddressInput = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const { currency_id, currency_network } = optionsItem;
		!!value &&
			checkWalletAddress({
				address: value,
				currency_id,
				currency_network,
			});
	};
	const closePopup = (e: any) => e.target === e.currentTarget && onClose();
	const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) =>
		!/^[0-9.,]*$/.test(event.key) && event.preventDefault();

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			initialTouched={{
				amount: true,
			}}
			onSubmit={(values, actions) => {
				actions.setSubmitting(false);
				onSubmit({ values });
			}}
		>
			{({ isSubmitting, dirty, isValid, setFieldValue, values, validateForm }) => {
				const { address } = values;
				const handleMax = () => {
					setFieldValue('amount', optionsItem.hot_balance);
				};

				const handleTypeChange = (_type: IItemOptions) => {
					setOptionsItem({
						id: _type.id,
						img: _type.img,
						currency_id: _type.currency_id,
						currency_network: _type.currency_network,
						currency_name: _type.currency_name,
						code: _type.code,
						name: _type.name,
						hot_balance: _type.hot_balance,
						provider_balance: _type.provider_balance,
					});
					address &&
						checkWalletAddress({
							address,
							currency_id: _type.currency_id,
							currency_network: _type.currency_network,
						});
				};

				return (
					<Form>
						<div className={style.popup}>
							<button
								aria-label="button"
								type="button"
								className={style.popup__close}
								onClick={closePopup}
							>
								<PopUpCloseIcon onClick={closePopup} />
							</button>
							<p className={style.popup__title}>Withdrawal</p>
							<div className={style.popup__body}>
								<div className={style.select__block}>
									<p className={style.selectBlock__name}>Wallet</p>
									<TypeSelect item={optionsItem} onChange={handleTypeChange} options={currencies} />
									<div className={style.selectInfo}>
										<p className={style.selectInfo__name}>Balance:</p>
										<p className={style.selectInfo__number}>{optionsItem.hot_balance}</p>
										<button type="button" onClick={handleMax} className={style.link}>
											MAX
										</button>
									</div>
								</div>
								<TextField
									label="Amount"
									type="text"
									placeholder="Enter"
									name="amount"
									inputProps={{ onKeyPress, onBlur: validateForm }}
									rightAdornment={<p>{optionsItem.code.toUpperCase()}</p>}
									onlyNumbers
								/>
								<TextField
									label="Address"
									type="text"
									placeholder="Enter address"
									name="address"
									inputProps={{
										onInput: onAddressInput,
									}}
									customErrorMsg={
										!isValidAddress && values.address ? 'Incorrect address!' : undefined
									}
								/>
								{/* <TextField
									label="Enter the 2FA 6-digit Code"
									type="text"
									placeholder="Enter code"
									name="code"
									maxLength={6}
									onlyNumbers
								/> */}
							</div>
							<div className={style.popup__button}>
								<button
									onClick={closePopup}
									type="button"
									className={classNames(style.button, style.button_cancel)}
								>
									Cancel
								</button>
								<button
									disabled={!dirty || !isValid || isSubmitting || !isValidAddress}
									type="submit"
									className={style.button}
								>
									Send
								</button>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default Withdraw;
