import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { PlusWhiteIcon } from 'assets/inline-svg';
import { useDispatch, useSelector } from 'react-redux';
import { getClientDocuments } from 'redux/reducers/projectClients/selectors';
import {
	addClientDocumentRequest,
	getClientDocumentsRequest,
} from 'redux/reducers/projectClients/reducer';
import {
	IAddClientDocumentPayload,
	IGetClientDocumentsPayload,
} from 'redux/reducers/projectClients/types';
import { toBase64 } from 'utils/files';
import { KYCDocumentsItem } from './KYCDocumentsItem/KYCDocumentsItem';

export const KYCDocuments = () => {
	const dispatch = useDispatch();
	const { id: clientId, projectId } = useParams<{ id: string; projectId: string }>();
	const documents = useSelector(getClientDocuments);

	const [firstLoading, setFirstLoading] = useState(true);

	const clientDocumentsRequest = useCallback(() => {
		const payload: IGetClientDocumentsPayload = {
			apiParams: { client_id: clientId, project_id: +projectId },
			onSuccess: () => setFirstLoading(false),
		};
		dispatch(getClientDocumentsRequest(payload));
	}, [dispatch, clientId, projectId]);

	useEffect(() => {
		clientDocumentsRequest();
	}, [clientDocumentsRequest]);

	const handleAddDocument = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		e.target.value = '';

		if (!file?.type.startsWith('image/')) {
			return;
		}
		const fileBase64: string = await toBase64(file);

		const payload: IAddClientDocumentPayload = {
			apiParams: {
				client_id: clientId,
				file: fileBase64,
			},
			onSuccess: () => clientDocumentsRequest(),
		};
		dispatch(addClientDocumentRequest(payload));
	};

	return (
		<div className="main-info-category">
			<div className="category-title-block">
				<h4 className="category-title">KYC documents</h4>

				<label className="button">
					<span className="btn-icon">
						<PlusWhiteIcon />
					</span>
					Add Document
					{/* File input */}
					<input
						type="file"
						className="hidden"
						accept=".jpg,.jpeg,.png"
						onChange={handleAddDocument}
					/>
				</label>
			</div>

			{!firstLoading && documents?.map((item) => <KYCDocumentsItem key={item.id} item={item} />)}
		</div>
	);
};
