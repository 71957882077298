import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IGetLiquidityDespositAddressParams,
	ILiquidityDepositAddressResponse,
} from 'services/api/provider/types';
import {
	ILiquidity,
	ILiquidityRequest,
	ILiquidityResponse,
	TLiqudityProviderPayload,
	IProviderLiqudityStore,
	ITabsLiquidityResponse,
} from './types';

export const initialState: IProviderLiqudityStore = {
	liqudityList: [],
	orderInvoice: null,
	depositAddressLoading: false,
	liqudityListLoading: false,
	liqudityTabsLoading: false,
	depositAddress: null,
	liqudityTabs: null,
};
/* eslint-disable */
/* eslint-disable @typescript-eslint/no-unused-vars */
const providerLiquidity = createSlice({
	name: '@@providerLiquidity',
	initialState,
	reducers: {
		getProviderLiqudityRequest: (state, _: PayloadAction<ILiquidityRequest>) => {
			state.liqudityListLoading = true;
			return state;
		},
		getProviderLiquditySuccess: (state, { payload }: PayloadAction<ILiquidityResponse>) => {
			state.liqudityListLoading = false;
			state.liqudityList = payload;
			state.liqudityList.sort((x, y) => x.currency_id - y.currency_id);
		},
		getProviderTabsLiqudityRequest: (state) => {
			state.liqudityTabsLoading = true;
			return state;
		},
		getProviderTabsLiquditySuccess: (state, { payload }: PayloadAction<ITabsLiquidityResponse>) => {
			state.liqudityTabsLoading = false;
			state.liqudityTabs = payload.data;
		},

		putProviderLiqudityRequest: (state, _: PayloadAction<TLiqudityProviderPayload>) => state,
		putProviderLiquditySuccess: (state, action: PayloadAction<ILiquidity>) => {
			if (!state.liqudityList) return;
			let result = state.liqudityList.map((obj: ILiquidity) => {
				return Number(obj.id) === Number(action.payload.id) ? { ...action.payload } : obj;
			});
			state.liqudityList = result;
		},
		getDepositAddressRequest: (state, _: PayloadAction<IGetLiquidityDespositAddressParams>) => {
			state.depositAddressLoading = true;
		},
		getDepositAddressSuccess: (
			state,
			{ payload }: PayloadAction<ILiquidityDepositAddressResponse>,
		) => {
			state.depositAddressLoading = false;
			state.depositAddress = payload;
		},
		getDepositAddressError: (state) => {
			state.depositAddressLoading = false;
		},
	},
});
export default providerLiquidity.reducer;
export const {
	getProviderTabsLiqudityRequest,
	getProviderTabsLiquditySuccess,
	getProviderLiqudityRequest,
	getProviderLiquditySuccess,
	putProviderLiqudityRequest,
	putProviderLiquditySuccess,
	getDepositAddressRequest,
	getDepositAddressSuccess,
	getDepositAddressError,
} = providerLiquidity.actions;
