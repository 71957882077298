import { IStoreState } from 'redux/types';

export const getOrdersMerchant = (state: IStoreState) => state.merchantOrders;

export const getOrdersListSelector = (state: IStoreState) => getOrdersMerchant(state).ordersList;
export const getOrdersListSelectorLoading = (state: IStoreState) =>
	getOrdersMerchant(state).loading;
export const getOrderSelector = (state: IStoreState) => getOrdersMerchant(state).order;
export const getOrderInvoiceIdSelector = (state: IStoreState) =>
	getOrdersMerchant(state).orderInvoice?.id;
