/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IGetProjectClientParams,
	TGetProjectClientsResponse,
	IActivateClientParams,
	IGetShowClientParams,
	IGetShowClientResponse,
	IEditClientParams,
	IClient,
	TGetClientDocumentsResponse,
	IDeleteClientDocumentParams,
	IClientTransactionsParams,
	TClientTransactions,
} from 'services/api/projectClients/types';
import {
	IProjectClientStore,
	IDeleteClientPayload,
	IGetClientDocumentsPayload,
	IAddClientDocumentPayload,
} from './types';

export const initialState: IProjectClientStore = {
	clients: null,
	clientsLoading: false,
	showClient: null,
	clientTransactions: null,
	clientDocuments: null,
	showClientLoading: false,
	editClientLoading: false,
	clientTransactionsLoading: false,
	activateLoading: null,
	deleteLoading: null,
};

const projectClients = createSlice({
	name: '@@projectClients',
	initialState,
	reducers: {
		getClientsRequest: (state, action: PayloadAction<IGetProjectClientParams>) => {
			state.clientsLoading = true;
		},
		getClientsSuccess: (state, { payload }: PayloadAction<TGetProjectClientsResponse>) => {
			state.clients = payload;
			state.clientsLoading = false;
		},
		getClientsError: (state) => {
			state.clients = null;
			state.clientsLoading = false;
		},
		showClientRequest: (state, action: PayloadAction<IGetShowClientParams>) => {
			state.showClientLoading = true;
		},
		showClientSuccess: (state, { payload }: PayloadAction<IGetShowClientResponse>) => {
			state.showClientLoading = false;
			state.showClient = payload;
		},
		showClientError: (state) => {
			state.showClientLoading = false;
			state.showClient = null;
		},
		editClientRequest: (state, action: PayloadAction<IEditClientParams>) => {
			state.editClientLoading = true;
		},
		editClientSuccess: (state, { payload }: PayloadAction<IClient>) => {
			state.editClientLoading = false;
			if (!state.showClient) {
				return;
			}
			state.showClient.client = payload;
		},
		editClientError: (state) => {
			state.editClientLoading = false;
		},
		getClientTransactionsRequest: (state, action: PayloadAction<IClientTransactionsParams>) => {
			state.clientTransactionsLoading = true;
		},
		getClientTransactionsSuccess: (state, { payload }: PayloadAction<TClientTransactions>) => {
			state.clientTransactionsLoading = false;
			state.clientTransactions = payload;
		},
		getClientTransactionsError: (state) => {
			state.clientTransactionsLoading = false;
		},
		activateClientRequest: (state, { payload }: PayloadAction<IActivateClientParams>) => {
			const { client_id: clientId, is_active: isActive } = payload;
			state.activateLoading = clientId;
		},
		activateClientSuccess: (state, { payload }: PayloadAction<IActivateClientParams>) => {
			const { client_id: clientId, is_active: isActive } = payload;
			state.activateLoading = null;
			if (!state.clients?.data) {
				return;
			}
			const newData = state.clients?.data.map((item) =>
				item.id === clientId
					? {
							...item,
							is_active: isActive,
					  }
					: item,
			);
			state.clients.data = newData;
		},
		activateClientError: (state) => {
			state.activateLoading = null;
		},
		deleteClientRequest: (state, { payload }: PayloadAction<IDeleteClientPayload>) => {
			const { client_id: clientId } = payload;
			state.deleteLoading = clientId;
		},
		deleteClientSuccess: (state, { payload }: PayloadAction<IDeleteClientPayload>) => {
			state.deleteLoading = null;
		},
		deleteClientError: (state) => {
			state.deleteLoading = null;
		},

		getClientDocumentsRequest: (state, action: PayloadAction<IGetClientDocumentsPayload>) => state,
		getClientDocumentsSuccess: (state, action: PayloadAction<TGetClientDocumentsResponse>) => {
			state.clientDocuments = action.payload;
		},
		getClientDocumentsError: (state) => {
			state.clientDocuments = null;
		},

		addClientDocumentRequest: (state, action: PayloadAction<IAddClientDocumentPayload>) => state,

		deleteClientDocumentRequest: (state, action: PayloadAction<IDeleteClientDocumentParams>) =>
			state,
		deleteClientDocumentSuccess: (state, action: PayloadAction<IDeleteClientDocumentParams>) => {
			if (!state.clientDocuments) return;
			const { client_document_id } = action.payload;

			state.clientDocuments = state.clientDocuments.filter(({ id }) => {
				return id !== client_document_id;
			});
		},
	},
});

export default projectClients.reducer;
export const {
	getClientsRequest,
	getClientsSuccess,
	getClientsError,
	showClientRequest,
	showClientSuccess,
	showClientError,
	editClientRequest,
	editClientSuccess,
	editClientError,
	getClientTransactionsRequest,
	getClientTransactionsSuccess,
	getClientTransactionsError,
	activateClientRequest,
	activateClientSuccess,
	activateClientError,
	deleteClientRequest,
	deleteClientSuccess,
	deleteClientError,
	getClientDocumentsRequest,
	getClientDocumentsSuccess,
	getClientDocumentsError,
	addClientDocumentRequest,
	deleteClientDocumentRequest,
	deleteClientDocumentSuccess,
} = projectClients.actions;
