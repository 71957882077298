import { FC } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { IConfirmProps } from './types';

export const ConfirmPopup: FC<IConfirmProps> = ({ title, body, onClose, onConfirm }) => {
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-480">
					<button aria-label="button" type="button" onClick={onClose} className="popup__close">
						<PopUpCloseIcon />
					</button>
					<div className="popup-header">
						<p className="popup-header__title">{title}</p>
					</div>
					<div className="popup-body">
						<p className="popup-text">{body}</p>
					</div>
					<div className="popup-button popup-button--type2">
						<button
							type="button"
							onClick={onClose}
							className="button button--full-width button--type2"
						>
							Cancel
						</button>
						<button
							type="button"
							onClick={onConfirm}
							className="button button--full-width button--type4"
						>
							Confirm
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
