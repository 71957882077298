import { useEffect, useState } from 'react';
import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import { constUserTypes } from 'services/constants/user-types';
import { dataComissions } from 'components/Project/ComissionsProvider/data';
import { ComissionsProvider } from 'components/Project/ComissionsProvider/ComissionsProvider';
import { useDispatch, useSelector } from 'react-redux';
import {
	getComissionsLoading,
	getProjectComissions,
} from 'redux/reducers/projectComissions/selectors';
import { useParams } from 'react-router-dom';
import { getUserType } from 'redux/reducers/auth/selectors';
import {
	editProjectCommissionsMerchantRequest,
	getProjectCommissionsMerchantRequest,
	getProjectCommissionsRequest,
} from 'redux/reducers/projectComissions/reducer';
import { notify } from 'utils/notify';
import Main from 'v2Components/layoutsComponents/Main';
import styles from './styles.module.scss';

export default () => {
	const dispatch = useDispatch();
	const commissions = useSelector(getProjectComissions);
	const loading = useSelector(getComissionsLoading);
	const { projectId } = useParams<{ projectId: string }>();
	const userType = useSelector(getUserType);
	const [activeInputs, setActiveInputs] = useState<number[]>([]);
	const addActiveInput = (commissionId: number) =>
		setActiveInputs((prev) => [...prev, commissionId]);
	const onSubmit = (firstInput: string, secondInput: string, feeId: number) => {
		dispatch(
			editProjectCommissionsMerchantRequest({
				params: {
					fee_id: feeId,
					fee_percent: +firstInput,
					fee_plus_usd: +secondInput,
				},
				onSuccess: () => {
					notify('Commission successfully changed', 'success');
				},
			}),
		);
	};

	useEffect(() => {
		const query =
			userType === constUserTypes.PROVIDER
				? getProjectCommissionsRequest
				: getProjectCommissionsMerchantRequest;
		dispatch(query({ project_id: +projectId }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, projectId]);

	return (
		<Main>
			<ProjectContent>
				{!loading &&
					(userType === constUserTypes.PROVIDER ? (
						<div>
							{commissions &&
								dataComissions.map((comission, index) => (
									<ComissionsProvider
										key={comission.id}
										comissionsRespnse={commissions}
										comission={comission}
										index={index}
										onSubmit={onSubmit}
										onActive={addActiveInput}
										activeInputs={activeInputs}
									/>
								))}
						</div>
					) : (
						<div className={styles.commission__list}>
							{commissions?.map((commission, index) => (
								<div className={styles.commission} key={commission.id}>
									<p className={styles.commission__title}>{commission.fee_view_name}</p>
									<div className={styles.commission__content}>
										<div className={styles.commission__item}>
											<div className={styles.commission__label}>Fee, %</div>
											<div className={styles.commission__value}>{commission.fee_percent}</div>
										</div>
										<div className={styles.commission__item}>
											<div className={styles.commission__label}>Fee, Fixed</div>
											<div className={styles.commission__value}>{commission.fee_plus_usd}</div>
										</div>
									</div>
								</div>
							))}
						</div>
					))}
			</ProjectContent>
		</Main>
	);
};
