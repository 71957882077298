import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface TdItem {
	title?: string;
	children?: ReactNode;
}

interface IProps extends TdItem {
	isLast?: boolean;
	isRight?: boolean;
	isCenter?: boolean;
	isHeader?: boolean;
}

export const Td = ({ isLast, isRight, isCenter, title, isHeader, children }: IProps) => {
	return (
		<div
			className={classNames(
				styles.td,
				{ [styles.tdRight]: isRight },
				{ [styles.tdHeader]: isHeader },
				{ [styles.tdLast]: isLast },
				{ [styles.tdCenter]: isCenter },
			)}
		>
			<p className="td-hidden-name"> {title} </p>
			{children}
		</div>
	);
};
