import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import { getSettings } from 'redux/reducers/merchantSettings/selectors';
import { getLanguages } from 'redux/reducers/app/selectors';
import { useParams } from 'react-router-dom';
import { ISettings } from 'redux/reducers/merchantSettings/types';
import { constUserTypes } from 'services/constants/user-types';
import {
	getMerchantSettingsRequest,
	postMerchantSettingsSuccess,
} from 'redux/reducers/merchantSettings/reducer';
import { notify } from 'utils/notify';
import { getLanguagesRequest } from 'redux/reducers/app/reducer';
import { ISettingsValues } from './types';
import { SelectLanguage } from './SelectLanguage/SelectLanguage';
import { CurrencyBox } from './CurrencyBox/CurrencyBox';
import { NotificationSwitcherBox } from './NotificationSwitcherBox/NotificationSwitcherBox';
import styles from './styles.module.scss';

export default () => {
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);
	const settings = useSelector(getSettings);
	const languages = useSelector(getLanguages);
	const [languagesSelect, setLanguagesSelect] = useState('English(USA)');
	const { merchantId } = useParams<{ merchantId: string }>();
	const [fields, setFields] = useState<ISettingsValues>({
		default_currency_id: 4,
		bank_deposit_withdrawals: true,
		coin_deposit_withdrawals: true,
		high_risk_transaction: true,
		manual_coin_withdrawals: true,
		product_updates: true,
		language: 'English(USA)',
	});

	useEffect(() => {
		if (settings) {
			setFields({
				default_currency_id: Number(settings[0]?.value),
				bank_deposit_withdrawals: Boolean(settings[1]?.is_active),
				coin_deposit_withdrawals: Boolean(settings[2]?.is_active),
				high_risk_transaction: Boolean(settings[3]?.is_active),
				manual_coin_withdrawals: Boolean(settings[4]?.is_active),
				product_updates: Boolean(settings[5]?.is_active),
				language: languages ? String(languages[0]?.value) : 'English(USA)',
			});
			setLanguagesSelect(languages ? String(languages[0]?.value) : 'English(USA)');
		}
	}, [settings, languages]);

	// const onSubmit = () => {
	// const apiPayload: ISettings = {
	// 	settings: {
	// 		default_currency_id: fields.default_currency_id,
	// 		bank_deposit_withdrawals: fields.bank_deposit_withdrawals,
	// 		coin_deposit_withdrawals: fields.coin_deposit_withdrawals,
	// 		high_risk_transaction: fields.high_risk_transaction,
	// 		manual_coin_withdrawals: fields.manual_coin_withdrawals,
	// 		product_updates: fields.product_updates,
	// 		language: languagesSelect,
	// 	},
	// };
	// if (userType === constUserTypes.PROVIDER) apiPayload.merchant_id = merchantId;
	// dispatch(
	// 	postMerchantSettingsSuccess({
	// 		apiPayload,
	// 		onSuccess: () => notify('Saved', 'success'),
	// 		onError: () => notify('Not Saved', 'error'),
	// 		userType,
	// 	}),
	// );
	// };

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement> | null,
		nameFiled?: string,
		valueFiled?: string,
	) => {
		let apiPayload: ISettings = {
			settings: {
				...fields,
			},
		};
		const { name, checked } = e ? e.currentTarget : { name: undefined, checked: undefined };
		if (name) {
			setFields({ ...fields, [name]: checked });
			apiPayload = {
				settings: {
					...fields,
					[name]: checked,
					language: languagesSelect,
				},
			};
		}
		if (nameFiled && nameFiled !== 'lang') {
			setFields({ ...fields, [nameFiled]: valueFiled });
			apiPayload = {
				settings: {
					...fields,
					[nameFiled]: valueFiled,
					language: languagesSelect,
				},
			};
		}
		if (nameFiled === 'lang' && valueFiled) {
			setLanguagesSelect(valueFiled);
			apiPayload = {
				settings: {
					...fields,
					language: valueFiled,
				},
			};
		}

		if (userType === constUserTypes.PROVIDER) apiPayload.merchant_id = merchantId;
		dispatch(
			postMerchantSettingsSuccess({
				apiPayload,
				onSuccess: () => notify('Saved', 'success'),
				onError: () => {
					if (settings)
						setFields({
							default_currency_id: Number(settings[0]?.value),
							bank_deposit_withdrawals: Boolean(settings[1]?.is_active),
							coin_deposit_withdrawals: Boolean(settings[2]?.is_active),
							high_risk_transaction: Boolean(settings[3]?.is_active),
							manual_coin_withdrawals: Boolean(settings[4]?.is_active),
							product_updates: Boolean(settings[5]?.is_active),
							language: languages ? String(languages[0]?.value) : 'English(USA)',
						});
				},
				userType,
			}),
		);
	};

	useEffect(() => {
		dispatch(getMerchantSettingsRequest({ userType, params: { merchant_id: merchantId } }));
		// dispatch(getMerchantSettingsLanguagesRequest());
		dispatch(getLanguagesRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<div className={styles.main}>
			<div className="mobile-project-info">
				<div className="header-project">
					<div className="header-project__name">
						<p>Merchant company name</p>
					</div>
				</div>
			</div>
			<div className={styles.main__wrapper}>
				<div className="info--width-560">
					<SelectLanguage
						options={
							languages || [
								{ value: 'English(USA)', lng: 'English (USA)', code: 'EN' },
								{ value: 'Spanish(SP)', lng: 'Spanish', code: 'MERCHANT_ADMIN' },
								{ value: 'Ukraine(UA)', lng: 'Ukraine', code: 'MERCHANT_OPERATOR' },
								{ value: 'France(FR)', lng: 'France', code: 'MERCHANT_FINANCIER' },
							]
						}
						title="Language"
						setSelect={handleChange}
					/>

					<CurrencyBox fields={fields} setFields={handleChange} />
					<div className="notifications">
						<div className="notifications-title">
							<p>Notifications</p>
						</div>
						<NotificationSwitcherBox
							title="Bank deposit and withdrawals"
							text="Send me an email each time I make a bank deposit or withdrawals"
							value={fields.bank_deposit_withdrawals}
							name="bank_deposit_withdrawals"
							onClick={handleChange}
						/>
						<NotificationSwitcherBox
							title="Coin deposit and withdrawals"
							text="Send me an email each time I deposit and withdrawals coins"
							value={fields.coin_deposit_withdrawals}
							name="coin_deposit_withdrawals"
							onClick={handleChange}
						/>
						<NotificationSwitcherBox
							title="High-risk transaction"
							text="Send me an email each time you detect a high-risk transaction"
							value={fields.high_risk_transaction}
							name="high_risk_transaction"
							onClick={handleChange}
						/>
						<NotificationSwitcherBox
							title="Manual coin withdrawals"
							text="Send me an email notification of every manual coin withdrawal (not by means API)
						from the account"
							value={fields.manual_coin_withdrawals}
							name="manual_coin_withdrawals"
							onClick={handleChange}
						/>
						<NotificationSwitcherBox
							title="Product updates"
							text="Send me an email about product updates and other news about youur services"
							value={fields.product_updates}
							name="product_updates"
							onClick={handleChange}
						/>
					</div>
					{/* <div className="buttons buttons--type3">
					<button onClick={onSubmit} type="button" className="button">
						Save
					</button>
				</div> */}
				</div>
			</div>
		</div>
	);
};
