import { FC, useEffect, useState } from 'react';
import { Pagination } from 'UI/Pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProjectTransactionsRequest,
	createReportTransactionProjectRequest,
	exportCsvProjectTransactionsRequest,
} from 'redux/reducers/projectTransactions/reducer';
import { useParams } from 'react-router-dom';
import {
	getProjectTransactions,
	getTransactionsFilter,
	getTransactionsLoading,
} from 'redux/reducers/projectTransactions/selectors';
import {
	IMerchantGetProjectTransactionsPayload,
	ICreateReportProjectTransactionPayload,
	IExportCsvTransactionsPayload as IExportCsvProjectTransactionsPayload,
} from 'redux/reducers/projectTransactions/types';
import { notify } from 'utils/notify';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { constUserTypes } from 'services/constants/user-types';
import { getUserType } from 'redux/reducers/auth/selectors';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import useFilterList from 'hooks/useFilterList';
import {
	convertRequestParams,
	getFilterTransactionsHistory,
} from 'v2Pages/Provider/TransactionsPage/Transactions/filter.helper';
import { getFilter } from 'redux/reducers/app/selectors';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import Table from 'v2Components/commomComponents/Table';
import { IApiGetProjectTransactionsItem } from 'services/api/project/types';
import { Filters } from '../../../Filters-new/Filters';
import TableRow from './TableRow';
import styles from './styles.module.scss';

export const CryptoTable: FC = () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const transactions = useSelector(getProjectTransactions);
	const loading = useSelector(getTransactionsLoading);
	const { projectId, merchantId } = useParams<{ projectId: string; merchantId: string }>();
	const { data = [], last_page: totalPages = 1, per_page: perPage } = transactions || {};
	const filters = useSelector(getTransactionsFilter);
	const [showFilter, setShowFilter] = useState(false);
	const [page, setPage] = useState(1);
	const userType = useSelector(getUserType);
	const filterList = useFilterList(getFilterTransactionsHistory);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription(
		'ProjectTransactionsCrypto',
	);
	const {
		dateFrom,
		dateTo,
		amountFrom,
		amountTo,
		currencyName,
		type,
		transactionId,
		status,
		riskScore,
	} = filters;

	const filterValues = {
		dateFrom,
		dateTo,
		amountFrom,
		amountTo,
		currencyName,
		type,
		transactionId,
		status,
		riskScore,
		merchantId,
		projectId,
	};
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());

	const handlePageClick = (selected: number) => {
		const payload: IMerchantGetProjectTransactionsPayload = {
			apiParams: {
				project_id: projectId,
				per_page: 15,
				current_page: selected,
			},
		};
		setPage(selected);
		dispatch(getProjectTransactionsRequest(payload));
	};

	const createReport = () => {
		const payload: ICreateReportProjectTransactionPayload = {
			apiParams: {
				...filter,
				risk_score: String(filter.risk_score),
			},
			is_crypto: true,
			onSuccess: () => {
				notify('Report successfully created', 'success');
			},
		};
		dispatch(createReportTransactionProjectRequest(payload));
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const onSubmit = (data: any) => {
		setPage(1);
		dispatch(
			setFilter({
				...convertRequestParams(data, 'crypto'),
			}),
		);
		setShowFilter(false);
	};

	const downloadCSV = () => {
		const nameFile = 'Transactions';
		const isMerchant = userType === constUserTypes.MERCHANT;
		if (isMerchant) {
			const payload: IExportCsvProjectTransactionsPayload = {
				nameFile,
				apiParams: {
					project_id: projectId,
					...filterValues,
				},
				onSuccess: () => notify('CSV successfully downloaded', 'success'),
				is_crypto: true,
			};
			dispatch(exportCsvProjectTransactionsRequest(payload));
			return;
		}
		const { transactionId: _transactionId, ...rest } = filterValues;
		// const payload: IExportCsvProviderTransactionsPayload
		const payload: IExportCsvProjectTransactionsPayload = {
			nameFile,
			// apiParams: {
			// 	project_id: projectId,
			// 	owner_merchant_id: merchantId,
			// 	...rest,
			// },
			apiParams: {
				project_id: projectId,
				...filterValues,
			},
			onSuccess: () => notify('CSV successfully downloaded', 'success'),
			is_crypto: true,
		};
		dispatch(exportCsvProjectTransactionsRequest(payload));
	};

	useEffect(() => {
		dispatch(
			getProjectTransactionsRequest({
				apiParams: {
					...filter,
					project_id: projectId,
					current_page: page,
				},
			}),
		);
	}, [dispatch, page, projectId, filter]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
				className={styles.filters}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<Filters values={filterValues} />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={data || []}
				getRow={(item: IApiGetProjectTransactionsItem, index: number) =>
					TableRow({ item, number: index + 1 + (filters.page - 1) * (perPage || 0) })
				}
				gridTemplateColumns={gridTemplateColumns}
			/>
			{totalPages > 1 && (
				<PaginationWrapper>
					<Pagination
						pageCount={Number(totalPages)}
						onPageChange={handlePageClick}
						forcePage={page}
					/>
				</PaginationWrapper>
			)}
		</>
	);
};
