import { IStoreState } from 'redux/types';

export const getProjectClients = (state: IStoreState) => state.projectClients;

export const getClients = (state: IStoreState) => getProjectClients(state).clients;
export const getClientsLoading = (state: IStoreState) => getProjectClients(state).clientsLoading;
export const getShowClient = (state: IStoreState) => getProjectClients(state).showClient;
export const getShowClientLoading = (state: IStoreState) =>
	getProjectClients(state).showClientLoading;
export const getClientTransactions = (state: IStoreState) =>
	getProjectClients(state)?.clientTransactions;
export const getClientTransactionsLoading = (state: IStoreState) =>
	getProjectClients(state).clientTransactionsLoading;
export const getClientActivating = (state: IStoreState) => getProjectClients(state).activateLoading;
export const getClientDeleting = (state: IStoreState) => getProjectClients(state).deleteLoading;

export const getClientDocuments = (state: IStoreState) => getProjectClients(state).clientDocuments;
