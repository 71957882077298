import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { TrashIcon, PencilWithLineIcon } from 'assets/inline-svg';
import { EditRole } from 'UI/Popups/EditRole/EditRole';
import { TEditRoleOnSubmit } from 'UI/Popups/EditRole/types';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import DeleteUserAccount from 'v2Pages/Provider/AdministrationPage/TeamMembers/TableRow/DeleteUserAccount/DeleteUserAccount';
import { IEditMerchantRolePayload } from 'redux/reducers/merchantApprovalManagement/types';
import { notify } from 'utils/notify';
import { useParams } from 'react-router-dom';
import {
	editMerchantRoleRequest,
	getUserMerchantProfileRequest,
	deleteMerchantRoleRequest,
} from 'redux/reducers/merchantApprovalManagement/reducer';
import { getUserType } from 'redux/reducers/auth/selectors';
import { IRoleItemProps, TRoleAction } from './type';

export const RoleItem: FC<IRoleItemProps> = ({ roleItem }) => {
	const dispatch = useDispatch();
	const [action, setAction] = useState<TRoleAction>(null);
	const [deletePopUp, setDeletePopUp] = useState(false);
	const { merchantId } = useParams<{ merchantId: string }>();
	const userType = useSelector(getUserType);

	const deletePopUpModal = () => setDeletePopUp(false);

	// const roleDescription = L.translate(
	// 	`Administration.Roles.Description.${String(user?.merchant?.role?.code)}`,
	// 	{ notFound: '' },
	// );

	const closeModal = () => setAction(null);

	const handleEditSubmit: TEditRoleOnSubmit = (newRole) => {
		const payload: IEditMerchantRolePayload = {
			apiParams: {
				merchant_id: Number(merchantId),
				role: newRole.code,
				totp: '000000',
			},
			newRoleName: newRole.name,
			userType,
			onSuccess: () => {
				closeModal();
				notify('Role changed successfully', 'success');
				dispatch(getUserMerchantProfileRequest({ userType, params: { merchant_id: merchantId } }));
			},
		};
		dispatch(editMerchantRoleRequest(payload));
	};

	const handleDeleteSubmit = (iDs: number, owner_id: number | undefined) => {
		dispatch(
			deleteMerchantRoleRequest({
				owner_merchant_id: Number(owner_id),
				merchant_id: Number(merchantId),
				project_id: iDs,
				userType,
				onSuccess: () => {
					notify('User delete', 'success');
					dispatch(
						getUserMerchantProfileRequest({ userType, params: { merchant_id: merchantId } }),
					);
				},
			}),
		);
		deletePopUpModal();
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Project</p>
				<p className="td-value">
					{roleItem?.project.name}

					{/* {roleDescription && (
											<span className="tooltip-item">
												<span className="tooltip-item__icon">
													<ExclamationRoundIcon />
												</span>
												<span className="tooltip tooltip--top tooltip--center tooltip--no-arrow">
													<span className="tooltip__text">
														<span>{roleDescription}</span>
													</span>
												</span>
											</span>
										)} */}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Role</p>
				<p className="td-value">
					{roleItem?.role?.name}

					{/* {roleDescription && (
											<span className="tooltip-item">
												<span className="tooltip-item__icon">
													<ExclamationRoundIcon />
												</span>
												<span className="tooltip tooltip--top tooltip--center tooltip--no-arrow">
													<span className="tooltip__text">
														<span>{roleDescription}</span>
													</span>
												</span>
											</span>
										)} */}
				</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					<button aria-label="button" type="button" onClick={() => setAction('edit')}>
						<PencilWithLineIcon />
					</button>
					<button
						aria-label="button"
						type="button"
						onClick={() => {
							setDeletePopUp(!deletePopUp);
						}}
					>
						<TrashIcon />
					</button>
				</div>
			</div>
			<PopupModal open={action === 'edit'} onClose={closeModal}>
				<EditRole initRole={roleItem.role} onSubmit={handleEditSubmit} onClose={closeModal} />
			</PopupModal>
			<PopupModal open={deletePopUp} onClose={deletePopUpModal}>
				<DeleteUserAccount
					onClose={deletePopUpModal}
					onSubmit={handleDeleteSubmit}
					id={roleItem.project.id}
					owner_id={roleItem.project.owner_merchant_id}
				/>
			</PopupModal>
		</div>
	);
};
