/* eslint-disable @typescript-eslint/no-explicit-any */

export const toBase64 = (blob: Blob): Promise<any> => {
	return new Promise((res, rej) => {
		const reader = new FileReader();
		reader.readAsDataURL(blob);
		reader.onload = () => res(reader.result);
		reader.onerror = (error) => rej(error);
	});
};

export const downloadFile = (path: string) => {
	const link = document.createElement('a');
	link.href = path;
	link.target = '__blank';
	link.download = '';
	link.click();
};

export const splitDataUrl = (url: string) => {
	const arr = url.split(',');
	// eslint-disable-next-line prefer-const
	let [mime, data] = arr;
	mime = /:(.*?);/.exec(mime)?.[1] || '';
	return [mime, data];
};

export const dataUrlToFile = (url: string, name: string) => {
	const [mime, data] = splitDataUrl(url);

	const fileExt = mime.split('/')[1];

	const dataStr = atob(data);

	let n = dataStr.length;

	const dataArr = new Uint8Array(n);

	// eslint-disable-next-line no-plusplus
	while (n--) {
		dataArr[n] = dataStr.charCodeAt(n);
	}

	const file = new File([dataArr], `${name}.${fileExt}`, { type: mime });

	return file;
};
