import { TriangleArrowDownIcon2, CheckIcon } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { ISelectProps } from './types';

export const Select = <T extends Record<string, any>>({
	title,
	value,
	onChange,
	options,
	valueProp = 'value' as keyof T,
	labelProp = 'label' as keyof T,
	renderOption,
	renderSelected,
	placeholder,
	className = '',
	uppercase = false,
	disabled,
	role,
	helperNotification,
}: ISelectProps<T>) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (_value: string) => {
		setOpen(false);
		onChange?.(String(_value)?.toLowerCase() === 'all' ? undefined : _value);
	};

	const selectedOption = options?.find((opt) => opt[valueProp] === value);

	let selectedLabel = selectedOption?.[labelProp];

	if (uppercase) {
		selectedLabel = selectedLabel?.toUpperCase();
	}

	return (
		<div className={`select-block ${disabled ? 'disabled' : ''} ${String(className)}`}>
			<div className="select-block__title">
				<p>{title || ''}</p>
			</div>
			<div className={`select select--type-choice ${open ? 'active' : ''}`}>
				<button
					disabled={disabled}
					type="button"
					className={`select__current ${!selectedOption ? 'select__current--placeholder' : ''}`}
					ref={triggerRef}
					onClick={toggleOpen}
				>
					{(selectedOption && renderSelected?.(selectedOption)) ||
						selectedLabel ||
						role ||
						placeholder ||
						'All'}
					<span className="select__current-arrow">
						<TriangleArrowDownIcon2 />
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll select__drop-scroll--type2">
						<div className="select__drop-item">
							<ul>
								{options?.map((option, index) => {
									const label = uppercase ? option[labelProp]?.toUpperCase() : option[labelProp];
									return (
										<li key={String(option[valueProp]) + String(index)}>
											<button
												type="button"
												className={value === option[valueProp] ? 'active' : ''}
												onClick={() => handleSelect(option[valueProp])}
											>
												<span>{renderOption ? renderOption(option) : label}</span>
												<span>{value === option[valueProp] && <CheckIcon />}</span>
											</button>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
			{helperNotification}
		</div>
	);
};
