import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getProviderApprovements,
	getProviderApprovementsLoading,
} from 'redux/reducers/providerProjectApprovements/selectors';
import { getApprovementRequest } from 'redux/reducers/providerProjectApprovements/reducer';
import { Pagination } from 'UI/Pagination/Pagination';
import { useLocalStorage } from 'hooks/useLocalStorage';
import Table from 'v2Components/commomComponents/Table';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Main from 'v2Components/layoutsComponents/Main';
import { IProviderApprovementItem } from 'services/api/provider/types';
import { TableRow } from './TableRow/TableRow';
import styles from './styles.module.scss';

export const ProjectApprovementsPage = () => {
	const dispatch = useDispatch();
	const loading = useSelector(getProviderApprovementsLoading);
	const approvements = useSelector(getProviderApprovements);
	const [page, setPage] = useState(1);
	const [, setNewProjectAppointments] = useLocalStorage('pending', '');
	const { data = [], last_page: totalPages = 1 } = approvements || {};
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('ProjectApprovements');
	const fetchApprovements = useCallback(() => {
		dispatch(getApprovementRequest({ current_page: page }));
	}, [dispatch, page]);

	useEffect(() => {
		fetchApprovements();
		const fetchedApprovements: any = approvements?.data;
		fetchedApprovements && setNewProjectAppointments(fetchedApprovements);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchApprovements]);

	const isShowPagination = totalPages > 1 && !loading;
	return (
		<Main>
			<Table
				className={styles.table}
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={data}
				getRow={(item: IProviderApprovementItem) => TableRow({ item })}
				gridTemplateColumns={gridTemplateColumns}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={Number(totalPages)}
						onPageChange={setPage}
						forcePage={Number(page)}
					/>
				</PaginationWrapper>
			)}
		</Main>
	);
};
