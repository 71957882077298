import { FC } from 'react';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { PhoneInput } from 'UI/PhoneInput/PhoneInput';
import { IGetCountriesResponse } from 'services/api/app/types';
import { IPhoneInputField } from './types';

const ErrorMessage: FC<{ errorMsg: string | undefined }> = ({ errorMsg }) => (
	<p className="input-notification input-notification--error">{errorMsg}</p>
);

export const PhoneInputField: FC<IPhoneInputField> = ({
	name,
	label,
	countries,
	classNameCustom,
	customErrorMsg,
	codeFieldName,
	openToUp,
	placeholder,
}) => {
	const [field, meta] = useField(name);
	const { submitCount, setFieldValue, values, errors } = useFormikContext<any>();
	const isShowError = (meta.touched || submitCount > 0) && (meta.error || !!errors[codeFieldName]);
	const errorMsg = errors[codeFieldName] || meta.error;

	const renderErrorMsg = () => {
		if (customErrorMsg) {
			return <ErrorMessage errorMsg={customErrorMsg} />;
		}
		return isShowError && <ErrorMessage errorMsg={typeof errorMsg === 'string' ? errorMsg : ''} />;
	};

	const handleNumberChange = (_value: string) => setFieldValue(name, _value);
	const handleCodeChange = (_value: string) => setFieldValue(codeFieldName, _value);

	return (
		<div
			className={classNames(
				'input',
				{ 'input--error': customErrorMsg || (meta.error && isShowError) },
				classNameCustom,
			)}
		>
			{label && <p className="input-name">{label}</p>}

			<PhoneInput
				number={field.value}
				code={values[codeFieldName]}
				onChangeNumber={handleNumberChange}
				onChangeCode={handleCodeChange}
				countries={countries || []}
				isSelectError={!!isShowError && !!errors[codeFieldName]}
				isInputError={!!isShowError && !!meta.error}
				openToUp={openToUp}
				placeholder={placeholder}
			/>

			{renderErrorMsg()}
		</div>
	);
};
