export const statusList = [
	{
		value: 'all',
		label: 'All',
	},
	{
		value: 'new',
		label: 'New',
	},
	{
		value: 'paid',
		label: 'Paid',
	},
	{
		value: 'underpaid',
		label: 'Underpaid',
	},
	{
		value: 'overpaid',
		label: 'Overpaid',
	},
	{
		value: 'overdue',
		label: 'Overdue',
	},
	{
		value: 'returned',
		label: 'Returned',
	},
	{
		value: 'overdue_and_paid',
		label: 'Overdue & Paid',
	},
];
