import { FC } from 'react';
import classNames from 'classnames';
import { MobileProjectInfo } from '../MobileProjectInfo/MobileProjectInfo';
import { IProjectContent } from './types';

export const ProjectContent: FC<IProjectContent> = ({ children, className, id }) => {
	const rootClassName = classNames(className || null);
	return (
		<div className={rootClassName} id={id}>
			<MobileProjectInfo />
			{children}
		</div>
	);
};
