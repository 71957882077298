import { Route, Switch, useRouteMatch } from 'react-router';
import TransactionDetails from 'components/Project/ProjectTransactions/TabsContent/TransactionDetails/TransactionDetails';
import { PaymentTransactions } from 'components/Provider/PaymentTransactions/PaymentTransactions';
import { PaymentTransactionsDetails } from 'components/Provider/PaymentTransactions/PaymentTransactionDetails';
import { Tabs } from 'v2Components/commomComponents/Tabs/Tabs';
import { routes } from 'routes/routes';
import BackLink from 'components/Project/ProjectTransactions/TabsContent/TransactionDetails/BackLink';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from 'assets/inline-svg';
import Main from 'v2Components/layoutsComponents/Main';
import { endpoint } from 'services/endpoint';
import WalletHeader from './components/WalletsHeader';
import Balances from './components/TabsContent/Balances';
import TransactionsHistory from './components/TabsContent/TransactionsHistory';
import Profit from './components/TabsContent/Profit';

export const WalletsPage = () => {
	const { path } = useRouteMatch();
	const url_string = window.location.href;
	const urlObg = new URL(url_string);
	const tabsUrl = urlObg.searchParams.get('tabs') || 'crypto';
	const paymentTransactionsMatch = useRouteMatch(path + routes.wallets.paymentTransactions);
	const paymentTransactionsDetailsMatch = useRouteMatch(
		path + routes.wallets.paymentTransactionsDetails,
	);
	const transactionDetailsMatch = useRouteMatch(path + routes.wallets.transactionHistoryDetails);
	const listContent = [
		{ link: `balances?tabs=${tabsUrl}`, value: 'Balances' },
		{ link: `transaction-history?tabs=${tabsUrl}`, value: 'Transactions history' },
		{ link: `payment-transactions?tabs=${tabsUrl}`, value: 'Payment Transactions' },
		{ link: `profit?tabs=${tabsUrl}`, value: 'Profit' },
	];
	const getDownloadReportUrl = () => {
		switch (urlObg.pathname) {
			case `${routes.walletsBase}${routes.wallets.balances}`:
				return endpoint.provider.DOWNLOAD_BALANCES_REPORT;
			case `${routes.walletsBase}${routes.wallets.transactionHistory}`:
				return tabsUrl === 'crypto'
					? endpoint.provider.DOWNLOAD_PROVIDER_CRYPTO_TRANSACTIONS
					: endpoint.provider.DOWNLOAD_PROVIDER_FIAT_TRANSACTIONS;
			case `${routes.walletsBase}${routes.wallets.paymentTransactions}`:
				return endpoint.provider.DOWNLOAD_PROVIDER_PAYMENT_TRANSACTIONS;
			default:
				return '';
		}
	};
	return (
		<Main
			header={
				<>
					{transactionDetailsMatch && <BackLink isHistory />}
					{paymentTransactionsDetailsMatch && (
						<Link to="/wallets/payment-transactions" className="back-page">
							<ArrowLeftIcon />
							<span>Back to Transactions</span>
						</Link>
					)}
					{!transactionDetailsMatch && !paymentTransactionsDetailsMatch && (
						<WalletHeader
							disabled={!!paymentTransactionsMatch}
							url={urlObg}
							title="Wallets"
							downloadReportUrl={getDownloadReportUrl()}
						/>
					)}
				</>
			}
		>
			{!transactionDetailsMatch && !paymentTransactionsDetailsMatch && (
				<Tabs listContent={listContent} />
			)}
			<Switch>
				<Route
					exact
					path={`${path}${routes.wallets.balances}`}
					render={(props) => <Balances currency={tabsUrl} {...props} />}
				/>
				<Route
					exact
					path={`${path}${routes.wallets.transactionHistory}`}
					render={(props) => <TransactionsHistory currency={tabsUrl} {...props} />}
				/>
				<Route
					exact
					path={`${path}${routes.wallets.transactionHistoryDetails}`}
					render={() => <TransactionDetails isHistory />}
				/>
				<Route
					exact
					path={`${path}${routes.wallets.paymentTransactions}`}
					render={() => <PaymentTransactions />}
				/>
				<Route
					exact
					path={`${path}${routes.wallets.paymentTransactionsDetails}`}
					render={() => <PaymentTransactionsDetails />}
				/>
				<Route
					exact
					path={`${path}${routes.wallets.profit}`}
					render={(props) => <Profit currency={tabsUrl} {...props} />}
				/>
			</Switch>
		</Main>
	);
};
