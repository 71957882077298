import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserId } from 'redux/reducers/auth/selectors';
import { routes } from 'routes/routes';
import success from '../../assets/styles/images/success.png';

export const Successful: FC<{ activeCard: number }> = ({ activeCard }) => {
	const merchantId = useSelector(getUserId);

	return (
		<section className="authorization-section">
			<div className="authorization-section__figure" />
			<button type="button" className="support-btn">
				<span className="support-btn__icon">
					<svg
						width="15"
						height="16"
						viewBox="0 0 15 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.75 1.25C10.1478 1.25 10.5294 1.40804 10.8107 1.68934C11.092 1.97064 11.25 2.35217 11.25 2.75L11.25 11.75L14.25 14.75L2.25 14.75C1.85218 14.75 1.47064 14.592 1.18934 14.3107C0.908035 14.0294 0.75 13.6478 0.75 13.25L0.749999 2.75C0.749999 2.35218 0.908035 1.97064 1.18934 1.68934C1.47064 1.40804 1.85217 1.25 2.25 1.25L9.75 1.25Z"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</span>
				Support
			</button>
			<div className="authorization-box">
				<div className="form form--authorization">
					<div className="form__header">
						<div className="form-img">
							<img src={success} alt="success" />
						</div>
						<p className="form-title form-title--smaller form-title--ta-center">Successful</p>
						<div className="form-text form-text--ta-center ">
							<p>Saved. Request on Activate project sent to Provider</p>
						</div>
					</div>
					<div className="form-footer form-footer--less-mt">
						<Link
							to={`/merchant/${String(merchantId)}}/project/${activeCard}`}
							className="button button--full-width button--type3"
						>
							Project settings
						</Link>
						<Link to={routes.merchantProjectList} className="button button--full-width">
							Go to projects
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};
