import { ClockIcon, StatusInfoIcon } from 'assets/inline-svg';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

const KEY_LIVE_TIME = 29;
interface IProps {
	loading?: boolean;
	isRate?: boolean;
	getRateAndFee: () => void;
}

const Loader = () => {
	const loaderItem = Array.from({ length: 4 }, (_, index) => index);
	return (
		<div className={styles.loader}>
			{loaderItem.map((item) => (
				<div
					key={item}
					className={styles.loader__item}
					style={{
						animationDelay: `${item * 0.25}s`,
					}}
				/>
			))}
		</div>
	);
};

const ExchangeStatus = ({ loading, isRate, getRateAndFee }: IProps) => {
	const [time, setTime] = useState(KEY_LIVE_TIME);
	const timer = useMemo(
		() =>
			!loading && isRate
				? setInterval(() => {
						setTime(time - 1);
				  }, 1000)
				: null,
		[isRate, loading, time],
	);

	useEffect(() => {
		if (time <= 0 && timer) {
			getRateAndFee();
			setTime(KEY_LIVE_TIME);
		}
	}, [getRateAndFee, time, timer]);

	useEffect(() => {
		return () => {
			timer && clearInterval(timer);
		};
	}, [timer]);

	if (loading) {
		return (
			<div className={styles.status}>
				<Loader />
				<span>Loading actual exchange rate</span>
			</div>
		);
	}
	if (!isRate) {
		return (
			<div className={styles.status}>
				<StatusInfoIcon />
				<span>Choose the currencies for your transaction</span>
			</div>
		);
	}
	return (
		<div className={styles.status}>
			<ClockIcon />
			<span>Time during which the course is valid: {time} sec</span>
		</div>
	);
};

export default ExchangeStatus;
