import { CheckIcon, CloseAccountIcon, EditIcon } from 'assets/inline-svg';
import { FC } from 'react';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { IPropsAccountAction } from '../TableRowCrypto/types';
import ModalExchange from '../Modals/ModalExchange';
import ModalDeposit from '../Modals/ModalDeposit';
import ModalTransfer from '../Modals/ModalTransfer';
import ModalWithdrawal from '../Modals/ModalWithdrawal';
import styles from './styles.module.scss';

export const AccountAction: FC<IPropsAccountAction> = ({
	onSubmit,
	active,
	onEdit,
	account,
	onPopUps,
}) => {
	return (
		<TableButtons right>
			<ModalTransfer defaultFromId={account.id} />
			<ModalDeposit defaultFromId={account.id} />
			<ModalWithdrawal defaultFromId={account.id} />
			<ModalExchange defaultFromId={account.id} />
			{active === account.id ? (
				<div className={styles.buttons}>
					<button aria-label="button" className="td-check" onClick={onSubmit} type="button">
						<CheckIcon />
					</button>
					<button aria-label="button" onClick={() => onEdit(null)} type="button">
						<CloseAccountIcon />
					</button>
				</div>
			) : (
				<button
					aria-label="button"
					onClick={() => onEdit(account.id, account.currency_setting_id)}
					type="button"
				>
					<EditIcon />
				</button>
			)}
		</TableButtons>
	);
};
