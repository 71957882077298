import { FC } from 'react';
import { useSelect } from 'hooks';
import { useFormikContext, FieldProps } from 'formik';
import { useSelector } from 'react-redux';
import { getCountries } from 'redux/reducers/app/selectors';
import { TriangleArrowDownIcon2, CheckIcon } from 'assets/inline-svg';
import { IValues } from 'v2Pages/Project/ProjectClients/ProjectClientEdit/ClientEditForm/ClientEditForm';

export const CountriesSelect: FC<FieldProps> = (props) => {
	const {
		field: { name, value },
	} = props;
	const countries = useSelector(getCountries);
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const { setFieldValue } = useFormikContext<IValues>();

	const handleSelect = (_value: string) => {
		setFieldValue(name, _value);
		setOpen(false);
	};

	return (
		<div className={`select select--type-choice ${open ? 'active' : ''}`}>
			<button type="button" className="select__current" ref={triggerRef} onClick={toggleOpen}>
				{countries?.find((opt) => opt.name === value)?.name}
				<span className="select__current-arrow">
					<TriangleArrowDownIcon2 />
				</span>
			</button>
			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll select__drop-scroll--type2">
					<div className="select__drop-item">
						<ul>
							{countries?.map((option) => (
								<li key={option.id}>
									<button
										type="button"
										className={option.name === value ? 'active' : ''}
										onClick={() => handleSelect(option.name)}
									>
										<span>{option.name}</span>
										<span>{option.name === value && <CheckIcon />}</span>
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
