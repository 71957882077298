import { IStoreState } from 'redux/types';

export const getProviderTRXManagementStore = (state: IStoreState) => state.providerTRXManagement;

export const getTRXStaking = (state: IStoreState) => getProviderTRXManagementStore(state).staking;

export const getTRXStakingLoading = (state: IStoreState) =>
	getProviderTRXManagementStore(state).stakingLoading;

export const getTRXDelegation = (state: IStoreState) =>
	getProviderTRXManagementStore(state).delegation;

export const getTRXDelegationLoading = (state: IStoreState) =>
	getProviderTRXManagementStore(state).delegationLoading;

export const getTRXOverview = (state: IStoreState) => getProviderTRXManagementStore(state).overview;

export const getTRXOverviewLoading = (state: IStoreState) =>
	getProviderTRXManagementStore(state).overviewLoading;
