/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IEditProjectBalancePayload,
	IProjectBalanceItem,
	IGetDataToDepositFiatParams,
	IGetDataToDepositFiatResponse,
} from 'services/api/project/types';
import {
	IProjectBalanceResponse,
	ImerchantAccountsStore,
	IProjectBalanceRequest,
	IGetProjectBalanceRequest,
	IDataTransferRequest,
	IDataExchangeRequest,
	IDataAutoExchangeRequest,
	IDataWitdrawalRequest,
	IExchangeRateFeeRequest,
	IExchangeRateFeeResponse,
	IDepositRequest,
	IDepositResponse,
	IWithdrawlFeeRequest,
	IWithdrawlLimitsRequest,
	IWithdrawlLimitsResponse,
	IWithdrawlFeeResponse,
	ICheckWithdrawlRequest,
	ICurrencySettings,
	IExchangeProviderRateFeeResponse,
	IExchangeProviderRateFeeRequest,
	ICryptoExchange,
	ICheckWithdrawSaga,
	IGetDataToDepositFiatPayload,
	IGetProjectBalancePayload,
} from './types';

export const initialState: ImerchantAccountsStore = {
	projectBalanceList: null,
	projectToBalanceList: null,
	currencies: null,
	projectBalanceLoading: false,
	projectToBalanceLoading: false,
	accountLoading: false,
	rateAndFee: null,
	rateAndFeeProvider: null,
	deposit: null,
	depositLoader: false,
	withdrawlLimits: null,
	withdrawlFee: null,
	isValidAddress: false,
	dataToFepositFiatLoading: false,
	dataToFepositFiat: null,
};

const merchantAccounts = createSlice({
	name: '@@merchantAccounts',
	initialState,
	reducers: {
		getProjectBalanceRequest: (state, action: PayloadAction<IGetProjectBalanceRequest>) => {
			const merchantAccountsState = state;

			merchantAccountsState.projectBalanceLoading = true;
		},
		getProjectMerchantBalanceRequest: (state, action: PayloadAction<IGetProjectBalanceRequest>) => {
			const merchantAccountsState = state;

			merchantAccountsState.projectBalanceLoading = true;
		},
		getProjectBalanceSuccess: (state, action: PayloadAction<Array<IProjectBalanceResponse>>) => {
			const merchantAccountsState = state;
			const { payload } = action;

			merchantAccountsState.projectBalanceList = payload;
			merchantAccountsState.projectBalanceLoading = false;
		},
		getProjectBalanceError: (state) => {
			const merchantAccountsState = state;

			merchantAccountsState.projectBalanceLoading = false;
		},

		editProjectBalanceRequest: (state, action: PayloadAction<IProjectBalanceRequest>) => {
			const merchantAccountsState = state;

			// merchantAccountsState.projectBalanceLoading = true;
		},
		updateProjectBalanceRequest: (state, action: PayloadAction<IEditProjectBalancePayload>) => {},
		updateProjectBalanceSuccess: (state, { payload }: PayloadAction<IProjectBalanceItem>) => {
			if (!state.projectBalanceList) return;
			state.projectBalanceList = state.projectBalanceList?.map((el) =>
				el.id === payload.id ? { ...el, ...payload } : el,
			);
		},

		editMerchantProjectBalanceRequest: (state, action: PayloadAction<IProjectBalanceRequest>) => {
			const merchantAccountsState = state;

			// merchantAccountsState.projectBalanceLoading = true;
		},
		editProjectBalanceSuccess: (state, action: PayloadAction<IProjectBalanceResponse>) => {
			const merchantAccountsState = state;
			const { payload } = action;

			if (merchantAccountsState.projectBalanceList) {
				merchantAccountsState.projectBalanceList = merchantAccountsState.projectBalanceList.map(
					(balance) => {
						if (balance.id === payload.id) {
							payload.currency = balance.currency;
							return { ...balance, ...payload };
						}
						return balance;
					},
				);

				// merchantAccountsState.projectBalanceLoading = false;
			}
		},

		getCurrenciesRequest: (state) => {},
		getCurrenciesSuccess: (state, action: PayloadAction<Array<ICurrencySettings>>) => {
			const merchantAccountsState = state;
			const { payload } = action;

			merchantAccountsState.currencies = payload;
		},

		cryptoTransferRequest: (state, action: PayloadAction<IDataTransferRequest>) => {
			const merchantAccountsState = state;

			merchantAccountsState.accountLoading = true;
		},

		cryptoTransferResponse: (state, action: PayloadAction<IProjectBalanceResponse>) => {
			const { payload } = action;
			const merchantAccountsState = state;

			merchantAccountsState.projectBalanceList?.forEach((balance, index) => {
				if (balance.id === payload.id && merchantAccountsState.projectBalanceList) {
					merchantAccountsState.projectBalanceList[index].balance = payload.balance;
				}
			});
		},

		cryptoExchangeRequest: (state, action: PayloadAction<IDataExchangeRequest>) => {
			const merchantAccountsState = state;

			merchantAccountsState.accountLoading = true;
		},

		cryptoExchangeResponse: (state, action: PayloadAction<ICryptoExchange>) => {
			const { payload } = action;
			const cryptoExchangeState = state;

			cryptoExchangeState.projectBalanceList?.forEach((balance, index) => {
				if (
					balance.id === payload.from_project_balance.id &&
					cryptoExchangeState.projectBalanceList
				) {
					cryptoExchangeState.projectBalanceList[index].balance =
						payload.from_project_balance.balance;
				}
				if (
					balance.id === payload.to_project_balance.id &&
					cryptoExchangeState.projectBalanceList
				) {
					cryptoExchangeState.projectBalanceList[index].balance =
						payload.to_project_balance.balance;
				}
			});
		},

		cryptoAutoExchangeRequest: (state, action: PayloadAction<IDataAutoExchangeRequest>) => {
			const merchantAccountsState = state;

			merchantAccountsState.accountLoading = true;
		},

		cryptoWitdrawalRequest: (state, action: PayloadAction<IDataWitdrawalRequest>) => {
			const merchantAccountsState = state;

			merchantAccountsState.accountLoading = true;
		},

		cryptoWitdrawalResponse: (
			state,
			{ payload }: PayloadAction<{ id: number; balance_after: number }>,
		) => {
			const merchantAccountsState = state;
			merchantAccountsState.projectBalanceList?.forEach((balance, index) => {
				if (balance.id === payload.id && state.projectBalanceList) {
					state.projectBalanceList[index].balance = payload.balance_after;
				}
			});
			merchantAccountsState.accountLoading = false;
		},

		cryptoDepositRequest: (state, action: PayloadAction<IDepositRequest>) => {
			const merchantAccountsState = state;

			merchantAccountsState.depositLoader = true;
			merchantAccountsState.accountLoading = true;
		},

		cryptoDepositResponse: (
			state,
			action: PayloadAction<{ data: IDepositResponse; id: number }>,
		) => {
			const merchantAccountsState = state;
			const { payload } = action;

			merchantAccountsState.accountLoading = true;
			merchantAccountsState.deposit = payload.data;
			merchantAccountsState.depositLoader = false;
			if (merchantAccountsState.projectBalanceList) {
				merchantAccountsState.projectBalanceList.forEach((balance, index) => {
					// eslint-disable-next-line no-param-reassign
					if (balance.id === payload.id) balance.deposit_address = payload.data.address;
				});
			}
		},

		getExchangeRateFeeRequest: (state, action: PayloadAction<IExchangeRateFeeRequest>) => {
			const merchantAccountsState = state;

			merchantAccountsState.accountLoading = true;
		},

		getExchangeRateFeeRespose: (state, action: PayloadAction<IExchangeRateFeeResponse | null>) => {
			const merchantAccountsState = state;
			const { payload } = action;
			merchantAccountsState.accountLoading = false;
			merchantAccountsState.rateAndFee = payload;
		},

		clearExchangeRateFee: (state) => {
			state.rateAndFee = null;
			state.accountLoading = false;
		},

		getExchangeProviderRateFeeRequest: (
			state,
			action: PayloadAction<IExchangeProviderRateFeeRequest>,
		) => {
			const merchantAccountsState = state;

			merchantAccountsState.accountLoading = true;
		},

		getExchangeProviderRateFeeRespose: (
			state,
			action: PayloadAction<IExchangeProviderRateFeeResponse | null>,
		) => {
			const merchantAccountsState = state;
			const { payload } = action;

			merchantAccountsState.accountLoading = false;
			merchantAccountsState.rateAndFeeProvider = payload;
		},

		getWithdrawlLimitsRequest: (state, action: PayloadAction<IWithdrawlLimitsRequest>) => {
			const merchantAccountsState = state;

			merchantAccountsState.accountLoading = true;
		},

		getWithdrawlLimitsRespose: (state, action: PayloadAction<IWithdrawlLimitsResponse>) => {
			const merchantAccountsState = state;
			const { payload } = action;

			merchantAccountsState.accountLoading = false;
			merchantAccountsState.withdrawlLimits = payload;
		},

		getWithdrawlFeeRequest: (state, action: PayloadAction<IWithdrawlFeeRequest>) => {
			const merchantAccountsState = state;

			merchantAccountsState.accountLoading = true;
		},

		getWithdrawlFeeRespose: (state, action: PayloadAction<IWithdrawlFeeResponse | null>) => {
			const merchantAccountsState = state;
			const { payload } = action;

			merchantAccountsState.accountLoading = false;
			merchantAccountsState.withdrawlFee = payload;
		},

		clearWithdrawalFee: (state) => {
			state.withdrawlFee = null;
		},

		getCheckWithdrawlRequest: (state, action: PayloadAction<ICheckWithdrawSaga>) => {
			const merchantAccountsState = state;

			merchantAccountsState.accountLoading = true;
		},

		getCheckWithdrawlRespose: (state, action: PayloadAction<boolean>) => {
			const merchantAccountsState = state;
			const { payload } = action;

			merchantAccountsState.accountLoading = false;
			merchantAccountsState.isValidAddress = payload;
		},
		getProjectMerchantToBalanceRequest: (
			state,
			action: PayloadAction<IGetProjectBalancePayload>,
		) => {
			const merchantAccountsState = state;

			merchantAccountsState.projectToBalanceLoading = true;
		},
		getProjectToBalanceRequest: (state, action: PayloadAction<IGetProjectBalancePayload>) => {
			const merchantAccountsState = state;

			merchantAccountsState.projectToBalanceLoading = true;
		},
		getProjectToBalanceSuccess: (state, action: PayloadAction<Array<IProjectBalanceResponse>>) => {
			const merchantAccountsState = state;
			const { payload } = action;

			merchantAccountsState.projectToBalanceList = payload;
			merchantAccountsState.projectToBalanceLoading = false;
		},
		getDataToDepositFiatRequest: (state, action: PayloadAction<IGetDataToDepositFiatPayload>) => {
			const merchantAccountsState = state;

			merchantAccountsState.dataToFepositFiatLoading = true;
		},
		getDataToDepositFiatSuccess: (state, action: PayloadAction<IGetDataToDepositFiatResponse>) => {
			const merchantAccountsState = state;
			const { payload } = action;

			merchantAccountsState.dataToFepositFiatLoading = false;
			merchantAccountsState.dataToFepositFiat = payload;
		},
		getDataToDepositFiatError: (state) => {
			const merchantAccountsState = state;

			merchantAccountsState.dataToFepositFiatLoading = false;
		},
	},
});

export default merchantAccounts.reducer;
export const {
	getProjectBalanceRequest,
	getProjectBalanceSuccess,
	editProjectBalanceRequest,
	editMerchantProjectBalanceRequest,
	editProjectBalanceSuccess,
	getCurrenciesRequest,
	getCurrenciesSuccess,
	cryptoTransferRequest,
	cryptoTransferResponse,
	cryptoExchangeRequest,
	cryptoExchangeResponse,
	cryptoAutoExchangeRequest,
	cryptoWitdrawalRequest,
	cryptoWitdrawalResponse,
	cryptoDepositRequest,
	getExchangeRateFeeRequest,
	getExchangeRateFeeRespose,
	cryptoDepositResponse,
	getWithdrawlLimitsRequest,
	getWithdrawlLimitsRespose,
	getWithdrawlFeeRequest,
	getWithdrawlFeeRespose,
	getCheckWithdrawlRequest,
	getCheckWithdrawlRespose,
	getProjectMerchantBalanceRequest,
	getExchangeProviderRateFeeRequest,
	getExchangeProviderRateFeeRespose,
	getProjectToBalanceRequest,
	getProjectMerchantToBalanceRequest,
	getProjectToBalanceSuccess,
	updateProjectBalanceRequest,
	updateProjectBalanceSuccess,
	getProjectBalanceError,
	getDataToDepositFiatRequest,
	getDataToDepositFiatSuccess,
	getDataToDepositFiatError,
	clearExchangeRateFee,
	clearWithdrawalFee,
} = merchantAccounts.actions;
