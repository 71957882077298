/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TMerchantInvoices,
	IMerchantInvoicesItem,
	IGetInvoiceParams,
	IGetInvoiceResponse,
	IGetInvoiceStatusResponse,
} from 'services/api/merchant/types';
import {
	IMerchantInvoicesStore,
	IGetMerchantInvoicesPayload,
	ICreateInvoicePayload,
	IDownloadInvoicePDFPayload,
	ISendInvoicePayload,
	IGetInvoiceLinkPayload,
	IDownloadCSVMerchantInvoicesPayload,
	IGetInvoiceStatusPayload,
} from './types';

export const initialState: IMerchantInvoicesStore = {
	merchantInvoices: null,
	merchantInvoicesLoading: false,
	createInvoiceLoading: false,
	pdfFile: null,
	saveLoading: false,
	invoiceLoading: false,
	invoice: null,
	downloadCSVLoading: false,
	status: null,
};

const merchantInvoices = createSlice({
	name: '@@merchantInvoices',
	initialState,
	reducers: {
		// 📌 Get invoices
		getMerchantInvoicesRequest: (state, action: PayloadAction<IGetMerchantInvoicesPayload>) => {
			state.merchantInvoicesLoading = true;
		},
		getMerchantInvoicesSuccess: (state, action: PayloadAction<TMerchantInvoices>) => {
			state.merchantInvoices = action.payload;
			state.merchantInvoicesLoading = false;
		},
		getMerchantInvoicesError: (state) => {
			state.merchantInvoicesLoading = false;
		},
		// 📌 Create invoice
		createMerchantInvoiceRequest: (state, action: PayloadAction<ICreateInvoicePayload>) => {
			state.createInvoiceLoading = true;
		},
		createMerchantInvoiceSuccess: (state, action: PayloadAction<IMerchantInvoicesItem>) => {
			state.createInvoiceLoading = true;
		},
		createMerchantInvoiceError: (state) => {
			state.createInvoiceLoading = true;
		},
		// 📌 Download invoice
		downloadMerchantInvoicePDFRequest: (
			state,
			action: PayloadAction<IDownloadInvoicePDFPayload>,
		) => {
			state.saveLoading = true;
		},
		downloadMerchantInvoicePDFSuccess: (state, action: PayloadAction<any>) => {
			state.saveLoading = false;
			state.pdfFile = action;
		},
		downloadMerchantInvoicePDFError: (state) => {
			state.saveLoading = false;
		},
		// 📌 Send invoice
		sendMerchantInvoiceRequest: (state, action: PayloadAction<ISendInvoicePayload>) => {
			state.saveLoading = true;
		},
		sendMerchantInvoiceSuccess: (state, action: PayloadAction<any>) => {
			state.saveLoading = false;
		},
		sendMerchantInvoiceError: (state) => {
			state.saveLoading = false;
		},
		getInvoiceLinkRequest: (state, action: PayloadAction<IGetInvoiceLinkPayload>) => {
			state.saveLoading = true;
		},
		getInvoiceLinkSuccess: (state, action: PayloadAction<string>) => {
			state.saveLoading = false;
		},
		getInvoiceLinkError: (state) => {
			state.saveLoading = false;
		},
		getInvoiceRequest: (state, action: PayloadAction<IGetInvoiceParams>) => {
			state.invoiceLoading = true;
		},
		getInvoiceSuccess: (state, action: PayloadAction<IGetInvoiceResponse>) => {
			state.invoiceLoading = false;
			state.invoice = action.payload;
		},
		getInvoiceError: (state) => {
			state.invoiceLoading = false;
		},
		getInvoiceStatusRequest: (state, action: PayloadAction<IGetInvoiceStatusPayload>) => {},
		getInvoiceStatusSuccess: (state, { payload }: PayloadAction<IGetInvoiceStatusResponse>) => {
			const { status } = payload;
			state.status = status;
		},
		downloadCSVRequest: (state, action: PayloadAction<IDownloadCSVMerchantInvoicesPayload>) => {
			state.downloadCSVLoading = true;
		},
		downloadCSVSuccess: (state, action: PayloadAction<string>) => {
			state.downloadCSVLoading = false;
		},
		downloadCSVError: (state) => {
			state.downloadCSVLoading = false;
		},
	},
});

export default merchantInvoices.reducer;
export const {
	getMerchantInvoicesRequest,
	getMerchantInvoicesSuccess,
	getMerchantInvoicesError,
	createMerchantInvoiceRequest,
	createMerchantInvoiceSuccess,
	createMerchantInvoiceError,
	downloadMerchantInvoicePDFRequest,
	downloadMerchantInvoicePDFSuccess,
	downloadMerchantInvoicePDFError,
	sendMerchantInvoiceRequest,
	sendMerchantInvoiceSuccess,
	sendMerchantInvoiceError,
	getInvoiceLinkRequest,
	getInvoiceLinkSuccess,
	getInvoiceLinkError,
	getInvoiceRequest,
	getInvoiceSuccess,
	getInvoiceError,
	getInvoiceStatusRequest,
	getInvoiceStatusSuccess,
	downloadCSVRequest,
	downloadCSVSuccess,
	downloadCSVError,
} = merchantInvoices.actions;
