import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Button from 'v2Components/UI/Button';
import { ArrowDownIcon } from 'assets/inline-svg';
import { deleteTRXDelegationRequest } from 'redux/reducers/providerTRXManagement/reducer';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

const stakingItems = [
	{ value: 'BANDWIDTH', label: 'bandwidth' },
	{ value: 'ENERGY', label: 'energy' },
];

interface IProps {
	id: number;
}

const DropdownDialogItem = ({ id }: IProps) => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const remove = () => {
		dispatch(deleteTRXDelegationRequest({ id }));
		handleClose();
	};
	return (
		<>
			<Button type="button" tableAction onClick={handleOpen}>
				<ArrowDownIcon />
				Undelegate
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				className={styles.dialog}
				PaperProps={{ classes: styles.dialog }}
			>
				<DialogTitle className={styles.dialog__title}>Undelegate</DialogTitle>
				<DialogContent className={styles.dialog__content} />
				<DialogActions className={styles.dialog__actions}>
					<Button noBorder type="button" onClick={handleClose} className={styles.cancel}>
						Cancel
					</Button>
					<Button type="button" onClick={remove}>
						Undelegate
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DropdownDialogItem;
