import { FC } from 'react';
import T from 'i18n-react';
import Over from 'assets/inline-svg/payment/payment-3.svg';
import { IPaymentError } from './types';
import { BackButton } from '../Buttons/BackButton';

export const PaymentError: FC<IPaymentError> = ({ networks, payment }) => {
	return (
		<div className="payment-boxes">
			<div className="payment-conf">
				{payment === 'success' ? (
					<span className="payment-title">
						<img src={Over} alt="icon" />
						{T.translate('Error.title')}
					</span>
				) : (
					<span className="payment-title">{T.translate('Error.text')}</span>
				)}
				<span className="payment-text">{T.translate('Error.text_01')}</span>
				<BackButton />
			</div>
		</div>
	);
};
