import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { OptionItem } from './OptionItem';
import { ISelectProps } from './types';

export const CurrencyNetworkSelect = <V extends string | number | null>({
	options,
	title,
	value,
	onChange,
	onClick,
	valueProp = 'id',
	helperNotification,
	withNetworkName,
}: ISelectProps<V>) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (_value: V) => {
		setOpen(false);
		onChange?.(_value);
	};

	const handleClick = () => {
		toggleOpen();
		onClick?.();
	};

	const selectedValue = options?.find((opt) => opt[valueProp] === value);

	return (
		<div className="select-block">
			<div className="select-block__name">
				<p>{title}</p>
			</div>
			<div className={`select select--height-55 ${open ? 'active' : ''}`}>
				<button type="button" className="select__current" ref={triggerRef} onClick={handleClick}>
					{selectedValue && <OptionItem option={selectedValue} withNetworkName={withNetworkName} />}
					<span className="select__current-arrow">
						<TriangleArrowDownIcon2 />
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{options?.map((option, index) => (
									<li key={String(option.id) + String(index)}>
										<button
											aria-label="button"
											type="button"
											className={value === option[valueProp] ? 'active' : ''}
											onClick={() => handleSelect(option[valueProp] as V)}
										>
											<OptionItem option={option} withNetworkName={withNetworkName} />
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			{helperNotification}
		</div>
	);
};
