import { FC } from 'react';
import ReactPaginate from 'react-paginate';
import { IPaginationProps } from './types';

export const Pagination: FC<IPaginationProps> = ({ pageCount, forcePage, onPageChange }) => {
	return (
		<ReactPaginate
			pageCount={pageCount}
			forcePage={forcePage - 1}
			onPageChange={({ selected }) => onPageChange(selected + 1)}
			className="pagination"
			activeClassName=""
			activeLinkClassName="active"
			previousLinkClassName="arrow"
			nextLinkClassName="arrow"
			previousLabel={
				<svg
					width="10"
					height="16"
					viewBox="0 0 10 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8.5 1L1.5 8L8.5 15"
						stroke="#777E90"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			}
			nextLabel={
				<svg
					width="10"
					height="16"
					viewBox="0 0 10 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1.5 1L8.5 8L1.5 15"
						stroke="#777E90"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			}
		/>
	);
};
