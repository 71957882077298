/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IMerchantAnalyticsStore,
	IAnalyticsRequest,
	IAnalyticsResponse,
	ISmsRequest,
	ISmsReducer,
} from './types';

export const initialState: IMerchantAnalyticsStore = {
	analyticsList: null,
	analyticsLoading: false,
	sendSms: false,
};

const merchantAnalytics = createSlice({
	name: '@@merchantAnalytics',
	initialState,
	reducers: {
		getMerchantAnalyticsRequest: (state, _: PayloadAction<IAnalyticsRequest>) => {
			const merchantAnalyticsState = state;
			merchantAnalyticsState.analyticsLoading = true;
		},

		getMerchantAnalyticsResponse: (state, { payload }: PayloadAction<IAnalyticsResponse>) => {
			const merchantAnalyticsState = state;
			merchantAnalyticsState.analyticsLoading = false;
			merchantAnalyticsState.analyticsList = payload;
		},

		smsMerchantAnalyticsRequest: (state, _: PayloadAction<ISmsReducer>) => {
			const merchantAnalyticsState = state;
			merchantAnalyticsState.sendSms = true;
		},

		smsMerchantAnalyticsResponse: (state) => {
			const merchantAnalyticsState = state;
			merchantAnalyticsState.sendSms = false;
		},

		merchantAnalyticsResetState: (state) => {
			const getState = state;

			getState.analyticsLoading = false;
		},
	},
});

export default merchantAnalytics.reducer;
export const {
	getMerchantAnalyticsRequest,
	getMerchantAnalyticsResponse,
	smsMerchantAnalyticsRequest,
	smsMerchantAnalyticsResponse,
	merchantAnalyticsResetState,
} = merchantAnalytics.actions;
