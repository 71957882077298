import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import {
	generateWidgetKeyMerchantRequest,
	generateWidgetKeyRequest,
} from 'redux/reducers/projectSettings/reducer';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import { IGenerateWidgetKeyPayload } from 'redux/reducers/projectSettings/types';
import { constUserTypes } from 'services/constants/user-types';
import { notify } from 'utils/notify';
import { PasswordInput } from '../../ApiTab/PasswordInput/PasswordInput';

export const WidgetKey = () => {
	const dispatch = useDispatch();
	const [isShowPass, setIsShowPass] = useState(false);
	const projectDetails = useSelector(getProjectDetails);
	const userType = useSelector(getUserType);

	const widgetKey = projectDetails?.widget_key || '';

	const handleGenerateNewKey = () => {
		if (!projectDetails) return;

		const payload: IGenerateWidgetKeyPayload = {
			apiParams: { project_id: projectDetails.id },
			onSuccess: () => notify('Widget key has been successfully generated', 'success'),
		};
		dispatch(
			userType === constUserTypes.PROVIDER
				? generateWidgetKeyRequest(payload)
				: generateWidgetKeyMerchantRequest(payload),
		);
	};

	return (
		<div className="api-item">
			<div className="api-item-wrapper">
				<div className="input input--no-mb">
					<p className="input-name">Widget Key</p>

					<div className="input-wrapper">
						<PasswordInput
							placeholder="Widget Key"
							value={widgetKey}
							readOnly
							showPass={isShowPass}
							onToggleShowPass={setIsShowPass}
						/>
					</div>
				</div>

				<button className="button" type="button" onClick={handleGenerateNewKey}>
					Generate new key
				</button>
			</div>
		</div>
	);
};
