import React, { FC, useState } from 'react';
import TransferForm from '../ModalTransfer/TransferForm';
import WithdrawalForm from '../ModalWithdrawal/WithdrawalForm';
import styles from './styles.module.scss';

interface IWithdrawalToTransferProps {
	defaultFromId: number;
	isTransfer?: boolean;
	open: boolean;
	closePopUp: () => void;
}
const WithdrawalToTransfer: FC<IWithdrawalToTransferProps> = ({
	defaultFromId,
	isTransfer,
	open,
	closePopUp,
}) => {
	const [showTransfer, setShowTransfer] = useState(isTransfer);
	return showTransfer ? (
		<>
			<TransferForm defaultFromId={defaultFromId} open={open} closePopUp={closePopUp} />
			<div className={styles.description}>
				If you need to send money to any wallet outside of Cryptonix, please go to the Withdrawals
				section.
			</div>
			<div className={styles.autoExchange} onClick={() => setShowTransfer(false)}>
				Withdrawals
			</div>
		</>
	) : (
		<>
			<WithdrawalForm defaultFromId={defaultFromId} open={open} closePopUp={closePopUp} />
			<div className={styles.description}>
				You want to trasnfer money from one of your projects inside Cryptonix to another? Try our
				free transfers!
			</div>
			<div className={styles.autoExchange} onClick={() => setShowTransfer(true)}>
				Transfer
			</div>
		</>
	);
};

export default WithdrawalToTransfer;
