import { FC, useCallback } from 'react';
import classNames from 'classnames';
import { useField } from 'formik';
import { ProjectSelect } from 'UI/ProjectSelect/ProjectSelect';
import { IApiGetProjectListItem } from 'services/api/merchant/types';
import { IRolesFieldProps } from './types';

export const ProjectField: FC<IRolesFieldProps> = ({ project, text = 'Project' }) => {
	const [field, meta, helpers] = useField<IApiGetProjectListItem | null>('project');

	const isShowError = meta.touched && meta.error;

	const setTouchedTrue = useCallback(() => {
		helpers.setTouched(true);
	}, []); //eslint-disable-line

	return (
		<div className={classNames('select-block', { 'select-block--error': isShowError })}>
			<p className="select-block__name">{text}</p>

			<ProjectSelect
				project={project}
				active={field.value}
				onChange={(newRole) => helpers.setValue(newRole)}
				onClose={setTouchedTrue}
			/>

			{isShowError && <p className="input-notification input-notification--error">{meta.error}</p>}
		</div>
	);
};
