import classNames from 'classnames';
import { ITooltip } from './type';
import styles from './styles.module.scss';

const Tooltip = ({
	value,
	shortValue,
	position = 'top',
	arrowPosition = 'center',
	width = '320',
}: ITooltip): JSX.Element => {
	return (
		<span className={styles['tooltip-item']}>
			{shortValue || value}
			<span
				className={classNames(styles.tooltip, {
					[styles.tooltip__top]: position === 'top',
					[styles['tooltip__arrow-bottom-center']]:
						position === 'top' && arrowPosition === 'center',
					[styles['tooltip__arrow-bottom-left']]: position === 'top' && arrowPosition === 'left',
					[styles.tooltip__right]: arrowPosition === 'right',
					[styles.tooltip__center]: arrowPosition === 'center',
					[styles.tooltip__bottom]: position === 'bottom',
					[styles['tooltip__arrow-bottom-right']]: position === 'top' && arrowPosition === 'right',
					[styles['tooltip__arrow-top-center']]:
						position === 'bottom' && arrowPosition === 'center',
					[styles['tooltip__arrow-top-left']]: position === 'bottom' && arrowPosition === 'left',
					[styles['tooltip__arrow-top-right']]: position === 'bottom' && arrowPosition === 'right',
					[styles['tooltip__width-170']]: width === '170',
					[styles['tooltip__width-320']]: width === '320',
					[styles['tooltip__width-400']]: width === '400',
					[styles['tooltip__width-auto']]: width === 'auto',
				})}
			>
				<span className={classNames(styles.tooltip__text, '--ta-c')}>
					<span>{value}</span>
				</span>
			</span>
		</span>
	);
};

export default Tooltip;
