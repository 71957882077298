import { useLocation } from 'react-router';
import T from 'i18n-react';

export const BackButton = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const url = searchParams.get('url_order');
	return (
		<a href={url ? `${String(url)}` : '#'} className="payment-btn payment-link">
			{T.translate('PaymentWidget.back_to_store')}
		</a>
	);
};
