export const dataTimes = ['7 Days', '30 Days', '90 Days', '365 Days'];

export const showCharts = [
	'Project wallet number',
	'Project total balances',
	'Project incoming payments',
	'Project withdraw payments',
	'Project invoice payments',
	'Project deposit by coins',
	'Project withdrawal by coins',
	'Project invoice by coins',
];

export const showChartsProvider = [
	'components quantity transactions',
	'components total balances',
	'components commissions',
	'components total new counters',
	'components transaction by params',
	'Balance current',
];
