import { FC } from 'react';
import { IOptionItem } from './types';

export const OptionItem: FC<IOptionItem> = ({ option, withNetworkName }) => {
	const { currency, currency_network_name, img_path: imgPath } = option;

	return (
		<span className="token">
			<span className="token__icon">
				<img src={imgPath} alt="" />
			</span>
			<span className="token__name">{currency.name}</span>
			<span className="token__transcription">{currency.code?.toUpperCase()}</span>
			{withNetworkName && (
				<>
					<span>,&nbsp;</span>
					<span className="token__transcription">{currency_network_name?.toUpperCase()}</span>
				</>
			)}
		</span>
	);
};
