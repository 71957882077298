import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProviderCurrencyAllNoSettings } from 'redux/reducers/providerCurrency/selectors';
import { getProviderAllCurrencyRequest } from 'redux/reducers/providerCurrency/reducer';
import { TextField } from 'v2Components/formComponents/TextField';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { DefaultToken, Token } from 'v2Components/UI/Token';
import { Formik } from 'formik';
import Button from 'v2Components/UI/Button';
import FormSideModal from 'v2Components/layoutsComponents/FormSideModal';
import styles from './styles.module.scss';
import { TAddBankAccountFormValues } from '../../types';
import { addBankSchema } from '../../utils';

interface IProps {
	showFilter: boolean;
	setShowFilter: (value: boolean) => void;
	onSubmit: (values: TAddBankAccountFormValues, resetForm: () => void) => void;
}

const initialValues: TAddBankAccountFormValues = {
	name_account: '',
	currency_id: 0,
	currency_name: '',
	iban: '',
	reference: '',
	bic: '',
	totp: '',
};

const BankAccountsForm = ({ showFilter, setShowFilter, onSubmit }: IProps): JSX.Element => {
	const currencyList = useSelector(getProviderCurrencyAllNoSettings);
	const selectedList = useMemo(
		() => currencyList.filter((el) => el.is_crypto === 0),
		[currencyList],
	);
	return (
		<Formik
			validationSchema={addBankSchema}
			initialValues={initialValues}
			onSubmit={(values, { resetForm, setSubmitting }) => {
				onSubmit(values, resetForm);
				setSubmitting(false);
			}}
			enableReinitialize
		>
			{({ resetForm }) => (
				<FormSideModal
					className={styles.accounts__drawer}
					onActionDrawer={resetForm}
					title="Add bank account"
					show={showFilter}
					setShow={setShowFilter}
					footer={
						<>
							<Button
								size="regular"
								width="100%"
								type="button"
								className={styles.cancel}
								onClick={() => setShowFilter(false)}
							>
								Cancel
							</Button>
							<Button size="regular" width="100%" type="submit">
								Add
							</Button>
						</>
					}
				>
					<TextField name="name_account" label="Bank name" placeholder="Enter name" />
					<SelectField
						title="Currency"
						name="currency_id"
						options={[...selectedList]}
						valueProp="id"
						renderSelected={(selectedValue) =>
							(selectedValue && (
								<Token
									code={
										selectedValue.code === 'All'
											? selectedValue.code
											: (selectedValue.code || '').toUpperCase()
									}
									name={selectedValue.name}
								/>
							)) || <DefaultToken />
						}
						renderOption={({ code, name, img_path }) => (
							<Token code={code === 'All' ? code : (code || '').toUpperCase()} name={name} />
						)}
					/>
					<TextField name="iban" label="IBAN" placeholder="Enter IBAN" />
					<TextField name="bic" label="BIC" placeholder="Enter BIC" />
					<TextField name="reference" label="Reference" placeholder="Enter Reference" />
					<TextField
						label="Enter the 2FA 6-digit Code"
						type="text"
						placeholder="Enter code"
						name="totp"
						maxLength={6}
						onlyNumbers
					/>
				</FormSideModal>
			)}
		</Formik>
	);
};

export default BankAccountsForm;
