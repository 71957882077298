import { FC } from 'react';
import { toUTC } from 'utils/dates';
import { UserIcon } from 'assets/inline-svg';
import { ICommnetProps } from './types';

export const ApprovementComments: FC<ICommnetProps> = ({ commnetInfo }) => {
	const { created_at, comment } = commnetInfo;
	return (
		<div className="approvements-user approvements-user--mb-24">
			<div className="approvements-user__icon">
				<UserIcon />
			</div>
			<div className="approvements-user__info">
				<div className="approvements-user__name">
					<p>D.Smytch</p>
				</div>
				<div className="approvements-user__date">
					<p>{toUTC(String(created_at))}</p>
				</div>
				<div className="approvements-user__text">
					<p>{comment}</p>
				</div>
			</div>
		</div>
	);
};
