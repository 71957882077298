import moment from 'moment';
import { TApiGetProviderAllCurrencyResponse } from 'services/api/provider/types';
import { IGetCurrencyNetworkItem } from 'services/api/public/types';
import { IFilterElemItem } from 'v2Components/layoutsComponents/Filter/types';
import { reportsTypeSelected } from './Filters/constants';

export interface TransactionsHistory {
	id: string;
	value: string | number | Date | null;
	networks?: IGetCurrencyNetworkItem[];
	currencies?: TApiGetProviderAllCurrencyResponse[];
}

export const getFilterProjectReportsHistory = ({
	id,
	value,
	networks,
	currencies,
}: TransactionsHistory): IFilterElemItem => {
	switch (id) {
		case 'date_from':
			return { identify: id, content: `from: ${moment(value).format('DD MMM YY, hh:mm a')}` };
		case 'date_to':
			return { identify: id, content: `from: ${moment(value).format('DD MMM YY, hh:mm a')}` };
		case 'type': {
			const content = reportsTypeSelected.find((type) => type.code.trim() === value);
			return { identify: id, content: content?.label || '' };
		}
		default:
			return { identify: '', content: '' };
	}
};
