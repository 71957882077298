import { FC } from 'react';
import inbox from '../../assets/styles/images/inbox.png';

interface IProps {
	onClose: (value: boolean) => void;
}

export const SuccessAuth: FC<IProps> = ({ onClose }) => {
	return (
		<div className="authorization-box">
			<div className="form form--authorization">
				<div className="form__header">
					<div className="form-img">
						<img src={inbox} alt="letter-icon" />
					</div>
					<p className="form-title form-title--center">Great, check your inbox</p>
					<div className="form-text form-text--center">
						<p>Check your mailbox for the instructions</p>
					</div>
				</div>
				<div className="form-footer">
					<button type="button" onClick={() => onClose(false)} className="link">
						Request a new one
					</button>
				</div>
			</div>
		</div>
	);
};

export const SuccessIP: FC<IProps> = ({ onClose }) => {
	return (
		<div className="authorization-box">
			<div className="form form--authorization">
				<div className="form__header">
					<div className="form-img">
						<img src={inbox} alt="letter-icon" />
					</div>
					<p className="form-title form-title--center">Great, but some additional steps needed</p>
					<div className="form-text form-text--center">
						<p>
							Your current IP address is unknown and you need to confirm it first. Please check your
							mailbox for IP confirmation instructions and then return to this page to try again
						</p>
					</div>
				</div>
				<div className="form-footer">
					<button type="button" onClick={() => onClose(false)} className="link">
						Try reset password again
					</button>
				</div>
			</div>
		</div>
	);
};
