import React, { FC, useState } from 'react';
import { PopUpCloseIcon, ArrowUpIcon } from 'assets/inline-svg';
import { Dialog } from '@mui/material';
import styles from './styles.module.scss';
import WithdrawalToTransfer from '../WithdrawalToTransfer';
import ModalOpen from '../components/ModalOpen';

interface IModalWithdrawalProps {
	defaultFromId: number;
}
const ModalWithdrawal: FC<IModalWithdrawalProps> = ({ defaultFromId }) => {
	const [open, setOpen] = useState(false);
	const handleClickOpen = () => setOpen(true);
	const closePopUp = () => setOpen(false);

	return (
		<>
			<ModalOpen handleClickOpen={handleClickOpen} label="Withdrawal">
				<ArrowUpIcon />
			</ModalOpen>
			<Dialog open={open} onClose={closePopUp}>
				<div className={styles.close} onClick={closePopUp}>
					<PopUpCloseIcon />
				</div>
				<WithdrawalToTransfer defaultFromId={defaultFromId} open={open} closePopUp={closePopUp} />
			</Dialog>
		</>
	);
};

export default ModalWithdrawal;
