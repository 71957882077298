import { FC, useLayoutEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { MerchantLayoutV2 } from 'layouts/MerchantLayoutV2/MerchantLayoutV2';
import { getProviderMerchantProfileRequest } from 'redux/reducers/merchantProfile/reducer';
import { routeListMerchant } from '../routeListMerchant';

export const MerchantRoutes: FC = () => {
	const dispatch = useDispatch();
	const { path } = useRouteMatch();
	const { merchantId } = useParams<{ merchantId: string }>();
	const userType = useSelector(getUserType);
	const isDesktop = useMemo(() => window.screen.width > 1180, []);
	useLayoutEffect(() => {
		userType === constUserTypes.PROVIDER &&
			dispatch(getProviderMerchantProfileRequest({ merchant_id: merchantId }));
	}, [dispatch, merchantId, userType]);

	return (
		<MerchantLayoutV2 title="Merchant Projects" v2={isDesktop}>
			<Switch>
				{/* One Merchant / Profile */}
				{routeListMerchant.map((route) => (
					<Route
						key={route.path}
						exact={route.exact}
						path={path + route.path}
						component={route.component}
					/>
				))}
				<Redirect to={path + routes.oneMerchant.profile} />
			</Switch>
		</MerchantLayoutV2>
	);
};
