import { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getMerchantTransactionRequest } from 'redux/reducers/projectTransactions/reducer';
import { getProjectMerchantTransaction } from 'redux/reducers/projectTransactions/selectors';
import {
	showTransactionHistoryFiatRequest,
	showTransactionHistoryCryptoRequest,
} from 'redux/reducers/providerCurrency/reducer';
import { getTransactionHistoryDetails } from 'redux/reducers/providerCurrency/selectors';
import { ITransactionDetails } from './types';
import ApprovementStatus from './ApprovementStatus';
import ApprovementsList from './ApprovementsList';
import ApprovementUsers from './ApprovementUsers';
import ApprovementStatusHistory from './ApprovementStatusHistory';
import { getItemData, getPageType } from './transaction-details.helper';
import ApprovementCallbacks from './ApprovementCallbacks';

const TransactionDetails: FC<ITransactionDetails> = ({ isProject, isHistory }) => {
	const dispatch = useDispatch();
	const item = useSelector(
		isHistory ? getTransactionHistoryDetails : getProjectMerchantTransaction,
	);
	const { transactionId } = useParams<{ transactionId: string }>();
	const { currencyTabs, transactionTypes } = useParams<{
		transactionId: string;
		currencyTabs: string;
		transactionTypes: string;
	}>();
	const isCrypto = useMemo(() => currencyTabs === 'crypto', [currencyTabs]);
	const type = getPageType(transactionTypes, isCrypto);
	useEffect(() => {
		if (isHistory) {
			dispatch(
				isCrypto
					? showTransactionHistoryCryptoRequest({
							transaction_id: +transactionId,
					  })
					: showTransactionHistoryFiatRequest({
							provider_fiat_transaction_id: +transactionId,
					  }),
			);
		} else {
			dispatch(
				getMerchantTransactionRequest({
					apiParams: {
						[isCrypto ? 'project_transaction_id' : 'project_fiat_transaction_id']:
							String(transactionId),
					},
					is_crypto: isCrypto,
				}),
			);
		}
	}, [dispatch, transactionId, isHistory, isCrypto]);

	if (!item) {
		return null;
	}

	const { id, status, currency, users } = getItemData(item?.transaction || {});
	return (
		<>
			<ApprovementStatus status={status} id={item.project_transaction_id || id} />
			<ApprovementsList
				tag={type || ''}
				item={item}
				type={transactionTypes}
				isProject={isProject}
			/>
			<ApprovementUsers currency={currency} items={users} />
			<ApprovementStatusHistory statusesHistory={item?.status_history} />
			<ApprovementCallbacks callbacks={item.callbacks} />
		</>
	);
};

export default TransactionDetails;
