import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProviderProfitRequest } from 'redux/reducers/providerCurrency/reducer';
import {
	getProviderProfit,
	getProviderTransactionLoading,
} from 'redux/reducers/providerCurrency/selectors';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { ICurrencyProfit } from 'redux/reducers/providerCurrency/types';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Table from 'v2Components/commomComponents/Table';
import { TableRow } from './TableRow/TableRow';
import { IPropsTabs } from './types';
import FilterProfit from './FilterProfit';
import { getFilterTransactionsHistory } from '../filter.helper';

interface IDict {
	[key: string]: string | number | Date;
}

const Profit: FC<IPropsTabs> = ({ currency }) => {
	const dispatch = useDispatch();
	const { getViewDecimal } = useViewDecimals();
	const profits = useSelector(getProviderProfit);
	const loading = useSelector(getProviderTransactionLoading);
	const [showFilter, setShowFilter] = useState(false);
	const [filterList, setFilterList] = useState<any>([]);
	const [requestParams, setRequestParams] = useState<any>({});
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('Profit');
	const isCrypto = currency === 'crypto';
	const onSubmit = (data: any) => {
		const params: IDict = {
			date_from: data.date_from as string | Date,
			date_to: data.date_to as string | Date,
		};
		setRequestParams(params);
		setFilterList(
			Object.keys(params)
				.filter((key: any) => params[key] != null)
				.map((key) =>
					getFilterTransactionsHistory({
						id: key,
						value: params[key],
					}),
				),
		);
		setShowFilter(false);
	};

	const deleteItemFilter = (value: string) => {
		setRequestParams({
			...requestParams,
			[value]: undefined,
		});
		setFilterList(filterList.filter((x: any) => x.identify !== value));
	};

	const onClearFilter = () => {
		setFilterList([]);
		setRequestParams({});
	};

	useEffect(() => {
		dispatch(
			getProviderProfitRequest({
				...requestParams,
				search_value: undefined,
				current_page: undefined,
			}),
		);
	}, [dispatch, requestParams]);
	return (
		<>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<FilterProfit />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={(isCrypto && profits) || []}
				getRow={(profit: ICurrencyProfit) =>
					TableRow({
						profit,
						getViewDecimal,
					})
				}
				gridTemplateColumns={gridTemplateColumns}
			/>
		</>
	);
};

export default Profit;
