import { FC } from 'react';
import { IWhiteListIPBlockProps } from './types';
import { WhiteListIpItem } from './WhiteListIpItem/WhiteListIpItem';
import styles from './styles.module.scss';

export const WhiteListIPBlock: FC<IWhiteListIPBlockProps> = ({ whiteList }) => {
	if (!whiteList?.length) {
		return null;
	}

	return (
		<>
			<div className={styles.title}>White list ip</div>
			<div className={styles.main__wrapper}>
				<div>
					<div className="table table--user-profile-white-list">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>ID</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>Last used at</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p>IP</p>
									</div>
								</div>
								<div className="td td--right">
									<div className="td-name">
										<p>Action</p>
									</div>
								</div>
							</div>
						</div>
						<div>
							{whiteList?.map((item) => (
								<WhiteListIpItem key={item?.id} item={item} />
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
