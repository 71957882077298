/* eslint-disable react/require-default-props */
import { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { IProjectCoins } from 'redux/reducers/analyticsMerchant/types';

interface IProps {
	params: Array<IProjectCoins> | undefined;
	isMerchant?: boolean;
}

export const ChartjsDoughnut: FC<IProps> = ({ params, isMerchant }) => {
	const data = {
		labels: params?.length
			? params?.map((el) =>
					isMerchant
						? `${el.currency_code?.toUpperCase()} ${el.amount_percent} %`
						: `${el.currency_code?.toUpperCase()}, ${el.currency_network?.toUpperCase()} ${
								el.amount
						  }/${el.amount_percent} USD`,
			  )
			: ['no data available'],
		datasets: [
			{
				type: 'doughnut',
				data: params?.length ? params?.map((el) => el.amount_percent) : [0],
				fill: true,
				backgroundColor: params?.length
					? ['#fd3232', '#B230B2', '#4285f5', '#01ffff', '#00ba88', '#fcfe00', '#ff7f00'].slice(
							0,
							params?.length,
					  )
					: ['#000'],
				borderWidth: 0,
				cutoutPercentage: 90,
				spacing: 5,
			},
		],
	};

	const options = {
		legend: {
			display: true,
			position: 'right',
			// width: 5,
			labels: {
				usePointStyle: true,
				pointStyle: 'rectRounded',
			},
		},
		// responsive: true,
	};
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return <Doughnut options={options} data={data} height={30} width={50} />;
};
