import { FC } from 'react';
import { ITooltip } from './types';

// @TODO: Надо доработать, пока юзаю только в инвойсах

export const Tooltip: FC<ITooltip> = ({ children, tooltip }) => {
	return (
		<span className="tooltip-item">
			{children}
			<span className="tooltip tooltip--width-auto tooltip--top tooltip--center tooltip--arrow-bottom-center">
				<span className="tooltip__text tooltip__text--ta-c">
					<span>{tooltip}</span>
				</span>
			</span>
		</span>
	);
};
