/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMerchantParams } from 'services/api/provider/types';
import { IProviderResetPasswordSaga } from '../providerMerchant/types';
import {
	IMerchantApprovalManagementStore,
	TApiGetIApiMerchantApprovalManagementParamsPayload,
	IGetMerchantApprovalManagementResponse,
	IDeleteMerchantRequset,
	IBlockMerchantRequset,
	IPayloadRequset,
	IApiGetUserProfileResponse,
	IEditMerchantRequset,
	IEditMerchantRolePayload,
	IDeleteMerchantRoleRequset,
	IGetUserApproveMerchantSagaRequset,
	IMerchantResponse,
} from './types';

export const initialState: IMerchantApprovalManagementStore = {
	merchantApprovalManagement: null,
	merchantApprovalListLoader: false,
	userProfileLoading: false,
	userProfile: null,
	merchantList: null,
};

const merchantApprovalManagement = createSlice({
	name: '@@merchantApprovalManagement',
	initialState,
	reducers: {
		getMerchantApprovalManagementRequest: (
			state,
			_: PayloadAction<TApiGetIApiMerchantApprovalManagementParamsPayload>,
		) => {
			state.merchantApprovalManagement = null;
			state.merchantApprovalListLoader = true;
		},
		getMerchantApprovalManagementProviderRequest: (
			state,
			_: PayloadAction<TApiGetIApiMerchantApprovalManagementParamsPayload>,
		) => {
			state.merchantApprovalManagement = null;
			state.merchantApprovalListLoader = true;
		},
		getListMerchantRequest: (state) => {},
		setListMerchantResponse: (state, { payload }: PayloadAction<Array<IMerchantResponse>>) => {
			state.merchantList = payload;
		},
		getProviderListMerchantRequest: (state, action: PayloadAction<IMerchantParams>) => {},
		setProviderMerchantResponse: (state, { payload }: PayloadAction<Array<IMerchantResponse>>) => {
			state.merchantList = payload;
		},
		updateMerchantApprovalManagementRequest: (
			state,
			action: PayloadAction<TApiGetIApiMerchantApprovalManagementParamsPayload>,
		) => {
			state.merchantApprovalListLoader = true;
		},
		updateMerchantApprovalRequest: (
			state,
			action: PayloadAction<TApiGetIApiMerchantApprovalManagementParamsPayload>,
		) => {
			state.merchantApprovalListLoader = true;
		},
		getMerchantApprovalManagementSuccess: (
			state,
			{ payload }: PayloadAction<IGetMerchantApprovalManagementResponse>,
		) => {
			state.merchantApprovalManagement = payload;
			state.merchantApprovalListLoader = false;
		},
		deleteMerchantRequest: (state, _: PayloadAction<IDeleteMerchantRequset>) => {},
		deleteMerchantRoleRequest: (state, _: PayloadAction<IDeleteMerchantRoleRequset>) => {},
		postBlockRequest: (state, _: PayloadAction<IBlockMerchantRequset>) => {},
		postBlockMerchantRequest: (state, _: PayloadAction<IBlockMerchantRequset>) => {},
		addNewUserRequest: (state, payload: PayloadAction<IPayloadRequset>) => {
			// state.merchantApprovalManagement = payload.payload;
		},
		addNewUserMerchantRequest: (state, payload: PayloadAction<IPayloadRequset>) => {
			// state.merchantApprovalManagement = payload.payload;
		},
		addNewUserRoleRequest: (state, payload: PayloadAction<IPayloadRequset>) => {
			// state.merchantApprovalManagement = payload.payload;
		},
		getUserMerchantProfileRequest: (
			state,
			action: PayloadAction<IGetUserApproveMerchantSagaRequset>,
		) => {
			state.userProfileLoading = true;
		},
		getMerchantUserProfileSuccess: (
			state,
			{ payload }: PayloadAction<IApiGetUserProfileResponse>,
		) => {
			state.userProfile = payload;
			state.userProfileLoading = false;
		},
		editMerchantUserProfileRequest: (state, { payload }: PayloadAction<IEditMerchantRequset>) => {},
		resetMerchantUserProfileRequest: (
			state,
			{ payload }: PayloadAction<IProviderResetPasswordSaga>,
		) => {},

		editMerchantRoleRequest: (state, action: PayloadAction<IEditMerchantRolePayload>) => state,
		editMerchantNotProviderRoleRequest: (state, action: PayloadAction<IEditMerchantRolePayload>) =>
			state,
	},
});

export default merchantApprovalManagement.reducer;
export const {
	getMerchantApprovalManagementRequest,
	getMerchantApprovalManagementProviderRequest,
	getMerchantApprovalManagementSuccess,
	deleteMerchantRequest,
	postBlockRequest,
	postBlockMerchantRequest,
	addNewUserRequest,
	addNewUserMerchantRequest,
	updateMerchantApprovalManagementRequest,
	updateMerchantApprovalRequest,
	getUserMerchantProfileRequest,
	getMerchantUserProfileSuccess,
	editMerchantUserProfileRequest,
	resetMerchantUserProfileRequest,
	editMerchantRoleRequest,
	editMerchantNotProviderRoleRequest,
	addNewUserRoleRequest,
	deleteMerchantRoleRequest,
	getListMerchantRequest,
	setListMerchantResponse,
	getProviderListMerchantRequest,
	setProviderMerchantResponse,
} = merchantApprovalManagement.actions;
