import { HTMLAttributes } from 'react';
import styles from './styles.module.scss';

type IDrawerBody = HTMLAttributes<HTMLDivElement>;

const DrawerBody = ({ children }: IDrawerBody): JSX.Element => {
	return <div className={styles['drawer-body']}>{children}</div>;
};

export default DrawerBody;
