import { IStoreState } from 'redux/types';

export const getProviderWalletsAddresses = (state: IStoreState) => state.projectWalletAddresses;
export const getProviderWalletsAddressesData = (state: IStoreState) =>
	getProviderWalletsAddresses(state).projectWalletsAddresses;
export const getProviderCurrencyAllLoading = (state: IStoreState) =>
	getProviderWalletsAddresses(state).projectWalletsAddressesLoading;
export const getProjectWalletsAddressesDataClient = (state: IStoreState) =>
	getProviderWalletsAddresses(state).projectWalletsAddressesClient;
export const getProjectCurrencyAllClientLoading = (state: IStoreState) =>
	getProviderWalletsAddresses(state).projectWalletsAddressesClientLoading;
