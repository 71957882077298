import { PopUpCloseIcon } from 'assets/inline-svg';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRolesRequest } from 'redux/reducers/administration/reducer';
import { getRoles } from 'redux/reducers/administration/selectors';
import { AddNewAdminForm } from './AddNewAdminForm/AddNewAdminForm';
import { IAddNewAdminProps } from './types';
// import mockRoles from './mockRoles.json';

export const AddNewAdmin: FC<IAddNewAdminProps> = ({ onSubmit, onClose }) => {
	const dispatch = useDispatch();
	const roles = useSelector(getRoles);

	useEffect(() => {
		dispatch(getRolesRequest({}));
	}, [dispatch]);

	return (
		<div className="popup popup--width-480">
			<button aria-label="button" type="button" className="popup__close" onClick={onClose}>
				<PopUpCloseIcon />
			</button>

			<AddNewAdminForm roles={roles} onSubmit={onSubmit} onCancel={onClose} />
		</div>
	);
};
