/* eslint-disable react/no-unescaped-entities */
import { FC, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { getApi } from 'redux/reducers/projectSettings/selectors';
import { CopyIcon, PopUpCloseIcon } from 'assets/inline-svg';
import { notify } from 'utils/notify';
import { IAddApiKeyResult } from './types';

export const AddApiKeyResult: FC<IAddApiKeyResult> = ({ onClose }) => {
	const api = useSelector(getApi);
	const [finalStep, setFinalStep] = useState(false);

	const newApiKey = api?.api_keys[0];
	const { private: privateKey = '', public: publicKey = '' } = newApiKey || {};

	const handlePublicKeyCopy = () => {
		notify('Public key copied successfully!', 'info');
	};

	const handlePrivateKeyCopy = () => {
		notify('Private key copied successfully!', 'info');
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{finalStep ? (
				<div className="popup">
					<div className="popup-header">
						<p className="popup-header__title">Confirmation</p>
					</div>
					<div className="popup-body">
						<p className="popup-text">
							Make sure you save your private key securely. To procceed, click "Ok". To return to
							the previous window, click "Cancel".
						</p>
					</div>
					<div className="popup-button popup-button--type2">
						<button
							type="button"
							className="button button--full-width button--type2"
							onClick={() => setFinalStep(false)}
						>
							Cancel
						</button>
						<button
							aria-label="button"
							type="button"
							className="button button--full-width "
							onClick={onClose}
						>
							Ok
						</button>
					</div>
				</div>
			) : (
				<div className="popup">
					<button
						aria-label="button"
						type="button"
						className="popup__close"
						onClick={() => setFinalStep(true)}
					>
						<PopUpCloseIcon />
					</button>
					<div className="popup-header">
						<p className="popup-header__title popup-header__title--ta-l popup-header__title--mb-8">
							{' '}
							Public key created successfully{' '}
						</p>
					</div>
					<div className="popup-body ">
						<div className="popup-text popup-text--ta-l popup-text--fz-14 popup-text--fw-600 popup-text--red popup-text--mb-24">
							<p>
								Note, that the Private Key only appears once on this screen. Make sure you save it
								securely. Public Key will be displayed on this page permanently.
							</p>
						</div>
						<div className="input">
							<p className="input-name"> Public key </p>
							<div className="copy-address copy-address--gap-24">
								<p>{publicKey}</p>
								<CopyToClipboard text={publicKey} onCopy={handlePublicKeyCopy}>
									<button aria-label="button" type="button">
										<CopyIcon />
									</button>
								</CopyToClipboard>
							</div>
						</div>
						<div className="input">
							<p className="input-name">Private key </p>
							<div className="copy-address copy-address--gap-24">
								<p>{privateKey}</p>
								<CopyToClipboard text={privateKey || ''} onCopy={handlePrivateKeyCopy}>
									<button aria-label="button" type="button">
										<CopyIcon />
									</button>
								</CopyToClipboard>
							</div>
						</div>
					</div>
					<div className="popup-button popup-button--type2">
						<button
							type="button"
							className="button button--full-width"
							onClick={() => setFinalStep(true)}
						>
							Ok{' '}
						</button>
					</div>
				</div>
			)}
		</>
	);
};
