import { EnergyIcon } from 'assets/inline-svg';
import ProgressbarCapsule from 'v2Components/commomComponents/ProgressbarCapsule';
import { useSelector } from 'react-redux';
import { getTRXOverview } from 'redux/reducers/providerTRXManagement/selectors';
import { priceFormat } from 'utils/strings';
import styles from './styles.module.scss';

const OverviewItem = () => {
	const overview = useSelector(getTRXOverview);
	const { accountResources } = overview || {};
	const percent = accountResources
		? (100 * accountResources.totalNetAvailable) / accountResources.totalNet
		: 1000;
	return (
		<div className={styles.card}>
			<div className={styles.header}>
				<div className={styles.icon}>
					<EnergyIcon />
				</div>
				<span>Energy</span>
			</div>
			<div>
				<p className={styles.label}>Energy Available</p>
				<p className={styles.available}>
					{priceFormat(accountResources?.energyAvailable)}
					<span className={styles.currency}> TRX</span>
				</p>
			</div>
			<ProgressbarCapsule color="#4285F5" percent={percent} />
			<div>
				<p className={styles.label}>Total Energy Staked</p>
				<p className={styles.value}>
					{priceFormat(accountResources?.totalNetUsed)}
					<span className={styles.currency}> TRX</span>
				</p>
			</div>
			<div>
				<p className={styles.label}>Energy Delegated</p>
				<p className={styles.value}>
					{priceFormat(accountResources?.totalNetAvailable)}
					<span className={styles.currency}> TRX</span>
				</p>
			</div>
		</div>
	);
};

export default OverviewItem;
