import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import { WEB_API_URL } from 'services/constants/env';
import {
	TMerchantInvoices,
	IMerchantInvoicesItem,
	IGetInvoiceParams,
	IGetInvoiceResponse,
	IGetInvoiceStatusResponse,
} from 'services/api/merchant/types';
import { downloadCSVFunc } from 'utils/downloadCSVFunc';
import { downloadFile } from 'utils/files';
import {
	getMerchantInvoicesRequest,
	getMerchantInvoicesSuccess,
	getMerchantInvoicesError,
	createMerchantInvoiceRequest,
	createMerchantInvoiceSuccess,
	createMerchantInvoiceError,
	downloadMerchantInvoicePDFRequest,
	downloadMerchantInvoicePDFSuccess,
	downloadMerchantInvoicePDFError,
	sendMerchantInvoiceRequest,
	sendMerchantInvoiceSuccess,
	sendMerchantInvoiceError,
	getInvoiceLinkRequest,
	getInvoiceLinkSuccess,
	getInvoiceLinkError,
	getInvoiceRequest,
	getInvoiceSuccess,
	getInvoiceError,
	getInvoiceStatusRequest,
	getInvoiceStatusSuccess,
	downloadCSVRequest,
	downloadCSVSuccess,
	downloadCSVError,
} from './reducer';
import {
	IGetMerchantInvoicesPayload,
	ICreateInvoicePayload,
	IDownloadInvoicePDFPayload,
	IGetInvoiceLinkPayload,
	IDownloadCSVMerchantInvoicesPayload,
	IGetInvoiceStatusPayload,
} from './types';

function* getMerchantInvoicesRequestWorker(action: PayloadAction<IGetMerchantInvoicesPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		const data: TMerchantInvoices = yield call(api.merchant.getInvoices, apiParams);
		yield put(getMerchantInvoicesSuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(getMerchantInvoicesError());
		showError(error);
	}
}

function* createMerchantInvoiceRequestWorker(action: PayloadAction<ICreateInvoicePayload>) {
	const { apiParams, onSuccess, onFinally } = action.payload;
	try {
		const data: IMerchantInvoicesItem = yield call(api.merchant.createInvoice, apiParams);
		yield put(createMerchantInvoiceSuccess(data));
		onSuccess?.(data.id);
	} catch (error) {
		yield put(createMerchantInvoiceError());
		showError(error);
		onFinally?.();
	}
}

function* downloadMerchantInvoicePDFRequestWorker(
	action: PayloadAction<IDownloadInvoicePDFPayload>,
): any {
	const { apiParams, onSuccess } = action.payload;
	try {
		const data: any = yield call(api.merchant.downloadInvoicePDF, apiParams);
		const link = `${String(WEB_API_URL).replace('/api', '')}${String(data)}`;
		downloadFile(link);
		yield put(downloadMerchantInvoicePDFSuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(downloadMerchantInvoicePDFError());
		showError(error);
	}
}

function* sendMerchantInvoiceRequestWorker(action: PayloadAction<any>): any {
	const { apiParams, onSuccess } = action.payload;
	try {
		const data: any = yield call(api.merchant.sendInvoiceLink, apiParams);
		yield put(sendMerchantInvoiceSuccess(data));
		onSuccess?.();
	} catch (error) {
		yield put(sendMerchantInvoiceError());
		showError(error);
	}
}

function* getInvoiceRequestWorker(action: PayloadAction<IGetInvoiceParams>) {
	try {
		const data: IGetInvoiceResponse = yield call(api.merchant.getInvoice, action.payload);
		yield put(getInvoiceSuccess(data));
	} catch (error) {
		yield put(getInvoiceError());
		showError(error);
	}
}

function* getInvoiceStatusWorker({ payload }: PayloadAction<IGetInvoiceStatusPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: IGetInvoiceStatusResponse = yield call(api.merchant.getInvoiceStatus, apiParams);
		yield put(getInvoiceStatusSuccess(data));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* getInvoiceLinkRequestWorker(action: PayloadAction<IGetInvoiceLinkPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		const link: string = yield call(api.merchant.getInvoiceLink, apiParams);
		window.open(link);
		yield put(getInvoiceLinkSuccess(link));
		onSuccess?.(link);
	} catch (error) {
		yield put(getInvoiceLinkError());
		showError(error);
	}
}

function* downloadCSVRequestWorker(action: PayloadAction<IDownloadCSVMerchantInvoicesPayload>) {
	const { nameFile, apiParams, onSuccess } = action.payload;
	try {
		const data: string = yield call(api.merchant.exportCSVInvoiceMerchant, apiParams);
		onSuccess?.();
		downloadCSVFunc(nameFile, data);
		yield put(downloadCSVSuccess(data));
	} catch (error) {
		yield put(downloadCSVError());
		showError(error);
	}
}

export function* merchantInvoicesSaga() {
	yield takeLatest(getMerchantInvoicesRequest, getMerchantInvoicesRequestWorker);
	yield takeLatest(createMerchantInvoiceRequest, createMerchantInvoiceRequestWorker);
	yield takeLatest(downloadMerchantInvoicePDFRequest, downloadMerchantInvoicePDFRequestWorker);
	yield takeLatest(sendMerchantInvoiceRequest, sendMerchantInvoiceRequestWorker);
	yield takeLatest(getInvoiceLinkRequest, getInvoiceLinkRequestWorker);
	yield takeLatest(getInvoiceRequest, getInvoiceRequestWorker);
	yield takeLatest(getInvoiceStatusRequest, getInvoiceStatusWorker);
	yield takeLatest(downloadCSVRequest, downloadCSVRequestWorker);
}
