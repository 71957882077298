import { FC } from 'react';
import { TextField } from 'v2Components/formComponents/TextField';
import { Formik } from 'formik';
import Button from 'v2Components/UI/Button';
import FormSideModal from 'v2Components/layoutsComponents/FormSideModal';
import { IBankAccountItem } from 'services/api/merchant/types';
import { TextareaField } from 'UI/Forms/TextareaField/TextareaField';
import { TEditBankAccountPageFormValues, TEditBankPageFormOnSubmit } from '../../types';
import styles from './styles.module.scss';

interface IProps {
	showFilter: boolean;
	setShowFilter: (value: boolean) => void;
	onSubmit: TEditBankPageFormOnSubmit;
	item: IBankAccountItem;
}
const BankAccountsFormEdit: FC<IProps> = ({ onSubmit, item, showFilter, setShowFilter }) => {
	const initialValues: TEditBankAccountPageFormValues = {
		reference: item?.reference || '',
		bic: item?.bic || '',
		bank_nickname: item?.bank_nickname || '',
		bank_name: item?.bank_name || '',
		bank_address: item?.bank_address || '',
		iban: item?.iban || '',
		notes: item?.notes || '',
		totp: '',
	};

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			{({ resetForm }) => (
				<FormSideModal
					className={styles.accounts__drawer}
					onActionDrawer={resetForm}
					title="Edit bank account"
					show={showFilter}
					setShow={setShowFilter}
					footer={
						<>
							<Button
								size="regular"
								width="100%"
								type="button"
								className={styles.cancel}
								onClick={() => setShowFilter(false)}
							>
								Cancel
							</Button>
							<Button size="regular" width="100%" type="submit">
								Change
							</Button>
						</>
					}
				>
					<TextField
						name="bank_nickname"
						label="Bank nickname"
						placeholder="Enter banck nickname"
					/>
					<TextField name="bank_name" label="Bank Name" placeholder="Enter bank name" />
					<TextField name="bank_address" label="Bank Address" placeholder="Enter bank address" />
					<TextField name="bic" label="SWIFT / BIC" placeholder="Enter SWIFT / BIC" />
					<TextField name="reference" label="Refernce" placeholder="Enter reference" />
					<TextField name="iban" label="IBAN" placeholder="Enter IBAN" />
					<TextField
						label="2FA 6-digit code"
						type="text"
						placeholder="Enter code"
						name="totp"
						maxLength={6}
						onlyNumbers
					/>
				</FormSideModal>
			)}
		</Formik>
	);
};

export default BankAccountsFormEdit;
