import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IAddBankAccountPayload,
	IEditBankAccountPayload,
	TBankAccountResponse,
	IBankAccountItem,
	IDeleteBankAccountPayload,
	IEditBankAccountInMerchantPayload,
	TCryptoWalletResponse,
	IAddCryptoWalletPayload,
	IEditCryptoWalletPayload,
	ICryptoWalletItem,
	IDelCryptoWalletPayload,
	TGetTotalBalancesInFiatResponse,
} from 'services/api/provider/types';

import { editBankAccountMerchantSuccess } from '../merchantBankAccount/reducer';

import {
	addBankAccountRequest,
	addCryptoWalletRequest,
	deleteBankAccountRequest,
	deleteCryptoWalletRequest,
	editBankAccountInMerchantRequest,
	editBankAccountRequect,
	editBankAccountSuccess,
	editCryptoWalletRequest,
	editCryptoWalletSuccess,
	getBankAccountsRequest,
	getBankAccountsSuccess,
	getCryptoWalletsRequest,
	getCryptoWalletsSuccess,
	getTotalBalancesInFiatRequest,
	getTotalBalancesInFiatSuccess,
} from './reducer';

function* getBankAccountsWorker() {
	try {
		const response: TBankAccountResponse = yield call(api.provider.getBankAccounts);
		yield put(getBankAccountsSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* addBankAccountWorker({ payload }: PayloadAction<IAddBankAccountPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.provider.addBankAccount, apiParams);
		yield put(getBankAccountsRequest());
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* editBankAccountWorker({ payload }: PayloadAction<IEditBankAccountPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: IBankAccountItem = yield call(api.provider.editBankAccount, apiParams);
		yield put(editBankAccountSuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteBankAccountWorker({ payload }: PayloadAction<IDeleteBankAccountPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: IBankAccountItem = yield call(api.provider.deleteBankAccount, apiParams);
		yield put(editBankAccountSuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* editBankAccountInMerchantWorker({
	payload,
}: PayloadAction<IEditBankAccountInMerchantPayload>) {
	const { apiParams, onSuccess, onError } = payload;
	try {
		const response: IBankAccountItem = yield call(
			api.provider.editBankAccountInMerchant,
			apiParams,
		);
		yield put(editBankAccountMerchantSuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
		onError?.();
	}
}

function* getCryptoWalletsWorker() {
	try {
		const response: TCryptoWalletResponse = yield call(api.provider.getCryptoWallets);
		yield put(getCryptoWalletsSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* addCryptoWalletWorker({ payload }: PayloadAction<IAddCryptoWalletPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.provider.addCryptoWallet, apiParams);
		yield put(getCryptoWalletsRequest());
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* editCryptoWalletWorker({ payload }: PayloadAction<IEditCryptoWalletPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: ICryptoWalletItem = yield call(api.provider.editCryptoWallet, apiParams);
		yield put(editCryptoWalletSuccess(response));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteCryptoWalletWorker({ payload }: PayloadAction<IDelCryptoWalletPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.provider.deleteCryptoWallet, apiParams);
		yield put(getCryptoWalletsRequest());
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* getTotalBalancesInFiatWorker() {
	try {
		const response: TGetTotalBalancesInFiatResponse = yield call(
			api.provider.getTotalBalancesInFiat,
		);
		yield put(getTotalBalancesInFiatSuccess(response));
	} catch (error) {
		showError(error);
	}
}

export function* providerBankAccountSaga() {
	yield takeEvery(getBankAccountsRequest, getBankAccountsWorker);
	yield takeEvery(addBankAccountRequest, addBankAccountWorker);
	yield takeEvery(editBankAccountRequect, editBankAccountWorker);
	yield takeEvery(deleteBankAccountRequest, deleteBankAccountWorker);
	yield takeEvery(editBankAccountInMerchantRequest, editBankAccountInMerchantWorker);
	yield takeEvery(getCryptoWalletsRequest, getCryptoWalletsWorker);
	yield takeEvery(addCryptoWalletRequest, addCryptoWalletWorker);
	yield takeEvery(editCryptoWalletRequest, editCryptoWalletWorker);
	yield takeEvery(deleteCryptoWalletRequest, deleteCryptoWalletWorker);
	yield takeEvery(getTotalBalancesInFiatRequest, getTotalBalancesInFiatWorker);
}
