import { FC, useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { routes } from 'routes/routes';
import { MerchantLayoutV2 } from 'layouts/MerchantLayoutV2/MerchantLayoutV2';
import { useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import { routeListMain, routeListMerchant } from '../routeListMerchant';
import 'assets/styles/merchant/scss/style.scss';

export const MainMerchantRoutes: FC = () => {
	const isDesktop = useMemo(() => window.screen.width > 1180, []);
	const indexMath = useRouteMatch(routes.loginMerchant);
	if (indexMath?.isExact) {
		return <Redirect to={routes.oneMerchant.merchantProjects} />;
	}
	return (
		<Switch>
			{routeListMain.map((route) => (
				<Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
			))}
			<MerchantLayoutV2 title="Merchant Projects" v2={isDesktop}>
				{routeListMerchant.map((route) => (
					<Route
						key={route.path}
						exact={route.exact}
						path={route.path}
						component={route.component}
					/>
				))}
			</MerchantLayoutV2>
			<Redirect to={routes.merchantProjectList} />
		</Switch>
	);
};
