import { CheckIcon, TriangleArrowDownIcon2 } from 'assets/inline-svg';
import classNames from 'classnames';
import { useSelect } from 'hooks';
import { FC } from 'react';
import { IApiGetProjectListItem } from 'services/api/merchant/types';
import { IRoleSelectProps } from './types';

export const ProjectSelect: FC<IRoleSelectProps> = ({
	project,
	active: activeRole,
	onChange,
	onClose,
}) => {
	const { open, setOpen, triggerRef, dropRef } = useSelect({ onClose });

	const handleClick = () => {
		if (open) {
			setOpen(false);
			onClose?.();
			return;
		}
		if (project?.length) {
			setOpen(true);
		}
	};

	const handleChange = (newRole: IApiGetProjectListItem) => {
		setOpen(false);
		// onClose() then onChange() - important for correct validation
		onClose?.();
		onChange(newRole);
	};

	return (
		<div className={classNames('select', 'select--type-choice', { active: open })}>
			<button type="button" className="select__current" ref={triggerRef} onClick={handleClick}>
				{activeRole?.name || 'Select project'}

				<span className="select__current-arrow">
					<TriangleArrowDownIcon2 />
				</span>
			</button>

			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select__drop-item">
						<ul>
							{project?.map((projec) => (
								<li key={projec.uuid}>
									<button
										className={classNames({ active: projec.uuid === activeRole?.uuid })}
										type="button"
										onClick={() => handleChange(projec)}
									>
										<span>{projec.name}</span>

										<span>
											<CheckIcon />
										</span>
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
