import { FC } from 'react';
import Button from 'v2Components/UI/Button';
import { useSelect } from 'hooks';
import classNames from 'classnames';
import { DownloadBlueIcon } from 'assets/inline-svg';
import styles from './styles.module.scss';
import { ReportDownloadMenu } from './ReportDownloadMenu';
import { IReportDownloadButtonProps } from './types';

export const ReportDownloadButton: FC<IReportDownloadButtonProps> = ({ downloadReportUrl }) => {
	const { open, toggleOpen, triggerRef, dropRef } = useSelect();
	return (
		<div className={classNames(styles.reportDownloadButton, { active: open })}>
			<button
				type="button"
				ref={triggerRef}
				onClick={toggleOpen}
				className={classNames(
					styles.reportDownloadButton__wrapper,
					styles.reportDownloadButton__button,
				)}
			>
				<DownloadBlueIcon /> Download
			</button>

			<ReportDownloadMenu
				isOpen={open}
				menuRef={dropRef}
				onClose={toggleOpen}
				downloadReportUrl={downloadReportUrl}
			/>
		</div>
	);
};
