import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
	UsersV2,
	TransactionsV2,
	AccountsV2,
	MerchantsV2,
	WalletV2,
	WalletAddressesV2,
	ReportsAnalyticsV2,
	OrdersV2,
	ApprovementsV2,
	DollarV2,
	TxPendingV2,
	LiquidityV2,
	TranslationsV2,
	NewProjectsV2,
	MarketV2,
	TrendIcon,
} from 'assets/inline-svg';
import {
	getProviderApprovementsDefault,
	getApprovementsDefault,
} from 'redux/reducers/providerApprovements/selectors';
import { getWithdrawal, getWithdrawalFiat } from 'redux/reducers/projectWithdrawal/selectors';
import { routes } from 'routes/routes';
import { useSelector } from 'react-redux';
import { getProviderApprovements } from 'redux/reducers/providerProjectApprovements/selectors';
import { getStorageValue } from 'hooks/useLocalStorage';
import SidebarV2 from '../Sidebar';
import styles from './styles.module.scss';

export const ProviderSidebarV2: FC = () => {
	const administrationBaseMatch = useRouteMatch(routes.administrationBase);
	const walletsBaseMatch = useRouteMatch(routes.walletsBase);
	const marketMatch = useRouteMatch(routes.marketBase);
	const transactionsMatch = useRouteMatch(routes.transactions);
	const accountsMatch = useRouteMatch(routes.bankAccount);
	const walletAddressMatch = useRouteMatch(routes.walletAddressBase);
	const invoiceMatch = useRouteMatch(routes.invoices);
	const ordersMatch = useRouteMatch(routes.orders);
	const trxManagementMatch = useRouteMatch(routes.trxManagementBase);
	const approvementsMatch = useRouteMatch(routes.approvementsBase);
	const pendingWithdrawalMatch = useRouteMatch(routes.pendingWithdrawal);
	const projectApprovementsMatch = useRouteMatch(routes.projectApprovements);
	const liquidityMatch = useRouteMatch(routes.liquidityBase);
	const reportsAnalyticsMatch = useRouteMatch(routes.reportsAnalyticsBase);
	const merchantMatch = useRouteMatch(routes.merchantManagement);
	const approvementMerchant = useSelector(getProviderApprovementsDefault);
	const approvementProvider = useSelector(getApprovementsDefault);
	const approvements = useSelector(getProviderApprovements);
	const withdrawal = useSelector(getWithdrawal);
	const withdrawalFiat = useSelector(getWithdrawalFiat);
	const viewPointA = approvementMerchant?.data.length || approvementProvider?.data.length;
	const viewPointW = withdrawal?.data.length || withdrawalFiat?.data.length;
	const approvementsCount = viewPointA || '';
	const txPendingCount = viewPointW || '';
	const newProjectscCount = approvements?.data.length || '';

	const providerInfo = [
		{
			keyRoles: 'wallets',
			link: `${routes.walletsBase}${routes.wallets.balances}?tabs=crypto`,
			children: 'Wallets',
			icon: <WalletV2 />,
			getIsActive: () => !!walletsBaseMatch,
		},
		{
			keyRoles: 'transactions',
			link: routes.transactions,
			children: 'Transactions',
			icon: <TransactionsV2 />,
			getIsActive: () => !!transactionsMatch,
		},
		{
			keyRoles: 'bank_account',
			link: routes.bankAccount,
			children: 'Accounts',
			icon: <AccountsV2 />,
			getIsActive: () => !!accountsMatch,
		},
		{
			keyRoles: 'invoices',
			link: routes.invoices,
			children: 'Invoices',
			icon: <DollarV2 />,
			getIsActive: () => !!invoiceMatch,
		},
		{
			keyRoles: 'orders',
			link: routes.orders,
			children: 'Orders',
			icon: <OrdersV2 />,
			getIsActive: () => !!ordersMatch,
		},
		{
			keyRoles: 'trxManagement',
			link: routes.trxManagementBase,
			children: 'TRX Management',
			icon: <TrendIcon />,
			getIsActive: () => !!trxManagementMatch,
		},
	];

	const merchantsInfo = [
		{
			keyRoles: 'merchant',
			link: routes.merchantManagement,
			children: 'Merchants',
			icon: <MerchantsV2 />,
			getIsActive: () => !!merchantMatch,
		},
		{
			keyRoles: 'wallet_addresses',
			link: routes.walletAddressBase,
			children: 'Wallet addresses',
			icon: <WalletAddressesV2 />,
			getIsActive: () => !!walletAddressMatch,
		},
		{
			keyRoles: 'pending_withdr',
			link: routes.pendingWithdrawal,
			children: 'TX Pending',
			icon: <TxPendingV2 />,
			getIsActive: () => !!pendingWithdrawalMatch,
			tag: txPendingCount && <span className={styles.nav__dot}>{txPendingCount}</span>,
		},
		{
			keyRoles: 'approvements',
			link: routes.approvementsBase,
			children: 'Approvements',
			icon: <ApprovementsV2 />,
			getIsActive: () => !!approvementsMatch,
			tag: approvementsCount && <span className={styles.nav__dot}>{approvementsCount}</span>,
		},
		{
			keyRoles: 'projects_approvements',
			link: routes.projectApprovements,
			children: 'New Projects',
			icon: <NewProjectsV2 />,
			getIsActive: () => !!projectApprovementsMatch,
			tag: newProjectscCount && <span className={styles.nav__dot}>{newProjectscCount}</span>,
		},
	];

	const settings = [
		{
			keyRoles: 'administration',
			link: `${routes.administrationBase}${routes.administration.teamMembers}`,
			children: 'Users',
			icon: <UsersV2 />,
			getIsActive: () => !!administrationBaseMatch,
		},
		{
			keyRoles: 'market',
			link: `${routes.marketBase}${routes.market.currencies}`,
			children: 'Market',
			icon: <MarketV2 />,
			getIsActive: () => !!marketMatch,
		},
		{
			keyRoles: 'liquidity',
			link: routes.liquidityBase,
			children: 'Liquidity',
			icon: <LiquidityV2 />,
			getIsActive: () => !!liquidityMatch,
		},
		{
			keyRoles: 'reports_analytics',
			link: routes.reportsAnalyticsBase,
			children: 'Reports/Analytics',
			icon: <ReportsAnalyticsV2 />,
			getIsActive: () => !!reportsAnalyticsMatch,
		},
	];

	const sidebarItems = [
		{
			name: 'Provider info',
			items: providerInfo,
		},
		{
			name: 'Merchants info',
			items: merchantsInfo,
		},
		{
			name: 'Settings',
			items: settings,
			children: (
				<button onClick={() => {}} type="button" className={styles.nav__link}>
					<span className={styles.nav__icon}>
						<TranslationsV2 />
					</span>
					<span className={styles.nav__text}>Translations</span>
				</button>
			),
		},
	];

	return <SidebarV2 sidebarItems={sidebarItems} />;
};
