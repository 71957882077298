import { FC } from 'react';
import { toUTC } from 'utils/dates';
import { shortNotation } from 'utils/strings';
import { IListProps } from './types';

export const ApprovementList: FC<IListProps> = ({ info }) => {
	const {
		order_merchant_id,
		invoice_merchant_id,
		project_id,
		created_at,
		status,
		updated_at,
		risk_score,
		address_from,
		address_to,
	} = info;
	return (
		<div className="approvements-list">
			<div className="approvements-column approvements-column--type2">
				<div className="approvements-item">
					<p>Project ID:</p>
					<span>{project_id}</span>
				</div>
				<div className="approvements-item">
					<p>Invoce ID:</p>
					<span>{invoice_merchant_id}</span>
				</div>
				<div className="approvements-item">
					<p>Created:</p>
					<span>{toUTC(String(created_at))}</span>
				</div>
				<div className="approvements-item">
					<p>Current satatus:</p>
					<span>{status.split('_').join(' ')}</span>
				</div>
				<div className="approvements-item">
					<p>Status change:</p>
					<span>{toUTC(String(updated_at))}</span>
				</div>
				<div className="approvements-item">
					<p>Status changed:</p>
					<span>123564</span>
				</div>
			</div>
			<div className="approvements-column approvements-column--type2">
				<div className="approvements-item">
					<p>TX Type:</p>
					<span>Payment</span>
				</div>
				<div className="approvements-item">
					<p>Current TX status:</p>
					<span>In Progres</span>
				</div>
				<div className="approvements-item">
					<p>Original Wallet:</p>
					<a href="#?" className="link">
						{address_from}
					</a>
				</div>
				<div className="approvements-item">
					<p>Destination wallet:</p>
					<a href="#?" className="link">
						{address_to}
					</a>
				</div>
				<div className="approvements-item">
					<p>Risk level:</p>
					<a href="#?" className="link">
						{risk_score}
					</a>
				</div>
			</div>
		</div>
	);
};
