import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { ProjectInfo } from 'layouts-elements/ProjectInfo/ProjectInfo';
import { getProfileLoading } from 'redux/reducers/merchantProfile/selectors';
import { getProjectDetailsLoading } from 'redux/reducers/projectSettings/selectors';
import { getBalanceInDefaultCurrencyRequest, getBurgerReducer } from 'redux/reducers/app/reducer';
import { getBalanceInDefaultCurrency, getStatusBurger } from 'redux/reducers/app/selectors';
import classNames from 'classnames';
import styles from 'v2Components/layoutsComponents/ProviderHeader/styles.module.scss';
import { TriangleArrowLeftIcon } from 'assets/inline-svg';
import { UserInfoV2 } from 'v2Components/layoutsComponents/UserInfo';
import { BalanceInfo } from '../../../layouts-elements/BalanceInfo/BalanceInfo';

let projectIdMemo: string | undefined;

export const ProjectHeaderV2 = () => {
	const { merchantId } = useParams<{ merchantId: string }>();
	const statusBurger = useSelector(getStatusBurger);
	const dispatch = useDispatch();
	const onBurger = () => {
		dispatch(getBurgerReducer(!statusBurger));
		window.scrollTo(0, 0);
	};

	const merchantLoading = useSelector(getProfileLoading);
	const projectLoading = useSelector(getProjectDetailsLoading);
	const { pathname } = useLocation();

	const balanceInDefaultCurrency = useSelector(getBalanceInDefaultCurrency);

	const { projectId } = useParams<{ projectId: string }>();

	useEffect(() => {
		const { balance, currencyCode } = balanceInDefaultCurrency;
		if (balance !== null && currencyCode !== null && projectIdMemo === projectId) {
			return;
		}
		projectIdMemo = projectId;
		dispatch(getBalanceInDefaultCurrencyRequest({ project_id: +projectId }));
	}, [balanceInDefaultCurrency, dispatch, projectId]);

	return (
		<header className={classNames(styles.header, { [styles.active]: statusBurger })}>
			<div className={styles.header__container}>
				<div className={styles.header__rollup} onClick={onBurger}>
					<TriangleArrowLeftIcon />
				</div>
				<div
					className={classNames(styles.header__companies, {
						'header-left header-left--type2': !pathname.includes('/add-project'),
					})}
				>
					{!merchantLoading && !projectLoading && <ProjectInfo />}
				</div>
				<div className={styles.header__info}>
					<BalanceInfo />
					<UserInfoV2 />
					{/* <BurgerBtn /> */}
				</div>
			</div>
		</header>
	);
};
