export const timeOptions = [
	{
		value: '30',
		label: '30 min',
	},
	{
		value: '60',
		label: '60 min',
	},
	{
		value: '120',
		label: '120 min',
	},
	{
		value: '180',
		label: '180 min',
	},
	{
		value: '240',
		label: '240 min',
	},
];
