/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IOrderShowRequest,
	IOrderShowResponse,
	IOrdersPagination,
	IOrdersRequest,
	IMerchantOrdersStore,
	ICommentReducerRequest,
	IComment,
	IInvoiceOrderResponse,
	IInvoiceOrderParams,
	IRefundRequest,
	IParamsSaga,
} from './types';

export const initialState: IMerchantOrdersStore = {
	ordersList: null,
	order: null,
	orderInvoice: null,
	loading: false,
};
/* eslint-disable @typescript-eslint/no-unused-vars */
const merchantOrders = createSlice({
	name: '@@merchantOrders',
	initialState,
	reducers: {
		getOrdersMerchantRequest: (state, _: PayloadAction<IOrdersRequest>) => {
			state.loading = true;
			return state;
		},
		getOrdersMerchantSuccess: (state, { payload }: PayloadAction<IOrdersPagination>) => {
			state.loading = false;
			const merchantOrdersState = state;
			merchantOrdersState.ordersList = payload;
		},

		getOrderDetailsMerchantRequest: (state, _: PayloadAction<IOrderShowRequest>) => state,
		getOrderDetailsMerchantSuccess: (state, { payload }: PayloadAction<IOrderShowResponse>) => {
			const merchantOrdersState = state;
			merchantOrdersState.order = payload;
		},

		createOrderMerchantRequest: (state, _: PayloadAction<ICommentReducerRequest>) => state,
		createOrderMerchantSuccess: (state, { payload }: PayloadAction<IComment>) => {
			const providerOrdersState = state;
			providerOrdersState.order?.order_comments.push(payload);
		},

		createOrderInvoiceMerchantRequest: (state, _: PayloadAction<IInvoiceOrderParams>) => state,
		createOrderInvoiceMerchantSuccess: (
			state,
			{ payload }: PayloadAction<IInvoiceOrderResponse | null>,
		) => {
			const providerOrdersState = state;
			providerOrdersState.orderInvoice = payload;
		},
		createRefundMerchantRequest: (state, _: PayloadAction<IParamsSaga>) => state,
	},
});

export default merchantOrders.reducer;
export const {
	getOrdersMerchantRequest,
	getOrdersMerchantSuccess,
	getOrderDetailsMerchantRequest,
	getOrderDetailsMerchantSuccess,
	createOrderMerchantRequest,
	createOrderMerchantSuccess,
	createOrderInvoiceMerchantRequest,
	createOrderInvoiceMerchantSuccess,
	createRefundMerchantRequest,
} = merchantOrders.actions;
