import { FC } from 'react';
import T from 'i18n-react';
import cryptonix from 'assets/inline-svg/cryptonix.svg';
import { useLocation } from 'react-router';
import { capitalize, urlSplit } from 'utils/strings';
import { IProps } from './types';

export const PaymentHeader: FC<IProps> = ({
	networks,
	amount,
	currencyRequest,
	step,
	paymentOrder,
	payment,
}) => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const urlSuccessParam = searchParams.get('url_success');
	const urlSuccess =
		(urlSuccessParam && capitalize(String(urlSplit(String(urlSuccessParam))))) || '';
	return (
		<>
			{Number(step) > 0 ? null : (
				<span className="payment-label">
					{networks
						? T.translate('PaymentWidget.select')
						: T.translate('PaymentWidget.select_to_payment')}
					<br />
					{!!networks && (
						<>
							{networks?.[0]?.currency_name}
							<span> {networks?.[0]?.currency_code?.toUpperCase()}</span>
						</>
					)}
				</span>
			)}
			<span className="payment-logo">
				<img src={networks?.[0]?.img_path || cryptonix} alt="img" />
			</span>
			<div className="payment-info">
				<span className="payment-site">
					{T.translate('PaymentWidget.purchase')} {urlSuccess}
				</span>
				<span className="payment-price">
					{amount} {currencyRequest}
				</span>

				<ul className="payment-order">
					<li>
						{T.translate('Header.order')}: <span>{paymentOrder?.order_id}</span>
					</li>
					{Number(step) > 0 && payment?.invoice_id ? (
						<li>
							{T.translate('Header.invoice')}: <span>{payment?.invoice_id}</span>
						</li>
					) : null}
				</ul>
			</div>
		</>
	);
};
