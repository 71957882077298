import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	getTransactionsFiatRequest,
	setTransactionsStateFiatFilter,
	setTransactionsStateFiatSearch,
} from 'redux/reducers/providerTransactions/reducer';
import { Pagination } from 'UI/Pagination/Pagination';
import {
	getTransactionsFiat,
	getTransactionsFiatLoader,
	getTransactionsFiatFilter,
	getTransactionsFiatSearch,
} from 'redux/reducers/providerTransactions/selectors';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { getFilter } from 'redux/reducers/app/selectors';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import FilterWrapper from 'v2Components/layoutsComponents/Filter/FilterWrapper';
import Table from 'v2Components/commomComponents/Table';
import Field from 'v2Components/UI/Field';
import { SearchIcon } from 'assets/inline-svg';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import useFilterList from 'hooks/useFilterList';
import {
	IDepositCryptoTransactionItem,
	IDepositCryptoTransactionParams,
} from 'services/api/provider/types';

import { TableRow } from './TableRow/TableRow';
import { Filters } from '../Filters/Filters';
import { IFiatTable } from './types';
import { convertRequestParams, getFilterTransactionsHistory } from '../filter.helper';
import styles from '../CryptoTable/styles.module.scss';

const FiatTable: FC<IFiatTable> = ({ currencyTab }) => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const transactions = useSelector(getTransactionsFiat);
	const loading = useSelector(getTransactionsFiatLoader);
	const searchValue = useSelector(getTransactionsFiatSearch);
	const filters = useSelector(getTransactionsFiatFilter);
	const [showFilter, setShowFilter] = useState(false);
	const { data = [], last_page: totalPages = 1, per_page: perPage } = transactions || {};
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('TransactionsFiat');
	const filterList = useFilterList(getFilterTransactionsHistory);
	const isShowPagination = totalPages > 1 && !loading;
	const setPage = (_page: number) => dispatch(setTransactionsStateFiatFilter({ page: _page }));
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const onSubmit = (data: any) => {
		setPage(1);
		dispatch(setFilter(convertRequestParams(data, 'fiat')));
		setShowFilter(false);
	};
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());
	const onChangeSearch = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			const searchData = value === '' || value.length < 1 ? undefined : value;

			dispatch(setTransactionsStateFiatSearch(searchData));
		},
		[dispatch],
	);

	useEffect(() => {
		const requestData: IDepositCryptoTransactionParams = {
			...filter,
			current_page: filters.page,
		};

		if (searchValue) {
			requestData.search_value = searchValue;
		}

		dispatch(getTransactionsFiatRequest(requestData));
	}, [dispatch, filter, filters.page, searchValue]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<>
			<div className={styles.filters}>
				<Field
					value={searchValue}
					onChange={onChangeSearch}
					placeholder="Search (Tx ID, Tx Hash, Risk Score)"
					renderRightIcon={<SearchIcon />}
					classNameCustom={styles.search}
					classNameInputItem={styles.search__input}
					widthInput={340}
					label="Search (Tx ID, Tx Hash, Risk Score)"
					isDebounce
				/>
				<FilterWrapper
					filterList={filterList}
					deleteItemFilter={deleteItemFilter}
					onClearFilter={onClearFilter}
					onOpenFilter={() => setShowFilter(true)}
					className={styles.filters}
				/>
			</div>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<Filters values={filter} currencyTab={currencyTab} />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={data}
				getRow={(item: IDepositCryptoTransactionItem, index: number) =>
					TableRow({ item, number: index + 1 + (filters.page - 1) * (perPage || 0) })
				}
				gridTemplateColumns={gridTemplateColumns}
			/>
			{isShowPagination && (
				<div className="pagination-block">
					<Pagination
						pageCount={Number(totalPages)}
						onPageChange={setPage}
						forcePage={Number(filters.page)}
					/>
				</div>
			)}
		</>
	);
};

export default FiatTable;
