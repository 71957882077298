import { ReactNode, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import logoImg from 'assets/styles/images/LogoV2.svg';
import logoShort from 'assets/styles/images/sidebar-icons/logo-short.svg';
import {
	setTransactionsStateFilter,
	setTransactionsStateFiatFilter,
} from 'redux/reducers/providerTransactions/reducer';
import { routes } from 'routes/routes';
import { getStatusBurger } from 'redux/reducers/app/selectors';
import { BackToHomeIcon } from 'assets/inline-svg';
import {
	authorizedUserAppDataInit,
	authorizedUserAppDataInitMerchant,
} from 'redux/reducers/app/reducer';
import { getUserData, getUserType } from 'redux/reducers/auth/selectors';

import { constUserTypes } from 'services/constants/user-types';
import SidebarBlock from './SidebarBlock';
import styles from './styles.module.scss';

interface ISidebarItem {
	name?: string;
	items: any[];
	children?: ReactNode;
}

interface IProps {
	sidebarItems: ISidebarItem[];
	isShowBack?: boolean;
}

const SidebarV2 = ({ sidebarItems, isShowBack }: IProps) => {
	const transactionsMatch = useRouteMatch(routes.transactions);
	const statusBurger = useSelector(getStatusBurger);
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const userType = useSelector(getUserType);

	const resetFilter = () => {
		dispatch(
			setTransactionsStateFilter({
				page: 1,
				dateFrom: undefined,
				dateTo: undefined,
				amountFrom: undefined,
				amountTo: undefined,
				currencyName: undefined,
				type: undefined,
				transactionId: undefined,
				currencyNetwork: undefined,
				status: undefined,
				riskScore: undefined,
				merchantId: undefined,
				projectId: undefined,
			}),
		);
		dispatch(
			setTransactionsStateFiatFilter({
				page: 1,
				dateFrom: undefined,
				dateTo: undefined,
				amountFrom: undefined,
				amountTo: undefined,
				currencyName: undefined,
				type: undefined,
				transactionId: undefined,
				currencyNetwork: undefined,
				status: undefined,
				riskScore: undefined,
				merchantId: undefined,
				projectId: undefined,
			}),
		);
	};

	useEffect(() => {
		if (!transactionsMatch) {
			resetFilter();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactionsMatch]);

	useEffect(() => {
		if (userData) {
			// TODO: тригер в этом месте срабатывает на каждый клик по меню - нужна оптимизация
			dispatch(
				userType === constUserTypes.PROVIDER
					? authorizedUserAppDataInit()
					: authorizedUserAppDataInitMerchant(),
			);
		}
	}, [userData, dispatch, userType]);

	return (
		<div className={classNames(styles.sidebar, { [styles.active]: statusBurger })}>
			<nav>
				<Link to="/" className={styles.sidebar__logo}>
					<img src={statusBurger ? logoShort : logoImg} alt="logo" />
				</Link>
				{isShowBack && (
					<Link to="/" className={styles.back}>
						<BackToHomeIcon />
						<span className={styles.back__text}>Home </span>
					</Link>
				)}
				{sidebarItems.map((item) => (
					<SidebarBlock ul={item.items} text={item.name} key={item.name || sidebarItems.length}>
						{item.children}
					</SidebarBlock>
				))}
			</nav>
		</div>
	);
};

export default SidebarV2;
