import Dialog from '@mui/material/Dialog';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyIcon, DepositIcon, PopUpCloseIcon } from 'assets/inline-svg';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import Loader from 'UI/Loader/Loader';
import { cryptoDepositRequest } from 'redux/reducers/merchantAccounts/reducer';
import {
	getDepositLoader,
	getDepositSelector,
	getProjectBalanceList,
} from 'redux/reducers/merchantAccounts/selectors';
import CopyToClipboard from 'react-copy-to-clipboard';
import { shortNotation } from 'utils/strings';
import { notify } from 'utils/notify';
import styles from './styles.module.scss';
import ModalOpen from '../components/ModalOpen';

interface IProps {
	defaultFromId: number;
}
const ModalDeposit = ({ defaultFromId }: IProps) => {
	const dispatch = useDispatch();
	const project = useSelector(getProjectDetails);
	const [open, setOpen] = useState(false);
	const accounts = useSelector(getProjectBalanceList);
	const accountList = useMemo(
		() => accounts?.filter((account) => account.is_crypto === 1 && account.is_active) || [],
		[accounts],
	);
	const filteredCurencies = accountList?.filter((item) => !!item.is_depositable);
	const select = filteredCurencies?.find((coin) => coin.id === defaultFromId) || null;
	const deposit = useSelector(getDepositSelector);
	const loader = useSelector(getDepositLoader);

	const handleAddressCopy = () => notify('Address id copied successfully!', 'info');

	const handleClickOpen = () => setOpen(true);
	const closePopUp = () => setOpen(false);
	useEffect(() => {
		select &&
			open &&
			dispatch(
				cryptoDepositRequest({
					project_balance_id: select.id,
					currency_id: select.currency_id,
					currency_network: select.currency_network,
				}),
			);
		return () => {
			document.body.style.overflow = '';
		};
	}, [dispatch, open, select]);

	return (
		<>
			<ModalOpen handleClickOpen={handleClickOpen} label="Deposit">
				<DepositIcon />
			</ModalOpen>
			<Dialog open={open} onClose={closePopUp}>
				<div className={styles.close} onClick={closePopUp}>
					<PopUpCloseIcon />
				</div>
				<div className={styles.content}>
					<div className={styles.head}>
						{project?.file_icon && (
							<span className={styles.logo}>
								<img src={project.file_icon} alt="" />
							</span>
						)}
						<div>
							<p className={styles.title}>Deposit</p>
							<p className={styles.subtitle}>{project?.name}</p>
						</div>
					</div>
					<div className={styles.body}>
						<p className={styles.body__title}>Account address</p>
						<div className={styles.qrCode}>
							{!!select && !loader && <img src={deposit?.address_qr} alt="qr" />}
							{loader && <Loader />}
						</div>
						<div className={styles.copy}>
							<p>{!loader && shortNotation(String(deposit?.address), 18, 18)}</p>
							{!!select && (
								<CopyToClipboard text={deposit?.address || ''} onCopy={handleAddressCopy}>
									<button aria-label="button" type="button">
										<CopyIcon />
									</button>
								</CopyToClipboard>
							)}
						</div>
					</div>
					<div className={styles.info}>
						<div className={styles.info__item}>
							Deposit limit Min:{' '}
							<span>
								{select?.deposit_min} {select?.network_name.toUpperCase() || ''}
							</span>
						</div>
						<div className={styles.info__item}>
							Deposit limit Max:{' '}
							<span>
								{select?.deposit_max} {select?.network_name.toUpperCase() || ''}
							</span>
						</div>
						<div className={styles.info__item}>
							Deposit Fee:{' '}
							{select?.fees && (
								<span>
									{select.fees.FEE_DEPOSIT.fee_percent.toFixed(1)}% +{' '}
									{select.fees.FEE_DEPOSIT.fee_plus_usd} USD
								</span>
							)}
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default ModalDeposit;
