import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentTrasactionRequest } from 'redux/reducers/providerPaymentTrasaction/reducer';
import {
	getPaymentTrasactionSelector,
	getPaymentTrasactionSelectorLoading,
} from 'redux/reducers/providerPaymentTrasaction/selectors';
import { Pagination, PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import { notify } from 'utils/notify';
import { getInvoiceLinkRequest } from 'redux/reducers/providerInvoices/reducer';
import { getCurrencyNetwork, getFilter } from 'redux/reducers/app/selectors';
import { FilterWrapper, FilterForm } from 'v2Components/layoutsComponents/Filter';
import { clearFilter, getNetworkRequest, setFilter } from 'redux/reducers/app/reducer';
import { getProviderAllCurrencyRequest } from 'redux/reducers/providerCurrency/reducer';
import { IFilterElemItem } from 'v2Components/layoutsComponents/Filter/types';
import { getProviderCurrencyAllNoSettings } from 'redux/reducers/providerCurrency/selectors';
import { IFilter } from 'redux/reducers/app/types';
import { routes } from 'routes/routes';
import { useViewDecimals } from 'hooks/useViewDecimals';
import {
	convertRequestParams,
	getFilterTransactionsHistory,
} from 'v2Pages/Provider/WalletsPage/components/TabsContent/filter.helper';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Table from 'v2Components/commomComponents/Table';
import { IPaymentTrasaction } from 'redux/reducers/providerPaymentTrasaction/types';
import { TableRow } from './Table/TableRow';
import { PaymentTransactionsFormBody } from './PaymentTransactionsFormBody/PaymentTransactionsFormBody';

export const PaymentTransactions: FC = () => {
	const dispatch = useDispatch();
	const { getViewDecimal } = useViewDecimals();
	const transactions = useSelector(getPaymentTrasactionSelector);
	const loading = useSelector(getPaymentTrasactionSelectorLoading);
	const currencies = useSelector(getProviderCurrencyAllNoSettings);
	const networks = useSelector(getCurrencyNetwork);
	const filter = useSelector(getFilter);
	const [showFilter, setShowFilter] = useState(false);
	const { data = [], last_page: totalPages = 1 } = transactions || {};

	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('PaymentTransactions');

	const setInvoiceLink = useCallback(
		(id: number) => {
			dispatch(
				getInvoiceLinkRequest({
					apiParams: { invoice_id: id },
					onSuccess: () => {
						notify('Link to invoice generated successfully', 'success');
					},
				}),
			);
		},
		[dispatch],
	);

	const filterList = useMemo(
		() =>
			Object.entries(filter).reduce((acc: IFilterElemItem[], value) => {
				if (value[0] !== 'current_page' && value[1] !== undefined && value[1] !== null) {
					acc.push(
						getFilterTransactionsHistory({
							id: value[0],
							value: value[1],
							networks,
							currencies,
						}),
					);
				}
				return acc;
			}, []),
		[currencies, filter, networks],
	);

	const onClearFilter = () => dispatch(clearFilter());
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const setCurrentPage = (value: number) => dispatch(setFilter({ ...filter, current_page: value }));
	const onSubmit = (formData: IFilter) => {
		const requestParams = convertRequestParams(formData, routes.wallets.paymentTransactions);
		dispatch(
			setFilter({
				...requestParams,
				current_page: 1,
			}),
		);
		setShowFilter(false);
	};

	useEffect(() => {
		dispatch(getProviderAllCurrencyRequest());
		dispatch(getNetworkRequest());
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(getPaymentTrasactionRequest(filter));
	}, [filter, dispatch]);
	const isShowPagination = totalPages > 1 && !loading;
	return (
		<>
			<div className="mobile-project-info">
				<div className="header-project">
					<div className="header-project__name">
						<p>Provider</p>
					</div>
				</div>
			</div>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<PaymentTransactionsFormBody showFilter={showFilter} />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={data || []}
				getRow={(paymentTransactionsItem: IPaymentTrasaction) =>
					TableRow({
						paymentTransactionsItem,
						getViewDecimal,
						setInvoiceLink,
					})
				}
				gridTemplateColumns={gridTemplateColumns}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={filter.current_page as number}
						onPageChange={setCurrentPage}
					/>
				</PaginationWrapper>
			)}
		</>
	);
};
