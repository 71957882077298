import style from './styles.module.scss';

const TableHeader = () => {
	return (
		<div className={style.tableHeader}>
			<div className={style.td}>
				<div className={style.td__name}>
					<p>Commision</p>
				</div>
			</div>
			<div className={style.tableHeader_right}>
				<div className={style.td}>
					<div className={style.td__name}>
						<p>Fee, %</p>
					</div>
				</div>
				<div className={style.td}>
					<div className={style.td__name}>
						<p>Fee, Fixed</p>
					</div>
				</div>
				<div className={style.td}>
					<div className={style.td__name}>
						<p>Additional, %</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default TableHeader;
