import { DatepickerField } from 'v2Components/formComponents/DatepickerField';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { TextField } from 'v2Components/formComponents/TextField';
import { SearchIcon } from 'assets/inline-svg';
import { OPTIONS_EVENT, OPTIONS_TYPE } from '../filter.helper';

export default () => {
	return (
		<>
			<SelectField title="Event" name="event" options={OPTIONS_EVENT} />
			<SelectField title="Type" name="type" options={OPTIONS_TYPE} />
			<DatepickerField label="Date from" name="date_from" inFilter />
			<DatepickerField label="Date to" name="date_to" inFilter />
			<TextField
				name="response_code"
				type="number"
				label="Response code"
				placeholder="Enter"
				renderRightIcon={<SearchIcon />}
			/>
		</>
	);
};
