import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProviderTransactionFiatRequest } from 'redux/reducers/providerCurrency/reducer';
import { Pagination, PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import {
	getProviderCurrencyAllNoSettings,
	getProviderTransactionsFiat,
	getProviderTransactionsFiatLoading,
} from 'redux/reducers/providerCurrency/selectors';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import { IFilterElemItem } from 'v2Components/layoutsComponents/Filter/types';
import { getFilter } from 'redux/reducers/app/selectors';
import { IFilter } from 'redux/reducers/app/types';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { routes } from 'routes/routes';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { IApiProviderTransactionItem } from 'services/api/provider/types';
import Table from 'v2Components/commomComponents/Table';
import { IPropsTabs } from '../types';
import TransactionsHistoryFormFilter from '../TransactionsHistoryFormFilter';
import { convertRequestParams, getFilterTransactionsHistory } from '../../filter.helper';
import { fiatTableRow } from '../FiatTable/FiatTableRow';

const TransactionsHistoryFiat: FC<IPropsTabs> = ({ currency }) => {
	const dispatch = useDispatch();
	const { getViewDecimal } = useViewDecimals();
	const item = useSelector(getProviderTransactionsFiat);
	const filter = useSelector(getFilter);
	const currencies = useSelector(getProviderCurrencyAllNoSettings);
	const { last_page: totalPages = 1 } = item || {};
	const [showFilter, setShowFilter] = useState(false);
	const loading = useSelector(getProviderTransactionsFiatLoading);
	const filterList = useMemo(
		() =>
			Object.entries(filter).reduce((acc: IFilterElemItem[], value) => {
				if (value[0] !== 'current_page' && value[1] !== undefined && value[1] !== null) {
					acc.push(getFilterTransactionsHistory({ id: value[0], value: value[1], currencies }));
				}
				return acc;
			}, []),
		[currencies, filter],
	);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('TransactionsHistoryFiat');
	const onClearFilter = () => dispatch(clearFilter());
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const setCurrentPage = (value: number) => dispatch(setFilter({ ...filter, current_page: value }));
	const onSubmit = (data: IFilter) => {
		const requestParams = convertRequestParams(
			data,
			`${routes.wallets.transactionHistory}/${String(currency)}`,
		);
		dispatch(
			setFilter({
				...requestParams,
				current_page: 1,
			}),
		);
		setShowFilter(false);
	};

	useEffect(() => {
		dispatch(getProviderTransactionFiatRequest({ apiParams: filter }));
	}, [dispatch, filter]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);
	const isShowPagination = totalPages > 1 && !loading;
	return (
		<>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
			/>
			<TransactionsHistoryFormFilter
				setShowFilter={setShowFilter}
				showFilter={showFilter}
				currencyTab={String(currency)}
				onSubmit={onSubmit}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={item?.data || []}
				getRow={(historyFiatItem: IApiProviderTransactionItem) =>
					fiatTableRow({
						historyFiatItem,
						getViewDecimal,
					})
				}
				gridTemplateColumns={gridTemplateColumns}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={filter.current_page as number}
						onPageChange={setCurrentPage}
					/>
				</PaginationWrapper>
			)}
		</>
	);
};

export default TransactionsHistoryFiat;
