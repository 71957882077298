import Dialog from '@mui/material/Dialog';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	cryptoAutoExchangeRequest,
	getDataToDepositFiatRequest,
} from 'redux/reducers/merchantAccounts/reducer';
import {
	IAutoExchangeRequest,
	IGetDataToDepositFiatPayload,
	IProjectBalanceResponse,
} from 'redux/reducers/merchantAccounts/types';
import { AlertIcon, PopUpCloseIcon } from 'assets/inline-svg';
import { Formik } from 'formik';
import Button from 'v2Components/UI/Button';
import { getProjectBalanceList } from 'redux/reducers/merchantAccounts/selectors';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import CurrencySelect from '../components/CurrencySelect';
import styles from './styles.module.scss';

interface IProps {
	defaultFromId: number;
	closePopUp: () => void;
	comeback: () => void;
	isOpen: boolean;
}
const ModalAutoExchange = ({ defaultFromId, closePopUp, isOpen, comeback }: IProps) => {
	const dispatch = useDispatch();
	const [warning, setWarning] = useState(false);
	const project = useSelector(getProjectDetails);
	const accounts = useSelector(getProjectBalanceList);
	const currencies = useMemo(
		() => accounts?.filter((account) => account.is_crypto === 1 && account.is_active) || [],
		[accounts],
	);
	const [currencyFrom, setCurrencyFrom] = useState<IProjectBalanceResponse | null>(
		currencies.find(
			(coin) =>
				coin.id === defaultFromId &&
				!accounts?.some(
					(currency) => currency?.auto_exchange?.auto_exchange_code === coin.currency.code,
				),
		) || null,
	);
	const [currencyTo, setCurrencyTo] = useState<IProjectBalanceResponse | null>(
		currencies?.find(
			(coin) =>
				coin.id !== currencyFrom?.id &&
				!accounts?.some(
					(currency) => currency?.auto_exchange?.auto_exchange_code === coin.currency.code,
				),
		) || null,
	);
	const onSubmit = () => {
		if (!currencyFrom?.id || !currencyTo?.id) {
			return;
		}
		const defaultData: IAutoExchangeRequest = {
			from_balance_id: currencyFrom?.id,
			to_balance_id: currencyTo?.id,
		};
		dispatch(cryptoAutoExchangeRequest({ data: { ...defaultData }, closePopUp }));
	};

	return (
		<Dialog open={isOpen} onClose={closePopUp}>
			<div className={styles.close} onClick={closePopUp}>
				<PopUpCloseIcon />
			</div>
			<Formik initialValues={{}} onSubmit={onSubmit}>
				<div className={styles.content}>
					<div className={styles.head}>
						{project?.file_icon && (
							<span className={styles.logo}>
								<img src={project.file_icon} alt="" />
							</span>
						)}
						<div>
							<p className={styles.title}>Exchange</p>
							<p className={styles.subtitle}>{project?.name}</p>
						</div>
					</div>
					<div className={styles.body}>
						<div className={styles.body__description}>
							All incoming BTC transfers will be converted to the currency of your choice
							automaticaly at the current exchange rate.
						</div>
						<CurrencySelect
							label="From"
							options={currencies}
							value={currencyFrom}
							setValue={setCurrencyFrom}
						/>
						<CurrencySelect
							label="To"
							options={currencies}
							value={currencyTo}
							setValue={setCurrencyTo}
						/>
						{warning && (
							<div className={styles.warning}>
								<div className={styles.warning__icon}>
									<AlertIcon />
								</div>
								<div>
									<p className={styles.warning__title}>Attention</p>
									<div className={styles.warning__text}>
										Please, note, that {warning} on current project is set to auto withdrawal.
										Received amount will be sent to an external address
									</div>
								</div>
							</div>
						)}
					</div>
					<div className={styles.buttons}>
						<Button onClick={closePopUp} type="button" className={styles.button} light>
							Cancel
						</Button>
						<Button onClick={onSubmit} type="button" className={styles.button} disabled={warning}>
							Submit
						</Button>
					</div>
					<div className={styles.description}>
						Do you want to exchange only part of your existing currency? Go to the exchange section!
					</div>
					<div className={styles.autoExchange} onClick={comeback}>
						Exchange
					</div>
				</div>
			</Formik>
		</Dialog>
	);
};

export default ModalAutoExchange;
