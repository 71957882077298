import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProviderAllCurrencySettingRequest,
	putProviderAllCurrencySettingRequest,
} from 'redux/reducers/providerCurrency/reducer';
import { notify } from 'utils/notify';
import {
	TApiGetProviderAllCurrencySettingResponse,
	TApiPutProviderAllCurrencySettingPayload,
} from 'redux/reducers/providerCurrency/types';
import { getProviderCurrencyAll } from 'redux/reducers/providerCurrency/selectors';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { IInSubmitProps } from './types';
import style from './styles.module.scss';

const FreeWithdraw = () => {
	const dispatch = useDispatch();
	const item = useSelector(getProviderCurrencyAll);

	useEffect(() => {
		const payload: any = {};
		dispatch(getProviderAllCurrencySettingRequest(payload));
	}, [dispatch]);
	const onSubmit = (body: IInSubmitProps) => {
		const payload: TApiPutProviderAllCurrencySettingPayload = {
			apiParams: {
				...body,
			},

			onSuccess: () => {
				notify('Platform limitation changed successfully', 'success');
			},
		};
		dispatch(putProviderAllCurrencySettingRequest(payload));
	};

	return (
		<div className={style.table}>
			<div className={style.table__block}>
				<TableHeader />
				<div className={style.table__body}>
					{item?.map((e: TApiGetProviderAllCurrencySettingResponse) => {
						return <TableBody key={e.id} item={e} onSubmit={onSubmit} />;
					})}
				</div>
			</div>
		</div>
	);
};

export default FreeWithdraw;
