import { FC } from 'react';
import { toUTC } from 'utils/dates';
import { IListProps } from './types';

export const ApprovementsStatus: FC<IListProps> = ({ statusInfo }) => {
	const { status, created_at, creator } = statusInfo;
	return (
		<div className="approvements-item">
			<span>
				{toUTC(String(created_at))}
				<span>Status created by {creator}</span>
			</span>
			<span>{status.split('_').join(' ')}</span>
		</div>
	);
};
