import { FC, useState, useRef, useMemo, useCallback, useEffect } from 'react';
import T from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { getAvailableCurrencies } from 'redux/reducers/paymentWidget/selectors';
import { getMerchantInvoiceStatusRequest } from 'redux/reducers/paymentWidget/reducer';
import { IGetMerchantInvoiceStatusPayload } from 'redux/reducers/paymentWidget/types';
import paymentIcon from 'assets/inline-svg/payment/payment-1.svg';
import { dateToUTC } from 'utils/dates';
import { PaymentHistory } from '../PaymentHistory/PaymentHistory';
import { getDateDiffFormatted } from './utils';
import { IMakePayment } from './types';
import { FormNotify } from '../FormNotify/FormNotify';
import { Steps } from '../PaymentWidget';

export const PaymentProgress: FC<IMakePayment> = ({
	payment,
	onSuccess,
	onExpired,
	setChangeStatus,
	viewNotify,
	setNotify,
}) => {
	const dispatch = useDispatch();
	const currencies = useSelector(getAvailableCurrencies);

	const [restTime, setRestTime] = useState<{ mm: string; ss: string }>({
		mm: '15',
		ss: '00',
	});

	const { mm, ss } = restTime;

	const { amount, currency_paid, address, due_date: dueDateStr, invoice_id } = payment || {};

	// @TODO: Лучше получать изображение с сервера
	const currencyPaidObj = currencies?.find(
		(item) => item.currency_code === currency_paid?.toLowerCase(),
	);

	const intervalRef = useRef<number | null>(null);
	const intervalInvoiceRequestRef = useRef<number | null>(null);
	const dueDate = useMemo(() => new Date(dueDateStr || ''), [dueDateStr]);

	const intervalCallback = useCallback(() => {
		const date = dateToUTC(new Date());
		const { mm: _mm, ss: _ss } = getDateDiffFormatted(dueDate, date);
		setRestTime({ mm: _mm, ss: _ss });
	}, [dueDate]);

	const invoiceRequestIntervalCallback = useCallback(() => {
		if (!invoice_id && invoice_id !== 0) {
			return;
		}
		const payload: IGetMerchantInvoiceStatusPayload = {
			apiParams: {
				invoice_id: +invoice_id,
			},
			onSuccess: (resp) => {
				const { status } = resp;
				switch (status) {
					case 'in_progress' || 'in_approvement' || 'risk_processing ':
						setChangeStatus?.(Steps.Progress);
						break;
					case 'overpaid':
						setChangeStatus?.(Steps.Overpaid);
						break;
					case 'underpaid':
						setChangeStatus?.(Steps.Underpaid);
						break;
					case 'overdue' || 'overdue_and_paid':
						setChangeStatus?.(Steps.Overdu);
						break;
					case 'paid' || 'blocked':
						onSuccess?.();
						break;
					default:
						onExpired?.();
				}
			},
		};
		dispatch(getMerchantInvoiceStatusRequest(payload));
		/* eslint-disable-next-line */
	}, [dispatch, invoice_id, onSuccess, setChangeStatus]);

	const onLoad = useCallback(() => {
		if (!intervalRef.current) {
			intervalRef.current = window.setInterval(intervalCallback, 1000);
		}
		if (!intervalInvoiceRequestRef.current) {
			intervalInvoiceRequestRef.current = window.setInterval(invoiceRequestIntervalCallback, 10000);
		}
	}, [intervalCallback, invoiceRequestIntervalCallback]);

	const clearIntervals = () => {
		if (intervalRef.current) {
			window.clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
		if (intervalInvoiceRequestRef.current) {
			window.clearInterval(intervalInvoiceRequestRef.current);
			intervalInvoiceRequestRef.current = null;
		}
	};

	useEffect(() => {
		if (mm === '00' && ss === '00') {
			setChangeStatus?.(Steps.Overdu);
		}
		/* eslint-disable-next-line */
	}, [mm, ss]);

	useEffect(() => {
		clearIntervals();
		onLoad();
		return () => {
			clearIntervals();
		};
	}, [onLoad]);

	return (
		<>
			<div className="payment-conf">
				<span className="payment-title">
					<img src={paymentIcon} alt="icon" />
					{T.translate('Progress.title')}
				</span>
				<span className="payment-text">
					{payment.currency_paid} {T.translate('Progress.text')}
				</span>
			</div>
			<FormNotify payment={payment} viewNotify={viewNotify} onSuccess={setNotify} />
			<PaymentHistory payment={payment} type="progress" />
		</>
	);
};
