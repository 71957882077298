import { FC } from 'react';
import { AuthLayout } from 'layouts/AuthLayout/AuthLayout';
import { ConfirmIpAddress } from 'components/Auth/ConfirmIpAddress/ConfirmIpAddress';

export const ConfirmIpAddressPage: FC = () => {
	return (
		<AuthLayout title="Confirm ip address">
			<ConfirmIpAddress />
		</AuthLayout>
	);
};
