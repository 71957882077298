import { trimLastZeros } from 'utils/strings';
import { Token } from 'v2Components/UI/Token';
import { ArrowDownIcon, ArrowUpIcon, EditIcon } from 'assets/inline-svg';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import Deposit from 'v2Pages/Provider/WalletsPage/components/TabsContent/Balances/PopUp/Deposit';
import Withdraw from 'v2Pages/Provider/WalletsPage/components/TabsContent/Balances/PopUp/Withdraw';
import Button from 'v2Components/UI/Button';
import PopUpWithdrawalFiat from 'v2Pages/Provider/WalletsPage/components/TabsContent/Balances/PopUp/Withdraw/PopUpWithdrawalFiat';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { useState } from 'react';
import { TApiGetProviderAllCurrencySettingResponse } from 'redux/reducers/providerCurrency/types';

interface IProps {
	currency: TApiGetProviderAllCurrencySettingResponse;
}
const TableRow = ({ currency: currencyItem }: IProps) => {
	const {
		id,
		currency_network,
		provider_balance,
		total_merchant_balance,
		currency,
		view_decimals,
	} = currencyItem;

	const [depositPopUp, setDepositPopUp] = useState(0);
	const [withdrawPopUp, setWithdrawPopUp] = useState(0);
	const [popUpOpen, setPopUpOpen] = useState(0);
	const withdrawPopUpModal = () => setWithdrawPopUp(0);
	const depositPopUpModal = () => setDepositPopUp(0);
	const onPopUps = (value: number) => setPopUpOpen(value);
	const onClosePopUps = () => setPopUpOpen(0);
	const handleOpenModalDeposit = () => setDepositPopUp(id);

	return [
		{
			title: 'Currency',
			children: (
				<Token
					name={currency.name}
					code={
						currency.code === currency_network
							? currency.code.toUpperCase()
							: `${currency.code.toUpperCase()}, ${currency_network.toUpperCase()}`
					}
					img={currency.img_path}
					type="second"
				/>
			),
		},
		{
			title: 'Balance',
			children: trimLastZeros(Number(provider_balance).toFixed(view_decimals)),
		},
		{
			title: 'Merchant balance',
			children: trimLastZeros(Number(total_merchant_balance).toFixed(view_decimals)) || '0',
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<Button onClick={handleOpenModalDeposit} type="button" tableAction>
						<ArrowDownIcon />
						Deposit
					</Button>
					<Button onClick={() => onPopUps(id)} type="button" tableAction>
						<ArrowUpIcon />
						Withdrawal
					</Button>
					<Button type="button" simple="regular">
						<EditIcon />
					</Button>
					<PopupModal open={depositPopUp === id} onClose={depositPopUpModal}>
						<Deposit onClose={depositPopUpModal} item={currencyItem} />
					</PopupModal>
					{popUpOpen > 0 && (
						<PopUpWithdrawalFiat
							onClosePopUps={onClosePopUps}
							popUpOpen={popUpOpen === id}
							item={currencyItem}
						/>
					)}
					<PopupModal open={withdrawPopUp === id} onClose={withdrawPopUpModal}>
						<Withdraw onClose={withdrawPopUpModal} item={currencyItem} isCrypto={false} />
					</PopupModal>
				</TableButtons>
			),
		},
	];
};

export default TableRow;
