import { Link } from 'react-router-dom';
import { EyeIcon } from 'assets/inline-svg';
import { ReactNode } from 'react';
import TableButtons from '../../TableButtons';
import styles from './styles.module.scss';

interface IProps {
	detailsUrl: string;
	children?: ReactNode;
}
const LinkDetails = ({ detailsUrl, children }: IProps) => {
	return (
		<TableButtons right>
			{children}
			<Link to={detailsUrl} className={styles.link}>
				<EyeIcon />
			</Link>
		</TableButtons>
	);
};
export default LinkDetails;
