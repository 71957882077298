/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiCreateApiKeyResponse,
	IApiDeleteApiKeyParams,
	TApiGenerateCallbackSecretResponse,
	IApiGetApiParams,
	IApiGetApiResponse,
	IApiGetProjectDetailsParams,
	IApiGetProjectDetailsResponse,
	TApiSaveCallbackUrlResponse,
	IApiUpdateApiKeyResponse,
	TApiChangeProjectDetailsResponse,
} from 'services/api/project/types';
import {
	IChangeProjectDetailsPayload,
	IClearCallbackUrlPayload,
	ICreateApiKeyPayload,
	IDeleteApiKeyPayload,
	IGenerateCallbackSecretPayload,
	IGenerateWidgetKeyPayload,
	IGenerateWidgetKeySuccessPayload,
	IProjectSettingsStore,
	ISaveCallbackUrlPayload,
	IUpdateApiKeyPayload,
} from './types';

export const initialState: IProjectSettingsStore = {
	projectDetails: null,
	projectDetailsLoading: false,
	api: null,
	apiLoading: false,
};

const projectSettings = createSlice({
	name: '@@projectSettings',
	initialState,
	reducers: {
		getProjectDetailsRequest: (state, action: PayloadAction<IApiGetProjectDetailsParams>) => {
			state.projectDetailsLoading = true;
		},
		getProjectDetailsMerchantRequest: (
			state,
			action: PayloadAction<IApiGetProjectDetailsParams>,
		) => {
			state.projectDetailsLoading = true;
		},
		getProjectDetailsSuccess: (
			state,
			action: PayloadAction<IApiGetProjectDetailsResponse | null>,
		) => {
			state.projectDetails = action.payload;
			state.projectDetailsLoading = false;
		},
		getProjectDetailsError: (state) => {
			state.projectDetails = null;
			state.projectDetailsLoading = false;
		},

		changeProjectDetailsRequest: (state, action: PayloadAction<IChangeProjectDetailsPayload>) =>
			state,
		changeProjectDetailsMerchantRequest: (
			state,
			action: PayloadAction<IChangeProjectDetailsPayload>,
		) => state,
		changeProjectDetailsSuccess: (
			state,
			action: PayloadAction<TApiChangeProjectDetailsResponse>,
		) => {
			state.projectDetails = action.payload;
		},

		getApiRequest: (state, action: PayloadAction<IApiGetApiParams>) => {
			state.apiLoading = true;
		},
		getApiMerchantRequest: (state, action: PayloadAction<IApiGetApiParams>) => {
			state.apiLoading = true;
		},
		getApiSuccess: (state, { payload }: PayloadAction<IApiGetApiResponse>) => {
			state.api = payload;
			state.apiLoading = false;
		},
		getApiError: (state) => {
			state.api = null;
			state.apiLoading = false;
		},

		createApiKeyRequest: (state, action: PayloadAction<ICreateApiKeyPayload>) => state,
		createApiKeySuccess: (state, { payload }: PayloadAction<IApiCreateApiKeyResponse>) => {
			if (!state.api?.api_keys) {
				return;
			}
			state.api.api_keys = [payload];
		},

		updateApiKeyRequest: (state, action: PayloadAction<IUpdateApiKeyPayload>) => state,
		updateApiKeySuccess: (state, { payload }: PayloadAction<IApiUpdateApiKeyResponse>) => {
			if (!state.api?.api_keys) {
				return;
			}
			state.api.api_keys = state.api.api_keys.map((apiKey) => {
				return apiKey.id === payload.id ? payload : apiKey;
			});
		},

		deleteApiKeyRequest: (state, action: PayloadAction<IDeleteApiKeyPayload>) => state,
		deleteApiKeySuccess: (state, { payload }: PayloadAction<IApiDeleteApiKeyParams>) => {
			if (!state.api?.api_keys) {
				return;
			}
			state.api.api_keys = state.api.api_keys.filter((apiKey) => {
				return apiKey.id !== payload.id;
			});
		},

		saveCallbackUrlRequest: (state, action: PayloadAction<ISaveCallbackUrlPayload>) => state,
		saveCallbackUrlSuccess: (state, action: PayloadAction<TApiSaveCallbackUrlResponse>) => {
			const { api_callback_url } = action.payload;

			if (state.projectDetails) {
				state.projectDetails.api_callback_url = api_callback_url;
			}
			if (state.api) {
				state.api.callback_url = api_callback_url;
			}
		},

		clearCallbackUrlRequest: (state, action: PayloadAction<IClearCallbackUrlPayload>) => state,
		clearCallbackUrlSuccess: (state, action: PayloadAction<TApiSaveCallbackUrlResponse>) => {
			if (state.projectDetails) {
				state.projectDetails.api_callback_url = '';
			}
			if (state.api) {
				state.api.callback_url = '';
			}
		},

		generateCallbackSecretRequest: (state, action: PayloadAction<IGenerateCallbackSecretPayload>) =>
			state,
		generateCallbackSecretSuccess: (
			state,
			action: PayloadAction<TApiGenerateCallbackSecretResponse>,
		) => {
			const { api_callback_secret_key } = action.payload;

			if (state.projectDetails) {
				state.projectDetails.api_callback_secret_key = api_callback_secret_key;
			}
			if (state.api) {
				state.api.callback_secret = api_callback_secret_key;
			}
		},

		generateWidgetKeyRequest: (state, action: PayloadAction<IGenerateWidgetKeyPayload>) => state,
		generateWidgetKeyMerchantRequest: (state, action: PayloadAction<IGenerateWidgetKeyPayload>) =>
			state,
		generateWidgetKeySuccess: (state, action: PayloadAction<IGenerateWidgetKeySuccessPayload>) => {
			const { apiParams, apiResponse } = action.payload;

			if (state.projectDetails?.id !== apiParams.project_id) {
				return;
			}
			state.projectDetails.widget_key = apiResponse;
		},
	},
});

export default projectSettings.reducer;
export const {
	getApiRequest,
	getApiSuccess,
	getApiError,
	getProjectDetailsRequest,
	getProjectDetailsMerchantRequest,
	getProjectDetailsSuccess,
	getProjectDetailsError,
	changeProjectDetailsRequest,
	changeProjectDetailsSuccess,
	createApiKeyRequest,
	createApiKeySuccess,
	updateApiKeyRequest,
	updateApiKeySuccess,
	deleteApiKeyRequest,
	deleteApiKeySuccess,
	saveCallbackUrlRequest,
	saveCallbackUrlSuccess,
	clearCallbackUrlRequest,
	clearCallbackUrlSuccess,
	generateCallbackSecretRequest,
	generateCallbackSecretSuccess,
	generateWidgetKeyRequest,
	generateWidgetKeyMerchantRequest,
	generateWidgetKeySuccess,
	changeProjectDetailsMerchantRequest,
	getApiMerchantRequest,
} = projectSettings.actions;
