import { FC } from 'react';
import { IProps } from './types';

export const PaymentTime: FC<IProps> = ({ width, message, minets, seconds }) => {
	return (
		<div className="payment-time">
			<div className="payment-time_line">
				<span style={{ width }} />
			</div>
			<span className="payment-time_text">
				{message}{' '}
				<span>
					{minets || '13'}m {seconds || '25'}s
				</span>
			</span>
		</div>
	);
};
