import classNames from 'classnames';
import { IToken } from './type';
import styles from './styles.module.scss';

export const Token = ({ img, name, code, type }: IToken): JSX.Element => {
	return (
		<span
			className={classNames(styles.token, {
				[styles.token__center]: code === 'All',
				[styles.token__type2]: type === 'second',
			})}
		>
			{img && (
				<span className={styles.token__icon}>
					<img src={img} alt={name} />
				</span>
			)}
			{name && !type && <span className={styles.token__name}>{name}</span>}
			<span className={styles['token__name-wrap']}>
				{name && type === 'second' && <span className={styles.token__name}>{name}</span>}
				<span className={styles.token__transcription}>{code}</span>
			</span>
		</span>
	);
};

export const DefaultToken = (): JSX.Element => (
	<span className={styles.token}>
		<span className={styles.token__transcription}>All</span>
	</span>
);
