import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BackArrowWidget } from 'assets/inline-svg';
import T from 'i18n-react';
import { IAvailableCurrency } from 'services/api/public/types';
import logo from 'assets/inline-svg/logo-black.svg';
import { dateToUTC } from 'utils/dates';
import { useLocation } from 'react-router';
import { getCreatedPayment, getCreatedUpdatePayment } from 'redux/reducers/paymentWidget/selectors';
import { changeAppLanguage } from 'redux/reducers/app/reducer';
import { ChooseCoinForm } from './ChooseCoinForm/ChooseCoinForm';
import { MakePayment as MakePaymentContent } from './MakePayment/MakePayment';
import { PaymentSuccess } from './PaymentSuccess/PaymentSuccess';
import { PaymentError } from './PaymentError/PaymentError';
import { PaymentHeader } from './PaymentHeader/PaymentHeader';
import { PaymentProgress } from './PaymentProgress/PaymentProgress';
import { PaymentOverpayd } from './PaymentOverpayd/PaymentOverpayd';
import { UnderPayment } from './UnderPayment/UnderPayment';
import { TimeOver } from './TimeOver/TimeOver';
import LangSwitcher from './LangSwitcher/LangSwitcher';

export enum Steps {
	ChooseCoin,
	MakePayment,
	Success,
	Expired,
	Progress,
	Underpaid,
	Overpaid,
	Overdu,
}

export const PaymentWidget = () => {
	const [step, setStep] = useState<Steps>(Steps.ChooseCoin);
	const paymentOrder = useSelector(getCreatedPayment)?.payment || {};
	const payment = useSelector(getCreatedUpdatePayment)?.payment || {};
	const statusPayment = useSelector(getCreatedUpdatePayment)?.status || '';
	const location = useLocation();
	const [networks, setNetworks] = useState<IAvailableCurrency[] | null>(null);
	const [viewNotify, setViewNotify] = useState(true);
	const [paymentStep, setPaymentStep] = useState('');
	const dispatch = useDispatch();

	const searchParams = new URLSearchParams(location.search);
	const currencyRequest = searchParams.get('order_currency');
	const amount = searchParams.get('order_price');
	const lang = searchParams.get('lang');

	useEffect(() => {
		if (lang) {
			dispatch(changeAppLanguage(lang));
		}
	}, [lang, dispatch]);

	const onChooseCoinSuccess = () => {
		setStep(Steps.MakePayment);
	};

	const duration =
		payment && payment.due_date
			? new Date(payment.due_date).getTime() - dateToUTC(new Date()).getTime()
			: 900000;

	const onSuccess = () => {
		setStep(Steps.Success);
	};

	const onExpired = () => {
		setStep(Steps.Expired);
	};
	/* eslint-disable */
	useEffect(() => {
		if (location.pathname === '/payment-method') {
			document.body.style.backgroundColor = '#1C1C1E';
			require('assets/styles/custom/main.css');
		}
	}, [location]);

	const backaToStart = () => {
		setNetworks(null);
		setStep(Steps.ChooseCoin);
	};

	const popupWidth = step === Steps.ChooseCoin ? 620 : 800;

	return (
		<div className="wrapper">
			<main className="main">
				<div className="payment">
					<div className="container two">
						<div className="payment-top">
							{networks && step === 0 ? (
								<>
									<button type="button" onClick={() => setNetworks(null)} className="payment-back">
										<BackArrowWidget />
										Back
									</button>
									<PaymentHeader
										networks={networks}
										amount={amount}
										currencyRequest={currencyRequest}
										paymentOrder={paymentOrder}
									/>
								</>
							) : (
								<>
									{step > 0 ? (
										<button type="button" onClick={() => backaToStart()} className="payment-back">
											<BackArrowWidget />
											Cancel
										</button>
									) : null}
									<PaymentHeader
										networks={networks}
										amount={amount}
										currencyRequest={currencyRequest}
										step={step}
										payment={payment}
										paymentOrder={paymentOrder}
									/>
								</>
							)}
						</div>

						{step === Steps.ChooseCoin && (
							<ChooseCoinForm
								onSuccess={onChooseCoinSuccess}
								networks={networks}
								setNetworks={setNetworks}
								onExpired={onExpired}
							/>
						)}
						{step === Steps.MakePayment && (
							<MakePaymentContent
								payment={payment}
								onSuccess={onSuccess}
								onExpired={onExpired}
								setChangeStatus={setStep}
								durationTime={duration}
								viewNotify={viewNotify}
								setNotify={() => setViewNotify(false)}
								setPaymentStep={setPaymentStep}
							/>
						)}
						{step === Steps.Success && <PaymentSuccess payment={payment} />}
						{step === Steps.Expired && <PaymentError payment={statusPayment} networks={networks} />}
						{step === Steps.Progress && (
							<PaymentProgress
								payment={payment}
								viewNotify={viewNotify}
								setNotify={() => setViewNotify(false)}
								onSuccess={onSuccess}
								setChangeStatus={setStep}
							/>
						)}
						{step === Steps.Overpaid && (
							<PaymentOverpayd payment={payment} setChangeStatus={setStep} />
						)}
						{step === Steps.Underpaid && (
							<UnderPayment
								payment={payment}
								onSuccess={onSuccess}
								onExpired={onExpired}
								setChangeStatus={setStep}
								durationTime={duration}
							/>
						)}

						{step === Steps.Overdu && <TimeOver payment={payment} startPayment={paymentStep} />}
					</div>
				</div>
			</main>
			<footer className="footer">
				<div className="container">
					<div className="footer-wrap">
						<div className="footer-box">
							<a href="/" className="footer-logo">
								<img src={logo} alt="logo" />
							</a>
							<span className="footer-terms">
								{T.translate('PaymentWidget.footer_text')} <a href="#">Cookie Policy</a>
							</span>
						</div>
						<div className="footer-buttons">
							<a href="#" className="footer-support">
								{T.translate('PaymentWidget.support')}
							</a>
							<LangSwitcher />
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};
