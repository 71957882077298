import { IApi } from './types';
import { app } from './app';
import { auth } from './auth';
import { provider } from './provider';
import { merchant } from './merchant';
import { project } from './project';
import { projectClients } from './projectClients';
import { publicApi } from './public';

export const api: IApi = {
	app,
	auth,
	provider,
	merchant,
	project,
	projectClients,
	public: publicApi,
};
