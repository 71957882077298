import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
	ProjectsIcon,
	ExclamationRoundIcon,
	TransactionsIcon,
	Approvements,
	SecurityMerchant,
	SettingsMerchant,
} from 'assets/inline-svg';
import { routes } from 'routes/routes';
import { useMerchantMatchUrl } from 'hooks';
import { constUserTypes } from 'services/constants/user-types';
import { useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import SidebarV2 from '../Sidebar';

export const MerchantSidebarV2: FC = () => {
	const userType = useSelector(getUserType);
	const merchantUrl = useMerchantMatchUrl();
	const projectsMatch = useRouteMatch(merchantUrl + routes.oneMerchant.merchantProjects);
	const accountsMatch = useRouteMatch(merchantUrl + routes.oneMerchant.bankAccount);
	const profileMatch = useRouteMatch(merchantUrl + routes.oneMerchant.profile);
	const approvalMatch = useRouteMatch(merchantUrl + routes.oneMerchant.approvalManagement);
	const securityMatch = useRouteMatch(merchantUrl + routes.oneMerchant.security);
	const settingsMatch = useRouteMatch(merchantUrl + routes.oneMerchant.settings);
	const isProvider = userType === constUserTypes.PROVIDER;
	const providerInfo = [
		{
			keyRoles: 'projects',
			link: merchantUrl + routes.oneMerchant.merchantProjects,
			children: 'Projects',
			icon: <ProjectsIcon />,
			getIsActive: () => !!projectsMatch,
		},
		{
			keyRoles: 'accounts',
			link: merchantUrl + routes.oneMerchant.bankAccount,
			children: 'Accounts',
			icon: <TransactionsIcon />,
			getIsActive: () => !!accountsMatch,
		},
		{
			keyRoles: 'profile',
			link: merchantUrl + routes.oneMerchant.profile,
			children: 'Profile',
			icon: <ExclamationRoundIcon />,
			getIsActive: () => !!profileMatch,
		},
		{
			keyRoles: 'users',
			link: merchantUrl + routes.oneMerchant.approvalManagement,
			children: 'Users',
			icon: <Approvements />,
			getIsActive: () => !!approvalMatch,
		},
		{
			keyRoles: 'security',
			link: merchantUrl + routes.oneMerchant.security,
			children: 'Security',
			icon: <SecurityMerchant />,
			getIsActive: () => !!securityMatch,
		},
		{
			keyRoles: 'settings',
			link: merchantUrl + routes.oneMerchant.settings,
			children: 'Settings',
			icon: <SettingsMerchant />,
			getIsActive: () => !!settingsMatch,
		},
	];

	return (
		<SidebarV2
			sidebarItems={[
				{
					items: providerInfo,
				},
			]}
			isShowBack={isProvider}
		/>
	);
};
