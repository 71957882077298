import { useRouteMatch } from 'react-router';
import { routes } from 'routes/routes';
import { trimRightSlash } from 'utils/strings';

export const useMerchantMatchUrl = () => {
	const merchantMatch = useRouteMatch(routes.oneMerchantBase);
	const merchantUrl = trimRightSlash(merchantMatch?.url || '');

	return merchantUrl;
};
