import { FC } from 'react';
import { IWhiteListIPBlockProps } from './types';
import { WhiteListIpItem } from './WhiteListIpItem/WhiteListIpItem';

export const WhiteListIPBlock: FC<IWhiteListIPBlockProps> = ({ user }) => {
	return (
		<>
			<div className="block-title block-title--type2">
				<p>White list ip</p>
			</div>

			<div className="table-block">
				<div className="table table--user-profile-white-list">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Last used at</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>IP</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>

					<div className="table-body">
						{user.white_list_ip.map((item) => (
							<WhiteListIpItem key={item.id} item={item} />
						))}
					</div>
				</div>
			</div>
		</>
	);
};
