/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TProviderApprovements, IGetApprovementsParams } from 'services/api/provider/types';
import { IProviderProjectApprovementsStore, IVerifyProjectPayload } from './types';

export const initialState: IProviderProjectApprovementsStore = {
	approvements: null,
	approvementsLoading: false,
	verifyLoading: false,
};

const providerProjectApprovements = createSlice({
	name: '@@providerProjectApprovements',
	initialState,
	reducers: {
		getApprovementRequest: (state, action: PayloadAction<IGetApprovementsParams>) => {
			state.approvementsLoading = true;
		},
		getApprovementsSuccess: (state, { payload }: PayloadAction<TProviderApprovements>) => {
			state.approvementsLoading = false;
			state.approvements = payload;
		},
		getApprovementsError: (state) => {
			state.approvementsLoading = false;
		},
		verifyProjectRequest: (state, action: PayloadAction<IVerifyProjectPayload>) => {
			state.verifyLoading = true;
		},
		verifyProjectSuccess: (state, { payload }: PayloadAction<IVerifyProjectPayload>) => {
			const {
				apiParams: { project_id },
				onSuccess,
			} = payload;
			state.verifyLoading = false;

			if (!state.approvements?.data) {
				return;
			}

			const newData = state.approvements.data.filter((item) => item.id !== project_id);
			state.approvements.data = newData;
			onSuccess?.();
		},
		verifyProjectError: (state) => {
			state.verifyLoading = false;
		},
		unverifyProjectRequest: (state, action: PayloadAction<IVerifyProjectPayload>) => {
			state.verifyLoading = true;
		},
		unverifyProjectSuccess: (state, { payload }: PayloadAction<IVerifyProjectPayload>) => {
			const {
				apiParams: { project_id },
				onSuccess,
			} = payload;
			state.verifyLoading = false;

			if (!state.approvements?.data) {
				return;
			}

			const newData = state.approvements.data.map((item) =>
				item.id === project_id
					? {
							...item,
							is_verified: 0 as 0 | 1,
					  }
					: item,
			);
			state.approvements.data = newData;
			onSuccess?.();
		},
		unverifyProjectError: (state) => {
			state.verifyLoading = false;
		},
		deleteProjectRequest: (state, action: PayloadAction<IVerifyProjectPayload>) => {
			state.verifyLoading = true;
		},
		deleteProjectSuccess: (state, { payload }: PayloadAction<IVerifyProjectPayload>) => {
			const {
				apiParams: { project_id },
				onSuccess,
			} = payload;
			state.verifyLoading = false;
			if (!state.approvements?.data) {
				return;
			}
			const newData = state.approvements.data.filter((item) => item.id !== project_id);
			state.approvements.data = newData;
			onSuccess?.();
		},
		deleteProjectError: (state) => {
			state.verifyLoading = false;
		},
	},
});

export default providerProjectApprovements.reducer;
export const {
	deleteProjectError,
	deleteProjectRequest,
	deleteProjectSuccess,
	getApprovementRequest,
	getApprovementsError,
	getApprovementsSuccess,
	unverifyProjectError,
	unverifyProjectRequest,
	unverifyProjectSuccess,
	verifyProjectError,
	verifyProjectRequest,
	verifyProjectSuccess,
} = providerProjectApprovements.actions;
