import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlusIcon, Union2Img } from 'assets/inline-svg';
import { notify } from 'utils/notify';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { IAddCryptoWalletParams, IEditCryptoWalletParams } from 'services/api/merchant/types';
import { getWalletsList } from 'redux/reducers/merchantBankAccount/selectors';
import { constUserTypes } from 'services/constants/user-types';
import {
	addWithdrawalAddressRequest,
	deleteWithdrawalAddressRequest,
	editWithdrawalAddressRequest,
} from 'redux/reducers/merchantBankAccount/reducer';
import { getProfile } from 'redux/reducers/merchantProfile/selectors';
import { getUserType } from 'redux/reducers/auth/selectors';

import CryptoWalletItem from './CryptoWalletItem';
import { TEditWalletFormOnSubmit } from '../types';
import DeleteWalletModal from '../modal/DeleteWalletModal';
import CryptoWalletForm from './CryptoWalletForm';
import styles from './styles.module.scss';
import CryptoWalletEdit from './CryptoWalletEdit';

const CODE_AUTO_EXCHANGE = 10;

interface IPropsCryptoWallet {
	updateItems: () => void;
}
const CryptoWallet: FC<IPropsCryptoWallet> = ({ updateItems }) => {
	const dispatch = useDispatch();
	const walletMerchantList = useSelector(getWalletsList);
	const [openDelModal, setOpenDelModal] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [force, setForce] = useState(false);
	const [item, setItem] = useState<any>({
		provider_reserve_wallet_id: null,
		reserve_wallet_name: null,
		reserve_wallet: null,
	});
	const [openModal, setOpenModal] = useState(false);
	const userData = useSelector(getProfile);
	const userType = useSelector(getUserType);

	const closeModal = () => {
		setForce(false);
		setOpenModal(false);
	};

	const closeDelModal = () => {
		setForce(false);
		setOpenDelModal(false);
	};

	const openEditModal = (_item: any) => {
		setItem(_item);
		setOpenModal(true);
	};

	const openDeleteModal = (_item: any) => {
		setItem(_item);
		setOpenDelModal(true);
	};
	const handleDeleteWallet = () => {
		dispatch(
			deleteWithdrawalAddressRequest({
				apiParams: { auto_withdrawal_address_id: item.id, force },
				onSuccess: () => {
					closeDelModal();
					notify('Wallet has been successfully deleted', 'success');
					updateItems();
				},
				onError: (error) => {
					const errorInfo = error.response?.data;
					if (errorInfo?.error_code === CODE_AUTO_EXCHANGE) {
						setForce(true);
					}
				},
			}),
		);
	};

	const handleEditWallet: TEditWalletFormOnSubmit = (values) => {
		const apiParams: IEditCryptoWalletParams = {
			auto_withdrawal_address_id: item.id,
			name_address: values.name_address,
			address: values.address,
		};

		if (userType === constUserTypes.PROVIDER)
			apiParams.owner_merchant_id = userData?.merchant.owner_merchant_id;

		dispatch(
			editWithdrawalAddressRequest({
				apiParams,
				onSuccess: () => {
					closeModal();
					notify('Wallet has been successfully edited', 'success');
				},
			}),
		);
	};

	const handleAddWallet = (values: IAddCryptoWalletParams, resetForm: () => void) => {
		const payload: IAddCryptoWalletParams = {
			currency_setting_id: values.currency_setting_id,
			name_address: values.name_address,
			address: values.address,
			is_crypto: 1,
		};
		if (userType === constUserTypes.PROVIDER)
			payload.owner_merchant_id = userData?.merchant.owner_merchant_id;
		dispatch(
			addWithdrawalAddressRequest({
				apiParams: payload,
				onSuccess: () => {
					setOpenModal(false);
					resetForm();
					notify('Wallet has been successfully added', 'success');
				},
			}),
		);
	};
	return (
		<div className={styles.crypto}>
			{walletMerchantList?.map((el) => (
				<CryptoWalletItem
					key={el.id}
					item={el}
					openEditModal={openEditModal}
					openDeleteModal={openDeleteModal}
				/>
			))}
			<div className={styles.append} onClick={() => setShowFilter(true)}>
				<div className={styles.append__icon}>
					<PlusIcon />
				</div>
				<p className={styles.append__text}>
					Add New <br /> Crypto Wallet
				</p>
				<div className={styles.append__union}>
					<Union2Img />
				</div>
			</div>
			<PopupModal open={openDelModal} onClose={closeDelModal}>
				<DeleteWalletModal onClose={closeDelModal} onSubmit={handleDeleteWallet} force={force} />
			</PopupModal>
			<CryptoWalletEdit
				setShowFilter={setOpenModal}
				showFilter={openModal}
				onSubmit={handleEditWallet}
				item={item}
			/>
			<CryptoWalletForm
				setShowFilter={setShowFilter}
				showFilter={showFilter}
				onSubmit={handleAddWallet}
			/>
		</div>
	);
};

export default CryptoWallet;
