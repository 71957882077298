import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IGetAvailableCurrenciesParams,
	IAvailableCurrency,
	ICreatePaymentResponse,
	IGetMerchantInvoiceStatusParams,
	ICreateClientResponse,
	IGetMerchantInvoiceStatusResponse,
} from 'services/api/public/types';
import {
	getAvailableCurrenciesRequest,
	getAvailableCurrenciesSuccess,
	getAvailableCurrenciesError,
	createPaymentRequest,
	createPaymentSuccess,
	createPaymentError,
	getMerchantInvoiceStatusRequest,
	getMerchantInvoiceStatusStatus,
	getUpdatePaymentRequest,
	getUpdatePaymentSuccess,
	createUpdatePaymentRequest,
	createUpdatePaymentSuccess,
	createUpdatePaymentError,
} from './reducer';
import {
	ICreatePaymentPayload,
	IGetMerchantInvoiceStatusPayload,
	ICreateUpdatePaymentPayload,
	IUpdatePaymentPayload,
} from './types';

function* getAvailableCurrenciesWorker({ payload }: PayloadAction<IGetAvailableCurrenciesParams>) {
	try {
		const response: IAvailableCurrency[] = yield call(api.public.getAvailableCurrencies, payload);
		yield put(getAvailableCurrenciesSuccess(response));
	} catch (error) {
		yield put(getAvailableCurrenciesError());
		showError(error);
	}
}

function* createPaymentWorker({ payload }: PayloadAction<ICreatePaymentPayload>) {
	const { apiParams, onSuccess, onError } = payload;
	try {
		const response: ICreatePaymentResponse = yield call(api.public.createPayment, apiParams);
		yield put(createPaymentSuccess(response));
		onSuccess?.(response);
	} catch (error) {
		onError?.();
		yield put(createPaymentError());
		showError(error);
	}
}

function* createUpdatePaymentWorker({ payload }: PayloadAction<ICreateUpdatePaymentPayload>) {
	const { apiParams, onSuccess, onError } = payload;
	try {
		const response: ICreatePaymentResponse = yield call(api.public.createUpdatePayment, apiParams);
		yield put(createUpdatePaymentSuccess(response));
		onSuccess?.(response?.status);
	} catch (error) {
		onError?.();
		yield put(createUpdatePaymentError());
		showError(error);
	}
}
/*eslint-disable*/
function* getMerchantInvoiceStatusWorker({
	payload,
}: PayloadAction<IGetMerchantInvoiceStatusPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: IGetMerchantInvoiceStatusResponse = yield call(
			api.public.getMerchantInvoiceStatus,
			apiParams,
		);
		onSuccess?.(response);
		if (response.extra_invoice_id) {
			yield put(
				getMerchantInvoiceStatusRequest({
					...payload,
					apiParams: { ...apiParams, invoice_id: response.extra_invoice_id },
				}),
			);
		}
		yield put(getMerchantInvoiceStatusStatus(response));
	} catch (error) {
		showError(error);
	}
}

function* updatePaymentRequestWorker({ payload }: PayloadAction<IUpdatePaymentPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: ICreateClientResponse = yield call(api.public.updatePayment, apiParams);
		onSuccess?.(response);
		yield put(getUpdatePaymentSuccess(response));
	} catch (error) {
		showError(error);
	}
}

export function* paymentWidgetSaga() {
	yield takeEvery(getAvailableCurrenciesRequest, getAvailableCurrenciesWorker);
	yield takeEvery(createPaymentRequest, createPaymentWorker);
	yield takeEvery(getMerchantInvoiceStatusRequest, getMerchantInvoiceStatusWorker);
	yield takeEvery(getUpdatePaymentRequest.type, updatePaymentRequestWorker);
	yield takeEvery(createUpdatePaymentRequest, createUpdatePaymentWorker);
}
