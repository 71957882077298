import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { FC } from 'react';
import { useLocation } from 'react-router';
import { showCharts, showChartsProvider } from 'services/constants/analytics';

interface IProps {
	chart: { [key: string]: number };
	handleSelects: (value: string, key?: string) => void;
}

export const SelectShowCharts: FC<IProps> = ({ chart, handleSelects }) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect({});
	const numberShow = Object.values(chart).filter((el) => !!el);
	const { pathname } = useLocation();
	const charts = pathname.includes('merchant') ? showCharts : showChartsProvider;

	const onSelect = (value: string) => {
		handleSelects(chart[value] ? '0' : '1', value);
		// setOpen(false);
	};

	const onReplace = (value: string) => {
		return value.toLowerCase().replaceAll(' ', '_');
	};

	return (
		<div className="select-block">
			<div className={`select select--type-checkbox select--show-chart ${open ? 'active' : ''}`}>
				<button ref={triggerRef} onClick={toggleOpen} type="button" className="select__current">
					{`Show ${numberShow.length} charts`}
					<span className="select__current-arrow">
						<span className="select__current-arrow">
							<TriangleArrowDownIcon2 />
						</span>
					</span>
				</button>
				<div className="select__drop select__drop--width-340" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{charts.map((chartName) => (
									<li key={chartName}>
										<div className="checkbox checkbox--mb-0 ">
											<label className="checkbox__label">
												<input
													type="checkbox"
													className="hidden"
													onChange={() => onSelect(onReplace(chartName))}
													checked={!!chart[onReplace(chartName)]}
												/>
												<span className="checkbox__item">
													<span className="checkbox__item-icon">
														<svg
															width="14"
															height="11"
															viewBox="0 0 14 11"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																d="M5 10.42L0 5.42L1.41 4.01L5 7.59L12.59 0L14 1.42L5 10.42Z"
																fill="#9996BE"
															/>
														</svg>
													</span>
												</span>
												<span className="checkbox__text">{chartName}</span>
											</label>
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
