import { Redirect, Route, Switch } from 'react-router';
import { routes } from 'routes/routes';
import { Orders } from './Orders/Orders';
import { OrderDetails } from './Orders/OrdersDetails/OrdersDetails';
import styles from './styles.module.scss';

export const OrdersPage = () => {
	return (
		<div className={styles.main}>
			<Switch>
				<Route exact path={routes.orders}>
					<Orders />
				</Route>
				<Route exact path={routes.orderDetails}>
					<OrderDetails />
				</Route>
				<Redirect to={routes.orders} />
			</Switch>
		</div>
	);
};
