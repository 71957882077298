import { FC } from 'react';
import { SelectAnalytics } from 'UI/Select/Select';
import { SelectShowCharts } from 'UI/SelectShowCharts/SelectShowCharts';
import { PlusBlueIcon, PlusWhiteIcon } from 'assets/inline-svg';
import Button from 'v2Components/UI/Button';
import { IPropsHeaderAnalytics } from './types';
import styles from './styles.module.scss';

export const HeaderAnalytics: FC<IPropsHeaderAnalytics> = ({
	chart,
	time,
	handleSelects,
	provider,
	onPopup,
}) =>
	provider === 'merchant' ? (
		<div className={styles.header}>
			<div className="item-line">
				<div className="item">
					<SelectAnalytics time={time} handleSelects={handleSelects} />
				</div>
				<div className="item">
					<SelectShowCharts chart={chart} handleSelects={handleSelects} />
				</div>
			</div>

			<div className="item-line">
				<Button onClick={onPopup} className={styles.header__button}>
					<PlusBlueIcon /> Add Different Statistics
				</Button>
			</div>
		</div>
	) : (
		<div className="item-wrap">
			<div className="item">
				<SelectAnalytics time={time} handleSelects={handleSelects} />
			</div>
			<div className="item">
				<SelectShowCharts chart={chart} handleSelects={handleSelects} />
			</div>
		</div>
	);
