import { CloseAccountIcon } from 'assets/inline-svg';
import { FC } from 'react';

interface IProps {
	onSubmit: () => void;
	closePopup: () => void;
	refund: string | number;
	network: string;
}

export const PopUpRefund: FC<IProps> = ({ onSubmit, closePopup, refund, network }) => (
	<div className="popup-window">
		<div className="popup-window__inside">
			<div className="popup popup--width-460">
				<button aria-label="button" onClick={closePopup} type="button" className="popup__close">
					<CloseAccountIcon />
				</button>
				<div className="popup-header">
					<p className="popup-header__title">Confirm refund</p>
				</div>

				<div className="popup-body">
					<div className="popup-text">
						<p>{`Are you sure you want to return ${refund} ${network.toUpperCase()}`}</p>
					</div>
				</div>
				<div className="popup-button popup-button--type2">
					<button
						onClick={closePopup}
						type="button"
						className="button button--full-width button--type2"
					>
						Cancel
					</button>
					<button onClick={onSubmit} type="button" className="button button--full-width">
						Refund
					</button>
				</div>
			</div>
		</div>
	</div>
);
