import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useProjectMatchUrl } from 'hooks';
import { EyeIcon } from 'assets/inline-svg';
import { resendCallbackRequest } from 'redux/reducers/projectCallbacks/reducer';
import { IResendCallbackPayload } from 'redux/reducers/projectCallbacks/types';
import { notify } from 'utils/notify';
import { trimBottomLine, capitalize } from 'utils/strings';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { ITableRow } from './types';

export const TableRow = ({ item }: ITableRow) => {
	const dispatch = useDispatch();
	const projectUrl = useProjectMatchUrl();
	const {
		id,
		creator_entity_id: creatorEntityId,
		response_code: responseCode,
		project_transaction_id: projectTransactionId,
	} = item;
	const event = capitalize(trimBottomLine(item.event));
	const type = capitalize(trimBottomLine(item.type));
	const href = `${projectUrl}${routes.oneProject.callbacks.item.replace(':id', String(id))}`;

	const handleResend = () => {
		const payload: IResendCallbackPayload = {
			apiParams: {
				id,
			},
			onSuccess: () => notify('Callback was successfully resent', 'success'),
		};
		dispatch(resendCallbackRequest(payload));
	};

	const transactionLink = `${projectUrl}/transaction/details/${String(
		projectTransactionId,
	)}/crypto/${type[0].toLowerCase()}${type.slice(1, type.length)}`;

	return [
		{
			title: 'Id',
			children: (
				<Link to={href} className="link">
					{id}
				</Link>
			),
		},
		{
			title: 'Tx ID',
			children: <Link to={transactionLink}>{projectTransactionId}</Link>,
		},
		{
			title: 'Event',
			children: event,
		},
		{
			title: 'Type',
			children: type,
		},
		{
			title: 'Received status',
			children: '-',
		},
		{
			title: 'Custom ID',
			children: '-',
		},
		{
			title: 'Risk score',
			children: '-',
		},
		{
			title: 'Responce code',
			children: responseCode,
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<button
						type="button"
						onClick={handleResend}
						className="button button--fw-500 button--fs-14 button--type9"
					>
						Resend callback
					</button>
					<a aria-label="button" href={href}>
						<EyeIcon />
					</a>
				</TableButtons>
			),
		},
	];
};
