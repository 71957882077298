import { FC, useState } from 'react';
import { DownloadBlueIcon, TrashIcon } from 'assets/inline-svg';
import { useDispatch } from 'react-redux';
import { deleteClientDocumentRequest } from 'redux/reducers/projectClients/reducer';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { ImagePreview } from 'UI/Popups/ImagePreview/ImagePreview';
import { IKYCDocumentsItemProps } from './types';

export const KYCDocumentsItem: FC<IKYCDocumentsItemProps> = ({ item }) => {
	const dispatch = useDispatch();
	const [isPreviewModal, setIsPreviewModal] = useState(false);

	const closeModal = () => setIsPreviewModal(false);

	const handleDelete = () => {
		dispatch(deleteClientDocumentRequest({ client_document_id: item.id }));
	};

	return (
		<div className="kyc-document-interactionist-wrap">
			<div className="kyc-document-interactionist">
				<p className="kyc-document-text">{item.file}</p>

				<div className="kyc-document-buttons">
					<button
						className="kyc-document-btn"
						type="button"
						onClick={() => setIsPreviewModal(true)}
					>
						Show
					</button>

					<a
						aria-label="button"
						href={item.file_path}
						target="_blank"
						rel="noreferrer"
						className="kyc-document-btn kyc-document-btn--icon"
					>
						<DownloadBlueIcon />
					</a>
				</div>
			</div>

			<button className="button button--type10" type="button" onClick={handleDelete}>
				<span className="btn-icon">
					<TrashIcon />
				</span>
				Delete
			</button>

			<PopupModal open={isPreviewModal} onClose={closeModal}>
				<ImagePreview imageSrc={item.file_path} onClose={closeModal} />
			</PopupModal>
		</div>
	);
};
