import { FC } from 'react';
import * as yup from 'yup';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { Form, Formik } from 'formik';
import { TextareaField } from 'UI/Forms/TextareaField/TextareaField';
import { TextField } from 'UI/Forms/TextField/TextField';
import { IAddEditApiKeyFormProps, IAddEditApiKeyFormValues } from './types';

export const AddEditApiKeyForm: FC<IAddEditApiKeyFormProps> = ({
	isEdit,
	apiKey,
	onSubmit,
	onClose,
}) => {
	const initValues: IAddEditApiKeyFormValues = {
		description: apiKey?.description || '',
		ip_list: '',
		totp: '',
	};

	const validationSchema = yup.object().shape({
		description: yup.string().required('Required').min(4, 'Too short').max(200, 'Too long'),
		ip_list: yup.string().required('Required').min(4, 'Too short').max(200, 'Too long'),
		totp: yup
			.string()
			.required('Totp code is required')
			.min(6, 'Totp is too short')
			.max(6, 'Totp is too long'),
	});

	return (
		<Formik initialValues={initValues} validationSchema={validationSchema} onSubmit={onSubmit}>
			{({ dirty }) => (
				<Form className="popup popup--width-480">
					<button aria-label="button" type="button" className="popup__close" onClick={onClose}>
						<PopUpCloseIcon />
					</button>

					<div className="popup-header">
						<p className="popup-header__title popup-header__title--ta-l">
							{isEdit ? 'Edit API Key' : 'Add an API Key'}
						</p>
					</div>

					<div className="popup-body popup-body--type2">
						<TextField
							name="ip_list"
							label="IP Addresses"
							placeholder="Enter IP Addresses"
							subDescription="Enter multiple IP addresses separated by commas"
						/>

						<TextareaField name="description" label="Description" placeholder="Enter your text" />
					</div>

					<TextField
						label="Enter the 2FA 6-digit Code"
						type="text"
						placeholder="Enter code"
						name="totp"
						maxLength={6}
						onlyNumbers
					/>

					<div className="popup-button popup-button--type2">
						<button
							type="button"
							className="button button--full-width button--type2"
							onClick={onClose}
						>
							Cancel
						</button>
						<button type="submit" className="button button--full-width" disabled={isEdit && !dirty}>
							{isEdit ? 'Confirm' : 'Add an API Key'}
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};
