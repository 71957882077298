import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { CopyIcon } from 'assets/inline-svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { shortNotation, trimLastZeros } from 'utils/strings';
import { notify } from 'utils/notify';
import { useDispatch } from 'react-redux';
import { getOwnerMerchantIdRequest } from 'redux/reducers/providerWalletAddresses/reducer';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import { IProviderItem } from 'redux/reducers/providerWalletAddresses/types';
import styles from './styles.module.scss';

export default (item: IProviderItem): TdItem[] => {
	if (!item) {
		return [];
	}
	const {
		address,
		type,
		balance,
		currency_network,
		project_id,
		client_id,
		id,
		currency,
		created_at,
		address_url,
	} = item;
	const { id: clienId } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const history = useHistory();
	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = currency?.code
		? getViewDecimal(currency?.code, currency_network)
		: undefined;

	const handleClick = () => client_id && history.push(`/wallet-addresses/${String(client_id)}`);

	const handleClickProject = () =>
		dispatch(
			getOwnerMerchantIdRequest({
				params: {
					project_id: String(project_id),
				},
				checkId: (ownerId: string) => {
					if (ownerId) {
						history.push(`/merchant/${String(ownerId)}/project/${project_id}`);
						return;
					}
					notify('owner not fined!', 'info');
				},
			}),
		);

	const handleAddressCopy = () => notify('Address copied successfully!', 'info');

	return [
		{
			title: 'Address ID',
			children: id || '---',
		},
		{
			title: 'Project ID',
			children: (
				<button
					onClick={handleClickProject}
					type="button"
					style={{ pointerEvents: project_id ? 'auto' : 'none' }}
					className={`link ${project_id ? '' : 'unactive--color'}`}
				>
					{project_id || '---'}
				</button>
			),
		},
		{
			title: 'Client ID',
			children: (
				<button
					type="button"
					onClick={handleClick}
					style={{ pointerEvents: client_id ? 'auto' : 'none' }}
					className={`link ${client_id ? '' : 'unactive--color'}`}
				>
					{client_id || '---'}
				</button>
			),
		},
		{
			title: 'Address number',
			children: (
				<div className="copy-address copy-address--type2 tooltip-item">
					<a className="link" href={address_url} target="_blank" rel="noreferrer">
						{address && shortNotation(address, 6, 6)?.toUpperCase()}
					</a>
					<CopyToClipboard text={String(address)} onCopy={handleAddressCopy}>
						<button type="button">
							<CopyIcon />
							<span className="tooltip tooltip--top tooltip--center tooltip--no-arrow">
								<span className="tooltip__text tooltip__text--ta-c">
									<span>
										Click to copy: <br />
										{address}
									</span>
								</span>
							</span>
						</button>
					</CopyToClipboard>
				</div>
			),
		},
		{
			title: 'Currency',
			children: (
				<>
					{currency?.code?.toLocaleUpperCase()}({currency_network?.toLocaleUpperCase()})
				</>
			),
		},
		{
			title: 'Date&Time',
			children: created_at && `${moment.utc(created_at).format('DD.MM.YYYY, HH:mm')} UTC`,
		},
		{
			title: 'Address type',
			children: type,
		},
		{
			title: 'Address balance',
			children: <div className={styles.right}>{trimLastZeros(balance.toFixed(viewDecimals))}</div>,
		},
	];
};
