import moment from 'moment';
import { TApiGetProviderAllCurrencyResponse } from 'services/api/provider/types';
import { IGetCurrencyNetworkItem } from 'services/api/public/types';
import { IFilterElemItem } from 'v2Components/layoutsComponents/Filter/types';

export interface TransactionsHistory {
	id: string;
	value: string | number | Date | null;
	networks?: IGetCurrencyNetworkItem[];
	currencies?: TApiGetProviderAllCurrencyResponse[];
}

export const getFilterProjectClientsHistory = ({
	id,
	value,
	networks,
	currencies,
}: TransactionsHistory): IFilterElemItem => {
	switch (id) {
		case 'client_id':
		case 'internal_id':
		case 'last_name':
		case 'email':
		case 'phone':
			return { identify: id, content: value as string };
		default:
			return { identify: '', content: '' };
	}
};
