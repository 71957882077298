export const endpoint = {
	app: {
		GET_COUNTRIES: '/v1/core/get-countries',
		GET_LANGUAGES: '/v1/core/available-languages',
		GET_CURRENCY_NETWORK: '/v1/core/get-currency-network',
	},
	auth: {
		CREATE_PASSWORD: '/v1/auth/first-time-login/reset',
		CONFIRM_RESET_PUB_PASS: '/v1/auth/confirm-reset-password',
		CONFIRM_IP_PUB_ADDRESS: '/v1/auth/confirm-ip',
		FORGOT_PASSWORD_MERCHANT: '/v1/auth/merchant/forgot-password',
		FORGOT_PASSWORD_PROVIDER: '/v1/auth/provider/forgot-password',
		LOGIN: '/v1/auth/provider/login',
		LOGOUT: '/v1/auth/provider/logout',
		FIRST_LOGIN: '/v1/auth/first-time-login/get-2fa',
		REQUEST_2FA_RESET: '/v1/auth/request-2fa-reset',
		RESET_2FA_PROVIDER: '/v1/auth/provider/2fa/confirm-reset',
		RESET_2FA_MERCHANT: '/v1/auth/merchant/2fa/confirm-reset',
	},
	authMerchant: {
		LOGIN: '/v1/auth/merchant/login',
		LOGOUT: '/v1/auth/merchant/logout',
	},
	provider: {
		// Merchant
		GET_MERCHANT_OWNERS: '/v1/auth/provider/merchants/merchant-owners',
		TOGGLE_MERCHANT_OWNER_BLOCK: '/v1/auth/provider/merchants/toggle-block-for-merchant-owner',
		PROVIDER_RESET_PASSWORD: '/v1/auth/provider/merchants/reset-password',
		DELETE_MERCHANT_OWNER: '/v1/auth/provider/merchants/delete-merchant-owner',
		ADD_REGISTER_MERCHANT_AND_PROJECT: '/v1/auth/provider/register-merchant-owner-with-project',
		ADD_REGISTER_MERCHANT_OWN_PROJECT: '/v1/merchants/register-merchant-owner-with-project', // НЕТ ТАКОГО МАРШРУТА НА Backend
		// Administration
		TEAM_MEMBERS: 'v1/auth/provider/providers/team-members',
		TEAM_MEMBERS_MERCHANT: 'v1/auth/merchant/team-members',
		GET_ROLES: '/v1/auth/provider/roles',
		GET_USER_PROFILE: '/v1/auth/provider/providers/profile',
		EDIT_PROVIDER_PROFILE: '/v1/auth/provider/providers/edit-profile',
		EDIT_PROVIDER_ROLE: '/v1/auth/provider/providers/edit-role',
		DELETE_PROVIDER: '/v1/auth/provider/providers/delete',
		DELETE_WHITE_LIST_IP_RECORD: '/v1/auth/provider/providers/white-list-ip',
		DELETE_WHITE_LIST_IP_RECORD_MERCHANT: '/v1/auth/merchant/white-list-ip',
		ADD_NEW_ADMIN: '/v1/auth/provider/providers/register-provider',
		ADD_NEW_ADMIN_MERCHANT: '/v1/merchant/register-provider', // НЕТ ТАКОГО МАРШРУТА НА Backend
		// TODO: Move to Merchant (// Profile)
		GET_USER_PROFILE_MERCHANT: (id: string | number) =>
			`/v1/auth/provider/merchants/profile?merchant_id=${id}`,
		POST_USER_PROFILE_MERCHANT: '/v1/auth/provider/merchants/edit-profile',
		POST_USER_PROFILE_MERCHANT_TOGGLE: '/v1/auth/provider/merchants/toggle-activity',
		POST_TOGGLE_BLOCK: '/v1/auth/provider/providers/toggle-block',
		// Market
		currency: {
			GET_ALL_CURRENCY: `v1/core/provider/all-currency`,
			GET_ALL_CURRENCY_SETTING: `v1/core/provider/all-currency-setting`,
			PUT_ALL_CURRENCY_SETTING: 'v1/core/provider/edit-currency-setting',
			GET_ALL_CURRENCY_TRANSACTIONS: 'v1/core/provider/get-provider-crypto-transaction',
			GET_ALL_CURRENCY_PROFIT: 'v1/core/provider/get-provider-profit',
			POST_CREATE_WITHDRAWAL: 'v1/core/provider/create-withdrawal',
			GET_DEPOSIT: 'v1/core/provider/get-deposit-address',
			GET_CURRENCY_TRANSACTIONS_FIAT: 'v1/core/provider/get-wallet-fiat-transactions-history',
		},
		export: {
			GET_TRANSACTIONS_CSV: `v1/core/provider/export-cvs-crypto-transaction`, // НЕТ ТАКОГО МАРШРУТА НА Backend
			GET_TRANSACTIONS_FIAT_CSV: `v1/core/provider/export-cvs-provider-fiat-transaction`,
		},
		fee: {
			GET_FEE: 'v1/core/provider/all-fee',
			PUT_FEE: 'v1/core/provider/edit-fee',
		},
		wallets: {
			GET_BALANCES: 'v1/core/provider/balances', // НЕТ ТАКОГО МАРШРУТА НА Backend
			PUT_BALANCES: 'v1/core/provider/balances', // НЕТ ТАКОГО МАРШРУТА НА Backend
		},
		// Wallet
		POST_PROVIDER_FIAT_WITHDRAWAL: '/v1/core/provider/create-provider-fiat-withdrawal',
		// Index
		GET_INVOICES: '/v1/core/provider/invoice/list',
		CREATE_INVOICE: '/v1/core/provider/invoice/create',
		GET_INVOICE_PDF: '/v1/core/provider/invoice/get-pdf',
		SEND_INVOICE_PDF: '/v1/core/provider/invoice/send-pdf',
		SEND_INVOICE_LINK: '/v1/core/provider/invoice/send',
		GET_INVOICE: '/v1/core/get-provider-invoice',
		GET_INVOICE_LINK: '/v1/core/provider/invoice/get-link',
		EXPORT_CSV_INVOICE_PROVIDER: '/v1/core/provider/export-cvs-invoice-provider',
		DOWNLOAD_INVOICES_REPORT: '/v1/core/provider/download-invoices-report',
		GET_PROVIDER_INVOICE_STATUS: '/v1/core/get-provider-invoice-status',
		// Reports
		GET_REPORTS: '/v1/core/provider/get-report-provider',
		CREATE_FIAT_TRANSACTIONS_REPORT: '/v1/core/provider/create-report-fiat-transactions',
		DOWNLOAD_FIAT_TRANSACTIONS_REPORT: '/v1/core/provider/download-report-fiat-transactions',
		CREATE_CRYPTO_TRANSACTIONS_REPORT: '/v1/core/provider/create-report-crypto-transactions',
		DOWNLOAD_CRYPTO_TRANSACTIONS_REPORT: '/v1/core/provider/download-report-crypto-transactions',
		DOWNLOAD_PROVIDER_CRYPTO_TRANSACTIONS:
			'/v1/core/provider/download-report-provider-crypto-transactions',
		DOWNLOAD_PROVIDER_FIAT_TRANSACTIONS:
			'/v1/core/provider/download-report-provider-fiat-transactions',
		DOWNLOAD_PROVIDER_PAYMENT_TRANSACTIONS:
			'/v1/core/provider/download-report-provider-payment-transactions',
		DOWNLOAD_ORDERS_REPORT: '/v1/core/provider/download-orders-report',
		CREATE_CURRENCY_FLOW_REPORT: '/v1/core/provider/create-report-currency-flow-provider',
		CREATE_BALANCES_REPORT: '/v1/core/provider/create-report-balances-provider',
		DOWNLOAD_BALANCES_REPORT: '/v1/core/provider/download-report-balances-provider',
		CREATE_BALANCES_FIAT_REPORTS:
			'/v1/core/provider/create-report-project-fiat-transactions-provider',

		// Index
		GET_APPROVEMENTS_MERCHANT: '/v1/core/provider/transaction-approvement/merchant',
		GET_APPROVEMENTS_PROVIDER: '/v1/core/provider/transaction-approvement/provider',
		PUT_REQUEST_KYC_PROVIDER: '/v1/core/provider/transaction-approvement/send-kyc-request',
		PUT_PROVIDER_STATUS: '/v1/core/provider/transaction-approvement/change-status',
		PUT_PROVIDER_REFOUND: '/v1/core/provider/transaction-approvement/refund',
		GET_PROVIDER_TRANSACTION: (id: string) => `/v1/core/provider/transaction-approvement/${id}`,
		POST_PROVIDER_ADD_COMMENT: `/v1/core/provider/add-transaction-approvement-comment`,
		// Bank Account
		GET_BANK_ACCOUNTS: '/v1/core/provider/get-bank-accounts',
		ADD_BANK_ACCOUNT: '/v1/core/provider/add-bank-account',
		EDIT_BANK_ACCOUNT: '/v1/core/provider/edit-bank-account',
		EDIT_BANK_ACCOUNT_MERCHANT: '/v1/core/provider/edit-bank-account-merchant',
		DELETE_BANK_ACCOUNT: '/v1/core/provider/delete-bank-account',
		GET_CRYPTO_WALLET: '/v1/core/provider/get-crypto-wallet',
		GET_CRYPTO_WALLET_BY_CURRENCY_SETTING:
			'/v1/core/provider/get-crypto-wallets-by-currency-setting',
		ADD_CRYPTO_WALLET: '/v1/core/provider/add-crypto-wallet',
		EDIT_CRYPTO_WALLET: '/v1/core/provider/edit-crypto-wallet',
		DELETE_CRYPTO_WALLET: '/v1/core/provider/delete-crypto-wallet',
		GET_TOTAL_BALANCES_IN_FIAT: '/v1/core/provider/get-total-provider-and-merchant-balance-in-fiat',
		GET_BANK_ACCOUNTS_CURRENCY_NAME: '/v1/core/provider/get-bank-account-by-currency-name',
		// Wallet Addresses
		GET_WALLET_ADDRESSES: '/v1/core/provider/wallet-addresses',
		GET_WALLET_ADDRESSES_CLIENT: '/v1/core/provider/wallet-addresses-by-client',
		// Orders
		GET_ORDERS_LIST: '/v1/core/provider/get-order-providers',
		GET_ORDER_DETAILS: '/v1/core/provider/show-order-provider',
		CREATE_ORDER: '/v1/core/provider/save-order-provider-comment',
		GET_EXCHANGE_PROVIDER_RATE_FEE: '/v1/core/get-currency-rate',
		CREATE_INVOICE_ORDER: '/v1/core/provider/invoice/create-for-exist-order',
		REFOUND_ORDER: '/v1/core/provider/create-refund',
		// trx manager
		GET_TRX_STAKING: '/v1/core/provider/trx-staking',
		DELETE_TRX_STAKING: (staking_id: number) => `/v1/core/provider/trx-staking/${staking_id}`,
		GET_TRX_DELEGATION: '/v1/core/provider/trx-delegation',
		DELETE_TRX_DELEGATION: (delegation_id: number) =>
			`/v1/core/provider/trx-delegation/${delegation_id}`,
		GET_TRX_OVERVIEW: '/v1/core/provider/trx-staking/overview',
		DELETE_TRX_OVERVIEW: (staking_id: string) => `v1/provider/trx-staking/${staking_id}`,
		CANCEL_ALL_UNSTAKINGS: '/v1/core/provider/trx-staking/cancel-all-unstakings',

		// Payment transactions
		GET_PAYMENT_TRANSACTIONS: '/v1/core/provider/get-provider-payment-transactions',
		GET_PAYMENT_TRANSACTION: '/v1/core/provider/show-provider-payment-transaction',
		GET_PROJECT_TRANSACTIONS: '/v1/core/provider/get-project-transaction',
		EXPORT_PROVIDER_TRANSACTIONS_CSV:
			'/v1/core/provider/export-csv-project-transactions-merchant-for-provider',
		EXPORT_FIAT_TRANSACTION_CSV:
			'/v1/core/provider/export-csv-project-fiat-transactions-merchant-for-provider',
		GET_PROVIDER_APPROVEMENTS: '/v1/core/provider/merchants/project/get-projects-approvements',
		VERIFY_PROJECT: '/v1/core/provider/merchants/project/verify',
		UNVERIFY_PROJECT: '/v1/core/provider/merchants/project/unverify',
		DELETE_PROJECT: '/v1/core/provider/merchants/project/decline-from-approvement',
		GET_OWNER_MERCHANT: '/v1/core/provider/get-owner-merchant-id-by-project-id',
		// Liquidity
		GET_LIQUIDITY_PROVIDER: '/v1/core/provider/get-provider-liquidity-by-platform',
		GET_TABS_LIQUIDITY_PROVIDER: '/v1/core/provider/get-provider-liquidity-platforms',
		PUT_LIQUIDITY_EDIT: '/v1/core/provider/edit-provider-liquidity',
		GET_MERCHANTS_LIST: '/v1/auth/provider/merchants/only-team-members',
		// Transactions
		GET_PROJECT_FIAT_TRANSACTIONS: '/v1/core/provider/get-project-fiat-transactions',
		SHOW_WALLET_FIAT_TRANSACTION_HISTORY: '/v1/core/provider/show-wallet-fiat-transaction-history',
		SHOW_WALLET_CRYPTO_TRANSACTION_HISTORY:
			'/v1/core/provider/get-provider-crypto-transaction-details',
		GET_LIQUIDITY_DEPOSIT_ADDRESS: '/v1/core/provider/liquidity-deposit-address',
	},
	merchant: {
		// Projects
		GET_PROJECT_LIST: '/v1/core/provider/merchants/project/list',
		GET_PROJECT_LIST_MERCHANT: '/v1/core/merchant/project/list',
		ACTIVATE_PROJECT: '/v1/core/provider/merchants/project/activate',
		DEACTIVATE_PROJECT: '/v1/core/provider/merchants/project/deactivate',
		ACTIVATE_PROJECT_MERCHANT: '/v1/core/merchant/project/activate',
		DEACTIVATE_PROJECT_MERCHANT: '/v1/core/merchant/project/deactivate',
		DELETE_PROJECT: '/v1/core/provider/merchants/project/delete',
		DELETE_MERCHANT_PROJECT: '/v1/core/merchant/project/delete',
		DELETE_MERCHANT_NOT_PROVIDER: '/v1/auth/merchant/delete-merchant',
		CREATE_PROJECT: '/v1/core/provider/merchants/project/create',
		CREATE_OWN_PROJECT: '/v1/core/merchant/project/create',
		// Management
		GET_APPROVAL_MANAGEMENT_LIST: '/v1/auth/provider/merchants/team-members',
		GET_APPROVAL_NOT_PROVIDER_MANAGEMENT_LIST: '/v1/auth/merchant/team-members',
		GET_ROLES: '/v1/auth/merchant/roles',
		DELETE_MERCHANT: '/v1/auth/provider/merchants/delete-merchant',
		POST_MERCHANT_BLOCK: '/v1/auth/provider/merchants/toggle-block-for-merchant',
		POST_MERCHANT_BLOCK_NOT_PROVIDER: '/v1/auth/merchant/toggle-block-for-merchant',
		POST_ADD_NEW_USER: 'v1/auth/provider/merchants/add-team-member',
		POST_ADD_NEW_USER_MERCHANT: 'v1/auth/merchant/add-team-member',
		POST_ADD_NEW_ROLE_USER: 'v1/auth/provider/merchants/add-new-role-to-project',
		POST_ADD_NEW_ROLE_USER_MERCHANT: 'v1/auth/merchant/attach-merchant-to-project',
		POST_MERCHANT_USER_PROFILE: 'v1/auth/provider/merchants/profile',
		GET_MERCHANT_USER_PROFILE: 'v1/auth/merchant/profile',
		GET_MERCHANT_USER_MY_PROFILE: 'v1/auth/merchant/my-profile',
		POST_MERCHANT_EDIT_PROFILE: 'v1/auth/provider/merchants/edit-member-profile',
		POST_MERCHANT_EDIT_PROVIDER: 'v1/auth/merchant/edit-profile',
		RESET_MERCHANT_PROFILE_PASSWORD: 'v1/auth/merchant/reset-password',
		RESET_PROVIDER_FOR_MERCHANT_PROFILE_PASSWORD: 'v1/auth/merchant/reset-password',
		EDIT_MERCHANT_ROLE: 'v1/auth/provider/merchants/edit-role',
		EDIT_MERCHANT_ROLE_NOT_PROVIDER: 'v1/auth/merchant/attach-merchant-to-project',
		EDIT_MERCHANT_NOT_PROVIDER_ROLE: 'v1/auth/merchant/edit-role',
		DELETE_MERCHANT_ROLE: 'v1/auth/provider/merchants/unattach-merchant-to-project',
		DELETE_MERCHANT_ROLE_NOT_PROVIDER: 'v1/auth/merchant/unattach-merchant-to-project',
		// Bank Account
		GET_BANK_ACCOUNTS: '/v1/core/merchant/get-bank-accounts',
		ADD_BANK_ACCOUNT: '/v1/core/merchant/add-bank-account',
		EDIT_BANK_ACCOUNT: '/v1/core/merchant/edit-bank-account',
		DELETE_BANK_ACCOUNT: '/v1/core/merchant/delete-bank-account',
		GET_AUTO_WITHDRAWAL_ADDRESS: '/v1/core/merchant/get-auto-withdrawal-address',
		ADD_AUTO_WITHDRAWAL_ADDRESS: '/v1/core/merchant/add-auto-withdrawal-address',
		EDIT_AUTO_WITHDRAWAL_ADDRESS: '/v1/core/merchant/edit-auto-withdrawal-address',
		DELETE_AUTO_WITHDRAWAL_ADDRESS: '/v1/core/merchant/delete-auto-withdrawal-address',
		GET_INVOICES: '/v1/core/provider/merchants/invoice/list',
		GET_INVOICE: '/v1/core/get-merchant-invoice',
		GET_INVOICE_LINK: '/v1/core/provider/merchants/invoice/get-link',
		CREATE_INVOICE: '/v1/core/merchant/invoice/create',
		GET_INVOICE_PDF: '/v1/core/provider/merchants/invoice/get-pdf',
		SEND_INVOICE_PDF: '/v1/core/provider/merchants/invoice/send-pdf',
		SEND_INVOICE_LINK: '/v1/core/provider/merchants/invoice/send',
		EXPORT_CSV_INVOICE_MERCHANT: '/v1/core/merchant/export-cvs-invoice',
		DOWNLOAD_INVOICES_REPORT: '/v1/core/merchant/download-invoices-report',
		GET_MERCHANT_INVOICE_STATUS: '/v1/core/get-merchant-invoice-status',
		ADD_DEPOSIT_FIAT_REFERENCE: '/v1/core/merchant/add-deposit-fiat-reference',
		CREATE_WITHDRAWAL_FIAT: '/v1/core/merchant/create-project-withdrawal-fiat',
		GET_BANK_ACCOUNT_BY_CURRENCY: '/v1/core/merchant/get-bank-account-by-currency',
		// Orders
		GET_ORDERS_LIST: '/v1/core/merchant/get-order-merchants',
		GET_ORDER_DETAILS: '/v1/core/merchant/show-order-merchant',
		CREATE_ORDER: '/v1/core/merchant/save-order-merchant-comment',
		CREATE_INVOICE_ORDER: '/v1/core/merchant/invoice/create-for-exist-order',
		REFOUND_ORDER: '/v1/core/merchant/create-refund',
		// Settngs merchant
		GET_SETTINGS: '/v1/core/merchant/get-settings',
		POST_SETTINGS: '/v1/core/merchant/change-settings',
		GET_SETTINGS_LANGUAGE: '/v1/core/merchant/get-chosen-language',
		// Settngs merchant provider
		GET_SETTINGS_PROVIDER: '/v1/core/provider/merchants/get-settings',
		POST_SETTINGS_PROVIDER: '/v1/core/provider/merchants/change-settings',
		// Security
		PUT_PASSWORD: '/v1/auth/merchant/change-password',
		GET_WHITE_LIST: '/v1/auth/merchant/get-white-list-ip',
		DELETE_WHITE_LIST: '/v1/auth/merchant/all-white-list-ip',
		// Security components
		PUT_PASSWORD_PROVIDER: '/v1/auth/provider/merchants/change-password',
		GET_WHITE_LIST_PROVIDER: '/v1/auth/provider/merchants/get-white-list-ip',
		GET_MERCHANTS_LIST: '/v1/auth/merchant/only-team-members',
		DELETE_WHITE_LIST_PROVIDER: '/v1/auth/provider/merchants/all-white-list-ip',
		// Transactions
		GET_MERCHANT_PROJECT_FIAT_TRANSACTIONS: '/v1/core/merchant/get-project-transaction',
		GET_VIEW_RELATED_MERCHANT_FIAT: (id: string | number) =>
			`/v1/core/merchant/view-related-fiat-transaction?project_fiat_transaction_id=${id}`,
		CREATE_REPORT_MERCHANT_FIAT_TRANSACTIONS:
			'/v1/core/merchant/create-report-project-fiat-transactions-merchant',
		DOWNLOAD_ORDERS_REPORT: '/v1/core/merchant/download-orders-report',
	},
	project: {
		// Project settings
		GET_PROJECT_DETAILS: '/v1/core/provider/merchants/project/details',
		GET_PROJECT_DETAILS_MERCHANT: '/v1/core/merchant/project/details',
		CHANGE_PROJECT_DETAILS: '/v1/core/provider/merchants/project/change-details',
		CHANGE_PROJECT_DETAILS_MERCHANT: '/v1/core/merchant/project/change-details',
		GET_API: '/v1/core/provider/merchants/project/apis',
		GET_API_MERCHANT: '/v1/core/merchant/project/apis',
		CREATE_API_KEY: '/v1/core/merchant/project/api-key',
		UPDATE_API_KEY: '/v1/core/merchant/project/api-key',
		DELETE_API_KEY: (id: number | string) => `/v1/core/merchant/project/api-key/${id}`,
		SAVE_CALLBACK_URL: '/v1/core/merchant/project/callback-url',
		CLEAR_CALLBACK_URL: '/v1/core/merchant/project/callback-url-clear',
		GENERATE_CALLBACK_SECRET: '/v1/core/merchant/project/callback-secret',
		GENERATE_WIDGET_KEY: '/v1/core/provider/merchants/project/generate-widget-key',
		GENERATE_WIDGET_KEY_MERCHANT: '/v1/core/merchant/project/generate-widget-key',
		// Transaction
		GET_PROJECT_TRANSACTIONS: '/v1/core/merchant/get-project-transaction',
		GET_PROJECT_MERCHANT_TRANSACTION: '/v1/core/merchant/get-transaction',
		EXPORT_CSV_PROJECT_TRANSACTIONS: '/v1/core/merchant/export-cvs-project-transaction',
		EXPORT_CSV_PROJECT_TRANSACTIONS_FIAT: '/v1/core/merchant/export-cvs-project-fiat-transaction',
		CREATE_FIAT_TRANSACTIONS: '/v1/core/provider/creat-deposit-fiat-transaction',
		GET_FIAT_REFERENCE_TRANSACTIONS: '/v1/core/provider/get-bank-account-merchant-by-currency-name',
		GET_VIEW_RELATED_MERCHANT_FIAT: `/v1/core/merchant/view-related-fiat-transaction`,
		GET_MERCHANT_FIAT_TRANSACTION: '/v1/core/merchant/get-project-fiat-transactions',
		// Reports
		CREATE_FIAT_TRANSACTIONS_REPORT: '/v1/core/merchant/create-report-fiat-transactions',
		DOWNLOAD_FIAT_TRANSACTIONS_REPORT: '/v1/core/merchant/download-report-fiat-transactions',
		CREATE_CRYPTO_TRANSACTIONS_REPORT: '/v1/core/merchant/create-report-crypto-transactions',
		DOWNLOAD_CRYPTO_TRANSACTIONS_REPORT: '/v1/core/merchant/download-report-crypto-transactions',
		// Index
		GET_PROJECT_MERCHANT_TRANSACTION_APPROVEMENTS: '/v1/core/merchant/get-transaction-approvement',
		VIEW_TRANSACTION_APROVEMENT: '/v1/core/merchant/view-transaction-approvement',
		// Client
		GET_CLIENT: '/v1/core/merchant/get-clients',
		SHOW_CLIENT: '/v1/core/merchant/show-client',
		SHOW_CLIENT_TRANSACTIONS: '/v1/core/merchant/show-client-transactions',
		EDIT_CLIENT: '/v1/core/merchant/edit-client',
		ACTIVATE_CLIENT: '/v1/core/merchant/activate-client',
		DELETE_CLIENT: '/v1/core/merchant/delete-client',
		GET_CLIENT_DOCUMENTS: '/v1/core/merchant/get-client-documents',
		ADD_CLIENT_DOCUMENT: '/v1/core/merchant/add-document',
		DELETE_CLIENT_DOCUMENT: '/v1/core/merchant/delete-document',
		// Callbacks
		GET_CALLBACKS: '/v1/core/merchant/get-callbacks',
		VIEW_CALLBACK: '/v1/core/merchant/view-callback',
		GET_CALLBACKS_CSV: '/v1/core/merchant/export-cvs-callback',
		RESEND_CALLBACK: '/v1/core/merchant/project/resend-callback',
		// Finance settings
		EDIT_PROVIDER_PROJECT_BALANCE: '/v1/core/provider/edit-project-balance',
		EDIT_MERCHANT_PROJECT_BALANCE: '/v1/core/merchant/edit-project-balance',
		// ProjectWallets
		GET_PROJECT_BALANCE: '/v1/core/provider/get-project-balance',
		GET_PROJECT_MERCHANT_BALANCE: '/v1/core/merchant/get-project-balance',
		GET_BALANCE_IN_DEFAULT_CURRENCY: '/v1/core/merchant/get-balance-in-default-currency',
		EDIT_PROJECT_BALANCE: '/v1/core/provider/edit-project-balance',
		ALL_CURRENCY: '/v1/core/provider/all-currency-setting',
		CREATE_PROJECT_TRANSFER: '/v1/core/merchant/create-project-transfer',
		CREATE_PROJECT_EXCHANGE: '/v1/core/merchant/create-project-exchange-transaction',
		GET_EXCHANGE_RATE_FEE: '/v1/core/merchant/get-exchange-rate-and-fee',
		AUTO_PROJECT_EXCHANGE: '/v1/core/merchant/set-auto-exchange',
		CREATE_WITHDRAWAL_EXCHANGE: '/v1/core/merchant/create-withdrawal',
		CREATE_DEPOSIT_EXCHANGE: '/v1/core/merchant/get-project-deposit-address',
		WITHDRAWAL_LIMITS: '/v1/core/merchant/used-withdrawal-amount',
		WITHDRAWAL_FEE: '/v1/core/merchant/withdrawal-fee',
		CHECK_WITHDRAWAL: '/v1/core/merchant/check-address',
		// Reports
		GET_REPORTS: '/v1/core/merchant/get-report-merchant',
		CREATE_TRANSACTION_REPORTS: '/v1/core/merchant/create-report-transaction-merchant',
		CREATE_APPROVEMENT_REPORT: '/v1/core/merchant/create-report-transaction-approvement-merchant',
		EXPORT_CSV_TRANSACTION_APPROVEMENT: '/v1/core/merchant/export-cvs-transaction-approvement',
		CREATE_FLOW_REPORTS: '/v1/core/merchant/create-report-currency-flow-merchant',
		CREATE_BALANCES_REPORTS: '/v1/core/merchant/create-report-balances-merchant',
		// Wallet Addresses
		GET_WALLET_ADDRESSES: '/v1/core/merchant/wallet-addresses',
		GET_WALLET_ADDRESSES_CLIENT: '/v1/core/merchant/wallet-addresses-by-client',
		GET_COMISSIONS: '/v1/core/provider/merchants/project/fee-settings',
		GET_COMISSIONS_MERCHANT: '/v1/core/merchant/project/get-fees-by-project',
		PUT_COMISSIONS_MERCHANT: '/v1/core/provider/merchants/project/edit-fee-settings',
		// Withdrawal
		GET_PROVIDER_WITHDRAWAL: '/v1/core/provider/project-withdrawal-crypto-transactions',
		GET_PROVIDER_WITHDRAWAL_FIAT: '/v1/core/provider/get-project-withdrawal-fiat-transactions',
		PUT_REJECT_WITHDRAWAL: (id: string | undefined) =>
			`/v1/core/provider/reject-withdrawal-crypto-transaction?project_withdrawal_crypto_transaction_id=${
				id || ''
			}`,
		PUT_REJECT_WITHDRAWAL_FIAT: `/v1/core/provider/reject-project-withdrawal-fiat`,
		PUT_RESEND_WITHDRAWAL: `/v1/core/provider/resend-withdrawal-crypto-transaction`,
		PUT_APPROVE_WITHDRAWAL: (id: string, amount: string) =>
			`/v1/core/provider/approve-withdrawal-crypto-transaction?project_withdrawal_crypto_transaction_id=${String(
				id,
			)}&amount=${String(amount)}`,
		PUT_APPROVE_WITHDRAWAL_FIAT: `/v1/core/provider/approve-project-withdrawal-fiat`,
		GET_WITHDRAWAL_DATA_FIAT: '/v1/core/merchant/get-project-withdrawal-fiat-data',
		// Analytics
		GET_MERCHANT_ANALYTICS: 'v1/core/merchant/get-merchant-analytics',
		POST_SMS_ANALYTICS: 'v1/notification/send-analytic-message',
		GET_PROVIDER_ANALYTICS: 'v1/core/provider/get-provider-analytics',
		POST_DATA_TO_DEPOSIT_FIAT: 'v1/core/merchant/get-data-to-deposit-fiat',
	},
	paymentWidget: {
		GET_AVAILABLE_CURRENCIES: '/v1/core/widget/get-available-currencies',
		CREATE_PAYMENT: '/v1/core/widget/create-payment',
		GET_MERCHANT_INVOICE_STATUS: '/v1/core/get-merchant-invoice-status',
		GET_UPDATE_NOTIFY: '/v1/core/widget/update-notify-params',
		CREATE_UPDATE_PAYMENT: '/v1/core/widget/update-payment',
	},
};
