import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getListAnlyticsSelector } from 'redux/reducers/analyticsMerchant/selectors';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import {
	getMerchantAnalyticsRequest,
	smsMerchantAnalyticsRequest,
} from 'redux/reducers/analyticsMerchant/reducer';
import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import { BackToMerchantProjects } from 'UI/GoBackLinks/Provider/BackToMerchantProjects/BackToMerchantProjects';
import { AddDifferent } from 'v2Pages/Project/ProjectAnalytics/HeaderAnalytics/AddDifferent/AddDifferent';
import { HeaderAnalytics } from 'v2Pages/Project/ProjectAnalytics/HeaderAnalytics/HeaderAnalytics';
import { ChartjsLine } from 'UI/Chartjs/ChartjsLine';
import { ChartjsDoughnut } from 'UI/Chartjs/ChartjsDoughnut';
import styles from './styles.module.scss';

const dataSelectCharts = {
	project_wallet_number: 1,
	project_total_balances: 1,
	project_incoming_payments: 1,
	project_withdraw_payments: 1,
	project_invoice_payments: 1,
	project_deposit_by_coins: 1,
	project_withdrawal_by_coins: 1,
	project_invoice_by_coins: 1,
};

export default () => {
	const [time, setTime] = useState<string>('7 Days');
	const [chart, setChart] = useState<{ [key: string]: number }>(dataSelectCharts);
	const { projectId, merchantId } = useParams<{ projectId: string; merchantId: string }>();
	const listAnalytics = useSelector(getListAnlyticsSelector);
	const [isPopup, setIsPopup] = useState<boolean>(false);
	const [text, setText] = useState<string>('');
	const dispatch = useDispatch();
	const project = useSelector(getProjectDetails);

	const chartsCurrencies =
		!!listAnalytics?.project_deposit_by_coins?.length ||
		!!listAnalytics?.project_invoice_by_coins?.length ||
		!!listAnalytics?.project_withdrawal_by_coins?.length;

	const walletAndBelanse =
		!!listAnalytics?.project_wallet_number?.length ||
		!!listAnalytics?.project_total_balances?.length;

	const incomingWithdraw =
		!!listAnalytics?.project_incoming_payments?.length ||
		!!listAnalytics?.project_withdraw_payments?.length;

	const handleSelects = (value: string, key?: string) => {
		const cloneVelue = { ...chart };

		if (key && !Number(value)) {
			delete cloneVelue[key];
		}

		if (key && +value) {
			setChart({ ...cloneVelue, [key]: +value });
			return;
		}

		key ? setChart(cloneVelue) : setTime(value);
	};

	const onPopup = () => setIsPopup(!isPopup);

	const onSubmit = () => {
		dispatch(
			smsMerchantAnalyticsRequest({
				paramsApi: {
					text,
					project_id: +projectId,
					merchant_id: +merchantId,
					owner_merchant_id: project?.owner_merchant_id,
				},
				close: onPopup,
				clearText: setText,
			}),
		);
	};

	const handleText = (event: ChangeEvent) => {
		const { value } = event.target as HTMLTextAreaElement;
		setText(value);
	};

	useEffect(() => {
		dispatch(
			getMerchantAnalyticsRequest({
				project_id: +projectId,
				type_filter_time: time.toLowerCase().replaceAll(' ', '_'),
				...chart,
			}),
		);
	}, [dispatch, projectId, chart, time]);

	return (
		<div className={styles.main}>
			<ProjectContent>
				{isPopup && (
					<AddDifferent onSubmit={onSubmit} text={text} onPopup={onPopup} handleText={handleText} />
				)}
				<HeaderAnalytics
					chart={chart}
					time={time}
					handleSelects={handleSelects}
					provider="merchant"
					onPopup={onPopup}
				/>
				<div className={styles.main__wrapper}>
					<div className="analytics-wrap">
						{walletAndBelanse ? (
							<div className={styles.analytics__list}>
								{listAnalytics?.project_wallet_number?.length ? (
									<div className="analytics-item">
										<div className="analytics-item__title">
											<p>Wallets number</p>
										</div>
										<div className="analytics-item__schedule">
											<ChartjsLine height={334} params={listAnalytics?.project_wallet_number} />
										</div>
									</div>
								) : null}
								{listAnalytics?.project_total_balances?.length ? (
									<div className="analytics-item">
										<div className="analytics-item__title">
											<p>Ballance on Merchant, USD</p>
										</div>
										<div className="analytics-item__schedule">
											<ChartjsLine height={334} params={listAnalytics?.project_total_balances} />
										</div>
									</div>
								) : null}
							</div>
						) : null}
						{incomingWithdraw ? (
							<div className={styles.analytics__list}>
								{listAnalytics?.project_incoming_payments?.length ? (
									<div className="analytics-item">
										<div className="analytics-item__title">
											<p>Incoming payments, USD</p>
										</div>
										<div className="analytics-item__schedule">
											<ChartjsLine height={334} params={listAnalytics?.project_incoming_payments} />
										</div>
									</div>
								) : null}
								{listAnalytics?.project_withdraw_payments?.length ? (
									<div className="analytics-item">
										<div className="analytics-item__title">
											<p>Withdrawals payments, USD</p>
										</div>
										<div className="analytics-item__schedule">
											<ChartjsLine height={334} params={listAnalytics?.project_withdraw_payments} />
										</div>
									</div>
								) : null}
							</div>
						) : null}
						{listAnalytics?.project_invoice_payments?.length ? (
							<div className={styles.analytics__list}>
								<div className="analytics-item">
									<div className="analytics-item__title">
										<p>Invoices, USD</p>
									</div>
									<div className="analytics-item__schedule">
										<ChartjsLine height={334} params={listAnalytics?.project_invoice_payments} />
									</div>
								</div>
							</div>
						) : null}
					</div>
					{chartsCurrencies ? (
						<div className="analytics-data-wrap-title">
							<p>Charts by currencies</p>
						</div>
					) : null}
					<div className="analytics-data-wrap analytics-data-wrap--grid-type">
						{listAnalytics?.project_deposit_by_coins?.length ? (
							<div className="analytics-data analytics-data--type3">
								<div className="analytics-data__title">
									<p>Payments by coin</p>
								</div>
								<div className="analytics-data__schedule">
									<ChartjsDoughnut isMerchant params={listAnalytics?.project_deposit_by_coins} />
								</div>
							</div>
						) : null}
						{listAnalytics?.project_withdrawal_by_coins?.length ? (
							<div className="analytics-data analytics-data--type3">
								<div className="analytics-data__title">
									<p>Withdrawals by coin</p>
								</div>
								<div className="analytics-data__schedule">
									<ChartjsDoughnut isMerchant params={listAnalytics?.project_withdrawal_by_coins} />
								</div>
							</div>
						) : null}
						{listAnalytics?.project_invoice_by_coins?.length ? (
							<div className="analytics-data analytics-data--type3">
								<div className="analytics-data__title">
									<p>Invoice by coin</p>
								</div>
								<div className="analytics-data__schedule">
									<ChartjsDoughnut isMerchant params={listAnalytics?.project_invoice_by_coins} />
								</div>
							</div>
						) : null}
					</div>
				</div>
			</ProjectContent>
		</div>
	);
};
