import { FC } from 'react';
import { IProviderTransactionParams } from 'redux/reducers/analyticsProvider/types';
import { BodyTable } from './BodyTable/BodyTable';
import { Header } from './Header/Header';

interface IProps {
	providerTransactionByParams: Array<IProviderTransactionParams> | undefined;
}

export const CryptocurrencyTransactions: FC<IProps> = ({ providerTransactionByParams }) => {
	return (
		<div className="table table--report-table-cryptocurrency-transactions">
			<Header />
			<div className="table-body">
				{providerTransactionByParams?.map((params) => (
					<BodyTable key={params.id} params={params} />
				))}
			</div>
		</div>
	);
};
