import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import StatusText from 'UI/StatusText/StatusText';
import { StatusHistory } from 'services/api/project/types';
import { capitalize } from 'utils/strings';
import { createDateFormatUTC } from 'utils/dates';
import { useParams } from 'react-router';
import { IViewTransactionAprovementPayload } from 'redux/reducers/projectTransactions/types';
import { getViewApprovement } from 'redux/reducers/projectTransactions/selectors';
import { viewTransactionApprovemientRequest } from 'redux/reducers/projectTransactions/reducer';
import { Comment } from '../Comment/Comment';

const ApprovemmentDetails: FC = () => {
	const dispatch = useDispatch();
	const item = useSelector(getViewApprovement);
	const { transactionId } = useParams<{ transactionId: string }>();
	useEffect(() => {
		const payload: IViewTransactionAprovementPayload = {
			apiParams: { transaction_approvement_id: +transactionId },
		};
		dispatch(viewTransactionApprovemientRequest(payload));
	}, [dispatch, transactionId]);

	const { currencyTabs, transactionTypes, merchantId, projectId } = useParams<{
		merchantId: string;
		projectId: string;
		currencyTabs: string;
		transactionTypes: string;
	}>();

	const { transaction, status_history: statusHistory, comments = [] } = item || {};

	const {
		id,
		status,
		created_at,
		updated_at,
		risk_score,
		address_from,
		address_to,
		balance_before,
		balance_after,
	} = transaction || {};

	return (
		<ProjectContent>
			<Link
				to={`/merchant/${merchantId}/project/${projectId}/transactions/approvements/?tabs=${
					currencyTabs || 'crypto'
				}`}
				className="back-page"
			>
				<ArrowLeftIcon />
				<span>Back to Approvements</span>
			</Link>
			<div className="content-header">
				<div className="content-header__group">
					<p className="form-title form-title--mb-0">{id}</p>
					<div className="status-block">
						<p className="block-text block-text--fs-14 status-block__title">Status:</p>
						{status && StatusText(status)}
					</div>
				</div>
			</div>
			<div className="approvements-list">
				<div className="approvements-column">
					<div className="approvements-item">
						<p className="approvements-item__title">Project ID:</p>
						<div className="approvements-item__value">
							<span>{projectId}</span>
						</div>
					</div>

					<div className="approvements-item">
						<p className="approvements-item__title">Invoice ID:</p>
						<div className="approvements-item__value">
							<span>{/* @TODO: Такого поля нет */}</span>
						</div>
					</div>

					<div className="approvements-item">
						<p className="approvements-item__title">Created:</p>
						<div className="approvements-item__value">
							<span>{createDateFormatUTC(created_at)}</span>
						</div>
					</div>

					<div className="approvements-item">
						<p className="approvements-item__title">Current status:</p>
						<div className="approvements-item__value">
							<span>{status && StatusText(status)}</span>
						</div>
					</div>

					<div className="approvements-item">
						<p className="approvements-item__title">Status change:</p>
						<div className="approvements-item__value">
							<span>{createDateFormatUTC(updated_at)}</span>
						</div>
					</div>

					<div className="approvements-item">
						<p className="approvements-item__title">Status changed:</p>
						<div className="approvements-item__value">
							<span>{/* @TODO: Неизвестно поле */}</span>
						</div>
					</div>
				</div>
				<div className="approvements-column">
					<div className="approvements-item">
						<p className="approvements-item__title">TX Type:</p>
						<div className="approvements-item__value">
							<p>{capitalize(transactionTypes).replaceAll('_', ' ')}</p>
						</div>
					</div>

					<div className="approvements-item">
						<p className="approvements-item__title">Current TX Status:</p>
						<div className="approvements-item__value">
							<p>{/* @TODO: Нет такого поля */}</p>
						</div>
					</div>
					<div className="approvements-item">
						<p className="approvements-item__title">Original wallet:</p>
						<div className="approvements-item__value">
							<p>{address_from}</p>
						</div>
					</div>
					<div className="approvements-item">
						<p className="approvements-item__title">Distination wallet:</p>
						<div className="approvements-item__value">
							<p>{address_to}</p>
						</div>
					</div>
					<div className="approvements-item">
						<p className="approvements-item__title">Risk level:</p>
						<div className="approvements-item__value">
							<p>{risk_score}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="approvements-user-row">
				{balance_before && (
					<div className="approvements-user">
						<div className="approvements-user__info">
							<div className="approvements-user__text approvements-user__text--type2">
								<p>Destination account/wallet balance “before” transaction:</p>
							</div>
							<div className="approvements-user__value">
								<p>{balance_before} USD</p>
							</div>
						</div>
					</div>
				)}
				{balance_after && (
					<div className="approvements-user">
						<div className="approvements-user__info">
							<div className="approvements-user__text approvements-user__text--type2">
								<p>Destination account/wallet balance “after” the transaction</p>
							</div>
							<div className="approvements-user__value">
								<p>{balance_after} USD</p>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className="block-title block-title--line2">
				<p>Status history</p>
			</div>
			<div className="approvements-list">
				<div className="approvements-column">
					{statusHistory?.map((e: StatusHistory) => {
						return (
							<div className="approvements-item approvements-item--status">
								<span>{createDateFormatUTC(e.created_at)}</span>
								<span>Status created by {capitalize(e.creator)}</span>
								<span className="approvements-item__status">{StatusText(e.status)}</span>
							</div>
						);
					})}
				</div>
			</div>

			<div className="block-title block-title--line2">
				<p>Transaction description/comment</p>
			</div>
			{comments?.map((_item) => (
				<Comment item={_item} />
			))}
		</ProjectContent>
	);
};

export default ApprovemmentDetails;
