import { IStoreState } from 'redux/types';

export const getLiquidityProvider = (state: IStoreState) => state.providerLiquidity;
export const getProviderTabsLiqudityWorker = (state: IStoreState) =>
	getLiquidityProvider(state).liqudityTabs;

export const getProviderTabsLiqudityWorkerLoading = (state: IStoreState) =>
	getLiquidityProvider(state).liqudityTabsLoading;

export const getLiquidityList = (state: IStoreState) => getLiquidityProvider(state).liqudityList;
export const getLiquidityListLoading = (state: IStoreState) =>
	getLiquidityProvider(state).liqudityListLoading;
export const getDepositAddressLoading = (state: IStoreState) =>
	getLiquidityProvider(state).depositAddressLoading;

export const getDepositAddress = (state: IStoreState) => getLiquidityProvider(state).depositAddress;

// export const getOrderSelector = (state: IStoreState) => getLiquidityProvider(state).order;
// export const getOrderInvoiceIdSelector = (state: IStoreState) =>
// getLiquidityProvider(state).orderInvoice?.id;
