/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getUserType } from 'redux/reducers/auth/selectors';
import { getCurrenciesSelector } from 'redux/reducers/merchantAccounts/selectors';
import { IProjectBalanceRequest } from 'redux/reducers/merchantAccounts/types';
import { getProjectList } from 'redux/reducers/merchantProjects/selectors';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import { EButtonName } from 'services/constants/account-btn';
import { constUserTypes } from 'services/constants/user-types';
import { notify } from 'utils/notify';
import { trimLastZeros } from 'utils/strings';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { AccountAction } from '../AccountAction/AccountAction';
import { PopUpTransfer } from '../PopUps/PopUpTransfer/PopUpTransfer';
import { PopUpWithdrawal } from '../PopUps/PopUpWithdrawal/PopUpWithdrawal';
import { AccountDrop } from './AccountDrop/AccountDrop';
import { IPropsAccountTableBody } from './types';

export const TableRowFiat = ({ accountList, account, handleSubmit }: IPropsAccountTableBody) => {
	const [active, setActive] = useState<number | null | string>(null);
	const [idCoin, setIdCoin] = useState<number | null>(null);
	const [accountId, setAccountId] = useState<number | null>(null);
	const [popUpOpen, setPopUpOpen] = useState('');
	const [balanceLimit, setBalanceLimit] = useState<string>('0');
	const [balance, setBalance] = useState('');
	const [reference, setReference] = useState('');
	const [current, setCurrent] = useState<string | null>(null);
	const [network, setNetwork] = useState<string>('');
	const dispatch = useDispatch();
	const currencies = useSelector(getCurrenciesSelector);
	const [didMount, setDidMount] = useState(true);
	const { merchantId, projectId } = useParams<{ merchantId: string; projectId: string }>();
	const projectList = useSelector(getProjectList);
	const userType = useSelector(getUserType);
	const projectChose = useSelector(getProjectDetails);

	const { getViewDecimal } = useViewDecimals();

	const {
		currency: { code: currency_code },
		currency_network,
	} = account;
	const viewDecimals = getViewDecimal(currency_code, currency_network);

	const onEdit = (value: number | null | string, id?: number) => {
		setActive(value);
		setIdCoin(id || null);
		setBalance(
			String(accountList.find(({ id: accountItemId }) => accountItemId === value)?.balance) || '',
		);
		setReference(
			String(
				accountList?.find(({ id: accountItemId }) => accountItemId === value)
					?.deposit_fiat_reference || '',
			),
		);
		setCurrent('');
	};

	const onPopUps = (value: string, id?: number) => {
		if (projectList?.find((project) => project.id === +projectId)?.is_active) {
			setAccountId(id || null);
			setPopUpOpen(value);
			return;
		}
		if (
			!projectList?.find((project) => project.id === +projectId)?.is_active &&
			EButtonName.TRANSFER !== value &&
			EButtonName.EXCHANGE !== value
		) {
			setAccountId(id || null);
			setPopUpOpen(value);
			return;
		}
		notify('The project is not activated', 'info');
	};

	const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
		const { value, name } = event.target;
		name === 'limit' && setBalanceLimit(value);
		name === 'balance' && setBalance(value);
		name === 'deposit_fiat_reference' && setReference(value);
		setCurrent(null);
	};

	const putCurrentcy = (name: string, name_network: string) => {
		setCurrent(name);
		setNetwork(name_network);
		setBalanceLimit('');
	};
	const isProvider = userType === constUserTypes.PROVIDER;
	const onSubmit = (event?: React.SyntheticEvent) => {
		event?.preventDefault();
		const data: IProjectBalanceRequest = {};
		data.auto_withdrawal_limit = null;
		if (balanceLimit) {
			data.auto_withdrawal_limit = +balanceLimit;
			data.auto_exchange_to_currency_name = null;
		}
		data.auto_withdrawal_limit = current ? data.auto_withdrawal_limit : balanceLimit;
		data.deposit_fiat_reference = reference || data.deposit_fiat_reference;

		handleSubmit(
			data,
			active as number,
			currencies?.find(
				(currency) => currency.currency_network === network && currency.currency_code === current,
			)?.id || null,
		);
		onEdit(null);
	};

	useEffect(() => {
		if (didMount) {
			setDidMount(false);
		} else {
			setBalanceLimit('');
			setReference('');
			setBalance('');
			setCurrent('');
		}
	}, [dispatch]);

	return [
		{
			title: 'Currency',
			children: (
				<span className="token token--type2">
					<span className="token__icon">
						<img src={account.currency.img_path} alt="" />
					</span>
					<span className="token__name-wrap">
						<span className="token__name">{account.currency.name}</span>
						<br />
						<span className="token__transcription">
							{account.currency.code.toLowerCase() === account.network_name.toLowerCase()
								? account.network_name.toUpperCase()
								: `${account.currency.code.toUpperCase()}, ${account.network_name.toUpperCase()}`}
						</span>
					</span>
				</span>
			),
		},
		{
			title: 'Reference code',
			children: isProvider ? (
				<>
					<p className="td-hidden-name">Reference code</p>
					{active !== account.id ? (
						<p className="td-value">{account?.deposit_fiat_reference}</p>
					) : (
						<div className="input input--mb-0">
							<div className="input-wrapper">
								<input
									// required
									onChange={handleInput}
									className="input-item input-item--type-td input-item--right-icon"
									type="text"
									placeholder="Reference code"
									name="deposit_fiat_reference"
									value={reference}
								/>
							</div>
						</div>
					)}
				</>
			) : (
				<>
					<p className="td-hidden-name">Reference code</p>
					<p className="td-value">{account?.deposit_fiat_reference}</p>
				</>
			),
		},
		{
			title: 'Balance',
			children: trimLastZeros(Number(account.balance).toFixed(viewDecimals)),
		},
		{
			title: 'Auto Exchange',
			children:
				active === account.id && account.auto_exchange?.auto_exchange_code ? (
					<AccountDrop
						setCurrent={putCurrentcy}
						network={network}
						current={current as string}
						nameCurrent={account.auto_exchange?.auto_exchange_code}
						currencies={currencies?.filter(
							(currency) =>
								!!currency.is_exchangeable && currency.id !== idCoin && currency.is_crypto,
						)}
						currencyRow={account.currency_name}
					/>
				) : (
					<p className="td-value td-value--custom">
						{account?.auto_exchange?.auto_exchange_code ===
						account?.auto_exchange?.auto_exchange_network_name
							? `${String(account?.auto_exchange?.auto_exchange_code || '')}`
							: `${String(account?.auto_exchange?.auto_exchange_code || '')}, ${String(
									account?.auto_exchange?.auto_exchange_network_name || '',
							  )}`}
					</p>
				),
		},
		{
			title: 'Action',
			children: (
				<>
					<AccountAction
						onEdit={onEdit}
						active={active}
						account={account}
						onPopUps={onPopUps}
						onSubmit={onSubmit}
					/>
					{popUpOpen === EButtonName.TRANSFER && (
						<PopUpTransfer
							popUpOpen={popUpOpen}
							onPopUps={onPopUps}
							projectList={projectList}
							accountId={accountId}
							currencies={accountList}
							projectChose={projectChose}
						/>
					)}
					{popUpOpen === EButtonName.WITDRAWAL && (
						<PopUpWithdrawal
							popUpOpen={popUpOpen}
							onPopUps={onPopUps}
							projectList={projectList}
							accountId={accountId}
							currencies={accountList}
							projectChose={projectChose}
						/>
					)}
				</>
			),
		},
	];
};

export default TableRowFiat;
