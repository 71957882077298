import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { capitalize, trimBottomLine, trimLastZeros } from 'utils/strings';
import { convertExponentialToDecimal, isExist } from 'utils/numbers';
import { createDateFormatUTC, toUTC } from 'utils/dates';
import { useViewDecimals } from 'hooks/useViewDecimals';
import StatusText from 'UI/StatusText/StatusText';
import Tooltip from 'v2Components/UI/Tooltip';
import {
	getApprovementList,
	getClientLink,
	getLinkedTransactionLink,
	getLinkRefund,
	TTags,
} from './approvement-list.helper';

interface IProps {
	item: any;
	type: string;
	isProject?: boolean;
	tag: string;
	auto?: boolean;
}

interface IParams {
	merchantId: string;
	projectId: string;
	currencyTabs: string;
}

const GetApprovementList = ({ tag, item, type, isProject, auto }: IProps) => {
	const { getViewDecimal } = useViewDecimals();
	const {
		status,
		amount,
		owner_merchant_id,
		created_at,
		updated_at,
		currency_network,
		reference_from,
		fee_network,
		fee_deposit,
		link_transaction_id,
		risk_score,
		hash_transaction,
		iban_origin,
		currency,
		tx_url,
		project_name,
		address_to,
		reference_to,
		address_from,
		iban_destination,
		bank_name,
		bic,
		invoice_merchant_id,
		invoice_provider_id,
		order_merchant_id,
		order_provider_id,
		client_id,
		project_id,
		type_exchange,
		rate,
		amount_from,
		currency_from,
		currency_to,
		amount_to,
		fee_exchange,
		link_refund_id,
		from_project_id,
		to_project_id,
		from_wallet,
		to_wallet,
		currency_name,
		currency_code,
		id,
		provider_id,
		provider_fee_description,
		fee_withdrawal,
		fee_payment,
	} = item?.transaction || {};
	const { merchantId, projectId, currencyTabs } = useParams<IParams>();
	const isCrypto = currencyTabs === 'crypto';
	const currencyCode = currency?.code || currency_code;
	const currencyName = currency?.name || currency_name;
	const viewDecimals = getViewDecimal(currencyCode, currency_network);
	const viewDecimalsFrom = currency_from ? getViewDecimal(currency_from) : undefined;
	const viewDecimalsTo = currency_to ? getViewDecimal(currency_to) : undefined;
	const userLink = `/administration/team-members/${String(owner_merchant_id)}`;
	const projectLink = `/merchant/${owner_merchant_id}/project/${project_id}`;
	const { link_transaction_id_type, owner_merchant_nick_name, statuses } = item;
	const linkedTransactionLink = getLinkedTransactionLink({
		link_transaction_id,
		link_transaction_id_type,
		merchantId,
		projectId,
		isProject,
	});
	const merchantLink = `/merchant/${owner_merchant_id}`;
	const provider_fee = [fee_deposit, fee_withdrawal, fee_payment, fee_exchange].find(
		(fee) => fee !== undefined,
	);
	const providerFee = useMemo(
		() => trimLastZeros(Number(provider_fee).toFixed(viewDecimals)),
		[provider_fee, viewDecimals],
	);
	const clientLink = getClientLink({ owner_merchant_id, project_id, client_id });
	const typeExchange = auto ? 'Automatically' : type_exchange && capitalize(type_exchange);
	const linkRefund = getLinkRefund({
		link_refund_id,
		type,
		owner_merchant_id,
		project_id,
		isProject,
	});
	const approvments = [
		{
			label: 'Type',
			children: <span>{capitalize(trimBottomLine(type))}</span>,
		},
		{
			label: 'Transfer amount',
			children: <p>{isExist(amount) && trimLastZeros(Number(amount).toFixed(viewDecimals))}</p>,
		},
		{
			label: 'Status',
			children: <span>{StatusText(status)}</span>,
		},
		{
			label: 'Provider ID',
			children: (
				<Link to="/" className="link">
					{provider_id}
				</Link>
			),
		},
		{
			label: 'To wallet',
			children: <p>{to_wallet}</p>,
		},
		{
			label: 'Network fee',
			children: (
				<p>{isExist(fee_network) && trimLastZeros(Number(fee_network).toFixed(viewDecimals))}</p>
			),
		},
		{
			label: 'User ID',
			children: userLink && (
				<Link to={userLink} className="link">
					{owner_merchant_id}
				</Link>
			),
		},
		{
			label: 'Provider Fee',
			children: <p>{Number.isNaN(+providerFee) || providerFee}</p>,
		},
		{
			label: 'Provider Fee %',
			children: <p>{provider_fee_description}</p>,
		},
		{
			label: 'Linked transaction ID',
			children: linkedTransactionLink && (
				<Link to={linkedTransactionLink}>{link_transaction_id}</Link>
			),
		},
		{
			label: 'Risk Score',
			children: <p>{risk_score}</p>,
		},
		{
			label: 'Link refund ID',
			children: linkRefund && (
				<a href={linkRefund} className="link">
					{link_refund_id}
				</a>
			),
		},
		{
			condition: !projectId,
			label: 'Calculation Fee',
			children: <p>{provider_fee_description || ''}</p>,
		},
		{
			label: 'Date&Time',
			children: <p>{createDateFormatUTC(created_at)}</p>,
		},
		{
			label: 'Currency from',
			children: <p> {currency_from?.toUpperCase()}</p>,
		},
		{
			label: 'Currency to',
			children: <p> {currency_to?.toUpperCase()}</p>,
		},
		{
			condition: isCrypto,
			label: 'From wallet',
			children: <p> {from_wallet}</p>,
		},
		{
			label: 'Destination wallet',
			children: (
				<Tooltip
					value={address_to || ''}
					shortValue={address_to || '-'}
					width="auto"
					arrowPosition="right"
				/>
			),
		},
		{
			label: 'Destination',
			children: (
				<Tooltip
					value={address_to || ''}
					shortValue={address_to || '-'}
					width="auto"
					arrowPosition="right"
				/>
			),
		},
		{
			label: 'Refund time',
			children: <p>{updated_at && toUTC(new Date(updated_at))}</p>,
		},
		{
			label: 'Transfer time',
			children: createDateFormatUTC(updated_at),
		},
		{
			label: 'Refund amount',
			children: <p>{trimLastZeros(Number(amount).toFixed(viewDecimals))}</p>,
		},
		{
			label: 'Recieve amount',
			children: <p>{isExist(amount) && trimLastZeros(Number(amount).toFixed(viewDecimals))}</p>,
		},
		{
			condition: !!projectId,
			label: 'Project ID',
			children: <Link to={projectLink}>{project_id}</Link>,
		},
		{
			label: 'Amount',
			children: <p>{trimLastZeros(Number(amount_to || amount).toFixed(viewDecimalsTo))}</p>,
		},
		{
			label: 'From Project',
			children: <p>{from_project_id}</p>,
		},
		{
			label: 'To Project',
			children: <p>{to_project_id}</p>,
		},
		{
			label: 'Recieve time',
			children: <p> {createDateFormatUTC(updated_at)}</p>,
		},
		{
			label: 'Transaction hash (link)',
			children: (
				<a href={tx_url} className="link">
					<Tooltip
						value={hash_transaction || ''}
						shortValue={hash_transaction || '-'}
						width="auto"
						arrowPosition="right"
					/>
				</a>
			),
		},
		{
			label: 'Credit currency',
			children: (
				<p>
					{`${currencyName}`} {!!currency_network && ` (${currency_network})`}
				</p>
			),
		},
		{
			condition: !!projectId,
			label: 'Merchant ID',
			children: <Link to={merchantLink}>{owner_merchant_id}</Link>,
		},
		{
			label: 'Origin account(IBAN)',
			children: (
				<Tooltip
					value={iban_origin || ''}
					shortValue={iban_origin || '-'}
					width="auto"
					arrowPosition="right"
				/>
			),
		},
		{
			condition: isCrypto,
			label: 'Destination Account(IBAN)',
			children: (
				<Tooltip
					value={iban_destination || ''}
					shortValue={iban_destination || '-'}
					width="auto"
					arrowPosition="right"
				/>
			),
		},
		{
			label: 'Project Name',
			children: <p>{project_name}</p>,
		},
		{
			label: 'Reference from',
			children: <p>{reference_from}</p>,
		},
		{
			label: 'Reference to',
			children: <p>{reference_to}</p>,
		},
		{
			condition: isCrypto,
			label: 'Exchange Fee',
			children: (
				<p>
					{fee_exchange &&
						trimLastZeros(
							Number(convertExponentialToDecimal(+fee_exchange)).toFixed(viewDecimalsFrom),
						)}
				</p>
			),
		},
		{
			label: 'Withdrawal amount',
			children: <p>{trimLastZeros(Number(amount).toFixed(viewDecimals))}</p>,
		},
		{
			label: 'Withdrawal time',
			children: <p>{updated_at && toUTC(new Date(updated_at))}</p>,
		},
		{
			label: 'Network',
			children: <p>{currency_network?.toUpperCase()}</p>,
		},
		{
			condition: statuses?.length > 0,
			label: 'Last attempt result',
			children: <span>{statuses && statuses[statuses.length - 1]?.last_result}</span>,
		},
		{
			label: 'Account/purse',
			children: (
				<Tooltip
					value={address_from || ''}
					shortValue={address_from || '-'}
					width="auto"
					arrowPosition="right"
				/>
			),
		},
		{
			label: 'Original wallet',
			children: (
				<Tooltip
					value={address_from || ''}
					shortValue={address_from || '-'}
					width="auto"
					arrowPosition="right"
				/>
			),
		},
		{
			label: 'Address',
			children: (
				<Tooltip
					value={address_from || ''}
					shortValue={address_from || '-'}
					width="auto"
					arrowPosition="right"
				/>
			),
		},
		{
			label: 'Destination account (IBAN)',
			children: <p> {iban_destination}</p>,
		},
		{
			label: 'Origin account (IBAN)',
			children: <p> {iban_origin}</p>,
		},
		{
			label: 'Bank Name',
			children: <p> {bank_name}</p>,
		},
		{
			label: 'BIC',
			children: <p> {bic}</p>,
		},
		{
			label: 'Amount From',
			children: <p>{trimLastZeros(Number(amount_from).toFixed(viewDecimalsFrom))}</p>,
		},
		{
			label: 'Invoice ID',
			children: <p> {invoice_merchant_id || invoice_provider_id}</p>,
		},
		{
			label: 'Type exchange',
			children: <p> {typeExchange}</p>,
		},
		{
			label: 'Convert rate',
			children: <p> {rate}</p>,
		},
		{
			label: 'ID',
			children: <span> {id}</span>,
		},
		{
			label: 'Amount To',
			children: <p>{trimLastZeros(Number(amount_to).toFixed(viewDecimalsTo))}</p>,
		},
		{
			label: 'Client ID',
			children: (
				<p>
					{clientLink ? (
						<Link to={clientLink} className="link">
							{client_id}
						</Link>
					) : (
						<p>{client_id}</p>
					)}
				</p>
			),
		},
		{
			label: 'Order ID',
			children: (
				<p>
					{typeof order_merchant_id === 'number' ||
						(typeof order_provider_id === 'number' && (
							<Link
								to={`/orders-provider/${order_merchant_id || order_provider_id}`}
								className="link"
							>
								{order_merchant_id || order_provider_id}
							</Link>
						))}
				</p>
			),
		},
	];
	return getApprovementList(approvments, tag as TTags).filter(
		(approvement) => !approvement.condition,
	);
};

export default GetApprovementList;
