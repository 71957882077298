import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { getProviderAllCurrencyRequest } from 'redux/reducers/providerCurrency/reducer';
import { MobileProviderInfo } from 'components/Provider/MobileProviderInfo/MobileProviderInfo';
import { routes } from 'routes/routes';
import Main from 'v2Components/layoutsComponents/Main';
import { TCurrency } from './CryptoFiatSwitch/types';
import { CryptoTable } from './CryptoTable/CryptoTable';
import { FiatTable } from './FiatTable/FiatTable';
import WalletHeader from '../WalletsPage/components/WalletsHeader';

export const PendingWithdrawalPage: FC = () => {
	const { path } = useRouteMatch();
	const url_string = window.location.href;
	const urlObg = new URL(url_string);
	const paymentTransactionsMatch = useRouteMatch(path + routes.wallets.paymentTransactions);
	const transactionDetailsMatch = useRouteMatch(path + routes.wallets.transactionHistoryDetails);
	const dispatch = useDispatch();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tabs = searchParams.get('tabs');
	const [currencyTab, setCurrencyTab] = useState<TCurrency>((tabs as TCurrency) || 'crypto');
	const isCrypto = currencyTab === 'crypto';

	useEffect(() => {
		dispatch(getProviderAllCurrencyRequest());
	}, [dispatch]);

	useEffect(() => {
		const search = new URLSearchParams(location.search);
		const value = search.get('tabs');
		setCurrencyTab(value === 'fiat' ? 'fiat' : 'crypto');
	}, [location.search]);

	return (
		<Main
			header={
				<>
					<MobileProviderInfo />
					<WalletHeader
						disabled={!!transactionDetailsMatch || !!paymentTransactionsMatch}
						url={urlObg}
						title="Pending Withdrawal"
					/>
				</>
			}
		>
			{isCrypto ? (
				<CryptoTable currencyTab={currencyTab} />
			) : (
				<FiatTable currencyTab={currencyTab} />
			)}
		</Main>
	);
};
