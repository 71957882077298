import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProviderAllCurrencyRequest } from 'redux/reducers/providerCurrency/reducer';
import { MobileProjectInfo } from 'layouts-elements/MobileProjectInfo/MobileProjectInfo';
import { Pagination } from 'UI/Pagination/Pagination';
import { BackToMerchantProjects } from 'UI/GoBackLinks/Provider/BackToMerchantProjects/BackToMerchantProjects';
import { DownloadBlueIcon, PlusBlueIcon, PlusWhiteIcon } from 'assets/inline-svg';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { getProviderInvoicesRequest } from 'redux/reducers/providerInvoices/reducer';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import Table from 'v2Components/commomComponents/Table';
import FilterWrapper from 'v2Components/layoutsComponents/Filter/FilterWrapper';
import { getFilter } from 'redux/reducers/app/selectors';
import useFilterList from 'hooks/useFilterList';
import Main from 'v2Components/layoutsComponents/Main';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { IProviderInvoicesItem } from 'services/api/provider/types';
import Button from 'v2Components/UI/Button';
import { ReportDownloadButton } from 'v2Components/layoutsComponents/ReportDownloadButton';
import { SaveInvoicePopup } from './SaveInvoicePopup/SaveInvoicePopup';
import { ProviderInvoiceTableRow } from './ProviderInvoiceTableRow/ProviderInvoiceTableRow';
import { MerchantInvoicesTableRow } from './MerchantInvoiceTableRow/MerchantInvoiceTableRow';
import { CreateInvoicePopup } from './CreateInvoicePopup/CreateInvoicePopup';
import { IInvoicesTable } from './types';
import { isMerchantInvoice } from './guards';
import Filters from '../Filters';
import { getFilterInvoices } from './filter.helper';
import styles from './styles.module.scss';

export const InvoicesTable: FC<IInvoicesTable> = ({
	invoices,
	isMerchant,
	filterProps,
	currentPage,
	setCurrentPage,
	saveLoading,
	loading,
	itemIdToSave,
	setItemIdToSave,
	onCreateSubmit,
	onSaveSubmit,
	downloadReportUrl,
}) => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const [createPopupOpened, setCreatePopupOpened] = useState(false);
	const [trActionOpened, setTrActionOpened] = useState<number | null>(null);
	const [showFilter, setShowFilter] = useState(false);
	const { data = [], last_page: lastPage = 1 } = invoices || {};
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('Invoices');
	const isShowPagination = lastPage > 1 && !loading;
	const handleTrActionClick = (id: number) =>
		setTrActionOpened((prev) => (id === prev ? null : id));

	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());
	const closeCreatePopup = () => setCreatePopupOpened(false);
	const handleSaveInvoiceClick = (id: number) => setItemIdToSave?.(id);
	const closeSavePopup = () => setItemIdToSave?.(null);

	const filterList = useFilterList(getFilterInvoices);
	const handleOutsideClick = useCallback(
		(e: MouseEvent) => {
			const eventPath = e.composedPath();
			let preventClosing = false;
			eventPath &&
				eventPath.forEach((item) => {
					if (
						item instanceof Element &&
						item.tagName === 'BUTTON' &&
						item.parentElement?.classList.contains('table-buttons')
					) {
						preventClosing = true;
					}
				});
			trActionOpened != null && !preventClosing && setTrActionOpened(null);
		},
		[trActionOpened],
	);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const onSubmit = (data: any) => {
		setCurrentPage(1);
		dispatch(
			setFilter({
				...data,
			}),
		);
		setShowFilter(false);
	};

	useEffect(() => {
		window.addEventListener('click', handleOutsideClick);
		return () => window.removeEventListener('click', handleOutsideClick);
	}, [handleOutsideClick]);

	return (
		<Main
			header={
				<>
					{isMerchant ? <MobileProjectInfo /> : null}
					<div className={styles.header}>
						<ReportDownloadButton downloadReportUrl={downloadReportUrl} />
						<Button onClick={() => setCreatePopupOpened(true)} className={styles.header__button}>
							<PlusBlueIcon /> Create an invoice
						</Button>
					</div>
				</>
			}
		>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
				className={styles.filters__button}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<Filters filterProps={filterProps} />}
			/>
			<Table
				loading={loading || false}
				headerItems={tableHeaderItems}
				gridTemplateColumns={gridTemplateColumns}
				bodyItems={data}
				getRow={(item: IProviderInvoicesItem) =>
					isMerchant && isMerchantInvoice(item)
						? MerchantInvoicesTableRow({
								item,
								onActionClick: handleTrActionClick,
								isMenuActive: item.id === trActionOpened,
								onSave: handleSaveInvoiceClick,
						  })
						: ProviderInvoiceTableRow({
								item,
								onActionClick: handleTrActionClick,
								isMenuActive: item.id === trActionOpened,
								onSave: handleSaveInvoiceClick,
						  })
				}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination pageCount={lastPage} forcePage={currentPage} onPageChange={setCurrentPage} />
				</PaginationWrapper>
			)}
			<CreateInvoicePopup
				isOpened={createPopupOpened}
				closePopup={closeCreatePopup}
				onSubmit={onCreateSubmit}
				isMerchant={isMerchant}
			/>

			<SaveInvoicePopup
				isOpened={!!itemIdToSave}
				loading={saveLoading}
				onClose={closeSavePopup}
				onSubmit={onSaveSubmit}
				isMerchant={isMerchant}
			/>
		</Main>
	);
};
