import { FC, useState } from 'react';
import { RadioItem } from 'UI/RadioItem/RadioItem';
import { ISaveInvoiceForm, TVariant } from './types';

export type TVariantValue =
	| 'DOWNLOAD_IN_PDF'
	| 'SEND_PDF_TO_EMAIL'
	| 'GENERATE_LINK'
	| 'GENERATE_LINK_AND_SEND_TO_EMAIL';

const options: TVariant[] = [
	{
		value: 'DOWNLOAD_IN_PDF',
		label: 'Download invoice in PDF',
	},
	{
		value: 'SEND_PDF_TO_EMAIL',
		label: "Send PDF invoice to client's email",
	},
	{
		value: 'GENERATE_LINK',
		label: 'Generate invoice link',
	},
	{
		value: 'GENERATE_LINK_AND_SEND_TO_EMAIL',
		label: 'Generate a link to the invoice and send it to the client by mail',
	},
];

export const SaveInvoiceForm: FC<ISaveInvoiceForm> = ({ onSubmit, onClose, loading }) => {
	const [selectedVariant, setSelectedVariant] = useState<TVariantValue | null>('DOWNLOAD_IN_PDF');

	const handleVariantChange = (value: string) => {
		setSelectedVariant(selectedVariant === value ? null : (value as TVariantValue));
	};

	return (
		<>
			<div className="popup-body popup-body--type3">
				{options.map(({ value, label }) => (
					<RadioItem
						checked={value === selectedVariant}
						key={value}
						value={value}
						label={label}
						onChange={handleVariantChange}
					/>
				))}
			</div>
			<div className="popup-button popup-button--type2">
				<button
					type="button"
					className="button button--full-width button--type2"
					onClick={() => onClose?.()}
				>
					Cancel
				</button>
				<button
					type="button"
					disabled={!selectedVariant || loading}
					className="button button--full-width"
					onClick={() => selectedVariant && onSubmit?.(selectedVariant)}
				>
					Save
				</button>
			</div>
		</>
	);
};
