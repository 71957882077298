import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import { BackToMerchantProjects } from 'UI/GoBackLinks/Provider/BackToMerchantProjects/BackToMerchantProjects';
import { Pagination } from 'UI/Pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getCallbacks, getCallbacksLoading } from 'redux/reducers/projectCallbacks/selectors';
import { useParams } from 'react-router-dom';
import { exportCSVRequest, getCallbacksRequest } from 'redux/reducers/projectCallbacks/reducer';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import Main from 'v2Components/layoutsComponents/Main';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Table from 'v2Components/commomComponents/Table';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import { getFilter } from 'redux/reducers/app/selectors';
import useFilterList from 'hooks/useFilterList';
import { ICallbackItem } from 'services/api/project/types';
import Button from 'v2Components/UI/Button';
import { DownloadBlueIcon } from 'assets/inline-svg';
import { TableRow } from './TableRow/TableRow';
import Filters from './Filters';
import { getFilterProjectCallbacksHistory } from './filter.helper';
import styles from './styles.module.scss';

export default () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const [page, setPage] = useState(1);
	const callbacks = useSelector(getCallbacks);
	const loading = useSelector(getCallbacksLoading);
	const [showFilter, setShowFilter] = useState(false);
	const { data = [], last_page: totalPages = 1 } = callbacks || {};
	const { projectId } = useParams<{ projectId: string }>();
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('ProjectCallbacks');
	const filterList = useFilterList(getFilterProjectCallbacksHistory);
	const onClearFilter = () => dispatch(clearFilter());
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const onSubmit = (data: any) => {
		setPage(1);
		dispatch(
			setFilter({
				...data,
			}),
		);
		setShowFilter(false);
	};

	useEffect(() => {
		dispatch(
			getCallbacksRequest({
				...filter,
				project_id: +projectId,
				current_page: page,
			}),
		);
	}, [dispatch, page, projectId, filter]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);
	return (
		<Main>
			<ProjectContent className="project-callbacks">
				<FilterWrapper
					filterList={filterList}
					deleteItemFilter={deleteItemFilter}
					onClearFilter={onClearFilter}
					onOpenFilter={() => setShowFilter(true)}
					className={styles.filters}
				/>
				<FilterForm
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					onSubmit={onSubmit}
					renderBodyForm={<Filters />}
				/>
				<Table
					loading={loading}
					headerItems={tableHeaderItems}
					bodyItems={data || []}
					getRow={(item: ICallbackItem) => TableRow({ item })}
					gridTemplateColumns={gridTemplateColumns}
					className={styles.table}
				/>
				{totalPages > 1 && (
					<PaginationWrapper>
						<Pagination
							pageCount={Number(totalPages)}
							onPageChange={setPage}
							forcePage={Number(page)}
						/>
					</PaginationWrapper>
				)}
			</ProjectContent>
		</Main>
	);
};
