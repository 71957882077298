import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, getUserType } from 'redux/reducers/auth/selectors';
import { getWhiteList } from 'redux/reducers/merchantSecurity/selectors';
import { Link, useParams } from 'react-router-dom';
import { getProfile } from 'redux/reducers/merchantProfile/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { IFormPayload } from 'redux/reducers/merchantSecurity/types';
import { notify } from 'utils/notify';
import {
	changePasswordRequest,
	deleteAllMerchantSecurityWhiteListRequest,
	getMerchantSecurityWhiteListRequest,
} from 'redux/reducers/merchantSecurity/reducer';
import { reset2faMerchantRequest, reset2faProviderRequest } from 'redux/reducers/auth/reducer';
import { routes } from 'routes/routes';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { DeleteConfirm } from 'UI/Popups/DeleteConfirm/DeleteConfirm';
import { SecurityForm } from './SecurityForm/SecurityForm';
import { TFormOnSubmit } from './SecurityForm/types';
import { WhiteListIPBlock } from './WhiteListIPBlock/WhiteListIPBlock';
import styles from './styles.module.scss';

export default () => {
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);
	const whiteList = useSelector(getWhiteList);
	const { merchantId } = useParams<{ merchantId: string }>();
	const [isDeleteModal, setIsDeleteModal] = useState(false);
	const user = useSelector(getUserData);
	const merchantProfile = useSelector(getProfile);
	const isProvider = userType === constUserTypes.PROVIDER;

	const closeModal = () => setIsDeleteModal(false);

	const changePassword: TFormOnSubmit = (values, { setSubmitting }) => {
		const { password, new_password } = values;
		if (password === '' || new_password === '') return;

		const payload: IFormPayload = {
			apiPayload: { current_password: password, new_password },
			onError: () => setSubmitting(false),
			onSuccess: () => {
				setSubmitting(false);
				notify('Password changed successfully', 'success');
			},
			userType,
		};
		if (isProvider) payload.apiPayload.merchant_id = merchantId;
		dispatch(changePasswordRequest(payload));
	};

	const deleteWhiteList = () => {
		dispatch(
			deleteAllMerchantSecurityWhiteListRequest({ params: { user_id: merchantId }, userType }),
		);
	};

	const onReset2fa = () => {
		const payload = {
			email: isProvider ? merchantProfile?.merchant?.email || '' : (user?.email as string),
			user_type: constUserTypes.MERCHANT,
		};

		dispatch(
			isProvider
				? reset2faProviderRequest({
						apiParams: payload,
				  })
				: reset2faMerchantRequest({
						apiParams: payload,
				  }),
		);
	};

	useEffect(() => {
		dispatch(
			getMerchantSecurityWhiteListRequest({ params: { merchant_id: merchantId }, userType }),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<div className={styles.main}>
			<div className="mobile-project-info">
				<div className="header-project">
					<div className="header-project__name">
						<p>Merchant company name</p>
					</div>
				</div>
			</div>
			<div className={styles.main__wrapper}>
				<div className="info--width-560">
					{(user?.role.code === 'PROVIDER_ADMIN' || user?.is_owner) && (
						<div>
							<p className="tfa-block__title">Two-factor autentication</p>
							<div className="tfa-block__box">
								<p className="tfa-block__text">
									Two -factor autentication (2-FA) is an extra layer of protection in addition to
									your password.
								</p>
							</div>
							<button onClick={onReset2fa} type="button" className="button button--type3">
								Reset Two-factor autentication
							</button>
						</div>
					)}
					<SecurityForm onSubmit={changePassword} />
					<div>
						<p className="tfa-block__title">Two-factor autentication</p>
						<div className="tfa-block__box">
							<p className="tfa-block__text">
								If you&apos;ve forgotten your previous password, you can initiate a reset. The reset
								request will be dispatched to the primary administrator of your company or to the
								provider&apos;s support team, depending on whether you hold the main administrator
								role
							</p>
						</div>
						<button type="button" className="button">
							Reset Password
						</button>
					</div>
				</div>
			</div>
			<PopupModal open={isDeleteModal} onClose={closeModal}>
				<DeleteConfirm
					title="Delete whitelist"
					body="Are you sure you want to delete all whitelist?"
					onDelete={deleteWhiteList}
					onClose={closeModal}
				/>
			</PopupModal>
			<WhiteListIPBlock whiteList={whiteList} />
			{!!whiteList?.length && (
				<div className="user-profile-white-list-button">
					<button
						onClick={() => setIsDeleteModal(true)}
						className="button button--type8"
						type="button"
					>
						Delete All
					</button>
				</div>
			)}
		</div>
	);
};
