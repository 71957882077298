/* eslint-disable import/no-relative-packages */
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import { FC } from 'react';
import classNames from 'classnames';

type ILangItemProps = {
	name: string;
	selectHandler: () => void;
	language: string;
};

const LanguageItem: FC<ILangItemProps> = ({ name, selectHandler, language }) => {
	return (
		<li onClick={selectHandler} className={classNames({ active: language === name })}>
			<a href="#?">{name}</a>
		</li>
	);
};

export default LanguageItem;
