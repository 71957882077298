/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProjectWalletAddressesStore, IProviderWalletAddressesResponse } from './types';

export const initialState: IProjectWalletAddressesStore = {
	projectWalletsAddresses: null,
	projectWalletsAddressesLoading: false,
	projectWalletsAddressesClient: null,
	projectWalletsAddressesClientLoading: false,
};

const projectWalletAddresses = createSlice({
	name: '@@projectWalletAddresses',
	initialState,
	reducers: {
		getProviderWalletAddressesRequest: (state, _: PayloadAction<any>) => {
			state.projectWalletsAddressesLoading = true;
		},
		getProviderWalletAddressesSuccess: (
			state,
			{ payload }: PayloadAction<IProviderWalletAddressesResponse>,
		) => {
			state.projectWalletsAddresses = payload;
			state.projectWalletsAddressesLoading = false;
		},
		getProjectWalletAddressesClientRequest: (state, _: PayloadAction<any>) => {
			state.projectWalletsAddressesClientLoading = true;
		},
		getProjectWalletAddressesSuccessClient: (
			state,
			{ payload }: PayloadAction<IProviderWalletAddressesResponse>,
		) => {
			state.projectWalletsAddressesClient = payload;
			state.projectWalletsAddressesClientLoading = false;
		},
	},
});

export default projectWalletAddresses.reducer;
export const {
	getProviderWalletAddressesRequest,
	getProviderWalletAddressesSuccess,
	getProjectWalletAddressesClientRequest,
	getProjectWalletAddressesSuccessClient,
} = projectWalletAddresses.actions;
