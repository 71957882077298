import { DotsIcon } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import moment from 'moment';
import { FC, MouseEvent, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSaveLoading } from 'redux/reducers/providerInvoices/selectors';
import { createOrderInvoiceProviderSuccess } from 'redux/reducers/providerOrders/reducer';
import { orderStatus } from 'services/constants/orderStatus';
import { SaveInvoicePopup } from 'v2Pages/Provider/InvoicesPage/InvoicesTable/SaveInvoicePopup/SaveInvoicePopup';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { notify } from 'utils/notify';
import { trimZerosAfterDot, trimLastZeros } from 'utils/strings';
import { routes } from 'routes/routes';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { MenuButton } from '@mui/base';
import { Menu } from '@mui/base/Menu';
import styles from 'components/Merchant/Orders/TableRow/styles.module.scss';
import { MenuItem } from '@mui/material';
import { roles } from 'layouts-elements/ProviderSidebar/roles';
import { Dropdown } from '@mui/base/Dropdown';
import { getUserRole } from 'redux/reducers/auth/selectors';
import { IPropsBodyTable } from './types';
import { PopUpRefun } from './PopUpRefun';
import { PopUpInvoice } from './PopUpInvoice';

export default ({
	order,
	onSaveSubmit,
	itemIdToSave,
	isMerchant,
	onRefetch,
}: IPropsBodyTable): TdItem[] => {
	const dispatch = useDispatch();
	const [isOpen, setIsOpen] = useState<number>(0);
	const [isOpenModal, setIsOpenModal] = useState<string>('');
	const [orderId, setOrderId] = useState<number | string | undefined>('');
	const { merchantId, projectId } = useParams<{ merchantId: string; projectId: string }>();
	const userRole = useSelector(getUserRole);
	const [defaultValues, setDefaultValues] = useState<{
		currency_code?: string;
		amount_request?: number;
	}>({});

	const saveLoading = useSelector(getSaveLoading);
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const { getViewDecimal } = useViewDecimals();

	const onAction = (
		name: string,
		event?: MouseEvent,
		id?: string | number | undefined,
		status?: string,
	) => {
		if (order) {
			const { issue, currency_paid } = order;
			setDefaultValues({
				currency_code: currency_paid,
				amount_request: issue || issue === 0 ? Math.abs(issue) : undefined,
			});
		}

		!(name === 'refun' && !orderStatus.includes(status || ''))
			? setIsOpenModal(name)
			: notify('Order status does not allow a refund', 'info');
		setIsOpen(0);
		setOpen(false);
		setOrderId(id);
	};

	const closeSavePopup = () => {
		dispatch(createOrderInvoiceProviderSuccess(null));
	};
	const viewDecimalsRequest = getViewDecimal(order.currency_request);
	const viewDecimalsPaid = getViewDecimal(order.currency_paid);
	const { merchant_id } = order;
	const merchantLink =
		typeof order.merchant_id === 'number'
			? routes.oneMerchantBase.replace(':merchantId', String(merchant_id))
			: null;

	return [
		{
			title: 'ID',
			children: <Link to={`/orders-provider/${String(order.id)}`}>{order.id}</Link>,
		},
		{
			title: 'Date',
			children: <>{moment(order.created_at).utc().format('DD.MM.YYYY, HH:mm')} UTC</>,
		},
		{
			title: 'Merchant ID',
			children: merchantLink ? (
				<Link to={merchantLink} className="td-value">
					{order.merchant_id}
				</Link>
			) : (
				<p className="td-value">-</p>
			),
		},
		{
			title: 'Amount',
			children: trimLastZeros(Number(order.amount_request).toFixed(viewDecimalsRequest)),
		},
		{
			title: 'Currency',
			children: order.currency_request.toUpperCase(),
		},
		{
			title: 'Amount paid',
			children: trimLastZeros(Number(order.amount_paid).toFixed(viewDecimalsPaid)),
		},
		{
			title: 'Currency paid',
			children: order.currency_paid.toUpperCase(),
		},
		{
			title: 'Invoice ID',
			children: order?.invoices.length ? order?.invoices[0].id : null,
		},
		{
			title: 'Status',
			children: (
				<p className={`td-value status-custom ${order?.status.replaceAll(' ', '-').toLowerCase()}`}>
					{order.status}
				</p>
			),
		},
		{
			title: 'Issue',
			children: trimZerosAfterDot(order.issue?.toFixed(8)),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<Dropdown>
						<MenuButton>
							<DotsIcon />
						</MenuButton>
						<Menu className={styles.menu}>
							<MenuItem className={styles.menu__item}>
								<Link
									ref={dropRef}
									to={`/merchant/${merchantId}/project/${projectId}/order-merchant/details/${orderId}`}
								>
									More info
								</Link>
							</MenuItem>
							{roles.ordersIn.includes(userRole || '') && (
								<MenuItem
									onClick={(event: MouseEvent) => onAction('refun', event, orderId, order.status)}
									className={styles.menu__item}
								>
									Refund
								</MenuItem>
							)}
							<MenuItem
								onClick={(event: MouseEvent) => onAction('invoice', event, orderId, order.status)}
								className={styles.menu__item}
							>
								Invoice
							</MenuItem>
						</Menu>
					</Dropdown>
					<SaveInvoicePopup
						isOpened={!!itemIdToSave}
						loading={saveLoading}
						onClose={closeSavePopup}
						onSubmit={onSaveSubmit}
						isMerchant={isMerchant}
					/>
					{isOpenModal === 'invoice' && orderId && (
						<PopUpInvoice
							onAction={(e) => onAction('invoice', e)}
							orderId={orderId}
							defaultValues={defaultValues}
						/>
					)}
					{isOpenModal === 'refun' && orderId && (
						<PopUpRefun
							onAction={(e) => onAction('refun', e)}
							orderId={orderId}
							setIsOpenModal={setIsOpenModal}
							order={order}
							onSuccess={onRefetch}
						/>
					)}
				</TableButtons>
			),
		},
	];
};
