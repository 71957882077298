import { FC, useMemo, useState } from 'react';
import { TriangleArrowDownIcon2, CheckIcon, SearchIcon } from 'assets/inline-svg';
import { useSelector } from 'react-redux';
import { getProviderCurrencyAllNoSettings } from 'redux/reducers/providerCurrency/selectors';
import classNames from 'classnames';
import { useSelect } from 'hooks';
import { ISelected, TSelectElement } from './types';

export const CurrencySelectItem: FC<ISelected> = ({
	selectedElArray,
	label,
	placeholder,
	crypto,
	fiat,
	selectedElId,
	className,
	search,
	setSearch,
	setSelectedToRequset,
}) => {
	const items = useSelector(getProviderCurrencyAllNoSettings);
	const [selectedItem, setSelectedItem] = useState<TSelectElement | null>(null);
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const startCurrency = items.find((el) => el.id === selectedElId);
	const handleSelect = (value: TSelectElement) => {
		setSelectedItem(value);
		setSelectedToRequset ? setSelectedToRequset(value) : undefined;
		setOpen(false);
	};

	const selectedList = useMemo(() => {
		if (!crypto && !fiat) return selectedElArray;
		const isCrypto = crypto ? 1 : 0;
		return selectedElArray.filter((el) => el.is_crypto === isCrypto);
	}, [crypto, fiat, selectedElArray]);

	return (
		<div className="select-block">
			<p className="select-block__name">{label}</p>
			<div className={classNames('select select--type-choice', { active: open }, className)}>
				<button
					type="button"
					className={`select__current ${!selectedItem ? 'select__current--placeholder' : ''}`}
					ref={triggerRef}
					onClick={toggleOpen}
				>
					{selectedItem || startCurrency ? (
						<span className="token">
							{selectedItem?.code !== 'All' && (
								<span className="token__icon">
									<img src={selectedItem?.img_path || startCurrency?.img_path} alt="" />
								</span>
							)}
							<span className="token__name">{selectedItem?.name || startCurrency?.name}</span>
							<span className="token__transcription">
								{selectedItem?.code === 'All'
									? selectedItem?.code
									: selectedItem?.code.toUpperCase() || startCurrency?.code.toUpperCase()}
							</span>
						</span>
					) : (
						placeholder
					)}
					<span className="select__current-arrow">
						<TriangleArrowDownIcon2 />
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					{className ? (
						<div className="input input--select-drop">
							<div className="input-wrapper">
								<input
									className="input-item input-item--right-icon"
									type="text"
									placeholder="Index"
									value={search}
									onChange={(e) => (setSearch ? setSearch(String(e.target.value)) : undefined)}
								/>
								<div className="input-icon input-icon--right">
									<SearchIcon />
								</div>
							</div>
						</div>
					) : null}
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{selectedList.map((el) => (
									<li key={el.id}>
										<button
											type="button"
											className={`${selectedItem?.id === el.id ? 'active' : ''} ${
												el.code === 'All' ? 'token--center' : ''
											}`}
											onClick={() => handleSelect(el)}
										>
											<span className="token">
												{el.code !== 'All' && (
													<span className="token__icon">
														<img src={el.img_path} alt="" />
													</span>
												)}
												<span className="token__name">{el.name}</span>
												<span className="token__transcription">
													{el.code === 'All' ? el.code : el.code.toUpperCase()}
												</span>
											</span>
											<span>
												{selectedItem?.id === el.id && el.code !== 'All' ? <CheckIcon /> : ''}
											</span>
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
