import { FC } from 'react';
import {
	IProviderCommissions,
	IProviderTotalCounters,
} from 'redux/reducers/analyticsProvider/types';
import { capitalize } from 'utils/strings';

interface IProps {
	providerCommissions: Array<IProviderCommissions> | undefined;
	providerTotalNewCounters: Array<IProviderTotalCounters> | undefined;
	balanceCurrent: number | undefined;
}

export const TextAnalytics: FC<IProps> = ({
	providerCommissions,
	providerTotalNewCounters,
	balanceCurrent,
}) => {
	return (
		<>
			<div className="analytics-data-wrap analytics-data-wrap--border-top">
				<div className="analytics-data">
					<div className="analytics-data__title">
						<p>Commission, Profit from businesses&apos; and clients</p>
					</div>
					<div className="analytics-data__number">
						<p>{providerCommissions?.[0]?.amount || 0}</p>
					</div>
				</div>
				<div className="analytics-data">
					<div className="analytics-data__title">
						<p>Business balance at the moment</p>
					</div>
					<div className="analytics-data__number">
						<p>{balanceCurrent || 0}</p>
					</div>
				</div>
			</div>

			<div className="analytics-data-wrap analytics-data-wrap--type2">
				{providerTotalNewCounters?.map((analytic) => (
					<div className="analytics-data">
						<div className="analytics-data__title">
							<p>{capitalize(analytic.param)}</p>
						</div>
						<div className="analytics-data__list analytics-data__list--type2">
							<div className="analytics-data__number">
								<span>Total:</span>
								<p>{analytic.total}</p>
							</div>
							<div className="analytics-data__number">
								<span>New:</span>
								<p>{analytic.new}</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
};
