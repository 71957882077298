import styles from './styles.module.scss';

const COUNT_CAPSULES = 42;

interface IProps {
	color?: string;
	percent: number;
}

const Capsule = ({ color }: { color?: string }) => (
	<div className={styles.capsule} style={{ background: color }} />
);
const ProgressbarCapsule = ({ color, percent }: IProps) => {
	const line = Math.round((percent / 100) * COUNT_CAPSULES);
	return (
		<div className={styles.progressbar}>
			{Array(COUNT_CAPSULES)
				.fill(null)
				.map((_: null, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<Capsule color={index <= line ? color : undefined} key={index} />
				))}
		</div>
	);
};
export default ProgressbarCapsule;
