import { FC } from 'react';
import { IPropsWithdrawalCryptoInfo } from './types';

export const WithdrawalFiatInfo: FC<IPropsWithdrawalCryptoInfo> = ({
	isSelect,
	limits,
	amount,
}) => {
	const limitsObj = {
		single: limits.limits.single_limit - (Number(limits.limits.single_limit_used) + Number(amount)),
		daily: limits.limits.daily_limit - (Number(limits.limits.daily_limit_used) + Number(amount)),
		monthly:
			limits.limits.monthly_limit - (Number(limits.limits.monthly_limit_used) + Number(amount)),
	};

	const minValue = Math.min(limitsObj.single, limitsObj.daily, limitsObj.monthly);

	const minKey = Object.keys(limitsObj).find(
		(key) => limitsObj[key as keyof typeof limitsObj] === minValue,
	);

	return (
		<>
			<div className="withdrawal-info">
				<div className="withdrawal-info__title">
					<p>Minimum Withdrawal: {isSelect?.withdraw_min || '0'}</p>
				</div>
			</div>
			{minKey === 'single' && (
				<div className="withdrawal-info">
					<div className="withdrawal-info__title">
						<p>Single limit</p>
					</div>
					<div className="progress-line">
						{Number(limits.limits.single_limit_used) + Number(amount) <=
						Number(limits.limits.single_limit) ? (
							<div
								className="progress-line__fill"
								style={{
									width: `${
										(Number(limits.limits.single_limit_used) + Number(amount)) /
										(Number(limits.limits.single_limit) / 100)
									}%`,
								}}
							/>
						) : (
							<div
								className="progress-line__full"
								style={{
									width: `100%`,
								}}
							/>
						)}
					</div>
					<div className="withdrawal-info__info">
						<p>{`${limitsObj.single} ${String(
							isSelect?.currency.code?.toUpperCase(),
						)} remains of ${String(limits.limits.single_limit)}`}</p>
						{Number(limits.limits.single_limit_used) + Number(amount) >
							Number(limits.limits.single_limit) && (
							<p className="limit-color-custom">Single limit reached</p>
						)}
					</div>
				</div>
			)}
			{minKey === 'daily' && (
				<div className="withdrawal-info">
					<div className="withdrawal-info__title">
						<p>Daily limit</p>
					</div>
					<div className="progress-line">
						{Number(limits.limits.daily_limit_used) + Number(amount) <=
						Number(limits.limits.daily_limit) ? (
							<div
								className="progress-line__fill"
								style={{
									width: `${
										(Number(limits.limits.daily_limit_used) + Number(amount)) /
										(Number(limits.limits.daily_limit) / 100)
									}%`,
								}}
							/>
						) : (
							<div
								className="progress-line__full"
								style={{
									width: `100%`,
								}}
							/>
						)}
					</div>
					<div className="withdrawal-info__info">
						<p>{`${limitsObj.daily} ${String(
							isSelect?.currency.code?.toUpperCase(),
						)} remains of ${String(limits.limits.daily_limit)}`}</p>
						{Number(limits.limits.daily_limit_used) + Number(amount) >
							Number(limits.limits.daily_limit) && (
							<p className="limit-color-custom">Daily limit reached</p>
						)}
					</div>
				</div>
			)}
			{minKey === 'monthly' && (
				<div className="withdrawal-info">
					<div className="withdrawal-info__title">
						<p>Monthly limit</p>
					</div>
					<div className="progress-line">
						{Number(limits.limits.monthly_limit_used) + Number(amount) <=
						limits.limits.monthly_limit ? (
							<div
								className="progress-line__fill"
								style={{
									width: `${
										(Number(limits.limits.monthly_limit_used) + Number(amount)) /
										(Number(limits.limits.monthly_limit) / 100)
									}%`,
								}}
							/>
						) : (
							<div
								className="progress-line__full"
								style={{
									width: `100%`,
								}}
							/>
						)}
					</div>
					<div className="withdrawal-info__info">
						<p>{`${limitsObj.monthly} ${String(
							isSelect?.currency.code?.toUpperCase(),
						)} remains of ${String(limits.limits.monthly_limit)}`}</p>
						{Number(limits.limits.monthly_limit_used) + Number(amount) >
							Number(limits.limits.monthly_limit) && (
							<p className="limit-color-custom">Monthly limit reached</p>
						)}
					</div>
				</div>
			)}
			{/* <div className="withdrawal-fee-choice">
				<div className="withdrawal-fee-choice-title">
					<p>Miner fee level</p>
				</div>
				<div className="withdrawal-fee-choice-list">
					<button type="button" className="withdrawal-fee-choice-item">
						<span className="withdrawal-fee-choice-item__time">
							<span>Slow</span>
						</span>
						<span className="withdrawal-fee-choice-item__price">
							<span>{`0 ${isSelect?.currency_network.toUpperCase() || ''}`}</span>
						</span>
					</button>
					<button type="button" className="withdrawal-fee-choice-item active">
						<span className="withdrawal-fee-choice-item__time">
							<span>Average</span>
						</span>
						<span className="withdrawal-fee-choice-item__price">
							<span>{`0 ${isSelect?.currency_network.toUpperCase() || ''}`}</span>
						</span>
					</button>
					<button type="button" className="withdrawal-fee-choice-item">
						<span className="withdrawal-fee-choice-item__time">
							<span>Fast</span>
						</span>
						<span className="withdrawal-fee-choice-item__price">
							<span>{`0 ${isSelect?.currency_network.toUpperCase() || ''}`}</span>
						</span>
					</button>
				</div>
			</div> */}
		</>
	);
};
