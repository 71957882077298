export const noRepeat = (networks: any, name: string) => {
	const filteredArray: Array<any> = [];
	networks?.filter((item: any) => {
		if (!filteredArray.some((element) => element[name] === item[name])) {
			filteredArray.push(item);
		}
		return item;
	});
	return filteredArray;
};
