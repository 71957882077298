import { FC } from 'react';
import logoImg from 'assets/styles/images/Logo.svg';
import { Link, useLocation, useParams } from 'react-router-dom';
import { UserInfo } from 'layouts-elements/UserInfo/UserInfo';
import { useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import classNames from 'classnames';
import { BurgerBtn } from 'UI/BurgerBtn/BurgerBtn';

export const ProviderHeader: FC = () => {
	const userType = useSelector(getUserType);
	const active = userType === constUserTypes.PROVIDER;
	const { merchantId } = useParams<{ merchantId: string }>();

	const { pathname } = useLocation();

	return (
		<header
			className={classNames('header', {
				'header--type2': active || !['/merchant-project-list', '/add-project'].includes(pathname),
			})}
		>
			<div className="header__container">
				<div
					className={classNames('header-left', {
						'header-left--type2': active,
					})}
				>
					<Link to="/wallets/balances?tabs=crypto" className="logo">
						<img src={logoImg} alt="logo" />
					</Link>
					{active && (
						<div className="header-project__name">
							<p>{merchantId ? 'Merchant' : 'Provider'}</p>
						</div>
					)}
				</div>

				<div className="header-right header-right--type2">
					<UserInfo />
					<BurgerBtn />
				</div>
			</div>
		</header>
	);
};
