/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import { FC } from 'react';
import { IAvailableCurrency } from 'services/api/public/types';
import { useSelector } from 'react-redux';
import { getCreatePaymentLoading } from 'redux/reducers/paymentWidget/selectors';
import classNames from 'classnames';

type IValues = {
	currencies: IAvailableCurrency[];
	onSubmit: (value: IAvailableCurrency, net: boolean) => void;
	network: boolean;
	networks: IAvailableCurrency[] | null;
	loading: boolean | null;
};

export const PaymentBox: FC<IValues> = ({ currencies, onSubmit, network, networks, loading }) => {
	const paymentLoading = useSelector(getCreatePaymentLoading);
	const getCurrencyNetworkName = (item: IAvailableCurrency) =>
		networks?.length && `(${item?.currency_network_name?.toUpperCase()})`;
	return (
		<ul className={classNames('payment-list', { two: network })}>
			{currencies.map((item) => (
				<button
					type="button"
					key={item.id}
					className="payment-list_point payment-list_point--custom"
					onClick={() => onSubmit(item, network)}
					disabled={paymentLoading}
				>
					<span className="payment-list_logo">
						<img src={item?.img_path} alt="icon" />
					</span>
					<span className="payment-list_name">
						{!networks?.length && item.currency_name}
						{item.currency_code?.toUpperCase()}
						{getCurrencyNetworkName(item)}
					</span>
				</button>
			))}
		</ul>
	);
};
