import { IStoreState } from 'redux/types';

export const getPaymentTrasactionsProvider = (state: IStoreState) =>
	state.providerPaymentTrasaction;

export const getPaymentTrasactionSelector = (state: IStoreState) =>
	getPaymentTrasactionsProvider(state).paymentTrasactions;
export const getShowPaymentTrasactionSelector = (state: IStoreState) =>
	getPaymentTrasactionsProvider(state).paymentTrasaction;

export const getPaymentTrasactionSelectorLoading = (state: IStoreState) =>
	getPaymentTrasactionsProvider(state).paymentTrasactionsLoading;
