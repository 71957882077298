import { FC } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';

const BlockUserAccount: FC<any> = ({ onClose, onSubmit, id, block }) => {
	return (
		<div className="popup popup--width-480">
			<button aria-label="button" type="button" className="popup__close">
				<PopUpCloseIcon
					onClick={(e) => {
						if (e.target === e.currentTarget) {
							onClose();
						}
					}}
				/>
			</button>
			<div className="popup-header">
				<p className="popup-header__title">{!block ? 'Block' : 'Unblock'} user</p>
			</div>
			<div className="popup-body">
				<p className="popup-text">
					Are you sure you want to {!block ? 'block' : 'unblock'} the user?
				</p>
			</div>
			<div className="popup-button popup-button--type2">
				<button
					onClick={() => {
						onClose();
					}}
					type="button"
					className="button button--full-width button--type2"
				>
					Cancel
				</button>
				<button
					onClick={() => {
						onSubmit(id);
					}}
					type="button"
					className="button button--full-width button--type4"
				>
					{!block ? 'Block' : 'Unblock'}
				</button>
			</div>
		</div>
	);
};

export default BlockUserAccount;
