import { DebounceInput } from 'react-debounce-input';
import { ChangeEvent } from 'react';
import { useViewDecimals } from 'hooks/useViewDecimals';
import styles from './styles.module.scss';

interface IProps {
	label?: string;
	network?: string;
	code?: string;
	max?: number;
	getRate: (value?: number) => void;
	value: number;
}

const CurrencyAmount = ({ label = 'Amount', network, code, max, getRate, value }: IProps) => {
	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = getViewDecimal(code || '', network);
	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newValue = e?.target?.value;
		getRate(Number.isNaN(+newValue) || Number(newValue) < 0 ? 0 : +newValue);
	};
	return (
		<div className={styles.input}>
			<div className={styles.label}>{label}:</div>
			<DebounceInput
				className={styles.value}
				type="number"
				max={max}
				value={value.toFixed(viewDecimals)}
				onChange={onChange}
				debounceTimeout={500}
			/>
			{max !== undefined && network && (
				<div className={styles.actions}>
					<span className={styles.max} onClick={() => getRate(max)}>
						MAX
					</span>
					{network?.toUpperCase()}
				</div>
			)}
		</div>
	);
};

export default CurrencyAmount;
