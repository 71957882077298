import { FC, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { TwoDotsVerticalIcon, DuplicateBlueIcon } from 'assets/inline-svg';
import { dateToString, dateToUTC } from 'utils/dates';
import { notify } from 'utils/notify';
import { copyToClipboard } from 'utils/clipboard';
import { getDateDiffFormatted } from './utils';
import { IInvoiceLink } from './types';

export type { IInvoiceLink };

export const InvoiceLink: FC<IInvoiceLink> = ({
	website,
	invoiceNumber,
	email,
	project,
	invoiceDate: invoiceDateStr = '',
	dueDate: dueDateStr = '',
	currencyName,
	currency,
	currencyToPay,
	amount,
	toPay,
	qrCodePath,
	address,
	status,
	checkStatus,
}) => {
	const [restTime, setRestTime] = useState<{ hh: string; mm: string; ss: string }>({
		hh: '',
		mm: '',
		ss: '',
	});

	const intervalRef = useRef<number | null>(null);
	const checkStatusIntervalRef = useRef<number | null>(null);
	const dueDate = useMemo(() => new Date(dueDateStr), [dueDateStr]);

	const currentDate = dateToUTC(new Date());

	const isOverdue = status === 'overdue' || currentDate > dueDate;

	const displayTimeCell = (value: string) => {
		return isOverdue ? '0' : value;
	};

	const invoiceDate = dateToUTC(new Date(invoiceDateStr));

	const intervalCallback = useCallback(() => {
		const date = dateToUTC(new Date());
		const { hh, mm, ss } = getDateDiffFormatted(dueDate, date);
		setRestTime({ hh, mm, ss });
	}, [dueDate]);

	const checkStatusCallback = useCallback(() => {
		checkStatus();
	}, [checkStatus]);

	const onLoad = useCallback(() => {
		if (!intervalRef.current && !isOverdue) {
			intervalRef.current = window.setInterval(intervalCallback, 1000);
		}
		if (!checkStatusIntervalRef.current) {
			checkStatusIntervalRef.current = window.setInterval(checkStatusCallback, 10000);
		}
	}, [checkStatusCallback, intervalCallback, isOverdue]);

	useEffect(() => {
		if (intervalRef.current) {
			window.clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
		onLoad();
		return () => {
			if (intervalRef.current) {
				window.clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
			if (checkStatusIntervalRef.current) {
				window.clearInterval(checkStatusIntervalRef.current);
				checkStatusIntervalRef.current = null;
			}
		};
	}, [onLoad]);

	useEffect(() => {
		checkStatus();
	}, [checkStatus]);

	useEffect(() => {
		if (isOverdue && intervalRef.current) {
			window.clearInterval(intervalRef.current);
		}
	}, [isOverdue]);

	const handleCopyAddress = () => {
		address &&
			copyToClipboard(address, () => {
				notify('Invoice was successfully copied to clipboard', 'success');
			});
	};

	const { hh, mm, ss } = restTime;

	return (
		<section className="invoices-section invoice-link">
			<div className="inside">
				<div className="invoices">
					<div className="invoices-header">
						<span className="token token--type5">
							<span className="token__icon">
								<img src={project?.file_icon || ''} alt="" />
							</span>
							<span className="token__name">{project?.name || ''}</span>
						</span>
						<div className="invoices-header__invoices">
							<p>INVOICE</p>
						</div>
					</div>
					<div className="invoices-info">
						<div className="invoices-info__item">
							<p>Website:</p>
							<p>
								<a href={website} className="link">
									{website}
								</a>
							</p>
						</div>
						<div className="invoices-info__item">
							<p>Invoice number:</p>
							<p>{invoiceNumber}</p>
						</div>
						<div className="invoices-info__item">
							<p>Invoice date:</p>
							<p>{dateToString(invoiceDate)} UTC</p>
						</div>
						<div className="invoices-info__item">
							<p>Due date:</p>
							<p>{dateToString(dueDate)} UTC</p>
						</div>
						<div className="invoices-info__item">
							<p>Bill to:</p>
							<p>{email}</p>
						</div>
					</div>
					{status === 'new' && (
						<>
							<div className="invoices-timer-titel">
								<p>Valid for:</p>
							</div>
							<div className="invoices-timer">
								<div className="invoices-timer__item">
									<p>{displayTimeCell(hh[0])}</p>
								</div>
								<div className="invoices-timer__item">
									<p>{displayTimeCell(hh[1])}</p>
								</div>
								<div className="invoices-timer__dots">
									<TwoDotsVerticalIcon />
								</div>
								<div className="invoices-timer__item">
									<p>{displayTimeCell(mm[0])}</p>
								</div>
								<div className="invoices-timer__item">
									<p>{displayTimeCell(mm[1])}</p>
								</div>
								<div className="invoices-timer__dots">
									<TwoDotsVerticalIcon />
								</div>
								<div className="invoices-timer__item">
									<p>{displayTimeCell(ss[0])}</p>
								</div>
								<div className="invoices-timer__item">
									<p>{displayTimeCell(ss[1])}</p>
								</div>
							</div>
						</>
					)}
					<div className="invoices-total-info">
						<p>
							<span className="grey">Order Price:</span>
							<span>
								{amount} {currency?.toUpperCase()}
							</span>
						</p>
						<p>
							<span>To pay:</span>
							<span className="fize2">
								{toPay} {currencyToPay?.toUpperCase()}
							</span>
						</p>
					</div>
					{status === 'new' && (
						<>
							<p className="popup-text popup-text--fz-14">{currencyName} account for payment</p>
							<div className="qr-code">
								<img src={`${String(window.location.origin)}${String(qrCodePath)}`} alt="qr" />
							</div>
							<div className="copy-address">
								<p>{address}</p>
								<button aria-label="button" type="button" onClick={handleCopyAddress}>
									<DuplicateBlueIcon />
								</button>
							</div>
						</>
					)}
					{isOverdue && (
						<div>
							<p className="status invoice-overdue">
								The invoice unavailable. Please contact support for details
							</p>
						</div>
					)}
					{status === 'in_progress' && (
						<div>
							<p className="in-progress">Your transaction is in progress</p>
						</div>
					)}
					{status === 'paid' && (
						<div>
							<p className="paid">Your invoice has been paid successfully</p>
						</div>
					)}
					{status === 'overpaid' && (
						<div>
							<p className="overpaid">Your invoice has been overpaid</p>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
