import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import { ProviderHeader } from 'layouts-elements/ProviderHeader/ProviderHeader';
import { ProviderHeaderV2 } from 'v2Components/layoutsComponents/ProviderHeader';
import { ProviderSidebar } from 'layouts-elements/ProviderSidebar/ProviderSidebar';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getStatusBurger } from 'redux/reducers/app/selectors';
import { ProviderSidebarV2 } from 'v2Components/layoutsComponents/ProviderSidebar';
import { routes } from 'routes/routes';
import { Redirect } from 'react-router-dom';
import { getUserType } from 'redux/reducers/auth/selectors';
import { IDashboardLayoutProps } from './types';
import styles from './styles.module.scss';

export const ProviderLayout: FC<IDashboardLayoutProps> = ({ children, title, v2 }) => {
	const userType = useSelector(getUserType);
	const appTitle = APP_NAME || '';
	const layoutTitle = title ? ` | ${title}` : '';
	const statusBurger = useSelector(getStatusBurger);

	if (!userType) {
		return <Redirect to={routes.login} />;
	}

	return (
		<>
			<Helmet>
				<title>{`${appTitle}${layoutTitle}`}</title>
			</Helmet>

			<div className="wrapper">
				{v2 ? <ProviderHeaderV2 /> : <ProviderHeader />}

				<main className={classNames(v2 ? styles.main : 'main', { [styles.active]: statusBurger })}>
					<section className="main-section">
						{v2 ? <ProviderSidebarV2 /> : <ProviderSidebar />}

						{children}
					</section>
				</main>
			</div>
		</>
	);
};
