import { PopUpCloseIcon } from 'assets/inline-svg';
import { FC } from 'react';
import { IPropsPopUps } from './types';

export const PopUpResend: FC<IPropsPopUps> = ({ onAction, onClose }) => {
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<button aria-label="button" type="button" onClick={onClose} className="popup__close">
						<PopUpCloseIcon />
					</button>
					<div className="popup-header">
						<p className="popup-header__title">Resend Withdrawal</p>
					</div>
					<div className="popup-body">
						<p className="popup-text">Are you sure you want to resend the withdrawal?</p>
					</div>
					<div className="popup-button popup-button--type2">
						<button
							type="button"
							onClick={onClose}
							className="button button--full-width button--type2"
						>
							Cancel
						</button>
						<button
							type="button"
							onClick={onAction}
							className="button button--full-width button--type4"
						>
							Resend
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
