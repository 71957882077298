import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IProjectClientsApi } from './types';

export const projectClients: IProjectClientsApi = {
	// Project clients
	getClients: (params) =>
		http.get(endpoint.project.GET_CLIENT, { params }).then(({ data }) => data),
	showClients: (params) =>
		http.get(endpoint.project.SHOW_CLIENT, { params }).then(({ data }) => data),
	editClient: (params) => http.put(endpoint.project.EDIT_CLIENT, params).then(({ data }) => data),
	getClientTransactions: (params) =>
		http.get(endpoint.project.SHOW_CLIENT_TRANSACTIONS, { params }).then(({ data }) => data),
	activateClient: (params) => http.put(endpoint.project.ACTIVATE_CLIENT, params),
	deleteClient: (params) => http.put(endpoint.project.DELETE_CLIENT, params),
	getClientDocuments: (params) =>
		http.get(endpoint.project.GET_CLIENT_DOCUMENTS, { params }).then(({ data }) => data),
	addClientDocument: (params) =>
		http.post(endpoint.project.ADD_CLIENT_DOCUMENT, params).then(({ data }) => data),
	deleteClientDocument: (params) =>
		http.put(endpoint.project.DELETE_CLIENT_DOCUMENT, params).then(({ data }) => data),
};
