import { FC } from 'react';
import { formatToTimezone } from '../utils';
import { ITopInfoBlockProps } from './types';

export const TopInfoBlock: FC<ITopInfoBlockProps> = ({ user }) => {
	return (
		<div className="line-info-block">
			<div className="line-info">
				<p className="line-info__name">User ID:</p>
				<p className="line-info__text">{user.provider.id}</p>
			</div>

			<div className="line-info ">
				<p className="line-info__name">Created date:</p>
				<p className="line-info__text">{formatToTimezone(user.provider.created_at * 1000)}</p>
			</div>

			<div className="line-info ">
				<p className="line-info__name">Last login:</p>
				<p className="line-info__text">
					{typeof user.provider.last_login === 'number'
						? formatToTimezone(user.provider.last_login * 1000)
						: '-'}
				</p>
			</div>
		</div>
	);
};
