import { FC, useEffect } from 'react';
import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import { getCurrenciesRequest } from 'redux/reducers/merchantAccounts/reducer';
import { useDispatch } from 'react-redux';
import TableFiat from '../TableFiat';
import TableCrypto from '../TableCrypto';

const ProjectWallets: FC = () => {
	const dispatch = useDispatch();
	const url_string = window.location.href;
	const url = new URL(url_string);
	const activeTab = url.searchParams.get('tabs') || 'crypto';

	useEffect(() => {
		dispatch(getCurrenciesRequest());
	}, [dispatch]);

	return (
		<ProjectContent>{activeTab === 'crypto' ? <TableCrypto /> : <TableFiat />}</ProjectContent>
	);
};

export default ProjectWallets;
