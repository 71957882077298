import { NoIcon, PencilWithLineIcon, YesIcon } from 'assets/inline-svg';
import { ChangeEvent, useState } from 'react';
import { formatAmount } from 'utils/numbers';
import { constUserTypes } from 'services/constants/user-types';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import useInputNum from 'hooks/useInputNum';
import { notify } from 'utils/notify';
import { useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { IProjectFinanceSettingsContentRowProps } from '../../../types';

const TableRow = ({
	item,
	handleInputs,
	updateBalance,
}: IProjectFinanceSettingsContentRowProps) => {
	const {
		id,
		currency,
		network_name,
		withdrawal_max_once,
		withdrawal_max_daily,
		withdrawal_max_monthly,
		withdraw_min,
		deposit_min,
		deposit_max,
		auto_exchange_to_currency_setting_id,
		is_active,
	} = item;

	const [edit, setEdit] = useState(false);
	const userType = useSelector(getUserType);
	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = currency.code ? getViewDecimal(currency.code) : undefined;

	const withdrawOnce = useInputNum(String(withdrawal_max_once));
	const withdrawDaily = useInputNum(String(withdrawal_max_daily));
	const withdrawMonthly = useInputNum(String(withdrawal_max_monthly));
	const depositMin = useInputNum(String(deposit_min));
	const depositMax = useInputNum(String(deposit_max));
	const withdrawMin = useInputNum(String(withdraw_min));

	const hahdleChack = () => {
		updateBalance?.({
			apiParams: {
				project_balance_id: String(id),
				withdrawal_max_once: withdrawOnce.value,
				withdrawal_max_daily: withdrawDaily.value,
				withdrawal_max_monthly: withdrawMonthly.value,
				withdraw_min: withdrawMin.value,
				deposit_min: depositMin.value,
				deposit_max: depositMax.value,
			},
			onSuccess: () => notify('Platform limitation changed successfully', 'success'),
		});
		setEdit(false);
	};
	return [
		{
			title: 'Currency',
			children: (
				<span className="token token--type2">
					<span className="token__icon">
						<img src={currency.img_path} alt={currency.name} />
					</span>
					<span className="token__name-wrap">
						<span className="token__name">{currency.name}</span> <br />
						<span className="token__transcription">
							{currency.code === network_name
								? currency.code.toUpperCase()
								: `${currency.code.toUpperCase()}, ${network_name.toUpperCase()}`}
						</span>
					</span>
				</span>
			),
		},
		{
			title: 'Withdrawal, Once',
			children: !edit ? (
				formatAmount(withdrawal_max_once, viewDecimals)
			) : (
				<div className="input input--mb-0 input--max-width-100px">
					<div className="input-wrapper">
						<input
							className="input-item input-item--type-td"
							type="text"
							placeholder="0"
							{...withdrawOnce}
						/>
					</div>
				</div>
			),
		},
		{
			title: 'Withdrawal, Daily',
			children: !edit ? (
				formatAmount(withdrawal_max_daily, viewDecimals)
			) : (
				<div className="input input--mb-0 input--max-width-100px">
					<div className="input-wrapper">
						<input
							className="input-item input-item--type-td"
							type="text"
							placeholder="0"
							{...withdrawDaily}
						/>
					</div>
				</div>
			),
		},
		{
			title: 'Withdrawal, Monthly',
			children: !edit ? (
				formatAmount(withdrawal_max_monthly, viewDecimals)
			) : (
				<div className="input input--mb-0 input--max-width-100px">
					<div className="input-wrapper">
						<input
							className="input-item input-item--type-td"
							type="text"
							placeholder="0"
							{...withdrawMonthly}
						/>
					</div>
				</div>
			),
		},
		{
			title: 'Deposit, MIN',
			children: !edit ? (
				formatAmount(deposit_min, viewDecimals)
			) : (
				<div className="input input--mb-0 input--max-width-100px">
					<div className="input-wrapper">
						<input
							className="input-item input-item--type-td"
							type="text"
							placeholder="0"
							{...depositMin}
						/>
					</div>
				</div>
			),
		},
		{
			title: 'Deposit, MAX',
			children: !edit ? (
				formatAmount(deposit_max, viewDecimals)
			) : (
				<div className="input input--mb-0 input--max-width-100px">
					<div className="input-wrapper">
						<input
							className="input-item input-item--type-td"
							type="text"
							placeholder="0"
							{...depositMax}
						/>
					</div>
				</div>
			),
		},
		{
			title: 'Withdrawal, MIN',
			children: !edit ? (
				formatAmount(withdraw_min, viewDecimals)
			) : (
				<div className="input input--mb-0 input--max-width-100px">
					<div className="input-wrapper">
						<input
							className="input-item input-item--type-td"
							type="text"
							placeholder="0"
							{...withdrawMin}
						/>
					</div>
				</div>
			),
		},
		{
			title: 'Activate',
			children: (
				<TableButtons right={userType === constUserTypes.MERCHANT}>
					{!edit ? (
						<div className="switch td-value">
							<label className="switch__label">
								<input
									id={String(id)}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										handleInputs && handleInputs(e, auto_exchange_to_currency_setting_id)
									}
									type="checkbox"
									name={String(is_active)}
									checked={!!is_active}
									className="hidden"
								/>
								<span className="switch__toggler" />
							</label>
						</div>
					) : (
						<div className="switch td-value">
							<label className="switch__label">
								<input
									id={String(id)}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										handleInputs && handleInputs(e, auto_exchange_to_currency_setting_id)
									}
									type="checkbox"
									name={String(is_active)}
									checked={!!is_active}
									className="hidden"
								/>
								<span className="switch__toggler" />
							</label>
						</div>
					)}
				</TableButtons>
			),
		},
		{
			condition: userType === constUserTypes.PROVIDER,
			title: 'Action',
			children: !edit ? (
				<TableButtons right>
					<button aria-label="button" type="button" onClick={() => setEdit && setEdit(true)}>
						<PencilWithLineIcon />
					</button>
				</TableButtons>
			) : (
				<TableButtons right>
					<button aria-label="button" type="button" onClick={hahdleChack}>
						<YesIcon />
					</button>
					<button aria-label="button" type="button" onClick={() => setEdit(false)}>
						<NoIcon />
					</button>
				</TableButtons>
			),
		},
	];
};

export default TableRow;
