import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import { Pagination } from 'UI/Pagination/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getReports, getReportsLoading } from 'redux/reducers/projectReports/selectors';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getReportsRequest } from 'redux/reducers/projectReports/reducer';
import { DownloadBlueIcon } from 'assets/inline-svg';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import Main from 'v2Components/layoutsComponents/Main';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import useFilterList from 'hooks/useFilterList';
import { getFilter } from 'redux/reducers/app/selectors';
import { IMerchantGetProjectReportsParams } from 'redux/reducers/projectReports/types';
import Table from 'v2Components/commomComponents/Table';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Button from 'v2Components/UI/Button';
import Filters from '../../Provider/AnalyticsPage/TabsContent/Reports/Filters/Filters';
import { getFilterProjectReportsHistory } from './filter.helper';
import TableRow from '../../Provider/AnalyticsPage/TabsContent/Reports/ReportsContent/TableRow';
import styles from './styles.module.scss';

export default () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const loading = useSelector(getReportsLoading);
	const reports = useSelector(getReports);
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [reportOpen, setReportOpen] = useState(false);
	const { projectId } = useParams<{ merchantId: string; projectId: string }>();
	const totalPages = reports?.last_page || 1;
	const [currentPage, setCurrentPage] = useState(1);
	const [showFilter, setShowFilter] = useState(false);
	const filterList = useFilterList(getFilterProjectReportsHistory);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('Reports');
	const onClearFilter = () => dispatch(clearFilter());
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));

	const onSubmit = (data: any) => {
		setCurrentPage(1);
		dispatch(
			setFilter({
				...data,
			}),
		);
		setShowFilter(false);
	};

	useEffect(() => {
		const params: IMerchantGetProjectReportsParams = {
			...filter,
			per_page: 15,
			project_id: projectId,
			current_page: currentPage,
		};
		dispatch(getReportsRequest({ params }));
	}, [dispatch, currentPage, projectId, filter]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<Main>
			<ProjectContent>
				<FilterWrapper
					filterList={filterList}
					deleteItemFilter={deleteItemFilter}
					onClearFilter={onClearFilter}
					onOpenFilter={() => setShowFilter(true)}
					className={styles.filters}
				/>
				<FilterForm
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					onSubmit={onSubmit}
					renderBodyForm={<Filters dateTo={endDate} dateFrom={startDate} />}
				/>
				<Table
					loading={loading}
					headerItems={tableHeaderItems}
					bodyItems={reports?.data || []}
					gridTemplateColumns={gridTemplateColumns}
					className={styles.table}
					getRow={TableRow}
				/>
				{totalPages > 1 && (
					<PaginationWrapper>
						<Pagination
							pageCount={totalPages}
							forcePage={currentPage}
							onPageChange={setCurrentPage}
						/>
					</PaginationWrapper>
				)}
			</ProjectContent>
		</Main>
	);
};
