import { IStoreState } from 'redux/types';

export const getProviderBankAccount = (state: IStoreState) => state.providerBankAccount;

export const getBankAccountsList = (state: IStoreState) =>
	getProviderBankAccount(state).bankAccountList;

export const getBankAccountNotDeleted = (state: IStoreState) =>
	getProviderBankAccount(state).bankAccountList?.filter((el) => el.is_deleted === 0);

export const getCryptoWalletList = (state: IStoreState) =>
	getProviderBankAccount(state).cryptoWalletList;

export const getBalancesInFiat = (state: IStoreState) =>
	getProviderBankAccount(state).balancesInFiat;
