import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import L from 'i18n-react';
import { Field, Form, Formik } from 'formik';
import { getUserType } from 'redux/reducers/auth/selectors';
import { getCountriesRequest } from 'redux/reducers/app/reducer';
import { getCountries } from 'redux/reducers/app/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { PhoneInputField } from 'UI/Forms/PhoneInputField/PhoneInputField';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { TextField } from 'UI/Forms/TextField/TextField';
import { RoleField } from 'v2Pages/Provider/AdministrationPage/Header/AddNewAdmin/AddNewAdminForm/RoleField/RoleField';
import { ICreateProjectProps } from '../types';
import PictureInput from './PictureInput.tsx/PictureInput';

// eslint-disable-next-line
const roles: any[] = [
	{
		code: 'inactive',
		name: 'Inactive',
	},
	{
		code: 'active',
		name: 'Active',
	},
];

const CreateMerchantProject: FC<ICreateProjectProps> = ({
	onSubmitRequest,
	valueState,
	setChecked,
}) => {
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);
	const countries = useSelector(getCountries);
	useEffect(() => {
		if (!countries) {
			dispatch(getCountriesRequest());
		}
	}, [countries, dispatch]);

	const validationSchema = yup.object().shape({
		projectName: yup
			.string()
			.required('First name is required')
			.min(2, 'Too Short!')
			.max(255, 'Too Long!')
			.matches(/^[a-zA-Z]+(([',. -_])?[a-zA-Z]*)*$/, `Invalid format - . _ ,'  `),
		role: yup.object().nullable().required('Choose the right field'),
		contractPersonFirstName: yup
			.string()
			.required('Company name is required')
			.min(2, 'Too Short!')
			.max(255, 'Too Long!')
			.matches(/^[a-zA-Z]+(([',. -_])?[a-zA-Z]*)*$/, `Invalid format - . _ ,'  `),
		contractPersonLastName: yup
			.string()
			.required('Contact last name is required')
			.min(2, 'Too Short!')
			.max(255, 'Too Long!')
			.matches(/^[a-zA-Z]+(([',. -_])?[a-zA-Z]*)*$/, `Invalid format - . _ ,'  `),
		email: yup
			.string()
			.required(String(L.translate('Validation.email_required')))
			.email(String(L.translate('Validation.email_format'))),
		phoneCode: yup.string().required('Phone code is required'),
		phoneNumber: yup
			.string()
			.when('phoneCode', (phoneCode, schema) => schema.phone(phoneCode, 'Phone is not valid'))
			.required('Phone number is required'),
		website: yup
			.string()
			.required('Website is required')
			.min(2, 'Too Short!')
			.max(247, 'Too Long!')
			.matches(
				/^http(s)?:\/\/[A-Za-z\d-]{1,63}\.[A-Za-z\d-]{1,63}(\.[A-Za-z\d-]{1,63})*\/?$/,
				'Invalid url format',
			),
		file: yup
			.mixed()
			// .required('Img is required')
			.test('fileSize', 'Your image is too big', (value) => {
				return !value || value?.size <= 5000000 || typeof value === 'string';
			})
			.test('fileSize', 'Only such formats are signed - jpg, png, gif', (value) => {
				return (
					!value ||
					['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(value?.type) ||
					typeof value === 'string'
				);
			}),
	});

	return (
		<Formik
			initialValues={valueState}
			validationSchema={validationSchema}
			onSubmit={onSubmitRequest}
		>
			{({ isSubmitting }) => (
				<Form className="main-content">
					<div className="mobile-project-info">
						<div className="header-project">
							<div className="header-project__name">
								<p>Provider</p>
							</div>
						</div>
					</div>
					{userType === constUserTypes.PROVIDER && (
						<button
							onClick={() => {
								setChecked(false);
							}}
							type="button"
							className="back-page"
						>
							<ArrowLeftIcon />
							<span>Back to Merchant management</span>
						</button>
					)}
					<div className="content-header">
						<p className="form-title">Create project</p>
					</div>
					<div className="fields-wrap">
						<div className="fields-item">
							<div className="fields-list">
								<TextField
									label="Project name"
									placeholder="Enter project name"
									name="projectName"
								/>

								<RoleField text="Project status" roles={roles} />

								<TextField
									label="Contact person first name"
									placeholder="Enter contact person first name"
									name="contractPersonFirstName"
								/>
								<TextField
									label="Contact person last name"
									placeholder="Enter contact person last name"
									name="contractPersonLastName"
								/>
								<TextField label="Email address" placeholder="Enter email address" name="email" />
								<PhoneInputField
									name="phoneNumber"
									codeFieldName="phoneCode"
									label="Phone"
									countries={countries || []}
								/>
								<TextField label="Website" type="text" placeholder="Enter URL" name="website" />
							</div>
						</div>
						<div className="fields-item fields-item--type3">
							<div>
								<Field name="file" component={PictureInput} />
							</div>
							<div className="fields-button fields-button--mt360">
								<Link className="link-create" to="/merchants">
									<button type="button" className="button button--type2">
										Cancel
									</button>
								</Link>
								<button type="submit" className="button">
									Create Project
								</button>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default CreateMerchantProject;
