import { FC } from 'react';
import { SelectField } from 'v2Components/formComponents/SelectField';
import Field from 'v2Components/UI/Field';
import { SearchIcon } from 'assets/inline-svg';
import { ROLES, PROJECTS, STATUSES } from '../filter.helper';
import styles from './styles.module.scss';
import { ISelect } from '../types';

interface IProps {
	projects?: Array<ISelect> | undefined;
}

export const Filters: FC<IProps> = ({ projects }) => {
	return (
		<div className={styles.table__filter}>
			<Field
				name="search"
				placeholder="Index"
				renderRightIcon={<SearchIcon />}
				classNameCustom={styles.search}
				classNameInputItem={styles.search__input}
				label="Index by name or email"
				isDebounce
			/>
			<SelectField
				options={projects ? [...PROJECTS, ...projects] : PROJECTS}
				title="Project"
				name="project"
			/>
			<SelectField options={ROLES} title="Role" name="role" />
			<SelectField options={STATUSES} title="Status" name="status" />
		</div>
	);
};
