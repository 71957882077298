import { FC, useEffect } from 'react';
import { ProviderAvatarIcon, TriangleArrowDownIcon } from 'assets/inline-svg';
import {
	getProviderApprovementsDefault,
	getApprovementsDefault,
} from 'redux/reducers/providerApprovements/selectors';
import { getProviderApprovements } from 'redux/reducers/providerProjectApprovements/selectors';
import { getWithdrawal, getWithdrawalFiat } from 'redux/reducers/projectWithdrawal/selectors';

import { useLocalStorage } from 'hooks/useLocalStorage';
import classNames from 'classnames';
import { useSelect } from 'hooks';
import { useSelector } from 'react-redux';
import { getUserData, getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { Link } from 'react-router-dom';
import { UserMenu } from './UserMenu/UserMenu';

export const UserInfo: FC = () => {
	const userData = useSelector(getUserData);
	const userType = useSelector(getUserType);
	const [withDr, setWithDr, removewithDr] = useLocalStorage('withdrawal', '');
	const [withPend, setPend, removePend] = useLocalStorage('pending', '');
	const [withApprovemen, setWithApprovemen, removewithApprovemen] = useLocalStorage(
		'approvement',
		'',
	);

	const approvementMerchant = useSelector(getProviderApprovementsDefault);
	const approvementProvider = useSelector(getApprovementsDefault);
	const approvements = useSelector(getProviderApprovements);
	const withdrawal = useSelector(getWithdrawal);
	const withdrawalDefaultFiat = useSelector(getWithdrawalFiat);

	const { open, toggleOpen, triggerRef, dropRef } = useSelect();

	useEffect(() => {
		if ((withdrawal && !withDr) || (withdrawalDefaultFiat && !withDr)) {
			const newData: any = withdrawal?.data || withdrawalDefaultFiat?.data;
			setWithDr(newData);
		}
		if (approvements && !withPend) {
			const newData: any = approvements.data;
			setPend(newData);
		}
		if (approvementMerchant || (approvementProvider && !withApprovemen)) {
			const newData: any = approvementMerchant?.data || approvementProvider?.data;
			setWithApprovemen(newData);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		withdrawal?.data,
		withdrawalDefaultFiat?.data,
		approvements,
		approvementMerchant,
		approvementProvider,
	]);

	return (
		<div className={classNames('header-admin-wrap', { active: open })}>
			{userType === constUserTypes.MERCHANT && (
				<Link to={`/merchant/${userData?.id || ''}`} className="header-admin__avatar">
					<ProviderAvatarIcon />
				</Link>
			)}
			<button type="button" className="header-admin" ref={triggerRef} onClick={toggleOpen}>
				{userType === constUserTypes.PROVIDER && (
					<span className="header-admin__avatar">
						<ProviderAvatarIcon />
					</span>
				)}
				<span className="header-admin__name">
					<span>{userData?.full_name || ''}</span>
					<span>{userData?.role?.name || ''}</span>
				</span>
				<span className="header-admin__arrow">
					<TriangleArrowDownIcon />
				</span>
			</button>
			<UserMenu menuRef={dropRef} onClose={toggleOpen} id={String(userData?.id)} />
		</div>
	);
};
