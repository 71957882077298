import { FC } from 'react';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { statusList } from 'components/Merchant/Orders/OrdersFilter/SelectStatus/dataStatus';
import { DatepickerField } from 'v2Components/formComponents/DatepickerField';
import { IPropsOrderFilters } from './types';

export const OrdersFilter: FC<IPropsOrderFilters> = ({ dateTo, dateFrom }) => {
	return (
		<>
			<SelectField title="Status" name="status" options={statusList} />
			<DatepickerField
				label="Date from"
				maxDate={(dateTo as Date) || null}
				name="date_from"
				inFilter
			/>
			<DatepickerField
				label="Date to"
				minDate={(dateFrom as Date) || null}
				name="date_to"
				inFilter
			/>
		</>
	);
};
