import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	TApiGetRolesResponse,
	IProviderTeamMembersParams,
	IProviderTeamMembersResponse,
	IApiGetUserProfileResponse,
	IApiGetUserProfileParams,
	IApiEditProviderProfileResponse,
} from 'services/api/provider/types';
import { constUserTypes } from 'services/constants/user-types';
import {
	addNewAdminError,
	addNewAdminMerchantRequest,
	addNewAdminRequest,
	addNewAdminSuccess,
	deleteProviderRequest,
	deleteProviderSuccess,
	deleteWhiteListIpRecordRequest,
	deleteWhiteListIpRecordSuccess,
	editProviderProfileRequest,
	editProviderProfileSuccess,
	editProviderRoleRequest,
	editProviderRoleSuccess,
	getProvidersToggleBlockRequest,
	getProvidersToggleBlockSuccess,
	getRolesError,
	getRolesRequest,
	getRolesSuccess,
	getUserProfileError,
	getUserProfileRequest,
	getUserProfileSuccess,
	teamMembersError,
	teamMembersRequest,
	teamMembersSuccess,
	teamNotProviderMembersRequest,
	updateTeamMembersRequest,
	updateTeamNotProviderMembersRequest,
} from './reducer';
import {
	IRoleString,
	IAddNewAdminPayload,
	IDeleteWhiteListIpRecordPayload,
	IEditProviderProfilePayload,
	IEditProviderRolePayload,
	IToggleProviderPayload,
	IDeleteProviderPayload,
} from './types';

function* administrationWorker(action: PayloadAction<IProviderTeamMembersParams>) {
	const { payload } = action;
	try {
		const data: IProviderTeamMembersResponse = yield call(
			api.provider.provider_team_members,
			payload,
		);
		yield put(teamMembersSuccess(data));
	} catch (error) {
		yield put(teamMembersError());
		showError(error);
	}
}

function* administrationMerchantWorker(action: PayloadAction<IProviderTeamMembersParams>) {
	const { payload } = action;
	try {
		const data: IProviderTeamMembersResponse = yield call(
			api.provider.merchant_team_members,
			payload,
		);
		yield put(teamMembersSuccess(data));
	} catch (error) {
		yield put(teamMembersError());
		showError(error);
	}
}

function* getRolesWorker({ payload }: PayloadAction<IRoleString>) {
	try {
		const data: TApiGetRolesResponse = yield call(api.provider.getRoles, payload);
		yield put(getRolesSuccess(data));
	} catch (error) {
		yield put(getRolesError());
		showError(error);
	}
}

function* getUserProfileWorker({ payload }: PayloadAction<IApiGetUserProfileParams>) {
	try {
		const data: IApiGetUserProfileResponse = yield call(api.provider.getUserProfile, payload);
		yield put(getUserProfileSuccess(data));
	} catch (error) {
		yield put(getUserProfileError());
		showError(error);
	}
}

function* editProviderProfileWorker(action: PayloadAction<IEditProviderProfilePayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		const data: IApiEditProviderProfileResponse = yield call(
			api.provider.editProviderProfile,
			apiParams,
		);
		yield put(editProviderProfileSuccess(data));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* editProviderRoleWorker({ payload }: PayloadAction<IEditProviderRolePayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.provider.editProviderRole, apiParams);
		yield put(editProviderRoleSuccess(payload));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteProviderRequestWorker(action: PayloadAction<IDeleteProviderPayload>) {
	const {
		payload: { apiParams, onSuccess },
	} = action;
	try {
		yield call(api.provider.deleteProvider, apiParams);
		yield put(deleteProviderSuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteWhiteListIpRecordWorker(action: PayloadAction<IDeleteWhiteListIpRecordPayload>) {
	const { apiParams, onSuccess, userType } = action.payload;
	try {
		yield userType === constUserTypes.PROVIDER
			? call(api.provider.deleteWhiteListIpRecord, apiParams)
			: call(api.provider.deleteWhiteListIpRecordMerchant, apiParams);
		yield put(deleteWhiteListIpRecordSuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* addNewAdminWorker({ payload }: PayloadAction<IAddNewAdminPayload>) {
	const { apiParams, onSuccess, onError } = payload;
	try {
		yield call(api.provider.addNewAdmin, apiParams);
		yield put(addNewAdminSuccess());
		onSuccess?.();
	} catch (error) {
		yield put(addNewAdminError());
		showError(error);
		onError?.();
	}
}

function* addNewAdminMerchantWorker({ payload }: PayloadAction<IAddNewAdminPayload>) {
	const { apiParams, onSuccess, onError } = payload;
	try {
		yield call(api.provider.addNewAdminMerchant, apiParams);
		yield put(addNewAdminSuccess());
		onSuccess?.();
	} catch (error) {
		yield put(addNewAdminError());
		showError(error);
		onError?.();
	}
}

function* providersToggleBlockWorker({ payload }: PayloadAction<IToggleProviderPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.provider.providerToggleBlock, apiParams);
		yield put(getProvidersToggleBlockSuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		yield put(addNewAdminError());
		showError(error);
	}
}

export function* administrationSaga() {
	yield takeLatest([teamMembersRequest, updateTeamMembersRequest], administrationWorker);
	yield takeLatest(
		[teamNotProviderMembersRequest, updateTeamNotProviderMembersRequest],
		administrationMerchantWorker,
	);
	yield takeLatest(getRolesRequest, getRolesWorker);
	yield takeLatest(getUserProfileRequest, getUserProfileWorker);
	yield takeLeading(editProviderProfileRequest, editProviderProfileWorker);
	yield takeLeading(editProviderRoleRequest, editProviderRoleWorker);
	yield takeLeading(deleteProviderRequest, deleteProviderRequestWorker);
	yield takeLeading(deleteWhiteListIpRecordRequest, deleteWhiteListIpRecordWorker);
	yield takeLeading(addNewAdminRequest, addNewAdminWorker);
	yield takeLeading(addNewAdminMerchantRequest, addNewAdminMerchantWorker);
	yield takeLeading(getProvidersToggleBlockRequest, providersToggleBlockWorker);
}
