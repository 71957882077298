import { useCallback, useState, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	getMerchantInvoices,
	getMerchantInvoicesLoading,
	getSaveLoading,
} from 'redux/reducers/merchantInvoices/selectors';
import {
	ICreateInvoicePayload,
	IDownloadCSVMerchantInvoicesPayload,
	IGetMerchantInvoicesPayload,
	ISendInvoicePayload,
} from 'redux/reducers/merchantInvoices/types';
import { TProviderInvoiceStatus } from 'services/api/provider/types';
import { dateToString } from 'utils/dates';
import {
	createMerchantInvoiceRequest,
	downloadCSVRequest,
	downloadMerchantInvoicePDFRequest,
	getInvoiceLinkRequest,
	getMerchantInvoicesRequest,
	sendMerchantInvoiceRequest,
} from 'redux/reducers/merchantInvoices/reducer';
import { notify } from 'utils/notify';
import { TVariantValue } from 'v2Pages/Project/ProjectInvoicesPage/types';
import { endpoint } from 'services/endpoint';
import { clearFilter } from 'redux/reducers/app/reducer';
import { getFilter } from 'redux/reducers/app/selectors';

import { InvoicesTable } from '../../Provider/InvoicesPage/InvoicesTable/InvoicesTable';
import { IValues } from '../../Provider/InvoicesPage/InvoicesTable/CreateInvoicePopup/CreateInvoiceForm/types';

const ProjectInvoicesPage: FC = () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const invoices = useSelector(getMerchantInvoices);
	const loading = useSelector(getMerchantInvoicesLoading);
	const { projectId } = useParams<{ projectId: string }>();
	const [currentPage, setCurrentPage] = useState(1);
	const [status, setStatus] = useState<string | null>(null);
	const [paidCurrency, setPaidCurrency] = useState<string | null>(null);
	const orderId = useParams<{ id: string }>();
	const [order, setOrder] = useState<string | null>(orderId.id || null);
	const [currency, setCurrency] = useState<string | null>(null);
	const [dateFrom, setDateFrom] = useState<Date | null>(null);
	const [dateTo, setDateTo] = useState<Date | null>(null);
	const [itemIdToSave, setItemIdToSave] = useState<number | null>(null);

	const saveLoading = useSelector(getSaveLoading);

	const fetchInvoices = useCallback(() => {
		const payload: IGetMerchantInvoicesPayload = {
			apiParams: {
				current_page: currentPage,
				project_id: projectId,
				status: (status as TProviderInvoiceStatus) || undefined,
				currency_request: currency || undefined,
				currency_paid: paidCurrency || undefined,
				order_id: order || undefined,
				date_from: dateFrom ? dateToString(dateFrom) : undefined,
				date_to: dateTo ? dateToString(dateTo) : undefined,
			},
		};
		dispatch(getMerchantInvoicesRequest(payload));
	}, [currency, currentPage, dateFrom, dateTo, dispatch, order, projectId, status, paidCurrency]);

	const handleCreateSubmit = (values: IValues, onFinally?: () => void) => {
		const {
			currencyRequest,
			amountRequest,
			currencyPaid,
			currencyPaidNetwork,
			rate,
			expiresTimeMinutes,
			clientEmail,
		} = values;
		if (
			!currencyRequest ||
			!amountRequest ||
			!currencyPaid ||
			!currencyPaidNetwork ||
			!expiresTimeMinutes ||
			!clientEmail
		) {
			notify('Not all fields are filled in', 'error');
			return;
		}
		const payload: ICreateInvoicePayload = {
			apiParams: {
				project_id: +projectId,
				currency_request: currencyRequest,
				amount_request: +amountRequest,
				currency_paid: currencyPaid,
				currency_paid_network: currencyPaidNetwork,
				rate: +(rate || 0),
				expires_time_minutes: +expiresTimeMinutes,
				client_email: clientEmail,
			},
			onSuccess: (id) => {
				notify('Invoice was successfully created', 'success');
				setItemIdToSave(id);
				fetchInvoices();
				onFinally?.();
			},
			onFinally,
		};
		dispatch(createMerchantInvoiceRequest(payload));
	};

	const handleSaveSubmit = (value: TVariantValue) => {
		if (itemIdToSave == null) {
			return;
		}
		switch (value) {
			case 'DOWNLOAD_IN_PDF': {
				dispatch(downloadMerchantInvoicePDFRequest({ apiParams: { invoice_id: itemIdToSave } }));
				break;
			}
			case 'SEND_PDF_TO_EMAIL': {
				const payload: ISendInvoicePayload = {
					apiParams: {
						type: 'pdf',
						invoice_id: itemIdToSave,
					},
					onSuccess: () => {
						notify('PDF invoice sent successfully', 'success');
					},
				};
				dispatch(sendMerchantInvoiceRequest(payload));
				break;
			}
			case 'GENERATE_LINK':
				dispatch(
					getInvoiceLinkRequest({
						apiParams: { invoice_id: itemIdToSave },
						onSuccess: (link) => {
							notify('Link to invoice generated successfully', 'success');
						},
					}),
				);
				break;
			case 'GENERATE_LINK_AND_SEND_TO_EMAIL': {
				const payload: ISendInvoicePayload = {
					apiParams: {
						type: 'link',
						invoice_id: itemIdToSave,
					},
					onSuccess: () => {
						notify('Link to invoice generated and sent successfully', 'success');
					},
				};
				dispatch(sendMerchantInvoiceRequest(payload));
				break;
			}
			default:
				break;
		}
	};

	const handleDownloadCSV = () => {
		const payload: IDownloadCSVMerchantInvoicesPayload = {
			nameFile: 'Merchant invoices',
			apiParams: {
				project_id: +projectId,
				status: (status as TProviderInvoiceStatus) || undefined,
				currency: currency || undefined,
				order_id: order || undefined,
				date_from: dateFrom ? dateToString(dateFrom) : undefined,
				date_to: dateTo ? dateToString(dateTo) : undefined,
			},
		};
		dispatch(downloadCSVRequest(payload));
	};

	const filterProps = {
		status,
		currency,
		dateFrom,
		dateTo,
		order,
		paidCurrency,
		onCurrencyPaid: setPaidCurrency,
		onStatusChange: setStatus,
		onCurrencyChange: setCurrency,
		onDateFromChange: setDateFrom,
		onDateToChange: setDateTo,
		onOrderChange: setOrder,
	};

	useEffect(() => {
		dispatch(
			getMerchantInvoicesRequest({
				apiParams: {
					...filter,
					project_id: projectId,
					current_page: currentPage,
				},
			}),
		);
	}, [currentPage, dispatch, filter, projectId]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<InvoicesTable
			invoices={invoices}
			isMerchant
			filterProps={filterProps}
			loading={loading}
			saveLoading={saveLoading}
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			itemIdToSave={itemIdToSave}
			setItemIdToSave={setItemIdToSave}
			onCreateSubmit={handleCreateSubmit}
			onSaveSubmit={handleSaveSubmit}
			downloadReportUrl={endpoint.merchant.DOWNLOAD_INVOICES_REPORT}
		/>
	);
};

export default ProjectInvoicesPage;
