import { IStoreState } from 'redux/types';

export const getProviderMerchant = (state: IStoreState) => state.providerMerchant;

export const getMerchantOwners = (state: IStoreState) => {
	return getProviderMerchant(state).merchantOwners;
};
export const getMerchantOwnersList = (state: IStoreState) => {
	return getMerchantOwners(state)?.data;
};

export const getMerchantOwnersListLoading = (state: IStoreState) =>
	getProviderMerchant(state)?.getMerchantOwnersLoading;

export const getMerchantOwnersPageCount = (state: IStoreState) => {
	return getMerchantOwners(state)?.last_page || 1;
};

export const postCreateMerchantAndProjectLoading = (state: IStoreState) =>
	getProviderMerchant(state).createMerchantAndProject;
