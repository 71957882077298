import { FC } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { routes } from 'routes/routes';
import {
	ExclamationRoundIcon,
	ProjectsIcon,
	SettingsMerchant,
	SecurityMerchant,
	ArrowLeftIcon,
	TransactionsIcon,
	Approvements,
} from 'assets/inline-svg';
import { useMerchantMatchUrl } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { getStatusBurger } from 'redux/reducers/app/selectors';
import classNames from 'classnames';
import { getBurgerReducer } from 'redux/reducers/app/reducer';

export const MerchantSidebar: FC = () => {
	const merchantUrl = useMerchantMatchUrl();
	const userType = useSelector(getUserType);
	const statusBurger = useSelector(getStatusBurger);
	const dispatch = useDispatch();

	const onBurger = () => {
		dispatch(getBurgerReducer(!statusBurger));
		window.scrollTo(0, 0);
	};

	return (
		<div className={classNames('sidebar', { 'sidebar-active': statusBurger })}>
			{userType === constUserTypes.MERCHANT && (
				<Link to={routes.merchantProjectList} className="back-page">
					<ArrowLeftIcon />
					<span>Back to projects</span>
				</Link>
			)}
			<nav className="sidebar-nav">
				<ul className="sidebar-nav__list">
					{/* <li>
						<NavLink to="/transactions">
							<span className="sidebar-nav-icon">
								<TransactionsIcon />
							</span>
							<span className="sidebar-nav-text">Index</span>
						</NavLink>
					</li> */}

					{userType === constUserTypes.PROVIDER && (
						<li>
							<NavLink
								onClick={onBurger}
								to={`${merchantUrl}${routes.oneMerchant.merchantProjects}`}
							>
								<span className="sidebar-nav-icon">
									<ProjectsIcon />
								</span>
								<span className="sidebar-nav-text">Projects</span>
							</NavLink>
						</li>
					)}

					<li>
						<NavLink onClick={onBurger} to={`${merchantUrl}${routes.oneMerchant.bankAccount}`}>
							<span className="sidebar-nav-icon">
								<TransactionsIcon />
							</span>
							<span className="sidebar-nav-text">Accounts</span>
						</NavLink>
					</li>

					<li>
						<NavLink onClick={onBurger} exact to={`${merchantUrl}${routes.oneMerchant.profile}`}>
							<span className="sidebar-nav-icon">
								<ExclamationRoundIcon />
							</span>
							<span className="sidebar-nav-text">Profile</span>
						</NavLink>
					</li>

					<li>
						<NavLink
							onClick={onBurger}
							to={`${merchantUrl}${routes.oneMerchant.approvalManagement}`}
						>
							<span className="sidebar-nav-icon">
								<Approvements />
							</span>
							<span className="sidebar-nav-text">Users</span>
						</NavLink>
					</li>

					<li>
						<NavLink onClick={onBurger} to={`${merchantUrl}${routes.oneMerchant.security}`}>
							<span className="sidebar-nav-icon">
								<SecurityMerchant />
							</span>
							<span className="sidebar-nav-text">Security</span>
						</NavLink>
					</li>

					<li>
						<NavLink onClick={onBurger} to={`${merchantUrl}${routes.oneMerchant.settings}`}>
							<span className="sidebar-nav-icon">
								<SettingsMerchant />
							</span>
							<span className="sidebar-nav-text">Settings</span>
						</NavLink>
					</li>
				</ul>
			</nav>
		</div>
	);
};
