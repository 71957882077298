export enum TransactionStatus {
	ACCEPTED = 'accepted',
	APPROVED = 'approved',
	BLOCKED = 'blocked',
	IN_APPROVEMENT = 'in_approvement',
	IN_PROGRESS = 'in_progress',
	LIMIT_EXCEEDED = 'limit_exceeded',
	PENDING = 'pending',
	REJECTED = 'rejected',
	RETURNED = 'returned',
	RISK_PROCESSING = 'risk_processing',
	SUCCESS = 'success',
	UNSUCCESSFUL = 'unsuccessful',
}
