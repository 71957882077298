import { useEffect, useState } from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDispatch, useSelector } from 'react-redux';
import {
	getProvidersToggleBlockRequest,
	teamMembersRequest,
} from 'redux/reducers/administration/reducer';
import { getTeamMembers, getTeamMembersLoading } from 'redux/reducers/administration/selectors';
import { IToggleProviderPayload } from 'redux/reducers/administration/types';
import { notify } from 'utils/notify';
import Table from 'v2Components/commomComponents/Table';
import { Pagination } from 'UI/Pagination/Pagination';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { Data } from 'services/api/provider/types';
import TableRow from './TableRow/TableRow';
import styles from './styles.module.scss';

const TeamMembers = () => {
	const dispatch = useDispatch();
	const items = useSelector(getTeamMembers);
	const loading = useSelector(getTeamMembersLoading);
	const { data = [], last_page: totalPages = 1 } = items || {};
	const [currentPage, setCurrentPage] = useState(1);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('TeamMembers');
	const isShowPagination = totalPages > 1 && !loading;
	const updateHandler = (_data: any) => console.log('Отправили', _data);
	const handlePageClick = (selected: number) => setCurrentPage(selected);
	const updateHandlerToggleBlock = (_data: any) => {
		const payload: IToggleProviderPayload = {
			apiParams: {
				..._data,
			},
			onSuccess: () => {
				notify(`${_data.is_block ? 'Block' : 'Unblock'} success`, 'success');
			},
		};
		dispatch(getProvidersToggleBlockRequest(payload));
	};

	useEffect(() => {
		dispatch(
			teamMembersRequest({
				current_page: currentPage,
			}),
		);
	}, [currentPage, dispatch]);
	return (
		<>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				gridTemplateColumns={gridTemplateColumns}
				className={styles.table}
				getRow={(item: Data) => TableRow({ item, updateHandler, updateHandlerToggleBlock })}
				bodyItems={data}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={handlePageClick}
					/>
				</PaginationWrapper>
			)}
		</>
	);
};

export default TeamMembers;
