import { FC } from 'react';
import logoImg from 'assets/styles/images/Logo.svg';
import { Link } from 'react-router-dom';

export const AuthHeader: FC = () => {
	return (
		<header className="header">
			<div className="header__container">
				<div className="header-left">
					<Link to="/login" className="logo">
						<img src={logoImg} alt="logo" />
					</Link>
				</div>
			</div>
		</header>
	);
};
