import { FC } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { IImagePreviewProps } from './types';

export const ImagePreview: FC<IImagePreviewProps> = ({ imageSrc, onClose }) => {
	return (
		<div className="image-preview">
			<button
				aria-label="button"
				className="image-preview__close-button"
				type="button"
				onClick={onClose}
			>
				<PopUpCloseIcon />
			</button>

			<img className="image_preview__image" src={imageSrc} alt="" />
		</div>
	);
};
