import { FC } from 'react';
import { TheeDotsHorizontalIcon } from 'assets/inline-svg';
import { IInvoiceTableRowActions } from './types';

export const InvoiceTableRowActions: FC<IInvoiceTableRowActions> = ({
	id,
	isMenuActive,
	onActionClick,
	onSave,
}) => {
	return (
		<>
			<div onClick={() => onActionClick?.(id)}>
				<TheeDotsHorizontalIcon />
			</div>
			{isMenuActive && (
				<div className="table-buttons-drop active">
					<button type="button" className="table-buttons-drop__item" onClick={() => onSave?.(id)}>
						Save invoice
					</button>
				</div>
			)}
		</>
	);
};
