import { HeaderAnalytics } from 'v2Pages/Project/ProjectAnalytics/HeaderAnalytics/HeaderAnalytics';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IProjectInfo } from 'redux/reducers/analyticsMerchant/types';
import { getProviderAnalyticsRequest } from 'redux/reducers/analyticsProvider/reducer';
import { getListProviderAnlyticsSelector } from 'redux/reducers/analyticsProvider/selectors';
import { ChartjsDoughnut } from 'UI/Chartjs/ChartjsDoughnut';
import { ChartjsLine } from 'UI/Chartjs/ChartjsLine';
import { CryptocurrencyTransactions } from './CryptocurrencyTransactions/CryptocurrencyTransactions';
import { TextAnalytics } from './TextAnalytics/TextAnalytics';
import styles from './styles.module.scss';

const dataSelectCharts = {
	provider_quantity_transactions: 1,
	provider_total_balances: 1,
	provider_commissions: 1,
	provider_total_new_counters: 1,
	provider_transaction_by_params: 1,
	balance_current: 1,
};

export const ProviderAnalytics: FC = () => {
	const [time, setTime] = useState<string>('7 Days');
	const [chart, setChart] = useState<{ [key: string]: number }>(dataSelectCharts);
	const listAnalytics = useSelector(getListProviderAnlyticsSelector);
	const dispatch = useDispatch();

	const handleSelects = (value: string, key?: string) => {
		const cloneVelue = { ...chart };

		if (key && !Number(value)) {
			delete cloneVelue[key];
		}

		if (key && +value) {
			setChart({ ...cloneVelue, [key]: +value });
			return;
		}

		key ? setChart(cloneVelue) : setTime(value);
	};
	useEffect(() => {
		dispatch(
			getProviderAnalyticsRequest({
				type_filter_time: time.toLowerCase().replaceAll(' ', '_'),
				...chart,
			}),
		);
	}, [dispatch, chart, time]);

	return (
		<>
			<HeaderAnalytics
				chart={chart}
				time={time}
				handleSelects={handleSelects}
				provider="provider"
			/>

			<div className="analytics-wrap">
				<div className="analytics-list analytics-list-custom ">
					<div className="analytics-item">
						<div className="analytics-item__title">
							<p>Number of business client transactions</p>
						</div>
						<div className="analytics-item__schedule">
							<ChartjsLine
								height={360}
								params={listAnalytics?.provider_quantity_transactions as Array<IProjectInfo>}
							/>
						</div>
					</div>
				</div>
				<div className="analytics-list analytics-list-custom">
					<div className="analytics-item">
						<div className="analytics-item__title">
							<p>Average balance of wallets/accounts of business clients</p>
						</div>
						<div className="analytics-item__schedule">
							<ChartjsLine
								height={360}
								params={listAnalytics?.provider_total_balances as Array<IProjectInfo>}
							/>
						</div>
					</div>
				</div>
			</div>
			<TextAnalytics
				providerCommissions={listAnalytics?.provider_commissions}
				providerTotalNewCounters={listAnalytics?.provider_total_new_counters}
				balanceCurrent={listAnalytics?.balance_current}
			/>
			<div className="report-table-title">
				<p>Cryptocurrency transactions</p>
			</div>
			<div className={styles.table}>
				<CryptocurrencyTransactions
					providerTransactionByParams={listAnalytics?.provider_transaction_by_params}
				/>
				<div className="report-table-schedule report-table-schedule--type2">
					<div className="report-frame">
						<div className="report-frame__item">
							<ChartjsDoughnut params={listAnalytics?.provider_transaction_by_coins} />
						</div>
						{/* <div className="report-frame__legend">
							<div className="report-frame__legend-item">
								<p>
									<svg
										width="12"
										height="12"
										viewBox="0 0 12 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<circle cx="6" cy="6" r="6" fill="#FFF101" />
									</svg>
									BTC, ERC20 16,4% / 100.000.000 USD
								</p>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
};
