import { FC, ChangeEvent } from 'react';

interface IProps {
	forgotPassword: () => void;
	handleInput: (event: ChangeEvent) => void;
	emailForgot: string;
}

export const ForgotPass: FC<IProps> = ({ forgotPassword, handleInput, emailForgot }) => {
	return (
		<div className="authorization-box">
			<div className="form form--authorization">
				<div className="form__header">
					<p className="form-title ">Reset password</p>
					<div className="form-text">
						<p>Input an email so we can send you the link to reset your password</p>
					</div>
				</div>
				<div className="form__body">
					<div className="input">
						<p className="input-name">Email</p>
						<div className="input-wrapper">
							<input
								value={emailForgot}
								onChange={handleInput}
								className="input-item"
								type="text"
								placeholder="Email"
							/>
						</div>
					</div>
				</div>
				<div className="form-footer">
					<button onClick={forgotPassword} type="button" className="button button--full-width">
						Reset Password
					</button>
				</div>
			</div>
		</div>
	);
};
