import { useParams, useHistory } from 'react-router-dom';
import { notify } from 'utils/notify';
import { trimLastZeros } from 'utils/strings';
import { isExist } from 'utils/numbers';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { CopyAddress } from 'v2Components/commomComponents/Table/CellComponents';
import { IMerchantTableItemProps } from './types';
import styles from './styles.module.scss';

const TableRow = ({ item }: IMerchantTableItemProps) => {
	const {
		merchantId,
		projectId,
		id: ClientId,
	} = useParams<{ merchantId: string; projectId: string; id: string }>();
	const history = useHistory();
	const { getViewDecimal } = useViewDecimals();

	const { address, type, balance, currency_network, client_id, id, currency, address_url } = item;
	const viewDecimals = currency?.code
		? getViewDecimal(currency?.code, currency_network)
		: undefined;
	const handleClick = () =>
		client_id &&
		history.push(
			`/merchant/${merchantId}/project/${projectId}/wallet-addresses/${String(client_id)}`,
		);
	const handleAddressCopy = () => notify('Address copied successfully!', 'info');
	return [
		{
			title: 'Address ID',
			children: id || '---',
		},
		{
			title: 'Client ID',
			children: (
				<span
					onClick={handleClick}
					style={{
						pointerEvents: client_id ? 'auto' : 'none',
						cursor: client_id ? 'pointer' : 'none',
					}}
					className={`link ${client_id ? '' : 'unactive--color'}`}
				>
					{client_id || '---'}
				</span>
			),
		},
		{
			title: 'Address number',
			children: <CopyAddress value={address} href={address_url} size={15} />,
		},
		{
			title: 'Currency',
			children: currency?.code?.toLocaleUpperCase(),
		},
		{
			title: 'Network',
			children: currency_network?.toLocaleUpperCase(),
		},
		{
			title: 'Address type',
			children: type,
		},
		{
			title: 'Address balance',
			children: (
				<div className={styles.right}>
					{isExist(balance) && trimLastZeros(balance.toFixed(viewDecimals))}
				</div>
			),
		},
	];
};

export default TableRow;
