import { FC } from 'react';
import successImg from 'assets/styles/images/success.png';
import { Link } from 'react-router-dom';

export const AccountCreateSuccess: FC = () => {
	return (
		<div className="authorization-box">
			<div className="form form--authorization">
				<div className="form__header">
					<div className="form-img">
						<img src={successImg} alt="success" />
					</div>
					<p className="form-title form-title--smaller form-title--ta-center">Success</p>
					<div className="form-text form-text--ta-center ">
						<p>Account created successfully. Please log in.</p>
					</div>
				</div>

				<div className="form-footer form-footer--less-mt">
					<Link to="/login" className="button button--full-width">
						Log in
					</Link>
				</div>
			</div>
		</div>
	);
};
