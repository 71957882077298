/* eslint-disable @typescript-eslint/no-use-before-define */

import moment from 'moment/moment';

export const toTimezone = (date?: number | Date | string) => {
	const localeString = timezoneFormat.format(toIntFormatParam(date));

	const [leftPart, time] = localeString.split(', ');
	const [dd, mm, yyyy] = leftPart.split('/');

	return `${dd}.${mm}.${yyyy}, ${time}`;
};

export const toTimezoneH12 = (date?: number | Date | string) => {
	const localeString = timezoneFormatH12.format(toIntFormatParam(date));

	const [leftPart, rightPart] = localeString.split(', ');
	const [dd, mm, yyyy] = leftPart.split('/');
	const [time, dayPart] = rightPart.split(' ');

	return `${dd}.${mm}.${yyyy}, ${time} ${dayPart?.toUpperCase()}`;
};

export const toTimezoneDate = (date?: number | Date | string) => {
	const localeString = timezoneDateFormat.format(toIntFormatParam(date));

	const [dd, mm, yyyy] = localeString.split('/');

	return `${dd}.${mm}.${yyyy}`;
};

/**
 * Именно эта функция переводит дату в UTC формат
 * P.S. остальные ниже - несмотря на название, возвращают строку а не дату
 * @param date {Date}
 * @returns {Date}
 */
export const dateToUTC = (date: Date) => {
	const year = date.getUTCFullYear();
	const month = date.getUTCMonth();
	const day = date.getUTCDate();
	const hour = date.getUTCHours();
	const minutes = date.getUTCMinutes();
	const seconds = date.getUTCSeconds();
	return new Date(year, month, day, hour, minutes, seconds);
};

export const toUTC = (date?: number | Date | string, br?: boolean, withSecond?: boolean) => {
	const localeString = (withSecond ? utcFormatWithSec : utcFormat).format(toIntFormatParam(date));

	const [leftPart, rightPart] = localeString.split(', ');
	const [dd, mm, yyyy] = leftPart.split('/');
	// const tagBr = '<br/>';
	return br ? `${dd}.${mm}.${yyyy}, ${rightPart}` : `${dd}.${mm}.${yyyy}, ${rightPart}`;
};

export const toUTCDate = (date?: number | Date | string) => {
	const localeString = utcFormat.format(toIntFormatParam(date));

	const [leftPart] = localeString.split(', ');
	const [dd, mm, yyyy] = leftPart.split('/');

	return `${dd}.${mm}.${yyyy}`;
};
export const toUTCTime = (date?: number | Date | string) => {
	const localeString = utcFormat.format(toIntFormatParam(date));

	const [rightPart] = localeString.split(', ');

	return `${rightPart}`;
};

export const fromBackFormat = (date: number | string) => {
	if (typeof date === 'number') {
		return date * 1000;
	}
	return date;
};

// Helpers

const timezoneFormat = createDateTimeFormat();
const timezoneFormatH12 = createDateTimeFormat({ hourCycle: 'h12' });
const timezoneDateFormat = createDateFormat();
const utcFormat = createDateTimeFormat({ timeZone: 'UTC', timeZoneName: 'short' });
// @TODO: Временный вариант
const utcFormatWithSec = createDateTimeFormat({
	timeZone: 'UTC',
	timeZoneName: 'short',
	second: '2-digit',
});

function createDateTimeFormat(additionalOptions: Intl.DateTimeFormatOptions = {}) {
	return Intl.DateTimeFormat('en-GB', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: 'numeric',
		minute: '2-digit',
		...additionalOptions,
	});
}

function createDateFormat(additionalOptions: Intl.DateTimeFormatOptions = {}) {
	return Intl.DateTimeFormat('en-GB', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		...additionalOptions,
	});
}

function toIntFormatParam(date?: number | Date | string) {
	if (typeof date === 'string') {
		return Date.parse(date);
	}
	return date;
}

export const dateToString: (date: Date) => string = (date) => {
	const dateStr = date.toLocaleString('en-US', { hour12: false });
	const [datePart, timePart] = dateStr.split(',');
	// eslint-disable-next-line prefer-const
	let [mm, dd, yy] = datePart.split('/');
	mm = mm.length === 1 ? `0${mm}` : mm;
	dd = dd.length === 1 ? `0${dd}` : dd;
	return `${yy}-${mm}-${dd} ${timePart.trim()}`;
};

export const createDateFormatUTC = (date?: string | null) => date && toUTC(date);
export const createFullDateFormatUTC = (date?: string | null) =>
	`${moment(date).utc().format('DD.MM.YYYY, HH:mm')} UTC`;
