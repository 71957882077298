import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TrashIcon } from 'assets/inline-svg';
import { toUTC } from 'utils/dates';
import { notify } from 'utils/notify';
import { useDispatch } from 'react-redux';
import {
	deleteMerchantOwnerRequest,
	toggleMerchantOwnerBlockRequest,
} from 'redux/reducers/providerMerchant/reducer';
import {
	IDeleteMerchantOwnerPayload,
	IToggleMerchantOwnerBlockPayload,
} from 'redux/reducers/providerMerchant/types';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import SwitchCheckbox from 'UI/SwitchCheckbox';
import { IMerchantTableItemProps } from './types';
import styles from './styles.module.scss';
import { DeactivateAccount } from './DeactivateAccount/DeactivateAccount';
import DeleteMerchantPopup from './DeleteMerchantPopup';

export const TableRow = ({ item, onRefreshMerchants }: IMerchantTableItemProps): TdItem[] => {
	const dispatch = useDispatch();
	const [isModal, setIsModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const { is_block } = item;

	const closeModal = () => setIsModal(false);

	const toggleBlockRequest = (isBlock: boolean) => {
		const blockMsg = 'User was deactivated successfully';
		const unblockMsg = 'User was activated successfully';

		const params: IToggleMerchantOwnerBlockPayload = {
			apiParams: { id: item.merchant_id, is_block: isBlock ? 1 : 0 },
			onSuccess: () => {
				closeModal();
				notify(isBlock ? blockMsg : unblockMsg, 'success');
				onRefreshMerchants();
			},
		};
		dispatch(toggleMerchantOwnerBlockRequest(params));
	};

	const handleBlockToggle = () => {
		if (is_block) {
			toggleBlockRequest(false);
			return;
		}
		setIsModal(true);
	};

	const handleDeactivateAccount = () => {
		toggleBlockRequest(true);
	};

	const closeDeletePopUp = () => {
		setDeleteModal(false);
	};

	const openDeletePopup = () => {
		setDeleteModal(true);
	};

	const handleDeleteAccount = () => {
		const params: IDeleteMerchantOwnerPayload = {
			apiParams: { id: item.merchant_id },
			onSuccess: () => {
				notify('Account was deleted successfully', 'success');
				onRefreshMerchants();
			},
		};
		dispatch(deleteMerchantOwnerRequest(params));
	};

	return [
		{
			title: 'Id',
			children: (
				<Link to={`/merchant/${item.merchant_id}/projects`} className="link">
					{item.merchant_id}
				</Link>
			),
		},
		{
			title: 'ID Mecrchant',
			children: item.id_merchant,
		},
		{
			title: 'Email',
			children: item.email,
		},
		{
			title: 'User Name',
			children: item.full_name,
		},
		{
			title: 'Phone number',
			children: item.phone_number,
		},
		{
			title: 'Last login',
			children: typeof item.last_login === 'number' ? toUTC(item.last_login * 1000) : '-',
		},
		{
			title: 'Block/Unblock',
			children: (
				<div className={styles.center}>
					<SwitchCheckbox
						value={Boolean(+is_block === 0)}
						handleSwitchToggle={handleBlockToggle}
						defaultChecked={Boolean(Number(is_block) === 1 ? 0 : 1)}
					>
						{+is_block ? 'Block' : 'Unblock'}
					</SwitchCheckbox>
				</div>
			),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<button aria-label="button" type="button" onClick={openDeletePopup}>
						<TrashIcon />
					</button>
					<PopupModal open={isModal} onClose={closeModal}>
						<DeactivateAccount onDeactivate={handleDeactivateAccount} onClose={closeModal} />
					</PopupModal>

					<PopupModal open={deleteModal} onClose={closeDeletePopUp}>
						<DeleteMerchantPopup onSubmit={handleDeleteAccount} onClose={closeDeletePopUp} />
					</PopupModal>
				</TableButtons>
			),
		},
	];
};
