import { IStoreState } from 'redux/types';

export const getOrdersProvider = (state: IStoreState) => state.providerOrders;

export const getOrdersListSelector = (state: IStoreState) => getOrdersProvider(state).ordersList;
export const getOrdersListSelectorLoading = (state: IStoreState) =>
	getOrdersProvider(state).ordersListLoading;
export const getOrderSelector = (state: IStoreState) => getOrdersProvider(state).order;
export const getOrderInvoiceIdSelector = (state: IStoreState) =>
	getOrdersProvider(state).orderInvoice?.id;
