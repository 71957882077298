import { ReactNode } from 'react';
import { trimLastZeros } from 'utils/strings';
import { useViewDecimals } from 'hooks/useViewDecimals';
import styles from './styles.module.scss';

interface IApprovementUser {
	children: ReactNode;
	label: string;
	condition?: boolean;
}

interface ICurrencyItem {
	name: string;
	code?: string;
}

export interface ICurrency {
	from: ICurrencyItem;
	to: ICurrencyItem;
}
interface IProps {
	currency?: ICurrency;
	items: (number | null | undefined)[];
}
const ApprovementUser = ({ children, label, condition }: IApprovementUser) => {
	if (condition) {
		return null;
	}

	return (
		<div className={styles.user}>
			<p className={styles.user__text}>{label}:</p>
			<div className={styles.user__value}>{children}</div>
		</div>
	);
};

export default ({ currency, items }: IProps) => {
	const { getViewDecimal } = useViewDecimals();
	const viewDecimalsFrom = currency?.from.code ? getViewDecimal(currency?.from.code) : 0;
	const viewDecimalsTo = currency?.to.code ? getViewDecimal(currency?.to.code) : 0;
	const users: IApprovementUser[] = items.map((item, index) => ({
		condition: typeof item === 'number',
		label: `${index < 2 ? 'Destination' : 'Origin'} account/wallet balance “${
			index % 2 ? 'after' : 'before'
		}” transaction`,
		children: (
			<p>{`${trimLastZeros(Number(item).toFixed(index < 2 ? viewDecimalsTo : viewDecimalsFrom))} ${
				(index < 2 ? currency?.to.name : currency?.from.name) || ''
			}`}</p>
		),
	}));
	return (
		<div className={styles.users}>
			{users.map((user) => (
				<ApprovementUser key={user.label} label={user.label}>
					{user.children}
				</ApprovementUser>
			))}
		</div>
	);
};
