import { FC, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
	downloadMerchantInvoicePDFRequest,
	sendMerchantInvoiceRequest,
	getInvoiceLinkRequest,
} from 'redux/reducers/merchantInvoices/reducer';
import { getOrdersMerchantRequest } from 'redux/reducers/merchantOrders/reducer';
import {
	getOrderInvoiceIdSelector,
	getOrdersListSelector,
	getOrdersListSelectorLoading,
} from 'redux/reducers/merchantOrders/selectors';
import { ISendInvoicePayload } from 'redux/reducers/providerInvoices/types';
import { Pagination } from 'UI/Pagination/Pagination';
import { notify } from 'utils/notify';
import { TVariantValue } from 'v2Pages/Project/ProjectInvoicesPage/types';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { OrdersFilter } from 'v2Pages/Provider/OrdersPage/Orders/OrdersFilter/OrdersFilter';
import useFilterList from 'hooks/useFilterList';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { getFilter } from 'redux/reducers/app/selectors';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import { getFilterInvoices } from 'v2Pages/Provider/InvoicesPage/InvoicesTable/filter.helper';
import Main from 'v2Components/layoutsComponents/Main';
import Table from 'v2Components/commomComponents/Table';
import { IOrdersResponse } from 'redux/reducers/merchantOrders/types';
import { ReportDownloadButton } from 'v2Components/layoutsComponents/ReportDownloadButton';
import { endpoint } from 'services/endpoint';
import styles from './styles.module.scss';
import TableRow from './TableRow';

export const Orders: FC = () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const ordersList = useSelector(getOrdersListSelector);
	const loading = useSelector(getOrdersListSelectorLoading);
	const [currentPage, setCurrentPage] = useState(1);
	const totalPages = ordersList?.last_page || 1;
	const [dateFrom, setDateFrom] = useState<Date | null>(null);
	const [dateTo, setDateTo] = useState<Date | null>(null);
	const [status, setStatus] = useState<string>('All status');
	const { projectId } = useParams<{ projectId: string }>();
	const itemIdToSave = useSelector(getOrderInvoiceIdSelector);
	const [showFilter, setShowFilter] = useState(false);
	const filterList = useFilterList(getFilterInvoices);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('ProjectOrders');
	const onClearFilter = () => dispatch(clearFilter());
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const handleSelectDate = (value: [Date | null, Date | null]) => {
		const [_startDate, _endDate] = value;
		setDateFrom(_startDate);
		setDateTo(_endDate);
	};

	const handleSaveSubmit = (value: TVariantValue) => {
		if (itemIdToSave == null) {
			return;
		}
		switch (value) {
			case 'DOWNLOAD_IN_PDF': {
				dispatch(downloadMerchantInvoicePDFRequest({ apiParams: { invoice_id: itemIdToSave } }));
				break;
			}
			case 'SEND_PDF_TO_EMAIL': {
				const payload: ISendInvoicePayload = {
					apiParams: {
						type: 'pdf',
						invoice_id: itemIdToSave,
					},
					onSuccess: () => {
						notify('PDF invoice sent successfully', 'success');
					},
				};
				dispatch(sendMerchantInvoiceRequest(payload));
				break;
			}
			case 'GENERATE_LINK':
				dispatch(
					getInvoiceLinkRequest({
						apiParams: { invoice_id: itemIdToSave },
						onSuccess: (link) => {
							notify('Link to invoice generated successfully', 'success');
						},
					}),
				);
				break;
			case 'GENERATE_LINK_AND_SEND_TO_EMAIL': {
				const payload: ISendInvoicePayload = {
					apiParams: {
						type: 'link',
						invoice_id: itemIdToSave,
					},
					onSuccess: () => {
						notify('Link to invoice generated and sent successfully', 'success');
					},
				};
				dispatch(sendMerchantInvoiceRequest(payload));
				break;
			}
			default:
				break;
		}
	};

	const fetchOrders = useCallback(() => {
		dispatch(
			getOrdersMerchantRequest({
				date_from: dateFrom,
				date_to: dateTo,
				status: status?.toLowerCase().replaceAll('_', ' ').includes('All status'.toLowerCase())
					? undefined
					: status,
				current_page: currentPage,
				project_id: projectId,
			}),
		);
	}, [dispatch, currentPage, status, dateTo, dateFrom, projectId]);

	const onSubmit = (data: any) => {
		dispatch(
			setFilter({
				...data,
				current_page: 1,
			}),
		);
		setShowFilter(false);
	};
	const downloadReportUrl = endpoint.merchant.DOWNLOAD_ORDERS_REPORT;

	useEffect(() => {
		dispatch(
			getOrdersMerchantRequest({
				...filter,
				current_page: 1,
				project_id: projectId,
			}),
		);
	}, [dispatch, filter, projectId]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<Main
			header={
				<div className={styles.header}>
					<ReportDownloadButton downloadReportUrl={downloadReportUrl} />
				</div>
			}
		>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
				className={styles.filters}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<OrdersFilter dateTo={dateTo} dateFrom={dateFrom} />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				gridTemplateColumns={gridTemplateColumns}
				bodyItems={ordersList?.data || []}
				getRow={(order: IOrdersResponse) =>
					TableRow({
						order,
						itemIdToSave,
						isMerchant: true,
						onSaveSubmit: handleSaveSubmit,
						onRefetch: fetchOrders,
					})
				}
			/>
			{totalPages > 1 && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</PaginationWrapper>
			)}
		</Main>
	);
};
