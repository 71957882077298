import { FC, useState, useEffect, ChangeEvent } from 'react';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { notify } from 'utils/notify';
import { useDispatch, useSelector } from 'react-redux';
import {
	IProviderStatusRequest,
	IProviderCommentRequest,
} from 'redux/reducers/providerApprovements/types';
import { getProviderApprovementDetails } from 'redux/reducers/providerApprovements/selectors';
import {
	putApprovementStatusRequest,
	getApprovementDetailsRequest,
	setCheckedIds,
	postApprovementCommentRequest,
} from 'redux/reducers/providerApprovements/reducer';
import Main from 'v2Components/layoutsComponents/Main';
import { ApprovementComments } from './ApprovementComments/ApprovementComments';
import { Select } from '../Select/Select';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import Header from '../../../Header/Header';
import { PopUpRefun } from '../../../PopUpRefun';
import { CommentPopup } from '../../../CommentPopup/CommentPopup';
import { ApprovementList } from './ApprovementList/ApprovementList';
import { ApprovementsStatus } from './ApprovementsStatus/ApprovementsStatus';

export const ApprovementsDetailsProvider: FC = () => {
	const dispatch = useDispatch();
	const [filters, setFilters] = useState({
		status: '',
		name: '',
	});
	const details = useSelector(getProviderApprovementDetails);
	const url_string = window.location.href;
	const urlObg = new URL(url_string);
	const matchPath = `${urlObg.pathname.split('/')[urlObg.pathname.split('/').length - 1]}`;
	const [isCommnetModal, setIsCommnetModal] = useState(false);
	const [isModal, setIsModal] = useState(false);
	const closeCommentModal = () => setIsCommnetModal(false);
	const [isOpenModal, setIsOpenModal] = useState<string>('');
	const [comment, setComment] = useState('');
	const closeModal = () => setIsModal(false);
	const changeFilter = (value: string, name: string) => {
		setIsOpenModal(value);
		setFilters({ ...filters, status: value, name });
	};

	const requestDetails = () => {
		dispatch(getApprovementDetailsRequest(String(matchPath)));
	};

	useEffect(() => {
		dispatch(setCheckedIds([Number(matchPath)]));
		requestDetails();
		/* eslint-disable-next-line */
	}, [dispatch, matchPath]);

	const onAction = (event: React.MouseEvent) => {
		const { name } = event.target as HTMLButtonElement;
		setIsOpenModal(name);
	};

	const handleConfirm = () => {
		if (filters.status === 'refound') return;
		const payloadData: IProviderStatusRequest = {
			params: { id: Number(matchPath), status: filters.status },
			onSuccess: () => {
				notify('make request', 'success');
			},
		};
		closeModal();
		dispatch(putApprovementStatusRequest(payloadData));
	};

	const handleAddCommnet = () => {
		const payload: IProviderCommentRequest = {
			payloadApi: { transaction_approvement_id: Number(matchPath), comment },
			onSuccess: () => {
				notify('add comment', 'success');
				setIsCommnetModal(false);
				requestDetails();
			},
		};
		dispatch(postApprovementCommentRequest(payload));
	};

	return (
		<Main
			header={
				<>
					<button type="button" onClick={() => window.history.go(-1)} className="back-page">
						<ArrowLeftIcon />
						<span>Back to Approvements</span>
					</button>
					<Header title="Tx Approvements" classHeader="content-header--mb-32" />
				</>
			}
		>
			{isOpenModal === 'refound' && (
				<PopUpRefun
					onAction={onAction}
					valueAmount={String(details?.transaction?.amount)}
					code={details?.transaction?.currency?.code}
					id={Number(matchPath)}
				/>
			)}
			<Select
				options={[
					{ value: 1, label: 'Returned', code: 'refound' },
					{ value: 2, label: 'Approved', code: 'approved' },
					{ value: 3, label: 'Blocked', code: 'blocked' },
				]}
				sleceted={filters.status}
				setSelect={(value: string, name: string) => changeFilter(value, name)}
				selectWd="select--width-540"
				selectMb="select__drop--width-240"
			/>
			<button onClick={() => setIsModal(true)} className="button" type="button">
				Save change
			</button>
			{details && <ApprovementList info={details?.transaction} />}
			<div className="approvements-title">
				<p>Status history</p>
			</div>
			<div className="approvements-list">
				<div className="approvements-column">
					{details &&
						details?.status_history?.map((item) => (
							<ApprovementsStatus key={item.id} statusInfo={item} />
						))}
				</div>
			</div>
			<div className="approvements-title">
				<p>Transaction description/comment</p>
			</div>
			{details?.comments?.length ? (
				details?.comments?.map((item) => <ApprovementComments key={item.id} commnetInfo={item} />)
			) : (
				<div className="approvements-user approvements-user--mb-24">No comments yet</div>
			)}
			<button onClick={() => setIsCommnetModal(true)} className="button" type="button">
				+ Add comment
			</button>
			<PopupModal open={isCommnetModal} onClose={closeModal}>
				<CommentPopup
					onClose={closeCommentModal}
					onConfirm={handleAddCommnet}
					comment={comment}
					setComment={(e: ChangeEvent<HTMLTextAreaElement>) => setComment(e.currentTarget.value)}
					title="Add comment"
				/>
			</PopupModal>
			<PopupModal open={isModal} onClose={closeModal}>
				<ConfirmPopup
					title="Confirm status change"
					body="Confirm this action to change status"
					onConfirm={handleConfirm}
					onClose={closeModal}
				/>
			</PopupModal>
			;
		</Main>
	);
};
