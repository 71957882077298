import { PlusBlueIcon } from 'assets/inline-svg';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUserType } from 'redux/reducers/auth/selectors';
import { useState } from 'react';
import {
	IPayloadRequset,
	TApiGetIApiMerchantApprovalManagementParamsPayload,
} from 'redux/reducers/merchantApprovalManagement/types';
import { notify } from 'utils/notify';
import { constUserTypes } from 'services/constants/user-types';
import {
	addNewUserMerchantRequest,
	addNewUserRequest,
	updateMerchantApprovalManagementRequest,
	updateMerchantApprovalRequest,
} from 'redux/reducers/merchantApprovalManagement/reducer';
import Button from 'v2Components/UI/Button';
import { TAddNewUserFormOnSubmit } from '../../../../Merchant/MerchantApprovalManagement/TabsContent/TeamMembers/Header/AddNewUser/AddNewUserForm/types';
import { AddNewUser } from '../../../../Merchant/MerchantApprovalManagement/TabsContent/TeamMembers/Header/AddNewUser/AddNewUser';
import styles from './styles.module.scss';

const Header = () => {
	const dispatch = useDispatch();
	const { merchantId } = useParams<{ merchantId: string }>();
	const userType = useSelector(getUserType);
	const [isAdminModal, setIsAdminModal] = useState(false);

	const closeAdminModal = () => setIsAdminModal(false);
	const handleAddNewAdmin: TAddNewUserFormOnSubmit = (values, { setSubmitting }) => {
		const { email, firstName, role, project, lastName } = values;

		const payload: IPayloadRequset = {
			apisParams: {
				email,
				first_name: firstName,
				last_name: lastName,
				role: role?.code as string,
				project_id: Number(project?.id),
				merchant_owner_id: Number(merchantId),
			},
			onError: () => setSubmitting(false),
			onSuccess: () => {
				closeAdminModal();
				notify('User add', 'success');

				// eslint-disable-next-line @typescript-eslint/no-shadow
				const payload: TApiGetIApiMerchantApprovalManagementParamsPayload = {
					apiParams: {
						merchant_owner_id: merchantId,
						per_page: 15,
						current_page: 1,
					},
				};
				userType === constUserTypes.PROVIDER
					? dispatch(updateMerchantApprovalManagementRequest(payload))
					: dispatch(updateMerchantApprovalRequest(payload));
			},
		};
		userType === constUserTypes.PROVIDER
			? dispatch(addNewUserRequest(payload))
			: dispatch(addNewUserMerchantRequest(payload));
	};
	return (
		<div className={styles.header}>
			<Button onClick={() => setIsAdminModal(true)} className={styles.header__button}>
				<PlusBlueIcon /> Add a new users
			</Button>
			<PopupModal open={isAdminModal} onClose={closeAdminModal}>
				<AddNewUser onSubmit={handleAddNewAdmin} onClose={closeAdminModal} />
			</PopupModal>
		</div>
	);
};

export default Header;
