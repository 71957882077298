import { FC, useState } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import Crop from 'UI//CropElement/CropElement';
import { ICropModal } from './types';

const CropModal: FC<ICropModal> = ({ image, onClose, onSave }) => {
	const [cropData, setCropData] = useState('');

	const handleSave = () => {
		if (!cropData) {
			return;
		}
		onSave(cropData);
	};

	return (
		<div className="popup">
			<button aria-label="button" type="button" className="popup__close" onClick={onClose}>
				<PopUpCloseIcon />
			</button>
			<Crop image={image} cropData={cropData} setCropData={setCropData} />
			<div className="popup-button popup-button--type2">
				<button type="button" className="button button--full-width button--type2" onClick={onClose}>
					Cancel
				</button>
				<button
					type="button"
					className="button button--full-width"
					disabled={!cropData}
					onClick={handleSave}
				>
					Save
				</button>
			</div>
		</div>
	);
};

export default CropModal;
