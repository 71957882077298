import { FC, useEffect } from 'react';
import { CopyIcon, PopUpCloseIcon } from 'assets/inline-svg';
import { useDispatch, useSelector } from 'react-redux';
import { getProviderDepositRequest } from 'redux/reducers/providerCurrency/reducer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TApiGetDepositPayload } from 'redux/reducers/providerCurrency/types';
import { notify } from 'utils/notify';
import { getProviderDepositAll } from 'redux/reducers/providerCurrency/selectors';
import { IProps } from './types';
import style from './styles.module.scss';

const Deposit: FC<IProps> = ({ onClose, item }) => {
	const code = useSelector(getProviderDepositAll);
	const handleAddressCopy = () => notify('Address copied successfully!', 'info');
	const dispatch = useDispatch();
	const closePopup = (e: any) => e.target === e.currentTarget && onClose();
	useEffect(() => {
		const payload: TApiGetDepositPayload = {
			apiParams: {
				currency_id: item.currency_id,
				currency_network: item.currency_network,
			},
		};
		dispatch(getProviderDepositRequest(payload));
	}, [dispatch, item.currency_id, item.currency_network]);
	return (
		<div className={style.popup}>
			<button aria-label="button" type="button" className={style.popup__close} onClick={closePopup}>
				<PopUpCloseIcon onClick={closePopup} />
			</button>
			<p className={style.popup__title}>Deposit</p>
			<div className={style.popup__body}>
				<p className={style.popup__text}>Account address</p>
				<div className={style.qrCode}>
					<img src={code?.address_qr} alt="qr" />
				</div>
				<div className={style.copyAddress}>
					<p>{code?.address}</p>
					<CopyToClipboard text={code?.address || ''} onCopy={handleAddressCopy}>
						<button aria-label="button" type="button">
							<CopyIcon />
						</button>
					</CopyToClipboard>
				</div>
			</div>
		</div>
	);
};
export default Deposit;
