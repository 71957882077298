import { StatusHistory } from 'services/api/project/types';
import { createDateFormatUTC } from 'utils/dates';
import { capitalize } from 'utils/strings';
import StatusText from 'UI/StatusText/StatusText';
import { ITrasactionHistory } from 'redux/reducers/providerPaymentTrasaction/types';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

interface IProps {
	statusesHistory?: StatusHistory[] | ITrasactionHistory[];
}

export default ({ statusesHistory }: IProps) => {
	if (!statusesHistory?.length) {
		return null;
	}
	return (
		<>
			<div className="block-title block-title--line2 --mt-24">
				<p>Status history</p>
			</div>
			<div className={styles.approvements__list}>
				<div className="approvements-column">
					{statusesHistory?.map((status) => (
						<div className={styles.approvements__item} key={status.id}>
							<span>{createDateFormatUTC(status.created_at)}</span>
							<span>
								Status created by{' '}
								<Link to={`/administration/team-members/${status.creator_entity_id}`}>
									{capitalize(status.creator)}
								</Link>
							</span>
							<span className="approvements-item__status">{StatusText(status.status)}</span>
						</div>
					))}
				</div>
			</div>
		</>
	);
};
