import { useState, useMemo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getApprovementProviderRequest,
	getApprovementProviderDefaultRequest,
	setCheckedIds,
} from 'redux/reducers/providerApprovements/reducer';
import {
	IProviderApprovementsRequest,
	IProviderItem,
} from 'redux/reducers/providerApprovements/types';
import {
	getProviderApprovementsProvider,
	getProviderApprovementsProviderLoading,
	getProviderApprovementsDefault,
} from 'redux/reducers/providerApprovements/selectors';
import { Pagination } from 'UI/Pagination/Pagination';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import Table from 'v2Components/commomComponents/Table';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { MobileProviderInfo } from 'components/Provider/MobileProviderInfo/MobileProviderInfo';
import { Tabs } from 'v2Components/commomComponents/Tabs/Tabs';
import Main from 'v2Components/layoutsComponents/Main';
import Header from '../../Header/Header';
import TableRow from './TableRow';

export const Provider = () => {
	const dispatch = useDispatch();
	const approvementProvider = useSelector(getProviderApprovementsProvider);
	const approvementProviderDefault = useSelector(getProviderApprovementsDefault);
	const totalPages = approvementProvider?.last_page || 1;
	const loading = useSelector(getProviderApprovementsProviderLoading);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('ApprovementsProvider');
	const [currentPage, setCurrentPage] = useState(1);
	const [checked, setChecked] = useState<Array<number>>([]);
	const [withApprovemen, setWithApprovemen, removewithApprovemen] = useLocalStorage(
		'approvement',
		'',
	);

	const params = useMemo(
		() => ({
			current_page: currentPage,
			per_page: 15,
		}),
		[currentPage],
	);

	const fetchData = useCallback(() => {
		const payload: IProviderApprovementsRequest = { params };

		dispatch(getApprovementProviderRequest(payload));
		getApprovementProviderDefaultRequest({ params: { current_page: 1, per_page: 15 } });
	}, [dispatch, params]);
	useEffect(() => {
		if (approvementProviderDefault && !withApprovemen) {
			const newWith: any = approvementProviderDefault.data;
			setWithApprovemen(newWith);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [approvementProviderDefault, withApprovemen]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		dispatch(setCheckedIds(checked));
	}, [dispatch, checked]);

	const updateApprovementRequset = () => {
		const payload: IProviderApprovementsRequest = { params };
		dispatch(getApprovementProviderRequest(payload));
	};
	const isShowPagination = totalPages > 1 && !loading;
	const listContent = [
		{ link: `merchant`, value: 'Crypto' },
		{ link: `provider`, value: 'Fiat' },
	];
	return (
		<Main
			header={
				<>
					<MobileProviderInfo />
					<Header title="Approvements" />
				</>
			}
		>
			<Tabs listContent={listContent} />
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={approvementProvider?.data || []}
				gridTemplateColumns={gridTemplateColumns}
				getRow={(item: IProviderItem) =>
					TableRow({ item, checked, setChecked, updateApprovementRequset })
				}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</PaginationWrapper>
			)}
		</Main>
	);
};
