import { FC, useState } from 'react';
import { resetMerchantUserProfileRequest } from 'redux/reducers/merchantApprovalManagement/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { IActionsBlockProps } from './types';
import { ResetPasswordForm } from '../ResetPasswordForm/ResetPasswordForm';

// eslint-disable-next-line
export const ActionsBlock: FC<IActionsBlockProps> = ({ user }) => {
	const [isResetPassModalOpen, setIsResetPassModalOpen] = useState<boolean>(false);
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);

	const resetPassword = (totp: string) => {
		user?.merchant.id &&
			dispatch(
				resetMerchantUserProfileRequest({
					paramApi: { merchant_id: user.merchant.id, totp },
					userType,
				}),
			);
		setIsResetPassModalOpen(false);
	};

	return (
		<>
			<div className="buttons">
				<button
					type="button"
					onClick={() => setIsResetPassModalOpen(true)}
					className="button button--type3"
				>
					Reset Password
				</button>
			</div>
			<PopupModal open={isResetPassModalOpen} onClose={() => setIsResetPassModalOpen(false)}>
				<ResetPasswordForm
					onClose={() => setIsResetPassModalOpen(false)}
					onSubmit={(totp) => resetPassword(totp)}
				/>
			</PopupModal>
		</>
	);
};
