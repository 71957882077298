import { PopUpCloseIcon, CheckIcon } from 'assets/inline-svg';
import { FC, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { notify } from 'utils/notify';
import { putApprovementRefoundRequest } from 'redux/reducers/providerApprovements/reducer';
import { IProviderRefoundRequest } from 'redux/reducers/providerApprovements/types';
import { IPropsPopUps } from './types';

export const PopUpApprove: FC<IPropsPopUps> = ({ onAction, onClose }) => {
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<button
						aria-label="button"
						name="close"
						onClick={onClose}
						type="button"
						className="popup__close"
					>
						<PopUpCloseIcon />
					</button>
					<div className="popup-header">
						<p className="popup-header__title popup-header__title--ta-l popup-header__title--mb-32">
							Approve
						</p>
					</div>
					<div className="popup-button popup-button--type2">
						<button
							onClick={onClose}
							type="button"
							className="button button--full-width button--type2"
						>
							Cancel
						</button>
						<button onClick={onAction} type="button" className="button button--full-width">
							Confirm
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
