import { notify } from 'utils/notify';
import { IFilter } from 'redux/reducers/app/types';
import { IProjectDownloadTransactionParams } from 'services/api/project/types';
import { createReportTransactionProjectRequest } from 'redux/reducers/projectTransactions/reducer';
import { ICreateReportProjectTransactionPayload } from 'redux/reducers/projectTransactions/types';

export const getCreateReport = (props: IFilter, tab: string, reportFormat?: string) => ({
	payload: {
		apiParams: props as IProjectDownloadTransactionParams,
		is_crypto: props.isCrypto,
		report_format: reportFormat,
		onSuccess: () =>
			notify("Report successfully created. You can find it in 'Reports' section", 'success'),
	} as ICreateReportProjectTransactionPayload,
	reducer: createReportTransactionProjectRequest,
});
