import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { DatepickerField } from 'v2Components/formComponents/DatepickerField';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { useSelector } from 'react-redux';
import { getFilter } from 'redux/reducers/app/selectors';
import { IFilter } from 'redux/reducers/app/types';
import { getProviderCurrencyAllNoSettings } from 'redux/reducers/providerCurrency/selectors';
import { DefaultToken, Token } from 'v2Components/UI/Token';

interface IFilterFiatBody {
	showFilter: boolean;
}

const FilterFiatBody = ({ showFilter }: IFilterFiatBody): JSX.Element => {
	const { values, resetForm, setValues } = useFormikContext<IFilter>();
	const filter = useSelector(getFilter);

	const currencies = useSelector(getProviderCurrencyAllNoSettings).filter(
		(item) => item.is_crypto === 0,
	);

	const selectedItems = [
		{
			id: 0,
			name: '',
			img_path: '',
			icon: '',
			code: 'All',
			is_crypto: 0,
			is_active: 1,
		},
		...currencies,
	];
	useEffect(() => {
		showFilter ? setValues({ ...filter }) : resetForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetForm, setValues, showFilter]);
	return (
		<div>
			<SelectField
				title="Assets"
				name="currency_code"
				options={selectedItems}
				valueProp="code"
				renderSelected={(selectedValue) =>
					(selectedValue && (
						<Token
							code={
								selectedValue.code === 'All'
									? selectedValue.code
									: (selectedValue.code || '').toUpperCase()
							}
							name={selectedValue.name}
						/>
					)) || <DefaultToken />
				}
				renderOption={({ code, name, img_path }) => (
					<Token code={code === 'All' ? code : (code || '').toUpperCase()} name={name} />
				)}
			/>
			<DatepickerField
				label="Date from"
				maxDate={(values?.dateTo as Date) || null}
				name="date_from"
				inFilter
			/>
			<DatepickerField
				label="Date to"
				minDate={(values?.dateFrom as Date) || null}
				name="date_to"
				inFilter
			/>
		</div>
	);
};

export default FilterFiatBody;
