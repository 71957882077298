import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';

import { getProviderCurrencyCrypto } from 'redux/reducers/providerCurrency/selectors';
import { TextField } from 'v2Components/formComponents/TextField';
import Button from 'v2Components/UI/Button';
import FormSideModal from 'v2Components/layoutsComponents/FormSideModal';
import { getWalletsList } from 'redux/reducers/merchantBankAccount/selectors';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { DefaultToken, Token } from 'v2Components/UI/Token';

import styles from './styles.module.scss';
import { TAddCryptoWalletFormValues } from '../../types';
import { addWalletSchema } from '../../BankAccounts/utils';

interface ICryptoWalletFormProps {
	showFilter: boolean;
	setShowFilter: (value: boolean) => void;
	onSubmit: (values: TAddCryptoWalletFormValues, resetForm: () => void) => void;
}

export const initialValues: TAddCryptoWalletFormValues = {
	currency_setting_id: 0,
	address: '',
	name_address: '',
};

const CryptoWalletForm: FC<ICryptoWalletFormProps> = ({ showFilter, setShowFilter, onSubmit }) => {
	const currencyList = useSelector(getProviderCurrencyCrypto);
	const walletList = useSelector(getWalletsList);

	const newList = useMemo(
		() =>
			currencyList.filter(
				(item) =>
					!walletList?.find(
						(wallet) =>
							Number(wallet?.currency_setting?.currency?.id) === Number(item.currency_id) &&
							String(wallet?.currency_setting?.currency_network) === String(item.currency_network),
					),
			) || [],
		[currencyList, walletList],
	);

	return (
		<Formik
			validationSchema={addWalletSchema}
			initialValues={initialValues}
			onSubmit={(values, { resetForm, setSubmitting }) => {
				onSubmit(values, resetForm);
				setSubmitting(false);
			}}
			enableReinitialize
		>
			{({ resetForm }) => (
				<FormSideModal
					className={styles.accounts__drawer}
					onActionDrawer={resetForm}
					title="Add Wallet"
					show={showFilter}
					setShow={setShowFilter}
					footer={
						<>
							<Button
								size="regular"
								width="100%"
								type="button"
								className={styles.cancel}
								onClick={() => setShowFilter(false)}
							>
								Cancel
							</Button>
							<Button size="regular" width="100%" type="submit">
								Add wallet
							</Button>
						</>
					}
				>
					<SelectField
						title="Currency"
						name="currency_setting_id"
						options={newList}
						valueProp="id"
						renderSelected={(selectedValue) =>
							(selectedValue && (
								<Token
									code={
										selectedValue.currency_code === 'All'
											? selectedValue.currency_code
											: (selectedValue.currency_code || '').toUpperCase()
									}
									name={selectedValue.currency_network_name}
								/>
							)) || <DefaultToken />
						}
						renderOption={({ currency_code, img_path, currency_network_name }) => (
							<Token
								code={currency_code === 'All' ? currency_code : (currency_code || '').toUpperCase()}
								name={currency_network_name}
							/>
						)}
					/>
					<TextField name="address" label="Address" placeholder="Enter wallet address" />
					<TextField name="name_address" label="Nickname" placeholder="Enter nickname" />
				</FormSideModal>
			)}
		</Formik>
	);
};

export default CryptoWalletForm;
