import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import styles from './styles.module.scss';

interface IPropsModalOpen {
	isDisabled?: boolean;
	handleClickOpen: () => void;
	children: ReactNode;
	label: string;
}
const ModalOpen: FC<IPropsModalOpen> = ({ isDisabled, handleClickOpen, children, label }) => {
	return (
		<div
			onClick={handleClickOpen}
			className={classNames({ [styles.tooltip__item_disabled]: isDisabled }, styles.tooltip__item)}
		>
			{children}
			<span className={classNames(styles.tooltip)}>
				<span className={classNames(styles.tooltip__text, '--ta-c')}>
					<span>{label}</span>
				</span>
			</span>
		</div>
	);
};

export default ModalOpen;
