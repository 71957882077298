/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IDepositCryptoTransactionParams,
	TDepositCryptoTransactions,
} from 'services/api/provider/types';
import {
	IProviderTransactionsStore,
	IExportTransactionsCSVPayload,
	ITransactionFilter,
} from './types';

export const initialState: IProviderTransactionsStore = {
	transactions: null,
	transactionsLoading: false,
	exportCSVLoading: false,
	transactionsSearch: '',
	transactionsFiatSearch: '',
	transactionsFilter: { page: 1 },
	transactionsFiatFilter: { page: 1 },
	transactionsFiat: null,
	transactionsFiatLoading: false,
};

const providerTransactions = createSlice({
	name: '@@providerTransactions',
	initialState,
	reducers: {
		getTransactionsRequest: (state, action: PayloadAction<IDepositCryptoTransactionParams>) => {
			state.transactionsLoading = true;
		},
		getTransactionsSuccess: (state, { payload }: PayloadAction<TDepositCryptoTransactions>) => {
			state.transactions = payload;
			state.transactionsLoading = false;
		},
		getTransactionsError: (state) => {
			state.transactions = null;
			state.transactionsLoading = false;
		},
		exportProviderTransactionsCSVRequest: (
			state,
			action: PayloadAction<IExportTransactionsCSVPayload>,
		) => {
			state.exportCSVLoading = true;
		},
		exportProviderTransactionsCSVSuccess: (state) => {
			state.exportCSVLoading = false;
		},
		exportProviderTransactionsCSVError: (state) => {
			state.exportCSVLoading = false;
		},
		setTransactionsStateFilter: (
			state,
			{ payload }: PayloadAction<Partial<ITransactionFilter>>,
		) => {
			state.transactionsLoading = true;
			state.transactionsFilter = { ...state.transactionsFilter, ...payload };
		},
		setTransactionsStateSearch: (state, { payload }: PayloadAction<string | undefined>) => {
			state.transactionsSearch = payload;
		},
		setTransactionsStateFiatSearch: (state, { payload }: PayloadAction<string | undefined>) => {
			state.transactionsFiatSearch = payload;
		},
		setTransactionsStateFiatFilter: (
			state,
			{ payload }: PayloadAction<Partial<ITransactionFilter>>,
		) => {
			state.transactionsFiatLoading = true;
			state.transactionsFiatFilter = { ...state.transactionsFiatFilter, ...payload };
		},
		getTransactionsFiatRequest: (state, action: PayloadAction<IDepositCryptoTransactionParams>) => {
			state.transactionsFiatLoading = true;
		},
		getTransactionsFiatSuccess: (state, { payload }: PayloadAction<TDepositCryptoTransactions>) => {
			state.transactionsFiat = payload;
			state.transactionsFiatLoading = false;
		},
		getTransactionsFiatError: (state) => {
			state.transactionsFiat = null;
			state.transactionsFiatLoading = false;
		},
	},
});

export default providerTransactions.reducer;
export const {
	exportProviderTransactionsCSVError,
	exportProviderTransactionsCSVRequest,
	exportProviderTransactionsCSVSuccess,
	getTransactionsError,
	getTransactionsFiatError,
	getTransactionsFiatRequest,
	getTransactionsFiatSuccess,
	getTransactionsRequest,
	getTransactionsSuccess,
	setTransactionsStateFiatFilter,
	setTransactionsStateFiatSearch,
	setTransactionsStateFilter,
	setTransactionsStateSearch,
} = providerTransactions.actions;
