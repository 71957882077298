import { ProjectInfo } from '../ProjectInfo/ProjectInfo';
import { BalanceInfo } from '../BalanceInfo/BalanceInfo';

export const MobileProjectInfo = () => {
	return (
		<div className="mobile-project-info">
			<div className="select-block select-block--project-select">
				<ProjectInfo />
			</div>
			<BalanceInfo />
		</div>
	);
};
