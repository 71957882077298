import { IStoreState } from 'redux/types';

export const projectWithdrawal = (state: IStoreState) => state.projectWithdrawal;

export const getWithdrawal = (state: IStoreState) => projectWithdrawal(state).withdrawal;
export const getWithdrawalLoading = (state: IStoreState) =>
	projectWithdrawal(state).withdrawalLoading;
export const getWithdrawalDataFiatLimit = (state: IStoreState) =>
	projectWithdrawal(state).withdrawalLimitData;
export const getWithdrawalFiat = (state: IStoreState) => projectWithdrawal(state).withdrawalFiat;
export const getWithdrawalFiatLoading = (state: IStoreState) =>
	projectWithdrawal(state).withdrawalFiatLoading;
