import { IStoreState } from 'redux/types';

export const getProviderWalletsAddresses = (state: IStoreState) => state.providerWalletAddresses;
export const getProviderWalletsAddressesData = (state: IStoreState) =>
	getProviderWalletsAddresses(state).providerWalletsAddresses;
export const getProviderCurrencyAllLoading = (state: IStoreState) =>
	getProviderWalletsAddresses(state).providerWalletsAddressesLoading;
export const getProviderWalletsAddressesClientData = (state: IStoreState) =>
	getProviderWalletsAddresses(state).providerWalletsAddressesClient;
export const getProviderCurrencyAllClientLoading = (state: IStoreState) =>
	getProviderWalletsAddresses(state).providerWalletsAddressesClientLoading;
export const getProviderMrechantOwnerId = (state: IStoreState) =>
	getProviderWalletsAddresses(state).merchantOwnerId;
