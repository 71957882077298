/* eslint-disable no-nested-ternary */
import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import L from 'i18n-react';
import { Field, Form, Formik } from 'formik';
import { RoleField } from 'v2Pages/Provider/AdministrationPage/Header/AddNewAdmin/AddNewAdminForm/RoleField/RoleField';
import { useHistory, useParams } from 'react-router';
import { constUserTypes } from 'services/constants/user-types';
import { getUserType } from 'redux/reducers/auth/selectors';
import { getCountriesRequest } from 'redux/reducers/app/reducer';
import { getCountries } from 'redux/reducers/app/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { getMerchantApprovalManagementProviderRequest } from 'redux/reducers/merchantApprovalManagement/reducer';
import { getMerchantsList } from 'redux/reducers/merchantApprovalManagement/selectors';
import { Successful } from 'UI/Successful/Successful';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { TextField } from 'UI/Forms/TextField/TextField';
import { PhoneInputField } from 'UI/Forms/PhoneInputField/PhoneInputField';
import { ICreateProjectFormValues } from 'components/Provider/Merchant/MerchantAccauntAndProject/types';
import { SelectMerchant } from 'pages/Merchant/MerchantProjectsCreatePage.tsx/SelectMerchant/SelectMerchant';
// @TODO: Удалить parsePhone
import { parsePhone } from 'utils/phone';
import { dataUrlToFile, toBase64 } from 'utils/files';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import CropModal from 'UI/Popups/CropModal';
import Main from 'v2Components/layoutsComponents/Main';
import { ICreateProjectProps } from '../types';
import PictureInput from './PictureInput.tsx/PictureInput';
import styles from './styles.module.scss';

const roles: any[] = [
	{
		code: 'inactive',
		name: 'Inactive',
	},
	{
		code: 'active',
		name: 'Active',
	},
];

const MerchantProjectCreateItem: FC<ICreateProjectProps> = ({
	onSubmitRequest,
	valueState,
	activeCard,
}) => {
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);
	const [show, setShow] = useState<boolean>(false);
	const [b64, setB64] = useState('');
	const isMerchant = userType === constUserTypes.MERCHANT;
	const isProvider = userType === constUserTypes.PROVIDER;

	const countries = useSelector(getCountries);

	const [contactPersonId, setContactPersonId] = useState<number | null>(null);

	const teamMembers = useSelector(getMerchantsList);

	useEffect(() => {
		if (!countries) {
			dispatch(getCountriesRequest());
		}
	}, [countries, dispatch]);

	useEffect(() => {
		if (!isMerchant) {
			return;
		}
		dispatch(getMerchantApprovalManagementProviderRequest({ apiParams: {} }));
	}, [dispatch, isMerchant]);

	const validationSchema = yup.object().shape({
		isProvider: yup.boolean(),
		projectName: yup
			.string()
			.required('Project name is required')
			.min(2, 'Too Short!')
			.max(255, 'Too Long!')
			.matches(/^[a-zA-Z]+(([',. -_])?[a-zA-Z]*)*$/, `Invalid format - . _ ,'  `),
		role: yup
			.object()
			.nullable()
			.when('isProvider', {
				is: true,
				then: yup.object().nullable().required('Project status role is required'),
			}),
		contractPersonFirstName: yup
			.string()
			.required('Contract person first name is required')
			.min(2, 'Too Short!')
			.max(255, 'Too Long!')
			.matches(/^[a-zA-Z]+$/, `Invalid format - . _ ,'  `),
		contractPersonLastName: yup
			.string()
			.required('Contract person last name is required')
			.min(2, 'Too Short!')
			.max(255, 'Too Long!')
			.matches(/^[a-zA-Z]+$/, `Invalid format - . _ ,'  `),
		email: yup
			.string()
			.required(String(L.translate('Email is required')))
			.email(String(L.translate('Validation.email_format'))),
		phoneCode: yup.string().required('Phone code is required'),
		phoneNumber: yup
			.string()
			.when('phoneCode', (phoneCode, schema) => schema.phone(phoneCode, 'Phone is not valid'))
			.required('Phone number is required'),
		website: yup
			.string()
			.required('Website is required')
			.min(2, 'Too Short!')
			.max(40, 'Too Long!')
			.matches(/^(https?:\/\/)?([\da-z.-]+).([a-z.]{2,6})([/\w .-])\/?$/, 'Invalid url format'),
		file: yup
			.mixed()
			// .required('Img is required')
			.test('fileSize', 'Your image is too big', (value) => {
				return !value || value?.size <= 5000000 || typeof value === 'string';
			})
			.test('fileSize', 'Only such formats are signed - jpg, png, gif', (value) => {
				return (
					!value ||
					['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(value?.type) ||
					typeof value === 'string'
				);
			}),
	});
	const history = useHistory();
	const { merchantId } = useParams<{ merchantId: string }>();
	return activeCard ? (
		<Successful activeCard={activeCard} />
	) : (
		<Main
			header={
				<>
					<div className="mobile-project-info">
						<div className="header-project">
							<div className="header-project__name">
								<p>Provider</p>
							</div>
						</div>
					</div>
					{isProvider && (
						<button
							onClick={() => {
								history.push(`/merchant/${merchantId}/projects`);
							}}
							type="button"
							className="back-page"
						>
							<ArrowLeftIcon />
							<span>Back to Merchant management</span>
						</button>
					)}
					<p className={styles.title}>{isMerchant ? 'Add project' : 'Create project'}</p>
				</>
			}
		>
			<Formik
				initialValues={{ ...valueState, isProvider }}
				validationSchema={validationSchema}
				onSubmit={(values: ICreateProjectFormValues, helpers) => {
					values.role
						? onSubmitRequest(values, helpers)
						: isProvider
						? setShow(true)
						: onSubmitRequest(values, helpers);
				}}
			>
				{({ values, setValues, setFieldValue }) => {
					const handleContactPersonIdSelect = (_contactPersonId: number | null) => {
						setContactPersonId(_contactPersonId);
						if (_contactPersonId === null) {
							setValues({
								...values,
								contractPersonFirstName: '',
								contractPersonLastName: '',
								email: '',
								phoneCode: '',
								phoneNumber: '',
							});
							return;
						}
						const contactPerson = teamMembers?.find((item) => item.id === _contactPersonId);
						if (!contactPerson) {
							return;
						}
						const { first_name, last_name } = contactPerson.merchant_data;
						const { email, merchant_data } = contactPerson;
						const phone = merchant_data?.phone_number || '';
						const [phoneCode, phoneNumber] = parsePhone(phone);
						setValues({
							...values,
							contractPersonFirstName: first_name || '',
							contractPersonLastName: last_name || '',
							email: email || '',
							phoneCode,
							phoneNumber,
						});
					};

					const saveCrop = (img: string) => {
						const file = dataUrlToFile(img, 'file');
						setFieldValue('file', file);
						setB64('');
					};

					const onImageUpload = async (file: any) => {
						setB64(await toBase64(file));
					};

					const handleCropClose = () => {
						setB64('');
					};

					return (
						<>
							<PopupModal open={!!b64} onClose={handleCropClose}>
								<CropModal image={b64} onClose={handleCropClose} onSave={saveCrop} />
							</PopupModal>
							<Form className={`${isMerchant ? 'main-content--type3' : ''}`}>
								<div className={isMerchant ? 'add-project-container' : ''}>
									<div className={styles.fields}>
										<div className="fields-item">
											<div className="fields-list">
												<TextField
													label="Project Name"
													placeholder="Enter project name"
													name="projectName"
												/>
												<SelectMerchant
													title="Select previously added user"
													placeholder="Select contact person"
													options={teamMembers}
													value={contactPersonId}
													onChange={handleContactPersonIdSelect}
													valueProp="id"
													labelProp="merchant_data"
												/>
												{isProvider && (
													<RoleField
														setShow={setShow}
														custom={show}
														text="Project status"
														roles={roles}
													/>
												)}
												<TextField
													label="Contact person first name"
													placeholder="Enter contact person first name"
													name="contractPersonFirstName"
												/>
												<TextField
													label="Contact person last name"
													placeholder="Enter contact person last name"
													name="contractPersonLastName"
												/>
												<TextField
													label="Email address"
													placeholder="Enter email address"
													name="email"
												/>
												<PhoneInputField
													name="phoneNumber"
													codeFieldName="phoneCode"
													label="Phone"
													countries={countries || []}
													openToUp
												/>
												<TextField
													label="Website"
													type="text"
													placeholder="Enter URL"
													name="website"
													classNameCustom={styles.mb_0}
												/>
											</div>
										</div>
										<div className="fields-item fields-item--type3">
											<div>
												<Field
													name="file"
													component={PictureInput}
													onChange={onImageUpload}
													preventDefaultChange
												/>
											</div>
											<div className="fields-button">
												<Link className="link-create" to="/merchants">
													<button type="button" className="button button--type2">
														Cancel
													</button>
												</Link>
												<button type="submit" className="button">
													Create Project
												</button>
											</div>
										</div>
									</div>
								</div>
							</Form>
						</>
					);
				}}
			</Formik>
		</Main>
	);
};

export default MerchantProjectCreateItem;
