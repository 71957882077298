import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAppStore, IBalanceInDefaultCurrency, IFilter } from 'redux/reducers/app/types';
import { TGetCountriesResponse, TGetLanguagesResponse } from 'services/api/app/types';
import { IGetCurrencyNetworkItem } from 'services/api/public/types';

const getAppState = (state: IStoreState): IAppStore => state.app;
export const getApp = createSelector([getAppState], (app: IAppStore) => app);

export const appLanguage = createSelector([getApp], (app): string => app.lang);

export const getCountries = createSelector(
	[getApp],
	(app): TGetCountriesResponse | null => app.countries,
);

export const getCountriesLoading = createSelector([getApp], (app): boolean => app.countriesLoading);

//

export const getLanguages = createSelector(
	[getApp],
	(app): TGetLanguagesResponse | null => app.languages,
);

export const getLanguagesLoading = createSelector([getApp], (app): boolean => app.languagesLoading);
export const getStatusBurger = createSelector([getApp], (app): boolean => app.burger);

export const getBalanceInDefaultCurrency = createSelector(
	[getApp],
	(app): IBalanceInDefaultCurrency => app.balanceInDefaultCurrency,
);

export const getCurrencyNetwork = createSelector(
	[getApp],
	(app): IGetCurrencyNetworkItem[] => app.currencyNetworks,
);

export const getFilter = createSelector([getApp], (app): IFilter => app.filter);
