import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getBalancesInFiat } from 'redux/reducers/providerBankAccount/selectors';
import { notify } from 'utils/notify';
import CopyToClipboard from 'react-copy-to-clipboard';
import { shortNotation } from 'utils/strings';
import { CopyIcon, DeleteIcon, EditIcon, TheeDotsHorizontalIcon } from 'assets/inline-svg';
import { getProviderCurrencyAll } from 'redux/reducers/providerCurrency/selectors';
import classNames from 'classnames';
import { IBankAccountBoxProps } from '../../types';
import styles from './styles.module.scss';

export default ({ item, openEditModal, openDeleteModal }: IBankAccountBoxProps) => {
	const balancesInFiat = useSelector(getBalancesInFiat);
	const currencyAll = useSelector(getProviderCurrencyAll);
	const currency = currencyAll.find(({ id }) => id === item.currency_id);
	const [openMenu, setOpenMenu] = useState(false);
	const currentBallance = balancesInFiat?.[item.currency_name];
	const { total_merchant_balance, total_provider_balance } = currentBallance || {};

	const handleIbanCopy = () => {
		notify('Iban copied successfully!', 'info');
	};
	const openEdit = () => {
		openEditModal(item);
		setOpenMenu(false);
	};
	const openDelete = () => {
		openDeleteModal(item);
		setOpenMenu(false);
	};

	return (
		<div className={styles.account}>
			<div className={styles.account__edit}>
				<div className={styles.account__editButton} onClick={() => setOpenMenu(!openMenu)}>
					<TheeDotsHorizontalIcon />
				</div>
				{openMenu && (
					<div className={styles.account__menu}>
						<div className={styles.account__menuItem} onClick={openEdit}>
							<EditIcon /> <span>Edit</span>
						</div>
						<div
							className={classNames(styles.account__menuItem, styles.account__menuItem_delete)}
							onClick={openDelete}
						>
							<DeleteIcon /> <span>Delete</span>
						</div>
					</div>
				)}
			</div>
			<span className={styles.account__icon}>
				{currency && <img src={currency?.img_path} alt={currency?.currency_network} />}
			</span>
			<p className={styles.account__name}>{item.name_account}</p>
			<div>
				<p className={styles.account__label}>Currency</p>
				<p className={styles.account__value}>{item.currency_name.toUpperCase()}</p>
			</div>
			<div>
				<p className={styles.account__label}>IBAN</p>
				<CopyToClipboard text={String(item.iban)} onCopy={handleIbanCopy}>
					<div className={styles.account__value}>
						<p className={styles.copy__value}>
							{item.iban && shortNotation(item.iban, 12, 8)?.toUpperCase()}
						</p>
						<button aria-label="button" type="button" className={styles.copy}>
							<CopyIcon />
						</button>
					</div>
				</CopyToClipboard>
			</div>
			<div className={styles.account__balance}>
				<div>
					<p className={styles.account__label}>Provider balance</p>
					<p className={styles.account__name}>{total_merchant_balance} USD</p>
				</div>
				<div>
					<p className={styles.account__label}>Merchant balance</p>
					<p className={styles.account__name}>{total_provider_balance} USD</p>
				</div>
			</div>
		</div>
	);
};
