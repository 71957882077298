import { NavLink, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import { useMemo } from 'react';
import styles from './styles.module.scss';

interface IListContent {
	value: string;
	link: string;
}
interface Props {
	listContent: IListContent[];
	isSwitch?: boolean;
}
export const Tabs = ({ listContent, isSwitch }: Props) => {
	const { url: matchUrl } = useRouteMatch();
	const isActive = (link: string) =>
		document.location.pathname + document.location.search === `${matchUrl}/${link}`;
	return (
		<div
			className={classNames(styles.tabs, {
				[styles.tabsSwitch]: isSwitch,
			})}
		>
			{listContent.map((item) => (
				<NavLink
					to={`${matchUrl}/${item.link}`}
					className={classNames(styles.tabs__item, {
						[styles.active]: isActive(item.link),
					})}
					key={item.value}
				>
					{item.value}
				</NavLink>
			))}
		</div>
	);
};
