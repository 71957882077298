export const reportsType: Array<TReports> = [
	{ id: 0, name: 'Balance', code: 'balance' },
	{ id: 1, name: 'Transaction report', code: 'transaction' },
	{ id: 2, name: 'Currency flow ', code: 'flow ' },
];

export interface TReports {
	id: number;
	name: string;
	code: string;
}
export const reportsTypeSelected: Array<TReportsType> = [
	{ value: '', label: 'All', code: '' },
	{
		value: 'MERCHANT_TRANSACTION_REPORT',
		label: 'Transaction report',
		code: 'MERCHANT_TRANSACTION_REPORT',
	},
	{ value: 'MERCHANT_BALANCES_REPORT', label: 'Balances', code: 'MERCHANT_BALANCES_REPORT' },
	{
		value: 'MERCHANT_CURRENCY_FLOW_REPORT',
		label: 'Currency flow ',
		code: 'MERCHANT_CURRENCY_FLOW_REPORT ',
	},
];

export interface TReportsType {
	value: string;
	label: string;
	code: string;
}
