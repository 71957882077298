/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRolesRequest } from 'redux/reducers/administration/reducer';
import { getRoles } from 'redux/reducers/administration/selectors';
import {
	getProjectListMerchantRequest,
	getProjectListProviderRequest,
} from 'redux/reducers/merchantProjects/reducer';
import { getProjectList } from 'redux/reducers/merchantProjects/selectors';
import { IGetProjectListPayload } from 'redux/reducers/merchantProjects/types';
import { constUserTypes } from 'services/constants/user-types';
import { getUserType } from 'redux/reducers/auth/selectors';
import { AddNewUserForm } from './AddNewUserForm/AddNewUserForm';
import { IAddNewAdminProps } from './types';

export const AddNewUser: FC<IAddNewAdminProps> = ({ onSubmit, onClose, user }) => {
	const dispatch = useDispatch();
	const roles = useSelector(getRoles);
	const projectList = useSelector(getProjectList);
	const userType = useSelector(getUserType);

	const { merchantId } = useParams<{ merchantId: string }>();
	const [firstLoading, setFirstLoading] = useState(true);

	useEffect(() => {
		if (!roles) {
			dispatch(getRolesRequest({ payload: 'ApprovalManagement' }));
		}
	}, [roles, dispatch]);

	useEffect(() => {
		const payload: IGetProjectListPayload = {
			apiParams: { merchant_id: user.merchant.owner_merchant_id },
			onFinally: () => setFirstLoading(false),
		};
		userType === constUserTypes.PROVIDER
			? dispatch(getProjectListProviderRequest(payload))
			: dispatch(getProjectListMerchantRequest(payload));
	}, [dispatch, merchantId, user.merchant.owner_merchant_id]);

	return (
		<div className="popup popup--width-480">
			<button aria-label="button" type="button" className="popup__close" onClick={onClose}>
				<PopUpCloseIcon />
			</button>

			<AddNewUserForm roles={roles} projects={projectList} onSubmit={onSubmit} onCancel={onClose} />
		</div>
	);
};
