import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
	header?: ReactNode;
	children: ReactNode;
	noBackground?: boolean;
}

const Main: FC<IProps> = ({ header, children, noBackground }) => {
	return (
		<div className={styles.main}>
			{header}
			<div
				className={classNames(styles.main__wrapper, { [styles.main__background]: !noBackground })}
			>
				{children}
			</div>
		</div>
	);
};

export default Main;
