import { FC, useLayoutEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from 'routes/routes';
import { getProviderMerchantProfileRequest } from 'redux/reducers/merchantProfile/reducer';
import {
	getProjectDetailsMerchantRequest,
	getProjectDetailsRequest,
} from 'redux/reducers/projectSettings/reducer';
import { getUserRole, getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { routeListProject, titleList } from 'routes/routeListProject';
import { ProjectLayoutV2 } from 'layouts/ProjectLayoutV2/ProjectLayoutV2';

export const ProjectRoutes: FC = () => {
	const dispatch = useDispatch();
	const { path } = useRouteMatch();
	const { pathname } = useLocation();
	const { merchantId, projectId } = useParams<{ merchantId: string; projectId: string }>();
	const userType = useSelector(getUserType);
	const userRole = useSelector(getUserRole);

	useLayoutEffect(() => {
		if (String(merchantId) !== 'null') {
			userType === constUserTypes.PROVIDER &&
				dispatch(getProviderMerchantProfileRequest({ merchant_id: merchantId }));
		}

		dispatch(
			userType === constUserTypes.PROVIDER
				? getProjectDetailsRequest({ project_id: projectId, is_crypto: 1 })
				: getProjectDetailsMerchantRequest({ project_id: projectId, is_crypto: 1 }),
		);
	}, [dispatch, merchantId, projectId, userType]);

	const isDesktop = useMemo(() => window.screen.width > 1180, []);
	const title = useMemo(() => titleList[pathname], [pathname]);
	const index = useRouteMatch(routes.oneProjectBase);
	const redirectUrl =
		path.replace(':merchantId', merchantId).replace(':projectId', projectId) +
		routes.oneProject.transactions.transactions;
	if (index?.isExact) {
		return <Redirect to={redirectUrl} />;
	}
	return (
		<Switch>
			<ProjectLayoutV2 title={title} v2={isDesktop}>
				{routeListProject.map((route) =>
					route.role.includes(userRole || '') ? (
						<Route
							key={route.path}
							exact={route.exact}
							path={path + route.path}
							component={route.component}
						/>
					) : null,
				)}
			</ProjectLayoutV2>
			<Redirect to={path + routes.oneProject.transactions.transactions} />
		</Switch>
	);
};
