import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getUserType } from 'redux/reducers/auth/selectors';
import { getApiMerchantRequest, getApiRequest } from 'redux/reducers/projectSettings/reducer';
import { getApi, getApiLoading } from 'redux/reducers/projectSettings/selectors';
import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import { constUserTypes } from 'services/constants/user-types';
import Loading from 'UI/Loading';
import { ApiKeys } from './ApiKeys/ApiKeys';
import { CallbackSecret } from './CallbackSecret/CallbackSecret';
import { CallbackUrl } from './CallbackUrl/CallbackUrl';
import styles from './styles.module.scss';

export const ApiTab = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);

	const api = useSelector(getApi);
	const loading = useSelector(getApiLoading);

	useLayoutEffect(() => {
		dispatch(
			userType === constUserTypes.PROVIDER
				? getApiRequest({ project_id: projectId })
				: getApiMerchantRequest({ project_id: projectId }),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, projectId]);

	if (loading) {
		return <Loading />;
	}

	return (
		<ProjectContent>
			{api && (
				<div className={styles.container}>
					<ApiKeys />
					<CallbackUrl />
					<CallbackSecret />
				</div>
			)}
		</ProjectContent>
	);
};
