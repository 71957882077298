import { FC, useEffect, useState } from 'react';
import { CopyIcon, PopUpCloseIcon } from 'assets/inline-svg';
import { useDispatch, useSelector } from 'react-redux';
import { getProviderAllCurrencySettingRequest } from 'redux/reducers/providerCurrency/reducer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notify } from 'utils/notify';
import {
	getLiquidityList,
	getDepositAddressLoading,
	getDepositAddress,
} from 'redux/reducers/providerLiquidity/selectors';
import { getDepositAddressRequest } from 'redux/reducers/providerLiquidity/reducer';
import { ILiquidity } from 'redux/reducers/providerLiquidity/types';
import Loader from 'UI/Loader/Loader';
import { TypeSelect } from './TypeSelect/TypeSelect';
import { IProps } from './types';

const Deposit: FC<IProps> = ({ onClose, item }) => {
	const dispatch = useDispatch();
	const [selectedItem, setSelectedItem] = useState<ILiquidity | undefined>(item);
	const liqudityList = useSelector(getLiquidityList);
	const depositAddress = useSelector(getDepositAddress);
	const loading = useSelector(getDepositAddressLoading);

	const { address, address_qr } = depositAddress || {};

	const handleAddressCopy = () => {
		notify('Address copied successfully!', 'info');
	};

	useEffect(() => {
		dispatch(getProviderAllCurrencySettingRequest({ apiParams: {} }));
	}, [dispatch]);

	useEffect(() => {
		if (!selectedItem) {
			return;
		}
		const { provider_liquidity_id, network } = selectedItem;
		dispatch(
			getDepositAddressRequest({
				provider_liquidity_id,
				network,
			}),
		);
	}, [dispatch, selectedItem]);

	const handleTypeChange = (_item: ILiquidity) => {
		setSelectedItem(_item);
	};

	return (
		<div className="popup popup--width-480">
			<button aria-label="button" type="button" className="popup__close">
				<PopUpCloseIcon onClick={onClose} />
			</button>
			<div className="popup-header">
				<p className="popup-header__title popup-header__title--mb-32 popup-header__title--ta-l">
					Deposit
				</p>
			</div>
			<div className="popup-body popup-body--type3">
				<div className="select-block">
					<p className="select-block__name">Wallet</p>
					<TypeSelect selected={selectedItem} onChange={handleTypeChange} options={liqudityList} />
					<div className="select-info">
						<p className="select-info__name">Balance:</p>
						<p className="select-info__number">{selectedItem?.balance}</p>
					</div>
				</div>
				<p className="popup-text popup-text--fz-14">Binance account address</p>
				<div className="qr-code">
					{loading && <Loader />}
					{!loading && address_qr && <img src={address_qr} alt="qr" />}
				</div>
				<div className="copy-address">
					<p>{address}</p>
					{!loading && address && (
						<CopyToClipboard text={address} onCopy={handleAddressCopy}>
							<button aria-label="button" type="button">
								<CopyIcon />
							</button>
						</CopyToClipboard>
					)}
				</div>
			</div>
		</div>
	);
};

export default Deposit;
