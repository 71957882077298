import Dialog from '@mui/material/Dialog';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearExchangeRateFee,
	cryptoExchangeRequest,
	getExchangeRateFeeRequest,
} from 'redux/reducers/merchantAccounts/reducer';
import { IProjectBalanceResponse } from 'redux/reducers/merchantAccounts/types';
import { ExchangeIcon, PopUpCloseIcon } from 'assets/inline-svg';
import { Formik } from 'formik';
import Button from 'v2Components/UI/Button';
import {
	getAccountLoading,
	getExchangeRateFeeSelector,
	getProjectBalanceList,
	getProjectToBalanceList,
} from 'redux/reducers/merchantAccounts/selectors';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import classNames from 'classnames';
import { useViewDecimals } from 'hooks/useViewDecimals';
import CurrencySelect from '../components/CurrencySelect';
import CurrencyAmount from '../components/CurrencyAmount';
import ExchangeStatus from '../components/ExchangeStatus';
import ModalOpen from '../components/ModalOpen';
import ModalAutoExchange from '../ModalAutoExchange';
import ModalConfirm from '../ModalConfirm';
import styles from './styles.module.scss';

interface IProps {
	defaultFromId: number;
}

const COUNT_EXCHANGE = 1;

const ModalExchange = ({ defaultFromId }: IProps) => {
	const dispatch = useDispatch();
	const project = useSelector(getProjectDetails);
	const rateAndFee = useSelector(getExchangeRateFeeSelector);
	const loading = useSelector(getAccountLoading);
	const projectChose = useSelector(getProjectDetails);
	const [amount, setAmount] = useState(0);
	const [received, setReceived] = useState(0);
	const [open, setOpen] = useState(false);
	const [openConfirm, setOpenConfirm] = useState<boolean>(false);
	const [isAutoExchangeOpen, setIsAutoExchangeOpen] = useState(false);
	const currenciesTo = useSelector(getProjectToBalanceList);
	const accounts = useSelector(getProjectBalanceList);
	const filteredCurrenciesFrom = useMemo(
		() =>
			accounts?.filter(
				(account) => account.is_crypto === 1 && account.is_active && account.is_exchangeable,
			) || [],
		[accounts],
	);

	const [currencyFrom, setCurrencyFrom] = useState<IProjectBalanceResponse | null>(null);
	const filteredCurrenciesTo = useMemo(
		() => currenciesTo?.filter((item) => item.is_exchangeable && item.id !== currencyFrom?.id),
		[currenciesTo, currencyFrom?.id],
	);

	const [currencyTo, setCurrencyTo] = useState<IProjectBalanceResponse | null>(null);
	const isDisabled = useMemo(
		() =>
			!filteredCurrenciesFrom.find(
				(coin) =>
					(coin.id === defaultFromId &&
						!accounts?.some(
							(currency) => currency?.auto_exchange?.auto_exchange_code === coin.currency.code,
						)) ||
					!coin?.currency.code,
			),
		[accounts, defaultFromId, filteredCurrenciesFrom],
	);

	const handleClickOpen = () => !isDisabled && setOpen(true);
	const closePopUp = () => setOpen(false);

	const showAutoExchangeOpen = () => {
		setIsAutoExchangeOpen(true);
		closePopUp();
	};

	const hideAutoExchangeOpen = () => {
		closePopUp();
		setIsAutoExchangeOpen(false);
	};

	const comebackAutoExchangeOpen = () => {
		handleClickOpen();
		setIsAutoExchangeOpen(false);
	};

	const onSubmit = () => {
		dispatch(
			cryptoExchangeRequest({
				data: {
					amount,
					from_balance_id: currencyFrom?.id,
					to_balance_id: currencyTo?.id,
					project_id: projectChose?.id,
					exchange_key: rateAndFee?.exchange_key,
				},
				closePopUp: () => {
					setOpenConfirm(true);
				},
				onError: closePopUp,
			}),
		);
	};

	const makeReverse = () => {
		if (!currencyTo?.id) {
			return;
		}
		const tmp = currencyFrom;
		setAmount(0);
		setReceived(0);
		setCurrencyFrom(currencyTo);
		setCurrencyTo(tmp);
	};

	const getRateAndFee = (
		value?: number,
		from?: IProjectBalanceResponse | null,
		to?: IProjectBalanceResponse | null,
	) => {
		if (value) {
			setAmount(value);
		}
		const newValue = value || amount;
		const from_balance_id = from ? from.id : currencyFrom?.id;
		const to_balance_id = to ? to.id : currencyTo?.id;
		if (newValue && from_balance_id && to_balance_id) {
			dispatch(
				getExchangeRateFeeRequest({
					amount: newValue,
					from_balance_id,
					to_balance_id,
					project_id: projectChose?.id,
				}),
			);
		}
	};

	const getRateAndFeeBackward = (value?: number) => {
		if (value) {
			setReceived(value);
		}
		const newValue = value || received;
		if (received && currencyTo) {
			dispatch(
				getExchangeRateFeeRequest({
					amount: newValue,
					from_balance_id: currencyFrom?.id,
					to_balance_id: currencyTo.id,
					project_id: projectChose?.id,
					type: 'backward',
				}),
			);
		}
	};
	const changeCurrencyFrom = (value: IProjectBalanceResponse) => {
		setCurrencyFrom(value);
		getRateAndFee(amount, value, currencyTo);
	};

	const changeCurrencyTo = (value: IProjectBalanceResponse) => {
		setCurrencyTo(value);
		getRateAndFee(amount, currencyFrom, value);
	};

	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = useMemo(
		() => getViewDecimal(currencyFrom?.currency?.code || '', currencyFrom?.currency_network),
		[currencyFrom?.currency?.code, currencyFrom?.currency_network, getViewDecimal],
	);

	useEffect(() => {
		setAmount(+(rateAndFee?.fromBalance?.amount || 0));
		setReceived(+(rateAndFee?.toBalance?.amount || 0));
	}, [rateAndFee]);

	useEffect(() => {
		if (currencyFrom?.id === currencyTo?.id) {
			setCurrencyTo(filteredCurrenciesFrom.find((coin) => coin.id !== currencyFrom?.id) || null);
		}
	}, [currencyFrom, currencyTo?.id, filteredCurrenciesFrom]);

	useEffect(() => {
		if (!open) {
			setAmount(0);
			setCurrencyTo(null);
			dispatch(clearExchangeRateFee());
		} else {
			setCurrencyFrom(
				filteredCurrenciesFrom.find(
					(coin) =>
						(coin.id === defaultFromId &&
							!accounts?.some(
								(currency) => currency?.auto_exchange?.auto_exchange_code === coin.currency.code,
							)) ||
						!coin?.currency.code,
				) || null,
			);
		}
	}, [accounts, defaultFromId, dispatch, filteredCurrenciesFrom, open]);

	return (
		<>
			<ModalOpen isDisabled={isDisabled} handleClickOpen={handleClickOpen} label="Exchange">
				<ExchangeIcon />
			</ModalOpen>
			<ModalAutoExchange
				defaultFromId={defaultFromId}
				closePopUp={hideAutoExchangeOpen}
				isOpen={isAutoExchangeOpen}
				comeback={comebackAutoExchangeOpen}
			/>
			<Dialog open={open} onClose={closePopUp}>
				<div className={styles.close} onClick={closePopUp}>
					<PopUpCloseIcon />
				</div>
				<Formik initialValues={{}} onSubmit={onSubmit}>
					<div className={styles.content}>
						{openConfirm ? (
							<ModalConfirm onClick={closePopUp} />
						) : (
							<>
								<div className={styles.head}>
									{project?.file_icon && (
										<span className={styles.logo}>
											<img src={project.file_icon} alt="" />
										</span>
									)}
									<div>
										<p className={styles.title}>Exchange</p>
										<p className={styles.subtitle}>{project?.name}</p>
									</div>
								</div>
								<div className={styles.body}>
									<div>
										<CurrencySelect
											label="You Send"
											options={filteredCurrenciesFrom}
											value={currencyFrom}
											setValue={changeCurrencyFrom}
										/>
										<CurrencyAmount
											getRate={getRateAndFee}
											value={amount}
											network={currencyFrom?.currency_network}
											code={currencyFrom?.currency?.code}
											max={currencyFrom?.balance}
										/>
									</div>
									<div
										className={classNames(styles.reverse, {
											[styles.reverseActive]: !!currencyTo?.id,
										})}
										onClick={makeReverse}
									>
										<ExchangeIcon />
									</div>
									<div>
										<CurrencySelect
											label="You Receive"
											options={filteredCurrenciesTo}
											value={currencyTo}
											setValue={changeCurrencyTo}
										/>
										<CurrencyAmount
											getRate={getRateAndFeeBackward}
											value={received}
											network={currencyTo?.currency_network}
											code={currencyTo?.currency?.code}
										/>
									</div>
								</div>
								<div className={styles.info}>
									<ExchangeStatus
										loading={loading}
										isRate={!!rateAndFee?.exchange_key && !!amount}
										getRateAndFee={getRateAndFee}
										key={rateAndFee?.exchange_key}
									/>
									<div className={styles.info__item}>
										Exchange rate:
										<span className={styles.info__value}>
											{!!rateAndFee?.rate && (
												<>
													{COUNT_EXCHANGE} {currencyFrom?.currency?.code?.toUpperCase()} ={' '}
													{rateAndFee?.rate || ''}{' '}
												</>
											)}
											{(!!rateAndFee?.rate && currencyFrom?.currency?.code?.toUpperCase()) || ''}
										</span>
									</div>
									<div className={styles.info__item}>
										Exchange Fee (included):
										<span className={styles.info__value}>
											{(rateAndFee?.fee && Number(rateAndFee?.fee)?.toFixed(viewDecimals)) || ''}{' '}
											{(!!rateAndFee?.fee && currencyFrom?.currency?.code?.toUpperCase()) || ''}
										</span>
									</div>
								</div>
								<div className={styles.buttons}>
									<Button onClick={closePopUp} type="button" className={styles.button} light>
										Cancel
									</Button>
									<Button
										onClick={onSubmit}
										type="button"
										className={styles.button}
										disabled={!rateAndFee?.exchange_key || !amount || loading}
									>
										Exchange
									</Button>
								</div>
								<div className={styles.description}>
									Do you want all incoming payments in one currency to be instantly exchanged for
									another? Try Auto exchange!
								</div>
								<div className={styles.autoExchange} onClick={showAutoExchangeOpen}>
									Auto exchange
								</div>
							</>
						)}
					</div>
				</Formik>
			</Dialog>
		</>
	);
};

export default ModalExchange;
