import { FC, useEffect } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import {
	ProviderAvatarCheckIcon,
	TransactionsIcon,
	TwoPeopleIcon,
	WalletIcon,
	WalletAddress,
	ReportsAnalytics,
	OrdersIcon,
	PaymentTransactionsIcon,
	ApprovementsProvider,
	DollarIcon,
	WithDrawal,
	LiqudityIcon,
	TranslationIcon,
	Approvements,
} from 'assets/inline-svg';
import {
	setTransactionsStateFilter,
	setTransactionsStateFiatFilter,
} from 'redux/reducers/providerTransactions/reducer';
import {
	getProviderApprovementsDefault,
	getApprovementsDefault,
} from 'redux/reducers/providerApprovements/selectors';
import { getWithdrawalFiat, getWithdrawal } from 'redux/reducers/projectWithdrawal/selectors';
import { routes } from 'routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRole } from 'redux/reducers/auth/selectors';
import { getStatusBurger } from 'redux/reducers/app/selectors';
import classNames from 'classnames';
import { getBurgerReducer } from 'redux/reducers/app/reducer';
import { getProviderApprovements } from 'redux/reducers/providerProjectApprovements/selectors';
import { getStorageValue, useLocalStorage } from 'hooks/useLocalStorage';
import { roles } from './roles';

export const ProviderSidebar: FC = () => {
	const administrationBaseMatch = useRouteMatch(routes.administrationBase);
	const walletsBaseMatch = useRouteMatch(routes.walletsBase);
	const marketBaseMatch = useRouteMatch(routes.marketBase);
	const transactionsMatch = useRouteMatch(routes.transactions);
	const userRole = useSelector(getUserRole);
	const statusBurger = useSelector(getStatusBurger);
	const dispatch = useDispatch();
	const approvementMerchant = useSelector(getProviderApprovementsDefault);
	const approvementProvider = useSelector(getApprovementsDefault);
	const approvements = useSelector(getProviderApprovements);

	const withdrawalFiat = useSelector(getWithdrawalFiat);
	const withdrawal = useSelector(getWithdrawal);
	// const [withDr, setWithDr, removewithDr] = useLocalStorage('withdrawal', '');
	// const [withApprovemen, setWithApprovemen, removewithApprovemen] = useLocalStorage(
	// 	'approvement',
	// 	'',
	// );
	const viewPointW = withdrawal?.data.length || withdrawalFiat?.data.length;
	// withdrawalFiat?.data.length ||
	// withdrawal?.data.length;
	const viewPointA = approvementMerchant?.data.length || approvementProvider?.data.length;

	const resetFilter = () => {
		dispatch(
			setTransactionsStateFilter({
				page: 1,
				dateFrom: undefined,
				dateTo: undefined,
				amountFrom: undefined,
				amountTo: undefined,
				currencyName: undefined,
				type: undefined,
				transactionId: undefined,
				currencyNetwork: undefined,
				status: undefined,
				riskScore: undefined,
				merchantId: undefined,
				projectId: undefined,
			}),
		);
		dispatch(
			setTransactionsStateFiatFilter({
				page: 1,
				dateFrom: undefined,
				dateTo: undefined,
				amountFrom: undefined,
				amountTo: undefined,
				currencyName: undefined,
				type: undefined,
				transactionId: undefined,
				currencyNetwork: undefined,
				status: undefined,
				riskScore: undefined,
				merchantId: undefined,
				projectId: undefined,
			}),
		);
	};

	useEffect(() => {
		if (!transactionsMatch) {
			resetFilter();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transactionsMatch]);

	const onBurger = () => {
		dispatch(getBurgerReducer(!statusBurger));
		window.scrollTo(0, 0);
	};

	return (
		<div className={classNames('sidebar', { 'sidebar-active': statusBurger })}>
			<nav className="sidebar-nav">
				<p className="sidebar-nav__title">Provider info</p>
				<ul className="sidebar-nav__list">
					{roles.wallets.includes(userRole || ' ') && (
						<li>
							<NavLink
								onClick={onBurger}
								to={`${routes.walletsBase}${routes.wallets.balances}?tabs=crypto`}
								isActive={() => !!walletsBaseMatch}
							>
								<span className="sidebar-nav-icon">
									<WalletIcon />
								</span>
								<span className="sidebar-nav-text">Wallets</span>
							</NavLink>
						</li>
					)}
					{roles.transactions.includes(userRole || ' ') && (
						<li>
							<NavLink onClick={onBurger} to={routes.transactions}>
								<span className="sidebar-nav-icon">
									<TransactionsIcon />
								</span>
								<span className="sidebar-nav-text">Transactions</span>
							</NavLink>
						</li>
					)}
					{roles.bank_account.includes(userRole || ' ') && (
						<li>
							<NavLink onClick={onBurger} to={routes.bankAccount}>
								<span className="sidebar-nav-icon">
									<TransactionsIcon />
								</span>
								<span className="sidebar-nav-text">Accounts</span>
							</NavLink>
						</li>
					)}
					{roles.invoices.includes(userRole || ' ') && (
						<li>
							<NavLink onClick={onBurger} to={routes.invoices}>
								<span className="sidebar-nav-icon">
									<DollarIcon />
								</span>
								<span className="sidebar-nav-text">Invoices</span>
							</NavLink>
						</li>
					)}
					{roles.orders.includes(userRole || ' ') && (
						<li>
							<NavLink onClick={onBurger} to={routes.orders}>
								<span className="sidebar-nav-icon">
									<OrdersIcon />
								</span>
								<span className="sidebar-nav-text">Orders</span>
							</NavLink>
						</li>
					)}
				</ul>
				<p className="sidebar-nav__title">Merchants info</p>
				<ul className="sidebar-nav__list">
					{roles.merchant.includes(userRole || ' ') && (
						<li>
							<NavLink onClick={onBurger} to={routes.merchantManagement}>
								<span className="sidebar-nav-icon">
									<TwoPeopleIcon />
								</span>
								<span className="sidebar-nav-text">Merchants</span>
							</NavLink>
						</li>
					)}
					{roles.wallet_addresses.includes(userRole || ' ') && (
						<li>
							<NavLink onClick={onBurger} to={`${routes.walletAddressBase}`}>
								<span className="sidebar-nav-icon">
									<WalletAddress />
								</span>
								<span className="sidebar-nav-text">Wallet addresses</span>
							</NavLink>
						</li>
					)}
					{roles.pending_withdr.includes(userRole || ' ') && (
						<li>
							<NavLink onClick={onBurger} to={routes.pendingWithdrawal}>
								<span className="sidebar-nav-icon">
									{getStorageValue('withdrawal', '')?.length || viewPointW ? (
										<span className="sidebar-nav-dot" />
									) : (
										<WithDrawal />
									)}
								</span>
								<span className="sidebar-nav-text">TX Pending</span>
							</NavLink>
						</li>
					)}

					{roles.approvements.includes(userRole || ' ') && (
						<li>
							<NavLink onClick={onBurger} to="/approvements">
								<span className="sidebar-nav-icon">
									{getStorageValue('approvement', '')?.length || viewPointA ? (
										<span className="sidebar-nav-dot" />
									) : (
										<ApprovementsProvider />
									)}
								</span>
								<span className="sidebar-nav-text">Approvements</span>
							</NavLink>
						</li>
					)}
					{roles.projects_approvements.includes(userRole || ' ') && (
						<li>
							<NavLink onClick={onBurger} to={routes.projectApprovements}>
								<span className="sidebar-nav-icon">
									{getStorageValue('pending', '')?.length || approvements?.data.length ? (
										<span className="sidebar-nav-dot" />
									) : (
										<Approvements />
									)}
								</span>
								<span className="sidebar-nav-text">New Projects</span>
							</NavLink>
						</li>
					)}
				</ul>
				<p className="sidebar-nav__title">Settings</p>
				<ul className="sidebar-nav__list">
					{roles.administration.includes(userRole || ' ') && (
						<li>
							<NavLink
								onClick={onBurger}
								to={`${routes.administrationBase}${routes.administration.teamMembers}`}
								isActive={() => !!administrationBaseMatch}
							>
								<span className="sidebar-nav-icon">
									<ProviderAvatarCheckIcon />
								</span>
								<span className="sidebar-nav-text">Users</span>
							</NavLink>
						</li>
					)}
					{roles.market.includes(userRole || ' ') && (
						<li>
							<NavLink
								onClick={onBurger}
								to={`${routes.marketBase}${routes.market.currencies}`}
								isActive={() => !!marketBaseMatch}
							>
								<span className="sidebar-nav-icon">
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
											stroke="#4285F5"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M9 3V21"
											stroke="#4285F5"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								<span className="sidebar-nav-text">Market</span>
							</NavLink>
						</li>
					)}
					{roles.liquidity.includes(userRole || ' ') && (
						<li>
							<NavLink to={routes.liquidityBase}>
								<span className="sidebar-nav-icon">
									<LiqudityIcon />
								</span>
								<span className="sidebar-nav-text">Liquidity</span>
							</NavLink>
						</li>
					)}
					{/* {roles.comissions.includes(userRole || ' ') &&
						{
							/* <li>
						<NavLink to="/">
							<span className="sidebar-nav-icon">
								<ComissionsIcon />
							</span>
							<span className="sidebar-nav-text">Comissions</span>
						</NavLink>
						</li> */}

					{/* roles.blog.includes(userRole || ' ') &&

							 <li>
						<NavLink to="/">
							<span className="sidebar-nav-icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
										stroke="#4285F5"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							<span className="sidebar-nav-text">Support/Blog</span>
						</NavLink>
				</li> */}

					{roles.reports_analytics.includes(userRole || ' ') && (
						<li>
							<NavLink onClick={onBurger} to={routes.reportsAnalyticsBase}>
								<span className="sidebar-nav-icon">
									<ReportsAnalytics />
								</span>
								<span className="sidebar-nav-text">Reports/Analytics</span>
							</NavLink>
						</li>
					)}

					{roles.tickets.includes(userRole || ' ') && (
						<li>
							{/* Previous */}
							{/* <a href="https://www.zoho.com/desk/login.html" target="_blank" rel="noreferrer"> */}
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a href="#" rel="noreferrer">
								<span className="sidebar-nav-icon">
									<TranslationIcon />
								</span>
								<span className="sidebar-nav-text">Translations</span>
							</a>
						</li>
					)}

					{/* roles.translations.includes(userRole || ' ') &&

							 <li>
						<NavLink to="/">
							<span className="sidebar-nav-icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M13.05 20C12.7333 20 12.5125 19.9042 12.3875 19.7125C12.2625 19.5208 12.2417 19.275 12.325 18.975L15.3 11.175C15.3667 10.9583 15.5083 10.775 15.725 10.625C15.9417 10.475 16.175 10.4 16.425 10.4C16.6083 10.4 16.8208 10.4792 17.0625 10.6375C17.3042 10.7958 17.4667 10.975 17.55 11.175L20.525 18.925C20.625 19.2583 20.6042 19.5208 20.4625 19.7125C20.3208 19.9042 20.0917 20 19.775 20C19.6083 20 19.4583 19.95 19.325 19.85C19.1917 19.75 19.1 19.6333 19.05 19.5L18.375 17.525H14.5L13.825 19.475C13.7583 19.6083 13.6542 19.7292 13.5125 19.8375C13.3708 19.9458 13.2167 20 13.05 20ZM14.975 16.125H17.825L16.425 12.2H16.375L14.975 16.125ZM8.15 9.175C8.35 9.55833 8.575 9.91667 8.825 10.25C9.075 10.5833 9.35 10.925 9.65 11.275C10.25 10.625 10.7458 9.97083 11.1375 9.3125C11.5292 8.65417 11.8583 7.95 12.125 7.2H4.05C3.81667 7.2 3.625 7.125 3.475 6.975C3.325 6.825 3.25 6.63333 3.25 6.4C3.25 6.16667 3.325 5.975 3.475 5.825C3.625 5.675 3.81667 5.6 4.05 5.6H8.85V4.8C8.85 4.56667 8.925 4.375 9.075 4.225C9.225 4.075 9.41667 4 9.65 4C9.88333 4 10.075 4.075 10.225 4.225C10.375 4.375 10.45 4.56667 10.45 4.8V5.6H15.25C15.4833 5.6 15.675 5.675 15.825 5.825C15.975 5.975 16.05 6.16667 16.05 6.4C16.05 6.63333 15.975 6.825 15.825 6.975C15.675 7.125 15.4833 7.2 15.25 7.2H13.725C13.4583 8.11667 13.0833 9.02083 12.6 9.9125C12.1167 10.8042 11.5167 11.6417 10.8 12.425L12.725 14.4L12.125 16.025L9.65 13.6L6.25 17C6.06667 17.1833 5.86667 17.275 5.65 17.275C5.43333 17.275 5.25 17.1833 5.1 17C4.91667 16.85 4.825 16.6667 4.825 16.45C4.825 16.2333 4.91667 16.0333 5.1 15.85L8.575 12.4C8.20833 12 7.87917 11.5958 7.5875 11.1875C7.29583 10.7792 7.04167 10.3333 6.825 9.85C6.675 9.58333 6.6625 9.34167 6.7875 9.125C6.9125 8.90833 7.15833 8.8 7.525 8.8C7.60833 8.8 7.71667 8.83333 7.85 8.9C7.98333 8.96667 8.08333 9.05833 8.15 9.175Z"
										fill="#4285F5"
									/>
								</svg>
							</span>
							<span className="sidebar-nav-text">Translations</span>
						</NavLink>
					</li> */}
				</ul>
			</nav>
		</div>
	);
};
