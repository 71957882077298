import { FC } from 'react';
import { fromBackFormat, toTimezoneH12 } from 'utils/dates';
import { IProjectInfoProps } from './types';

export const ProjectInfo: FC<IProjectInfoProps> = ({ project }) => {
	return (
		<div className="line-info-block ">
			<div className="line-info">
				<p className="line-info__name line-info__name--type2">Project ID:</p>
				<p className="line-info__text line-info__text--type2">{project.id}</p>
			</div>

			<div className="line-info">
				<p className="line-info__name line-info__name--type2">Registration date:</p>
				<p className="line-info__text line-info__text--type2">
					{project.created_at && toTimezoneH12(fromBackFormat(project.created_at))}
				</p>
			</div>

			<div className="line-info">
				<p className="line-info__name line-info__name--type2">Last status change:</p>
				<p className="line-info__text line-info__text--type2">
					{project.updated_at && toTimezoneH12(fromBackFormat(project.updated_at))}
				</p>
			</div>
		</div>
	);
};
