import { FC } from 'react';
import { IApiGetProjectListItem, IBalance } from 'services/api/merchant/types';
import { trimLastZeros } from 'utils/strings';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { IcUserIcon, WalletIcon } from 'assets/inline-svg';
import { useSelector } from 'react-redux';
import { getProviderCurrencyAll } from 'redux/reducers/providerCurrency/selectors';
import { Link } from 'react-router-dom';
import { getUserId } from 'redux/reducers/auth/selectors';
import { useMerchantMatchUrl } from 'hooks';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ProjectMenu } from '../ProjectItem/ProjectMenu/ProjectMenu';

interface IPropsMerchantProjectCard {
	item: IApiGetProjectListItem;
}
const FULL_PERCENT = 100;

interface ICurrencyItemProps extends IBalance {
	sum: number;
	currenciesCount: number;
	dec?: number;
}

const CurrencyItem: FC<ICurrencyItemProps> = ({
	sum,
	currenciesCount,
	balance,
	currency_code,
	balance_in_usd,
	dec,
}) => {
	const percent = Math.min(Math.ceil((balance_in_usd / sum) * FULL_PERCENT), 99);
	const currencyAll = useSelector(getProviderCurrencyAll);
	const currency = currencyAll.find((item) => currency_code === item.currency_code);
	const decimals = dec || 2;

	return (
		<div className={styles.currency}>
			<img src={currency?.img_path} alt="" className={styles.currency__icon} />
			<div className={styles.currency__info}>
				<div className={styles.currency__value}>
					<span>{currency_code}</span>
					<p>{balance.toFixed(decimals)}</p>
				</div>
				<div className={styles.currency__progress}>
					<div className={styles.currency__progressValue} style={{ width: `${percent}%` }} />
				</div>
			</div>
		</div>
	);
};

const MerchantProjectCard: FC<IPropsMerchantProjectCard> = ({ item }) => {
	const { total_balance_in_default_currency, default_currency = '' } = item;
	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = getViewDecimal(default_currency.toLowerCase());
	const balances = item.balances ? Object.values(item.balances) : [];
	balances.sort((x, y) => y.balance_in_usd - x.balance_in_usd);
	const sumBalance = balances.reduce((sum, current) => sum + current.balance_in_usd, 0);
	const merchantId = useSelector(getUserId);
	const merchantUrl = useMerchantMatchUrl();
	return (
		<Link
			to={`${merchantUrl || `/merchant/${String(merchantId)}`}/project/${item.id}`}
			className={styles.card}
		>
			<ProjectMenu item={item} />
			<div className={styles.head}>
				<div className={styles.title}>
					<img src={item.file_icon} alt="" className={styles.icon} />
					<p>{item.name}</p>
				</div>
				{!!item.is_active && <span className={styles.isActive}>Active</span>}
				{!item.is_active && (
					<span className={classNames(styles.isNotActive, styles.isActive)}>Inactive</span>
				)}
			</div>
			<div className={styles.balance}>
				<p className={styles.balance__title}>Total Balance</p>
				<p className={styles.balance__value}>
					{trimLastZeros(total_balance_in_default_currency.toFixed(viewDecimals))}{' '}
					<span className={styles.balance__currency}>{default_currency?.toUpperCase()}</span>
				</p>
			</div>
			<p className={styles.currencies__title}>Top 3 Currencies</p>
			<div className={styles.currencies__items}>
				{!!balances?.length &&
					balances.slice(0, 3)?.map((balance) => {
						const decimals = getViewDecimal(balance.currency_code);

						return (
							<CurrencyItem
								sum={sumBalance}
								dec={decimals}
								currenciesCount={balances.length}
								balance={balance.balance}
								balance_in_usd={balance.balance_in_usd}
								currency_network={balance.currency_network}
								currency_code={balance.currency_code}
							/>
						);
					})}
			</div>
			<div className={styles.info}>
				<div className={styles.info__item}>
					<div className={styles.info__icon}>
						<WalletIcon />
					</div>
					<div>
						<p className={styles.info__title}>Wallets</p>
						<p className={styles.info__value}>{item.wallets}</p>
					</div>
				</div>
				<div className={styles.info__item}>
					<div className={styles.info__icon}>
						<IcUserIcon />
					</div>
					<div>
						<p className={styles.info__title}>Users</p>
						<p className={styles.info__value}>{item.clients}</p>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default MerchantProjectCard;
