import { useField, useFormikContext } from 'formik';
import classNames from 'classnames';
import { Select } from 'v2Components/UI/GeneralSelect';
import { ISelectField } from './types';
import styles from './styles.module.scss';

export const SelectField = <T extends Record<string, any>>({
	name,
	title,
	options,
	lastElem,
	change,
	...rest
}: ISelectField<T>) => {
	const [field, meta, helper] = useField(name);
	const { value } = field;
	const { submitCount } = useFormikContext();
	const isShowError = (meta.touched || submitCount > 0) && meta.error;
	const { setValue, setTouched } = helper;

	const handleSelect = (_value: string | null) => {
		change?.(_value);
		setValue(_value);
	};

	const onSelectClick = () => {
		setTouched(true);
	};

	return (
		<Select<T>
			options={options}
			title={title}
			value={value}
			isShowError={!!isShowError}
			onChange={handleSelect}
			onClick={onSelectClick}
			className={classNames({ [styles['select-block__last-elem']]: lastElem })}
			helperNotification={
				isShowError ? (
					<p
						className={classNames(
							styles['select-block__notification'],
							styles['select-block__notification--error'],
						)}
					>
						{meta.error}
					</p>
				) : null
			}
			{...rest}
		/>
	);
};
