import { FC } from 'react';
import { CheckBox } from 'UI/Forms/CheckBox/CheckBox';
import { TBoxProps } from './types';

export const NotificationSwitcherBox: FC<TBoxProps> = ({ title, text, value, onClick, name }) => {
	return (
		<div className="notifications-item">
			<div className="notifications-item__info">
				<div className="notifications-item__title">
					<p>{title}</p>
				</div>
				<div className="notifications-item__text">
					<p>{text}</p>
				</div>
			</div>
			<CheckBox value={Number(value)} onClick={onClick} name={name} />
		</div>
	);
};
