import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { api, showError } from 'services';
import { IApiGetProjectListItem, TApiGetProjectListResponse } from 'services/api/merchant/types';
import { log } from 'util';
import {
	activateProjectMerchantRequest,
	activateProjectRequest,
	activateProjectSuccess,
	deactivateProjectMerchantRequest,
	deactivateProjectRequest,
	deactivateProjectSuccess,
	deleteProjectMerchantRequest,
	deleteProjectRequest,
	deleteProjectSuccess,
	getProjectListError,
	getProjectListMerchantRequest,
	getProjectListSuccess,
	postOwnProjectMerchantCreateRequest,
	postProjectMerchantCreateRequest,
	postProjectMerchantCreateSuccess,
	getProjectListProviderRequest,
} from './reducer';
import {
	IActivateProjectPayload,
	ICreateProjectPayload,
	IDeactivateProjectPayload,
	IDeleteProjectPayload,
	IGetProjectListPayload,
} from './types';
// import { notify } from '../../../utils/notify';

function* getProjectListMerchantWorker({ payload }: PayloadAction<IGetProjectListPayload>) {
	const { apiParams, onFinally } = payload;

	try {
		const data: TApiGetProjectListResponse = yield call(
			api.merchant.getProjectListMerchant,
			apiParams,
		);
		yield put(getProjectListSuccess(data));
	} catch (error) {
		yield put(getProjectListError());
		showError(error);
	} finally {
		onFinally?.();
	}
}

function* getProjectListProviderWorker({ payload }: PayloadAction<IGetProjectListPayload>) {
	const { apiParams, onFinally } = payload;

	try {
		const data: TApiGetProjectListResponse = yield call(
			api.merchant.getProjectListProvider,
			apiParams,
		);
		yield put(getProjectListSuccess(data));
	} catch (error) {
		yield put(getProjectListError());
		showError(error);
	} finally {
		onFinally?.();
	}
}

function* activateProjectWorker(action: PayloadAction<IActivateProjectPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		yield call(api.merchant.activateProject, apiParams);
		yield put(activateProjectSuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deactivateProjectWorker(action: PayloadAction<IDeactivateProjectPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		yield call(api.merchant.deactivateProject, apiParams);
		yield put(deactivateProjectSuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* activateProjectMerchantWorker(action: PayloadAction<IActivateProjectPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		yield call(api.merchant.activateProjectMerchant, apiParams);
		yield put(activateProjectSuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deactivateProjectMerchantWorker(action: PayloadAction<IDeactivateProjectPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		yield call(api.merchant.deactivateProjectMerchant, apiParams);
		yield put(deactivateProjectSuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteProjectWorker(action: PayloadAction<IDeleteProjectPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		yield call(api.merchant.deleteProject, apiParams);
		yield put(deleteProjectSuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteProjectMerchantWorker(action: PayloadAction<IDeleteProjectPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		yield call(api.merchant.deleteMerchantProject, apiParams);
		yield put(deleteProjectSuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* postProjectMerchantCreateWorker(action: PayloadAction<ICreateProjectPayload>) {
	const { apiParams, onSuccess } = action.payload;
	try {
		const data: TApiGetProjectListResponse = yield call(
			api.merchant.postProject,
			action.payload.apiParams,
		);
		yield put(postProjectMerchantCreateSuccess());
		onSuccess?.();
		action.payload.history.push(`/merchant/${apiParams.merchant_id}/projects`);
	} catch (error) {
		showError(error);
	}
}

function* postOwnProjectMerchantCreateWorker(action: PayloadAction<ICreateProjectPayload>) {
	const { setActiveCard } = action.payload;
	try {
		const data: IApiGetProjectListItem = yield call(
			api.merchant.postOwnProject,
			action.payload.apiParams,
		);
		yield put(postProjectMerchantCreateSuccess());
		yield setActiveCard?.(data.id);
	} catch (error) {
		showError(error);
	}
}

export function* merchantProjectsSaga() {
	yield takeLatest(getProjectListMerchantRequest, getProjectListMerchantWorker);
	yield takeLeading(activateProjectRequest, activateProjectWorker);
	yield takeLeading(deactivateProjectRequest, deactivateProjectWorker);
	yield takeLeading(activateProjectMerchantRequest, activateProjectMerchantWorker);
	yield takeLeading(deactivateProjectMerchantRequest, deactivateProjectMerchantWorker);
	yield takeLeading(deleteProjectRequest, deleteProjectWorker);
	yield takeLeading(deleteProjectMerchantRequest, deleteProjectMerchantWorker);
	yield takeLeading(postProjectMerchantCreateRequest, postProjectMerchantCreateWorker);
	yield takeLeading(getProjectListProviderRequest, getProjectListProviderWorker);
	yield takeLeading(postOwnProjectMerchantCreateRequest, postOwnProjectMerchantCreateWorker);
}
