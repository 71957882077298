import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useProjectMatchUrl } from 'hooks';
import { toUTC } from 'utils/dates';
import { shortNotation, trimBottomLine, capitalize, trimLastZeros } from 'utils/strings';
import { isExist } from 'utils/numbers';
import { useViewDecimals } from 'hooks/useViewDecimals';

import { ITableRow } from './types';

export const TableRow: FC<ITableRow> = ({ item }) => {
	const {
		id,
		type,
		hash_transaction: hashTransaction,
		created_at: createdAt,
		status,
		amount,
		currency_name,
		currency_code,
		currency_network,
	} = item;

	const { getViewDecimal } = useViewDecimals();

	const viewDecimals = currency_code ? getViewDecimal(currency_code) : undefined;

	const projectUrl = useProjectMatchUrl();

	const statusColor = useMemo(() => {
		switch (status) {
			case 'success':
				return 'green';
			case 'in_progress':
				return 'grey';
			case 'error':
				return 'red';
			default:
				return '';
		}
	}, [status]);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				{type && (
					<Link to={`${projectUrl}/transaction/details/${id}/crypto/${type}`} className="td-value">
						{id}
					</Link>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p className="td-value">{type && capitalize(trimBottomLine(type))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Tx Hash</p>
				<div className="hint-value">
					<p className="td-value">
						<span className="tooltip-item">
							{' '}
							{shortNotation(String(hashTransaction), 8, 8) || '-'}
							<span className="tooltip tooltip--width-320  tooltip--top tooltip--top-copy tooltip--center tooltip--arrow-bottom-center">
								<span className="tooltip__text tooltip__text--ta-c">
									<span>{hashTransaction || ''}</span>
								</span>
							</span>
						</span>
					</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Data&amp;time</p>
				<p className="td-value">{createdAt ? `${toUTC(createdAt)}` : '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<p className={`td-value ${statusColor}`}>{status && capitalize(trimBottomLine(status))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p className="td-value">
					{isExist(amount) ? trimLastZeros(Number(amount).toFixed(viewDecimals)) : '-'}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Currency</p>
				<p className="td-value">{`${String(currency_name)} (${String(
					currency_network,
				)?.toUpperCase()})`}</p>
			</div>
		</div>
	);
};
