import { FC, useEffect, useMemo } from 'react';
import L from 'i18n-react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { languages } from 'languages';

import { appLanguage } from 'redux/reducers/app/selectors';
import { getUserRole, getUserType } from 'redux/reducers/auth/selectors';
import { UserSupport, RemoveSupport } from 'services/zoho/zoho';
import { constUserTypes } from 'services/constants/user-types';
import { MainMerchantRoutes } from 'routes/MainMerchantRoutes/MainMerchantRoutes';
import { routes } from 'routes/routes';
import { authList, routeList, routeListV2, titleList } from 'routes/routeListProvider';
import { PaymentWidgetPage } from 'pages/Public/PaymentWidgetPage/PaymentWidgetPage';
import { ProviderLayout } from 'layouts/ProviderLayout/ProviderLayout';

export const App: FC = () => {
	const { pathname } = useLocation();
	const language = useSelector(appLanguage);
	const userType = useSelector(getUserType);
	const userRole = useSelector(getUserRole);
	L.setTexts(languages[language]);

	useEffect(() => {
		if (userType === constUserTypes.MERCHANT || pathname === '/login-merchant') {
			UserSupport();
		} else {
			RemoveSupport();
		}
		return () => {
			RemoveSupport();
		};
	}, [userType, pathname]);

	const isDesktop = useMemo(() => window.screen.width > 1180, []);
	const title = useMemo(() => titleList[pathname], [pathname]);
	const indexMath = useRouteMatch(pathname + routes.index);

	if (indexMath?.path === '/') {
		const indexPath =
			userType === constUserTypes.MERCHANT ? '/projects' : '/wallets/balances?tabs=crypto';
		return <Redirect to={indexPath} />;
	}
	return (
		<Switch>
			<Route exact path={routes.paymentMethod} component={PaymentWidgetPage} />
			{userType === constUserTypes.MERCHANT ? (
				<MainMerchantRoutes />
			) : (
				<Switch>
					{authList.map((route) =>
						route.role?.includes(userRole || '') ? (
							<Route
								key={route.path}
								exact={route.exact}
								path={route.path}
								component={route.component}
							/>
						) : null,
					)}
					{routeList.map((route) =>
						route.role?.includes(userRole || '') ? (
							<Route
								key={route.path}
								exact={route.exact}
								path={route.path}
								component={route.component}
							/>
						) : null,
					)}
					<ProviderLayout title={title} v2={isDesktop}>
						{routeListV2.map((route) =>
							route.role?.includes(userRole || '') ? (
								<Route
									key={route.path}
									exact={route.exact}
									path={route.path}
									component={route.component}
								/>
							) : null,
						)}
					</ProviderLayout>
					<Redirect to="/wallets/balances" />
				</Switch>
			)}
		</Switch>
	);
};
