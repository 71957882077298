import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import { routes } from 'routes/routes';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { IBackLink } from './types';

const BackLink: FC<IBackLink> = ({ isProject, isHistory }) => {
	const { merchantId, projectId, currencyTabs } = useParams<{
		merchantId: string;
		projectId: string;
		currencyTabs: string;
	}>();
	const url_string = window.location.href;
	const urlObg = new URL(url_string);
	const tabsUrl = urlObg.searchParams.get('tabs') || 'crypto';
	const providerBackLink = routes.transactions;
	const projectBackLink = `/merchant/${merchantId}/project/${projectId}/transactions/transactions/?tabs=${tabsUrl}`;
	const historyBackLink = `/wallets/transaction-history?tabs=${tabsUrl}`;
	let backLink = isProject ? projectBackLink : providerBackLink;
	backLink = isHistory ? historyBackLink : backLink;

	return (
		<Link to={backLink} className="back-page">
			<ArrowLeftIcon />
			<span>Back to Transaction</span>
		</Link>
	);
};

export default BackLink;
