import { PopUpCloseIcon } from 'assets/inline-svg';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProviderAllCurrencySettingRequest,
	getProviderAllCurrencyRequest,
} from 'redux/reducers/providerCurrency/reducer';
import {
	getProviderCurrencyAll,
	getProviderCurrencyAllNoSettings,
} from 'redux/reducers/providerCurrency/selectors';
import { useParams } from 'react-router';
import { createOrderInvoiceProviderRequest } from 'redux/reducers/providerOrders/reducer';
import { createOrderInvoiceMerchantRequest } from 'redux/reducers/merchantOrders/reducer';
import { IInvoiceOrderRequest } from 'redux/reducers/merchantOrders/types';
import { FormInvoiseOrder } from '../FormInvoiseOrder/FormInvoiseOrder';
import { IPropsPopUps } from '../types';
import { IInitialValuesOrders } from '../../types';
import { PopUpDetails } from '../PopUpDetails/PopUpDetails';

const initialValues: IInitialValuesOrders = {
	order_id: '',
	currencyPaid: '',
	currencyRequest: '',
	amount_request: '',
	// rate: '',
	currency_paid: '',
	currency_paid_network: '',
	amount_to_pay: '',
	expires_time_minutes: '',
	client_email: '',
};

const validationSchema = yup.object().shape({
	currencyRequest: yup.string().required('Currency is required'),
	currencyPaid: yup.string().required('Pay in currency is required'),
	amount_request: yup.number().required('Amount is requierd').typeError('Amount must be a number'),
	amount_to_pay: yup.number().required('Amount is requierd').typeError('Amount must be a number'),
	expires_time_minutes: yup.number().required('Expire ttime is required'),
	client_email: yup.string().required('Client email is required').email('Invalid email format'),
});

export const PopUpInvoice: FC<IPropsPopUps> = ({ onAction, orderId, defaultValues }) => {
	const currencySettings = useSelector(getProviderCurrencyAll);
	const currencies = useSelector(getProviderCurrencyAllNoSettings);
	const dispatch = useDispatch();
	const { projectId } = useParams<{ projectId: string }>();
	const [data, setData] = useState<IInitialValuesOrders | null>(null);
	const [openDetailsPopup, setOpenDetailsPopup] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);

	const putDataFromForm = (values: IInitialValuesOrders) => {
		setData(values);
		setOpenDetailsPopup(true);
	};

	const closeConfirm = () => {
		setOpenDetailsPopup(false);
		setData(null);
	};

	const onSubmit = (event: React.MouseEvent) => {
		let params: IInvoiceOrderRequest | null = null;
		const close = () => onAction(event, '');
		setLoader(true);

		if (data) {
			params = {
				order_id: data.order_id,
				amount_request: Number(data.amount_request),
				// rate: data?.rate,
				// amount_to_pay: data.amount_to_pay,
				currency_paid: data.currency_paid,
				currency_paid_network: data.currency_paid_network,
				expires_time_minutes: data.expires_time_minutes,
				client_email: data.client_email,
			};
		}
		projectId
			? dispatch(
					createOrderInvoiceMerchantRequest({
						params,
						closePopup: close,
					}),
			  )
			: dispatch(
					createOrderInvoiceProviderRequest({
						params,
						closePopup: close,
					}),
			  );
	};

	useEffect(() => {
		dispatch(getProviderAllCurrencyRequest());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getProviderAllCurrencySettingRequest({ apiParams: {} }));

		return () => {
			document.body.style.overflow = '';
		};
	}, [dispatch]);

	return openDetailsPopup ? (
		<PopUpDetails closeConfirm={closeConfirm} onSubmit={onSubmit} data={data} loader={loader} />
	) : (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup popup--width-460">
					<button
						onClick={(event: MouseEvent) => onAction(event, '')}
						type="button"
						className="popup__close"
						aria-label="button"
					>
						<PopUpCloseIcon />
					</button>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={putDataFromForm}
					>
						<Form>
							<FormInvoiseOrder
								onAction={onAction}
								orderId={orderId}
								currencySettings={currencySettings}
								currencies={currencies}
								defaultValues={defaultValues}
							/>
						</Form>
					</Formik>
				</div>
			</div>
		</div>
	);
};
