import { ReactNode, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Form, Formik } from 'formik';
import Button from 'v2Components/UI/Button';
import {
	ICreateTRXDelegation,
	IDelete,
	IStakingParams,
} from 'redux/reducers/providerTRXManagement/types';
import styles from './styles.module.scss';

interface IProps {
	title: string;
	children: ReactNode;
	handleSubmit: (params: any) => void;
}
const DropdownDialogItem = ({ title, children, handleSubmit }: IProps) => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const submit = (params: IDelete | IStakingParams | ICreateTRXDelegation) => {
		handleSubmit(params);
		handleClose();
	};
	return (
		<>
			<div onClick={handleOpen} className={styles.open}>
				{title}
			</div>
			<Dialog
				open={open}
				onClose={handleClose}
				className={styles.dialog}
				PaperProps={{ classes: styles.dialog }}
			>
				<Formik onSubmit={submit} initialValues={{}}>
					<Form>
						<DialogTitle className={styles.dialog__title}>{title}</DialogTitle>
						<DialogContent className={styles.dialog__content}>{children}</DialogContent>
						<DialogActions className={styles.dialog__actions}>
							<Button noBorder type="button" onClick={handleClose} className={styles.cancel}>
								Cancel
							</Button>
							<Button type="submit">{title}</Button>
						</DialogActions>
					</Form>
				</Formik>
			</Dialog>
		</>
	);
};

export default DropdownDialogItem;
