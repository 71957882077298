import { trimLastZeros } from 'utils/strings';
import { Token } from 'v2Components/UI/Token';
import { CopyAddress } from 'v2Components/commomComponents/Table/CellComponents';
import { ArrowDownIcon, ArrowUpIcon, EditIcon, NoIcon, YesIcon } from 'assets/inline-svg';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import Deposit from 'v2Pages/Provider/WalletsPage/components/TabsContent/Balances/PopUp/Deposit';
import Withdraw from 'v2Pages/Provider/WalletsPage/components/TabsContent/Balances/PopUp/Withdraw';
import Button from 'v2Components/UI/Button';
import PopUpWithdrawalFiat from 'v2Pages/Provider/WalletsPage/components/TabsContent/Balances/PopUp/Withdraw/PopUpWithdrawalFiat';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Field from 'v2Components/UI/Field';
import { Select } from 'v2Components/UI/GeneralSelect';
import { getCurrencyCryptoWallets } from 'redux/reducers/providerCurrency/selectors';
import { TApiGetProviderAllCurrencySettingResponse } from 'redux/reducers/providerCurrency/types';
import { IInSubmitProps } from '../../types';

interface IProps {
	currency: TApiGetProviderAllCurrencySettingResponse;
	onSubmit: (body: IInSubmitProps) => void;
}
const TableRow = ({ currency: currencyItem, onSubmit }: IProps) => {
	const {
		currency_id,
		id,
		currency_network,
		deposit_wallet,
		hot_balance,
		total_merchant_balance,
		alert_min_balance,
		reserve_wallet,
		currency,
		view_decimals,
	} = currencyItem;
	const currencyCryptoWallets = currencyItem?.provider_reserve_wallets;
	const [reserveWallet, setReserveWallet] = useState(reserve_wallet);
	const [changeableMode, setChangeableMode] = useState<number>(-1);
	const [depositPopUp, setDepositPopUp] = useState(0);
	const [withdrawPopUp, setWithdrawPopUp] = useState(0);
	const [popUpOpen, setPopUpOpen] = useState(0);
	const withdrawPopUpModal = () => setWithdrawPopUp(0);
	const depositPopUpModal = () => setDepositPopUp(0);

	const switchOnChangeableMode = () => setChangeableMode(id);
	const switchOffChangeableMode = () => setChangeableMode(-1);
	const onClosePopUps = () => setPopUpOpen(0);
	const handleOpenModalDeposit = () => setDepositPopUp(id);

	const initialValues = {
		deposit_wallet: deposit_wallet || '',
		hot_balance: hot_balance || '',
		alert_min_balance: !!alert_min_balance || alert_min_balance === 0 ? alert_min_balance : '',
	};

	const formik = useFormik({
		initialValues,
		onSubmit: (values, actions) => {
			actions.setSubmitting(false);
			const { alert_min_balance: _alertMinBalance } = values;
			const body = {
				currency_setting_id: currency_id,
				deposit_wallet: values.deposit_wallet || deposit_wallet,
				hot_balance: values.hot_balance === '' ? hot_balance : values.hot_balance,
				alert_min_balance:
					!!_alertMinBalance || _alertMinBalance === 0 ? _alertMinBalance : alert_min_balance,
				reserve_wallet: reserveWallet,
			};
			onSubmit(body);
			switchOffChangeableMode();
		},
	});

	return [
		{
			title: 'Currency',
			children: (
				<Token
					name={currency.name}
					code={
						currency.code === currency_network
							? currency.code.toUpperCase()
							: `${currency.code.toUpperCase()}, ${currency_network.toUpperCase()}`
					}
					img={currency.img_path}
					type="second"
				/>
			),
		},
		{
			title: 'Address',
			children: <CopyAddress value={deposit_wallet} />,
		},
		{
			title: 'Balance',
			children: trimLastZeros(Number(hot_balance).toFixed(view_decimals)),
		},
		{
			title: 'Merchant balance',
			children: trimLastZeros(Number(total_merchant_balance).toFixed(view_decimals)) || '0',
		},
		{
			title: 'Get alert at',
			children:
				changeableMode === id ? (
					<Field
						type="number"
						classNameCustom="input--mb-0"
						name="alert_min_balance"
						value={formik.values.alert_min_balance}
						onChange={formik.handleChange}
					/>
				) : (
					alert_min_balance.toString()
				),
		},
		{
			title: 'Reserve Wallet',
			children: changeableMode === id && (
				<>
					<CopyAddress value={reserve_wallet} hideTooltip={!reserve_wallet} />
					<Select
						options={currencyCryptoWallets}
						value={reserveWallet}
						onChange={setReserveWallet}
						className="select--mb-0 --width-100"
						labelProp="reserve_wallet_name"
						valueProp="reserve_wallet"
					/>
				</>
			),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					{changeableMode === id ? (
						<>
							<button
								aria-label="button"
								type="submit"
								onClick={() => {
									formik.submitForm();
								}}
							>
								<YesIcon />
							</button>
							<button aria-label="button" type="button" onClick={switchOffChangeableMode}>
								<NoIcon />
							</button>
						</>
					) : (
						<>
							<Button onClick={handleOpenModalDeposit} type="button" tableAction>
								<ArrowDownIcon />
								Deposit
							</Button>
							<Button onClick={() => setWithdrawPopUp(id)} type="button" tableAction>
								<ArrowUpIcon />
								Withdrawal
							</Button>
							<Button onClick={switchOnChangeableMode} type="button" simple="regular">
								<EditIcon />
							</Button>
						</>
					)}
					<PopupModal open={depositPopUp === id} onClose={depositPopUpModal}>
						<Deposit onClose={depositPopUpModal} item={currencyItem} />
					</PopupModal>
					{popUpOpen > 0 && (
						<PopUpWithdrawalFiat
							onClosePopUps={onClosePopUps}
							popUpOpen={popUpOpen === id}
							item={currencyItem}
						/>
					)}
					<PopupModal open={withdrawPopUp === id} onClose={withdrawPopUpModal}>
						<Withdraw onClose={withdrawPopUpModal} item={currencyItem} isCrypto />
					</PopupModal>
				</TableButtons>
			),
		},
	];
};

export default TableRow;
