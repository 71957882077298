import { FC } from 'react';
import * as yup from 'yup';
import L from 'i18n-react';
import { Formik, Form } from 'formik';
import { authPasswordRegexp } from 'utils/formValidation';
import { IFormProps } from './types';
import { PasswordField } from '../PasswordField/PasswordField';
import styles from './styles.module.scss';

export const SecurityForm: FC<IFormProps> = ({ onSubmit }) => {
	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required(String(L.translate('Validation.password_required')))
			.matches(authPasswordRegexp, String(L.translate('Validation.password_auth_requirements'))),
		new_password: yup
			.string()
			.required(String(L.translate('Validation.password_required')))
			.matches(authPasswordRegexp, String(L.translate('Validation.password_auth_requirements'))),
		confirm_password: yup
			.string()
			.required(String(L.translate('Validation.password_required')))
			.oneOf([yup.ref('new_password'), null], `Password doesn't match`)
			.matches(authPasswordRegexp, String(L.translate('Validation.password_auth_requirements'))),
	});

	return (
		<Formik
			initialValues={{
				password: '',
				new_password: '',
				confirm_password: '',
				totp: '',
			}}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			{({ isSubmitting, resetForm }) => (
				<Form className={styles.form}>
					<div className="info-title">
						<p>Change Password</p>
					</div>
					<PasswordField name="password" title="Current password" placeholder="Current password" />
					<PasswordField
						name="new_password"
						title="Create new password"
						placeholder="Enter new password"
					/>
					<PasswordField
						name="confirm_password"
						title="Confirm new password"
						placeholder="Enter new password"
					/>
					<div className="buttons buttons--type2">
						{/* <button type="button" onClick={() => resetForm()} className="button button--type3">
							Reset Password
						</button> */}
						<button type="submit" className="button">
							Change password
						</button>
					</div>
				</Form>
			)}
		</Formik>
	);
};
