import { Link, useParams } from 'react-router-dom';
import { FC } from 'react';
import { routes } from 'routes/routes';
import classNames from 'classnames';
import { IProps } from './types';
import styles from './styles.module.scss';

export const Tabs: FC<IProps> = ({ currency }) => {
	const { tab } = useParams<{ tab: string }>();

	return (
		<div className={styles.tabs}>
			<Link
				to={`${routes.reportsAnalyticsBase}${routes.analytics}`}
				className={classNames(styles.tabs__item, {
					[styles.active]: tab === routes.analytics.slice(1),
				})}
			>
				Analytics
			</Link>
			<Link
				to={`${routes.reportsAnalyticsBase}${routes.reports}`}
				className={classNames(styles.tabs__item, {
					[styles.active]:
						tab === routes.reports.slice(1) || (tab !== routes.analytics.slice(1) && !tab),
				})}
			>
				Report
			</Link>
		</div>
	);
};
