/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProviderAnalyticsStore, IAnalyticsRequest, IAnalyticsResponse } from './types';

export const initialState: IProviderAnalyticsStore = {
	analyticsList: null,
	analyticsLoading: false,
};

const providerAnalytics = createSlice({
	name: '@@providerAnalytics',
	initialState,
	reducers: {
		getProviderAnalyticsRequest: (state, _: PayloadAction<IAnalyticsRequest>) => {
			const merchantAnalyticsState = state;
			merchantAnalyticsState.analyticsLoading = true;
		},

		getProviderAnalyticsResponse: (state, { payload }: PayloadAction<IAnalyticsResponse>) => {
			const merchantAnalyticsState = state;
			merchantAnalyticsState.analyticsLoading = false;
			merchantAnalyticsState.analyticsList = payload;
		},

		providerAnalyticsResetState: (state) => {
			const getState = state;

			getState.analyticsLoading = false;
		},
	},
});

export default providerAnalytics.reducer;
export const {
	getProviderAnalyticsRequest,
	getProviderAnalyticsResponse,
	providerAnalyticsResetState,
} = providerAnalytics.actions;
