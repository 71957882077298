import { PopUpCloseIcon } from 'assets/inline-svg';
import { FC } from 'react';
import L from 'i18n-react';
import { ReportSelect } from '../ReportSelect/ReportSelect';
import { IPopupReportProps } from './types';

export const PopupReport: FC<IPopupReportProps> = ({
	role,
	onSubmit,
	onClose,
	activeRole,
	setActiveRole,
}) => {
	// const dispatch = useDispatch();
	// const roles = useSelector(getRoles);

	// useEffect(() => {
	// 	if (!roles) {
	// 		dispatch(getRolesRequest({ payload: type }));
	// 	}
	// }, [roles, dispatch, type]);

	return (
		<div className="popup popup--width-480">
			<button aria-label="button" type="button" className="popup__close" onClick={onClose}>
				<PopUpCloseIcon />
			</button>
			<div className="popup-header">
				<p className="popup-header__title popup-header__title--mb-32 popup-header__title--ta-l">
					Create report
				</p>
			</div>

			<div className="popup-body">
				<div className="select-block">
					<p className="select-block__name">Report type</p>

					<ReportSelect roles={role} active={activeRole} onChange={setActiveRole} />
				</div>

				<div className="edit-role-info-pop">
					<p>
						{L.translate(`Administration.Roles.Description.${String(activeRole?.code)}`, {
							notFound: '',
						})}
					</p>
				</div>
			</div>

			<div className="popup-button popup-button--type2">
				<button type="button" className="button button--full-width button--type2" onClick={onClose}>
					Cancel
				</button>
				<button
					type="button"
					className="button button--full-width"
					onClick={() => onSubmit(activeRole)}
					disabled={activeRole.code === String(role?.code)}
				>
					Create
				</button>
			</div>
		</div>
	);
};
