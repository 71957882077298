import { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { routes } from 'routes/routes';
import { Tabs } from 'v2Components/commomComponents/Tabs/Tabs';
import Main from 'v2Components/layoutsComponents/Main';
import Header from './components/Header';
import Currencies from './components/TabsContent/Currencies';
import FeeManagement from './components/TabsContent/FeeManagement';
import Limitation from './components/TabsContent/Limitation';
import FreeWithdraw from './components/TabsContent/FreeWithdraw';

export const MarketPage: FC = () => {
	const { path } = useRouteMatch();
	const listContent = [
		{ link: 'currencies', value: 'Currencies' },
		{ link: 'fee-management', value: 'Commissions' },
		{ link: 'limitation', value: 'Limitation' },
	];
	return (
		<Main header={<Header />}>
			<Tabs listContent={listContent} />
			<Switch>
				<Route exact path={`${path}${routes.market.currencies}`} component={Currencies} />
				<Route exact path={`${path}${routes.market.feeManagement}`} component={FeeManagement} />
				<Route exact path={`${path}${routes.market.limitation}`} component={Limitation} />
				<Route exact path={`${path}${routes.market.freeWithdraw}`} component={FreeWithdraw} />
				<Redirect to={`${path}${routes.market.currencies}`} />
			</Switch>
		</Main>
	);
};
