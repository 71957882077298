import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import { useHistory } from 'react-router';
import {
	ICreateAccountFormValues,
	ICreateProjectFormValues,
	TCreateAccountFormOnSubmit,
	TCreateRequestFormOnSubmit,
} from 'components/Provider/Merchant/MerchantAccauntAndProject/types';
import { toBase64 } from 'utils/files';
import { constUserTypes } from 'services/constants/user-types';
import {
	postMerchantAndProjectRequest,
	postMerchantOwnProjectRequest,
} from 'redux/reducers/providerMerchant/reducer';
import CreateMerchantAccount from 'components/Provider/Merchant/MerchantAccauntAndProject/CreateMerchantAccount/CreateMerchantAccount';
import CreateMerchantProject from 'components/Provider/Merchant/MerchantAccauntAndProject/CreateMerchantProject/CreateMerchantProject';
import { getCountries } from 'redux/reducers/app/selectors';

export const CreateMerchantPage = () => {
	const userType = useSelector(getUserType);
	const dispatch = useDispatch();
	const history = useHistory();
	const countries = useSelector(getCountries);
	const [stateAccount, setValueState] = useState<ICreateAccountFormValues>({
		firstName: '',
		lastName: '',
		companyName: '',
		idMerchant: '',
		email: '',
		phoneCode: '',
		phoneNumber: '',
		country: '',
		city: '',
		addressLineOne: '',
		addressLineTwo: '',
	});
	const [stateProject, setStateProject] = useState<ICreateProjectFormValues>({
		projectName: '',
		role: null,
		contractPersonFirstName: '',
		contractPersonLastName: '',
		email: '',
		phoneCode: '',
		phoneNumber: '',
		website: '',
		file: '',
	});

	const [checked, setChecked] = useState(false);
	const onSubmit: TCreateAccountFormOnSubmit = (values) => {
		const phoneCode = countries?.find((country) => country.iso === values.phoneCode)?.code || '';
		setValueState({
			...values,
			phoneCode,
		});
		setChecked(!checked);
	};
	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	const onSubmitRequest: TCreateRequestFormOnSubmit = async (values) => {
		const b64 = typeof values?.file !== 'string' ? await toBase64(values?.file) : undefined;
		const projectContactPhoneCode =
			countries?.find((country) => country.iso === values.phoneCode)?.code || '';
		const { phoneCode, phoneNumber } = stateAccount;
		const data = {
			email: stateAccount.email,
			phone_number: phoneNumber,
			phone_code: phoneCode,
			first_name: stateAccount.firstName,
			last_name: stateAccount.lastName,
			company_name: stateAccount.companyName,
			id_merchant: String(stateAccount.idMerchant),
			country: stateAccount.country,
			city: stateAccount.city,
			address_1: stateAccount.addressLineOne,
			address_2: stateAccount.addressLineTwo || undefined,
			project_name: values.projectName,
			project_status: values.role.code,
			project_contact_person_first_name: values.contractPersonFirstName,
			project_contact_person_last_name: values.contractPersonLastName,
			project_contact_person_email: values.email,
			project_contact_person_phone_number: values.phoneNumber,
			project_contact_person_phone_code: projectContactPhoneCode,
			project_website: values.website,
			project_logo: b64,
		};
		dispatch(
			userType === constUserTypes.PROVIDER
				? postMerchantAndProjectRequest({
						onSuccess: (id: string | undefined) => {
							if (id) history.push(`/merchant/${String(id)}/projects`);
						},
						apiParams: data,
				  })
				: postMerchantOwnProjectRequest({ onSuccess: () => {}, apiParams: data }),
		);

		setStateProject(values);
	};
	return !checked ? (
		<CreateMerchantAccount onSubmit={onSubmit} valueState={stateAccount} />
	) : (
		<CreateMerchantProject
			onSubmitRequest={onSubmitRequest}
			setChecked={setChecked}
			valueState={stateProject}
		/>
	);
};
