import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { getRolesRequest } from 'redux/reducers/administration/reducer';
import { getRoles, getRolesLoading } from 'redux/reducers/administration/selectors';
import Loading from 'UI/Loading';
import { NoResults } from 'v2Components/commomComponents/Table/NoResults';
import styles from './styles.module.scss';

const PAYLOAD_MERCHANT = 'ApprovalManagement';

interface IProps {
	isMerchant?: boolean;
}

export const Roles = ({ isMerchant }: IProps) => {
	const dispatch = useDispatch();
	const roles = useSelector(getRoles);
	const loading = useSelector(getRolesLoading);
	useEffect(() => {
		dispatch(
			getRolesRequest({
				payload: isMerchant ? PAYLOAD_MERCHANT : '',
			}),
		);
	}, [dispatch, isMerchant]);

	if (loading) {
		return <Loading />;
	}

	if (!roles?.length) {
		return <NoResults />;
	}

	return (
		<>
			{roles.map(({ code, name }) => (
				<div key={code} className={styles.role}>
					<div className={styles.role__title}>{name}</div>
					<div className={styles.role__text}>
						{L.translate(`Administration.Roles.Description.${code}`, { notFound: '' })}
					</div>
				</div>
			))}
		</>
	);
};
