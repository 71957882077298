import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset2faMerchantRequest, reset2faProviderRequest } from 'redux/reducers/auth/reducer';
import { getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { ITwoFABlockMerchantProps } from './types';

// eslint-disable-next-line
export const TwoFABlock: FC<ITwoFABlockMerchantProps> = ({ user, email, userType: merchantUserType }) => {
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);

	const onReset2fa = () => {
		const payload = {
			email,
			user_type: merchantUserType,
		};

		dispatch(
			userType === constUserTypes.PROVIDER
				? reset2faProviderRequest({
						apiParams: payload,
				  })
				: reset2faMerchantRequest({
						apiParams: payload,
				  }),
		);
	};

	return (
		<div className="tfa-block-wrap">
			<div className="tfa-block">
				<p className="tfa-block__title">Two-factor autentication</p>
				<div className="tfa-block__box">
					<p className="tfa-block__text">
						Two -factor autentication (2-FA) is an extra layer of protection in addition to your
						password.
					</p>
				</div>
			</div>

			<button onClick={onReset2fa} type="button" className="button button--type3">
				Reset Two-factor autentication
			</button>
		</div>
	);
};
