import moment from 'moment';
import { IFilter } from 'redux/reducers/app/types';
import { TApiGetProviderAllCurrencyResponse } from 'services/api/provider/types';
import { IGetCurrencyNetworkItem } from 'services/api/public/types';
import { IFilterElemItem } from 'v2Components/layoutsComponents/Filter/types';

export interface TransactionsHistory {
	id: string;
	value: string | number | Date | null;
	networks?: IGetCurrencyNetworkItem[];
	currencies?: TApiGetProviderAllCurrencyResponse[];
}

export const addressType = [
	{ value: 'All', label: 'All' },
	{ value: 'temporary', label: 'temporary' },
	{ value: 'permanent', label: 'permanent' },
];
export const getFilterWalletAddressesHistory = ({
	id,
	value,
	networks,
	currencies,
}: TransactionsHistory): IFilterElemItem => {
	switch (id) {
		case 'currency_id': {
			const content = currencies?.find((item) => item.id === value);
			return { identify: id, content: content?.name || '', label: content?.code };
		}
		case 'currency_network': {
			const content = networks?.find((item) => item.id === value);
			return { identify: id, content: content?.currency_name || '', label: content?.currency_code };
		}
		case 'address_type': {
			const content = addressType?.find((item) => item.value === value)?.label || '';
			return { identify: id, content };
		}
		case 'address_id':
		case 'project_id':
		case 'client_id':
		case 'address_number':
			return { identify: id, content: value as string };
		default:
			return { identify: '', content: '' };
	}
};
