import { FC } from 'react';
import { capitalize, trimBottomLine } from 'utils/strings';
import { IProviderTransactionParams } from 'redux/reducers/analyticsProvider/types';

interface IProps {
	params: IProviderTransactionParams;
}

export const BodyTable: FC<IProps> = ({ params }) => {
	return (
		<div className={`tr ${params.param === 'total' ? 'tr--type2' : ''}`}>
			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p className="td-value">{capitalize(trimBottomLine(params.param || ''))}:</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Quantity</p>
				<p className="td-value">{params.quantity}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p className="td-value">{`${params.amount} USD`}</p>
			</div>
		</div>
	);
};
