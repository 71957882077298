/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable */
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import {
	TApiGetRolesResponse,
	IProviderTeamMembersParams,
	IApiGetUserProfileResponse,
	IApiGetUserProfileParams,
	IApiEditProviderProfileParams,
	IApiEditProviderProfileResponse,
	IApiDeleteWhiteListIpRecordParams,
	Data,
	TApiProviderRoleCode,
	IApiDeleteProviderParams,
} from 'services/api/provider/types';
import {
	IRoleString,
	IAddNewAdminPayload,
	IAdministrationStore,
	IDeleteWhiteListIpRecordPayload,
	IEditProviderProfilePayload,
	IEditProviderRolePayload,
	IToggleProviderPayload,
	IDeleteProviderPayload,
} from './types';

export const initialState: IAdministrationStore = {
	teamMembers: null,
	teamMembersLoading: false,
	roles: null,
	rolesLoading: false,
	userProfile: null,
	userProfileLoading: false,
	actionsLoading: false,
	providerToggleBlockLoading: false,
};

const administration = createSlice({
	name: '@@administration',
	initialState,
	reducers: {
		teamMembersRequest: (state, action: PayloadAction<IProviderTeamMembersParams>) => {
			state.teamMembersLoading = true;
		},
		teamNotProviderMembersRequest: (state, action: PayloadAction<IProviderTeamMembersParams>) => {
			state.teamMembersLoading = true;
		},
		updateTeamMembersRequest: (state, action: PayloadAction<IProviderTeamMembersParams>) => state,
		updateTeamNotProviderMembersRequest: (
			state,
			action: PayloadAction<IProviderTeamMembersParams>,
		) => state,
		teamMembersSuccess: (state, { payload }: PayloadAction<any>) => {
			state.teamMembers = payload;
			state.teamMembersLoading = false;
		},
		teamMembersError: (state) => {
			state.teamMembers = null;
			state.teamMembersLoading = false;
		},

		getRolesRequest: (state, action: PayloadAction<IRoleString>) => {
			state.rolesLoading = true;
		},
		getRolesSuccess: (state, { payload }: PayloadAction<TApiGetRolesResponse>) => {
			state.roles = payload;
			state.rolesLoading = false;
		},
		getRolesError: (state) => {
			state.roles = null;
			state.rolesLoading = false;
		},

		getUserProfileRequest: (state, action: PayloadAction<IApiGetUserProfileParams>) => {
			state.userProfileLoading = true;
		},
		getUserProfileSuccess: (state, { payload }: PayloadAction<IApiGetUserProfileResponse>) => {
			state.userProfile = payload;
			state.userProfileLoading = false;
		},
		getUserProfileError: (state) => {
			state.userProfile = null;
			state.userProfileLoading = false;
		},

		editProviderProfileRequest: (state, action: PayloadAction<IEditProviderProfilePayload>) =>
			state,
		editProviderProfileSuccess: (state, action: PayloadAction<IApiEditProviderProfileResponse>) => {
			const { provider } = action.payload;

			if (state.userProfile?.provider.id !== provider.id) {
				return;
			}
			state.userProfile.provider.name = provider.provider_data.full_name;
		},

		editProviderRoleRequest: (state, action: PayloadAction<IEditProviderRolePayload>) => state,
		editProviderRoleSuccess: (state, action: PayloadAction<IEditProviderRolePayload>) => {
			const {
				apiParams: { id, role },
				newRoleName,
			} = action.payload;

			if (state.userProfile?.provider.id === id) {
				state.userProfile.provider.role = {
					code: role,
					name: newRoleName,
				};
			}

			if (state.teamMembers) {
				state.teamMembers = {
					...state.teamMembers,
					data: state.teamMembers.data.map((item) => {
						return item.id === id
							? {
									...item,
									role: {
										...item.role,
										code: role as TApiProviderRoleCode,
										name: newRoleName,
									},
							  }
							: item;
					}),
				};
			}
		},

		deleteProviderRequest: (state, action: PayloadAction<IDeleteProviderPayload>) => state,
		deleteProviderSuccess: (state, action: PayloadAction<IApiDeleteProviderParams>) => {
			const {
				payload: { id },
			} = action;
			if (!state.teamMembers?.data) {
				return;
			}
			state.teamMembers.data = state.teamMembers.data.filter((item) => item.id !== id);
		},

		deleteWhiteListIpRecordRequest: (
			state,
			action: PayloadAction<IDeleteWhiteListIpRecordPayload>,
		) => state,
		deleteWhiteListIpRecordSuccess: (
			state,
			{ payload }: PayloadAction<IApiDeleteWhiteListIpRecordParams>,
		) => {
			if (!state.userProfile?.white_list_ip) {
				return;
			}
			state.userProfile.white_list_ip = state.userProfile.white_list_ip.filter(
				({ id }) => id !== payload.id,
			);
		},

		addNewAdminRequest: (state, action: PayloadAction<IAddNewAdminPayload>) => {
			state.actionsLoading = true;
		},
		addNewAdminMerchantRequest: (state, action: PayloadAction<IAddNewAdminPayload>) => {
			state.actionsLoading = true;
		},
		addNewAdminSuccess: (state) => {
			state.actionsLoading = false;
		},
		addNewAdminError: (state) => {
			state.actionsLoading = false;
		},
		getProvidersToggleBlockRequest: (state, action: PayloadAction<IToggleProviderPayload>) => {
			state.providerToggleBlockLoading = true;
		},
		getProvidersToggleBlockSuccess: (state, action: PayloadAction<any>) => {
			const { id, is_block } = action.payload;
			if (state.teamMembers) {
				state.teamMembers = {
					...state.teamMembers,
					data: state.teamMembers.data.map((item) => {
						return item.id === id
							? {
									...item,
									blocked: is_block,
							  }
							: item;
					}),
				};
			}
			state.providerToggleBlockLoading = false;
		},
	},
});
export default administration.reducer;
export const {
	teamMembersRequest,
	teamNotProviderMembersRequest,
	updateTeamMembersRequest,
	updateTeamNotProviderMembersRequest,
	teamMembersSuccess,
	teamMembersError,
	getRolesRequest,
	getRolesSuccess,
	getRolesError,
	getUserProfileRequest,
	getUserProfileSuccess,
	getUserProfileError,
	addNewAdminRequest,
	addNewAdminMerchantRequest,
	addNewAdminSuccess,
	addNewAdminError,
	editProviderProfileRequest,
	editProviderProfileSuccess,
	editProviderRoleRequest,
	editProviderRoleSuccess,
	deleteProviderRequest,
	deleteProviderSuccess,
	deleteWhiteListIpRecordRequest,
	deleteWhiteListIpRecordSuccess,
	getProvidersToggleBlockRequest,
	getProvidersToggleBlockSuccess,
} = administration.actions;
