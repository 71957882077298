import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IExportTransactionsCSVPayload } from 'redux/reducers/providerTransactions/types';
import { useParams } from 'react-router-dom';
import { exportProviderTransactionsCSVRequest } from 'redux/reducers/providerTransactions/reducer';
import {
	setTransactionsStateFiatFilter,
	createReportTransactionProjectRequest,
	getProjectTransactionsRequest,
} from 'redux/reducers/projectTransactions/reducer';
import { Pagination } from 'UI/Pagination/Pagination';
import {
	getTransactionFiat,
	getTransactionsFiatLoader,
	getTransactionsFiatFilter,
} from 'redux/reducers/projectTransactions/selectors';
import { ICreateReportProjectTransactionPayload } from 'redux/reducers/projectTransactions/types';
import { notify } from 'utils/notify';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import useFilterList from 'hooks/useFilterList';
import { getFilterTransactionsHistory } from 'v2Pages/Provider/TransactionsPage/Transactions/filter.helper';
import { getFilter } from 'redux/reducers/app/selectors';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import Table from 'v2Components/commomComponents/Table';
import { IFilter } from 'redux/reducers/app/types';
import { IDepositCryptoTransactionItem } from 'services/api/provider/types';
import { Filters } from '../../../Filters-new/Filters';
import { Index } from './TableRow';
import { convertRequestParams } from './filter.helper';
import styles from './styles.module.scss';

export const FiatTable: FC = () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const transactions = useSelector(getTransactionFiat);
	const loading = useSelector(getTransactionsFiatLoader);
	const filters = useSelector(getTransactionsFiatFilter);
	const { projectId, merchantId } = useParams<{ projectId: string; merchantId: string }>();
	const [showFilter, setShowFilter] = useState(false);
	const { data = [], last_page: totalPages = 1, per_page: perPage } = transactions || {};
	const filterList = useFilterList(getFilterTransactionsHistory);
	const isShowPagination = totalPages > 1 && !loading;
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('ProjectTransactionsFiat');
	const {
		page,
		dateFrom,
		dateTo,
		amountFrom,
		amountTo,
		currencyName,
		type,
		transactionId,
		status,
		riskScore,
	} = filters;

	const filterValues = {
		dateFrom,
		dateTo,
		amountFrom,
		amountTo,
		currencyName,
		type,
		transactionId,
		status,
		riskScore,
		merchantId,
		projectId,
	};

	const setPage = (_page: number) => dispatch(setTransactionsStateFiatFilter({ page: _page }));

	const resetFilter = () => {
		dispatch(
			setTransactionsStateFiatFilter({
				page: 1,
				dateFrom: undefined,
				dateTo: undefined,
				amountFrom: undefined,
				amountTo: undefined,
				currencyName: undefined,
				type: undefined,
				transactionId: undefined,
				status: undefined,
				riskScore: undefined,
			}),
		);
	};

	const downloadCSV = () => {
		const payload: IExportTransactionsCSVPayload = {
			nameFile: 'components transactions',
			apiParams: {
				...filter,
				risk_score: riskScore ? String(riskScore) : undefined,
			},
			onSuccess: () => notify('File successfully downloaded', 'success'),
		};
		dispatch(exportProviderTransactionsCSVRequest(payload));
	};
	const createReport = () => {
		const payload: ICreateReportProjectTransactionPayload = {
			apiParams: {
				...filter,
				risk_score: String(filter.risk_score),
			},
			is_crypto: false,
			onSuccess: () => {
				notify('Report successfully created', 'success');
			},
		};
		dispatch(createReportTransactionProjectRequest(payload));
	};

	const onSubmit = (params: IFilter) => {
		dispatch(
			setFilter({
				...params,
				current_page: 1,
			}),
		);
		setShowFilter(false);
	};

	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());

	useEffect(() => {
		dispatch(
			getProjectTransactionsRequest({
				apiParams: {
					...convertRequestParams(filter, 'fiat'),
					project_id: projectId,
					current_page: page,
				},
				onSuccess: () => {
					notify('Report successfully created', 'success');
				},
			}),
		);
	}, [dispatch, filter, page, projectId]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);
	return (
		<>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
				className={styles.filters}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<Filters values={filterValues} isFiat />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={data || []}
				getRow={(item: IDepositCryptoTransactionItem, index: number) =>
					Index({ item, number: index + 1 + (filters.page - 1) * (perPage || 0) })
				}
				gridTemplateColumns={gridTemplateColumns}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={Number(totalPages)}
						onPageChange={setPage}
						forcePage={Number(page)}
					/>
				</PaginationWrapper>
			)}
		</>
	);
};
