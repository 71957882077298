import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IApiCreateApiKeyResponse,
	TApiGenerateCallbackSecretResponse,
	IApiGetApiParams,
	IApiGetApiResponse,
	IApiGetProjectDetailsParams,
	IApiGetProjectDetailsResponse,
	TApiSaveCallbackUrlResponse,
	IApiUpdateApiKeyResponse,
	TApiChangeProjectDetailsResponse,
	TApiGenerateWidgetKeyResponse,
} from 'services/api/project/types';
import {
	changeProjectDetailsMerchantRequest,
	changeProjectDetailsRequest,
	changeProjectDetailsSuccess,
	createApiKeyRequest,
	createApiKeySuccess,
	deleteApiKeyRequest,
	deleteApiKeySuccess,
	generateCallbackSecretRequest,
	generateCallbackSecretSuccess,
	generateWidgetKeyMerchantRequest,
	generateWidgetKeyRequest,
	generateWidgetKeySuccess,
	getApiError,
	getApiMerchantRequest,
	getApiRequest,
	getApiSuccess,
	getProjectDetailsError,
	getProjectDetailsMerchantRequest,
	getProjectDetailsRequest,
	getProjectDetailsSuccess,
	saveCallbackUrlRequest,
	saveCallbackUrlSuccess,
	clearCallbackUrlRequest,
	clearCallbackUrlSuccess,
	updateApiKeyRequest,
	updateApiKeySuccess,
} from './reducer';
import {
	IChangeProjectDetailsPayload,
	IClearCallbackUrlPayload,
	ICreateApiKeyPayload,
	IDeleteApiKeyPayload,
	IGenerateCallbackSecretPayload,
	IGenerateWidgetKeyPayload,
	ISaveCallbackUrlPayload,
	IUpdateApiKeyPayload,
} from './types';

function* getProjectDetailsWorker({ payload }: PayloadAction<IApiGetProjectDetailsParams>) {
	try {
		const data: IApiGetProjectDetailsResponse = yield call(api.project.getProjectDetails, payload);
		yield put(getProjectDetailsSuccess(data));
	} catch (error) {
		yield put(getProjectDetailsError());
		showError(error);
	}
}

function* getProjectDetailsMerchantWorker({ payload }: PayloadAction<IApiGetProjectDetailsParams>) {
	try {
		const data: IApiGetProjectDetailsResponse = yield call(
			api.project.getProjectDetailsMerchant,
			payload,
		);
		yield put(getProjectDetailsSuccess(data));
	} catch (error) {
		yield put(getProjectDetailsError());
		showError(error);
	}
}

function* changeProjectDetailsWorker({ payload }: PayloadAction<IChangeProjectDetailsPayload>) {
	const { apiParams, onSuccess, onError } = payload;
	try {
		const data: TApiChangeProjectDetailsResponse = yield call(
			api.project.changeProjectDetails,
			apiParams,
		);
		yield put(changeProjectDetailsSuccess(data));
		onSuccess?.();
	} catch (error) {
		onError?.();
		showError(error);
	}
}

function* changeProjectDetailsMerchantWorker({
	payload,
}: PayloadAction<IChangeProjectDetailsPayload>) {
	const { apiParams, onSuccess, onError } = payload;
	try {
		const data: TApiChangeProjectDetailsResponse = yield call(
			api.project.changeProjectDetailsMerchant,
			apiParams,
		);
		yield put(changeProjectDetailsSuccess(data));
		onSuccess?.();
	} catch (error) {
		onError?.();
		showError(error);
	}
}

function* getApiWorker({ payload }: PayloadAction<IApiGetApiParams>) {
	try {
		const data: IApiGetApiResponse = yield call(api.project.getApi, payload);
		yield put(getApiSuccess(data));
	} catch (error) {
		yield put(getApiError());
		showError(error);
	}
}

function* getApiMerchantWorker({ payload }: PayloadAction<IApiGetApiParams>) {
	try {
		const data: IApiGetApiResponse = yield call(api.project.getApiMerchant, payload);
		yield put(getApiSuccess(data));
	} catch (error) {
		yield put(getApiError());
		showError(error);
	}
}

function* createApiKeyWorker({ payload }: PayloadAction<ICreateApiKeyPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: IApiCreateApiKeyResponse = yield call(api.project.createApiKey, apiParams);
		yield put(createApiKeySuccess(data));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* updateApiKeyWorker({ payload }: PayloadAction<IUpdateApiKeyPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: IApiUpdateApiKeyResponse = yield call(api.project.updateApiKey, apiParams);
		yield put(updateApiKeySuccess(data));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* deleteApiKeyWorker({ payload }: PayloadAction<IDeleteApiKeyPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.project.deleteApiKey, apiParams);
		yield put(deleteApiKeySuccess(apiParams));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* saveCallbackUrlWorker({ payload }: PayloadAction<ISaveCallbackUrlPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: TApiSaveCallbackUrlResponse = yield call(api.project.saveCallbackUrl, apiParams);
		yield put(saveCallbackUrlSuccess(data));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* clearCallbackUrlWorker({ payload }: PayloadAction<IClearCallbackUrlPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: TApiSaveCallbackUrlResponse = yield call(api.project.clearCallbackUrl, apiParams);
		yield put(clearCallbackUrlSuccess(data));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* generateCallbackSecretWorker({ payload }: PayloadAction<IGenerateCallbackSecretPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: TApiGenerateCallbackSecretResponse = yield call(
			api.project.generateCallbacksecret,
			apiParams,
		);
		yield put(generateCallbackSecretSuccess(data));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* generateWidgetKeyWorker({ payload }: PayloadAction<IGenerateWidgetKeyPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const apiResponse: TApiGenerateWidgetKeyResponse = yield call(
			api.project.generateWidgetKey,
			apiParams,
		);
		yield put(generateWidgetKeySuccess({ apiParams, apiResponse }));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

function* generateWidgetKeyMerchantWorker({ payload }: PayloadAction<IGenerateWidgetKeyPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const apiResponse: TApiGenerateWidgetKeyResponse = yield call(
			api.project.generateWidgetKeyMerchant,
			apiParams,
		);
		yield put(generateWidgetKeySuccess({ apiParams, apiResponse }));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

export function* projectSettingsSaga() {
	yield takeLatest(getProjectDetailsRequest, getProjectDetailsWorker);
	yield takeLeading(changeProjectDetailsRequest, changeProjectDetailsWorker);
	yield takeLatest(getApiRequest, getApiWorker);
	yield takeLeading(createApiKeyRequest, createApiKeyWorker);
	yield takeLeading(updateApiKeyRequest, updateApiKeyWorker);
	yield takeLeading(deleteApiKeyRequest, deleteApiKeyWorker);
	yield takeLeading(saveCallbackUrlRequest, saveCallbackUrlWorker);
	yield takeLeading(clearCallbackUrlRequest, clearCallbackUrlWorker);
	yield takeLeading(generateCallbackSecretRequest, generateCallbackSecretWorker);
	yield takeLeading(generateWidgetKeyRequest, generateWidgetKeyWorker);
	yield takeLeading(generateWidgetKeyMerchantRequest, generateWidgetKeyMerchantWorker);
	yield takeLeading(getProjectDetailsMerchantRequest, getProjectDetailsMerchantWorker);
	yield takeLeading(changeProjectDetailsMerchantRequest, changeProjectDetailsMerchantWorker);
	yield takeLeading(getApiMerchantRequest, getApiMerchantWorker);
}
