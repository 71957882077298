import { IStoreState } from 'redux/types';

export const getProjectSettings = (state: IStoreState) => state.projectSettings;

export const getProjectDetails = (state: IStoreState) => getProjectSettings(state).projectDetails;
export const getProjectDetailsLoading = (state: IStoreState) => {
	return getProjectSettings(state).projectDetailsLoading;
};

export const getApi = (state: IStoreState) => getProjectSettings(state).api;
export const getApiLoading = (state: IStoreState) => getProjectSettings(state).apiLoading;
