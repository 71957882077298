import { useRouteMatch } from 'react-router';
import { routes } from 'routes/routes';
import { trimRightSlash } from 'utils/strings';

export const useProjectMatchUrl = () => {
	const projectMatch = useRouteMatch(routes.oneProjectBase);
	const projectUrl = trimRightSlash(projectMatch?.url || '');

	return projectUrl;
};
