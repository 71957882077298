/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toBase64 } from 'utils/files';
import { getUserId, getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import {
	getProjectDetailsMerchantRequest,
	getProjectDetailsRequest,
	getProjectDetailsSuccess,
} from 'redux/reducers/projectSettings/reducer';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import {
	getListMerchantRequest,
	getProviderListMerchantRequest,
} from 'redux/reducers/merchantApprovalManagement/reducer';
import { parsePhone } from 'utils/phone';
import { routes } from 'routes/routes';
import {
	postOwnProjectMerchantCreateRequest,
	postProjectMerchantCreateRequest,
} from 'redux/reducers/merchantProjects/reducer';
import { notify } from 'utils/notify';
import { ICreateProjectPayload } from 'redux/reducers/merchantProjects/types';
import { getCountries } from 'redux/reducers/app/selectors';
import MerchantProjectCreateItem from './MerchantProjectCreateItem/MerchantProjectCreateItem';
import { ICreateProjectFormValues, TCreateRequestFormOnSubmit } from './types';

const MerchantProjectCreate = () => {
	const dispatch = useDispatch();
	const { merchantId, id } = useParams<{ merchantId: string; id: string }>();
	const userId = useSelector(getUserId);
	const project = useSelector(getProjectDetails);
	const userType = useSelector(getUserType);
	const countries = useSelector(getCountries);
	const history = useHistory();
	const [activeCard, setActiveCard] = useState<number | null>(null);
	const location = history.location.pathname.includes(routes.duplicateMerchantProject);
	const isProvider = userType === constUserTypes.PROVIDER;
	const [phoneCode, phoneNumber] = parsePhone(project?.phone || '');
	const stateProject: ICreateProjectFormValues =
		project && location
			? {
					projectName: project.name.includes('(Copy)') ? project.name : `${project.name} (Copy)`,
					role: {
						code: project.is_active ? 'active' : 'inactive',
						name: project.is_active ? 'Active' : 'Inactive',
					},
					contractPersonFirstName: project.contact_first_name,
					contractPersonLastName: project.contact_last_name,
					email: project.email,
					phoneNumber,
					phoneCode,
					website: project.website,
					file: project.file_icon,
			  }
			: {
					projectName: '',
					role: null,
					contractPersonFirstName: '',
					contractPersonLastName: '',
					email: '',
					phoneCode: '',
					phoneNumber: '',
					website: '',
					file: '',
			  };

	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	const onSubmitRequest: TCreateRequestFormOnSubmit = async (values) => {
		const fileDuplicate = project && location ? project.file_icon_base64 : undefined;
		const b64 = typeof values?.file !== 'string' ? await toBase64(values?.file) : fileDuplicate;
		const projectContactPhoneCode =
			countries?.find((country) => country.iso === values.phoneCode)?.code || '';
		const payload: ICreateProjectPayload = {
			apiParams: {
				merchant_id: Number(merchantId || userId),
				name: values.projectName,
				status: values.role ? values.role.code : undefined,
				contact_person_first_name: values.contractPersonFirstName,
				contact_person_last_name: values.contractPersonLastName,
				contact_person_email: values.email,
				contact_person_phone_number: values.phoneNumber,
				contact_person_phone_code: projectContactPhoneCode,
				website: values.website,
				logo: b64 || undefined,
			},
			onSuccess: () => notify('Project created successfully', 'success'),
			history,
			setActiveCard,
		};
		dispatch(
			isProvider
				? postProjectMerchantCreateRequest(payload)
				: postOwnProjectMerchantCreateRequest(payload),
		);
	};

	useEffect(() => {
		id &&
			!project &&
			dispatch(
				isProvider
					? getProjectDetailsRequest({ project_id: id, is_crypto: 1 })
					: getProjectDetailsMerchantRequest({ project_id: id, is_crypto: 1 }),
			);
	}, [dispatch, userType, project, id]);

	useEffect(() => {
		isProvider
			? dispatch(getProviderListMerchantRequest({ owner_merchant_id: +merchantId }))
			: dispatch(getListMerchantRequest());

		return () => {
			dispatch(getProjectDetailsSuccess(null));
		};
	}, []);

	return project || !location ? (
		<MerchantProjectCreateItem
			onSubmitRequest={onSubmitRequest}
			valueState={stateProject}
			activeCard={activeCard}
		/>
	) : null;
};

export default MerchantProjectCreate;
