import { useSelector } from 'react-redux';
import {
	getProviderCurrencyAllLoading,
	getProviderCurrencyFiat,
} from 'redux/reducers/providerCurrency/selectors';
import { TApiGetProviderAllCurrencySettingResponse } from 'redux/reducers/providerCurrency/types';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Table from 'v2Components/commomComponents/Table';
import TableRow from './TableRow';

const TableFiat = (): JSX.Element => {
	const itemsFiat = useSelector(getProviderCurrencyFiat);
	const loading = useSelector(getProviderCurrencyAllLoading);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('WalletsBalancesFiat');
	return (
		<Table
			loading={loading}
			headerItems={tableHeaderItems}
			bodyItems={itemsFiat}
			getRow={(item: TApiGetProviderAllCurrencySettingResponse) =>
				TableRow({
					currency: item,
				})
			}
			gridTemplateColumns={gridTemplateColumns}
		/>
	);
};

export default TableFiat;
