import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { EParams } from 'services/constants/params';
import { WalletAddressProvider } from 'v2Pages/Project/ProjectWalletAddressesPage/WalletContent/WalletAddressProvider';
import { WalletAddressUser } from 'v2Pages/Project/ProjectWalletAddressesPage/WalletContent/WalletAddressUser';

export default () => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route exact path={path}>
				<WalletAddressProvider />
			</Route>
			<Route exact path={path + EParams.ID}>
				<WalletAddressUser />
			</Route>
			<Redirect to={path} />
		</Switch>
	);
};
