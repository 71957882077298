import { AuthLayout } from 'layouts/AuthLayout/AuthLayout';
import { ChangeEvent, FC, FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { reset2faRequest } from 'redux/reducers/auth/reducer';

export const Reset2fa: FC = () => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState<string>('');

	const handleInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setEmail(value);
	};

	const onReset2Fa = (event: FormEvent) => {
		event.preventDefault();

		dispatch(
			reset2faRequest({
				apiParams: { email },
			}),
		);
	};

	return (
		<AuthLayout title="Reset 2fa">
			<form action="/" onSubmit={onReset2Fa} className="authorization-section">
				<div className="authorization-box">
					<div className="form form--authorization">
						<div className="form__header">
							<p className="form-title ">Reset 2FA</p>
							<div className="form-text">
								<p>Input an email so we can send you the link to reset your 2FA.</p>
							</div>
						</div>
						<div className="form__body">
							<div className="input">
								<p className="input-name">Email</p>
								<div className="input-wrapper">
									<input
										value={email}
										onChange={handleInput}
										className="input-item"
										type="email"
										placeholder="Email"
										required
									/>
								</div>
							</div>
						</div>
						<div className="form-footer">
							<Link to="/login" className="button button--type2 button--full-width">
								Back
							</Link>
							<button type="submit" className="button button--full-width">
								Reset 2FA
							</button>
						</div>
					</div>
				</div>
			</form>
		</AuthLayout>
	);
};
