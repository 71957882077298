export const formatPrice = (price: number | string, countAfterDot = 2) => {
	const dotIdx = String(price).indexOf('.');
	if (dotIdx === -1) {
		return String(price);
	}
	const visiblePart = String(price).slice(0, dotIdx + countAfterDot + 1);
	if (visiblePart.length === String(price).length) {
		return visiblePart;
	}
	return `${visiblePart}...`;
};
