import { useField } from 'formik';
import { FC, useEffect, useState } from 'react';
import { dataUrlToFile, toBase64 } from 'utils/files';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import CropModal from 'UI/Popups/CropModal';
import { IProjectLogoFieldProps, TProjectLogoFile } from './types';
import styles from './styles.module.scss';

export const ProjectLogoField: FC<IProjectLogoFieldProps> = ({ initLogoUrl }) => {
	const [field, meta, helpers] = useField<TProjectLogoFile>('logoFile');
	const [previewImg, setPreviewImg] = useState('');
	const [b64, setB64] = useState('');

	const isShowError = meta.touched && meta.error;

	// Helpers
	const validateAndSetFile = async (file?: File) => {
		if (file?.type.startsWith('image/')) {
			setB64(await toBase64(file));
		}
	};

	const saveCrop = (img: string) => {
		const file = dataUrlToFile(img, 'file');
		helpers.setValue(file);
		setB64('');
	};

	// Event handlers
	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		await validateAndSetFile(e.target.files?.[0]);
		e.target.value = '';
	};
	const handleDrop = async (e: React.DragEvent<HTMLSpanElement>) => {
		e.preventDefault();
		await validateAndSetFile(e.dataTransfer.files[0]);
	};

	const handleFileDelete = () => {
		helpers.setTouched(true);
		helpers.setValue('delete');
	};

	// Set preview image
	useEffect(() => {
		if (!field.value) {
			setPreviewImg(initLogoUrl || '');
			return;
		}
		if (field.value === 'delete') {
			setPreviewImg('');
			return;
		}
		setPreviewImg(URL.createObjectURL(field.value));
	}, [field.value, initLogoUrl]);

	return (
		<>
			<PopupModal open={!!b64}>
				<CropModal image={b64} onClose={() => setB64('')} onSave={saveCrop} />
			</PopupModal>
			<div>
				<div className={styles.title}>
					<p>Project Logo</p>
				</div>

				<span
					className="upload-file-box"
					onDragOver={(e) => e.preventDefault()}
					onDrop={handleDrop}
				>
					<input
						type="file"
						className="hidden"
						id="upload1"
						accept=".jpg,.png,.gif"
						onChange={handleFileChange}
						onBlur={() => helpers.setTouched(true)}
					/>
					<span className="upload-file">
						{previewImg && (
							<span className="upload-file__icon upload-file__icon--big">
								<img
									src={previewImg}
									alt="logo preview"
									onLoad={() => URL.revokeObjectURL(previewImg)}
								/>
							</span>
						)}

						<span className="upload-file__buttons">
							<button
								className="button button--size2 button--type2"
								type="button"
								onClick={handleFileDelete}
							>
								Delete
							</button>
							<label htmlFor="upload1" className="button button--size2 button--type3">
								Upload
							</label>
						</span>

						<span className="upload-file__text">
							{field.value && typeof field.value === 'object'
								? field.value.name
								: 'Supports jpg, png and gif'}
						</span>
						<span className="upload-file__size">* Max file size 5Mb.</span>
					</span>
				</span>
				{isShowError && (
					<p className="input-notification input-notification--error">{meta.error}</p>
				)}
			</div>
		</>
	);
};
