/* eslint-disable import/no-relative-packages */

import { useCallback, useEffect, useRef, useState } from 'react';
import { languages } from 'languages';
import { useDispatch, useSelector } from 'react-redux';
import { appLanguage } from 'redux/reducers/app/selectors';
import { changeAppLanguage } from 'redux/reducers/app/reducer';
import classNames from 'classnames';
import { LangIcon } from 'assets/inline-svg';
import LanguageItem from './LanguageItem';

const LangSwitcher = () => {
	const language = useSelector(appLanguage);
	const [showList, setShowList] = useState<boolean>(false);
	const dropdownRef = useRef(null);
	const selectRef = useRef(null);
	const dispatch = useDispatch();

	const handleShowDropdown = () => {
		setShowList(true);
	};

	const handleLanguageSelect = (lang: string) => {
		dispatch(changeAppLanguage(lang));
		setShowList(false);
	};

	const renderItems = () =>
		Object.keys(languages)?.map((languageItem) => (
			<LanguageItem
				key={languageItem}
				language={languageItem}
				name={languageItem}
				selectHandler={() => handleLanguageSelect(languageItem)}
			/>
		));

	const onWindowClick = useCallback(
		(e: Event) => {
			const path = e.composedPath();
			const isCanHideList =
				dropdownRef?.current &&
				selectRef?.current &&
				!path.includes(dropdownRef?.current) &&
				!path.includes(selectRef?.current) &&
				showList;
			isCanHideList && setShowList(false);
		},
		[showList],
	);

	useEffect(() => {
		window.addEventListener('click', onWindowClick, true);
		return () => window.removeEventListener('click', onWindowClick);
	}, [onWindowClick]);

	return (
		<div className="footer-lang" ref={selectRef}>
			<span className="footer-lang_point" onClick={handleShowDropdown}>
				{language}
				<LangIcon />
			</span>
			<div ref={dropdownRef} className={classNames('footer-lang_wrap', { active: showList })}>
				<ul className="footer-lang_list">{renderItems()}</ul>
			</div>
		</div>
	);
};

export default LangSwitcher;
