import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Pagination } from 'UI/Pagination/Pagination';
import { ArrowLeftIcon } from 'assets/inline-svg';
import {
	getProjectCurrencyAllClientLoading,
	getProjectWalletsAddressesDataClient,
} from 'redux/reducers/projectWalletAddresses/selectors';
import { IGetMerchantOwnersPayload } from 'redux/reducers/providerMerchant/types';
import { getProjectWalletAddressesClientRequest } from 'redux/reducers/projectWalletAddresses/reducer';
import Table from 'v2Components/commomComponents/Table';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import Main from 'v2Components/layoutsComponents/Main';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import { IProviderItem } from 'redux/reducers/providerWalletAddresses/types';
import TableRow from '../WalletAddressesTable/TableRow';
import styles from './styles.module.scss';

export const WalletAddressUser = () => {
	const dispatch = useDispatch();
	const walletAddresses = useSelector(getProjectWalletsAddressesDataClient);
	const loading = useSelector(getProjectCurrencyAllClientLoading);
	const history = useHistory();
	const { merchantId, projectId, id } = useParams<{
		merchantId: string;
		projectId: string;
		id: string;
	}>();
	const [currentPage, setCurrentPage] = useState(1);
	const [firstLoading, setFirstLoading] = useState(true);
	const totalPages = walletAddresses?.last_page || 1;
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('ProjectWalletAddress');
	const isShowPagination = !firstLoading && totalPages > 1;
	const getProviderWalletAddressesPayload: IGetMerchantOwnersPayload | null = useMemo(() => {
		return {
			apiParams: {
				current_page: currentPage,
				project_id: String(projectId),
				client_id: String(id),
			},

			onFinally: () => setFirstLoading(false),
		};
	}, [currentPage, id, projectId]);

	const handleGoToClient = () =>
		history.push(`/merchant/${merchantId}/project/${projectId}/client/${String(id)}`);

	useEffect(() => {
		dispatch(getProjectWalletAddressesClientRequest(getProviderWalletAddressesPayload));
	}, [dispatch, getProviderWalletAddressesPayload]);

	return (
		<Main
			header={
				<>
					<div onClick={() => history.goBack()} style={{ cursor: 'pointer' }} className="back-page">
						<ArrowLeftIcon />
						<span>Back to Wallet addresses</span>
					</div>
					<div className={styles.title} onClick={handleGoToClient}>
						Client ID: {id}
					</div>
				</>
			}
		>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				gridTemplateColumns={gridTemplateColumns}
				getRow={(item: IProviderItem) => TableRow({ item })}
				bodyItems={walletAddresses?.data || []}
				className={styles.table}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</PaginationWrapper>
			)}
		</Main>
	);
};
