import { EParams } from 'services/constants/params';
import { MerchantProjectsListPage } from 'pages/Merchant/MerchantProjectsListPage.tsx/MerchantProjectsListPage';
import { MerchantProjectsCreatePage } from 'pages/Merchant/MerchantProjectsCreatePage.tsx/MerchantProjectsCreatePage';
import { ResetPasswordPage } from 'pages/Auth/ResetPasswordPage/ResetPasswordPage';
import { ConfirmIpAddressPage } from 'pages/Auth/ConfirmIpAddressPage/ConfirmIpAddress';
import { ProjectInvoiceLinkPage } from 'pages/Project/ProjectInvoiceLinkPage/ProjectInvoiceLinkPage';
import MerchantProfilePage from 'v2Pages/Merchant/MerchantProfilePage';
import MerchantSettingsPage from 'v2Pages/Merchant/MerchantSettingsPage';
import MerchantSecurityPage from 'v2Pages/Merchant/MerchantSecurityPage';
import MerchantProjectsPage from 'v2Pages/Merchant/MerchantProjectsPage';
import MerchantBankAccountPage from 'v2Pages/Merchant/MerchantBankAccountPage';
import MerchantApprovalManagementPage from 'v2Pages/Merchant/MerchantApprovalManagement';
import MerchantEditBankAccountPage from 'pages/Merchant/MerchantEditBankAccountPage/MerchantEditBankAccountPage';
import { ProjectRoutes } from './ProjectRoutes/ProjectRoutes';
import { MerchantRoutes } from './MerchantRoutes/MerchantRoutes';
import { routes } from './routes';

export const titleList = {
	[routes.merchantProjectList]: 'Project details transactions',
	[routes.createMerchantProject]: 'Merchant Projects',
};

export const routeListMain = [
	{
		exact: true,
		path: routes.merchantProjectList,
		component: MerchantProjectsListPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.resetPassword,
		component: ResetPasswordPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.confirmIpAddress,
		component: ConfirmIpAddressPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.merchantInvoiceLink,
		component: ProjectInvoiceLinkPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.oneProjectBase,
		component: ProjectRoutes,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.oneMerchantBase,
		component: MerchantRoutes,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
];

export const routeMerchant = [
	// One Project / Project Settings
	{
		exact: true,
		path: routes.createMerchantProject,
		component: MerchantProjectsCreatePage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.duplicateMerchantProject + EParams.ID,
		component: MerchantProjectsCreatePage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
];

export const routeListMerchant = [
	{
		exact: true,
		path: routes.oneMerchant.profile,
		component: MerchantProfilePage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.oneMerchant.settings,
		component: MerchantSettingsPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.oneMerchant.security,
		component: MerchantSecurityPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.oneMerchant.merchantProjects,
		component: MerchantProjectsPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.oneMerchant.createProject,
		component: MerchantProjectsCreatePage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: `/projects${routes.duplicateMerchantProject}${EParams.ID}`,
		component: MerchantProjectsCreatePage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: false,
		path: routes.oneMerchant.approvalManagement,
		component: MerchantApprovalManagementPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.oneMerchant.bankAccount,
		component: MerchantBankAccountPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
	{
		exact: true,
		path: routes.oneMerchant.editBankAccount,
		component: MerchantEditBankAccountPage,
		role: 'Administrator, Operator, Financier, Techsupport',
	},
];
