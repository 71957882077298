import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { routes } from 'routes/routes';
import { ProjectDetailsTab } from 'components/Project/ProjectSettings/ProjectDetailsTab/ProjectDetailsTab';
import { rolesProject } from 'layouts-elements/ProviderSidebar/roles';
import { ApiTab } from 'components/Project/ProjectSettings/ApiTab/ApiTab';
import { WidgetTab } from 'components/Project/ProjectSettings/WidgetTab/WidgetTab';
import { useSelector } from 'react-redux';
import { getUserRole } from 'redux/reducers/auth/selectors';
import Main from 'v2Components/layoutsComponents/Main';
import { ProjectSettingsHeader } from 'components/Project/ProjectSettings/ProjectSettingsHeader/ProjectSettingsHeader';
import { Tabs } from 'v2Components/commomComponents/Tabs/Tabs';

export default () => {
	const { path } = useRouteMatch();
	const userRole = useSelector(getUserRole);
	const listContent = [
		{ link: `details`, value: 'Project details' },
		{ link: `api`, value: 'API' },
		{ link: `widget`, value: 'Widget' },
	];
	return (
		<Main>
			<Tabs listContent={listContent} />
			<Switch>
				<Route
					exact
					path={path + routes.oneProject.settings.details}
					component={ProjectDetailsTab}
				/>
				{rolesProject.project_settings.includes(userRole || '') && (
					<Route exact path={path + routes.oneProject.settings.api} component={ApiTab} />
				)}
				{rolesProject.project_settings.includes(userRole || '') && (
					<Route exact path={path + routes.oneProject.settings.widget} component={WidgetTab} />
				)}
				<Redirect to={path + routes.oneProject.settings.details} />
			</Switch>
		</Main>
	);
};
