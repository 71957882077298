import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchIcon } from 'assets/inline-svg';
import { getProviderAllCurrencyRequest } from 'redux/reducers/providerCurrency/reducer';
import { getNetworkRequest } from 'redux/reducers/app/reducer';
import { getCurrencyNetwork } from 'redux/reducers/app/selectors';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { SelectFieldCurrency } from 'UI/SelectFieldCurrency/SelectFieldCurrency';
import { TextField } from 'v2Components/formComponents/TextField';
import { addressType } from '../filter.helper';

const Filters: FC = () => {
	const dispatch = useDispatch();
	const currencyNetwork = useSelector(getCurrencyNetwork);

	const networks = currencyNetwork.map((network) => ({
		value: network.id,
		label: network.currency_network,
	}));
	useEffect(() => {
		dispatch(getProviderAllCurrencyRequest());
		dispatch(getNetworkRequest());
	}, [dispatch]);

	return (
		<>
			<TextField
				name="address_id"
				type="number"
				label="Address ID"
				placeholder="Enter"
				renderRightIcon={<SearchIcon />}
			/>
			<TextField
				name="project_id"
				type="number"
				label="Project ID"
				placeholder="Enter"
				renderRightIcon={<SearchIcon />}
			/>
			<TextField
				name="client_id"
				type="number"
				label="Client ID"
				placeholder="Enter"
				renderRightIcon={<SearchIcon />}
			/>
			<TextField
				name="address_number"
				type="text"
				label="Address number"
				placeholder="Enter"
				renderRightIcon={<SearchIcon />}
			/>
			<SelectFieldCurrency />
			<SelectField
				name="currency_network"
				options={[{ value: 0, text: 'All' }, ...networks]}
				title="Network"
			/>
			<SelectField name="address_type" options={addressType} title="Address type" />
		</>
	);
};

export default Filters;
