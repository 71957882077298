import { FC } from 'react';
import { useField, useFormikContext } from 'formik';
import classNames from 'classnames';
import { CurrencySelect } from '../CurrencySelect/CurrencySelect';
import { ICurrencySelectField } from './types';

export const CurrencySelectField: FC<ICurrencySelectField> = ({ name, ...rest }) => {
	const [field, meta, helper] = useField(name);
	const { submitCount } = useFormikContext();
	const { value } = field;
	const isShowError = (meta.touched || submitCount > 0) && meta.error;
	const { setValue, setTouched } = helper;

	const handleSelect = (_value: string | number | null) => {
		setValue(_value);
	};

	const onSelectClick = () => {
		setTouched(true);
	};

	return (
		<div className={classNames('select-block', { 'select-block--error': isShowError })}>
			<CurrencySelect
				value={value}
				onChange={handleSelect}
				onClick={onSelectClick}
				helperNotification={
					isShowError ? (
						<p className="input-notification input-notification--error">{meta.error}</p>
					) : null
				}
				{...rest}
			/>
		</div>
	);
};
