import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { getProviderAllCurrencySettingRequest } from 'redux/reducers/providerCurrency/reducer';
import { getProviderCurrencyAll } from 'redux/reducers/providerCurrency/selectors';
import { CreateInvoiceFormInner } from './CreateInvoiceFormInner/CreateInvoiceFormInner';
import { ICreateInvoiceForm, IValues } from './types';

const initialValues: IValues = {
	currencyRequest: '',
	amountRequest: '',
	currencyPaid: '',
	rate: '',
	amountToPay: '',
	expiresTimeMinutes: '',
	clientEmail: '',
};

const validationSchema = yup.object().shape({
	currencyRequest: yup.string().required('Currency is required'),
	amountRequest: yup
		.number()
		.required('Amount is requierd')
		.moreThan(0, 'Amount should be more than 0')
		.typeError('Amount must be a number'),
	currencyPaid: yup.string().required('Pay in currency is required'),
	expiresTimeMinutes: yup.number().required('Expire ttime is required'),
	clientEmail: yup.string().required('Client email is required').email('Invalid email format'),
});

export type { IValues };

export const CreateInvoiceForm: FC<ICreateInvoiceForm> = ({ onSubmit, ...rest }) => {
	const dispatch = useDispatch();
	const currencies = useSelector(getProviderCurrencyAll);

	const handleSubmit = (values: IValues, helpers: FormikHelpers<IValues>) => {
		console.log('handleSubmit');
		const { setSubmitting } = helpers;
		const { currencyRequest, currencyPaid } = values;
		const currencyRequestOption =
			currencyRequest && currencies?.find((opt) => opt.id === +currencyRequest);
		const currencyPaidOption = currencyPaid && currencies?.find((opt) => opt.id === +currencyPaid);
		const newValues: IValues = {
			...values,
			currencyRequest: currencyRequestOption && currencyRequestOption?.currency.code,
			currencyPaid: currencyPaidOption && currencyPaidOption?.currency.code,
			currencyPaidNetwork: currencyPaidOption && currencyPaidOption.currency_network,
		};
		onSubmit(newValues, () => {
			setSubmitting(false);
		});
	};

	useEffect(() => {
		dispatch(getProviderAllCurrencySettingRequest({ apiParams: {} }));
	}, [dispatch]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			<Form>
				<CreateInvoiceFormInner onSubmit={onSubmit} currencies={currencies} {...rest} />
			</Form>
		</Formik>
	);
};
