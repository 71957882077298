import moment from 'moment';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createOrderMerchantRequest } from 'redux/reducers/merchantOrders/reducer';
import { ModalComment } from './ModalComment/ModalComment';
import { IPropsComments } from './types';

export const Comments: FC<IPropsComments> = ({ coments, orderId }) => {
	const [openAddComment, setOpenAddComment] = useState<boolean>(false);
	const dispatch = useDispatch();

	const onClose = () => {
		setOpenAddComment(false);
	};

	const onCreateComment = (comment: string) => {
		dispatch(
			createOrderMerchantRequest({
				body: {
					order_merchant_id: orderId,
					comment,
				},
				closePopup: onClose,
			}),
		);
	};

	return (
		<>
			{openAddComment && <ModalComment onClose={onClose} onCreateComment={onCreateComment} />}
			{coments.length ? (
				<div className="block-title block-title--line2">
					<p>Comment</p>
				</div>
			) : null}
			{coments?.map((coment) => (
				<div key={coment.id} className="approvements-user approvements-user--mb-24">
					<div className="approvements-user__icon">
						<svg
							width="46"
							height="46"
							viewBox="0 0 46 46"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect width="46" height="46" rx="12" fill="#F2F6FF" />
							<path
								d="M31 32V30C31 28.9391 30.5786 27.9217 29.8284 27.1716C29.0783 26.4214 28.0609 26 27 26H19C17.9391 26 16.9217 26.4214 16.1716 27.1716C15.4214 27.9217 15 28.9391 15 30V32"
								stroke="#4285F5"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M23 22C25.2091 22 27 20.2091 27 18C27 15.7909 25.2091 14 23 14C20.7909 14 19 15.7909 19 18C19 20.2091 20.7909 22 23 22Z"
								stroke="#4285F5"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<div className="approvements-user__info">
						<div className="approvements-user__name">
							<p>{coment.comment_owner_type}</p>
						</div>
						<div className="approvements-user__date">
							<p>{`${moment(coment.created_at).format('DD.MM.YYYY, HH:mm')} UTC`}</p>
						</div>
						<div className="approvements-user__text">
							<p>{coment.comment}</p>
						</div>
					</div>
				</div>
			))}
			<button onClick={() => setOpenAddComment(!openAddComment)} className="button" type="button">
				+ Add comment
			</button>
		</>
	);
};
