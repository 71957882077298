import { FC, useEffect, useState } from 'react';
import { putApprovementKYCRequest } from 'redux/reducers/providerApprovements/reducer';
import { IProviderKycRequest } from 'redux/reducers/providerApprovements/types';
import { getProviderApprovementsKYC } from 'redux/reducers/providerApprovements/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from 'utils/notify';
import { IProps } from './types';
import styles from './styles.module.scss';
import { ArrowLeftIcon } from '../../../../assets/inline-svg';

const Header: FC<IProps> = ({ title, classHeader }) => {
	const dispatch = useDispatch();
	const providerApprovementsKYC = useSelector(getProviderApprovementsKYC);

	const [check, setCkeck] = useState([]);

	useEffect(() => {
		setCkeck(providerApprovementsKYC);
	}, [providerApprovementsKYC]);

	const handleSentKYCid = () => {
		const payload: IProviderKycRequest = {
			transaction_approvement_ids: [...check],
			onSuccess: () => {
				notify('send', 'success');
			},
		};
		dispatch(putApprovementKYCRequest(payload));
	};
	return (
		<div className={styles.header}>
			<div className="content-header__actions">
				<button onClick={handleSentKYCid} className="button" type="button" disabled={!check.length}>
					Request on KYC documents
				</button>
			</div>
		</div>
	);
};

export default Header;
