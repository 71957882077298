/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApiDeleteWhiteListIpRecordParams } from 'services/api/provider/types';
import { IDeleteWhiteListIpRecordPayload } from '../administration/types';
import {
	IFormPayload,
	IMerchantSecurityStore,
	IParamasWhiteList,
	IParamasWhiteListDelete,
	IWhiteListResponse,
	// IApiDeleteWhiteListIpRecordParams,
} from './types';

export const initialState: IMerchantSecurityStore = {
	settings: null,
	languages: null,
	settingsLoading: false,
	whiteList: null,
};

const merchantSecurity = createSlice({
	name: '@@merchantSecurity',
	initialState,
	reducers: {
		changePasswordRequest: (state, action: PayloadAction<IFormPayload>) => state,
		getMerchantSecurityWhiteListRequest: (state, action: PayloadAction<IParamasWhiteList>) => state,

		getMerchantSecurityWhiteListResponse: (
			state,
			action: PayloadAction<Array<IWhiteListResponse>>,
		) => {
			const { payload } = action;
			state.whiteList = payload;
		},

		getMerchantSettingsLanguagesSuccess: (state, action: PayloadAction<any>) => {
			state.languages = action.payload;
		},
		deleteAllMerchantSecurityWhiteListRequest: (
			state,
			action: PayloadAction<IParamasWhiteListDelete>,
		) => state,

		deleteAllMerchantSecurityWhiteList: (state) => {
			if (state.whiteList) state.whiteList = null;
		},

		deleteWhiteListIpRecordRequest: (
			state,
			action: PayloadAction<IDeleteWhiteListIpRecordPayload>,
		) => state,
		deleteWhiteListIpRequest: (state, action: PayloadAction<IDeleteWhiteListIpRecordPayload>) =>
			state,
		deleteWhiteListIpSuccess: (
			state,
			{ payload }: PayloadAction<IApiDeleteWhiteListIpRecordParams>,
		) => {
			if (state.whiteList)
				state.whiteList = state.whiteList?.filter((element) => element.id !== payload.id);
		},
	},
});

export default merchantSecurity.reducer;
export const {
	changePasswordRequest,
	getMerchantSecurityWhiteListRequest,
	getMerchantSettingsLanguagesSuccess,
	deleteAllMerchantSecurityWhiteListRequest,
	deleteAllMerchantSecurityWhiteList,
	deleteWhiteListIpRecordRequest,
	getMerchantSecurityWhiteListResponse,
	deleteWhiteListIpRequest,
	deleteWhiteListIpSuccess,
} = merchantSecurity.actions;
