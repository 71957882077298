import { forwardRef } from 'react';
import { CalendarIcon, NoIcon } from 'assets/inline-svg';
import classNames from 'classnames';
import Field from 'v2Components/UI/Field';
import styles from './styles.module.scss';
import { TCustomInputRef, ICustomInputProps } from './types';

export const CustomInput = forwardRef<TCustomInputRef, ICustomInputProps>((props, ref) => {
	// eslint-disable-next-line react/prop-types
	const { onClick, value, closeElement, noMB } = props;

	return (
		<>
			<div className={styles['datepicker-input-wrapper']} ref={ref} onClick={onClick}>
				<Field
					value={value}
					readOnly
					placeholder="Select"
					type="text"
					classNameCustom={classNames({ 'datepicker-input--mb-0': noMB })}
				/>
				{value ? (
					''
				) : (
					<div
						className={classNames(
							styles['datepicker-input-wrapper__icon'],
							styles['datepicker-input-wrapper__icon--right'],
						)}
					>
						<CalendarIcon />
					</div>
				)}
			</div>
			{value ? (
				<div
					style={{ cursor: 'pointer' }}
					onClick={closeElement}
					className={classNames(
						styles['datepicker-input-wrapper__icon'],
						styles['datepicker-input-wrapper__icon--right'],
					)}
				>
					<NoIcon />
				</div>
			) : (
				''
			)}
		</>
	);
});
