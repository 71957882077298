import { TextField } from 'v2Components/formComponents/TextField';
import { PhoneInputField } from 'UI/Forms/PhoneInputField/PhoneInputField';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries } from 'redux/reducers/app/selectors';
import { useEffect, useState } from 'react';
import { getCountriesRequest } from 'redux/reducers/app/reducer';

export const Filters = () => {
	const dispatch = useDispatch();
	const countries = useSelector(getCountries);
	useEffect(() => {
		!countries && dispatch(getCountriesRequest());
	}, [countries, dispatch]);
	return (
		<>
			<TextField name="client_id" type="text" label="Client ID" placeholder="Client ID" />
			<TextField name="internal_id" type="text" label="Internal ID" placeholder="Internal ID" />
			<TextField name="last_name" type="text" label="Last name" placeholder="Last name" />
			<TextField name="email" type="text" label="Email" placeholder="Email" />
			<PhoneInputField
				name="phone"
				codeFieldName="phoneCode"
				placeholder="Phone"
				countries={countries || []}
			/>
		</>
	);
};
