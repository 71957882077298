import { toUTC } from 'utils/dates';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import { IReportsItem } from 'redux/reducers/projectReports/types';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';

export default (item: IReportsItem): TdItem[] => {
	const typeBy = (types: string) => {
		switch (types) {
			case 'merchant_balances_report':
				return 'Balances';
			case 'merchant_currency_flow_report':
				return 'Flow';
			case 'merchant_transaction_report':
				return 'Transaction';
			default:
				return '---';
		}
	};
	const { file_path, created_at, type, date_from, date_to } = item;
	return [
		{
			title: 'Type',
			children: typeBy(type),
		},
		{
			title: 'Created Date',
			children: toUTC(created_at),
		},
		{
			title: 'Time interval From',
			children: date_from || '---',
		},
		{
			title: 'Time interval To',
			children: date_to || '---',
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<a href={file_path} className="button--fs-14 button--type9" download>
						Download
					</a>
				</TableButtons>
			),
		},
	];
};
