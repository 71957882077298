import { useField, useFormikContext } from 'formik';
import classNames from 'classnames';
import { Select } from 'UI/GeneralSelect/GeneralSelect';
import { ISelectField } from './types';

export const SelectField = <T extends Record<string, any>>({
	name,
	title,
	options,
	...rest
}: ISelectField<T>) => {
	const [field, meta, helper] = useField(name);
	const { value } = field;
	const { submitCount } = useFormikContext();
	const isShowError = (meta.touched || submitCount > 0) && meta.error;
	const { setValue, setTouched } = helper;

	const handleSelect = (_value: string | null) => {
		setValue(_value);
	};

	const onSelectClick = () => {
		setTouched(true);
	};

	return (
		<div className={classNames('select-block', { 'select-block--error': isShowError })}>
			<Select<T>
				options={options}
				title={title}
				value={value}
				onChange={handleSelect}
				onClick={onSelectClick}
				helperNotification={
					isShowError ? (
						<p className="input-notification input-notification--error">{meta.error}</p>
					) : null
				}
				{...rest}
			/>
		</div>
	);
};
