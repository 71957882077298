import { FC } from 'react';
import { TablePlugIcon } from 'assets/inline-svg';
import { INoResultsProps } from './types';
import styles from './styles.module.scss';

export const NoResults: FC<INoResultsProps> = ({ onReset }) => {
	return (
		<div className={styles.noResult}>
			<div className={styles.noResult__icon}>
				<TablePlugIcon />
			</div>
			<p className={styles.noResult__text}>No results were found for your request</p>

			{/* <button type="button" className="button button--size2 button--type3" onClick={onReset}>
				Reset search request
			</button> */}
		</div>
	);
};
