import { IStoreState } from 'redux/types';

export const getMerchantBankAccount = (state: IStoreState) => state.merchantBankAccount;

export const getBankAccountsList = (state: IStoreState) => {
	return getMerchantBankAccount(state).bankAccountList;
};

export const getBankAccountNotDeleted = (state: IStoreState) =>
	getMerchantBankAccount(state).bankAccountList?.filter((el) => el.is_deleted === 0);

export const getWalletsList = (state: IStoreState) => {
	return getMerchantBankAccount(state).cryptoWalletList;
};

export const getWalletsListLoading = (state: IStoreState) => {
	return getMerchantBankAccount(state).loading;
};
export const getBankAccountData = (state: IStoreState) => {
	return getMerchantBankAccount(state).bankAccountData;
};
export const getBankAccountByCurrency = (state: IStoreState) => {
	return getMerchantBankAccount(state).bankAccountByCurrency;
};
