import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { DatepickerField } from 'v2Components/formComponents/DatepickerField';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { TextField } from 'v2Components/formComponents/TextField';
import { SearchIcon } from 'assets/inline-svg';
import { noRepeat } from 'utils/arrayNoRepeat';
import { capitalize, trimBottomLine } from 'utils/strings';
import { getProviderCurrencyAllNoSettings } from 'redux/reducers/providerCurrency/selectors';
import { getCurrencyNetwork, getFilter } from 'redux/reducers/app/selectors';
import { IFilter } from 'redux/reducers/app/types';
import { CurrencyOptionItem } from 'v2Components/layoutsComponents/OptionItem/CurrencySelector';
import { DefaultToken, Token } from 'v2Components/UI/Token';
import { statusOptions, transactionTypeOptions } from '../../filter.helper';
import style from './styles.module.scss';

interface IFilterCryptoBody {
	isCrypto: boolean;
	showFilter: boolean;
}

const FilterCryptoBody = ({ isCrypto, showFilter }: IFilterCryptoBody): JSX.Element => {
	const { values, resetForm, setValues } = useFormikContext<IFilter>();
	const filter = useSelector(getFilter);

	const network = useSelector(getCurrencyNetwork);
	const networks = network.map((net) => {
		return {
			id: net.currency_network,
			label: trimBottomLine(capitalize(net.currency_network_name)),
		};
	});

	const currencies =
		useSelector(getProviderCurrencyAllNoSettings)?.filter((item) =>
			isCrypto ? item.is_crypto : !item.is_crypto,
		) || [];
	const filterNetworks = noRepeat(networks, 'id');

	useEffect(() => {
		showFilter ? setValues({ ...filter }) : resetForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetForm, setValues, showFilter]);
	return (
		<div>
			<SelectField
				title="Currency"
				name="currency_id"
				options={[
					{
						id: 0,
						code: 'All',
						name: '',
						img_path: '',
						icon: '',
						is_active: 1,
						is_crypto: 1,
					},
					...currencies,
				]}
				valueProp="id"
				renderSelected={(selectedValue) =>
					(selectedValue && (
						<Token
							code={
								selectedValue.code === 'All'
									? selectedValue.code
									: (selectedValue.code || '').toUpperCase()
							}
							name={selectedValue.name}
						/>
					)) || <DefaultToken />
				}
				renderOption={({ code, name, img_path }) => (
					<Token code={code === 'All' ? code : (code || '').toUpperCase()} name={name} />
				)}
			/>
			<SelectField title="Type" options={transactionTypeOptions} name="transaction_type" />
			<DatepickerField
				label="Date from"
				maxDate={(values?.dateTo as Date) || null}
				name="date_from"
				inFilter
			/>
			<DatepickerField
				label="Date to"
				minDate={(values?.dateFrom as Date) || null}
				name="date_to"
				inFilter
			/>
			<TextField name="amount_from" type="number" label="Amount From" placeholder="Enter" />
			<TextField name="amount_to" type="number" label="Amount To" placeholder="Enter" />

			<SelectField name="status" title="Status" options={statusOptions} />

			<TextField
				name="transaction_id"
				label="Tx ID"
				placeholder="Index"
				renderRightIcon={<SearchIcon />}
			/>
			{isCrypto ? (
				<>
					<SelectField
						name="currency_network"
						title="Network"
						options={[{ id: 'All', label: 'All' }, ...filterNetworks]}
						valueProp="id"
						labelProp="label"
					/>
					<TextField
						name="hash_transaction"
						label="Tx Hash"
						placeholder="Index"
						classNameCustom={style.input__mb_0}
						renderRightIcon={<SearchIcon />}
					/>
				</>
			) : null}
		</div>
	);
};

export default FilterCryptoBody;
