import { FC } from 'react';
import * as yup from 'yup';
import L from 'i18n-react';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { CheckBoxField } from 'UI/Forms/CheckBoxField/CheckBoxField';
import { useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';
import { ArrowLeftIcon } from 'assets/inline-svg';
import { TextField } from 'UI/Forms/TextField/TextField';
import { routes } from 'routes/routes';
import { IProfileProps } from './types';
import styles from './styles.module.scss';

const ProfileItem: FC<IProfileProps> = ({
	onSubmit,
	valueState,
	resetPassword,
	resetTwoFactor,
	handleChange,
	userRole,
	activTogle,
}) => {
	const user = useSelector(getUserData);

	const validationSchema = yup.object().shape({
		firstName: yup
			.string()
			.required('First name is required')
			.min(2, 'Too Short!')
			.max(255, 'Too Long!'),
		lastName: yup
			.string()
			.required('Last name is required')
			.min(2, 'Too Short!')
			.max(255, 'Too Long!'),
		companyName: yup
			.string()
			.required('Company name is required')
			.min(1, 'Too Short!')
			.max(255, 'Too Long!'),
		idMerchant: yup.string().required('Id is required'),
		email: yup
			.string()
			.required(String(L.translate('Validation.email_required')))
			.email(String(L.translate('Validation.email_format'))),
		phone: yup
			.string()
			.required('Phone number is required')
			.min(2, 'Too Short!')
			.max(25, 'Too Long!'),
		country: yup.string().required('Country is required').min(2, 'Too Short!').max(25, 'Too Long!'),
		city: yup.string().required('City is required').min(2, 'Too Short!').max(25, 'Too Long!'),
		addressLineOne: yup
			.string()
			.required('Address Line One is required')
			.min(2, 'Too Short!')
			.max(255, 'Too Long!'),
		addressLineTwo: yup
			.string()
			.required('Address line two is required')
			.min(2, 'Too Short!')
			.max(255, 'Too Long!'),
	});

	return (
		<Formik initialValues={valueState} validationSchema={validationSchema} onSubmit={onSubmit}>
			{({ isSubmitting }) => (
				<Form>
					<div className="mobile-project-info">
						<div className="header-project">
							<div className="header-project__name">
								<p>Provider</p>
							</div>
						</div>
					</div>
					<div className={styles.main__wrapper}>
						<div className={styles.fields}>
							<div className="fields-item">
								<div className="fields-list">
									<div className="fields-switch">
										<div className="fields-switch__text">
											<p>Activate merchant</p>
										</div>
										<div className="switch">
											<CheckBoxField
												name="activateMerchant"
												onClick={handleChange}
												userRole={userRole}
												valueDefault={valueState.activateMerchant}
												activTogle={activTogle}
											/>
										</div>
									</div>
									<TextField label="First name" placeholder="Enter first name" name="firstName" />
									<TextField label="Last name" placeholder="Enter last name" name="lastName" />
									<TextField
										label="Company name (legal entity name)"
										placeholder="Enter company name"
										type="text"
										name="companyName"
									/>
									<TextField
										label="Enter ID Merchant"
										type="text"
										placeholder="Enter ID Merchant"
										name="idMerchant"
									/>
									<TextField label="Email address" placeholder="Enter email address" name="email" />
									<TextField
										label="Phone number"
										type="text"
										placeholder="Enter phone number"
										name="phone"
										classNameCustom={styles.mb_0}
										classNameInputItem={styles.mb_0}
									/>
								</div>
							</div>
							<div className="fields-item">
								<div className="fields-list">
									<div className="fields-list__title">
										<p>Location info</p>
									</div>
									<TextField label="Country" placeholder="Enter country" name="country" />
									<TextField label="City" placeholder="Enter city" name="city" />
									<TextField
										label="Address Line 1"
										placeholder="Enter address"
										name="addressLineOne"
									/>
									<TextField
										label="Address Line 2"
										placeholder="Enter address"
										name="addressLineTwo"
										classNameCustom={styles.mb_0}
										classNameInputItem={styles.mb_0}
									/>
								</div>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default ProfileItem;
