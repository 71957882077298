import { useEffect, FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Pagination } from 'UI/Pagination/Pagination';
import {
	getProviderCurrencyAllLoading,
	getProviderWalletsAddressesData,
} from 'redux/reducers/providerWalletAddresses/selectors';
import useFilterList from 'hooks/useFilterList';
import { getProviderWalletAddressesRequest } from 'redux/reducers/providerWalletAddresses/reducer';
import Filters from 'v2Pages/Provider/WalletAddressesPage/WalletAddresses/WalletAddressesTable/Filters/Filters';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { getFilter } from 'redux/reducers/app/selectors';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import Table from 'v2Components/commomComponents/Table';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { IFilter } from '../../../../redux/reducers/app/types';

import { getFilterWalletAddressesHistory } from '../WalletAddresses/WalletAddressesTable/filter.helper';
import TableRow from '../WalletAddresses/WalletAddressesTable/TableRow';
import styles from './styles.module.scss';

const WalletAddressProvider: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const filter = useSelector(getFilter);
	const walletAddresses = useSelector(getProviderWalletsAddressesData);
	const loading = useSelector(getProviderCurrencyAllLoading);
	const [currentPage, setCurrentPage] = useState(1);
	const totalPages = walletAddresses?.last_page || 1;
	const [showFilter, setShowFilter] = useState(false);
	const filterList = useFilterList(getFilterWalletAddressesHistory);
	const isShowPagination = totalPages > 1 && !loading;
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('WalletAddress');
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());
	const onSubmit = (data: IFilter) => {
		dispatch(setFilter(data));
		setShowFilter(false);
	};
	useEffect(() => {
		location &&
			dispatch(
				getProviderWalletAddressesRequest({
					apiParams: {
						...filter,
						current_page: currentPage,
					},
				}),
			);
	}, [currentPage, dispatch, filter, location]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<div className={styles.main__wrapper}>
			<FilterWrapper
				filterList={filterList}
				deleteItemFilter={deleteItemFilter}
				onClearFilter={onClearFilter}
				onOpenFilter={() => setShowFilter(true)}
				className={styles.filters}
			/>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<Filters />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				gridTemplateColumns={gridTemplateColumns}
				getRow={TableRow}
				bodyItems={walletAddresses?.data || []}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={totalPages}
						forcePage={currentPage}
						onPageChange={setCurrentPage}
					/>
				</PaginationWrapper>
			)}
		</div>
	);
};

export default WalletAddressProvider;
