import { FC } from 'react';
import { useField } from 'formik';
import { IImageFieldProps } from './types';
/* eslint-disable  @typescript-eslint/no-explicit-any */
const PictureInput: FC<IImageFieldProps> = ({ form }) => {
	const [field, meta] = useField('file');
	const isShowError = meta.touched && meta.error;
	return (
		<div>
			<div className="upload-file-box-title">
				<p>Project Logo</p>
			</div>
			<label className="upload-file-box">
				<input
					type="file"
					className="hidden"
					id="upload1"
					name="file"
					accept="image/*"
					onChange={(e: any) => form.setFieldValue('file', e.target?.files[0])}
				/>
				<span className="upload-file">
					<span className="upload-file__icon-upload">
						<img src="images/ic_image.svg" alt="" />
					</span>
					<span className="upload-file__title">Drop your Image here or</span>
					<span className="upload-file__buttons">
						<label htmlFor="upload1" className="button button--size2 button--type3">
							Upload
						</label>
					</span>
					<span className="upload-file__text">
						{form?.values?.file?.name ? form?.values?.file?.name : 'Supports jpg, png and gif'}
					</span>

					<span className="upload-file__size">* Max file size 5Mb.</span>
				</span>
			</label>
			{isShowError && <p className="input-notification input-notification--error">{meta.error}</p>}
		</div>
	);
};

export default PictureInput;
