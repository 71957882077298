import { FC } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { ProjectInfo } from 'layouts-elements/ProjectInfo/ProjectInfo';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { SaveInvoiceForm } from './SaveInvoiceForm/SaveInvoiceForm';
import { ISaveInvoicePopup } from './types';

export const SaveInvoicePopup: FC<ISaveInvoicePopup> = ({
	isOpened,
	onClose,
	isMerchant,
	...rest
}) => {
	return (
		<PopupModal open={isOpened} onClose={onClose}>
			<div className="popup popup--width-460 invoice-popup">
				<button aria-label="button" type="button" className="popup__close" onClick={onClose}>
					<PopUpCloseIcon />
				</button>

				<div className="popup-header">
					<p className="popup-header__title popup-header__title--ta-l ">Save invoice</p>
				</div>

				{isMerchant && (
					<div className="select-block select-block--project-select">
						<ProjectInfo />
					</div>
				)}

				<SaveInvoiceForm onClose={onClose} {...rest} />
			</div>
		</PopupModal>
	);
};
