import { FC } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { TextField } from 'UI/Forms/TextField/TextField';
import { IValues, ITotpPopup } from './types';

const initialValues: IValues = {
	totp: '',
};

export type { IValues };

export const TotpPopup: FC<ITotpPopup> = ({ onSubmit, onClose }) => {
	const validationSchema = yup.object().shape({
		totp: yup
			.string()
			.required('Totp code is required')
			.min(6, 'Totp is too short')
			.max(6, 'Totp is too long'),
	});
	return (
		<div className="popup-window">
			<div className="popup-window__inside">
				<div className="popup">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ isSubmitting }) => (
							<Form>
								<button
									aria-label="button"
									onClick={onClose}
									type="button"
									className="popup__close"
								>
									<PopUpCloseIcon />
								</button>
								<div className="popup-header">
									<p className="popup-header__title popup-header__title--ta-l">2FA Authorization</p>
									<div className="popup-text popup-text--ta-l">
										<p>Enter TOTP code</p>
									</div>
								</div>
								<TextField
									label="Enter the 2FA 6-digit Code"
									type="text"
									placeholder="Enter code"
									name="totp"
									maxLength={6}
									onlyNumbers
								/>
								<div className="popup-button popup-button--type2">
									<button
										onClick={onClose}
										type="button"
										className="button button--full-width button--type2"
									>
										Cancel
									</button>
									<button
										type="submit"
										className="button button--full-width"
										disabled={isSubmitting}
									>
										Confirm
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};
