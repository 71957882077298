import { FC } from 'react';
import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import classNames from 'classnames';
import { IProjectStatus, IProjectStatusSelectProps } from './types';

export const ProjectStatusSelect: FC<IProjectStatusSelectProps> = ({
	statuses,
	active,
	onChange,
	onClose,
}) => {
	const { open, setOpen, triggerRef, dropRef } = useSelect({ onClose });

	const handleClick = () => {
		if (open) {
			setOpen(false);
			onClose?.();
			return;
		}
		if (statuses?.length) {
			setOpen(true);
		}
	};

	const handleChange = (newStatus: IProjectStatus) => {
		setOpen(false);
		// onClose(), then onChange() - important for correct field validation
		onClose?.();
		onChange(newStatus);
	};

	return (
		<div className={classNames('select', { active: open })}>
			<button
				type="button"
				className={classNames('select__current', active?.activeClassName)}
				ref={triggerRef}
				onClick={handleClick}
			>
				{active?.name || 'Select status'}

				<span className="select__current-arrow">
					<TriangleArrowDownIcon2 />
				</span>
			</button>

			<div className="select__drop" ref={dropRef}>
				<div className="select__drop-scroll">
					<div className="select__drop-item">
						<ul>
							{statuses?.map((status) => (
								<li key={status.responseCode}>
									<button type="button" onClick={() => handleChange(status)}>
										{status.name}
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
