import { IStoreState } from 'redux/types';

export const getMerchantSettings = (state: IStoreState) => state.merchantSettings;
export const getMerchantSecurity = (state: IStoreState) => state.merchantSecurity;

export const getSettings = (state: IStoreState) => {
	return getMerchantSettings(state).settings;
};

export const getWhiteList = (state: IStoreState) => {
	return getMerchantSecurity(state).whiteList;
};
