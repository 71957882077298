import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	addCryptoWalletRequest,
	deleteCryptoWalletRequest,
	editCryptoWalletRequest,
} from 'redux/reducers/providerBankAccount/reducer';
import { PlusIcon, Union2Img } from 'assets/inline-svg';
import { notify } from 'utils/notify';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { IAddCryptoWalletParams } from 'services/api/provider/types';
import { getCryptoWalletList } from 'redux/reducers/providerBankAccount/selectors';
import CryptoWalletItem from './CryptoWalletItem';
import { TEditWalletFormOnSubmit } from '../types';
import CryptoWalletForm from './CryptoWalletForm';
import CryptoWalletEdit from './CryptoWalletEdit';
import DeleteWalletModal from '../modal/DeleteWalletModal';
import styles from './styles.module.scss';

const CryptoWallet = () => {
	const dispatch = useDispatch();
	const walletList = useSelector(getCryptoWalletList);
	const [openDelModal, setOpenDelModal] = useState(false);
	const [showFilter, setShowFilter] = useState(false);
	const [item, setItem] = useState<any>({
		provider_reserve_wallet_id: null,
		reserve_wallet_name: null,
		reserve_wallet: null,
	});
	const [openModal, setOpenModal] = useState(false);
	const closeModal = () => setOpenModal(false);
	const closeDelModal = () => setOpenDelModal(false);

	const openEditModal = (_item: any) => {
		setItem(_item);
		setOpenModal(true);
	};

	const openDeleteModal = (_item: any) => {
		setItem(_item);
		setOpenDelModal(true);
	};
	const handleDeleteWallet = () => {
		dispatch(
			deleteCryptoWalletRequest({
				apiParams: { provider_reserve_wallet_id: item.id },
				onSuccess: () => {
					closeDelModal();
					notify('Wallet has been successfully deleted', 'success');
				},
			}),
		);
	};

	const handleEditWallet: TEditWalletFormOnSubmit = (values) => {
		dispatch(
			editCryptoWalletRequest({
				apiParams: values,
				onSuccess: () => {
					closeModal();
					notify('Wallet has been successfully edited', 'success');
				},
			}),
		);
	};

	const handleAddWallet = (values: IAddCryptoWalletParams, resetForm: () => void) => {
		dispatch(
			addCryptoWalletRequest({
				apiParams: values,
				onSuccess: () => {
					closeModal();
					resetForm();
					notify('Wallet has been successfully added', 'success');
				},
			}),
		);
	};
	return (
		<div className={styles.crypto}>
			{walletList?.map((el) => (
				<CryptoWalletItem
					key={el.id}
					item={el}
					openEditModal={openEditModal}
					openDeleteModal={openDeleteModal}
				/>
			))}
			<div className={styles.append} onClick={() => setShowFilter(true)}>
				<div className={styles.append__icon}>
					<PlusIcon />
				</div>
				<p className={styles.append__text}>
					Add New <br /> Crypto Wallet
				</p>
				<div className={styles.append__union}>
					<Union2Img />
				</div>
			</div>
			<PopupModal open={openDelModal} onClose={closeDelModal}>
				<DeleteWalletModal onClose={closeDelModal} onSubmit={handleDeleteWallet} />
			</PopupModal>
			<CryptoWalletEdit
				setShowFilter={setOpenModal}
				showFilter={openModal}
				onSubmit={handleEditWallet}
				item={item}
			/>
			<CryptoWalletForm
				setShowFilter={setShowFilter}
				showFilter={showFilter}
				onSubmit={handleAddWallet}
			/>
		</div>
	);
};

export default CryptoWallet;
