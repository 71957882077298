/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IProviderWalletAddressesStore,
	IProviderWalletAddressesResponse,
	IWalletAddressesMerchantIdResponse,
	IWalletAddressesMerchantIdRequest,
} from './types';

export const initialState: IProviderWalletAddressesStore = {
	providerWalletsAddresses: null,
	providerWalletsAddressesLoading: false,
	providerWalletsAddressesClient: null,
	providerWalletsAddressesClientLoading: false,
	merchantOwnerId: '',
};

const providerWalletAddresses = createSlice({
	name: '@@providerWalletAddresses',
	initialState,
	reducers: {
		getProviderWalletAddressesRequest: (state, _: PayloadAction<any>) => {
			state.providerWalletsAddressesLoading = true;
		},
		getProviderWalletAddressesSuccess: (
			state,
			{ payload }: PayloadAction<IProviderWalletAddressesResponse>,
		) => {
			state.providerWalletsAddresses = payload;
			state.providerWalletsAddressesLoading = false;
		},
		getProviderWalletAddressesClientRequest: (state, _: PayloadAction<any>) => {
			state.providerWalletsAddressesClientLoading = true;
		},
		getProviderWalletAddressesSuccessClient: (
			state,
			{ payload }: PayloadAction<IProviderWalletAddressesResponse>,
		) => {
			state.providerWalletsAddressesClient = payload;
			state.providerWalletsAddressesClientLoading = false;
		},
		getOwnerMerchantIdRequest: (state, _: PayloadAction<IWalletAddressesMerchantIdRequest>) => {
			// state.merchantOwnerId = true;
		},
		getOwnerMerchantIdSuccess: (
			state,
			{ payload }: PayloadAction<IWalletAddressesMerchantIdResponse>,
		) => {
			state.merchantOwnerId = payload.owner_merchant_id;
		},
	},
});

export default providerWalletAddresses.reducer;
export const {
	getProviderWalletAddressesRequest,
	getProviderWalletAddressesSuccess,
	getProviderWalletAddressesClientRequest,
	getProviderWalletAddressesSuccessClient,
	getOwnerMerchantIdRequest,
	getOwnerMerchantIdSuccess,
} = providerWalletAddresses.actions;
