import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilter } from 'redux/reducers/app/selectors';
import classNames from 'classnames';
import { CsvIcon, ExcelIcon } from 'assets/inline-svg';
import { IReportCreateMenuProps } from './types';
import styles from './styles.module.scss';

export const ReportCreateMenu: FC<IReportCreateMenuProps> = ({
	getCreateReport,
	isOpen,
	menuRef,
	tab,
	onClose,
}) => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const handleXLSX = () => {
		if (getCreateReport) {
			const { payload, reducer } = getCreateReport(filter, tab || 'crypto', 'xlsx');
			dispatch(reducer({ ...payload }));
		}
		onClose();
	};
	const handleCSV = () => {
		if (getCreateReport) {
			const { payload, reducer } = getCreateReport(filter, tab || 'crypto', 'csv');
			dispatch(reducer({ ...payload }));
		}
		onClose();
	};
	return (
		<div className={classNames(styles.reportCreateMenu, { [styles.active]: isOpen })} ref={menuRef}>
			<div className={styles.reportCreateMenu__item} onClick={handleXLSX}>
				<ExcelIcon />
				<span>Create XLSX</span>
			</div>

			<div className={styles.reportCreateMenu__item} onClick={handleCSV}>
				<CsvIcon />
				<span>Create CSV</span>
			</div>
		</div>
	);
};
