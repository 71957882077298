/* eslint-disable no-param-reassign */
import { store } from 'redux/store';
import { getAuthState } from 'redux/reducers/auth/selectors';
import { IAxiosRequestConfig } from './types';

const onSuccess = (config: IAxiosRequestConfig) => {
	const state = store.getState();
	const auth = getAuthState(state);

	if (auth.token) {
		config.headers = {
			Authorization: `Bearer ${auth.token}`,
		};
	}

	return config;
};

export default onSuccess;
