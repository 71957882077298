import { FC } from 'react';
import T from 'i18n-react';
import payment5Icon from 'assets/inline-svg/payment/payment-5.svg';
import { PaymentWarningIcon } from 'assets/inline-svg';
import { IMakePayment } from './types';
import { BackButton } from '../Buttons/BackButton';

export const TimeOver: FC<IMakePayment> = ({ startPayment }) => {
	return (
		<div className="payment-boxes">
			<div className="payment-conf">
				<span className="payment-title">
					<img src={payment5Icon} alt="icon" />
					{T.translate('TimeOver.title')}
				</span>
				{startPayment !== '1' && (
					<div className="payment-warng">
						<PaymentWarningIcon />
						<span className="payment-warng_text">{T.translate('TimeOver.content_message')}</span>
					</div>
				)}
				<span className="payment-text">
					<span>{T.translate('TimeOver.sub_message')}</span>
				</span>
			</div>

			<BackButton />
		</div>
	);
};
