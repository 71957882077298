import { AuthHeader } from 'layouts-elements/AuthHeader/AuthHeader';
import { ProviderInvoiceLink } from 'components/Provider/ProviderInvoiceLink/ProviderInvoiceLink';

export const ProviderInvoiceLinkPage = () => {
	return (
		<>
			<AuthHeader />
			<ProviderInvoiceLink />
		</>
	);
};
