import { TextField } from 'v2Components/formComponents/TextField';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { useDispatch } from 'react-redux';
import {
	addStakeRequest,
	addUnstakeRequest,
	cancelAllUnstakingsRequest,
	createTRXDelegationRequest,
} from 'redux/reducers/providerTRXManagement/reducer';
import {
	ICreateTRXDelegation,
	IDelete,
	IStakingParams,
} from 'redux/reducers/providerTRXManagement/types';
import DropdownDialogItem from './DropdownDialogItem';
import styles from './styles.module.scss';
import { types } from '../index';

interface IProps {
	type?: types;
}

const stakingItems = [
	{ value: 'bandwidth', label: 'bandwidth' },
	{ value: 'energy', label: 'energy' },
];
const DelegateForm = () => {
	return (
		<>
			<SelectField
				name="resource_type"
				title="Type"
				placeholder="Type"
				options={stakingItems || []}
				valueProp="value"
				labelProp="label"
			/>
			<TextField name="address_to" label="Owner address" placeholder="Owner address" />
			<TextField name="address_from" label="Receiver address" placeholder="Receiver address" />
			<TextField
				name="amount"
				label="Amount"
				placeholder="Amount"
				type="number"
				classNameCustom={styles.mb_0}
			/>
		</>
	);
};

const StakeForm = () => {
	return (
		<>
			<SelectField
				name="type"
				title="Type"
				placeholder="Type"
				options={stakingItems || []}
				valueProp="value"
				labelProp="label"
			/>
			<TextField name="owner_address" label="Owner address" placeholder="Owner address" />
			<TextField name="amount" label="Amount" placeholder="Amount" classNameCustom={styles.mb_0} />
		</>
	);
};

const UnstakeForm = () => {
	return (
		<>
			<SelectField
				name="type"
				title="Type"
				placeholder="Type"
				options={stakingItems || []}
				valueProp="value"
				labelProp="label"
			/>
			<TextField name="owner_address" label="Owner address" placeholder="Owner address" />
			<TextField name="amount" label="Amount" placeholder="Amount" classNameCustom={styles.mb_0} />
		</>
	);
};

const DropdownDialogs = ({ type }: IProps) => {
	const dispatch = useDispatch();
	const addStake = (params: IStakingParams) =>
		dispatch(
			addStakeRequest({
				...params,
				staking_type: 'stake',
			}),
		);

	const addUnstake = (params: IStakingParams) =>
		dispatch(
			addStakeRequest({
				...params,
				staking_type: 'unstake',
			}),
		);
	const addTRXDelegation = (params: ICreateTRXDelegation) =>
		dispatch(
			createTRXDelegationRequest({
				...params,
			}),
		);
	const unstakeCancel = () => dispatch(cancelAllUnstakingsRequest());
	return (
		<div className={styles.dropdown}>
			{type === types.DELEGATION && (
				<DropdownDialogItem title="Delegate" handleSubmit={addTRXDelegation}>
					<DelegateForm />
				</DropdownDialogItem>
			)}
			{type === types.STAKING && (
				<>
					<DropdownDialogItem title="Stake" handleSubmit={addStake}>
						<StakeForm />
					</DropdownDialogItem>
					<DropdownDialogItem title="Unstake" handleSubmit={addUnstake}>
						<StakeForm />
					</DropdownDialogItem>
				</>
			)}
			{type === types.OVERVIEW && (
				<DropdownDialogItem title="Unstake Cancel" handleSubmit={unstakeCancel}>
					<div />
				</DropdownDialogItem>
			)}
		</div>
	);
};

export default DropdownDialogs;
