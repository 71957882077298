import { trimLastZeros } from './strings';

export function toMaxDecimals(value: number | string, decimals: number) {
	const maxNoExponent = 1e21;
	const valueNum = Number(value);

	if (decimals < 1 || Math.abs(valueNum) >= maxNoExponent) {
		return valueNum.toFixed(decimals);
	}
	return valueNum.toFixed(decimals).replace(/\.?0+$/, '');
}

export function toGroupFixed(value: number | string, decimals: number) {
	const numFormat = new Intl.NumberFormat('en-GB', {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	});
	return numFormat.format(Number(value));
}

export function toGroupMaxDecimals(value: number | string, decimals: number) {
	const numFormat = new Intl.NumberFormat('en-GB', { maximumFractionDigits: decimals });
	return numFormat.format(Number(value));
}

export function convertExponentialToDecimal(n: number): number | string {
	const sign = +n < 0 ? '-' : '';
	const toStr = n.toString();
	if (!/e/i.test(toStr)) {
		return n;
	}
	const [lead, decimal, pow] = n
		.toString()
		.replace(/^-/, '')
		.replace(/^([0-9]+)(e.*)/, '$1.$2')
		.split(/e|\./);
	return +pow < 0
		? `${sign}0.${'0'.repeat(Math.max(Math.abs(+pow) - 1 || 0, 0))}${lead}${decimal}`
		: sign +
				lead +
				(+pow >= decimal.length
					? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
					: `${decimal.slice(0, +pow)}.${decimal.slice(+pow)}`);
}

export function isExist(num: number | undefined | null | boolean | string) {
	return !!num || num === 0;
}

export const formatAmount = (amount: number | string, viewDecimalsPaid?: number) =>
	isExist(amount) && trimLastZeros(Number(amount).toFixed(viewDecimalsPaid));
