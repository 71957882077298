import { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { toUTC } from 'utils/dates';
import { LockIcon, TrashIcon, PencilWithLineIcon, LockCloseIcon } from 'assets/inline-svg';
import {
	updateMerchantApprovalManagementRequest,
	deleteMerchantRequest,
	postBlockRequest,
	editMerchantRoleRequest,
	updateMerchantApprovalRequest,
	editMerchantNotProviderRoleRequest,
	postBlockMerchantRequest,
} from 'redux/reducers/merchantApprovalManagement/reducer';
import {
	TApiGetIApiMerchantApprovalManagementParamsPayload,
	IEditMerchantRolePayload,
} from 'redux/reducers/merchantApprovalManagement/types';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { EditRole } from 'UI/Popups/EditRole/EditRole';
import { TRoleAction } from 'v2Pages/Provider/UserProfilePage/UserProfileForm/RoleBlock/types';
import { TEditRoleOnSubmit } from 'UI/Popups/EditRole/types';
import DeleteUserAccount from 'v2Pages/Provider/AdministrationPage/TeamMembers/TableRow/DeleteUserAccount/DeleteUserAccount';
import { useDispatch, useSelector } from 'react-redux';

import { useMerchantMatchUrl } from 'hooks';
import { getUserType } from 'redux/reducers/auth/selectors';
import { constUserTypes } from 'services/constants/user-types';
import { notify } from 'utils/notify';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { IBodyProps } from '../types';
import BlockUserAccount from '../BlockUserAccount/BlockUserAccount';

// /* eslint-disable */

const TableRow = ({ item }: IBodyProps): TdItem[] => {
	const { id, email, name, role, projects, status, blocked, last_login, updated_at, created_at } =
		item;
	const dispatch = useDispatch();
	const history = useHistory();
	const oneMerchantUrl = useMerchantMatchUrl();
	const { merchantId } = useParams<{ merchantId: string }>();
	const [deletePopUp, setDeletePopUp] = useState(false);
	const [blockPopUp, setblockPopUp] = useState(false);
	const [block, setBlock] = useState(blocked);
	const [action, setAction] = useState<TRoleAction>(null);
	const closeModal = () => setAction(null);
	const deletePopUpModal = () => setDeletePopUp(false);
	const blockPopUpModal = () => setblockPopUp(false);
	const userType = useSelector(getUserType);
	const isProvider = userType === constUserTypes.PROVIDER;

	const handleEditSubmit: TEditRoleOnSubmit = (newRole) => {
		const payload: IEditMerchantRolePayload = {
			apiParams: {
				merchant_id: id,
				role: newRole.code,
				totp: '000000',
			},
			newRoleName: newRole.name,
			userType,
			onSuccess: () => {
				closeModal();
				notify('Role changed successfully', 'success');
				const payloadUpdate: TApiGetIApiMerchantApprovalManagementParamsPayload = {
					apiParams: {
						merchant_owner_id: merchantId,
						per_page: 15,
						current_page: 1,
					},
				};
				isProvider
					? dispatch(updateMerchantApprovalManagementRequest(payloadUpdate))
					: dispatch(updateMerchantApprovalRequest(payloadUpdate));
			},
		};
		isProvider
			? dispatch(editMerchantRoleRequest(payload))
			: dispatch(editMerchantNotProviderRoleRequest(payload));
	};

	const handleDeleteSubmit = (iDs: number) => {
		dispatch(
			deleteMerchantRequest({
				id: iDs,
				onSuccess: () => {
					notify('User delete', 'success');

					const payload: TApiGetIApiMerchantApprovalManagementParamsPayload = {
						apiParams: {
							merchant_owner_id: merchantId,
							per_page: 15,
							current_page: 1,
						},
					};
					isProvider
						? dispatch(updateMerchantApprovalManagementRequest(payload))
						: dispatch(updateMerchantApprovalRequest(payload));
				},
				userType,
			}),
		);
		deletePopUpModal();
	};

	const handleBlockSubmit = () => {
		const payload = {
			id,
			is_block: !block,
		};
		setBlock(() => !block);
		isProvider ? dispatch(postBlockRequest(payload)) : dispatch(postBlockMerchantRequest(payload));
		blockPopUpModal();
	};

	return [
		{
			title: 'Id',
			children: (
				<NavLink
					style={{ cursor: 'pointer' }}
					className="link"
					to={`${oneMerchantUrl}/approval-management/user-management/${id}`}
				>
					{id}
				</NavLink>
			),
		},
		{
			title: 'Email',
			children: email,
		},
		{
			title: 'Full Name',
			children: name,
		},
		{
			title: 'Project',
			children: projects,
		},
		{
			title: 'Role',
			children: role?.name,
		},
		{
			title: 'Last entry',
			children: typeof last_login === 'number' ? toUTC(last_login * 1000) : '-',
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<button aria-label="button" type="button" onClick={() => setAction('edit')}>
						<PencilWithLineIcon />
					</button>
					<button
						onClick={() => {
							setblockPopUp(!blockPopUp);
						}}
						type="button"
					>
						{block ? <LockCloseIcon /> : <LockIcon />}
					</button>
					<button
						aria-label="button"
						type="button"
						onClick={() => {
							setDeletePopUp(!deletePopUp);
						}}
					>
						<TrashIcon />
					</button>
					<PopupModal open={action === 'edit'} onClose={closeModal}>
						<EditRole
							initRole={item.role}
							onSubmit={handleEditSubmit}
							onClose={closeModal}
							type="ApprovalManagement"
						/>
					</PopupModal>
					<PopupModal open={blockPopUp} onClose={blockPopUpModal}>
						<BlockUserAccount
							onClose={blockPopUpModal}
							onSubmit={handleBlockSubmit}
							id={id}
							block={block}
						/>
					</PopupModal>
					<PopupModal open={deletePopUp} onClose={deletePopUpModal}>
						<DeleteUserAccount onClose={deletePopUpModal} onSubmit={handleDeleteSubmit} id={id} />
					</PopupModal>
				</TableButtons>
			),
		},
	];
};

export default TableRow;
