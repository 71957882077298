/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TProviderInvoices,
	IProviderInvoice,
	IGetInvoiceParams,
	IGetInvoiceResponse,
	IGetInvoiceStatusResponse,
} from 'services/api/provider/types';
import {
	IProviderInvoicesStore,
	IGetProviderInvoicesPayload,
	ICreateInvoicePayload,
	IDownloadInvoicePDFPayload,
	ISendInvoicePayload,
	IGetInvoiceLinkPayload,
	IDownloadCSVProviderInvoicesPayload,
	IGetInvoiceStatusPayload,
} from './types';

export const initialState: IProviderInvoicesStore = {
	providerInvoices: null,
	providerInvoicesLoading: false,
	createInvoiceLoading: false,
	pdfFile: null,
	saveLoading: false,
	invoiceLoading: false,
	invoice: null,
	downloadCSVLoading: false,
	status: null,
};

const providerInvoices = createSlice({
	name: '@@providerInvoices',
	initialState,
	reducers: {
		// 📌 Get invoices
		getProviderInvoicesRequest: (state, action: PayloadAction<IGetProviderInvoicesPayload>) => {
			state.providerInvoicesLoading = true;
		},
		getProviderInvoicesSuccess: (state, action: PayloadAction<TProviderInvoices>) => {
			state.providerInvoices = action.payload;
			state.providerInvoicesLoading = false;
		},
		getProviderInvoicesError: (state) => {
			state.providerInvoicesLoading = false;
		},
		// 📌 Create invoice
		createInvoiceRequest: (state, action: PayloadAction<ICreateInvoicePayload>) => {
			state.createInvoiceLoading = true;
		},
		createInvoiceSuccess: (state, action: PayloadAction<IProviderInvoice>) => {
			state.createInvoiceLoading = true;
		},
		createInvoiceError: (state) => {
			state.createInvoiceLoading = true;
		},
		// 📌 Download invoice
		downloadInvoicePDFRequest: (state, action: PayloadAction<IDownloadInvoicePDFPayload>) => {
			state.saveLoading = true;
		},
		downloadInvoicePDFSuccess: (state, action: PayloadAction<any>) => {
			state.saveLoading = false;
			state.pdfFile = action;
		},
		downloadInvoicePDFError: (state) => {
			state.saveLoading = false;
		},
		// 📌 Send invoice
		sendInvoiceRequest: (state, action: PayloadAction<ISendInvoicePayload>) => {
			state.saveLoading = true;
		},
		sendInvoiceSuccess: (state, action: PayloadAction<any>) => {
			state.saveLoading = false;
		},
		sendInvoiceError: (state) => {
			state.saveLoading = false;
		},
		// 📌 Get invoice link
		getInvoiceLinkRequest: (state, action: PayloadAction<IGetInvoiceLinkPayload>) => {
			state.saveLoading = true;
		},
		getInvoiceLinkSuccess: (state, action: PayloadAction<string>) => {
			state.saveLoading = false;
		},
		getInvoiceLinkError: (state) => {
			state.saveLoading = false;
		},
		getInvoiceRequest: (state, action: PayloadAction<IGetInvoiceParams>) => {
			state.invoiceLoading = true;
		},
		getInvoiceSuccess: (state, action: PayloadAction<IGetInvoiceResponse>) => {
			state.invoiceLoading = false;
			state.invoice = action.payload;
		},
		getInvoiceError: (state) => {
			state.invoiceLoading = false;
		},
		getInvoiceStatusRequest: (state, action: PayloadAction<IGetInvoiceStatusPayload>) => {},
		getInvoiceStatusSuccess: (state, { payload }: PayloadAction<IGetInvoiceStatusResponse>) => {
			const { status } = payload;
			state.status = status;
		},
		downloadCSVRequest: (state, action: PayloadAction<IDownloadCSVProviderInvoicesPayload>) => {
			state.downloadCSVLoading = true;
		},
		downloadCSVSuccess: (state, action: PayloadAction<string>) => {
			state.downloadCSVLoading = false;
		},
		downloadCSVError: (state) => {
			state.downloadCSVLoading = false;
		},
	},
});

export default providerInvoices.reducer;
export const {
	getProviderInvoicesRequest,
	getProviderInvoicesSuccess,
	getProviderInvoicesError,
	createInvoiceRequest,
	createInvoiceSuccess,
	createInvoiceError,
	downloadInvoicePDFRequest,
	downloadInvoicePDFSuccess,
	downloadInvoicePDFError,
	sendInvoiceRequest,
	sendInvoiceSuccess,
	sendInvoiceError,
	getInvoiceLinkRequest,
	getInvoiceLinkSuccess,
	getInvoiceLinkError,
	getInvoiceRequest,
	getInvoiceSuccess,
	getInvoiceError,
	getInvoiceStatusRequest,
	getInvoiceStatusSuccess,
	downloadCSVRequest,
	downloadCSVSuccess,
	downloadCSVError,
} = providerInvoices.actions;
