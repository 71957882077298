import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IApiGetProjectDetailsParams,
	IEditProjectBalancePayload,
	IProjectBalanceItem,
	TApiGetProjectBalanceResponse,
} from '../../../services/api/project/types';

import {
	getProjectBalanceMerchantRequest,
	getProjectBalanceRequest,
	getProjectBalanceSuccess,
	updateMerchantProjectBalanceRequest,
	updateProjectBalanceRequest,
	updateProjectBalanceSuccess,
} from './reducer';

function* getProjectBalanceWorker({ payload }: PayloadAction<IApiGetProjectDetailsParams>) {
	try {
		const response: TApiGetProjectBalanceResponse = yield call(
			api.project.getProjectBalanceList,
			payload,
		);
		yield put(getProjectBalanceSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* getProjectBalanceMerchantWorker({ payload }: PayloadAction<IApiGetProjectDetailsParams>) {
	try {
		const response: TApiGetProjectBalanceResponse = yield call(
			api.project.getProjectMerchantBalanceList,
			payload,
		);
		yield put(getProjectBalanceSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* updateProjectBalanceWorker({ payload }: PayloadAction<IEditProjectBalancePayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: IProjectBalanceItem = yield call(
			api.project.editProviderProjectBalance,
			apiParams,
		);
		onSuccess?.();
		yield put(updateProjectBalanceSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* updateMerchantProjectBalanceWorker({
	payload,
}: PayloadAction<IEditProjectBalancePayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const response: IProjectBalanceItem = yield call(
			api.project.editMerchantProjectBalance,
			apiParams,
		);
		onSuccess?.();
		yield put(updateProjectBalanceSuccess(response));
	} catch (error) {
		showError(error);
	}
}

export function* projectBalanceSaga() {
	yield takeEvery(getProjectBalanceRequest, getProjectBalanceWorker);
	yield takeEvery(getProjectBalanceMerchantRequest, getProjectBalanceMerchantWorker);
	yield takeEvery(updateProjectBalanceRequest, updateProjectBalanceWorker);
	yield takeEvery(updateMerchantProjectBalanceRequest, updateMerchantProjectBalanceWorker);
}
