import { FC } from 'react';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { DatepickerField } from 'v2Components/formComponents/DatepickerField';
import { reportsTypeSelected } from './constants';
import { TFilterType } from './types';

const Filters: FC<TFilterType> = ({ dateTo, dateFrom }) => {
	return (
		<>
			<SelectField name="type" options={reportsTypeSelected} title="Report type" />
			<DatepickerField
				label="Date from"
				maxDate={(dateTo as Date) || null}
				name="date_from"
				inFilter
			/>
			<DatepickerField
				label="Date to"
				minDate={(dateFrom as Date) || null}
				name="date_to"
				inFilter
			/>
		</>
	);
};
export default Filters;
