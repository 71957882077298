import { DatepickerField } from 'v2Components/formComponents/DatepickerField';

export default () => {
	return (
		<>
			<DatepickerField label="Date from" name="date_from" inFilter />
			<DatepickerField label="Date to" name="date_to" inFilter />
		</>
	);
};
