import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IDepositCryptoTransactionParams,
	TDepositCryptoTransactions,
} from 'services/api/provider/types';
import { downloadCSVFunc } from 'utils/downloadCSVFunc';
import {
	exportProviderTransactionsCSVError,
	exportProviderTransactionsCSVRequest,
	exportProviderTransactionsCSVSuccess,
	getTransactionsError,
	getTransactionsFiatError,
	getTransactionsFiatRequest,
	getTransactionsFiatSuccess,
	getTransactionsRequest,
	getTransactionsSuccess,
} from './reducer';
import { IExportTransactionsCSVPayload } from './types';

function* getTransactionsRequestWorker({
	payload,
}: PayloadAction<IDepositCryptoTransactionParams>) {
	try {
		const response: TDepositCryptoTransactions = yield call(
			api.provider.getProjectTransactions,
			payload,
		);
		yield put(getTransactionsSuccess(response));
	} catch (error) {
		yield put(getTransactionsError());
		showError(error);
	}
}

function* exportProviderTransactionsCSVWorker({
	payload,
}: PayloadAction<IExportTransactionsCSVPayload>) {
	const { nameFile, apiParams, onSuccess } = payload;
	try {
		const data: string = yield call(api.provider.downloadCryptoTransactionsReport, payload);
		downloadCSVFunc(nameFile, data);
		onSuccess?.();
		yield put(exportProviderTransactionsCSVSuccess());
	} catch (error) {
		yield put(exportProviderTransactionsCSVError());
		showError(error);
	}
}

function* getTransactionsFiatRequestWorker({
	payload,
}: PayloadAction<IDepositCryptoTransactionParams>) {
	try {
		const response: TDepositCryptoTransactions = yield call(
			api.provider.getProviderFiatTransaction,
			payload,
		);
		yield put(getTransactionsFiatSuccess(response));
	} catch (error) {
		yield put(getTransactionsFiatError());
		showError(error);
	}
}

export function* providerTransactionsSaga() {
	yield takeLatest(getTransactionsRequest, getTransactionsRequestWorker);
	yield takeLatest(exportProviderTransactionsCSVRequest, exportProviderTransactionsCSVWorker);
	yield takeLatest(getTransactionsFiatRequest, getTransactionsFiatRequestWorker);
}
