import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProviderCurrencyAllNoSettings } from 'redux/reducers/providerCurrency/selectors';
import { TextField } from 'v2Components/formComponents/TextField';
import { Formik } from 'formik';
import Button from 'v2Components/UI/Button';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { DefaultToken, Token } from 'v2Components/UI/Token';
import FormSideModal from 'v2Components/layoutsComponents/FormSideModal';
import { IEditBankAccountProps, TEditBankAccountFormValues } from '../../types';
import styles from './styles.module.scss';

const BankAccountsFormEdit: FC<IEditBankAccountProps> = ({
	onSubmit,
	item,
	showFilter,
	setShowFilter,
}) => {
	const dispatch = useDispatch();
	const currencyList = useSelector(getProviderCurrencyAllNoSettings);
	const selectedList = useMemo(
		() => currencyList.filter((el) => el.is_crypto === 0),
		[currencyList],
	);
	const initialValues: TEditBankAccountFormValues = useMemo(
		() => ({
			currency_id: item.currency_id,
			currency_name: item.currency_name,
			name_account: item.name_account,
			iban: item.iban,
			reference: item.reference,
			bic: item.bic,
		}),
		[item],
	);

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			{({ resetForm }) => (
				<FormSideModal
					className={styles.accounts__drawer}
					onActionDrawer={resetForm}
					title="Edit bank account"
					show={showFilter}
					setShow={setShowFilter}
					footer={
						<>
							<Button
								size="regular"
								width="100%"
								type="button"
								className={styles.cancel}
								onClick={() => setShowFilter(false)}
							>
								Cancel
							</Button>
							<Button size="regular" width="100%" type="submit">
								Change
							</Button>
						</>
					}
				>
					<TextField name="name_account" label="Bank name" placeholder="Enter name" />
					<SelectField
						title="Currency"
						name="currency_id"
						options={[...selectedList]}
						valueProp="id"
						renderSelected={(selectedValue) =>
							(selectedValue && (
								<Token
									code={
										selectedValue.code === 'All'
											? selectedValue.code
											: (selectedValue.code || '').toUpperCase()
									}
									name={selectedValue.name}
								/>
							)) || <DefaultToken />
						}
						renderOption={({ code, name, img_path }) => (
							<Token code={code === 'All' ? code : (code || '').toUpperCase()} name={name} />
						)}
					/>
					<TextField name="iban" label="IBAN" placeholder="Enter IBAN" />
					<TextField name="bic" label="BIC" placeholder="Enter BIC" />
					<TextField name="reference" label="Reference" placeholder="Enter Reference" />
					<TextField
						label="2FA 6-digit code"
						type="text"
						placeholder="Enter code"
						name="totp"
						maxLength={6}
						onlyNumbers
					/>
				</FormSideModal>
			)}
		</Formik>
	);
};

export default BankAccountsFormEdit;
