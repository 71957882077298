import { FC } from 'react';
import T from 'i18n-react';
import { useSelector } from 'react-redux';
import { getPaymentStatus } from 'redux/reducers/paymentWidget/selectors';
import payment2Icon from 'assets/inline-svg/payment/payment-2.svg';
import { PaymentHistory } from '../PaymentHistory/PaymentHistory';
import { IMakePayment } from './types';
import { BackButton } from '../Buttons/BackButton';

export const PaymentOverpayd: FC<IMakePayment> = ({ payment }) => {
	const paymentStatus = useSelector(getPaymentStatus);

	const { currency_paid } = payment || {};

	return (
		<div className="payment-boxes">
			<div className="payment-conf">
				<span className="payment-title">
					<img src={payment2Icon} alt="icon" />
					{T.translate('Overpaid.title')}
				</span>
				<div className="payment-warng">
					<svg
						className="payment-warng_icon"
						width="34"
						height="29"
						viewBox="0 0 34 29"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M17 4L29.5781 25.6406H4.42188L17 4ZM1.53125 24C0.28125 26.1875 1.92188 29 4.42188 29H29.5781C32.0781 29 33.7188 26.1875 32.4688 24L19.8906 2.28125C18.5625 0.09375 15.4375 0.09375 14.1094 2.28125L1.53125 24ZM15.3594 12.3594V15.6406C15.3594 16.5781 16.0625 17.3594 17 17.3594C17.9375 17.3594 18.6406 16.5781 18.6406 15.6406V12.3594C18.6406 11.4219 17.9375 10.6406 17 10.6406C16.0625 10.6406 15.3594 11.4219 15.3594 12.3594ZM15.3594 20.6406H18.6406V24H15.3594V20.6406Z"
							fill="#A5B0C4"
						/>
					</svg>
					<span className="payment-warng_text">
						{T.translate('Overpaid.text_01')}&lsquo;
						{T.translate('Overpaid.text_02')}
						<strong>
							{paymentStatus?.amount_paid} {currency_paid}
						</strong>
						{T.translate('Overpaid.text_03')}{' '}
						<strong>
							{Math.abs(Number(paymentStatus?.amount_to_pay))} {currency_paid}.
						</strong>{' '}
						{T.translate('Overpaid.text_04')}
					</span>
				</div>
			</div>
			<BackButton />
			<PaymentHistory payment={payment} type="overpay" />
		</div>
	);
};
