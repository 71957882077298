import { IStoreState } from 'redux/types';

export const getProviderInvoicesState = (state: IStoreState) => state.providerInvoices;

export const getProviderInvoices = (state: IStoreState) =>
	getProviderInvoicesState(state).providerInvoices;

export const getProviderInvoicesLoading = (state: IStoreState) =>
	getProviderInvoicesState(state).providerInvoicesLoading;

export const getSaveLoading = (state: IStoreState) => getProviderInvoicesState(state).saveLoading;

export const getInvoiceLoading = (state: IStoreState) =>
	getProviderInvoicesState(state).invoiceLoading;

export const getInvoice = (state: IStoreState) => getProviderInvoicesState(state).invoice;

export const getInvoiceStatus = (state: IStoreState) => getProviderInvoicesState(state).status;
