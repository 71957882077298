import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProviderAllCurrencySettingRequest,
	putProviderAllCurrencySettingRequest,
} from 'redux/reducers/providerCurrency/reducer';
import { notify } from 'utils/notify';
import {
	getProviderCurrencyAll,
	getProviderCurrencyAllLoading,
} from 'redux/reducers/providerCurrency/selectors';
import Table from 'v2Components/commomComponents/Table';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { TApiGetProviderAllCurrencySettingResponse } from 'redux/reducers/providerCurrency/types';
import { TableRow } from './TableBody';
import { IInSubmitProps, IEditSubmit } from './types';

const Currencies = () => {
	const dispatch = useDispatch();
	const items = useSelector(getProviderCurrencyAll);
	const loading = useSelector(getProviderCurrencyAllLoading);
	const [activeRow, setActiveRow] = useState<number | null>(null);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('Currencies');
	const onSubmit = (body: IInSubmitProps) =>
		dispatch(
			putProviderAllCurrencySettingRequest({
				apiParams: {
					currency_setting_id: body.currency_setting_id,
					[body.field]: Number(body.active),
				},
				onSuccess: () => {
					notify('CurrencySelected edit success', 'success');
				},
			}),
		);

	const onEditSubmit = (body: IEditSubmit) => {
		dispatch(
			putProviderAllCurrencySettingRequest({
				apiParams: body,
				onSuccess: () => {
					notify('CurrencySelected edit success', 'success');
					setActiveRow(null);
				},
			}),
		);
	};

	useEffect(() => {
		const payload: any = {
			onSuccess: () => {
				return null;
			},
		};
		dispatch(getProviderAllCurrencySettingRequest(payload));
	}, [dispatch]);

	return (
		<Table
			loading={loading}
			headerItems={tableHeaderItems}
			bodyItems={items}
			gridTemplateColumns={gridTemplateColumns}
			getRow={(item: TApiGetProviderAllCurrencySettingResponse) =>
				TableRow({
					item,
					onSubmit,
					active: activeRow === item.id,
					setActive: setActiveRow,
					onEditSubmit,
				})
			}
		/>
	);
};

export default Currencies;
