import { IStoreState } from 'redux/types';
import { createSelector } from '@reduxjs/toolkit';

export const getAuthState = (state: IStoreState) => state.auth;

export const getToken = (state: IStoreState) => getAuthState(state).token;

export const getIsAuth = (state: IStoreState) => !!getToken(state);
export const getIsNotAuth = (state: IStoreState) => !getToken(state);

export const getUserData = (state: IStoreState) => getAuthState(state).userData;
export const getUserId = (state: IStoreState) => getAuthState(state).userData?.id;
export const getUserType = (state: IStoreState) => getAuthState(state).userData?.user_type || '';
export const getUserRole = (state: IStoreState) => getAuthState(state).userData?.role?.name;
export const getAuthAll = createSelector([getAuthState], (): boolean => true);
export const getFirstLoginData = (state: IStoreState) => getAuthState(state).firstLogin;
