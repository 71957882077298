import { useState } from 'react';
import { useParams } from 'react-router';
import { MobileProviderInfo } from 'components/Provider/MobileProviderInfo/MobileProviderInfo';
import { Tabs } from 'v2Pages/Provider/ReportsAnalyticsPage/Tabs/Tabs';
import { routes } from 'routes/routes';
import { ProviderAnalytics } from 'v2Pages/Provider/ReportsAnalyticsPage/ProviderAnalytics/ProviderAnalytics';
import { PlusIcon } from 'assets/inline-svg';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import {
	createReportRequset,
	getReportsUpdateRequest,
} from 'redux/reducers/providerReportsAnalytics/reducer';
import { notify } from 'utils/notify';
import { useDispatch, useSelector } from 'react-redux';
import { getFilter } from 'redux/reducers/app/selectors';
import Main from 'v2Components/layoutsComponents/Main';
import { reportsType } from '../AnalyticsPage/TabsContent/Reports/Filters/constants';
import { PopupReport } from '../AnalyticsPage/TabsContent/Reports/PopupReport/PopupReport';
import { Reports } from '../AnalyticsPage/TabsContent/Reports/Reports';
import styles from './styles.module.scss';

export const ReportsAnalyticsPage = () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const url_string = window.location.href;
	const urlObg = new URL(url_string);
	const tabsUrl = urlObg.searchParams.get('tabs');
	const { tab } = useParams<{ tab: string }>();
	const [currency, setCurrency] = useState(tabsUrl || 'crypto');
	const [reportOpen, setReportOpen] = useState(false);
	const [activeRole, setActiveRole] = useState({ id: 0, name: 'Balance', code: 'balance' });

	const closeModal = () => setReportOpen(false);
	const createReport = () => {
		dispatch(
			createReportRequset({
				dataPayload: {
					type: activeRole.code,
					date_from: (filter.date_from as Date) || undefined,
					date_to: (filter.date_to as Date) || undefined,
				},
				onSuccess: () => {
					closeModal();
					notify('Report created', 'success');
					dispatch(
						getReportsUpdateRequest({
							params: { current_page: 1, per_page: 15 },
						}),
					);
				},
				onFinally: () => closeModal(),
			}),
		);
	};

	return (
		<Main
			header={
				<>
					<MobileProviderInfo />
					<div className={styles.head}>
						<button type="button" onClick={() => setReportOpen(true)} className="button">
							<PlusIcon />
							Create report
						</button>
						<PopupModal open={reportOpen} onClose={closeModal}>
							<PopupReport
								role={reportsType}
								onSubmit={createReport}
								onClose={closeModal}
								activeRole={activeRole}
								setActiveRole={setActiveRole}
							/>
						</PopupModal>
					</div>
				</>
			}
		>
			<Tabs currency={currency} />
			{tab === routes.analytics.slice(1) ? <ProviderAnalytics /> : <Reports />}
		</Main>
	);
};
