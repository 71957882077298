import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IProjectBalanceResponse } from 'redux/reducers/merchantAccounts/types';
import Button from 'v2Components/UI/Button';
import {
	getProjectBalanceList,
	getWithdrawlFeeSelector,
	getWithdrawlLimitsSelector,
} from 'redux/reducers/merchantAccounts/selectors';
import { getProjectDetails } from 'redux/reducers/projectSettings/selectors';
import {
	clearWithdrawalFee,
	getWithdrawlFeeRequest,
	getWithdrawlLimitsRequest,
} from 'redux/reducers/merchantAccounts/reducer';
import Field from 'v2Components/UI/Field';
import { isExist } from 'utils/numbers';
import { trimLastZeros } from 'utils/strings';
import { useViewDecimals } from 'hooks/useViewDecimals';
import CurrencySelect from '../../components/CurrencySelect';
import CurrencyAmount from '../../components/CurrencyAmount';
import styles from './styles.module.scss';
import { WithdrawalCryptoInfo } from '../../../PopUps/PopUpWithdrawal/PopUpWithdrawalCrypto/WithdrawalCryptoInfo/WithdrawalCryptoInfo';
import { WithdrawalConfirm } from '../../../PopUps/PopUpWithdrawal/PopUpWithdrawalCrypto/WithdrawalConfirm/WithdrawalConfirm';

interface IWithdrawalFormProps {
	defaultFromId: number;
	closePopUp: () => void;
	open: boolean;
}

const WithdrawalForm: FC<IWithdrawalFormProps> = ({ defaultFromId, closePopUp, open }) => {
	const dispatch = useDispatch();
	const fee = useSelector(getWithdrawlFeeSelector);
	const project = useSelector(getProjectDetails);
	const [amount, setAmount] = useState(0);
	const [address, setAddress] = useState('');
	const [openConfirm, setOpenConfirm] = useState<boolean>(false);
	const [isAutoExchangeOpen, setIsAutoExchangeOpen] = useState(false);
	const accounts = useSelector(getProjectBalanceList);
	const filteredCurrenciesFrom = useMemo(
		() =>
			accounts?.filter(
				(account) => account.is_crypto === 1 && account.is_active && account.is_exchangeable,
			) || [],
		[accounts],
	);
	const [currencyFrom, setCurrencyFrom] = useState<IProjectBalanceResponse | null>(null);
	const limits = useSelector(getWithdrawlLimitsSelector);

	const { getViewDecimal } = useViewDecimals();

	const viewDecimals = useMemo(
		() => (currencyFrom?.currency.code ? getViewDecimal(currencyFrom?.currency.code) : undefined),
		[getViewDecimal, currencyFrom?.currency.code],
	);
	const getRate = (value?: number) => value && setAmount(value);

	const numberFormat = (value?: number | null) =>
		isExist(value) ? trimLastZeros(Number(value).toFixed(viewDecimals)) : 0;

	const currencyCode = useMemo(
		() => currencyFrom?.currency.code.toUpperCase(),
		[currencyFrom?.currency.code],
	);

	useEffect(() => {
		currencyFrom?.id &&
			dispatch(getWithdrawlLimitsRequest({ project_balance_id: currencyFrom.id }));
	}, [currencyFrom?.id, dispatch]);

	useEffect(() => {
		currencyFrom?.id &&
			+amount &&
			address &&
			dispatch(
				getWithdrawlFeeRequest({
					project_balance_id: +currencyFrom.id,
					amount,
					address,
				}),
			);
	}, [address, amount, dispatch, currencyFrom?.id]);

	useEffect(() => {
		if (!open) {
			setOpenConfirm(false);
			setCurrencyFrom(null);
			setAmount(0);
			setAddress('');
			dispatch(clearWithdrawalFee());
		} else {
			const currency = filteredCurrenciesFrom.find((coin) => coin.id === defaultFromId);
			setCurrencyFrom(currency || null);
		}
	}, [defaultFromId, dispatch, filteredCurrenciesFrom, open]);

	return openConfirm && limits ? (
		<WithdrawalConfirm
			setOpenConfirm={setOpenConfirm}
			onPopUps={closePopUp}
			limits={limits}
			amount={String(amount)}
			isSelect={currencyFrom}
			address={address}
			fee={fee?.platform_fee as number}
		/>
	) : (
		<div className={styles.content}>
			<div className={styles.head}>
				{project?.file_icon && (
					<span className={styles.logo}>
						<img src={project.file_icon} alt="" />
					</span>
				)}
				<div>
					<p className={styles.title}>Withdrawal</p>
					<p className={styles.subtitle}>{project?.name}</p>
				</div>
			</div>
			<div className={styles.body}>
				<div>
					<CurrencySelect
						label="You Send"
						options={filteredCurrenciesFrom}
						value={currencyFrom}
						setValue={setCurrencyFrom}
					/>
					<Field
						label="Address"
						type="text"
						value={address}
						onChange={(e) => setAddress(e.target.value)}
					/>
					<CurrencyAmount
						getRate={getRate}
						value={amount}
						network={currencyFrom?.currency_network}
						code={currencyFrom?.currency?.code}
						max={currencyFrom?.balance}
					/>
				</div>
				{limits && (
					<WithdrawalCryptoInfo isSelect={currencyFrom} limits={limits} amount={String(amount)} />
				)}
			</div>
			<div className={styles.info}>
				<div className={styles.info__item}>
					Withdrawal limit Min:
					<span className={styles.info__value}>0.001 {currencyCode}</span>
				</div>
				<div className={styles.info__item}>
					Platform Fee:
					<span className={styles.info__value}>
						{numberFormat(fee?.platform_fee)} {currencyCode}
					</span>
				</div>
				<div className={styles.info__item}>
					Will be Sent:
					<span className={styles.info__value}>
						{numberFormat(amount)} {currencyCode}
					</span>
				</div>
				<div className={styles.info__item}>
					Network Fee:
					<span className={styles.info__value}>
						{numberFormat(fee?.network_fee)} {currencyCode}
					</span>
				</div>
				<div className={styles.info__item}>
					Will be Received:
					<span className={styles.info__value}>
						{numberFormat(amount - (fee?.platform_fee || 0))} {currencyCode}
					</span>
				</div>
			</div>
			<div className={styles.buttons}>
				<Button onClick={closePopUp} type="button" className={styles.button} light>
					Cancel
				</Button>
				<Button
					disabled={!address || !amount}
					onClick={() => setOpenConfirm(true)}
					type="submit"
					className={styles.button}
				>
					Withdrawal
				</Button>
			</div>
		</div>
	);
};

export default WithdrawalForm;
