import { Link } from 'react-router-dom';
import { capitalize, trimBottomLine, trimLastZeros } from 'utils/strings';
import { CopyAddress } from 'v2Components/commomComponents/Table/CellComponents';
import StatusText from 'v2Components/layoutsComponents/StatusText';
import DateFormat from 'v2Components/commomComponents/DateFormat';
import LinkDetails from 'v2Components/commomComponents/Table/Td/LinkDetails';
import { ICryptoTableRow } from './type';
import styles from './styles.module.scss';

export const cryptoTableRow = ({ historyCryptoItem, getViewDecimal }: ICryptoTableRow) => {
	const {
		provider_id,
		id,
		fee_network,
		created_at,
		transaction_type,
		currency,
		currency_network,
		amount,
		address_to,
		address_from,
		hash_transaction,
		status,
	} = historyCryptoItem;

	const viewDecimals =
		(!!currency?.code && getViewDecimal(currency?.code, currency_network)) || undefined;

	const detailsUrl = `/wallets/transaction-history/${String(id)}/crypto/${String(
		transaction_type || '',
	)}`;

	const currencyCol = (
		<div className={styles.col}>
			<div className={styles.col__major}> {currency?.code?.toUpperCase()}</div>
			<div className={styles.col__minor}>({currency_network?.toUpperCase()})</div>
		</div>
	);

	return [
		{
			title: id.toString() || 'ID',
			children: <Link to={detailsUrl}>{id}</Link>,
		},
		{
			title: 'UserID',
			children: (
				<Link to={`/administration/team-members/${String(provider_id)}`}>{provider_id}</Link>
			),
		},
		{
			title: 'Date',
			children: <DateFormat created_at={created_at} />,
		},
		{
			title: `Currency`,
			children: currencyCol,
		},
		{
			title: 'Amount',
			children: trimLastZeros(Number(amount).toFixed(viewDecimals)),
		},
		{
			title: 'Fee',
			children: trimLastZeros(Number(fee_network).toFixed(viewDecimals)),
		},
		{
			title: 'Type',
			children: transaction_type && capitalize(trimBottomLine(transaction_type)),
		},
		{
			title: 'Address to',
			children: <CopyAddress value={address_to} widthTooltip="auto" />,
		},
		{
			title: 'Hash',
			children: hash_transaction ? (
				<CopyAddress value={hash_transaction} widthTooltip="auto" />
			) : (
				''
			),
		},
		{
			title: 'Address from',
			children: <CopyAddress value={address_from} />,
		},
		{
			title: 'Status',
			children: StatusText(status || '-'),
		},
		{
			title: 'Details',
			children: <LinkDetails detailsUrl={detailsUrl} />,
		},
	];
};
