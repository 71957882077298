import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import { IProviderWalletAddressesResponse } from './types';

import {
	getProviderWalletAddressesRequest,
	getProviderWalletAddressesSuccess,
	getProjectWalletAddressesSuccessClient,
	getProjectWalletAddressesClientRequest,
} from './reducer';

function* getProviderWalletAddressesWorker({ payload }: PayloadAction<any>) {
	const { apiParams, onSuccess, onFinally } = payload;
	try {
		const data: IProviderWalletAddressesResponse = yield call(
			api.project.getProjectWalletAddresses,
			apiParams,
		);

		yield put(getProviderWalletAddressesSuccess(data));
	} catch (error) {
		showError(error);
	} finally {
		onFinally?.();
	}
}
function* getProjectWalletAddressesClientWorker({ payload }: PayloadAction<any>) {
	const { apiParams, onSuccess, onFinally } = payload;
	try {
		const data: IProviderWalletAddressesResponse = yield call(
			api.project.getProjectWalletAddressesByClient,
			apiParams,
		);

		yield put(getProjectWalletAddressesSuccessClient(data));
	} catch (error) {
		showError(error);
	} finally {
		onFinally?.();
	}
}

export function* projectWalletAddressesSaga() {
	yield takeLatest(getProviderWalletAddressesRequest, getProviderWalletAddressesWorker);
	yield takeLatest(getProjectWalletAddressesClientRequest, getProjectWalletAddressesClientWorker);
}
