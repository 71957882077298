import { ChangeEvent, FC, FocusEvent, useCallback } from 'react';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { ITextFieldProps } from './types';

const ErrorMessage: FC<{ errorMsg: string | undefined }> = ({ errorMsg }) => (
	<p className="input-notification input-notification--error">{errorMsg}</p>
);

export const TextField: FC<ITextFieldProps> = ({
	name,
	label,
	placeholder,
	type,
	inputProps = {},
	subDescription,
	classNameCustom,
	classNameInputItem,
	readOnly,
	customErrorMsg,
	rightAdornment,
	maxLength,
	onlyNumbers,
}) => {
	const [field, meta] = useField(name);
	const { submitCount } = useFormikContext();
	const isShowError = (meta.touched || submitCount > 0) && meta.error;

	const renderErrorMsg = () => {
		if (customErrorMsg) {
			return <ErrorMessage errorMsg={customErrorMsg} />;
		}
		return isShowError && <ErrorMessage errorMsg={meta.error} />;
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value: prevValue, onChange } = field;
		const { value } = e.target;
		if (
			(!!maxLength || maxLength === 0) &&
			prevValue?.length === maxLength &&
			value.length >= maxLength
		) {
			return;
		}
		if (onlyNumbers && value && !/^\d+(\.+\d*)?$/.test(value)) {
			return;
		}
		onChange(e);
	};
	const disableScroll = useCallback((e: WheelEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);
	const handleInputFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
		if (type === 'number') e.target.addEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInputBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
		if (type === 'number') e.target.removeEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={classNames(
				'input',
				{ 'input--error': customErrorMsg || isShowError },
				classNameCustom,
			)}
		>
			{label && <p className="input-name">{label}</p>}

			<div className="input-wrapper">
				<input
					{...field}
					className={classNames('input-item', classNameInputItem)}
					placeholder={placeholder}
					type={type}
					onFocus={handleInputFocus}
					onBlur={handleInputBlur}
					readOnly={readOnly}
					onChange={handleChange}
					{...inputProps}
				/>
				{!!rightAdornment && <div className="input-text">{rightAdornment}</div>}
			</div>
			{subDescription ? (
				<div className="input-description">
					<p>{subDescription}</p>
				</div>
			) : (
				''
			)}

			{renderErrorMsg()}
		</div>
	);
};
