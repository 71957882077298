import { ChangeEvent, FC, FocusEvent, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import Field from 'v2Components/UI/Field';
import { ITextFieldProps } from './types';

export const TextField: FC<ITextFieldProps> = ({
	name,
	type,
	inputProps = {},
	classNameCustom,
	customErrorMsg,
	maxLength,
	onlyNumbers,
	widthInput,
	...rest
}) => {
	const [field, meta] = useField(name);
	const { submitCount } = useFormikContext();
	const isShowError = (meta.touched || submitCount > 0) && meta.error;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value: prevValue, onChange } = field;
		const { value } = e.target;
		if (
			(!!maxLength || maxLength === 0) &&
			prevValue?.length === maxLength &&
			value.length >= maxLength
		) {
			return;
		}
		if (onlyNumbers && value && !/^\d+(\.+\d*)?$/.test(value)) {
			return;
		}
		onChange(e);
	};
	const disableScroll = useCallback((e: WheelEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);
	const handleInputFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
		if (type === 'number') e.target.addEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInputBlur = useCallback((e: FocusEvent<HTMLInputElement>) => {
		if (type === 'number') e.target.removeEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Field
			{...field}
			type={type}
			value={field.value != null ? field.value : ''}
			onFocus={handleInputFocus}
			onBlur={handleInputBlur}
			onChange={handleChange}
			error={meta.error}
			isShowError={!!isShowError}
			{...rest}
			{...inputProps}
		/>
	);
};
