import { FC } from 'react';
import { TriangleArrowDownIcon2 } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { dataTimes } from 'services/constants/analytics';

interface IProps {
	time: string;
	handleSelects: (value: string, key?: string) => void;
}

export const SelectAnalytics: FC<IProps> = ({ time, handleSelects }) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (value: string) => {
		handleSelects(value);
		setOpen(false);
	};

	return (
		<div className="select-block">
			<div className={`select select--type-choice ${open ? 'active' : ''}`}>
				<button ref={triggerRef} onClick={toggleOpen} type="button" className="select__current">
					{time}
					<span className="select__current-arrow">
						<TriangleArrowDownIcon2 />
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll select__drop-scroll--type2">
						<div className="select__drop-item">
							<ul>
								{dataTimes.map((item) => (
									<li key={item}>
										<button
											className={time === item ? 'active' : ''}
											onClick={() => handleSelect(item)}
											type="button"
										>
											<span>{item}</span>
											<span>
												<svg
													width="14"
													height="11"
													viewBox="0 0 14 11"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M5 10.42L0 5.42L1.41 4.01L5 7.59L12.59 0L14 1.42L5 10.42Z"
														fill="#9996BE"
													/>
												</svg>
											</span>
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
