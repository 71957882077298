import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IComission,
	IComissionRequest,
	IGetComissionsParams,
	TComissions,
} from 'services/api/project/types';
import {
	getProjectCommissionsRequest,
	getProjectCommissionsSuccess,
	getProjectCommissionsError,
	getProjectCommissionsMerchantRequest,
	editProjectCommissionsMerchantRequest,
	editProjectCommissionsSuccess,
} from './reducer';

function* getProjectCommissionsRequestWorker(action: PayloadAction<IGetComissionsParams>) {
	const { payload } = action;

	try {
		const data: TComissions = yield call(api.project.getComissions, payload);
		yield put(getProjectCommissionsSuccess(data));
	} catch (error) {
		yield put(getProjectCommissionsError());
		showError(error);
	}
}

function* getProjectCommissionsMerchantRequestWorker(action: PayloadAction<IGetComissionsParams>) {
	const { payload } = action;

	try {
		const data: TComissions = yield call(api.project.getComissionsMerchant, payload);
		yield put(getProjectCommissionsSuccess(data));
	} catch (error) {
		yield put(getProjectCommissionsError());
		showError(error);
	}
}

function* putProjectCommissionsMerchantRequestWorker(action: PayloadAction<IComissionRequest>) {
	const { params, onSuccess } = action.payload;

	try {
		const data: IComission = yield call(api.project.putComissionsMerchant, params);
		yield put(editProjectCommissionsSuccess(data));
		yield onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

export function* projectComissionsSaga() {
	yield takeLatest(getProjectCommissionsRequest, getProjectCommissionsRequestWorker);
	yield takeLatest(
		getProjectCommissionsMerchantRequest,
		getProjectCommissionsMerchantRequestWorker,
	);
	yield takeLatest(
		editProjectCommissionsMerchantRequest,
		putProjectCommissionsMerchantRequestWorker,
	);
}
