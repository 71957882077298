import { FC } from 'react';
import { IRadioItem } from './types';

export const RadioItem: FC<IRadioItem> = ({ name, value, label, checked, onChange }) => {
	return (
		<div className="radio">
			<label className="radio__label">
				<input
					type="radio"
					className="hidden"
					name={name}
					checked={checked}
					value={value}
					onChange={(e) => onChange?.(e.target.value)}
				/>
				<span className="radio__item" />
				<span className="radio__text">{label}</span>
			</label>
		</div>
	);
};
