import { Link } from 'react-router-dom';
import { capitalize, trimBottomLine, trimLastZeros } from 'utils/strings';
import StatusText from 'v2Components/layoutsComponents/StatusText';
import DateFormat from 'v2Components/commomComponents/DateFormat';
import LinkDetails from 'v2Components/commomComponents/Table/Td/LinkDetails';
import { ICryptoTableRow } from './type';

export const fiatTableRow = ({ historyFiatItem, getViewDecimal }: ICryptoTableRow) => {
	const {
		provider_id,
		id,
		created_at,
		transaction_type,
		currency,
		currency_network,
		amount,
		currency_code,
		status,
	} = historyFiatItem;

	const viewDecimals =
		(!!currency?.code && getViewDecimal(currency?.code, currency_network)) || undefined;

	const detailsUrl = `/wallets/transaction-history/${String(id)}/fiat/${String(
		transaction_type || '',
	)}?tabs=fiat`;

	return [
		{
			title: id.toString() || 'ID',
			children: <Link to={detailsUrl}>{id}</Link>,
		},
		{
			title: 'UserID',
			children: (
				<Link to={`/administration/team-members/${String(provider_id)}`}>{provider_id}</Link>
			),
		},
		{
			title: 'Date',
			children: <DateFormat created_at={created_at} />,
		},
		{
			title: 'Type',
			children: transaction_type && capitalize(trimBottomLine(transaction_type)),
		},
		{
			title: 'Currency',
			children: currency_code?.toUpperCase(),
		},
		{
			title: 'Amount',
			children: trimLastZeros(Number(amount).toFixed(viewDecimals)),
		},
		{
			title: 'Status',
			children: StatusText(status || '-'),
		},
		{
			title: 'Details',
			children: <LinkDetails detailsUrl={detailsUrl} />,
		},
	];
};
