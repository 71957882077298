type TTag =
	| 'TransactionsCrypto'
	| 'TransactionsFiat'
	| 'MerchantManagement'
	| 'ProjectApprovements'
	| 'Limitation'
	| 'Orders'
	| 'WalletAddress'
	| 'PendingWithdrawalCrypto'
	| 'PendingWithdrawalFiat'
	| 'ApprovementsMerchant'
	| 'ApprovementsProvider'
	| 'Invoices'
	| 'Reports'
	| 'TeamMembers'
	| 'MerchantWallet'
	| 'MerchantTeamMembers'
	| 'ProjectTransactionsCrypto'
	| 'ProjectTransactionsFiat'
	| 'AccountsAccountsCrypto'
	| 'AccountsAccountsFiat'
	| 'AccountsCurrencySettings'
	| 'AccountsCurrencySettingsMerchant'
	| 'ProjectClients'
	| 'ProjectCallbacks'
	| 'ProjectWalletAddress'
	| 'ProjectOrders'
	| 'WalletsBalancesCrypto'
	| 'WalletsBalancesFiat'
	| 'TransactionsHistoryCrypto'
	| 'TransactionsHistoryFiat'
	| 'PaymentTransactions'
	| 'Profit'
	| 'TRXManagement'
	| 'TRXManagementDelegation'
	| 'Liquidity'
	| 'Currencies';

const tableHeaderItems = {
	TransactionsCrypto: [
		'№',
		'Tx ID',
		'User ID',
		'Merchant ID',
		'Project',
		'Date&Time',
		'Currency',
		'Type',
		'Amount',
		'Status',
		'TX Hash',
		'Details',
	],
	TransactionsFiat: [
		'№',
		'Tx ID',
		'User ID',
		'Merchant ID',
		'Project',
		'Date&Time',
		'Type',
		'Currency',
		'Amount',
		'Status',
		'Details',
	],
	MerchantManagement: [
		'ID',
		'ID Mecrchant',
		'Email',
		'User Name',
		'Phone number',
		'Last login',
		'Block/Unblock',
		'Action',
	],
	ProjectApprovements: [
		'Merchant ID',
		'Project ID',
		'Project Name',
		'Created date',
		'Current status',
		'Action',
	],
	Currencies: ['Currency', 'Deposit', 'Withdrawal', 'Exchange', 'View decimals', 'Action'],
	Limitation: [
		'Currency',
		'Withdrawal, Once',
		'Withdrawal, Daily',
		'Withdrawal, Monthly',
		'Deposit, MIN',
		'Deposit, MAX',
		'Withdrawal, MIN',
		'Free Withdraw',
		'Action',
	],
	Orders: [
		'ID',
		'Date',
		'Merchant ID',
		'Amount',
		'Currency',
		'Amount paid',
		'Currency paid',
		'Invoice ID',
		'Status',
		'Issue',
		'Action',
	],
	WalletAddress: [
		'Address ID',
		'Project ID',
		'Client ID',
		'Address number',
		'Currency',
		'Date&Time',
		'Address type',
		'Address balance',
	],
	PendingWithdrawalCrypto: [
		'ID',
		'User ID',
		'Merchant ID',
		'Project',
		'Balance',
		'Currency',
		'Networks',
		'Value',
		// 'Fee',
		'Created',
		'Message',
		'Action',
	],
	PendingWithdrawalFiat: [
		'ID',
		'User ID',
		'Merchant ID',
		'Project',
		'Balance',
		'Currency',
		'Networks',
		'Value',
		'Fee',
		'Created',
		'Message',
		'Action',
	],
	ApprovementsMerchant: [
		'№',
		'Tx ID',
		'User ID',
		'Merchant ID',
		'Project',
		'Date&Time',
		'Currency',
		'Type',
		'Amount',
		'Status',
		'TX Hash',
		'Details',
	],
	ApprovementsProvider: [
		'Created',
		'Status',
		'Status change',
		'Status changer',
		'TX Type',
		'Tx ID',
		'Risk Score',
		'Currency',
		'Amount',
		'Action',
	],
	Invoices: [
		'ID',
		'Created',
		'Order ID ',
		'User ID',
		'Price',
		'Currency',
		'Amount to pay',
		'Currency paid',
		'Status',
		'Merchant ID',
		'Client email',
		'Issue',
		'Action',
	],
	Reports: ['Type', 'Created Date', 'Time interval From', 'Time interval To', 'Action'],
	TeamMembers: [
		'ID',
		'User Name',
		'Email',
		'Last login',
		'Created',
		'Role',
		'Block/Unblock',
		'Action',
	],
	MerchantWallet: ['Currency', 'Network', 'Nickname', 'Address', 'Action'],
	MerchantTeamMembers: ['ID', 'Email', 'Full Name', 'Project', 'Role', 'Last entry', 'Action'],
	ProjectTransactionsCrypto: [
		'№',
		'Tx ID',
		'User ID',
		'Date&Time',
		'Type',
		'Currency',
		'Amount',
		'Status',
		'TX Hash',
		'Details',
	],
	ProjectTransactionsFiat: [
		'№',
		'Tx ID',
		'User ID',
		'Merchant ID',
		'Project',
		'Date&Time',
		'Type',
		'Currency',
		'Amount',
		'Status',
		'Details',
	],
	AccountsAccountsCrypto: [
		'Currency',
		'Address',
		'Balance',
		'Balance limit',
		'Auto Exchange',
		'Reserve wallet',
		'Action',
	],
	ProjectClients: [
		'Client ID',
		'Internal ID',
		'Email',
		'User Name',
		'Phone number',
		'Country',
		'Level of Trust',
		'Block/Unblock',
		'Action',
	],
	AccountsAccountsFiat: ['Currency', 'Reference code', 'Balance', 'Auto Exchange', 'Action'],
	ProjectCallbacks: [
		'ID',
		'Tx ID',
		'Event',
		'Type',
		'Received status',
		'Custom ID',
		'Risk score',
		'Responce code',
		'Action',
	],
	AccountsCurrencySettings: [
		'Currency',
		{
			title: 'Withdrawal, Once',
			text: 'Withdrawal, Once - The maximum amount in the respective currency, that can be sent to any external address in one installment.',
		},
		{
			title: 'Withdrawal, Daily',
			text: 'Withdrawal, daily - The maximum amount in the respective currency, that can be sent to any external address during any 24-hours period.',
		},
		{
			title: 'Withdrawal, Monthly',
			text: 'Withdrawal, monthly - The maximum amount in the respective currency, that can be sent to any external address since 00:00, N-th day, of any month till 23:59, "N minus 1"-th day of the next month.',
		},
		{
			title: 'Deposit, MIN',
			text: 'Deposit, min - the minimum amount in the respective currency, that could be credited to your account. Please, note, that all deposits below this value will be blocked and not added to your balance.',
		},
		{
			title: 'Deposit, MAX',
			text: 'Deposit, max - the maximum amount in the respective currency, that could be credited to your account in one installment. Please, note, that all deposits above this value might be blocked and/or sent back to the deposit sender.',
		},
		{
			title: 'Withdrawal, MIN',
			text: 'Withdrawal, min - The minimum amount in the respective currency, that can be sent to any external address from your account. All requests below the value will be rejected.',
		},
		'Activate',
		'Action',
	],
	AccountsCurrencySettingsMerchant: [
		'Currency',
		{
			title: 'Withdrawal, Once',
			text: 'Withdrawal, Once - The maximum amount in the respective currency, that can be sent to any external address in one installment.',
		},
		{
			title: 'Withdrawal, Daily',
			text: 'Withdrawal, daily - The maximum amount in the respective currency, that can be sent to any external address during any 24-hours period.',
		},
		{
			title: 'Withdrawal, Monthly',
			text: 'Withdrawal, monthly - The maximum amount in the respective currency, that can be sent to any external address since 00:00, N-th day, of any month till 23:59, "N minus 1"-th day of the next month.',
		},
		{
			title: 'Deposit, MIN',
			text: 'Deposit, min - the minimum amount in the respective currency, that could be credited to your account. Please, note, that all deposits below this value will be blocked and not added to your balance.',
		},
		{
			title: 'Deposit, MAX',
			text: 'Deposit, max - the maximum amount in the respective currency, that could be credited to your account in one installment. Please, note, that all deposits above this value might be blocked and/or sent back to the deposit sender.',
		},
		{
			title: 'Withdrawal, MIN',
			text: 'Withdrawal, min - The minimum amount in the respective currency, that can be sent to any external address from your account. All requests below the value will be rejected.',
		},
		'Activate',
	],
	ProjectWalletAddress: ['ID', 'Client ID', 'Address', 'Currency', 'Network', 'Type', 'Balance'],
	ProjectOrders: [
		'ID',
		'Date',
		'Client ID',
		'Оrder №',
		'Amount',
		'Currency',
		'Amount paid',
		'Currency paid',
		'Invoice ID',
		'Status',
		'Issue',
		'Action',
	],
	WalletsBalancesCrypto: [
		'Currency',
		'Address',
		'Balance',
		'Merchant balance',
		'Get alert at',
		'Reserve Wallet',
		'Action',
	],
	WalletsBalancesFiat: ['Currency', 'Balance', 'Merchant balance', 'Action'],
	TransactionsHistoryCrypto: [
		'ID',
		'UserID',
		'Date',
		'Currency',
		'Amount',
		'Fee',
		'Type',
		'Address to',
		'Hash',
		'Address from',
		'Status',
		'Details',
	],
	TransactionsHistoryFiat: [
		'ID',
		'UserID',
		'Date',
		'Type',
		'Currency',
		'Amount',
		'Status',
		'Details',
	],
	PaymentTransactions: [
		'TX ID',
		'Invoice ID',
		'Merchant ID',
		'Date&Time',
		'Type',
		'Currency',
		'Amount',
		'TX Hash',
		'Details',
	],
	Profit: [
		'Currency',
		'Payment',
		'Deposit',
		'Withdraw',
		'Exchange',
		'Auto - exchange',
		'Invoice',
		'Auto - withdrawal',
		'Internal transfer',
		'Total Amount',
	],
	TRXManagement: [
		'Resource',
		'Address',
		'Balance Before/After',
		'Created',
		'TxID',
		'Amount',
		'Type',
		'Meta',
	],
	TRXManagementDelegation: [
		'Resource',
		'Address',
		'Receiver',
		'Created',
		'TxID',
		'Amount',
		'Type',
		'Action',
	],
	Liquidity: ['Currency', 'Address', 'Balance', 'Amount, MIN', 'Action'],
};

const gridTemplateColumns = {
	TransactionsCrypto: [
		'50px',
		'minmax(100px, 0.55fr)',
		'minmax(100px, 0.55fr)',
		'minmax(150px, 0.9fr)',
		'minmax(150px, 0.9fr)',
		'minmax(180px, 1fr)',
		'minmax(150px, 1fr)',
		'minmax(120px, 0.6fr)',
		'minmax(130px, 0.65fr)',
		'minmax(150px, 0.9fr)',
		'minmax(160px, 0.6fr)',
		'50px',
	],
	TransactionsFiat: [
		'50px',
		'minmax(100px, 0.55fr)',
		'minmax(100px, 0.55fr)',
		'minmax(150px, 1fr)',
		'minmax(120px, 0.8fr)',
		'minmax(150px, 1fr)',
		'minmax(150px, 1fr)',
		'minmax(100px, 0.6fr)',
		'minmax(100px, 0.65fr)',
		'minmax(120px, 0.8fr)',
		'50px',
	],
	MerchantManagement: ['0.35fr', '1.6fr', '2.4fr', '1.45fr', '1.65fr', '1.65fr', '1.05fr', '0.6fr'],
	ProjectApprovements: ['1.85fr', '1.65fr', '2.55fr', '2.2fr', '1.6fr', '1.08fr'],
	Currencies: ['1.3fr', '1fr', '1fr', '1fr', '0.8fr', '70px'],
	Limitation: ['1.5fr', '1.5fr', '1.5fr', '1.75fr', '1.2fr', '1.2fr', '1.25fr', '0.85fr', '1fr'],
	Orders: [
		'0.2fr',
		'1.38fr',
		'0.57fr',
		'0.75fr',
		'0.62fr',
		'0.86fr',
		'0.95fr',
		'0.66fr',
		'0.7fr',
		'0.7fr',
		'minmax(60px, 0.6fr)',
	],
	WalletAddress: ['1fr', '1fr', '1fr', 'minmax(100px, 2fr)', '1.1fr', '2.1fr', '1.3fr', '1.3fr'],
	PendingWithdrawalCrypto: [
		'53px',
		'0.55fr',
		'0.85fr',
		'1.3fr',
		'0.66fr',
		'0.71fr',
		'0.76fr',
		'0.76fr',
		// '0.61fr',
		'0.87fr',
		'1.2fr',
		'175px',
	],
	PendingWithdrawalFiat: [
		'53px',
		'0.55fr',
		'0.85fr',
		'1.3fr',
		'0.66fr',
		'0.71fr',
		'0.76fr',
		'0.76fr',
		'0.61fr',
		'0.87fr',
		'1.2fr',
		'175px',
	],
	ApprovementsMerchant: [
		'0.63fr',
		'0.7fr',
		'1.35fr',
		'1.1fr',
		'1.45fr',
		'0.85fr',
		'0.75fr',
		'0.6fr',
		'0.65fr',
		'0.8fr',
		'0.7fr',
		'minmax(58px, 0.51fr)',
	],
	ApprovementsProvider: [
		'1.45fr',
		'.75fr',
		'1.45fr',
		'.85fr',
		'.75fr',
		'.85fr',
		'.6fr',
		'.65fr',
		'.7fr',
		'.51fr',
	],
	Invoices: [
		'minmax(40px, 0.22fr)',
		'minmax(200px, 1fr)',
		'0.65fr',
		'minmax(55px, 0.65fr)',
		'minmax(80px, 0.75fr)',
		'minmax(65px, 0.67fr)',
		'minmax(120px, 1fr)',
		'minmax(120px, 1fr)',
		'minmax(80px, 0.8fr)',
		'minmax(100px, 0.5fr)',
		'minmax(130px, 1fr)',
		'minmax(30px, 0.4fr)',
		'75px',
	],
	Reports: ['1fr', '1fr', '1fr', '1fr', '90px'],
	TeamMembers: ['0.35fr', '1.4fr', '2.35fr', '1.6fr', '1.7fr', '1.5fr', '1.05fr', '0.8fr'],
	MerchantWallet: ['1.22fr', '1fr', '1fr', '1fr', '0.6fr'],
	MerchantTeamMembers: ['0.32fr', '2.37fr', '1.5fr', '1.55fr', '1.1fr', '1.77fr', '1fr'],
	ProjectTransactionsCrypto: [
		'60px',
		'0.65fr',
		'0.65fr',
		'1fr',
		'1fr',
		'0.75fr',
		'0.7fr',
		'1fr',
		'0.7fr',
		'50px',
	],
	ProjectTransactionsFiat: [
		'50px',
		'0.55fr',
		'0.55fr',
		'1fr',
		'0.8fr',
		'minmax(150px,',
		'1fr)',
		'1fr',
		'0.6fr',
		'0.65fr',
		'0.85fr',
		'50px',
	],
	AccountsAccountsCrypto: [
		'1.45fr',
		'1.35fr',
		'.85fr',
		'1.1fr',
		'1.3fr',
		'1.15fr',
		'minmax(300px,3.6fr)',
	],
	AccountsAccountsFiat: ['1.5fr', '1.4fr', '1.4fr', '1.4fr', '3fr'],
	AccountsCurrencySettings: [
		'1.3fr',
		'1fr',
		'1fr',
		'1fr',
		'1fr',
		'1fr',
		'1fr',
		'minmax(60px,.4fr)',
		'minmax(50px,.4fr)',
	],
	AccountsCurrencySettingsMerchant: [],
	ProjectClients: [
		'.57fr',
		'.82fr',
		'1.97fr',
		'1.26fr',
		'1.07fr',
		'.77fr',
		'.87fr',
		'.65fr',
		'.62fr',
	],
	ProjectCallbacks: [
		'0.33fr',
		'0.75fr',
		'1.37fr',
		'1.67fr',
		'1.65fr',
		'0.87fr',
		'0.87fr',
		'1.11fr',
		'1.63fr',
	],
	ProjectWalletAddress: [
		'1fr',
		'1fr',
		'minmax(100px,',
		'3.45fr)',
		'1.1fr',
		'1.1fr',
		'1.3fr',
		'1.3fr',
	],
	ProjectOrders: [
		'0.2fr',
		'1.3fr',
		'0.57fr',
		'0.59fr',
		'0.65fr',
		'0.62fr',
		'0.86fr',
		'0.95fr',
		'0.8fr',
		'0.7fr',
		'0.49fr',
		'0.8fr',
	],
	WalletsBalancesCrypto: [
		'1.7fr',
		'2.3fr',
		'1.25fr',
		'1.25fr',
		'1.34fr',
		'1.95fr',
		'minmax(220px, 2.5fr)',
	],
	WalletsBalancesFiat: ['1fr', '1fr', '1fr', '1fr'],
	TransactionsHistoryCrypto: [
		'minmax(30px, 0.2fr)',
		'0.6fr',
		'minmax(160px, 1.4fr)',
		'0.75fr',
		'0.85fr',
		'0.8fr',
		'1fr',
		'1.3fr',
		'1.3fr',
		'1.3fr',
		'minmax(80px, 0.45fr)',
		'50px',
	],
	TransactionsHistoryFiat: ['1fr', '1fr', '3.75fr', '1.1fr', '1.1fr', '1.3fr', '1.3fr', '1.3fr'],
	Profit: [
		'minmax(160px, 1.45fr)',
		'0.85fr',
		'0.9fr',
		'1fr',
		'0.95fr',
		'1.15fr',
		'0.97fr',
		'1.3fr',
		'1.2fr',
		'1fr',
	],
	PaymentTransactions: [
		'0.75fr',
		'0.85fr',
		'0.85fr',
		'1.95fr',
		'1.1fr',
		'1.1fr',
		'1fr',
		'2.3fr',
		'0.85fr',
	],
	TRXManagement: [
		'minmax(74px, 1fr)',
		'minmax(125px, 1.5fr)',
		'minmax(87px, 1fr)',
		'minmax(80px, 1fr)',
		'minmax(101px, 1.5fr)',
		'minmax(35px, 1fr)',
		'minmax(74px, 1fr)',
		'minmax(55px, 0.4fr)',
	],
	TRXManagementDelegation: [
		'minmax(74px, 1fr)',
		'minmax(125px, 1.5fr)',
		'minmax(125px, 1.5fr)',
		'minmax(80px, 1fr)',
		'minmax(101px, 1.5fr)',
		'minmax(74px, 1fr)',
		'minmax(79px, 1fr)',
		'minmax(88px, 0.4fr)',
	],
	Liquidity: [],
};

export const getTableDescription = (tag: TTag) => ({
	tableHeaderItems: tableHeaderItems[tag],
	gridTemplateColumns: gridTemplateColumns[tag].join(' '),
});
