import { ReactNode } from 'react';
import { ExclamationRoundMiddle } from 'assets/inline-svg';
import styles from './styles.module.scss';

interface IProps {
	title: string;
	children: ReactNode;
}
const Tooltip = ({ title, children }: IProps) => {
	return (
		<p className={styles.tooltip__item}>
			{title}
			<span className={styles.tooltip}>
				<span className="tooltip-icon ">
					<ExclamationRoundMiddle />
				</span>
				<span className={styles.tooltip__content}>
					<span className="tooltip__text tooltip__text--ta-c">
						<span>{children}</span>
					</span>
				</span>
			</span>
		</p>
	);
};

export default Tooltip;
