import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TGetCountriesResponse, TGetLanguagesResponse } from 'services/api/app/types';
import { IBalanceInDefaultParams, IBalanceInDefaultResponse } from 'services/api/project/types';
import { IGetCurrencyNetworkItem } from 'services/api/public/types';
import { IAppStore, IFilter } from './types';

const initialState: IAppStore = {
	lang: 'EN',
	countries: null,
	countriesLoading: false,
	languages: null,
	languagesLoading: false,
	burger: false,
	balanceInDefaultCurrency: {
		balance: null,
		currencyCode: null,
	},
	networkLoading: false,
	currencyNetworks: null || [],
	filter: {
		current_page: 1,
	},
};

const app = createSlice({
	name: '@@app',
	initialState,
	reducers: {
		authorizedUserAppDataInit: () => {},
		authorizedUserAppDataInitMerchant: () => {},
		changeAppLanguage: (state, action: PayloadAction<string>) => {
			const { payload } = action;
			const appLanguageState = state;

			appLanguageState.lang = payload;
		},
		getCountriesRequest: (state) => {
			const appState = state;
			appState.countriesLoading = true;
		},
		getCountriesSuccess: (state, { payload }: PayloadAction<TGetCountriesResponse>) => {
			const appState = state;
			appState.countriesLoading = false;
			appState.countries = payload;
		},
		getCountriesError: (state) => {
			const appState = state;
			appState.countriesLoading = false;
		},
		getLanguagesRequest: (state) => {
			const appState = state;
			appState.languagesLoading = true;
		},
		getLanguagesSuccess: (state, { payload }: PayloadAction<TGetLanguagesResponse>) => {
			const appState = state;
			appState.languagesLoading = false;
			appState.languages = payload;
		},
		getLanguagesError: (state) => {
			const appState = state;
			appState.languagesLoading = false;
		},
		getBurgerReducer: (state, { payload }: PayloadAction<boolean>) => {
			const appState = state;
			appState.burger = payload;
		},
		getBalanceInDefaultCurrencyRequest: (
			state,
			{ payload }: PayloadAction<IBalanceInDefaultParams>,
		) => {},
		getBalanceInDefaultCurrencyResponse: (
			state,
			{ payload }: PayloadAction<IBalanceInDefaultResponse>,
		) => {
			const appState = state;
			appState.balanceInDefaultCurrency.balance = payload.balance;
			appState.balanceInDefaultCurrency.currencyCode = payload.currency_code;
		},
		getNetworkRequest: (state) => {
			const appState = state;
			appState.networkLoading = true;
		},
		getNetworkSuccess: (state, { payload }: PayloadAction<Array<IGetCurrencyNetworkItem>>) => {
			const appState = state;
			appState.networkLoading = false;
			appState.currencyNetworks = payload;
		},
		setFilter: (state, { payload }: PayloadAction<IFilter>) => {
			const appState = state;
			appState.filter = { ...initialState.filter, ...payload };
		},
		clearFilter: (state) => {
			const appState = state;
			appState.filter = initialState.filter;
		},
	},
});

export default app.reducer;
export const {
	authorizedUserAppDataInitMerchant,
	authorizedUserAppDataInit,
	changeAppLanguage,
	getCountriesRequest,
	getCountriesSuccess,
	getCountriesError,
	getLanguagesRequest,
	getLanguagesSuccess,
	getLanguagesError,
	getBurgerReducer,
	getBalanceInDefaultCurrencyRequest,
	getBalanceInDefaultCurrencyResponse,
	getNetworkRequest,
	getNetworkSuccess,
	setFilter,
	clearFilter,
} = app.actions;
