import { useState, FC } from 'react';
import { TriangleArrowDownIcon2, CheckIcon } from 'assets/inline-svg';
import { useSelect } from 'hooks';
import { ISelectProps } from './types';

export const SelectLanguage: FC<ISelectProps> = ({ options, title, setSelect }) => {
	const [value, setValue] = useState('English(USA)');
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const handleSelect = (_value: string, __filename: string) => {
		setValue(_value);
		setOpen(false);
		setSelect(null, 'lang', __filename);
	};

	return (
		<div className="select-block">
			<p className="select-block__name">{title}</p>
			<div className={`select select--type-choice ${open ? 'active' : ''} select--height-58`}>
				<button
					type="button"
					className="select__current select__current--placeholder"
					ref={triggerRef}
					onClick={toggleOpen}
				>
					<span className="token token--type2">
						<span className="token__icon token__icon--type2">
							<img
								src={
									options?.find((opt: any) => {
										return String(opt.value) === String(value);
									})?.icon || ''
								}
								alt=""
							/>
						</span>
						<span className="token__name">
							{options?.find((opt: any) => {
								return String(opt.value) === String(value);
							})?.value || 'Select'}
						</span>
					</span>

					<span className="select__current-arrow">
						<TriangleArrowDownIcon2 />
					</span>
				</button>
				<div className="select__drop" ref={dropRef}>
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							<ul>
								{options &&
									options?.map((option: any) => (
										<li key={option.value}>
											<button
												type="button"
												className={String(value) === String(option.value) ? 'active' : ''}
												onClick={() => handleSelect(String(option?.value), option.value)}
											>
												<span className="token">
													<span className="token__icon">
														<img src={option?.icon} alt="" />
													</span>
													<span className="token__name">{option.value}</span>
												</span>
												<span>{String(value) === String(option.value) && <CheckIcon />}</span>
											</button>
										</li>
									))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
