import * as Yup from 'yup';
import L from 'i18n-react';

export const addBankSchema = () => {
	return Yup.object().shape({
		currency_name: Yup.string().required(String(L.translate('Validation.account_name_required'))),
		iban: Yup.string().required(String(L.translate('Validation.iban_required'))),
		bic: Yup.string().required(String(L.translate('Validation.bic_required'))),
		reference: Yup.string().required(String(L.translate('Validation.reference_required'))),
	});
};

export const addWalletSchema = () => {
	return Yup.object().shape({
		currency_setting_id: Yup.number().required(String(L.translate('Validation.currency_required'))),
		address: Yup.string().required(String(L.translate('Please enter address'))),
		name_address: Yup.string().required(String(L.translate('Please enter nickname'))),
	});
};

export const editBankSchema = () => {
	return Yup.object().shape({
		name_account: Yup.string().required(String(L.translate('Validation.account_name_required'))),
		iban: Yup.string().required(String(L.translate('Validation.iban_required'))),
		bic: Yup.string().required(String(L.translate('Validation.bic_required'))),
		reference: Yup.string().required(String(L.translate('Validation.reference_required'))),
	});
};

export const editBankPageSchema = () => {
	return Yup.object().shape({
		bank_name: Yup.string().required(String(L.translate('Please enter bank name'))),
		bic: Yup.string().required(String(L.translate('Validation.bic_required'))),
		reference: Yup.string().required(String(L.translate('Validation.reference_required'))),
		iban: Yup.string().required(String(L.translate('Validation.iban_required'))),
		totp: Yup.string()
			.required('Totp code is required')
			.min(6, 'Totp is too short')
			.max(6, 'Totp is too long'),
	});
};
