/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiActivateProjectParams,
	IApiCreateProjectParams,
	IApiDeactivateProjectParams,
	IApiDeleteProjectParams,
	ISettingsProviderSaga,
	TApiGetProjectListResponse,
} from 'services/api/merchant/types';
import {
	IActivateProjectPayload,
	ICreateSettingsPayload,
	ISettingsResponse,
	IDeleteProjectPayload,
	IGetPayload,
	IMerchantSettingsStore,
} from './types';

export const initialState: IMerchantSettingsStore = {
	settings: null,
	languages: null,
	settingsLoading: false,
};

const merchantSettings = createSlice({
	name: '@@merchantSettings',
	initialState,
	reducers: {
		getMerchantSettingsRequest: (state, action: PayloadAction<ISettingsProviderSaga>) => state,
		getMerchantSettingsSuccess: (state, action: PayloadAction<ISettingsResponse>) => {
			state.settings = action.payload;
		},
		getMerchantSettingsLanguagesRequest: (state) => state,

		getMerchantSettingsLanguagesSuccess: (state, action: PayloadAction<any>) => {
			state.languages = action.payload;
		},
		postMerchantSettingsSuccess: (state, action: PayloadAction<ICreateSettingsPayload>) => {},
	},
});

export default merchantSettings.reducer;
export const {
	getMerchantSettingsRequest,
	getMerchantSettingsSuccess,
	getMerchantSettingsLanguagesRequest,
	getMerchantSettingsLanguagesSuccess,
	postMerchantSettingsSuccess,
} = merchantSettings.actions;
