import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import app from 'redux/reducers/app/reducer';
import auth from 'redux/reducers/auth/reducer';
import providerMerchant from 'redux/reducers/providerMerchant/reducer';
import administration from 'redux/reducers/administration/reducer';
import merchantApprovalManagement from 'redux/reducers/merchantApprovalManagement/reducer';
import merchantProjects from 'redux/reducers/merchantProjects/reducer';
import merchantProviderProfile from 'redux/reducers/merchantProfile/reducer';
import projectSettings from 'redux/reducers/projectSettings/reducer';
import projectTransactions from 'redux/reducers/projectTransactions/reducer';
import projectClients from 'redux/reducers/projectClients/reducer';
import projectCallbacks from 'redux/reducers/projectCallbacks/reducer';
import providerCurrencySetting from 'redux/reducers/providerCurrency/reducer';
import providerFee from 'redux/reducers/providerFee/reducer';
import providerWallets from 'redux/reducers/providerWallets/reducer';
import providerExportCSV from 'redux/reducers/providerExportCSV/reducer';
import providerInvoices from 'redux/reducers/providerInvoices/reducer';
import projectReports from 'redux/reducers/projectReports/reducer';
import projectBalance from 'redux/reducers/projectBalance/reducer';
import merchantAccounts from 'redux/reducers/merchantAccounts/reducer';
import providerWalletAddresses from 'redux/reducers/providerWalletAddresses/reducer';
import merchantBankAccount from 'redux/reducers/merchantBankAccount/reducer';
import providerBankAccount from 'redux/reducers/providerBankAccount/reducer';
import providerReportsAnalytics from 'redux/reducers/providerReportsAnalytics/reducer';
import merchantInvoices from 'redux/reducers/merchantInvoices/reducer';
import projectWalletAddresses from 'redux/reducers/projectWalletAddresses/reducer';
import providerOrders from 'redux/reducers/providerOrders/reducer';
import merchantOrders from 'redux/reducers/merchantOrders/reducer';
import providerPaymentTrasaction from 'redux/reducers/providerPaymentTrasaction/reducer';
import merchantSettings from 'redux/reducers/merchantSettings/reducer';
import merchantSecurity from 'redux/reducers/merchantSecurity/reducer';
import projectComissions from 'redux/reducers/projectComissions/reducer';
import providerProjectApprovements from 'redux/reducers/providerProjectApprovements/reducer';
import providerApprovements from 'redux/reducers/providerApprovements/reducer';
import providerTransactions from 'redux/reducers/providerTransactions/reducer';
import projectWithdrawal from 'redux/reducers/projectWithdrawal/reducer';
import paymentWidget from 'redux/reducers/paymentWidget/reducer';
import merchantAnalytics from 'redux/reducers/analyticsMerchant/reducer';
import providerLiquidity from 'redux/reducers/providerLiquidity/reducer';
import providerAnalytics from 'redux/reducers/analyticsProvider/reducer';
import providerTRXManagement from 'redux/reducers/providerTRXManagement/reducer';

const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		app,
		auth,
		// components
		providerMerchant,
		administration,
		providerCurrencySetting,
		providerFee,
		providerWallets,
		providerWalletAddresses,
		providerExportCSV,
		providerInvoices,
		providerBankAccount,
		providerReportsAnalytics,
		providerOrders,
		providerPaymentTrasaction,
		providerProjectApprovements,
		providerApprovements,
		providerTransactions,
		providerLiquidity,
		providerAnalytics,
		providerTRXManagement,
		// Merchant
		merchantApprovalManagement,
		merchantProjects,
		merchantProviderProfile,
		merchantBankAccount,
		merchantSettings,
		merchantSecurity,
		// Project
		projectSettings,
		projectTransactions,
		projectClients,
		projectCallbacks,
		projectReports,
		projectBalance,
		merchantAccounts,
		merchantInvoices,
		projectWalletAddresses,
		merchantOrders,
		projectComissions,
		projectWithdrawal,
		paymentWidget,
		merchantAnalytics,
	});

export default createRootReducer;
