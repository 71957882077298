import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	getProviderFeeRequest,
	getProviderFeeSuccess,
	putProviderFeeRequest,
	putProviderFeeSuccess,
} from './reducer';
import {
	TApiGetProviderFeeResponse,
	TApiPutProviderFeePayload,
	TApiPutProviderFeeResponse,
} from './types';

function* getProviderFeeWorker() {
	try {
		const data: TApiGetProviderFeeResponse[] = yield call(api.provider.getProviderFee);

		yield put(getProviderFeeSuccess(data));
	} catch (error) {
		showError(error);
	}
}

function* putProviderFeeWorker({ payload }: PayloadAction<TApiPutProviderFeePayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		const data: TApiPutProviderFeeResponse = yield call(api.provider.putProviderFee, apiParams);
		yield put(putProviderFeeSuccess(data));
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}
export function* providerFeeSaga() {
	yield takeLatest(getProviderFeeRequest, getProviderFeeWorker);
	yield takeLatest(putProviderFeeRequest, putProviderFeeWorker);
}
