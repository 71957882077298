import { notify } from 'utils/notify';
import { IFilter } from 'redux/reducers/app/types';
import { IProviderDownloadTransactionParams } from 'services/api/provider/types';
import { createReportTransactionProviderRequest } from 'redux/reducers/providerReportsAnalytics/reducer';
import { IReportProviderTransactionPayload } from 'redux/reducers/providerReportsAnalytics/types';

export const getCreateReport = (props: IFilter, tab: string, reportFormat?: string) => ({
	payload: {
		apiParams: props as IProviderDownloadTransactionParams,
		is_crypto: props.isCrypto,
		report_format: reportFormat,
		onSuccess: () =>
			notify("Report successfully created. You can find it in 'Reports' section", 'success'),
	} as IReportProviderTransactionPayload,
	reducer: createReportTransactionProviderRequest,
});
