import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrencyNetwork, getFilter } from 'redux/reducers/app/selectors';
import { getProviderCurrencyAllNoSettings } from 'redux/reducers/providerCurrency/selectors';
import { IFilterElemItem } from 'v2Components/layoutsComponents/Filter/types';
import { IGetCurrencyNetworkItem } from '../services/api/public/types';
import { TApiGetProviderAllCurrencyResponse } from '../services/api/provider/types';

interface IFilterHistoryParams {
	id: string;
	value: string | number | Date | null;
	networks?: IGetCurrencyNetworkItem[];
	currencies?: TApiGetProviderAllCurrencyResponse[];
}

type TFilterHistory = (params: IFilterHistoryParams) => IFilterElemItem;

export default function useFilterList(getFilterHistory: TFilterHistory) {
	const filter = useSelector(getFilter);
	const networks = useSelector(getCurrencyNetwork);
	const currencies = useSelector(getProviderCurrencyAllNoSettings);
	const getValue = (value: string | null | Date | number | undefined) => {
		if (value === 0) return 0;
		return value || null;
	};
	return useMemo(
		() =>
			Object.keys(filter)
				.filter((key: string) => key !== 'current_page' && filter[key] != null)
				.map((key: string) =>
					getFilterHistory({
						id: key,
						value: getValue(filter[key]),
						networks,
						currencies,
					}),
				),
		[currencies, filter, getFilterHistory, networks],
	);
}
