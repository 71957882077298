import { CopyIcon, TrendIcon } from 'assets/inline-svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { priceFormat, shortNotation } from 'utils/strings';
import { notify } from 'utils/notify';
import { useSelector } from 'react-redux';
import { getTRXOverview } from 'redux/reducers/providerTRXManagement/selectors';
import styles from './styles.module.scss';

const AddressInformation = () => {
	const overview = useSelector(getTRXOverview);
	const handleIbanCopy = () => notify('Address copied successfully!', 'info');
	return (
		<div className={styles.account}>
			<div className={styles.account__header}>
				<div className={styles.account__icon}>
					<TrendIcon />
				</div>
				<p className={styles.account__title}>TRX Provider Address Information</p>
			</div>
			<div className={styles.account__currency}>
				<p className={styles.account__label}>Balance</p>
				<div>
					<p className={styles.account__balance}>
						{priceFormat(overview?.totalBalance)}
						<span className={styles.account__balanceCurrency}> TRX</span>
					</p>
				</div>
				<div className={styles.trend}>
					<div className={styles.trend__icon}>
						<TrendIcon />
					</div>
					<p className={styles.trend__value}>
						+2.6% <span className={styles.account__label}>than last week</span>
					</p>
				</div>
			</div>
			<div>
				<p className={styles.account__label}>Address</p>
				<CopyToClipboard text={String(overview?.address)} onCopy={handleIbanCopy}>
					<div className={styles.account__value}>
						<p className={styles.copy__value}>
							{overview?.address && shortNotation(overview?.address, 12, 8)?.toUpperCase()}
						</p>
						<button aria-label="button" type="button" className={styles.copy}>
							<CopyIcon />
						</button>
					</div>
				</CopyToClipboard>
			</div>
			<div className={styles.bottom}>
				<div className={styles.bottom__item}>
					<p className={styles.bottom__label}>Total TRX Staked</p>
					<p className={styles.bottom__value}>
						{priceFormat(overview?.stakedBalance)}
						<span className={styles.bottom__currency}> TRX</span>
					</p>
				</div>
				<div className={styles.bottom__item}>
					<p className={styles.bottom__label}>Total TRX Unstaked</p>
					<p className={styles.bottom__value}>
						{priceFormat(overview?.availableBalance)}
						<span className={styles.bottom__currency}> TRX</span>
					</p>
				</div>
			</div>
		</div>
	);
};

export default AddressInformation;
