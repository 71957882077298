import { HTMLAttributes, ReactNode, useEffect, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IDrawerLayout extends HTMLAttributes<HTMLDivElement> {
	classNameLayout?: string;
	children: ReactNode;
	openDrawer: boolean;
	setOpenDrawer: (value: boolean) => void;
}

const DrawerLayout = ({
	openDrawer,
	setOpenDrawer,
	children,
	className,
	classNameLayout,
	...props
}: IDrawerLayout): JSX.Element => {
	const triggerRef = useRef<HTMLSelectElement>(null);

	useEffect(() => {
		const checkClickInDrawer: EventListenerOrEventListenerObject = (e) => {
			const clickedElement = e?.target as HTMLDivElement;
			if (
				openDrawer &&
				e?.target &&
				!triggerRef?.current?.contains(e?.target as Node) &&
				(typeof clickedElement?.className === 'object' ||
					!clickedElement?.className?.includes('datepicker'))
			) {
				setOpenDrawer(false);
			}
		};
		document.addEventListener('click', checkClickInDrawer);
		return () => {
			document.removeEventListener('click', checkClickInDrawer);
		};
	}, [openDrawer, setOpenDrawer]);

	// Высчитываем высоту DrawerBody для корректного скролла
	useLayoutEffect(() => {
		let heightHeader = 0;
		let heightFooter = 0;
		const headerComponent = triggerRef.current?.getElementsByClassName(styles['drawer-header']);
		const footerComponent = triggerRef.current?.getElementsByClassName(styles['drawer-footer']);
		const bodyComponent = triggerRef.current?.getElementsByClassName(styles['drawer-body']);

		if (headerComponent && headerComponent[0]) {
			heightHeader = (headerComponent[0] as HTMLDivElement).offsetHeight;
		}

		if (footerComponent && footerComponent[0]) {
			heightFooter = (footerComponent[0] as HTMLDivElement).offsetHeight;
		}

		if (bodyComponent && bodyComponent[0]) {
			(bodyComponent[0] as HTMLDivElement).style.height = `calc(100vh - ${
				heightHeader + heightFooter
			}px)`;
			(bodyComponent[0] as HTMLDivElement).style.flex = `1 1 calc(100% - ${
				heightHeader + heightFooter
			}px)`;
		}
	});

	return (
		<section
			ref={triggerRef}
			className={classNames(classNameLayout, styles['drawer-layout'], {
				[styles['drawer-layout__active']]: openDrawer,
			})}
			{...props}
		>
			<div className={classNames(styles.drawer, className)}>{children}</div>
		</section>
	);
};

export default DrawerLayout;
