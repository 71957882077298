import { PopUpCloseIcon } from 'assets/inline-svg';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { cryptoWitdrawalRequest } from 'redux/reducers/merchantAccounts/reducer';
import { TextField } from 'UI/Forms/TextField/TextField';
import { trimLastZeros } from 'utils/strings';
import { isExist } from 'utils/numbers';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { IPropsWithdrawalConfirm, IValues } from './types';

const initialValues: IValues = {
	address: '',
};

const MIN_PERCENT = 0;
const MAX_PERCENT = 100;
const LENGTH_TOTP = 6;

export const WithdrawalConfirm: FC<IPropsWithdrawalConfirm> = ({
	setOpenConfirm,
	onPopUps,
	limits,
	amount,
	isSelect,
	address,
	fee,
}) => {
	const dispatch = useDispatch();

	const onSubmit = (values: IValues) => {
		isSelect &&
			dispatch(
				cryptoWitdrawalRequest({
					data: {
						project_balance_id: isSelect.id,
						amount: +amount,
						address,
					},
					closePopUp: onPopUps,
				}),
			);
	};

	const validationSchema = yup.object().shape({
		totp: yup
			.string()
			.required('Totp code is required')
			.min(LENGTH_TOTP, 'Totp is too short')
			.max(LENGTH_TOTP, 'Totp is too long'),
	});

	const { getViewDecimal } = useViewDecimals();

	const viewDecimals = isSelect?.currency.code
		? getViewDecimal(isSelect?.currency.code)
		: undefined;

	const width = useMemo(
		() => (limits.daily_limit_used + +amount) / (limits.daily_limit / MAX_PERCENT),
		[amount, limits.daily_limit, limits.daily_limit_used],
	);

	return (
		<div className="popup popup--width-540">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				initialTouched={{
					totp: true,
				}}
				onSubmit={onSubmit}
			>
				{({ isSubmitting }) => (
					<Form>
						<div className="popup-header">
							<p className="popup-header__title popup-header__title--ta-l">Confirm withdrawal</p>
							<div className="popup-text popup-text--ta-l">
								<p>Confirm this action to withdrawal</p>
							</div>
						</div>
						<div className="popup-body popup-body--type3">
							<div className="withdrawal-info">
								<div className="withdrawal-info__title">
									<p>Daily limit</p>
								</div>
								<div className="progress-line">
									<div
										className="progress-line__fill"
										style={{
											width: `${Math.min(MIN_PERCENT, Math.max(MAX_PERCENT, width))}%`,
										}}
									/>
								</div>
								<div className="withdrawal-info__info">
									<p>{`${trimLastZeros(
										(limits.daily_limit_used + +amount).toFixed(viewDecimals),
									)} ${String(isSelect?.currency.code.toUpperCase())} remaining`}</p>
								</div>
							</div>
							<div className="deposit-info-pop">
								<ul>
									<li>
										<span>Wallet</span>
										<p>
											{isSelect &&
												`${String(isSelect.currency.name)} ${String(
													isSelect.currency.code.toUpperCase(),
												)}`}
										</p>
									</li>
									<li>
										<span className="header-custom">Address</span>
										<p title={address}>
											<span className="currency-address-custom">{String(address)}</span>{' '}
											<span className="currency-address-custom">
												{isSelect ? String(isSelect.currency.code.toUpperCase()) : ''}
											</span>
										</p>
									</li>
									<li>
										<span>Amount</span>
										<p>
											{isSelect &&
												`${trimLastZeros(Number(amount).toFixed(viewDecimals))} ${String(
													isSelect.currency.code.toUpperCase(),
												)}`}
										</p>
									</li>
								</ul>
							</div>
							<div className="deposit-info-pop">
								<ul>
									<li>
										<span>Platform Fee:</span>
										<p>
											{isSelect &&
												`${
													isExist(fee) ? trimLastZeros(Number(fee).toFixed(viewDecimals)) : '0'
												} ${String(isSelect.currency.code.toUpperCase())}`}
										</p>
									</li>
									<li>
										<span>Will be sent:</span>
										<p>{`${trimLastZeros(Number(amount).toFixed(viewDecimals))} ${
											String(isSelect?.currency.code.toUpperCase()) || ''
										}`}</p>
									</li>
									<li>
										<span>Will be received:</span>
										<p>{`${trimLastZeros(
											(Number(amount) - Number(fee) || 0).toFixed(viewDecimals),
										)} ${String(isSelect?.currency.code.toUpperCase()) || ''}`}</p>
									</li>
								</ul>
							</div>
							<TextField
								label="Enter the 2FA 6-digit Code"
								type="text"
								placeholder="Enter code"
								name="totp"
								maxLength={6}
								onlyNumbers
							/>
						</div>
						<div className="popup-button popup-button--type2">
							<button
								onClick={() => setOpenConfirm(false)}
								type="button"
								className="button button--full-width button--type2"
							>
								Cancel
							</button>
							<button type="submit" className="button button--full-width" disabled={isSubmitting}>
								Confirm
							</button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};
