import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProviderCurrencyAllNoSettings,
	getProviderCurrencyCrypto,
} from 'redux/reducers/providerCurrency/selectors';
import { TextField } from 'v2Components/formComponents/TextField';
import { Formik, useFormikContext } from 'formik';
import Button from 'v2Components/UI/Button';
import {
	getAccountLoading,
	getCheckAddressSelector,
} from 'redux/reducers/merchantAccounts/selectors';
import debounce from 'lodash.debounce';
import { getCheckWithdrawlRequest } from 'redux/reducers/merchantAccounts/reducer';
import { SelectField } from 'v2Components/formComponents/SelectField';
import { DefaultToken, Token } from 'v2Components/UI/Token';
import FormSideModal from 'v2Components/layoutsComponents/FormSideModal';
import { TApiGetProviderAllCurrencySettingResponse } from 'redux/reducers/providerCurrency/types';
import { IAddCryptoWalletParams } from 'services/api/provider/types';
import styles from './styles.module.scss';
import { TAddCryptoWalletFormValues } from '../../types';
import { addWalletSchema } from '../../utils';

interface IProps {
	showFilter: boolean;
	setShowFilter: (value: boolean) => void;
	onSubmit: (values: IAddCryptoWalletParams, resetForm: () => void) => void;
}

export const initialValues: TAddCryptoWalletFormValues = {
	currency_setting_id: 0,
	reserve_wallet_name: '',
	reserve_wallet: '',
};

const CryptoWalletForm = ({ showFilter, setShowFilter, onSubmit }: IProps): JSX.Element => {
	const dispatch = useDispatch();
	const currencyList = useSelector(getProviderCurrencyCrypto);
	const currencyListSelect = useSelector(getProviderCurrencyAllNoSettings);
	const selectedList = useMemo(
		() => currencyListSelect.filter((el) => el.is_crypto === 1),
		[currencyListSelect],
	);
	const isValidAddress = useSelector(getCheckAddressSelector);
	const isAccountLoading = useSelector(getAccountLoading);
	const fromValues = useFormikContext<TAddCryptoWalletFormValues>();
	const { reserve_wallet, currency_setting_id } = fromValues?.values || initialValues;

	const checkWalletAddressDebouced = useMemo(
		() =>
			debounce((address: string) => {
				const selectedCurrency = currencyList.find(
					(item: TApiGetProviderAllCurrencySettingResponse) => item.id === currency_setting_id,
				);
				const { currency_network } = selectedCurrency || {};
				if (!currency_network) return;
				dispatch(
					getCheckWithdrawlRequest({
						paramsApi: {
							address,
							currency_id: +currency_setting_id,
							currency_network,
						},
					}),
				);
			}, 500),
		[currencyList, currency_setting_id, dispatch],
	);

	const checkWalletAddress = useCallback(
		(address: string) => checkWalletAddressDebouced(address),
		[checkWalletAddressDebouced],
	);

	useEffect(() => {
		reserve_wallet && checkWalletAddress(reserve_wallet);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reserve_wallet, currency_setting_id]);

	return (
		<Formik
			validationSchema={addWalletSchema}
			initialValues={initialValues}
			onSubmit={(values, { resetForm, setSubmitting }) => {
				onSubmit(values, resetForm);
				setSubmitting(false);
			}}
			enableReinitialize
		>
			{({ resetForm }) => (
				<FormSideModal
					className={styles.accounts__drawer}
					onActionDrawer={resetForm}
					title="Add Wallet"
					show={showFilter}
					setShow={setShowFilter}
					footer={
						<>
							<Button
								size="regular"
								width="100%"
								type="button"
								className={styles.cancel}
								onClick={() => setShowFilter(false)}
							>
								Cancel
							</Button>
							<Button size="regular" width="100%" type="submit">
								Add wallet
							</Button>
						</>
					}
				>
					<SelectField
						title="Currency"
						name="currency_setting_id"
						options={[...selectedList]}
						valueProp="id"
						renderSelected={(selectedValue) =>
							(selectedValue && (
								<Token
									code={
										selectedValue.code === 'All'
											? selectedValue.code
											: (selectedValue.code || '').toUpperCase()
									}
									name={selectedValue.name}
								/>
							)) || <DefaultToken />
						}
						renderOption={({ code, name, img_path }) => (
							<Token code={code === 'All' ? code : (code || '').toUpperCase()} name={name} />
						)}
					/>
					<TextField
						name="reserve_wallet"
						label="Address"
						placeholder="Enter wallet address"
						customErrorMsg={
							!isValidAddress && !isAccountLoading && reserve_wallet && currency_setting_id
								? 'Incorrect address!'
								: undefined
						}
					/>
					<TextField name="currency_network" label="Network" readOnly />
					<TextField name="reserve_wallet_name" label="Nickname" placeholder="Enter nickname" />
				</FormSideModal>
			)}
		</Formik>
	);
};

export default CryptoWalletForm;
