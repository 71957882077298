import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPasswordRequest, firstLoginRequest } from 'redux/reducers/auth/reducer';
import { getFirstLoginData } from 'redux/reducers/auth/selectors';
import { ICreatePasswordPayload } from 'redux/reducers/auth/types';
import { Success } from 'UI/Success/Success';

interface IProps {
	data: ICreatePasswordPayload | null;
}

export const TwoFactorAuth: FC<IProps> = ({ data }) => {
	const dispatch = useDispatch();
	const [code, setCode] = useState<string>('');
	const [success, setSuccess] = useState<string | undefined>('');
	const firstLoginData = useSelector(getFirstLoginData);

	const { qr, secretCode } = firstLoginData || {};

	const onSubmit = () => {
		if (data?.apiParams) {
			const payload = data;
			payload.apiParams.totp = +code;
			payload.onSuccess = setSuccess;
			dispatch(createPasswordRequest(payload));
		}
	};

	const handleInput = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;

		setCode(value);
	};

	useEffect(() => {
		data?.apiParams && dispatch(firstLoginRequest({ token: data.apiParams.token }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<section className="authorization-section">
			{success ? (
				<Success emailSuccess={success} />
			) : (
				<div className="authorization-box">
					<div className="form form--authorization">
						<div className="form__header">
							<p className="form-title ">Two-factor authentication</p>
							<div className="form-text form-text--color3">
								<p>
									Two-factor authentication (2FA) is an extra layer of protection in addition to
									your password.
								</p>
							</div>
						</div>
						<div className="form__body">
							<p className="form-subtitle">Google Authenticator</p>
							<div className="form-text">
								<p>Protecting your account with a digital key.</p>
							</div>
							<div className="form-group form-group--type2">
								<p className="input-name">
									Scan this QR code with the two-factor authentication app.
								</p>
								<div className="tfa">
									<div className="tfa__qr">
										<img src={qr} alt="qr" />
									</div>
									<p className="tfa__code">{secretCode}</p>
								</div>
							</div>
							<div className="input input--mb-0">
								<p className="input-name">Enter the 2FA 6-digit Code</p>
								<div className="input-wrapper">
									<input
										onChange={handleInput}
										className="input-item"
										type="text"
										placeholder="Code"
										value={code}
									/>
								</div>
							</div>
						</div>
						<div className="form-footer">
							<button type="button" className="button button--type2 button--full-width">
								Cancel
							</button>
							<button
								disabled={code.length !== 6}
								onClick={onSubmit}
								type="button"
								className="button button--full-width"
							>
								Confirm
							</button>
						</div>
					</div>
				</div>
			)}
		</section>
	);
};
