import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
	saveCallbackUrlRequest,
	clearCallbackUrlRequest,
} from 'redux/reducers/projectSettings/reducer';
import { getApi } from 'redux/reducers/projectSettings/selectors';
import {
	IClearCallbackUrlPayload,
	ISaveCallbackUrlPayload,
} from 'redux/reducers/projectSettings/types';
import { notify } from 'utils/notify';
import { CallbackUrlForm } from './CallbackUrlForm/CallbackUrlForm';
import { TCallbackUrlFormOnSubmit } from './CallbackUrlForm/types';

export const CallbackUrl: FC = () => {
	const dispatch = useDispatch();
	const { projectId } = useParams<{ projectId: string }>();
	const api = useSelector(getApi);

	const handleSubmit: TCallbackUrlFormOnSubmit = ({ callbackUrl }) => {
		if (callbackUrl) {
			const payload: ISaveCallbackUrlPayload = {
				apiParams: { project_id: projectId, url: callbackUrl },
				onSuccess: () => notify('Callback URL has been successfully saved', 'success'),
			};
			dispatch(saveCallbackUrlRequest(payload));
			return;
		}
		const payload: IClearCallbackUrlPayload = {
			apiParams: { project_id: +projectId },
			onSuccess: () => notify('Callback URL has been successfully cleared', 'success'),
		};
		dispatch(clearCallbackUrlRequest(payload));
	};

	return (
		<CallbackUrlForm
			initValues={{ callbackUrl: api?.callback_url || '' }}
			onSubmit={handleSubmit}
		/>
	);
};
