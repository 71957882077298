import { createRef, FC } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { ICropElement } from './types';

export const CropElement: FC<ICropElement> = ({ image, cropData, setCropData }) => {
	const cropperRef = createRef<ReactCropperElement>();

	function getRoundedCanvas(sourceCanvas: any) {
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		const { width, height } = sourceCanvas;

		canvas.width = width;
		canvas.height = height;

		if (context) {
			context.imageSmoothingEnabled = true;
			context.drawImage(sourceCanvas, 0, 0, width, height);
			context.globalCompositeOperation = 'destination-in';
			context.beginPath();
			context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
			context.fill();
		}

		return canvas;
	}

	const getCropData = () => {
		if (typeof cropperRef.current?.cropper !== 'undefined') {
			const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
			const roundedCanvas = getRoundedCanvas(croppedCanvas);
			// const [mime] = splitDataUrl(image);
			setCropData(roundedCanvas.toDataURL());
		}
	};

	return (
		<div className="crop-element">
			<div className="crop-area-block">
				<Cropper
					ref={cropperRef}
					style={{ height: '100%', width: '100%' }}
					initialAspectRatio={1}
					aspectRatio={1}
					src={image}
					viewMode={0}
					minCropBoxHeight={61}
					minCropBoxWidth={61}
					background={false}
					autoCropArea={1}
					checkOrientation={false}
					guides
					dragMode="move"
					// cropBoxMovable={false}
					// cropBoxResizable={false}
				/>
			</div>
			<div className="crop-image-result-container">
				<button
					type="button"
					className="button button--full-width crop-img-btn"
					onClick={getCropData}
				>
					Crop Image
				</button>
				{cropData && <img src={cropData} alt="cropped" className="cropped-image" />}
			</div>
		</div>
	);
};

export default CropElement;
