import { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import {
	getInvoice,
	getInvoiceLoading,
	getInvoiceStatus,
} from 'redux/reducers/merchantInvoices/selectors';
import {
	getInvoiceRequest,
	getInvoiceStatusRequest,
} from 'redux/reducers/merchantInvoices/reducer';
import { IGetInvoiceStatusPayload } from 'redux/reducers/merchantInvoices/types';
import { InvoiceLink, IInvoiceLink } from 'v2Pages/Provider/InvoicesPage/InvoiceLink/InvoiceLink';

export const ProjectInvoiceLink: FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const data = useSelector(getInvoice);
	const loading = useSelector(getInvoiceLoading);
	const status = useSelector(getInvoiceStatus);

	const searchParams = new URLSearchParams(location.search);
	const id = searchParams.get('id') || '';

	useEffect(() => {
		dispatch(getInvoiceRequest({ id }));
	}, [dispatch, id]);

	const { client_email, invoice, project, qrAddressPath, currency } = data || {};

	const { name: currencyName } = currency || {};

	const {
		id: invoiceId,
		created_at,
		due_date,
		currency_request,
		currency_paid,
		amount_request,
		amount_to_pay,
		address,
	} = invoice || {};

	const { website, name, file_icon } = project || {};

	const checkStatus = () => {
		const payload: IGetInvoiceStatusPayload = {
			apiParams: { invoice_id: Number(invoiceId) },
			onSuccess: () => {},
		};
		dispatch(getInvoiceStatusRequest(payload));
	};

	const invoiceLinkProps: IInvoiceLink = {
		project,
		file_icon,
		website,
		email: client_email,
		invoiceNumber: invoiceId,
		invoiceDate: created_at,
		dueDate: due_date,
		currencyName,
		currency: currency_request,
		currencyToPay: currency_paid,
		amount: amount_request,
		toPay: amount_to_pay,
		qrCodePath: qrAddressPath,
		address,
		status,
		checkStatus,
	};

	return !loading && data ? <InvoiceLink {...invoiceLinkProps} /> : null;
};
