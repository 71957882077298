import { Formik } from 'formik';
import { FC, useState } from 'react';
import { TextField } from 'UI/Forms/TextField/TextField';
import { EditIcon, NoIcon, YesIcon } from 'assets/inline-svg';
import classNames from 'classnames';
import { IProps } from '../types';
import style from './styles.module.scss';

const TableBody: FC<IProps> = ({ onSubmit, item }) => {
	const { currency_network, withdrawal_amount_free } = item;

	const [changeableMode, setChangeableMode] = useState<boolean>(false);

	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};

	const initialValues = {
		withdrawal_amount_free: withdrawal_amount_free || '',
	};
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						const body = {
							currency_setting_id: item.currency_id,
							withdrawal_amount_free: values.withdrawal_amount_free,
						};
						onSubmit(body);
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className={classNames(style.tr, style.active)} onSubmit={props.handleSubmit}>
							<div className={style.td}>
								<p className={style.td_hiddenName}>Currency</p>
								<span className={classNames(style.token, style.tokenType2)}>
									<span className={style.token__icon}>
										<img src={item?.currency.img_path} alt={item?.currency.name} />
									</span>
									<span className={style.token__nameWrap}>
										<span className={style.token__name}>{item?.currency.name}</span> <br />
										<span className={style.token__transcription}>
											{item?.currency.code === item?.currency_network_name
												? item?.currency.code.toUpperCase()
												: `${item?.currency.code.toUpperCase()}, ${item?.currency_network_name.toUpperCase()}`}
										</span>
									</span>
								</span>
							</div>
							<div className={style.td}>
								<p className={style.td_hiddenName}>Withdrawal, Once</p>
								<TextField
									type="number"
									classNameCustom={style.input__mb_0}
									name="withdrawal_amount_free"
								/>
							</div>

							<div className={style.td}>
								<p className={style.td_hiddenName}>Action</p>
								<div className={classNames(style.table__buttons, style.flexE)}>
									<button aria-label="button" type="submit">
										<YesIcon />
									</button>
									<button aria-label="button" type="button" onClick={switchOffChangeableMode}>
										<NoIcon />
									</button>
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className={style.tr}>
					<div className={style.td}>
						<p className={style.td_hiddenName}>Currency</p>
						<span className={classNames(style.token, style.tokenType2)}>
							<span className={style.token__icon}>
								<img src={item?.currency.img_path} alt={item?.currency.name} />
							</span>
							<span className={style.token__nameWrap}>
								<span className={style.token__name}>{item?.currency.name}</span> <br />
								<span className={style.token__transcription}>
									{item?.currency.code === item?.currency_network_name
										? item?.currency.code.toUpperCase()
										: `${item?.currency.code.toUpperCase()}, ${item?.currency_network_name.toUpperCase()}`}
								</span>
							</span>
						</span>
					</div>
					<div className={style.td}>
						<p className={style.td_hiddenName}>Currency on which autoconvert</p>
						<p className={style.tdValue}>{withdrawal_amount_free}</p>
					</div>
					<div className={classNames(style.td, style.tdRight)}>
						<p className={style.td_hiddenName}>Action</p>
						<div className={style.table__button}>
							<button
								aria-label="button"
								type="button"
								onClick={() => {
									switchOnChangeableMode();
								}}
							>
								<EditIcon />
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default TableBody;
