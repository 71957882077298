import { IStoreState } from 'redux/types';

export const getProviderApprovements = (state: IStoreState) => state.providerApprovements;
export const getProviderApprovementsData = (state: IStoreState) =>
	getProviderApprovements(state).providerApprovementsMerchants;
export const getProviderApprovementsLoading = (state: IStoreState) =>
	getProviderApprovements(state).providerApprovementsMerchantLoading;
export const getProviderApprovementsProvider = (state: IStoreState) =>
	getProviderApprovements(state).providerApprovementsProvider;
export const getProviderApprovementsProviderLoading = (state: IStoreState) =>
	getProviderApprovements(state).providerApprovementsProviderLoading;
export const getProviderApprovementsKYC = (state: IStoreState) =>
	getProviderApprovements(state).merchantKYCids;
export const getProviderApprovementDetails = (state: IStoreState) =>
	getProviderApprovements(state).providerApprovementsDetails;
export const getProviderApprovementsDefault = (state: IStoreState) =>
	getProviderApprovements(state).providerApprovementsDefaultMerchants;
export const getApprovementsDefault = (state: IStoreState) =>
	getProviderApprovements(state).providerApprovementsDefault;
