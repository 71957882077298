import { createDateFormatUTC } from 'utils/dates';
import styles from './styles.module.scss';

interface IProps {
	created_at?: string | null;
}
export default ({ created_at }: IProps) => {
	const dateSplit = createDateFormatUTC(created_at)?.split(' ');

	if (!dateSplit?.length) {
		return null;
	}

	return (
		<div className={styles.col}>
			<div className={styles.col__major}> {dateSplit[0]?.slice(0, -1)}</div>
			<div className={styles.col__minor}>
				{dateSplit[1]} {dateSplit[2]}
			</div>
		</div>
	);
};
