import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { api, showError } from 'services';
import {
	IGetCallbacksParams,
	TGetCallbacksResponse,
	IViewCallbackParams,
	IViewCallbackResponse,
	IProjectCallbacksExportCSVPayload,
} from 'services/api/project/types';

import { downloadCSVFunc } from 'utils/downloadCSVFunc';
import {
	getCallbacksRequest,
	getCallbacksSuccess,
	getCallbacksError,
	viewCallbackRequest,
	viewCallbackSuccess,
	viewCallbackError,
	exportCSVRequest,
	exportCSVSuccess,
	resendCallbackRequest,
} from './reducer';
import { IResendCallbackPayload } from './types';

function* getCallbacksRequestWorker({ payload }: PayloadAction<IGetCallbacksParams>) {
	try {
		const response: TGetCallbacksResponse = yield call(api.project.getCallbacks, payload);
		yield put(getCallbacksSuccess(response));
	} catch (error) {
		yield put(getCallbacksError());
		showError(error);
	}
}

function* viewCallbackRequestWorker({ payload }: PayloadAction<IViewCallbackParams>) {
	try {
		const response: IViewCallbackResponse = yield call(api.project.viewCallback, payload);
		yield put(viewCallbackSuccess(response));
	} catch (error) {
		yield put(viewCallbackError());
		showError(error);
	}
}

function* exportCSVRequestWorker({ payload }: PayloadAction<IProjectCallbacksExportCSVPayload>) {
	const { nameFile, apiParams, onSuccess } = payload;
	try {
		const data: string = yield call(api.project.getProjectExportCSVTransaction, apiParams);
		onSuccess?.();
		downloadCSVFunc(nameFile, data);
		yield put(exportCSVSuccess());
	} catch (error) {
		showError(error);
	}
}

function* resendCallbackWorker({ payload }: PayloadAction<IResendCallbackPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield call(api.project.resendCallback, apiParams);
		onSuccess?.();
	} catch (error) {
		showError(error);
	}
}

export function* projectCallbacksSaga() {
	yield takeLatest(getCallbacksRequest, getCallbacksRequestWorker);
	yield takeLatest(viewCallbackRequest, viewCallbackRequestWorker);
	yield takeLatest(exportCSVRequest, exportCSVRequestWorker);
	yield takeLatest(resendCallbackRequest, resendCallbackWorker);
}
