import TableRow from 'v2Pages/Project/ProjectClients/TableRow/TableRow';
import { Pagination } from 'UI/Pagination/Pagination';
import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClients, getClientsLoading } from 'redux/reducers/projectClients/selectors';
import { useParams } from 'react-router-dom';
import { getClientsRequest } from 'redux/reducers/projectClients/reducer';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import Table from 'v2Components/commomComponents/Table';
import Main from 'v2Components/layoutsComponents/Main';
import { IProjectClientItem } from 'services/api/projectClients/types';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { FilterWrapper } from 'v2Components/layoutsComponents/Filter';
import useFilterList from 'hooks/useFilterList';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import { getFilter } from 'redux/reducers/app/selectors';
import { IFilter } from 'redux/reducers/app/types';
import { Filters } from './Filters';
import { getFilterProjectClientsHistory } from './filter.helper';
import styles from './styles.module.scss';

export default () => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const clients = useSelector(getClients);
	const loading = useSelector(getClientsLoading);
	const [search, setSearch] = useState<string | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [showFilter, setShowFilter] = useState(false);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('ProjectClients');
	const { projectId } = useParams<{ projectId: string }>();
	const { data, last_page: totalPages = 1 } = clients || {};
	const filterList = useFilterList(getFilterProjectClientsHistory);
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());

	const fetchProjectClients = useCallback(() => {
		dispatch(
			getClientsRequest({
				project_id: projectId,
				current_page: currentPage,
				// @TODO: Поиск должен быть по двум полям одновременно, но пока бек не переделали будет поиск по email
				search_field: search ? 'email' : undefined,
				search_value: search || undefined,
			}),
		);
	}, [currentPage, dispatch, projectId, search]);

	const onSubmit = (params: IFilter) => {
		dispatch(
			setFilter({
				...params,
			}),
		);
		setShowFilter(false);
	};

	useEffect(() => {
		dispatch(
			getClientsRequest({
				...filter,
				current_page: 1,
				project_id: projectId,
			}),
		);
	}, [dispatch, filter, projectId]);

	useEffect(() => {
		fetchProjectClients();
	}, [fetchProjectClients]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);
	return (
		<Main>
			<ProjectContent>
				<FilterWrapper
					filterList={filterList}
					deleteItemFilter={deleteItemFilter}
					onClearFilter={onClearFilter}
					onOpenFilter={() => setShowFilter(true)}
					className={styles.filters}
				/>
				<FilterForm
					showFilter={showFilter}
					setShowFilter={setShowFilter}
					onSubmit={onSubmit}
					renderBodyForm={<Filters />}
				/>
				<Table
					loading={loading}
					headerItems={tableHeaderItems}
					bodyItems={data || []}
					getRow={(item: IProjectClientItem) => TableRow({ item, onRefetch: fetchProjectClients })}
					gridTemplateColumns={gridTemplateColumns}
					className={styles.table}
				/>
				{totalPages > 1 && (
					<PaginationWrapper>
						<Pagination
							pageCount={Number(totalPages)}
							onPageChange={setCurrentPage}
							forcePage={Number(currentPage)}
						/>
					</PaginationWrapper>
				)}
			</ProjectContent>
		</Main>
	);
};
