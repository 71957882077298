import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { UserInfoV2 } from 'v2Components/layoutsComponents/UserInfo';
import { TriangleArrowLeftIcon } from 'assets/inline-svg';
import { useDispatch, useSelector } from 'react-redux';
import { getBurgerReducer } from 'redux/reducers/app/reducer';
import { getStatusBurger } from 'redux/reducers/app/selectors';
import classNames from 'classnames';
import { constUserTypes } from 'services/constants/user-types';
import { getUserData, getUserType } from 'redux/reducers/auth/selectors';
import styles from './styles.module.scss';

export const ProviderHeaderV2: FC = () => {
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const userType = useSelector(getUserType);
	const statusBurger = useSelector(getStatusBurger);
	const { merchantId } = useParams<{ merchantId: string }>();
	const onBurger = () => {
		dispatch(getBurgerReducer(!statusBurger));
		window.scrollTo(0, 0);
	};
	return (
		<header className={classNames(styles.header, { [styles.active]: statusBurger })}>
			<div className={styles.header__container}>
				<div className={styles.header__rollup} onClick={onBurger}>
					<TriangleArrowLeftIcon />
				</div>
				<p className={styles.header__userType}>
					{merchantId || userType === constUserTypes.MERCHANT
						? `Merchant ${userData?.id || ''}`
						: 'Provider'}
				</p>

				<div className={styles.header__right}>
					<UserInfoV2 />
					{/* <BurgerBtn /> */}
				</div>
			</div>
		</header>
	);
};
