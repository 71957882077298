export const UserSupport = () => {
	const url = 'https://salesiq.zoho.eu/widget';
	const widgetCode =
		'667e321b5971f0f706cc8c43962a36d3a5f41d4d897bd9152edb99183678d65b0b5e354ee404cab7a41e04eb58edaf7d';
	const script = document.createElement('script');
	script.setAttribute('type', 'text/javascript');
	script.setAttribute('id', 'scriptid');
	const code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;
	script.appendChild(document.createTextNode(code));
	document.body.appendChild(script);
};
export const RemoveSupport = () => {
	const float = document.getElementById('scriptid') as HTMLElement;
	if (float) float.remove();
	const zsiq = document.getElementById('zsiqscript') as HTMLElement;
	if (zsiq) zsiq.remove();
};
