import { IApprovement } from '../ApprovementItem';

interface IApprovementItem extends IApprovement {
	condition?: boolean;
}

interface IPropsLinked {
	link_transaction_id?: string;
	link_transaction_id_type: string;
	merchantId: string;
	projectId: string;
	isProject?: boolean;
}

interface IPropsLinkRefund {
	link_refund_id?: string;
	type: string;
	owner_merchant_id: string;
	project_id: string;
	isProject?: boolean;
}

interface IClientLink {
	owner_merchant_id?: number;
	project_id?: number;
	client_id?: number;
}

export type TTags =
	| 'DepositCrypto'
	| 'DepositFiat'
	| 'WithdrawalCrypto'
	| 'WithdrawalFiat'
	| 'Payment'
	| 'Refund'
	| 'Transfer'
	| 'ReceiveFunds'
	| 'Return'
	| 'SendCommission'
	| 'PaymentTransactions'
	| 'Exchange';

const APPROVEMENT_LIST = {
	DepositCrypto: [
		'Merchant ID',
		'Project ID',
		'Type',
		'Client ID',
		'Credit currency',
		'Linked transaction ID',
		'Amount',
		// 'Transfer amount',
		'Transaction hash (link)',
		'Provider Fee',
		'Risk Score',
		'Provider Fee %', //
		'Address', //
		'Date&Time',
	],
	DepositFiat: [
		'Merchant ID',
		'Project ID',
		'Type',
		'Client ID',
		'Credit currency',
		'Linked transaction ID',
		'Amount',
		// 'Transfer amount',
		'Transaction hash (link)',
		'Provider Fee',
		'Risk Score',
		'Provider Fee %',
		'Address',
		'Date&Time',
	],
	WithdrawalCrypto: [
		'Type',
		'User ID',
		'Credit currency',
		'Linked transaction ID',
		'Amount',
		'Transaction hash (link)',
		'Provider Fee',
		'Network fee',
		'Provider Fee %',
		'Address',
		'Date&Time',
	],
	WithdrawalFiat: [
		'Type',
		'User ID',
		'Credit currency',
		'Linked transaction ID',
		'Amount',
		'Transaction hash (link)',
		'Provider Fee',
		'Network fee',
		'Provider Fee %',
		'Address',
		'Date&Time',
	],
	Payment: [
		'Type',
		'Client ID',
		'Credit currency',
		'Linked transaction ID',
		'Amount',
		'Transaction hash (link)',
		'Provider Fee',
		'Risk Score',
		'Provider fee, %',
		'Address',
		'Date&Time',
		'Invoice ID',
		'Order ID',
	],
	Exchange: [
		'Merchant ID',
		'Project ID',
		'Type',
		'User ID',
		'Currency from',
		'Linked transaction ID',
		'Amount From',
		'Currency to',
		'Provider Fee',
		'Amount To',
		'Provider Fee %',
		'Type exchange',
		'Date&Time',
	],
	Refund: [
		'Type',
		'User ID',
		'Credit currency',
		'Link refund ID',
		'Refund amount',
		'Transaction hash (link)',
		'Provider Fee',
		'Network fee',
		'Provider Fee %',
		'Address',
		'Date&Time',
	],
	Transfer: [
		'Type',
		'User ID',
		'Currency from',
		'Linked transaction ID',
		'Amount',
		'From Project',
		'Provider Fee',
		'To Project',
		'Provider Fee %',
		'Date&Time',
	],
	ReceiveFunds: [
		'Type',
		'Recieve amount',
		'Status',
		'Network fee',
		'Linked transaction ID',
		'Destination',
		'Date&Time',
		'Transaction hash (link)',
		'Recieve time',
		'Network',
		'Credit currency',
	],
	Return: [
		'Type',
		'Recieve amount',
		'Status',
		'Network fee',
		'User ID',
		'Destination',
		'Link refund ID',
		'Transaction hash (link)',
		'Date&Time',
		'Network',
		'Refund time',
		'Credit currency',
	],
	SendCommission: [
		'Type',
		'Amount',
		'Status',
		'Network fee',
		'Link refund ID',
		'Destination',
		'Date&Time',
		'Transaction hash (link)',
		'Refund time',
		'Network',
		'Credit currency',
	],
	PaymentTransactions: [
		'ID',
		'Original wallet',
		'Provider ID',
		'Destination wallet',
		'Order ID',
		'Network fee',
		'Invoice ID',
		'Status',
		'Merchant ID',
		'Risk Score',
		'Date&Time',
		'Transaction hash (link)',
		'Credit currency',
		'Client ID',
		'Transfer amount',
		'Transfer time',
	],
};

export const getClientLink = ({ owner_merchant_id, project_id, client_id }: IClientLink) => {
	return (
		[owner_merchant_id, project_id, client_id].every((id) => typeof id === 'number') &&
		`/merchant/${owner_merchant_id}/project/${project_id}/client/${client_id}`
	);
};

export const getLinkRefund = ({
	link_refund_id,
	type,
	owner_merchant_id,
	project_id,
	isProject,
}: IPropsLinkRefund) => {
	if (!link_refund_id) {
		return null;
	}

	if (type === 'refund_payment') {
		return `/merchant/${String(
			owner_merchant_id,
		)}/project/${project_id}/order-merchant/details/${String(link_refund_id)}`;
	}

	// eslint-disable-next-line no-lonely-if
	if (isProject) {
		return `/merchant/${String(owner_merchant_id)}/project/${String(
			project_id,
		)}/transaction/details/${String(link_refund_id)}/crypto/${String(type)}`;
	}

	return `/transactions/${String(link_refund_id)}/crypto/${String(type)}`;
};

export const getLinkedTransactionLink = ({
	link_transaction_id,
	link_transaction_id_type,
	merchantId,
	projectId,
	isProject,
}: IPropsLinked) => {
	if (!link_transaction_id || !link_transaction_id_type) {
		return null;
	}

	return isProject
		? `/merchant/${merchantId}/project/${projectId}/transaction/details/${link_transaction_id}/fiat/${String(
				link_transaction_id_type,
		  )}`
		: `/transactions/${link_transaction_id}/fiat/${String(link_transaction_id_type)}`;
};

export const getApprovementList = (list: IApprovementItem[], tag: TTags): IApprovementItem[] => {
	const labels = APPROVEMENT_LIST[tag];
	const approvementList: IApprovementItem[] = [];
	labels &&
		labels.forEach((label) => {
			const item = list.find((approvement) => approvement.label === label);
			item && approvementList.push(item);
		});
	return approvementList;
};
