import moment from 'moment';
import { IFilter } from 'redux/reducers/app/types';
import { dateToString } from 'utils/dates';
import { TApiGetProviderAllCurrencyResponse } from 'services/api/provider/types';
import { routes } from 'routes/routes';
import { IGetCurrencyNetworkItem } from 'services/api/public/types';
import { IFilterElemItem } from 'v2Components/layoutsComponents/Filter/types';

export const convertRequestParams = (props: IFilter, tab: string) => {
	switch (tab) {
		case `${routes.wallets.transactionHistory}/crypto`: {
			return {
				...props,
				current_page: props.current_page || 1,
				date_from: props.date_from ? dateToString(props.date_from as Date) : undefined,
				date_to: props.date_to ? dateToString(props.date_to as Date) : undefined,
				amount_from:
					props.amount_from != null ? parseFloat(props.amount_from as string) : undefined,
				amount_to: props.amount_to != null ? parseFloat(props.amount_to as string) : undefined,
				currency_id: props.currency_id || undefined,
				transaction_type: props.transaction_type || undefined,
				transaction_id: props.transaction_id
					? parseFloat(props.transaction_id as string)
					: undefined,
				currency_network: props.currency_network || undefined,
				status: props.status || undefined,
				hash_transaction: props.hash_transaction || undefined,
			} as IFilter;
		}
		case `${routes.wallets.transactionHistory}/fiat`:
			return {
				...props,
				current_page: props.current_page || 1,
				currency_code: props.currency_code || undefined,
				date_from: props.date_from ? dateToString(props.date_from as Date) : undefined,
				date_to: props.date_to ? dateToString(props.date_to as Date) : undefined,
			};
		case routes.wallets.paymentTransactions:
			return {
				...props,
				current_page: props.current_page || 1,
				currency_name: props.currency_name || undefined,
				date_from: props.date_from ? dateToString(props.date_from as Date) : undefined,
				date_to: props.date_to ? dateToString(props.date_to as Date) : undefined,
				merchant_id: props.merchant_id || undefined,
				hash_transaction: props.hash_transaction || undefined,
				currency_network: props.currency_network || undefined,
			};
		default:
			return {};
	}
};

export const transactionTypeOptions = [
	{ label: 'All', value: 'all' },
	{ label: 'Deposit', value: 'deposit' },
	{ label: 'Withdrawal', value: 'withdrawal' },
	{ label: 'Receive funds', value: 'receive_funds' },
	{ label: 'Send commission', value: 'send_commission' },
	{ label: 'Refund', value: 'refund' },
	{ label: 'Return', value: 'return' },
];

export const statusOptions = [
	{ label: 'All', value: 'all' },
	{ label: 'In progress', value: 'in_progress' },
	{ label: 'Success', value: 'success' },
	{ label: 'Unsuccessful', value: 'unsuccessful' },
];

export interface TransactionsHistory {
	id: string;
	value: string | number | Date | null;
	networks?: IGetCurrencyNetworkItem[];
	currencies?: TApiGetProviderAllCurrencyResponse[];
}

export const getFilterTransactionsHistory = ({
	id,
	value,
	networks,
	currencies,
}: TransactionsHistory): IFilterElemItem => {
	switch (id) {
		case 'date_from':
			return { identify: id, content: `${moment(value).format('DD MMM YY')}` };
		case 'date_to':
			return { identify: id, content: `${moment(value).format('DD MMM YY')}` };
		case 'currency_id': {
			const content = currencies?.find((item) => item.id === value);
			return { identify: id, content: content?.name || '', label: content?.code };
		}
		case 'currency_name': {
			const content = currencies?.find((item) => item.name === value);
			return { identify: id, content: content?.name || '', label: content?.code };
		}
		case 'currency_code': {
			const content = currencies?.find((item) => item.code === value);
			return { identify: id, content: content?.name || '', label: content?.code };
		}
		case 'transaction_type': {
			const content = transactionTypeOptions.find((item) => item.value === value);
			return { identify: id, content: content?.label || '' };
		}
		case 'currency_network': {
			const content = networks?.find((item) => item.currency_network === value);
			return { identify: id, content: content?.currency_name || '', label: content?.currency_code };
		}
		case 'status': {
			const content = statusOptions.find((item) => item.value === value);
			return { identify: id, content: content?.label || '' };
		}
		case 'amount_from':
		case 'amount_to':
		case 'amount':
		case 'hash_transaction':
		case 'transaction_id':
		case 'merchant_id':
			return { identify: id, content: value as string };
		default:
			return { identify: '', content: '' };
	}
};
