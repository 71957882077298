import { TextField } from 'UI/Forms/TextField/TextField';
import { NoIcon, YesIcon } from 'assets/inline-svg';
import classNames from 'classnames';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { IProps } from '../types';
import style from './styles.module.scss';

const TableRowForm = ({ item, onSubmit, switchOffChangeableMode }: IProps): TdItem[] => {
	const { currency_network, currency } = item;
	return [
		{
			title: 'Currency',
			children: (
				<span className={classNames(style.token, style.tokenType2)}>
					<span className={style.token__icon}>
						<img src={currency.img_path} alt={currency.name} />
					</span>
					<span className={style.token__nameWrap}>
						<span className={style.token__name}>{currency.name}</span> <br />
						<span className={style.token__transcription}>
							{currency.code === currency_network
								? currency.code.toUpperCase()
								: `${currency.code.toUpperCase()}, ${currency_network.toUpperCase()}`}
						</span>
					</span>
				</span>
			),
		},
		{
			title: 'Withdrawal, Once',
			children: (
				<TextField type="number" classNameCustom={style.input__mb_0} name="withdrawal_max_once" />
			),
		},
		{
			title: 'Withdrawal, Daily',
			children: (
				<TextField type="number" classNameCustom={style.input__mb_0} name="withdrawal_max_daily" />
			),
		},
		{
			title: 'Withdrawal, Monthly',
			children: (
				<TextField
					type="number"
					classNameCustom={style.input__mb_0}
					name="withdrawal_max_monthly"
				/>
			),
		},
		{
			title: 'Deposit, MIN',
			children: <TextField type="number" classNameCustom={style.input__mb_0} name="deposit_min" />,
		},
		{
			title: 'Deposit, MAX',
			children: <TextField type="number" classNameCustom={style.input__mb_0} name="deposit_max" />,
		},
		{
			title: 'Withdrawal, MIN',
			children: <TextField type="number" classNameCustom={style.input__mb_0} name="withdraw_min" />,
		},
		{
			title: 'Free Withdraw',
			children: (
				<TextField
					type="number"
					classNameCustom={style.input__mb_0}
					name="withdrawal_amount_free"
				/>
			),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<button
						aria-label="button"
						type="submit"
						onClick={() => onSubmit()}
						className={style.tdCheck}
					>
						<YesIcon />
					</button>
					<button aria-label="button" type="button" onClick={switchOffChangeableMode}>
						<NoIcon />
					</button>
				</TableButtons>
			),
		},
	];
};

export default TableRowForm;
