import { useDispatch, useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import {
	changeProjectDetailsMerchantRequest,
	changeProjectDetailsRequest,
} from 'redux/reducers/projectSettings/reducer';
import {
	getProjectDetails,
	getProjectDetailsLoading,
} from 'redux/reducers/projectSettings/selectors';
import { IChangeProjectDetailsPayload } from 'redux/reducers/projectSettings/types';
import { constUserTypes } from 'services/constants/user-types';
import { toBase64 } from 'utils/files';
import { notify } from 'utils/notify';
import { ProjectContent } from 'layouts-elements/ProjectContent/ProjectContent';
import Loading from 'UI/Loading';
import { getCountries } from 'redux/reducers/app/selectors';
import { ProjectDetailsForm } from './ProjectDetailsForm/ProjectDetailsForm';
import { TProjectDetailsFormOnSubmit } from './ProjectDetailsForm/types';

export const ProjectDetailsTab = () => {
	const dispatch = useDispatch();
	const project = useSelector(getProjectDetails);
	const loading = useSelector(getProjectDetailsLoading);
	const userType = useSelector(getUserType);
	const countries = useSelector(getCountries);
	// eslint-disable-next-line @typescript-eslint/no-misused-promises
	const handleSubmit: TProjectDetailsFormOnSubmit = async (values, helpers) => {
		if (!project || !values.status) {
			return;
		}
		let logo: string | undefined;

		if (values.logoFile && values.logoFile !== 'delete') {
			logo = (await toBase64(values.logoFile)) as string;
		}

		const phoneCode = countries?.find((country) => country.iso === values.phoneCode)?.code || '';

		const payload: IChangeProjectDetailsPayload = {
			apiParams: {
				project_id: project.id,
				name: values.projectName,
				status: values.status.requestCode,
				contact_person_name: `${values.contactFirstName} ${values.contactLastName}`,
				email: values.email,
				phone: values.phoneNumber,
				phone_code: phoneCode,
				website: values.website,
				...(logo ? { logo } : {}),
			},
			onSuccess: () => {
				notify('Project saved successfully', 'success');
			},
			onError: () => {
				helpers.setSubmitting(false);
			},
		};
		dispatch(
			userType === constUserTypes.PROVIDER
				? changeProjectDetailsRequest(payload)
				: changeProjectDetailsMerchantRequest(payload),
		);
	};
	if (loading) {
		return <Loading />;
	}

	return (
		<ProjectContent>
			{project && <ProjectDetailsForm project={project} onSubmit={handleSubmit} />}
		</ProjectContent>
	);
};
