import { PopUpCloseIcon } from 'assets/inline-svg';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'i18n-react';
import { getRolesRequest } from 'redux/reducers/administration/reducer';
import { getRoles } from 'redux/reducers/administration/selectors';
import { RoleSelect } from 'UI/RoleSelect/RoleSelect';
import { IEditRoleProps } from './types';

export const EditRole: FC<IEditRoleProps> = ({ initRole, onSubmit, onClose, type }) => {
	const dispatch = useDispatch();
	const roles = useSelector(getRoles);
	const [activeRole, setActiveRole] = useState(initRole);

	useEffect(() => {
		if (!roles) {
			dispatch(getRolesRequest({ payload: type }));
		}
	}, [roles, dispatch, type]);

	return (
		<div className="popup popup--width-480">
			<button aria-label="button" type="button" className="popup__close" onClick={onClose}>
				<PopUpCloseIcon />
			</button>
			<div className="popup-header">
				<p className="popup-header__title popup-header__title--mb-32 popup-header__title--ta-l">
					Edit a role
				</p>
			</div>

			<div className="popup-body">
				<div className="select-block">
					<p className="select-block__name">Role</p>

					<RoleSelect roles={roles} active={activeRole} onChange={setActiveRole} />
				</div>

				{/* <div className="edit-role-info-pop">
					<p>
						{L.translate(`Administration.Roles.Description.${activeRole.code}`, { notFound: '' })}
					</p>
				</div> */}
			</div>

			<div className="popup-button popup-button--type2">
				<button type="button" className="button button--full-width button--type2" onClick={onClose}>
					Cancel
				</button>
				<button
					type="button"
					className="button button--full-width"
					onClick={() => onSubmit(activeRole)}
					disabled={activeRole.code === initRole.code}
				>
					Change
				</button>
			</div>
		</div>
	);
};
