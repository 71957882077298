import classNames from 'classnames';
import { TablePlugIcon } from 'assets/inline-svg';
import styles from './styles.module.scss';

export const NoResults = (): JSX.Element => {
	return (
		<div className={classNames(styles['table-plug'], styles['table-plug__with-header'])}>
			<div className={styles['table-plug__icon']}>
				<TablePlugIcon />
			</div>

			<div className={styles['table-plug__text']}>
				<p>No results were found for your request</p>
			</div>
		</div>
	);
};
