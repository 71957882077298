import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { EditIcon } from 'assets/inline-svg';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { DeleteConfirm } from 'UI/Popups/DeleteConfirm/DeleteConfirm';
import { getClientActivating, getClientDeleting } from 'redux/reducers/projectClients/selectors';
import { activateClientRequest, deleteClientRequest } from 'redux/reducers/projectClients/reducer';
import { notify } from 'utils/notify';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { ITableRow } from './types';

const TableRow = ({ item, onRefetch }: ITableRow) => {
	const dispatch = useDispatch();
	const activating = useSelector(getClientActivating);
	const deleting = useSelector(getClientDeleting);
	const {
		id,
		external_id: externalId,
		email,
		first_name: firstName,
		last_name: lastName,
		phone,
		country,
		level_trust: levelTrust,
		is_active: isActive,
	} = item;
	const { projectId, merchantId } = useParams<{ projectId: string; merchantId: string }>();
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const fullName =
		!firstName && !lastName ? '-' : `${String(firstName || '')} ${String(lastName || '')}`;

	const openDeleteConfirm = () => setDeleteConfirm(true);

	const closeDeleteConfirm = () => setDeleteConfirm(false);

	const handleDeleteConfirm = () => {
		if (deleting === id) {
			return;
		}
		dispatch(
			deleteClientRequest({
				client_id: id,
				onSuccess: () => {
					notify('Client was successfully deleted', 'success');
					closeDeleteConfirm();
					onRefetch?.();
				},
			}),
		);
	};

	const toggleActivate = () => {
		if (activating === id) {
			return;
		}
		dispatch(
			activateClientRequest({
				client_id: id,
				is_active: !isActive,
			}),
		);
	};

	return [
		{
			title: 'Client ID',
			children: (
				<Link to={`/merchant/${merchantId}/project/${projectId}/client/${id}`} className="link">
					{id}
				</Link>
			),
		},
		{
			title: 'Internal ID',
			children:
				(externalId &&
					(String(externalId).length < 9 ? externalId : `${String(externalId).slice(0, 7)}...`)) ||
				'-',
		},
		{
			title: 'Email',
			children: email || '-',
		},
		{
			title: 'User Name',
			children: fullName,
		},
		{
			title: 'Phone number',
			children: phone || '-',
		},
		{
			title: 'Country',
			children: country || '-',
		},
		{
			title: 'Level of Trust',
			children: levelTrust || '-',
		},
		{
			title: 'Block/Unblock',
			children: (
				<TableButtons>
					<div className="switch td-value tooltip-item">
						<label className="switch__label">
							<span className="tooltip tooltip--width-auto tooltip--top tooltip--center tooltip--arrow-bottom-center">
								<span className="tooltip__text">
									<span>Block</span>
								</span>
							</span>
							<input
								type="checkbox"
								className="hidden"
								checked={!!isActive}
								disabled={activating === id}
								onChange={toggleActivate}
							/>
							<span className="switch__toggler" />
						</label>
					</div>
				</TableButtons>
			),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<Link to={`/merchant/${merchantId}/project/${projectId}/client/${id}`}>
						<EditIcon />
					</Link>
					{/* <button type="button" disabled={deleting === id} onClick={openDeleteConfirm}>
						<TrashIcon />
					</button> */}
					<PopupModal open={deleteConfirm} onClose={closeDeleteConfirm}>
						<DeleteConfirm
							title="Delete a project client"
							body="Are you sure you want to delete the client?"
							onClose={closeDeleteConfirm}
							onDelete={handleDeleteConfirm}
						/>
					</PopupModal>
				</TableButtons>
			),
		},
	];
};

export default TableRow;
