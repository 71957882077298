import { useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { DeleteIcon, EditIcon, ExclamationIcon } from 'assets/inline-svg';
import {
	editProviderRoleRequest,
	deleteProviderRequest,
} from 'redux/reducers/administration/reducer';
import {
	IEditProviderRolePayload,
	IDeleteProviderPayload,
} from 'redux/reducers/administration/types';
import { TRoleAction } from 'v2Pages/Provider/UserProfilePage/UserProfileForm/RoleBlock/types';
import { TEditRoleOnSubmit } from 'UI/Popups/EditRole/types';
import { notify } from 'utils/notify';
import { Link } from 'react-router-dom';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import SwitchCheckbox from 'UI/SwitchCheckbox';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { EditRole } from 'UI/Popups/EditRole/EditRole';
import { PropsItem } from './index';
import DeleteUserAccount from './DeleteUserAccount/DeleteUserAccount';
import styles from './styles.module.scss';

const TableRow = ({ item, updateHandlerToggleBlock }: PropsItem) => {
	const dispatch = useDispatch();
	const [deletePopUp, setDeletePopUp] = useState(false);
	const closeDeletePopUp = () => setDeletePopUp(false);
	const [action, setAction] = useState<TRoleAction>(null);
	const closeModal = () => setAction(null);
	const { id, email, blocked, last_login, created_at } = item;
	const handleEditSubmit: TEditRoleOnSubmit = (newRole) => {
		const payload: IEditProviderRolePayload = {
			apiParams: {
				id,
				role: newRole.code,
			},
			newRoleName: newRole.name,
			onSuccess: () => {
				closeModal();
				notify('Role changed successfully', 'success');
			},
		};
		dispatch(editProviderRoleRequest(payload));
	};

	const handleDeleteSubmit = () => {
		const payload: IDeleteProviderPayload = {
			apiParams: {
				id,
			},
			onSuccess: () => {
				closeDeletePopUp();
				notify('components deleted successfully', 'success');
			},
		};
		dispatch(deleteProviderRequest(payload));
	};
	const handleSwitchToggle = () => {
		updateHandlerToggleBlock({
			id: item.id,
			is_block: !blocked,
		});
	};

	return [
		{
			title: 'Id',
			children: (
				<Link to={`/administration/team-members/${id}`} className="link">
					{id}
				</Link>
			),
		},
		{
			title: 'User Name',
			children: <p>-</p>,
		},
		{
			title: 'Email',
			children: <p>{email}</p>,
		},
		{
			title: 'Last Login',
			children: (
				<p>
					{last_login === null ? (
						'-'
					) : (
						<>
							{moment(Number(last_login) * 1000).format('l')},{' '}
							{moment(Number(last_login) * 1000).format('LT')}
						</>
					)}
				</p>
			),
		},
		{
			title: 'Created',
			children: (
				<>
					{moment(Number(created_at) * 1000).format('l')},{' '}
					{moment(Number(created_at) * 1000).format('LT')}
				</>
			),
		},
		{
			title: 'Role',
			children: (
				<p className="td-value flex flex-m">
					{item?.role.name}
					<span className="tooltip-item">
						<ExclamationIcon />
						<span className="tooltip tooltip--width-320 tooltip--top tooltip--center tooltip--arrow-bottom-center">
							<span className="tooltip__text tooltip__text--ta-c">
								<span>
									Has access to the sections “Transactions”, “Clients”, “Tickets”, “Callbacks”,
									“History of confirmations”, “Commissions”, and “Invoices”. The operator cannot
									confirm high-risk transactions. Cannot withdraw money from wallets (including
									making refunds). Has no access to settings, analytics, reports, users.
								</span>
							</span>
						</span>
					</span>
				</p>
			),
		},
		{
			title: 'Block/Unblock',
			children: (
				<div className={styles.center}>
					<SwitchCheckbox value={Boolean(blocked)} handleSwitchToggle={handleSwitchToggle}>
						Block
					</SwitchCheckbox>
				</div>
			),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<button aria-label="button" type="button" onClick={() => setAction('edit')}>
						<EditIcon />
					</button>
					<button
						aria-label="button"
						onClick={() => {
							setDeletePopUp(!deletePopUp);
						}}
						type="button"
					>
						<DeleteIcon />
					</button>
					<PopupModal open={action === 'edit'} onClose={closeModal}>
						<EditRole initRole={item.role} onSubmit={handleEditSubmit} onClose={closeModal} />
					</PopupModal>
					<PopupModal open={deletePopUp} onClose={closeDeletePopUp}>
						<DeleteUserAccount id={id} onClose={closeDeletePopUp} onSubmit={handleDeleteSubmit} />
					</PopupModal>
				</TableButtons>
			),
		},
	];
};
export default TableRow;
