import { useField } from 'formik';

export const TwoFaField = () => {
	const [field] = useField('totp');

	return (
		<div className="input">
			<p className="input-name">Enter the 2FA 6-digit Code</p>

			<div className="input-wrapper">
				<input {...field} className="input-item" placeholder="Authenticator Code" />
			</div>
		</div>
	);
};
