import { FC, useState } from 'react';
import { DeleteIcon, EditIcon } from 'assets/inline-svg';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { useDispatch } from 'react-redux';
import { IDeleteApiKeyPayload, IUpdateApiKeyPayload } from 'redux/reducers/projectSettings/types';
import { useParams } from 'react-router';
import { notify } from 'utils/notify';
import { deleteApiKeyRequest, updateApiKeyRequest } from 'redux/reducers/projectSettings/reducer';
import { DeleteConfirm } from 'UI/Popups/DeleteConfirm/DeleteConfirm';
import { PasswordInput } from '../../PasswordInput/PasswordInput';
import { IApiKeyItemProps, TModalAction } from './types';
import { AddEditApiKeyForm } from '../AddEditApiKeyForm/AddEditApiKeyForm';
import { trimInnerSpaces } from '../utils';
import { TAddEditApiKeyFormOnSubmit } from '../AddEditApiKeyForm/types';

export const ApiKeyItem: FC<IApiKeyItemProps> = ({ item }) => {
	const dispatch = useDispatch();
	const { projectId } = useParams<{ projectId: string }>();

	const [isShowPass, setIsShowPass] = useState(false);
	const [modalAction, setModalAction] = useState<TModalAction>(null);

	const closeModal = () => setModalAction(null);

	const handleEditSubmit: TAddEditApiKeyFormOnSubmit = ({ description, ip_list, totp }) => {
		const ipListFormatted = ip_list && trimInnerSpaces(ip_list);
		const payload: IUpdateApiKeyPayload = {
			apiParams: {
				id: item.id,
				project_id: projectId,
				description,
				ip_list: ipListFormatted,
				totp,
			},
			onSuccess: () => {
				closeModal();
				notify('API key has been successfully edited', 'success');
			},
		};
		dispatch(updateApiKeyRequest(payload));
	};

	const handleDeleteSubmit = () => {
		const payload: IDeleteApiKeyPayload = {
			apiParams: { id: item.id },
			onSuccess: () => {
				closeModal();
				notify('API key has been successfully deleted', 'success');
			},
		};
		dispatch(deleteApiKeyRequest(payload));
	};

	return (
		<div className="api-item-descr-block">
			<p className="api-item-descr">Description</p>
			<p className="block-text block-text--mb16">{item.description}</p>

			<div className="api-item-wrapper">
				<div className="input input--no-mb">
					<p className="input-name">API Key</p>
					<PasswordInput
						placeholder="API Key"
						value={item.public}
						readOnly
						showPass={isShowPass}
						onToggleShowPass={setIsShowPass}
					/>
				</div>

				<div className="fields-button fields-button--center fields-button--mt0">
					{/* <button
						className="button button--show-key"
						type="button"
						onClick={() => setIsShowPass(!isShowPass)}
					>
						{isShowPass ? 'Hide Key' : 'Show Key'}
					</button> */}

					<div className="action-buttons action-button--size">
						<button
							className="action-buttons__item action-buttons__item--blue"
							type="button"
							onClick={() => setModalAction('edit')}
						>
							<span className="action-buttons__icon">
								<EditIcon />
							</span>
							Edit
						</button>

						<button
							className="action-buttons__item action-buttons__item--red"
							type="button"
							onClick={() => setModalAction('delete')}
						>
							<span className="action-buttons__icon">
								<DeleteIcon />
							</span>
							Delete
						</button>
					</div>
				</div>
			</div>

			<PopupModal open={modalAction === 'edit'} onClose={closeModal}>
				<AddEditApiKeyForm isEdit apiKey={item} onSubmit={handleEditSubmit} onClose={closeModal} />
			</PopupModal>

			<PopupModal open={modalAction === 'delete'} onClose={closeModal}>
				<DeleteConfirm
					title="Delete API Key"
					body="Are you sure you want to delete API Key?"
					onDelete={handleDeleteSubmit}
					onClose={closeModal}
				/>
			</PopupModal>
		</div>
	);
};
