import { FC } from 'react';
import { AuthLayout } from 'layouts/AuthLayout/AuthLayout';
import { Login } from 'components/Auth/Login/Login';

export const LoginMerchantPage: FC = () => {
	return (
		<AuthLayout title="Sign in">
			<Login />
		</AuthLayout>
	);
};
