import { FC } from 'react';
import { PopUpCloseIcon } from 'assets/inline-svg';
import { IDeleteBankModalProps } from '../../types';

const DeleteWalletModal: FC<IDeleteBankModalProps> = ({ onClose, onSubmit }) => {
	return (
		<div className="popup">
			<button aria-label="button" type="button" className="popup__close" onClick={onClose}>
				<PopUpCloseIcon />
			</button>
			<div className="popup-header">
				<p className="popup-header__title popup-header__title--ta-l">Delete Wallet</p>
			</div>

			<div className="popup-body">
				<p className="popup-text">Are you sure you want to delete wallet?</p>
			</div>
			<div className="popup-button popup-button--type2">
				<button type="button" className="button button--full-width button--type2" onClick={onClose}>
					Cancel
				</button>
				<button
					type="button"
					className="button button--full-width button--type4"
					onClick={onSubmit}
				>
					Delete
				</button>
			</div>
		</div>
	);
};

export default DeleteWalletModal;
