import { SelectField } from 'v2Components/formComponents/SelectField';
import { DefaultToken, Token } from 'v2Components/UI/Token';
import { useSelector } from 'react-redux';
import { getProviderCurrencyAllNoSettings } from 'redux/reducers/providerCurrency/selectors';

interface IProps {
	valueProp?: 'id' | 'code';
	fieldName?: string;
}
export const SelectFieldCurrency = ({ fieldName = 'currency_id', valueProp = 'id' }: IProps) => {
	const currencies = useSelector(getProviderCurrencyAllNoSettings);
	return (
		<SelectField
			title="Currency"
			name={fieldName}
			options={[
				{
					id: 0,
					code: 'All',
					name: '',
					img_path: '',
					icon: '',
					is_active: 1,
					is_crypto: 1,
				},
				...currencies,
			]}
			valueProp={valueProp}
			renderSelected={(selectedValue) =>
				(selectedValue && (
					<Token
						code={
							selectedValue.code === 'All'
								? selectedValue.code
								: (selectedValue.code || '').toUpperCase()
						}
						name={selectedValue.name}
					/>
				)) || <DefaultToken />
			}
			renderOption={({ code, name, img_path }) => (
				<Token code={code === 'All' ? code : (code || '').toUpperCase()} name={name} />
			)}
		/>
	);
};
