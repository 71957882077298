import { FC, useState, ChangeEvent } from 'react';
import { toUTC } from 'utils/dates';
import { CheckIcon } from 'assets/inline-svg';
import { capitalize, shortNotation, trimLastZeros } from 'utils/strings';
import { IProviderStatusRequest } from 'redux/reducers/providerApprovements/types';
import { putApprovementStatusRequest } from 'redux/reducers/providerApprovements/reducer';
import { notify } from 'utils/notify';
import { useDispatch } from 'react-redux';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { PopupModal } from 'UI/Popups/PopupModal/PopupModal';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import { PopUpRefun } from '../../../PopUpRefun';
import { Actions } from '../Actions/Actions';
import { ConfirmPopup } from '../../../ConfirmPopup/ConfirmPopup';
import { Select } from '../Select/Select';
import { IProps } from './types';

export default ({ item, checked, setChecked, updateApprovementRequset }: IProps): TdItem[] => {
	const {
		hash_transaction,
		created_at,
		comments_count,
		status,
		date_status_change,
		status_changer_id,
		transaction_type,
		risk_score,
		currency_code,
		amount,
		is_kyc_requested,
		id,
	} = item;
	const dispatch = useDispatch();
	const [active, setActive] = useState<number | null>(null);
	const [isModal, setIsModal] = useState(false);

	const closeModal = () => setIsModal(false);
	const [isOpenModal, setIsOpenModal] = useState<string>('');
	const statusBy = (types: string) => {
		if (types === 'in_approvement') {
			return <p className="td-value green">New</p>;
		}
		if (types === 'blocked') {
			return <p className="td-value red">Blocked</p>;
		}

		return '---';
	};
	const [filters, setFilters] = useState({
		status: '',
		name: '',
	});

	const { getViewDecimal } = useViewDecimals();

	const viewDecimals = getViewDecimal(currency_code);

	const onEdit = (value: number | null) => {
		setActive(value);
	};

	const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
		if (!checked.includes(id)) {
			setChecked([...checked, id]);
			return;
		}
		const newArr = checked.filter((check: number) => String(check) !== String(id));
		setChecked(newArr);
	};

	const changeFilter = (value: string, name: string) => {
		setIsOpenModal(value);
		setFilters({ ...filters, status: value, name });
	};

	const onAction = (event: React.MouseEvent) => {
		const { name } = event.target as HTMLButtonElement;
		setIsOpenModal(name);
		setActive(null);
	};

	const handleConfirm = () => {
		if (filters.status === 'refound') return;
		const payloadData: IProviderStatusRequest = {
			params: { id, status: filters.status },
			onSuccess: () => {
				notify('make request', 'success');
				updateApprovementRequset();
			},
		};
		closeModal();
		setActive(null);
		dispatch(putApprovementStatusRequest(payloadData));
	};

	return [
		{
			title: 'Created',
			children: toUTC(created_at, true),
		},
		{
			title: 'Status',
			children:
				active === id ? (
					<Select
						options={[
							{ value: 1, label: 'Returned', code: 'refound' },
							{ value: 2, label: 'Approved', code: 'approved' },
							{ value: 3, label: 'Blocked', code: 'blocked' },
						]}
						sleceted={filters.status}
						setSelect={(value: string, name: string) => changeFilter(value, name)}
						selectMb="select-block--mb-0"
						selectWd="select--width-540"
					/>
				) : (
					statusBy(status)
				),
		},
		{
			title: 'Status change',
			children: date_status_change,
		},
		{
			title: 'Status changer',
			children: status_changer_id || '--',
		},
		{
			title: 'TX Type',
			children: capitalize(transaction_type || ''),
		},
		{
			title: 'Tx ID',
			children: shortNotation(hash_transaction, 4, 0),
		},
		{
			title: 'Risk Score',
			children: risk_score,
		},
		{
			title: 'Currency',
			children: currency_code?.toLocaleUpperCase(),
		},
		{
			title: 'Amount',
			children: trimLastZeros(amount.toFixed(viewDecimals)),
		},
	];
};
