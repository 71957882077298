import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { EditIcon, CheckIcon, NoIcon } from 'assets/inline-svg';
import {
	verifyProjectRequest,
	unverifyProjectRequest,
	deleteProjectRequest,
} from 'redux/reducers/providerProjectApprovements/reducer';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import { notify } from 'utils/notify';
import { dateToString } from 'utils/dates';
import { Link } from 'react-router-dom';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { VerifySelect, TValue } from './VeirfySelect/VerifySelect';
import { ITableRow } from './types';

export const TableRow = ({ item }: ITableRow): TdItem[] => {
	const dispatch = useDispatch();
	const { id, owner_merchant_id, name, created_at, is_verified } = item;
	const [verifyState, setVerifyState] = useState<TValue>(is_verified ? 'verified' : 'unverified');
	const [isEdit, setIsEdit] = useState(false);
	const onVerify = () => {
		dispatch(
			verifyProjectRequest({
				apiParams: {
					project_id: id,
				},
				onSuccess: () => {
					notify('Project successfully verified', 'success');
					// onRefetch?.();
					// onSetActive?.(id);
				},
			}),
		);
	};

	const onUnverify = () =>
		dispatch(
			unverifyProjectRequest({
				apiParams: {
					project_id: id,
				},
				onSuccess: () => {
					notify('Project successfully unverified', 'success');
					// onRefetch?.();
					// onSetActive?.(id);
				},
			}),
		);

	const onDelete = () =>
		dispatch(
			deleteProjectRequest({
				apiParams: {
					project_id: id,
				},
				onSuccess: () => {
					notify('Project successfully deleted', 'success');
				},
			}),
		);

	const handleVerifyChange = () => {
		switch (verifyState) {
			case 'verified':
				onVerify();
				break;
			case 'unverified':
				onUnverify();
				break;
			case 'deleted':
				onDelete();
				break;
			default:
				break;
		}
		setIsEdit(false);
	};
	return [
		{
			title: 'Merchant ID',
			children: owner_merchant_id,
		},
		{
			title: 'Project ID',
			children: (
				<Link to={`/merchant/${String(owner_merchant_id)}/project/${id}`} className="link">
					{id}
				</Link>
			),
		},
		{
			title: 'Project Name',
			children: name,
		},
		{
			title: 'Created date',
			children: created_at && `${dateToString(new Date(created_at))} UTC`,
		},
		{
			title: 'Current status',
			children: (
				<>
					{isEdit && <VerifySelect value={verifyState} onChange={setVerifyState} />}
					{!isEdit && (is_verified ? 'Verified' : 'Unverified')}
				</>
			),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					{!isEdit && (
						<button aria-label="button" type="button" onClick={() => setIsEdit(true)}>
							<EditIcon />
						</button>
					)}
					{isEdit && (
						<>
							<button aria-label="button" type="button" onClick={handleVerifyChange}>
								<CheckIcon />
							</button>
							<button aria-label="button" type="button" onClick={() => setIsEdit(false)}>
								<NoIcon />
							</button>
						</>
					)}
				</TableButtons>
			),
		},
	];
};
