import { FC } from 'react';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { Datepicker } from 'v2Components/UI/Datepicker';
import { IDatepickerField } from './types';

const ErrorMessage: FC<{ errorMsg: string | undefined }> = ({ errorMsg }) => (
	<p className="input-notification input-notification--error">{errorMsg}</p>
);

export const DatepickerField = <T extends Record<string, any>>({
	name,
	value,
	onChange,
	inFilter,
	portalId,
	customErrorMsg,
	classNameCustom,
	...rest
}: IDatepickerField) => {
	const [field, meta, helper] = useField(name);
	const { value: usedValue } = field;
	const { submitCount } = useFormikContext();
	const isShowError = (meta.touched || submitCount > 0) && meta.error;
	const { setValue } = helper;

	const handleSelect = (_value: Date | null) => {
		setValue(_value);
	};

	const renderErrorMsg = () => {
		if (customErrorMsg) {
			return <ErrorMessage errorMsg={customErrorMsg} />;
		}
		return isShowError && <ErrorMessage errorMsg={meta.error} />;
	};

	if (typeof usedValue === 'string') {
		setValue(new Date(usedValue));
	}

	return (
		<div
			className={classNames(
				'input',
				{ 'input--error': customErrorMsg || isShowError },
				classNameCustom,
			)}
		>
			<Datepicker
				value={typeof usedValue === 'string' ? undefined : usedValue}
				onChange={handleSelect}
				{...rest}
				portalId={inFilter ? 'filter-datepicker-portal' : portalId}
			/>
			{renderErrorMsg()}
		</div>
	);
};
