import { FC } from 'react';
import { IApiGetUserProfileResponse } from 'redux/reducers/merchantApprovalManagement/types';

export const MerchantInfo: FC<{ profile: IApiGetUserProfileResponse }> = ({ profile }) => {
	const { first_name, last_name, company_name, phone, email, country, city, address_1, address_2 } =
		profile.merchant;
	return (
		<div className="user-info">
			<div className="user-info-item">
				<div className="user-info-item__title">
					<p>First name</p>
				</div>
				<div className="user-info-item__text">
					<p>{first_name}</p>
				</div>
			</div>
			<div className="user-info-item">
				<div className="user-info-item__title">
					<p>Last name</p>
				</div>
				<div className="user-info-item__text">
					<p>{last_name}</p>
				</div>
			</div>
			<div className="user-info-item">
				<div className="user-info-item__title">
					<p>Name of the company</p>
				</div>
				<div className="user-info-item__text">
					<p>{company_name}</p>
				</div>
			</div>
			<div className="user-info-item">
				<div className="user-info-item__title">
					<p>Phone number</p>
				</div>
				<div className="user-info-item__text">
					<p>{phone}</p>
				</div>
			</div>
			<div className="user-info-item">
				<div className="user-info-item__title">
					<p>Email</p>
				</div>
				<div className="user-info-item__text">
					<p>{email}</p>
				</div>
			</div>
			<div className="user-info-item">
				<h4 className="user-info-item__subtitle">Location info</h4>
				<div className="user-info-item__title">
					<p>Country</p>
				</div>
				<div className="user-info-item__text">
					<p>{country}</p>
				</div>
			</div>
			<div className="user-info-item">
				<div className="user-info-item__title">
					<p>City</p>
				</div>
				<div className="user-info-item__text">
					<p>{city}</p>
				</div>
			</div>
			<div className="user-info-item">
				<div className="user-info-item__title">
					<p>Address line 1</p>
				</div>
				<div className="user-info-item__text">
					<p>{address_1}</p>
				</div>
			</div>
			<div className="user-info-item">
				<div className="user-info-item__title">
					<p>Address line 2</p>
				</div>
				<div className="user-info-item__text">
					<p>{address_2}</p>
				</div>
			</div>
		</div>
	);
};
