import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import logoImg from 'assets/styles/images/Logo.svg';
import { Link, useLocation, useParams } from 'react-router-dom';
import { UserInfo } from 'layouts-elements/UserInfo/UserInfo';
import { ProjectInfo } from 'layouts-elements/ProjectInfo/ProjectInfo';
import { getProfileLoading } from 'redux/reducers/merchantProfile/selectors';
import { getProjectDetailsLoading } from 'redux/reducers/projectSettings/selectors';
import { getBalanceInDefaultCurrencyRequest } from 'redux/reducers/app/reducer';
import { getBalanceInDefaultCurrency } from 'redux/reducers/app/selectors';
import classNames from 'classnames';
import { BurgerBtn } from 'UI/BurgerBtn/BurgerBtn';
import { BalanceInfo } from '../BalanceInfo/BalanceInfo';

let projectIdMemo: string | undefined;

export const ProjectHeader = () => {
	const merchantLoading = useSelector(getProfileLoading);
	const projectLoading = useSelector(getProjectDetailsLoading);
	const { pathname } = useLocation();

	const dispatch = useDispatch();

	const balanceInDefaultCurrency = useSelector(getBalanceInDefaultCurrency);

	const { projectId } = useParams<{ projectId: string }>();

	useEffect(() => {
		const { balance, currencyCode } = balanceInDefaultCurrency;
		if (balance !== null && currencyCode !== null && projectIdMemo === projectId) {
			return;
		}
		projectIdMemo = projectId;
		dispatch(getBalanceInDefaultCurrencyRequest({ project_id: +projectId }));
	}, [balanceInDefaultCurrency, dispatch, projectId]);

	return (
		<header className="header header--type2">
			<div className="header__container">
				<div
					className={classNames('header', {
						'header-left header-left--type2': !pathname.includes('/add-project'),
					})}
				>
					<Link to="/transactions" className="logo">
						<img src={logoImg} alt="logo" />
					</Link>

					<div className="select-block select-block--project-select select-block--custom">
						{!merchantLoading && !projectLoading && <ProjectInfo />}
					</div>
				</div>

				<div className="header-right header-right--type2">
					<BalanceInfo />
					<UserInfo />
					<BurgerBtn />
				</div>
			</div>
		</header>
	);
};
