import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getProviderAllCurrencySettingRequest,
	putProviderAllCurrencySettingRequest,
} from 'redux/reducers/providerCurrency/reducer';
import { notify } from 'utils/notify';
import {
	getProviderCurrencyAll,
	getProviderCurrencyAllLoading,
} from 'redux/reducers/providerCurrency/selectors';
import Table from 'v2Components/commomComponents/Table';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import { Formik } from 'formik';
import TableRow from './TableRow';

const Limitation = () => {
	const dispatch = useDispatch();
	const items = useSelector(getProviderCurrencyAll);
	const loading = useSelector(getProviderCurrencyAllLoading);
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('Limitation');
	const [item, setItem] = useState<any>({});
	const initialValues = useMemo(
		() => ({
			withdrawal_max_once: item.withdrawal_max_once || '',
			withdrawal_max_daily: item.withdrawal_max_daily || '',
			withdrawal_max_monthly: item.withdrawal_max_monthly || '',
			deposit_min: item.deposit_min || '',
			deposit_max: item.deposit_max || '',
			withdraw_min: item.withdraw_min || '',
			withdrawal_amount_free: item.withdrawal_amount_free || '',
		}),
		[item],
	);
	const onSubmit = (values: any, actions: any) => {
		actions.setSubmitting(false);
		const body = {
			currency_setting_id: item?.id,
			withdrawal_max_once: values.withdrawal_max_once,
			withdrawal_max_daily: values.withdrawal_max_daily,
			withdrawal_max_monthly: values.withdrawal_max_monthly,
			deposit_min: values.deposit_min,
			deposit_max: values.deposit_max,
			withdraw_min: values.withdraw_min,
			withdrawal_amount_free: values.withdrawal_amount_free,
		};
		dispatch(
			putProviderAllCurrencySettingRequest({
				apiParams: {
					...body,
				},
				onSuccess: () => {
					notify('CurrencySelected edit success', 'success');
				},
			}),
		);
	};

	useEffect(() => {
		dispatch(
			getProviderAllCurrencySettingRequest({
				apiParams: {},
			}),
		);
	}, [dispatch]);

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			{({ handleSubmit }) => (
				<Table
					loading={loading}
					headerItems={tableHeaderItems}
					gridTemplateColumns={gridTemplateColumns}
					getRow={(row: any) => TableRow({ item: row, handleSubmit, setItem })}
					bodyItems={items}
				/>
			)}
		</Formik>
	);
};

export default Limitation;
