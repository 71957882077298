import { AccountsNotFoundIcon, PlusBlueIcon, SignIcon } from 'assets/inline-svg';
import Button from 'v2Components/UI/Button';
import { useSelector } from 'react-redux';
import { getUserType } from 'redux/reducers/auth/selectors';
import styles from './styles.module.scss';

interface IProps {
	setShowFilter: (value: boolean) => void;
	setOpenModal: (value: boolean) => void;
}
const NoAccount = ({ setShowFilter, setOpenModal }: IProps) => {
	return (
		<div className={styles.empty}>
			<div>
				<AccountsNotFoundIcon />
			</div>
			<div className={styles.empty__content}>
				<SignIcon />
				<span className={styles.empty__title}>Welcome to your Financial Accounts Dashboard!</span>
				<span className={styles.empty__text}>
					This page allows you to easily manage your Bank Accounts and Cryptocurrency Wallets.
				</span>
				<div className={styles.empty__buttons}>
					<Button className={styles.empty__button} onClick={() => setShowFilter(true)}>
						<PlusBlueIcon /> Add Bank Account
					</Button>
					<span className={styles.empty__or}>OR</span>
					<Button className={styles.empty__button} onClick={() => setOpenModal(true)} light>
						<PlusBlueIcon /> Add Crypto Wallet
					</Button>
				</div>
			</div>
		</div>
	);
};

export default NoAccount;
