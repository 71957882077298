/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { CheckIcon } from 'assets/inline-svg';
import { useField } from 'formik';

export const AgreePrivacyPolicyField: FC = () => {
	const [field, meta] = useField('isAgreePrivacyPolicy');

	const isShowError = meta.touched && meta.error;

	return (
		<div className="checkbox checkbox--mb-0 ">
			<label className="checkbox__label">
				<input {...field} type="checkbox" className="hidden" />
				<span className="checkbox__item">
					<span className="checkbox__item-icon">
						<CheckIcon />
					</span>
				</span>

				<span className="checkbox__text">
					I agree to our <a>Privacy policy</a> and <a>User agreement</a>
				</span>
			</label>

			{isShowError && <p className="input-notification input-notification--error">{meta.error}</p>}
		</div>
	);
};
