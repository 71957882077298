export const MobileProviderInfo = () => {
	return (
		<div className="mobile-project-info">
			<div className="header-project">
				<div className="header-project__name">
					<p>Provider</p>
				</div>
			</div>
		</div>
	);
};
