import { capitalize, trimBottomLine, shortNotation, trimLastZeros } from 'utils/strings';
import { toUTC } from 'utils/dates';
import { Tooltip } from 'UI/Tooltip/Tooltip';
import { isExist } from 'utils/numbers';
import { useViewDecimals } from 'hooks/useViewDecimals';
import { TdItem } from 'v2Components/commomComponents/Table/Td';
import TableButtons from 'v2Components/commomComponents/Table/TableButtons';
import { InvoiceTableRowActions } from '../InvoiceTableRowActions/InvoiceTableRowActions';
import { formatPrice } from '../utils';
import { IProviderInvoiceTableRow } from './types';

export const ProviderInvoiceTableRow = ({ item, ...rest }: IProviderInvoiceTableRow): TdItem[] => {
	const {
		id,
		created_at: createdAt,
		provider_id: providerId,
		merchant_id: merchantId,
		order_provider_id: orderProviderId,
		amount_request: amountRequest,
		currency_request: currencyRequest,
		amount_to_pay: amountToPay,
		currency_paid: currencyPaid,
		email,
		issue,
		status,
	} = item;

	const { getViewDecimal } = useViewDecimals();
	const viewDecimals = currencyPaid && getViewDecimal(currencyPaid);
	return [
		{
			title: 'Id',
			children: id,
		},
		{
			title: 'Created',
			children: createdAt && toUTC(new Date(createdAt)),
		},
		{
			title: 'Order ID',
			children: orderProviderId,
		},
		{
			title: 'Creater',
			children: providerId,
		},
		{
			title: 'Price',
			children: (
				<Tooltip tooltip={amountRequest}>{amountRequest && formatPrice(amountRequest)}</Tooltip>
			),
		},
		{
			title: 'Currency',
			children: currencyRequest?.toUpperCase(),
		},
		{
			title: 'Amount to pay',
			children: (
				<div className="hint-value">
					<p className="td-value">
						<Tooltip tooltip={amountToPay}>{amountToPay && formatPrice(amountToPay, 4)}</Tooltip>
					</p>
				</div>
			),
		},
		{
			title: 'Currency paid',
			children: currencyPaid?.toUpperCase(),
		},
		{
			title: 'Status',
			children: status && capitalize(trimBottomLine(status as string)),
		},
		{
			title: 'Merchant ID',
			children: merchantId,
		},
		{
			title: 'Client email',
			children: (
				<div className="hint-value">
					<p className="td-value">
						<Tooltip tooltip={email}>{email && shortNotation(email, 9, 3)}</Tooltip>
					</p>
				</div>
			),
		},
		{
			title: 'Issue',
			children: (
				<p className="td-status">
					{isExist(issue) && isExist(viewDecimals)
						? trimLastZeros(String(issue?.toFixed(Number(viewDecimals))))
						: '-'}
				</p>
			),
		},
		{
			title: 'Action',
			children: (
				<TableButtons right>
					<InvoiceTableRowActions id={id} {...rest} />
				</TableButtons>
			),
		},
	];
};
export default ProviderInvoiceTableRow;
