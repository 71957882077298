import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api, showError } from 'services';
import { IPaymentTrasactionsParams } from 'services/api/provider/types';
import {
	getPaymentTrasactionRequest,
	getPaymentTrasactionSuccess,
	showPaymentTrasactionRequest,
	showPaymentTrasactionSuccess,
} from './reducer';
import {
	IPaymentTrasactionPagination,
	IShowPaymentTrasactionRequest,
	IShowPaymentTrasactionResponse,
} from './types';

function* getPaymentTrasactionsWorker({ payload }: PayloadAction<IPaymentTrasactionsParams>) {
	try {
		const response: IPaymentTrasactionPagination = yield call(
			api.provider.getPaymentTransactionsList,
			payload,
		);
		yield put(getPaymentTrasactionSuccess(response));
	} catch (error) {
		showError(error);
	}
}

function* showPaymentTrasactionWorker({ payload }: PayloadAction<IShowPaymentTrasactionRequest>) {
	try {
		const response: IShowPaymentTrasactionResponse = yield call(
			api.provider.getPaymentTransactionDetails,
			payload,
		);
		yield put(showPaymentTrasactionSuccess(response));
	} catch (error) {
		showError(error);
	}
}

export function* providerPaymentSaga() {
	yield takeEvery(getPaymentTrasactionRequest, getPaymentTrasactionsWorker);
	yield takeEvery(showPaymentTrasactionRequest, showPaymentTrasactionWorker);
}
