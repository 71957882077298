import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	getTransactionsRequest,
	setTransactionsStateFilter,
	setTransactionsStateSearch,
} from 'redux/reducers/providerTransactions/reducer';
import {
	getTransactions,
	getTransactionsLoading,
	getTransactionsFilter,
	getTransactionsSearch,
} from 'redux/reducers/providerTransactions/selectors';
import { Pagination } from 'UI/Pagination/Pagination';
import FilterForm from 'v2Components/layoutsComponents/Filter/FilterForm';
import { getFilter } from 'redux/reducers/app/selectors';
import FilterWrapper from 'v2Components/layoutsComponents/Filter/FilterWrapper';
import { clearFilter, setFilter } from 'redux/reducers/app/reducer';
import Field from 'v2Components/UI/Field';
import { SearchIcon } from 'assets/inline-svg';
import { PaginationWrapper } from 'v2Components/layoutsComponents/Pagination';
import Table from 'v2Components/commomComponents/Table';
import { getTableDescription } from 'v2Components/commomComponents/Table/table.data';
import useFilterList from 'hooks/useFilterList';
import {
	IDepositCryptoTransactionItem,
	IDepositCryptoTransactionParams,
} from 'services/api/provider/types';

import { Filters } from '../Filters/Filters';
import { ICryptoTable } from './types';
import { getFilterTransactionsHistory, convertRequestParams } from '../filter.helper';
import { TableRow } from './TableRow/TableRow';
import styles from './styles.module.scss';

const CryptoTable: FC<ICryptoTable> = ({ currencyTab }) => {
	const dispatch = useDispatch();
	const filter = useSelector(getFilter);
	const transactions = useSelector(getTransactions);
	const loading = useSelector(getTransactionsLoading);
	const filters = useSelector(getTransactionsFilter);
	const searchValue = useSelector(getTransactionsSearch);
	const [showFilter, setShowFilter] = useState(false);
	const { data = [], last_page: totalPages = 1, per_page: perPage } = transactions || {};
	const { tableHeaderItems, gridTemplateColumns } = getTableDescription('TransactionsCrypto');
	const filterList = useFilterList(getFilterTransactionsHistory);
	const setPage = (_page: number) => dispatch(setTransactionsStateFilter({ page: _page }));
	const deleteItemFilter = (value: string) => dispatch(setFilter({ ...filter, [value]: null }));
	const onClearFilter = () => dispatch(clearFilter());
	const isShowPagination = totalPages > 1 && !loading;
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const onSubmit = (data: any) => {
		dispatch(setFilter(convertRequestParams(data, 'crypto')));
		setPage(1);
		setShowFilter(false);
	};

	const onChangeSearch = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			const searchData = value === '' || value.length < 1 ? undefined : value;

			dispatch(setTransactionsStateSearch(searchData));
		},
		[dispatch],
	);

	useEffect(() => {
		const requestData: IDepositCryptoTransactionParams = {
			...filter,
			current_page: filters.page,
		};

		if (searchValue) {
			requestData.search_value = searchValue;
		}

		dispatch(getTransactionsRequest(requestData));
	}, [dispatch, filter, filters.page, searchValue]);

	useEffect(() => {
		return () => {
			dispatch(clearFilter());
		};
	}, [dispatch]);

	return (
		<>
			<div className={styles.filters}>
				<Field
					value={searchValue}
					onChange={onChangeSearch}
					placeholder="Enter text"
					renderRightIcon={<SearchIcon />}
					classNameCustom={styles.search}
					classNameInputItem={styles.search__input}
					widthInput={340}
					label="Search (Tx ID, Tx Hash)"
					isDebounce
				/>
				<FilterWrapper
					filterList={filterList}
					deleteItemFilter={deleteItemFilter}
					onClearFilter={onClearFilter}
					onOpenFilter={() => setShowFilter(true)}
					className={styles.filters__button}
				/>
			</div>
			<FilterForm
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				onSubmit={onSubmit}
				renderBodyForm={<Filters values={filter} currencyTab={currencyTab} />}
			/>
			<Table
				loading={loading}
				headerItems={tableHeaderItems}
				bodyItems={data}
				getRow={(item: IDepositCryptoTransactionItem, index: number) =>
					TableRow({ item, number: index + 1 + (filters.page - 1) * (perPage || 0) })
				}
				gridTemplateColumns={gridTemplateColumns}
			/>
			{isShowPagination && (
				<PaginationWrapper>
					<Pagination
						pageCount={Number(totalPages)}
						onPageChange={setPage}
						forcePage={Number(filters.page)}
					/>
				</PaginationWrapper>
			)}
		</>
	);
};
export default CryptoTable;
